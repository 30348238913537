import React, { useState } from 'react';
import NavHeader from '~/components/NavHeader';
import TextEditor from '~/components/TextEditor';
import { DEFAULT_MAX_CHARS } from '~/data/constants';
import { ReportInstance } from '~/data/models/yearbook';
import { t } from '~/utils/i18n';
import { Container, SettingsButtonIconIcon } from './style';

type ReportDetailLayoutProps = {
  reportInstance?: ReportInstance | null;
  saving?: boolean;
  viewOnly?: boolean;
  autoFocus?: boolean;
  allowImages?: boolean;
  onBack: () => void;
  onSetting: (text?: string) => void;
  onSave: (text: string) => void;
};

export default function ReportDetail({
  reportInstance,
  saving = false,
  viewOnly,
  autoFocus = true,
  allowImages,
  onBack,
  onSetting,
  onSave,
}: ReportDetailLayoutProps): JSX.Element {
  const [markdown, setMarkdown] = useState('');
  return (
    <Container>
      <NavHeader
        backButtonTestID={'buttonBack'}
        title={reportInstance?.title || t('screens.reportDetail')}
        RightComponent={
          !viewOnly ? (
            <SettingsButtonIconIcon
              testID={'buttonSetting'}
              onPress={() => {
                onSetting(markdown);
              }}
            />
          ) : null
        }
        onBackPress={onBack}
      />
      <TextEditor
        inputTestID={'textEditor'}
        initialMarkdownText={reportInstance?.text}
        autoFocus={autoFocus}
        saving={saving}
        disabled={viewOnly}
        allowImages={allowImages}
        maxCharCount={reportInstance?.maxChars || DEFAULT_MAX_CHARS}
        onEndEditing={onSave}
        onChangeMarkdown={(text) => setMarkdown(text)}
        minHeight={300}
      />
    </Container>
  );
}
