import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { ReportingStackParamList } from '~/navigation/types';
import Reporting from '~/screens/Admin/Reporting';

const ReportingStackNavigator = createStackNavigator<ReportingStackParamList>();

export default function ReportingStack(): JSX.Element {
  return (
    <ReportingStackNavigator.Navigator
      initialRouteName={'Reporting'}
      screenOptions={{
        headerShown: false,
      }}
    >
      <ReportingStackNavigator.Screen
        name={'Reporting'}
        component={Reporting}
      />
    </ReportingStackNavigator.Navigator>
  );
}
