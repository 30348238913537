import styled from 'styled-components/native';
import Icon from '~/components/Icon';

export const Content = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c0};
  flex-direction: vertical;
  padding: 16px;
  gap: 16px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.color.base.c3};
  border-radius: 5px;
  box-shadow: -3px 3px 2px ${({ theme }) => theme.color.base.c1};
`;

export const IconContent = styled.View`
  width: 56px;
  height: 56px;
  background-color: ${({ theme }) => theme.color.base.c2};
  border-radius: 5px;
  align-items: center;
  margin-right: 16px;
  justify-content: center;
`;

export const IconCell = styled(Icon).attrs(() => ({
  size: 32,
}))``;

export const Text = styled.Text`
  color: ${({ theme }) => theme.color.base.c10};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
  line-height: 20px;
`;
