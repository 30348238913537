import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';
import ButtonIcon from '../ButtonIcon';

interface PickerContainerProps {
  openToSide?: boolean;
}

export const Container = styled.View``;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c7};
  font-size: 16px;
  line-height: 24px;
`;

export const ButtonContainer = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})`
  margin-top: 8px;
  background-color: ${({ theme }) => theme.color.base.c0};
  border-width: 1px;
  border-color: ${({ theme }) => theme.color.base.c3};
  padding: 8px 16px;
  border-radius: 7px;
  flex-direction: row;
  align-items: center;
`;

export const Text = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 14px;
  line-height: 20px;
`;

export const ArrowButtonIcon = styled(ButtonIcon).attrs(({ theme }) => ({
  color: theme.color.base.c6,
  size: 16,
  name: 'chevron-down',
}))`
  margin-left: 8px;
`;

export const PickerContainer = styled.View<PickerContainerProps>`
  margin-top: 8px;
  background-color: ${({ theme }) => theme.color.base.c0};
  border-width: 1px;
  border-color: ${({ theme }) => theme.color.base.c3};
  border-radius: 7px;
  align-items: flex-start;

  ${({ openToSide }) =>
    /* Open the picker to side to avoid z-index problems - workaround solution */
    openToSide && Platform.OS === 'web'
      ? css`
          position: absolute;
          right: -120px;
          top: -8px;
        `
      : css``};

  z-index: 999;
`;

export const ButtonContent = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})`
  padding: 8px 16px;
`;
