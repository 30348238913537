import styled from 'styled-components/native';
import IconStyled from '~/components/IconStyled';
import { CommonTouchableOpacity } from '~/components/common/style';

export const Container = CommonTouchableOpacity`
  flex: 1;
  align-items: center;
  padding-vertical: 8px;
`;

interface ITabBar {
  isFocused: boolean;
}

export const Title = styled.Text<ITabBar>`
  margin-top: 4px;
  color: ${({ theme, isFocused }) =>
    isFocused ? theme.color.brand_02 : theme.color.base.c7};
  font-family: ${({ theme, isFocused }) =>
    isFocused ? theme.fontFamily.core.w600 : theme.fontFamily.core.w600};
  font-size: 12px;
`;

export const StyledIcon = styled(IconStyled).attrs<ITabBar>(
  ({ theme, isFocused }) => ({
    size: 24,
    color: isFocused ? theme.color.brand_02 : theme.color.base.c5,
  }),
)<ITabBar>`
  position: relative;
`;

export const TabBarBadge = styled.Text`
  background-color: red;
  color: white;
  position: absolute;
  right: -6;
  top: -6;
  width: 16px;
  height: 16px;
  text-align: center;
  border-radius: 8px;
  overflow: hidden;
  font-size: 12px;
  font-weight: 600;
`;

export const IconWrapper = styled.View``;
