import React from 'react';
import {
  getStyledLineMap,
  LineKey,
  LineStyleMap,
  TextStyleMap,
} from 'react-native-markdown-editor';
import { LineText, Text } from './style';

type MarkdownTextProps = {
  children: string;
  textStyleMap?: TextStyleMap;
  lineStyleMap?: LineStyleMap;
};

export default function MarkdownText({
  children,
  textStyleMap = {},
  lineStyleMap = {},
}: MarkdownTextProps): JSX.Element {
  const styleLineMap = getStyledLineMap(children, textStyleMap, lineStyleMap);

  const lineKeys = Object.keys(styleLineMap) as LineKey[];
  return (
    <>
      {lineKeys.map((lineKey, lineIndex) => {
        const { lineStyle, styledTexts } = styleLineMap[lineKey];
        return (
          <LineText key={lineKey} lineStyle={lineStyle}>
            {styledTexts.map(({ text, ...style }, textIndex) => (
              <Text key={`t${textIndex}`} {...style}>
                {text}
              </Text>
            ))}
            {lineIndex < lineKeys.length - 1 ? '\n' : ''}
          </LineText>
        );
      })}
    </>
  );
}
