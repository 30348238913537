import { GroupMember } from '~/data/models/admin';

interface IUsersInsideGroupHelpers {
  filterMembers(query: string, members: GroupMember[]): GroupMember[];
}

const isMatchingQuery = (field: string | null, query: string) => {
  return !!field && field.toLowerCase().includes(query.toLowerCase());
};

const UsersInsideGroupHelpers: IUsersInsideGroupHelpers = {
  filterMembers(query: string, members: GroupMember[]) {
    if (!query || query.length < 1) {
      return members;
    }

    const matchingMembers = members.filter((member) => {
      const { firstName, lastName, email, phone } = member;
      const fullName = `${firstName} ${lastName}`;
      const matchesByName = isMatchingQuery(fullName, query);
      const matchesByEmail = isMatchingQuery(email, query);
      const matchesByPhone = isMatchingQuery(phone, query);
      return matchesByName || matchesByEmail || matchesByPhone;
    });

    return matchingMembers;
  },
};

export default UsersInsideGroupHelpers;
