import React from 'react';
import { ProfilePageSuggestedQuestion } from '~/data/models/yearbook';
import { t } from '~/utils/i18n';
import {
  Container,
  EmojiContainer,
  EmojiText,
  Content,
  QuestionText,
  QuestionType,
} from './style';

type RecommendedQuestionCellProps = {
  profilePageQuestion: ProfilePageSuggestedQuestion;
  onPress: () => void;
};

//TODO setup emoji

export default function RecommendedQuestionCell({
  profilePageQuestion,
  onPress,
}: RecommendedQuestionCellProps): JSX.Element {
  const type =
    profilePageQuestion.options != null
      ? t('g.optionList')
      : t('g.freeTextAnswer');
  return (
    <Container
      testID={`RecommendedQuestionCell:${profilePageQuestion.id}`}
      onPress={onPress}
    >
      <EmojiContainer>
        <EmojiText>{''}</EmojiText>
      </EmojiContainer>
      <Content>
        <QuestionText>{profilePageQuestion.text}</QuestionText>
        <QuestionType>{type}</QuestionType>
      </Content>
    </Container>
  );
}
