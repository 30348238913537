import styled from 'styled-components/native';

interface IContainer {
  teamColor?: string;
  size: number;
  borderRadius: number;
  defaultBg?: boolean;
}
export const Container = styled.View<IContainer>`
  background-color: ${({ teamColor, defaultBg, theme }) =>
    teamColor ? teamColor : defaultBg ? theme.color.base.c4 : 'transparent'};
  width: ${({ size, theme }) => theme.ratio(size)};
  height: ${({ size, theme }) => theme.ratio(size)};
  border-radius: ${({ borderRadius }) => borderRadius}px;
  align-items: center;
  justify-content: center;
`;
