import styled from 'styled-components/native';
import Icon from '~/components/Icon';
import ImageComp from '~/components/Image';

interface IContainer {
  width: number;
}
export const Container = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})<IContainer>`
  width: ${({ width }) => width}px;
  background-color: ${({ theme }) => theme.color.base.c0};
  padding: 16px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.color.base.c3};
  border-radius: 4px;
  box-shadow: -3px 3px 2px ${({ theme }) => theme.color.base.c1};
  aspect-ratio: 1;
`;

export const Image = styled(ImageComp)`
  flex: 1;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.base.c2};
  border-width: 2px;
  margin: 0px 8px;
  border-color: ${({ theme }) => theme.color.base.c2};
`;

export const Title = styled.Text`
  color: ${({ theme }) => theme.color.base.c10};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 18px;
  margin: 8px 8px 0px 8px;
  line-height: 22px;
`;

export const Footer = styled.View`
  flex-direction: row;
  align-items: center;
  padding-top: 8px;
`;

export const ClockIcon = styled(Icon).attrs(({ theme }) => ({
  name: 'clock',
  size: 16,
  color: theme.color.base.c5,
}))`
  margin: 0px 8px;
`;

export const DueDate = styled.Text`
  flex: 1;
  color: ${({ theme }) => theme.color.base.c6};
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 12px;
  line-height: 14.5px;
`;
