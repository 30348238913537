import React from 'react';
import Shimmer from '~/components/Shimmer';
import { ProfilePagePhotoCategory } from '~/data/models/yearbook';
import { PhotoFormat } from '~/data/operations/global';
import {
  Container,
  ImageContainer,
  ImageContent,
  Image,
  EmptyImageIcon,
  Loading,
  RemoveButtonIcon,
  CategoryText,
} from './style';

type PagePhotoCategoryCardProps = {
  photoCategory?: ProfilePagePhotoCategory;
  localImgUri?: string;
  photoFormat: PhotoFormat;
  loading: boolean;
  onPickImage: (photoCategoryId: string, photoId?: string) => void;
  onRemove: (photoCategoryId: string, photoId: string) => void;
  disableRemoveButton?: boolean;
};

export default function PagePhotoCategoryCard({
  photoCategory,
  photoFormat,
  localImgUri,
  loading,
  onPickImage,
  onRemove,
  disableRemoveButton = false,
}: PagePhotoCategoryCardProps): JSX.Element {
  if (!photoCategory) {
    return (
      <Container>
        <ImageContent>
          <Shimmer w={96} h={128} br={14} g={'g2'} />
        </ImageContent>
        <CategoryText>
          <Shimmer mt={8} h={16} g={'g1'} />
        </CategoryText>
      </Container>
    );
  }

  const photo = photoCategory?.profilePagePhotos?.edges?.[0]?.node;
  const imgUri = localImgUri || photo?.image || '';
  return (
    <Container
      testID={`PagePhotoCategoryCard:${photoCategory.id}`}
      onPress={() =>
        onPickImage(
          photoCategory.id,
          photoCategory.profilePagePhotos?.edges?.[0]?.node?.id,
        )
      }
    >
      <ImageContainer>
        <ImageContent>
          <Image
            testID={`PagePhotoCategoryCardImage:${photoCategory.id}/${imgUri}`}
            source={{ uri: imgUri }}
            photoFormat={photoFormat}
          >
            {!imgUri ? <EmptyImageIcon /> : null}
            {loading ? (
              <Loading
                testID={`PagePhotoCategoryCardLoading:${photoCategory.id}`}
              />
            ) : null}
          </Image>
          {photo && !disableRemoveButton ? (
            <RemoveButtonIcon
              onPress={() => onRemove(photoCategory.id, photo.id)}
            />
          ) : null}
        </ImageContent>
      </ImageContainer>
      <CategoryText>{photoCategory.name}</CategoryText>
    </Container>
  );
}
