import styled from 'styled-components/native';
import { ModalScreenContainer } from '~/screens/style';

export const Container = styled(ModalScreenContainer)`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const FlatList = styled.FlatList``;

export const InputContainer = styled.View`
  margin-top: 8px;
  margin-bottom: 32px;
  padding-horizontal: 16px;
`;

export const SectionContainer = styled.View`
  padding: 16px;
`;

export const Footer = styled.View`
  padding: 16px;
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c6,
}))`
  padding-bottom: 12px;
`;
