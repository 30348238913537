/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation EditEvent
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { EditEvent, EditEventVariables } from './types/EditEvent';
import { EDIT_EVENT } from './';

export function useEditEventMutation(
  options?: MutationHookOptions<EditEvent, EditEventVariables>,
): MutationTuple<EditEvent, EditEventVariables> {
  return useMutation<EditEvent, EditEventVariables>(EDIT_EVENT, options);
}

export function createEditEventMock({
  variables,
  data,
  error,
}: {
  variables?: EditEventVariables;
  data?: EditEvent;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: EDIT_EVENT,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
