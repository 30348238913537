/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation UpdateCustomPagesInstance
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  UpdateCustomPagesInstance,
  UpdateCustomPagesInstanceVariables,
} from './types/UpdateCustomPagesInstance';
import { UPDATE_CUSTOM_PAGES_INSTANCE } from './';

export function useUpdateCustomPagesInstanceMutation(
  options?: MutationHookOptions<
    UpdateCustomPagesInstance,
    UpdateCustomPagesInstanceVariables
  >,
): MutationTuple<
  UpdateCustomPagesInstance,
  UpdateCustomPagesInstanceVariables
> {
  return useMutation<
    UpdateCustomPagesInstance,
    UpdateCustomPagesInstanceVariables
  >(UPDATE_CUSTOM_PAGES_INSTANCE, options);
}

export function createUpdateCustomPagesInstanceMock({
  variables,
  data,
  error,
}: {
  variables?: UpdateCustomPagesInstanceVariables;
  data?: UpdateCustomPagesInstance;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: UPDATE_CUSTOM_PAGES_INSTANCE,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
