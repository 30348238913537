/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query CustomListOptions
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  CustomListOptions,
  CustomListOptionsVariables,
} from './types/CustomListOptions';
import { CUSTOM_LIST_OPTIONS } from './';

export function useCustomListOptionsQuery(
  options?: QueryHookOptions<CustomListOptions, CustomListOptionsVariables>,
): QueryResult<CustomListOptions, CustomListOptionsVariables> {
  return useQuery<CustomListOptions, CustomListOptionsVariables>(
    CUSTOM_LIST_OPTIONS,
    options,
  );
}

export function useCustomListOptionsLazyQuery(
  options?: LazyQueryHookOptions<CustomListOptions, CustomListOptionsVariables>,
): QueryTuple<CustomListOptions, CustomListOptionsVariables> {
  return useLazyQuery<CustomListOptions, CustomListOptionsVariables>(
    CUSTOM_LIST_OPTIONS,
    options,
  );
}

export function createCustomListOptionsMock({
  variables,
  data,
  error,
}: {
  variables?: CustomListOptionsVariables;
  data?: CustomListOptions;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: CUSTOM_LIST_OPTIONS,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
