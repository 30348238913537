import React, { useEffect, useRef, useState } from 'react';
import AuthorPicker, {
  AuthorCommonType,
  AuthorTeamType,
} from '~/components/AuthorPicker';
import Button from '~/components/Button';
import CommonIconCell, {
  CommonIconCellHandle,
} from '~/components/CommonIconCell';
import SectionHeader from '~/components/SectionHeader';
import { Team } from '~/data/models/team';
import {
  ModuleInstance,
  RankingQuestion,
  RankingsSetupConfig,
} from '~/data/models/yearbook';
import { t } from '~/utils/i18n';
import ModuleSetupLayout, {
  UpdateModuleInstanceInput,
} from '../../layout/ModuleSetupLayout';
import {
  QuestionsWrapper,
  SectionButtonWrapper,
  QuestionsFooter,
  Loading,
} from './style';

export type UpdateRankingSetupInput = {
  resultsTeamId: string | null;
};

export type RankingsSetupLayoutProps = {
  moduleInstance: ModuleInstance;
  rankingSetupConfig: RankingsSetupConfig;
  rankingQuestions: RankingQuestion[];
  rankingQuestionsLoading: boolean;
  teams: Team[];
  onBack: () => void;
  onUpdateModuleInstance: (
    updateModuleInstanceInput: UpdateModuleInstanceInput,
  ) => void;
  onUpdateRankingSetup: (
    pdateRankingSetupInput: UpdateRankingSetupInput,
  ) => void;
  onAddQuestion: () => void;
  onUpdateQuestion: (rankingQuestionId: string) => void;
  onDeleteQuestion: (rankingQuestionId: string) => void;
  onLoadMoreQuestions: () => void;
  onLoadMoreTeams: () => void;
};

export default function RankingsSetup({
  moduleInstance,
  rankingSetupConfig,
  rankingQuestions,
  rankingQuestionsLoading,
  teams,
  onBack,
  onUpdateModuleInstance,
  onUpdateRankingSetup,
  onAddQuestion,
  onUpdateQuestion,
  onDeleteQuestion,
  onLoadMoreQuestions,
  onLoadMoreTeams,
}: RankingsSetupLayoutProps): JSX.Element {
  const commonIconCellsRef = useRef<(CommonIconCellHandle | null)[]>([]);

  const getInitialResultsTeamId = () =>
    rankingSetupConfig.resultsTeam?.id || null;

  const [resultsTeamId, setResultsTeamId] = useState(getInitialResultsTeamId());

  const authorEverybody: AuthorCommonType = {
    id: 'all',
    authorType: 'common',
    info: {
      description: t('g.everybody'),
      styledIcon: 'everyone',
    },
  };

  const teamAuthors: AuthorTeamType[] = teams.map((team) => {
    return {
      id: team.id,
      authorType: 'team',
      team,
    };
  });

  const authors = [authorEverybody, ...teamAuthors];

  useEffect(() => {
    const updatedResultTeam =
      rankingSetupConfig && resultsTeamId != getInitialResultsTeamId()
        ? true
        : false;

    if (updatedResultTeam) {
      onUpdateRankingSetup({
        resultsTeamId:
          resultsTeamId === authorEverybody.id ? null : resultsTeamId,
      });
    }
  }, [resultsTeamId]);

  return (
    <ModuleSetupLayout
      title={t('screens.rankingsSetup')}
      activateText={t('rankings.setupActivate')}
      moduleInstance={moduleInstance}
      onBack={onBack}
      onUpdateModuleInstance={onUpdateModuleInstance}
      onEndReached={() => !rankingQuestionsLoading && onLoadMoreQuestions()}
    >
      <>
        <SectionHeader sub title={t('rankings.setupAddQuestionsHeader')} />
        <QuestionsWrapper>
          {rankingQuestions.map((rankingQuestion, i) => {
            return (
              <CommonIconCell
                testID={`CommonIconCell:${rankingQuestion.id}`}
                ref={(el) => (commonIconCellsRef.current[i] = el)}
                key={rankingQuestion.id}
                info={{ text: rankingQuestion.question }}
                swipeable
                onPress={() => onUpdateQuestion(rankingQuestion.id)}
                onDelete={() => {
                  commonIconCellsRef?.current?.[i]?.collapse();
                  onDeleteQuestion(rankingQuestion.id);
                }}
              />
            );
          })}
          {rankingQuestionsLoading ? (
            <QuestionsFooter>
              <Loading />
            </QuestionsFooter>
          ) : null}
          <SectionButtonWrapper>
            <Button
              testID={'addQuestionButton'}
              text={t('rankings.setupAddQuestionsButton')}
              icon={'left'}
              iconName={'plus'}
              size={'sm'}
              type={'secondary-base'}
              flex
              onPress={onAddQuestion}
            />
          </SectionButtonWrapper>
        </QuestionsWrapper>
        <SectionHeader sub title={t('rankings.setupVisibilityHeader')} />
        <AuthorPicker
          testID={'createResultTeamAuthorPicker'}
          authors={authors}
          selectedAuthorId={resultsTeamId || authorEverybody.id}
          onSelectedAuthor={(authorId) => setResultsTeamId(authorId)}
          onLoadMore={onLoadMoreTeams}
        />
      </>
    </ModuleSetupLayout>
  );
}
