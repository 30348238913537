/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation UpdateRankingsAnswer
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  UpdateRankingsAnswer,
  UpdateRankingsAnswerVariables,
} from './types/UpdateRankingsAnswer';
import { UPDATE_RANKINGS_ANSWER } from './';

export function useUpdateRankingsAnswerMutation(
  options?: MutationHookOptions<
    UpdateRankingsAnswer,
    UpdateRankingsAnswerVariables
  >,
): MutationTuple<UpdateRankingsAnswer, UpdateRankingsAnswerVariables> {
  return useMutation<UpdateRankingsAnswer, UpdateRankingsAnswerVariables>(
    UPDATE_RANKINGS_ANSWER,
    options,
  );
}

export function createUpdateRankingsAnswerMock({
  variables,
  data,
  error,
}: {
  variables?: UpdateRankingsAnswerVariables;
  data?: UpdateRankingsAnswer;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: UPDATE_RANKINGS_ANSWER,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
