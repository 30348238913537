import color from 'gradoo-theme/lib/color';
import { ViewStyle } from 'react-native';
import styled, { css } from 'styled-components/native';
import ButtonIcon from '~/components/ButtonIcon';
import Icon from '~/components/Icon';
import IconStyled from '~/components/IconStyled/index';

const DRAWER_GRADIENT_BG = {
  background: `linear-gradient(176.29deg, ${color.brand_02} 17.12%, ${color.brand_01} 102.44%)`,
};

export const DESKTOP_DRAWER_STYLE: ViewStyle = {
  width: 220,
  borderWidth: 0,
  ...DRAWER_GRADIENT_BG,
};

export const MOBILE_DRAWER_STYLE: ViewStyle = {
  width: '100%',
  ...DRAWER_GRADIENT_BG,
};

export const Logo = styled.Text`
  flex: 1;
  margin-top: 44px;
  margin-bottom: 92px;
  text-align: center;
  color: ${({ theme }) => theme.color.base.c0};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 32px;
`;

export const DrawerItem = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})<IDrawer>`
  position: relative;
  flex-direction: row;
  align-items: center;
  padding-vertical: 8px;
  height: 54px;
  margin-bottom: 40px;
  ${({ isActive, theme }) =>
    isActive &&
    css`
      background-color: ${theme.color.brand_02};
    `}
`;

export const FocusedItemIndicator = styled.View`
  position: absolute;
  width: 8px;
  height: 54px;
  background: ${({ theme }) => theme.color.brand_02};
`;

interface IDrawer {
  isActive: boolean;
}

export const DrawerIcon = styled(Icon).attrs(({ theme }) => ({
  size: 24,
  color: theme.color.base.c0,
}))`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.large};
    margin-right: ${theme.spacing.medium};
  `}
`;

export const StyledIcon = styled(IconStyled).attrs(({ theme }) => ({
  size: 24,
  color: theme.color.base.c0,
}))``;

export const DrawerLabel = styled.Text`
  color: ${({ theme }) => theme.color.base.c0};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 22px;
`;

export const CloseButtonIcon = styled(ButtonIcon).attrs(({ theme }) => ({
  name: 'x-close',
  size: 16,
  color: theme.color.base.c3,
}))`
  background-color: ${({ theme }) => theme.color.brand_02};
  align-self: flex-end;
  margin-top: ${({ theme }) => theme.spacing.medium};
  margin-right: ${({ theme }) => theme.spacing.medium};
  width: 32px;
  height: 32px;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
`;
