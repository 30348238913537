import React, { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Team } from '~/data/models/team';
import { ToDoList } from '~/data/models/todo';
import { PlanningStackParamList } from '~/navigation/types';
import { t } from '~/utils/i18n';
import ToDoListsLayout from './layout';

type ToDoListNavProp = StackNavigationProp<PlanningStackParamList, 'ToDoLists'>;

export default function ToDoLists(): JSX.Element {
  const navigation = useNavigation<ToDoListNavProp>();
  const listOptions = [
    {
      key: 'allList',
      title: t('toDoList.allList'),
    },
    {
      key: 'teamsList',
      title: t('toDoList.teamsList'),
    },
  ];

  const [selectedTab, setSelectedTab] = useState('allList');
  const [items, setItems] = useState<ToDoList[]>([]);

  const data: ToDoList[] = []; // TODO: query API to fetch all ToDo items
  const userTeams: Team[] = []; // TODO: we might not need this, but it will depend on the API implementation
  const userTeamsIds: string[] = userTeams.map((team) => team.id);

  useEffect(() => {
    switch (selectedTab) {
      case 'allList':
        setItems(
          data.filter((item) => userTeamsIds.indexOf(item.team.id) == -1),
        ); // TODO: filter data to show only items from teams to whom the user belongs to
        break;
      default:
        setItems(data);
    }
  }, [selectedTab]);

  return (
    <ToDoListsLayout
      items={items}
      tabOptions={listOptions}
      currentTab={selectedTab}
      onTabPressed={setSelectedTab}
      onListSelected={(listId) => navigation.navigate('ToDoTasks', { listId })}
      onBack={() => navigation.goBack()}
      onNotification={() => console.log('onNotification')} //eslint-disable-line
    />
  );
}
