import styled from 'styled-components/native';
import Image from '~/components/Image';
import ImageComp from '~/components/Image';

const COLOR_BOX_HEIGHT_MOBILE = 144;

export const Content = styled.View``;

export const ScrollView = styled.ScrollView`
  flex: 1;
  padding-horizontal: ${({ theme }) => theme.spacing.medium};
`;

export const InfoContent = styled.View`
  align-items: center;
  padding: ${({ theme }) => theme.spacing.medium};
`;

export const NoGroupContent = styled.View`
  padding: ${({ theme }) => theme.spacing.medium};
  margin-bottom: 36px;
`;

export const NoGroupImage = styled(Image)`
  width: 200px;
  height: 100px;
`;
export const NoGroupTitle = styled.Text`
  margin-top: 8px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c10};
  font-size: 32px;
  line-height: 38px;
`;

export const NoGroupDescp = styled.Text`
  margin-top: 16px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c10};
  font-size: 18px;
  line-height: 23px;
`;

export const SectionTitle = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 16px;
  line-height: 20px;
  padding-top: 32px;
  padding-bottom: 16px;
`;

export const Footer = styled.View`
  padding-horizontal: ${({ theme }) => theme.spacing.medium};
  padding-bottom: ${({ theme }) => theme.spacing.medium};
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c10,
}))``;

export const ErrorMessage = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.error};
  font-size: 18px;
  line-height: 23px;
`;

export const RefreshControl = styled.RefreshControl.attrs(({ theme }) => ({
  tintColor: theme.color.base.c10,
  colors: [theme.color.base.c10],
}))``;

// -----

export const ColorBox = styled.View`
  position: absolute;
  background-color: ${({ theme }) => theme.color.brand_01};
  z-index: -1;
  top: 0px;
  left: 0px;
  right: 0px;
  height: ${({ theme }) =>
    COLOR_BOX_HEIGHT_MOBILE + theme.safeAreaInsets.top}px;
`;

export const RequestTitle = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.brand_02};
  font-size: 32px;
  line-height: 40px;
  margin-top: 32px;
  margin-bottom: 24px;
  padding-horizontal: ${({ theme }) => theme.spacing.medium};
`;

export const HeartsImage = styled(ImageComp)`
  width: 175px;
  height: 150px;
  margin-top: 50px;
`;

export const UserName = styled.Text`
  font-size: 40px;
  color: ${({ theme }) => theme.color.brand_02};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
`;

export const NoGroupContainer = styled.View`
  padding: 91px 0 40px 0;
`;

export const NoGroupsTitle = styled.Text`
  font-size: 32px;
  color: ${({ theme }) => theme.color.base.c10};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
`;

export const NoGroupsDescp = styled.Text`
  font-size: 18px;
  color: ${({ theme }) => theme.color.base.c6};
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
`;
