/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query UserTeam
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { UserTeam, UserTeamVariables } from './types/UserTeam';
import { USER_TEAM } from './';

export function useUserTeamQuery(
  options?: QueryHookOptions<UserTeam, UserTeamVariables>,
): QueryResult<UserTeam, UserTeamVariables> {
  return useQuery<UserTeam, UserTeamVariables>(USER_TEAM, options);
}

export function useUserTeamLazyQuery(
  options?: LazyQueryHookOptions<UserTeam, UserTeamVariables>,
): QueryTuple<UserTeam, UserTeamVariables> {
  return useLazyQuery<UserTeam, UserTeamVariables>(USER_TEAM, options);
}

export function createUserTeamMock({
  variables,
  data,
  error,
}: {
  variables?: UserTeamVariables;
  data?: UserTeam;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: USER_TEAM,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
