import React from 'react';
import AdminHeader from '~/components/AdminHeader';
import InstituteDatabase from '~/components/InstituteDatabase';
import InstituteRequestsPanel from '~/components/InstituteRequestsPanel';
import { Spinner, VSpacer } from '~/components/common/style.web';
import { Institute } from '~/data/models/institute';
import { InstituteRequest } from '~/data/models/instituteRequest';
import { t } from '~/utils/i18n';
import { Container, InstitutesWebContainer } from './style';

type InstitutesLayoutProps = {
  onCheckInstituteRequestPress: (id: string) => void;
  onAddInstituteRequestPress: () => void;
  instituteRequests: InstituteRequest[];
  institutes: Institute[];
  institutesLoading?: boolean;
  requestsLoading?: boolean;
  institutesTotalCount: number;
  setCurrentActiveTab: (tab: number) => void;
  openDrawer: () => void;
  onOpenInstitutesGroup: (id: string) => void;
  setFilterQuery: (value: string) => void;
  filterQuery: string;
  rowsPerPage: number;
  setRowsPerPage: (value: number) => void;
};

export default function Institutes({
  instituteRequests,
  institutes,
  onCheckInstituteRequestPress,
  onAddInstituteRequestPress,
  openDrawer,
  institutesLoading = false,
  requestsLoading = false,
  onOpenInstitutesGroup,
  setCurrentActiveTab,
  institutesTotalCount,
  setFilterQuery,
  filterQuery,
  rowsPerPage,
  setRowsPerPage,
}: InstitutesLayoutProps): JSX.Element {
  return (
    <Container>
      <AdminHeader
        onOpenDrawer={openDrawer}
        title={t('screens.Institutes')}
        onBack={() => null}
        breadcrumbs={['Institutes']}
        buttonTitle={t('adminPanelSearch.saveChanges')}
      />
      <InstitutesWebContainer>
        <VSpacer />
        {requestsLoading ? (
          <Spinner testID="requestsSpinner" />
        ) : (
          <InstituteRequestsPanel
            onCheckInstituteRequestPress={onCheckInstituteRequestPress}
            instituteRequests={instituteRequests}
            onAddInstituteRequestPress={onAddInstituteRequestPress}
          />
        )}
        <VSpacer />
        <InstituteDatabase
          institutes={institutes}
          institutesLoading={institutesLoading}
          onOpenInstitutesGroup={onOpenInstitutesGroup}
          institutesTotalCount={institutesTotalCount}
          setCurrentActiveTab={setCurrentActiveTab}
          setFilterQuery={setFilterQuery}
          filterQuery={filterQuery}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </InstitutesWebContainer>
    </Container>
  );
}
