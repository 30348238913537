/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation EditBannerAsset
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  EditBannerAsset,
  EditBannerAssetVariables,
} from './types/EditBannerAsset';
import { EDIT_BANNER_ASSET } from './';

export function useEditBannerAssetMutation(
  options?: MutationHookOptions<EditBannerAsset, EditBannerAssetVariables>,
): MutationTuple<EditBannerAsset, EditBannerAssetVariables> {
  return useMutation<EditBannerAsset, EditBannerAssetVariables>(
    EDIT_BANNER_ASSET,
    options,
  );
}

export function createEditBannerAssetMock({
  variables,
  data,
  error,
}: {
  variables?: EditBannerAssetVariables;
  data?: EditBannerAsset;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: EDIT_BANNER_ASSET,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
