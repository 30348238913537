import styled from 'styled-components/native';
import { StackScreenContainer } from '~/screens/style';

export const Container = styled(StackScreenContainer).attrs({
  edges: ['right', 'left', 'bottom'],
})`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const PageList = styled.FlatList.attrs(({ theme }) => ({
  decelerationRate: 0,
  snapToInterval: theme.dimensions.screenWidth,
  snapToAlignment: 'center',
}))``;

export const Footer = styled.View`
  margin-bottom: ${({ theme }) => theme.safeAreaInsets.bottom}px;
`;
