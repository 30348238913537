import styled from 'styled-components/native';

export const InputContent = styled.View`
  padding-vertical: ${({ theme }) => theme.spacing.medium};
`;

export const CityList = styled.FlatList`
  padding-top: ${({ theme }) => theme.spacing.small};
  padding-horizontal: ${({ theme }) => theme.spacing.medium};
`;

export const Footer = styled.View`
  padding-vertical: ${({ theme }) => theme.spacing.medium};
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c9,
}))`
  padding-top: ${({ theme }) => theme.spacing.medium};
`;

export const EmptyText = styled.Text`
  margin-vertical: ${({ theme }) => theme.spacing.medium};
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 14px;
`;
