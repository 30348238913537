import React from 'react';
import { Platform, View } from 'react-native';
import { general } from '~/assets/imgs';
import { AvatarProps } from '~/components/Avatar';
import AvatarStackedRow from '~/components/AvatarStackedRow';
import Button from '~/components/Button';
import NavHeader from '~/components/NavHeader';
import { GroupSample } from '~/data/models/group';
import AuthWebLayout from '~/screens/Auth/layout/AuthLayout/AuthWebLayout';
import { LanguageProps } from '~/screens/Auth/layout/AuthLayout/types';
import { StackScreenContainer } from '~/screens/style';
import { formattedDate } from '~/utils/dates';
import { t } from '~/utils/i18n';
import {
  ScrollView,
  Title,
  Descp,
  Image,
  SectionTitle,
  SectionValue,
  Loading,
  ErrorText,
  Footer,
  Content,
  RowSpaceBetween,
  ColorBox,
  Container,
  ImageWrapper,
  SmallImage,
  WebTitleSection,
  WebImageSection,
  GroupStatsSection,
  GroupStats,
  GroupStatsTitle,
  GroupStatsValue,
  WebAvatarsSection,
  CenterContent,
} from './style';

export type InviteLayoutProps = {
  isAuthenticated: boolean;
  groupSample?: GroupSample | null;
  loading: boolean;
  joinLoading: boolean;
  errorMessage?: string;
  onBack: () => void;
  onLogIn: () => void;
  onSignUp: () => void;
  onLogout: () => void;
  onJoin: () => void;
  onDecline: () => void;
  onRetry: () => void;
} & LanguageProps;

export default function Invite({
  isAuthenticated,
  groupSample,
  loading,
  joinLoading,
  errorMessage,
  selectedLocale,
  onBack,
  onLogIn,
  onSignUp,
  onJoin,
  onDecline,
  onRetry,
  onLogout,
  onSelectedLocale,
}: InviteLayoutProps): JSX.Element {
  const isWeb = Platform.OS === 'web';

  const usersCount = groupSample?.usersCount || 0;
  const userAvatars: AvatarProps[] =
    groupSample?.sampleMembers?.map((member) => ({
      userGroup: member,
    })) || [];

  const FooterComponent = isAuthenticated ? (
    <>
      <Button
        testID={'buttonJoin'}
        size="2xl"
        flex
        text={t('invite.join')}
        type="primary-brand-02"
        bottomMargin={isWeb ? 16 : 7}
        onPress={onJoin}
        loading={joinLoading}
      />
      <Button
        testID={'buttonDecline'}
        text={t('invite.decline')}
        size="2xl"
        flex
        type="secondary-contrast"
        onPress={onDecline}
      />
    </>
  ) : (
    <>
      <Button
        testID={'buttonLogIn'}
        text={t('invite.login')}
        size="2xl"
        flex
        type="primary-brand-02"
        bottomMargin={isWeb ? 16 : 7}
        onPress={onLogIn}
      />
      <Button
        size="2xl"
        flex
        testID={'buttonSignUp'}
        text={t('invite.signup')}
        type={'secondary-contrast'}
        onPress={onSignUp}
      />
    </>
  );
  return (
    <Container>
      <StackScreenContainer>
        {!isWeb ? (
          <>
            {(() => {
              if (loading) {
                return (
                  <CenterContent>
                    <Loading testID="loading" />
                  </CenterContent>
                );
              }
              if (errorMessage) {
                return (
                  <CenterContent>
                    <ErrorText>{errorMessage}</ErrorText>
                    <Button
                      testID={'buttonRetry'}
                      type={'secondary-base'}
                      text={t('retry')}
                      onPress={onRetry}
                    />
                  </CenterContent>
                );
              }
              return (
                <>
                  <ColorBox />
                  <NavHeader
                    backButtonTestID={'buttonBack'}
                    colorStyle={'light'}
                    onBackPress={onBack}
                    RightComponent={
                      isAuthenticated && (
                        <Button
                          testID="buttonLogout"
                          size="sm"
                          text={t('g.logout')}
                          type="secondary-base"
                          onPress={onLogout}
                        />
                      )
                    }
                  />
                  <ScrollView>
                    <Content>
                      <ImageWrapper>
                        <Image
                          source={{
                            uri:
                              groupSample?.avatar ||
                              groupSample?.icon?.icon ||
                              undefined,
                          }}
                        />
                        <SmallImage source={general.double_hearts} />
                      </ImageWrapper>
                      <Title>
                        {groupSample?.name || ''} {groupSample?.year || ''}
                      </Title>
                      <Descp>{t('invite.descp')} </Descp>
                      <RowSpaceBetween>
                        <View>
                          <SectionTitle>{t('invite.sectionYear')}</SectionTitle>
                          <SectionValue>
                            {formattedDate(groupSample?.created)}
                          </SectionValue>
                        </View>
                        <View>
                          <SectionTitle>
                            {t('invite.sectionMembers')}
                          </SectionTitle>
                          <SectionValue>{usersCount}</SectionValue>
                        </View>
                      </RowSpaceBetween>
                      <WebAvatarsSection>
                        <AvatarStackedRow avatars={userAvatars} size={48} />
                      </WebAvatarsSection>
                    </Content>
                    <Footer>{FooterComponent}</Footer>
                  </ScrollView>
                </>
              );
            })()}
          </>
        ) : (
          <AuthWebLayout
            languageProps={{ selectedLocale, onSelectedLocale }}
            isContentCentered={!!loading || !!errorMessage}
            HeaderRightComponent={
              isAuthenticated ? (
                <Button
                  testID="buttonLogout"
                  size="sm"
                  text={t('g.logout')}
                  type="secondary-base"
                  onPress={onLogout}
                />
              ) : undefined
            }
          >
            {(() => {
              if (loading) {
                return (
                  <CenterContent>
                    <Loading testID="loading" />
                  </CenterContent>
                );
              }
              if (errorMessage) {
                return (
                  <CenterContent>
                    <ErrorText>{errorMessage}</ErrorText>
                    <Button
                      testID={'buttonRetry'}
                      type={'secondary-base'}
                      text={t('retry')}
                      onPress={onRetry}
                    />
                  </CenterContent>
                );
              }

              return (
                <>
                  <WebImageSection>
                    <ImageWrapper>
                      <Image
                        source={{
                          uri:
                            groupSample?.avatar ||
                            groupSample?.icon?.icon ||
                            undefined,
                        }}
                        noMargin
                      />
                      <SmallImage source={general.double_hearts} />
                    </ImageWrapper>
                  </WebImageSection>
                  <WebTitleSection>
                    <Title align="left">{groupSample?.name || ''}</Title>
                    <Descp align="left">{t('invite.descp')}</Descp>
                  </WebTitleSection>
                  <GroupStatsSection>
                    <GroupStats>
                      <GroupStatsTitle>
                        {t('invite.sectionYear')}
                      </GroupStatsTitle>
                      <GroupStatsValue>
                        {formattedDate(groupSample?.created)}
                      </GroupStatsValue>
                    </GroupStats>
                    <GroupStats>
                      <GroupStatsTitle>
                        {t('invite.sectionMembers')}
                      </GroupStatsTitle>
                      <GroupStatsValue>{usersCount}</GroupStatsValue>
                    </GroupStats>
                  </GroupStatsSection>
                  <WebAvatarsSection>
                    <AvatarStackedRow avatars={userAvatars} size={59} />
                  </WebAvatarsSection>
                  {FooterComponent}
                </>
              );
            })()}
          </AuthWebLayout>
        )}
      </StackScreenContainer>
    </Container>
  );
}
