import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
} from 'react';
import { Animated } from 'react-native';
import { IconName } from '~/components/Icon';
import IconStyled, { IconStyledName } from '~/components/IconStyled';
import { t } from '~/utils/i18n';
import Badge from '../Badge';
import {
  Container,
  AnimatedSwipeableContainer,
  DeleteButton,
  DeleteIcon,
  DeleteText,
  Content,
  IconContent,
  ImageContent,
  CellIcon,
  Text,
  ArrowIcon,
  CELL_CONTAINER_ICON_HEIGHT,
  CELL_CONTAINER_NO_ICON_HEIGHT,
  SWIPEABLE_BUTTON_WIDTH,
  TextWrapper,
  Desc,
  MainCellIcon,
  CELL_DESCRIPTION_HEIGHT,
} from './style';

// TODO: delete styledIcon prop and use mainIcon with V2. Keeping styledIcon for now for backup purposes.
// When the process is done, remove styledIcon prop and re-organize the stories.

export type CommonIconCellInfo = {
  text: string;
  description?: string;
  icon?: IconName;
  styledIcon?: IconStyledName;
  mainIcon?: IconName;
  img?: string;
  disabled?: boolean;
  disabledText?: string;
  hideArrowIcon?: boolean;
};

export type CommonIconCellProps = {
  testID?: string;
  info: CommonIconCellInfo;
  swipeable?: boolean;
  onPress?: () => void;
  onLongPress?: () => void;
  onDelete?: () => void;
};

export type CommonIconCellHandle = {
  collapse: () => void;
};

const CommonIconCell: ForwardRefRenderFunction<
  CommonIconCellHandle,
  CommonIconCellProps
> = (
  { testID, info, swipeable = false, onPress, onLongPress, onDelete },
  ref,
) => {
  const {
    disabled,
    disabledText = t('g.soon'),
    hideArrowIcon,
    description,
  } = info;

  const [animated] = useState(new Animated.Value(0));
  const anyIconOrImg =
    info.icon || info.styledIcon || info.mainIcon || info.img;

  const deleteAnimation = {
    height: animated.interpolate({
      inputRange: [0, 1],
      outputRange: [
        anyIconOrImg
          ? CELL_CONTAINER_ICON_HEIGHT
          : info.description
          ? CELL_CONTAINER_NO_ICON_HEIGHT + CELL_DESCRIPTION_HEIGHT
          : CELL_CONTAINER_NO_ICON_HEIGHT,
        0,
      ],
      extrapolate: 'clamp',
    }),
  };

  function collapse() {
    Animated.timing(animated, {
      toValue: 1,
      duration: 250,
      useNativeDriver: false,
    }).start();
  }

  useImperativeHandle(ref, () => ({
    collapse: () => collapse(),
  }));

  const renderContent = () => {
    return (
      <Content
        disabled={disabled || !onPress}
        onPress={onPress}
        onLongPress={onLongPress}
        hasIcon={!!info.styledIcon}
        swipeable={swipeable}
      >
        {(() => {
          if (info.icon) {
            return (
              <IconContent>
                <CellIcon name={info.icon} />
              </IconContent>
            );
          }
          if (info.styledIcon && !info.mainIcon) {
            return (
              <IconContent>
                <IconStyled name={info.styledIcon} />
              </IconContent>
            );
          }
          if (info.mainIcon) {
            return (
              <IconContent>
                <MainCellIcon name={info.mainIcon} />
              </IconContent>
            );
          }
          if (info.img) {
            return <ImageContent source={{ uri: info.img }} />;
          }
          return null;
        })()}
        <TextWrapper>
          <Text disabled={disabled}>{info.text}</Text>
          {description && <Desc disabled={disabled}>{description}</Desc>}
        </TextWrapper>
        {disabled ? (
          <Badge text={disabledText} colorTheme={'darkGray'} size={'large'} />
        ) : !hideArrowIcon ? (
          <ArrowIcon />
        ) : null}
      </Content>
    );
  };

  if (swipeable) {
    return (
      <Container testID={testID}>
        <AnimatedSwipeableContainer
          style={deleteAnimation}
          rightButtonWidth={SWIPEABLE_BUTTON_WIDTH}
          rightButtons={[
            <DeleteButton key={'deleteButton'} onPress={onDelete}>
              <DeleteIcon />
              <DeleteText>{t('g.delete')}</DeleteText>
            </DeleteButton>,
          ]}
        >
          {renderContent()}
        </AnimatedSwipeableContainer>
      </Container>
    );
  }
  return <Container testID={testID}>{renderContent()}</Container>;
};

export default forwardRef(CommonIconCell);
