import { gql } from '@apollo/client';

//TEAM_COLOR
export const COLOR_FIELDS = gql`
  fragment ColorFields on ColorNode {
    id
    hex
  }
`;

//TEAM_ICON
export const ICON_FIELDS = gql`
  fragment IconFields on IconNode {
    id
    icon
  }
`;

//USER_AVATARS
export const DEFAULT_AVATAR_FIELDS = gql`
  fragment DefaultAvatarFields on DefaultAvatarNode {
    id
    avatar
  }
`;

//GROUP_ICONS
export const GROUP_ICON_FIELDS = gql`
  fragment GroupIconFields on GroupIconNode {
    id
    icon
  }
`;

export const COLORS = gql`
  ${COLOR_FIELDS}
  query Colors($after: String, $first: Int!) {
    colors(after: $after, first: $first) {
      edges {
        node {
          ...ColorFields
        }
      }
    }
  }
`;

export const ICONS = gql`
  ${ICON_FIELDS}
  query Icons($after: String, $first: Int!) {
    icons(after: $after, first: $first) {
      edges {
        node {
          ...IconFields
        }
      }
    }
  }
`;

export const DEFAULT_AVATARS = gql`
  ${DEFAULT_AVATAR_FIELDS}
  query DefaultAvatars($after: String, $first: Int!) {
    defaultAvatars(after: $after, first: $first) {
      edges {
        node {
          ...DefaultAvatarFields
        }
      }
    }
  }
`;

export const GROUP_ICONS = gql`
  ${GROUP_ICON_FIELDS}
  query GroupIcons($after: String, $first: Int!) {
    groupIcons(after: $after, first: $first) {
      edges {
        node {
          ...GroupIconFields
        }
      }
    }
  }
`;
