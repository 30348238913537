import React, {
  forwardRef,
  ForwardRefRenderFunction,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import mapboxgl, { GeoJSONSource, LngLatLike, Map } from 'mapbox-gl';
import { View } from 'react-native';
import { useTheme } from 'styled-components/native';
import { CENTER_GERMANY_AND_TURKEY, DEFAULT_ZOOM } from '~/data/constants';
import {
  DEFAULT_MATCHER,
  MapBoxWrapperHandle,
  MapBoxWrapperProps,
} from './models';
import { Container } from './style';

const MapBoxWrapper: ForwardRefRenderFunction<
  MapBoxWrapperHandle,
  MapBoxWrapperProps
> = (
  {
    center = CENTER_GERMANY_AND_TURKEY,
    zoom = DEFAULT_ZOOM,
    iconMapper = DEFAULT_MATCHER,
    clusterData,
    isDesktop = true,
  },
  ref,
) => {
  const container = useRef(ref);
  const theme = useTheme();
  const map = useRef<Map | null>(null);
  const clusterDataCache = useRef<string>();
  useImperativeHandle(ref, () => ({
    recenter: (center: LngLatLike, zoom: number) => {
      map.current?.flyTo({
        zoom: zoom,
        center: center,
      });
    },
  }));

  useEffect(() => {
    if (map.current) {
      return;
    }
    map.current = new mapboxgl.Map({
      accessToken: process.env.MAPBOX_ACCESS_TOKEN,
      container: container.current as unknown as HTMLElement,
      style: 'mapbox://styles/mapbox/light-v11',
      center: center ?? CENTER_GERMANY_AND_TURKEY,
      zoom: zoom ?? DEFAULT_ZOOM,
      attributionControl: false,
      localFontFamily: 'Inter500',
    });
    map.current?.on('style.load', () => {
      renderClustersOnMap();
    });

    clusterDataCache.current = JSON.stringify(clusterData);
    // Cleanup
    return () => {
      // if (map.current) {
      //   map.current.remove();
      // }
    };
  });

  useEffect(() => {
    if (!clusterData) {
      return;
    }
    (map.current?.getSource('clusterData') as GeoJSONSource)?.setData(
      clusterData,
    );
  }, [clusterData]);

  const renderClustersOnMap = (): void => {
    map.current?.addSource('clusterData', {
      type: 'geojson',
      data: clusterData,
      cluster: true,
      clusterMaxZoom: 14, // Max zoom to cluster points on
      clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
    });
    map.current?.addLayer({
      id: 'clusters',
      type: 'circle',
      source: 'clusterData',
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#434b56',
          10,
          '#606773',
          100,
          '#959ca7',
        ],
        'circle-radius': ['step', ['get', 'point_count'], 25, 10, 30, 100, 35],
      },
    });

    map.current?.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'clusterData',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': ['get', 'point_count_abbreviated'],
        'text-size': parseInt(theme.fontSize.subhead.replace('px', ''), 10),
      },
      paint: {
        'text-color': '#e9eaec',
      },
    });

    iconMapper?.forEach(({ id, icon, matcher }) => {
      const image = new Image(48, 48);
      image.onload = () => map.current?.addImage(id, image);
      image.src = icon;

      map.current?.addLayer({
        id,
        type: 'symbol',
        source: 'clusterData',
        filter: ['all', ['!', ['has', 'point_count']], matcher],
        layout: {
          'icon-image': id,
          'icon-size': 0.5,
        },
      });
    });
  };
  return (
    <Container
      height={isDesktop ? 450 : 259}
      weight={isDesktop ? 320 : 340}
      ref={container as Ref<View>}
    ></Container>
  );
};

export default forwardRef(MapBoxWrapper);
