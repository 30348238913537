/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation CreatePollVote
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  CreatePollVote,
  CreatePollVoteVariables,
} from './types/CreatePollVote';
import { CREATE_POLL_VOTE } from './';

export function useCreatePollVoteMutation(
  options?: MutationHookOptions<CreatePollVote, CreatePollVoteVariables>,
): MutationTuple<CreatePollVote, CreatePollVoteVariables> {
  return useMutation<CreatePollVote, CreatePollVoteVariables>(
    CREATE_POLL_VOTE,
    options,
  );
}

export function createCreatePollVoteMock({
  variables,
  data,
  error,
}: {
  variables?: CreatePollVoteVariables;
  data?: CreatePollVote;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: CREATE_POLL_VOTE,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
