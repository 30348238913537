/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query ModuleInstance
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  ModuleInstance,
  ModuleInstanceVariables,
} from './types/ModuleInstance';
import { MODULE_INSTANCE } from './';

export function useModuleInstanceQuery(
  options?: QueryHookOptions<ModuleInstance, ModuleInstanceVariables>,
): QueryResult<ModuleInstance, ModuleInstanceVariables> {
  return useQuery<ModuleInstance, ModuleInstanceVariables>(
    MODULE_INSTANCE,
    options,
  );
}

export function useModuleInstanceLazyQuery(
  options?: LazyQueryHookOptions<ModuleInstance, ModuleInstanceVariables>,
): QueryTuple<ModuleInstance, ModuleInstanceVariables> {
  return useLazyQuery<ModuleInstance, ModuleInstanceVariables>(
    MODULE_INSTANCE,
    options,
  );
}

export function createModuleInstanceMock({
  variables,
  data,
  error,
}: {
  variables?: ModuleInstanceVariables;
  data?: ModuleInstance;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: MODULE_INSTANCE,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
