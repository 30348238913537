import { InputAccessoryView, Platform } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import styled from 'styled-components/native';
import ButtonIcon from '~/components/ButtonIcon';
import ImageComp from '~/components/Image';
import { ModalScreenContainer } from '~/screens/style';

export const Container = styled(ModalScreenContainer)``;

export const ScrollView = styled(KeyboardAwareScrollView).attrs({
  contentContainerStyle: {
    justifyContent: 'space-between',
    flexGrow: 1,
  },
})`
  padding-horizontal: 16px;
  padding-bottom: 100px;
`;

export const TextInput = styled.TextInput`
  margin-top: 16px;
  min-height: 124px;
  color: ${({ theme }) => theme.color.base.c6};
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 18px;
  line-height: 24px;
`;

export const View = styled.View``;

export const ToolbarContainer = styled(
  Platform.OS === 'ios' ? InputAccessoryView : View,
)`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

//==

export const ImageContainer = styled.View`
  flex-direction: row;
`;

export const ImageView = styled.View`
  flex: 1;
  padding-top: 12px;
  padding-right: 8px;
`;

export const Image = styled(ImageComp)`
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.base.c1};
  aspect-ratio: 1.5;
`;

export const RemoveButtonIcon = styled(ButtonIcon).attrs(({ theme }) => ({
  color: theme.color.base.c2,
  size: 12,
  name: 'x-close',
}))`
  position: absolute;
  right: 0;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.color.base.c6};
  align-items: center;
  justify-content: center;
`;

export const Controls = styled.View`
  width: 100%;
  flex-direction: row;
  gap: 8px;
  padding-left: 16px;
  background-color: ${({ theme }) => theme.color.base.c0};
  padding-top: 8px;
`;
