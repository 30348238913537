import React from 'react';
import { Platform } from 'react-native';
import { general } from '~/assets/imgs';
import Button from '~/components/Button';
import { Confetti } from '~/components/Lottie';
import UnderlineButton from '~/components/UnderlineButton';
import AuthLayout from '~/screens/Auth/layout/AuthLayout';
import { LanguageProps } from '~/screens/Auth/layout/AuthLayout/types';
import { t } from '~/utils/i18n';
import {
  SafeAreaContainer,
  ScrollView,
  Header,
  Content,
  Title,
  Descp,
  Footer,
  HeartImage,
} from './style';

type SignUpSuccessLayoutProps = {
  name: string;
  lottieEnabled?: boolean;
  onJoinNow: () => void;
  onJoinLater: () => void;
  onLogout: () => void;
} & LanguageProps;

export default function SignUpSuccess({
  name,
  lottieEnabled = true,
  selectedLocale,
  onJoinNow,
  onJoinLater,
  onLogout,
  onSelectedLocale,
}: SignUpSuccessLayoutProps): JSX.Element {
  const isWeb = Platform.OS === 'web';

  const languageProps = {
    selectedLocale,
    onSelectedLocale,
  };

  if (isWeb) {
    return (
      <AuthLayout languageProps={languageProps}>
        <>
          <Content>
            <Title>{t('signUpSuccess.title', { name })}</Title>
            <Descp>{t('signUpSuccess.descp')}</Descp>
          </Content>
          <Footer>
            <UnderlineButton
              testID={'buttonJoinLater'}
              type={'light'}
              text={t('g.continue')}
              onPress={() => onJoinLater && onJoinLater()}
            />
          </Footer>
        </>
      </AuthLayout>
    );
  }

  return (
    <SafeAreaContainer>
      <Confetti enabled={lottieEnabled} />
      <ScrollView>
        <Header>
          <Button
            testID={'buttonLogout'}
            type={'secondary-base'}
            size={'sm'}
            text={t('logout')}
            onPress={() => onLogout && onLogout()}
          />
        </Header>
        <Content>
          <HeartImage source={general.double_hearts} />
          <Title>{t('signUpSuccess.title', { name })}</Title>
          <Descp>{t('signUpSuccess.descp')}</Descp>
        </Content>
      </ScrollView>
      <Footer>
        <Button
          testID={'buttonJoinNow'}
          text={t('signUpSuccess.buttonNow')}
          type={'primary-brand-02'}
          size={'2xl'}
          flex
          onPress={() => onJoinNow && onJoinNow()}
        />
        <Button
          testID={'buttonJoinLater'}
          text={t('signUpSuccess.buttonLater')}
          type={'secondary-contrast'}
          size={'2xl'}
          flex
          onPress={() => onJoinLater && onJoinLater()}
        />
      </Footer>
    </SafeAreaContainer>
  );
}
