import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import styled from 'styled-components/native';

export const ScroolView = styled(KeyboardAwareScrollView)`
  padding-top: ${({ theme }) => theme.spacing.small};
  padding-horizontal: ${({ theme }) => theme.spacing.medium};
`;

export const InputContent = styled.View`
  padding-vertical: ${({ theme }) => theme.spacing.medium};
`;

export const Footer = styled.View`
  padding-bottom: ${({ theme }) => theme.spacing.large};
  margin-top: 24px;
`;

export const InputWrapper = styled.View`
  display: flex;
  flex-direction: row;
`;

export const InputZipWrapper = styled.View`
  width: 35%;
`;

export const InputLocationWrapper = styled.View`
  width: 65%;
`;
