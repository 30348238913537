import { useState } from 'react';
import { t } from '~/utils/i18n';

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<boolean>;

const useCopyToClipboard = (): { copiedText: CopiedValue; copy: CopyFn } => {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);

  /**
   * if the copy action fails we provide a fallback option.
   * Known reasons for clipboard to fails could be one or multiple of following:
   *  - Outdated Safari,
   *  - Safari decides to update their clipboard API (again),
   *  - the user does not allow clipboard permissions in his browser settings, etc
   *  - other,...
   *  we would show the browser confirm dialog with a message that the action failed and the text with the message that must be copied.
   *  This way the user can still manually copy the text by selecting the text.
   */
  const fallbackAction = (text: string) => {
    if (!window.confirm) {
      return;
    }
    window.confirm(t('clipboardCopy.fallback', { text }));
  };

  const copy: CopyFn = async (text) => {
    // Check if copying is supported
    if (!navigator?.clipboard) {
      return false;
    }

    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      return true;
    } catch (error) {
      setCopiedText(null);
      fallbackAction(text);
      return false;
    }
  };

  return {
    copiedText,
    copy,
  };
};

export default useCopyToClipboard;
