/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation CreateModuleInstance
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  CreateModuleInstance,
  CreateModuleInstanceVariables,
} from './types/CreateModuleInstance';
import { CREATE_MODULE_INSTANCE } from './';

export function useCreateModuleInstanceMutation(
  options?: MutationHookOptions<
    CreateModuleInstance,
    CreateModuleInstanceVariables
  >,
): MutationTuple<CreateModuleInstance, CreateModuleInstanceVariables> {
  return useMutation<CreateModuleInstance, CreateModuleInstanceVariables>(
    CREATE_MODULE_INSTANCE,
    options,
  );
}

export function createCreateModuleInstanceMock({
  variables,
  data,
  error,
}: {
  variables?: CreateModuleInstanceVariables;
  data?: CreateModuleInstance;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: CREATE_MODULE_INSTANCE,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
