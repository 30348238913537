import React from 'react';
import moment from 'moment';
import useAppLocale from '~/data/hooks/useAppLocale';
import { Container, Day, Month } from './style';
import 'moment/locale/tr';
import 'moment/locale/de';

type EventDateProps = {
  date: Date;
};

export default function EventDate({ date }: EventDateProps): JSX.Element {
  const { locale } = useAppLocale();
  moment.locale(locale);

  const formattedDate = moment(date);
  const month = formattedDate.format('MMMM');
  const day = formattedDate.format('D');

  return (
    <Container>
      <Day>{day}</Day>
      <Month>{month}</Month>
    </Container>
  );
}
