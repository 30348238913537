/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation UpdateMe
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { UpdateMe, UpdateMeVariables } from './types/UpdateMe';
import { UPDATE_ME } from './';

export function useUpdateMeMutation(
  options?: MutationHookOptions<UpdateMe, UpdateMeVariables>,
): MutationTuple<UpdateMe, UpdateMeVariables> {
  return useMutation<UpdateMe, UpdateMeVariables>(UPDATE_ME, options);
}

export function createUpdateMeMock({
  variables,
  data,
  error,
}: {
  variables?: UpdateMeVariables;
  data?: UpdateMe;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: UPDATE_ME,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
