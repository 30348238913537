import React, { useEffect, useRef, useState } from 'react';
import { useIsFocused } from '@react-navigation/native';
import * as FileSystem from 'expo-file-system';
import {
  Keyboard,
  NativeSyntheticEvent,
  Platform,
  TextInput as RNTextInput,
  TextInputSelectionChangeEventData,
} from 'react-native';
import { LineKey, useTextEditor } from 'react-native-markdown-editor';
import { getImageFromLibrary } from '~/components/ImagePicker';
import { t } from '~/utils/i18n';
import MarkdownText from './MarkdownText';
import StyleBar from './StyleBar';
import Toolbar from './Toolbar';
import {
  Container,
  TextInput,
  ToolbarContainer,
  CharCountContainer,
  CharCount,
  ScrollView,
  ImageContainer,
  Image,
  RemoveButton,
  LoadingHeader,
  LoadingText,
  Loading,
} from './style';

type TextEditorProps = {
  inputTestID?: string;
  initialMarkdownText?: string;
  placeholder?: string;
  saving?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  maxCharCount?: number;
  allowImages?: boolean;
  minHeight?: number;
  onEndEditing: (text: string) => void;
  onChangeMarkdown?: (text: string) => void;
};

export default function TextEditor({
  inputTestID = 'inputText',
  initialMarkdownText = '',
  placeholder,
  saving = false,
  disabled,
  autoFocus = true,
  maxCharCount = 0,
  allowImages,
  onEndEditing,
  minHeight,
  onChangeMarkdown,
}: TextEditorProps): JSX.Element {
  const inputRef = useRef<RNTextInput>(null);
  const inputAccessoryViewID = 'toolbarAcessoryView';
  const [visibleStyleBar, setVisibleStyleBar] = useState(false);
  const [selectedImageBlockInfoKey, setSelectedImageBlockInfoKey] = useState<
    LineKey | undefined
  >(undefined);

  const {
    inputBlockInfoMap,
    markdown,
    currentLineStyle,
    currentTextStyles,
    onChangeText,
    onSelectionChange,
    onTextStyle,
    onLineStyle,
    onAddPhoto,
    onRemovePhoto,
  } = useTextEditor({
    initialMarkdownText,
  });

  useEffect(() => {
    onChangeMarkdown && onChangeMarkdown(markdown);
  }, [markdown]);

  const inputBlockKeys = Object.keys(inputBlockInfoMap) as LineKey[];
  const isFocused = useIsFocused();

  const onPickImage = async () => {
    const imgUris = await getImageFromLibrary({ compressImageQuality: 0.5 });

    const uri = imgUris?.[0];
    if (uri) {
      const base64 = await FileSystem.readAsStringAsync(uri, {
        encoding: 'base64',
      });
      onAddPhoto(base64);
    }
  };

  // note: onEndEditing not triggering properly when leaving screen in android.
  useEffect(() => {
    if (
      markdown !== initialMarkdownText &&
      !isFocused &&
      Platform.OS === 'android'
    ) {
      onEndEditing(markdown);
    }
  }, [isFocused, markdown, initialMarkdownText]);

  const text = Object.values(inputBlockInfoMap)
    .map((inputBlockInfo) =>
      inputBlockInfo.type === 'text' ? inputBlockInfo.text : '',
    )
    .join('');
  const currentCharCount = text.length;
  const exceedCharCount = maxCharCount ? maxCharCount - currentCharCount : 0;
  const exceed = exceedCharCount < 0;

  return (
    <Container>
      <ScrollView keyboardShouldPersistTaps="handled">
        <LoadingHeader>
          {saving && (
            <>
              <LoadingText>{t('g.saving')}</LoadingText>
              <Loading />
            </>
          )}
        </LoadingHeader>
        {inputBlockKeys.map((inputBlockKey, i) => {
          const inputBlockInfo = inputBlockInfoMap[inputBlockKey];
          if (inputBlockInfo.type === 'text') {
            const { text, textStyleMap, lineStyleMap } = inputBlockInfo;
            return (
              <TextInput
                minHeight={minHeight}
                testID={inputTestID}
                key={`${inputTestID}${inputBlockKey}`}
                as={RNTextInput}
                ref={inputRef}
                placeholder={placeholder || ''}
                multiline
                editable={!disabled}
                autoFocus={inputBlockKey === '0' && !disabled && autoFocus}
                inputAccessoryViewID={inputAccessoryViewID}
                onChangeText={(newText: string) =>
                  onChangeText(inputBlockKey, newText)
                }
                onEndEditing={() => {
                  if (markdown != initialMarkdownText) {
                    onEndEditing(markdown);
                  }
                }}
                onSelectionChange={({
                  nativeEvent: { selection },
                }: NativeSyntheticEvent<TextInputSelectionChangeEventData>) =>
                  onSelectionChange(inputBlockKey, selection)
                }
              >
                <MarkdownText
                  lineStyleMap={lineStyleMap}
                  textStyleMap={textStyleMap}
                >
                  {text}
                </MarkdownText>
              </TextInput>
            );
          } else {
            const { imgUrl } = inputBlockInfo;
            return (
              <ImageContainer
                key={`ic${i}`}
                onPress={() => {
                  setSelectedImageBlockInfoKey(
                    selectedImageBlockInfoKey === inputBlockKey
                      ? undefined
                      : inputBlockKey,
                  );
                }}
              >
                <Image
                  source={{ uri: `data:image/png;base64,${imgUrl}` }}
                  resizeMode={'cover'}
                />
                {selectedImageBlockInfoKey === inputBlockKey && (
                  <RemoveButton onPress={() => onRemovePhoto(inputBlockKey)} />
                )}
              </ImageContainer>
            );
          }
        })}
      </ScrollView>
      <ToolbarContainer nativeID={inputAccessoryViewID}>
        <CharCountContainer>
          <CharCount exceed={exceed}>{`${
            exceed ? exceedCharCount : currentCharCount
          }/${maxCharCount}`}</CharCount>
        </CharCountContainer>
        {visibleStyleBar ? (
          <StyleBar
            testID={'styleBar'}
            currentLineStyle={currentLineStyle}
            currentTextStyles={currentTextStyles}
            onLineStyle={onLineStyle}
            onTextStyle={onTextStyle}
            onClose={() => {
              setVisibleStyleBar(false);
            }}
          />
        ) : !disabled && allowImages != undefined ? (
          <Toolbar
            testID={'toolBar'}
            allowImages={allowImages}
            onAddPhoto={onPickImage}
            onTextStyle={() => {
              setVisibleStyleBar(true);
            }}
            onClose={() => Keyboard.dismiss()}
          />
        ) : null}
      </ToolbarContainer>
    </Container>
  );
}

//README
/*
Guidelines
- never replaces text
- textStyleMap just maps textStyles, 
    and reajust itself on text change
    TODO: 
      - handles replace
      -

- lineStyleMap just maps lineStyles,
    and reajust itself on line change
    TODO: 


- lineIndex can be infered from selection and text (lines handled by\n)??
- textStyles, managed with selectin (start, end)
- textStyleMap: ['0:3': ['**']]
- lineStyle, managed with lineIndex (??)
- lineStyleMap: [1: '#']

---
0 [ 0, 9]: #Hey title\n
1 [10,20]: ##Hey header\n
2 [10,20]: ###Hey sub header\n
3 [36,36]: \n
4 [37,45]: Hey body\n
5 [46,46]: \n
6 [47,81]: Hey **bold**, __italic__ and ~~strikethrough~~\n
7 [82,82]: |\n

{
  currentLineIndex: 7,
  textStyleMap: {
    '51:55': ['**'],
    '57:63': ['__'],
    '68:81': ['~~'],
  },
  lineStyleMap: {}
}

Hey title
Hey header
Hey sub header

Hey body

Hey bold, italic and strikethrough


*/
