import { Dimensions } from 'react-native';
import styled from 'styled-components/native';
import { TabScreenContainer } from '~/screens/style';

const { width } = Dimensions.get('window');

export const CONTAINER_PADDING = 16;
const INNER_CELL_PADDING = 22;
export const PHOTO_FORMAT_CELL_WIDTH =
  (width - CONTAINER_PADDING * 2 - INNER_CELL_PADDING * 2) / 3;

export const Container = styled(TabScreenContainer)``;

export const PaddedView = styled.View`
  padding-horizontal: 16px;
`;

export const Header = styled.View`
  padding-top: 24px;
  padding-bottom: 16px;
`;

export const PhotoCategoryContent = styled.View`
  padding-vertical: 16px;
`;

export const PhotoButtonContainer = styled.View`
  padding-vertical: 16px;
  padding-horizontal: 16px;
`;

export const PhotoFormatContent = styled.View`
  flex-direction: row;
  padding-top: 16px;
  padding-bottom: 32px;
  justify-content: space-between;
`;

export const QuestionButtonContainer = styled.View`
  padding-vertical: 16px;
`;

export const CharCountContainer = styled.View`
  flex-direction: row;
  padding-top: 8px;
  align-items: center;

  padding-bottom: 24px;
`;

export const ViewContainer = styled.View`
  flex: 1;
`;

export const Footer = styled.View`
  padding-horizontal: 16px;
  padding-bottom: 64px;
`;
