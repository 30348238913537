/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation CreateBannerAsset
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  CreateBannerAsset,
  CreateBannerAssetVariables,
} from './types/CreateBannerAsset';
import { CREATE_BANNER_ASSET } from './';

export function useCreateBannerAssetMutation(
  options?: MutationHookOptions<CreateBannerAsset, CreateBannerAssetVariables>,
): MutationTuple<CreateBannerAsset, CreateBannerAssetVariables> {
  return useMutation<CreateBannerAsset, CreateBannerAssetVariables>(
    CREATE_BANNER_ASSET,
    options,
  );
}

export function createCreateBannerAssetMock({
  variables,
  data,
  error,
}: {
  variables?: CreateBannerAssetVariables;
  data?: CreateBannerAsset;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: CREATE_BANNER_ASSET,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
