import { Image } from 'react-native';
import styled, { css } from 'styled-components/native';
import IconStyled from '~/components/IconStyled';
import StatisticsCard from '~/components/StatisticsCard';
import {
  ResponsiveProps,
  FlexRowCentered,
} from '~/components/common/style.web';

export const FlexRowCenteredWrap = styled(FlexRowCentered)`
  flex-wrap: wrap;
`;

export const AssetIconContainer = styled.View<ResponsiveProps>`
  width: ${({ isDesktop }) => (isDesktop ? 130 : 64)}px;
  height: ${({ isDesktop }) => (isDesktop ? 130 : 64)}px;
  border-radius: 12px;
  border-width: ${({ isDesktop }) => (isDesktop ? 5 : 2)}px;
  border-color: ${({ theme }) => theme.color.base.c3};
  background-color: ${({ theme }) => theme.color.base.c2};
  justify-content: center;
  align-items: center;
`;

export const AssetIcon = styled(IconStyled).attrs(({ theme }) => ({
  name: 'icn/teams/rocket',
  color: theme.color.brand_02,
}))``;

export const AssetNameContainer = styled.View`
  flex-shrink: 1;
`;

export const StatisticsCardsContainer = styled.FlatList.attrs<ResponsiveProps>(
  ({ isDesktop }) => ({
    contentContainerStyle: isDesktop
      ? {
          justifyContent: 'flex-end',
          width: '100%',
          columnGap: 10,
          marginBottom: 10,
        }
      : {
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gridTemplateRows: 'repeat(2, 1fr)',
          gap: '10px',
          width: '100%',
          marginBottom: '10px',
        },
  }),
)`
  flex-direction: row;
  overflow-x: scroll;
`;

export const StyledStatisticsCard = styled(StatisticsCard).attrs(() => ({
  descriptionFontSize: 8,
  statisticFontSize: 22,
  height: 85,
}))``;

export const ButtonWrapper = styled.View<ResponsiveProps>`
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
`;

export const TitleWrapper = styled.View`
  padding-bottom: 10px;
`;

export const DetailsWrapper = styled.View<ResponsiveProps>`
  background-color: ${({ theme }) => theme.color.base.c0};
  flex-direction: 'column';
  min-width: ${({ isDesktop }) => (isDesktop ? '468px' : '100%')};
  padding: 16px;
  border-radius: 14px;
`;

export const SwitchSelectorSize = styled.View`
  width: 300px;
`;

export const SwitchSelectorText = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ActionsContainer = styled.View<ResponsiveProps>`
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
  justify-content: space-between;
`;

export const SectionWrapper = styled.View<ResponsiveProps>`
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
  justify-content: space-between;
`;

export const SectionsContainer = styled.View``;
export const SectionLabel = styled.Text`
  font-size: 16px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  margin-bottom: 8px;
`;

export const TargetingContainer = styled.View`
  flex-direction: row;
  padding-bottom: 10px;
`;

export const MapContainer = styled.View<ResponsiveProps>`
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
  border-radius: 10px;
  margin-right: ${({ isDesktop }) => (isDesktop ? '24px' : '0px')};
`;

export const VisabilityText = styled.Text`
  color: ${({ theme }) => theme.color.base.c6};
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 16px;
`;

export const AssetTitleWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  flex-shrink: 1;
`;

export const BiggerImageContainer = styled(Image)<ResponsiveProps>`
  width: ${({ isDesktop }) => (isDesktop ? '320px' : '340px')};
  height: 180px;
`;

export const SmallerImageContainer = styled(Image)<ResponsiveProps>`
  width: ${({ isDesktop }) => (isDesktop ? '320px' : '340px')};
  height: 90px;
`;

export const DeleteIconWrapper = styled.View`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.color.base.c6};
  margin-top: -9px;
  align-items: center;
  margin-left: -15px;
  justify-content: center;
`;

export const ImageContainer = styled.View`
  flex-direction: row;
  margin-bottom: 15px;
`;

export const ImageWrapper = styled.View<ResponsiveProps>`
  ${({ isDesktop }) =>
    isDesktop &&
    css`
      flex: 1;
    `}
`;

export const TextWrapper = styled.View`
  width: 100%;
`;

export const ImageSizeText = styled.Text`
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 10px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
`;

export const FormatText = styled.Text`
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 10px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
`;

export const UploadImage = styled.TouchableOpacity<ResponsiveProps>`
  background-color: ${({ theme }) => theme.color.base.c2};
  border-radius: 5px;
  border: 2px solid ${({ theme }) => theme.color.brand_02};
  width: ${({ isDesktop }) => (isDesktop ? '320px' : '340px')};
  margin-bottom: 15px;
  align-items: center;
  justify-content: center;
`;
export const UploadBiggerImage = styled(UploadImage)`
  height: 180px;
`;

export const UploadSmallerImage = styled(UploadImage)`
  height: 90px;
`;

export const SmallerImageWrapper = styled.View<ResponsiveProps>`
  ${({ isDesktop }) =>
    isDesktop &&
    css`
      flex: 1;
      justify-content: flex-end;
      align-items: flex-end;
    `}
`;

export const SmallerImageTextAlignWrapper = styled.View`
  width: fit-content;
`;

export const UploadImageContainer = styled.View<ResponsiveProps>`
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
  margin-bottom: 50px;
`;
