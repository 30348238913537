import React from 'react';
import { general } from '~/assets/imgs';
import { t } from '~/utils/i18n';
import {
  EmptyComponentContainer,
  EmptyComponentText,
  EmptyContainerTitle,
  EmptyListImage,
} from './style';

const NoResults = (): JSX.Element => (
  <EmptyComponentContainer testID="noSearchResults">
    <EmptyListImage source={general.empty} />
    <EmptyContainerTitle>{t('adminPanelSearch.noResults')}</EmptyContainerTitle>
    <EmptyComponentText>
      {t('adminPanelSearch.couldNotFindAnything')}
    </EmptyComponentText>
  </EmptyComponentContainer>
);

export default NoResults;
