/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation CreateRankingsAnswer
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  CreateRankingsAnswer,
  CreateRankingsAnswerVariables,
} from './types/CreateRankingsAnswer';
import { CREATE_RANKINGS_ANSWER } from './';

export function useCreateRankingsAnswerMutation(
  options?: MutationHookOptions<
    CreateRankingsAnswer,
    CreateRankingsAnswerVariables
  >,
): MutationTuple<CreateRankingsAnswer, CreateRankingsAnswerVariables> {
  return useMutation<CreateRankingsAnswer, CreateRankingsAnswerVariables>(
    CREATE_RANKINGS_ANSWER,
    options,
  );
}

export function createCreateRankingsAnswerMock({
  variables,
  data,
  error,
}: {
  variables?: CreateRankingsAnswerVariables;
  data?: CreateRankingsAnswer;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: CREATE_RANKINGS_ANSWER,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
