import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import { ApolloError } from '@apollo/client';
import { StyleSheet } from 'react-native';
import { Modalize } from 'react-native-modalize';
import Button from '~/components/Button';
import Snackbar from '~/components/Snackbar';
import { GroupUser } from '~/data/models/group';
import { Group } from '~/data/models/group';
import { t } from '~/utils/i18n';
import GroupCell from '../GroupCell';
import {
  Content,
  Title,
  FlexBox,
  StyledFlatList,
  LineWrapper,
  Line,
  Loading,
} from './style';

export type ModalSwitchGroupsHandler = Modalize;

export type ModalSwitchGroupsProps = {
  testID?: string;
  contentOnly?: boolean;
  onSelectedGroup: (groupId: string, userGroupId: string) => void;
  onJoinGroup: () => void;
  loading?: boolean;
  error?: ApolloError | undefined;
  groupUsers: GroupUser[];
};

const ModalSwitchGroups: ForwardRefRenderFunction<
  ModalSwitchGroupsHandler,
  ModalSwitchGroupsProps
> = (
  {
    testID,
    contentOnly,
    onSelectedGroup,
    onJoinGroup,
    groupUsers,
    loading,
    error,
  },
  ref,
) => {
  const isError = !!error;

  if (isError) {
    Snackbar.show(error.message);
    return null;
  }
  const renderItem = ({ item: group }: { item: GroupUser }) => {
    return (
      <GroupCell
        type={'secondary'}
        group={group?.group}
        onPress={() => {
          group?.group && onSelectedGroup(group.group.id, group.id);
        }}
      />
    );
  };
  const renderContent = () => {
    return (
      <Content>
        <LineWrapper>
          <Line />
        </LineWrapper>
        <FlexBox>
          <Title>{t('changeGroup.title')}</Title>
          <Button
            size={'sm'}
            text={t('changeGroup.joinNewGroup')}
            state={'default'}
            type={'primary-brand-01'}
            minWidth={150}
            onPress={onJoinGroup}
          />
        </FlexBox>
        <StyledFlatList<React.ElementType>
          testID={testID}
          vertical
          data={groupUsers}
          renderItem={renderItem}
          keyExtractor={(group: Group) => group.id}
          showsHorizontalScrollIndicator={false}
          keyboardShouldPersistTaps="always"
        />
      </Content>
    );
  };

  if (contentOnly) {
    return renderContent();
  }

  return (
    <Modalize
      ref={ref}
      adjustToContentHeight
      handlePosition={'inside'}
      closeSnapPointStraightEnabled={true}
      closeOnOverlayTap={true}
      handleStyle={styles.handle__shape}
      rootStyle={styles.modalize}
      overlayStyle={styles.overlay__background}
      modalStyle={styles.modalize__content}
    >
      {loading ? <Loading /> : renderContent()}
    </Modalize>
  );
};

const styles = StyleSheet.create({
  handle__shape: {
    width: 0,
    height: 0,
  },
  modalize: {
    paddingBottom: 40,
  },
  overlay__background: {
    bottom: 200,
  },
  modalize__content: {
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    elevation: 0,
  },
});

export default forwardRef(ModalSwitchGroups);
