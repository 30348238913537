/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation DeletePost
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { DeletePost, DeletePostVariables } from './types/DeletePost';
import { DELETE_POST } from './';

export function useDeletePostMutation(
  options?: MutationHookOptions<DeletePost, DeletePostVariables>,
): MutationTuple<DeletePost, DeletePostVariables> {
  return useMutation<DeletePost, DeletePostVariables>(DELETE_POST, options);
}

export function createDeletePostMock({
  variables,
  data,
  error,
}: {
  variables?: DeletePostVariables;
  data?: DeletePost;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: DELETE_POST,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
