import React, { useRef, useState } from 'react';
import { Platform } from 'react-native';
import InputField, { InputFieldHandle } from '~/components/InputField';
import AuthLayout from '~/screens/Auth/layout/AuthLayout';
import {
  LanguageProps,
  SubmitButtonProps,
} from '~/screens/Auth/layout/AuthLayout/types';
import validateField from '~/screens/Auth/utils/validateField';
import { t } from '~/utils/i18n';
import { isValidEmail, isValidPassword } from '~/utils/validators';
import {
  InputContent,
  ForgotPasswordView,
  ForgotPasswordText,
  ForgotPasswordTouchView,
  ForgotPasswordLinkText,
} from './style';

export type LoginLayoutProps = {
  loading: boolean;
  errorMessage?: string;
  onBack: () => void;
  onForgotPassword: (email: string) => void;
  onNext: (email: string, password: string) => void;
} & LanguageProps;

export default function Login({
  loading,
  selectedLocale,
  errorMessage,
  onBack,
  onForgotPassword,
  onNext,
  onSelectedLocale,
}: LoginLayoutProps): JSX.Element {
  const isWeb = Platform.OS === 'web';
  const emailRef = useRef<InputFieldHandle>(null);
  const passwordRef = useRef<InputFieldHandle>(null);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [inputHash, setInputHash] = useState<string | undefined>();

  const getInputHash = (email: string, password: string) =>
    `${email}${password}`;

  const isValidInput = (): boolean => {
    return isValidEmail(email) && isValidPassword(password);
  };

  const { state: emailState, info: emailInfo } = validateField({
    field: email,
    isValidField: isValidEmail,
    detectErrors: (field) =>
      errorMessage && inputHash === getInputHash(field, password) ? [''] : [],
  });

  const { state: passwordState, info: passwordInfo } = validateField({
    field: password,
    isValidField: isValidPassword,
    detectErrors: (field) =>
      errorMessage && inputHash === getInputHash(email, field)
        ? [errorMessage]
        : [],
  });

  const handleNext = () => {
    if (isValidInput()) {
      setInputHash(getInputHash(email, password));
      onNext(email, password);
    }
  };

  const headerProps = {
    title: t('login.title'),
    descp: t('login.descp'),
    total: 0,
  };

  const submitButtonProps: SubmitButtonProps = {
    isValidInput,
    loading,
    onPress: handleNext,
    text: t('login.login'),
    flex: true,
    size: '2xl',
  };

  const languageProps = {
    selectedLocale,
    onSelectedLocale,
  };

  const ForgotPasswordComp = (
    <ForgotPasswordView isWeb={isWeb}>
      <ForgotPasswordText isWeb={isWeb}>
        {t('login.forgotPassword')}
      </ForgotPasswordText>
      <ForgotPasswordTouchView
        testID={'buttonForgotPassword'}
        onPress={() => onForgotPassword(email)}
      >
        <ForgotPasswordLinkText isWeb={isWeb}>
          {t('login.resetItHere')}
        </ForgotPasswordLinkText>
      </ForgotPasswordTouchView>
    </ForgotPasswordView>
  );

  return (
    <AuthLayout
      onBack={onBack}
      submitButtonProps={submitButtonProps}
      headerProps={headerProps}
      languageProps={languageProps}
      UnderActionsComponent={ForgotPasswordComp}
    >
      <InputContent>
        <InputField
          ref={emailRef}
          testID={'inputEmail'}
          state={emailState}
          iconName={'mail-01'}
          label={t('login.emailLabel')}
          placeholder={t('login.emailPlaceholder')}
          value={email}
          infoLabel={emailInfo}
          onChangeText={(value) => setEmail(value)}
          blurOnSubmit={false}
          returnKeyType={'next'}
          autoCapitalize={'none'}
          keyboardType={'email-address'}
          onSubmitEditing={() => passwordRef.current?.focus()}
        />
        <InputField
          ref={passwordRef}
          testID={'inputPassword'}
          state={passwordState}
          iconName={'lock-02'}
          label={t('login.passwordLabel')}
          placeholder={t('login.passwordPlaceholder')}
          value={password}
          infoLabel={passwordInfo}
          onChangeText={(value) => setPassword(value)}
          blurOnSubmit={false}
          returnKeyType={'next'}
          autoCapitalize={'none'}
          handleSecureTextEntry
          multiline={false}
          onSubmitEditing={() => handleNext()}
        />
      </InputContent>
    </AuthLayout>
  );
}
