/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation EditCampaign
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { EditCampaign, EditCampaignVariables } from './types/EditCampaign';
import { EDIT_CAMPAIGN } from './';

export function useEditCampaignMutation(
  options?: MutationHookOptions<EditCampaign, EditCampaignVariables>,
): MutationTuple<EditCampaign, EditCampaignVariables> {
  return useMutation<EditCampaign, EditCampaignVariables>(
    EDIT_CAMPAIGN,
    options,
  );
}

export function createEditCampaignMock({
  variables,
  data,
  error,
}: {
  variables?: EditCampaignVariables;
  data?: EditCampaign;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: EDIT_CAMPAIGN,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
