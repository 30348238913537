/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query Teams
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { Teams, TeamsVariables } from './types/Teams';
import { TEAMS } from './';

export function useTeamsQuery(
  options?: QueryHookOptions<Teams, TeamsVariables>,
): QueryResult<Teams, TeamsVariables> {
  return useQuery<Teams, TeamsVariables>(TEAMS, options);
}

export function useTeamsLazyQuery(
  options?: LazyQueryHookOptions<Teams, TeamsVariables>,
): QueryTuple<Teams, TeamsVariables> {
  return useLazyQuery<Teams, TeamsVariables>(TEAMS, options);
}

export function createTeamsMock({
  variables,
  data,
  error,
}: {
  variables?: TeamsVariables;
  data?: Teams;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: TEAMS,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
