import React from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useAuth } from '~/context/auth';
import useAppLocale from '~/data/hooks/useAppLocale';
import { AuthStackParamList } from '~/navigation/types';
import SignUpPasswordLayout from './layout';

type SignUpPasswordNavProp = StackNavigationProp<
  AuthStackParamList,
  'SignUpPassword'
>;
type SignUpPasswordRouteProp = RouteProp<AuthStackParamList, 'SignUpPassword'>;

export default function SignUpPassword(): JSX.Element {
  const navigation = useNavigation<SignUpPasswordNavProp>();
  const {
    params: { g: groupId, t: groupToken, r: inviteCode, shopReturnUrl },
  } = useRoute<SignUpPasswordRouteProp>();

  const { onSetSignUpUser, signUpData } = useAuth();

  const { locale, onChangeLocale } = useAppLocale({
    onReload: () =>
      navigation.replace('SignUpPassword', {
        g: groupId,
        t: groupToken,
        r: inviteCode,
      }),
  });

  const onNext = (password: string) => {
    onSetSignUpUser({ password, passwordConfirmation: password });

    navigation.navigate('SignUpTerm', {
      g: groupId,
      t: groupToken,
      r: inviteCode,
      shopReturnUrl,
    });
  };

  return (
    <SignUpPasswordLayout
      initialPassword={signUpData.password}
      initalPasswordConfirmation={signUpData.passwordConfirmation}
      inputProgress={3}
      selectedLocale={locale}
      onBack={() => navigation.goBack()}
      onNext={onNext}
      onSelectedLocale={onChangeLocale}
    />
  );
}
