/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation CreateProfilePageComment
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  CreateProfilePageComment,
  CreateProfilePageCommentVariables,
} from './types/CreateProfilePageComment';
import { CREATE_PROFILE_PAGE_COMMENT } from './';

export function useCreateProfilePageCommentMutation(
  options?: MutationHookOptions<
    CreateProfilePageComment,
    CreateProfilePageCommentVariables
  >,
): MutationTuple<CreateProfilePageComment, CreateProfilePageCommentVariables> {
  return useMutation<
    CreateProfilePageComment,
    CreateProfilePageCommentVariables
  >(CREATE_PROFILE_PAGE_COMMENT, options);
}

export function createCreateProfilePageCommentMock({
  variables,
  data,
  error,
}: {
  variables?: CreateProfilePageCommentVariables;
  data?: CreateProfilePageComment;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: CREATE_PROFILE_PAGE_COMMENT,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
