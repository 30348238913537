import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components/native';
import AdminHeader from '~/components/AdminHeader';
import Badge from '~/components/Badge';
import { BadgeColorTheme } from '~/components/Badge/types';
import Button from '~/components/Button';
import InputField, { InputFieldHandle } from '~/components/InputField';
import { SettingLabelItem } from '~/components/SettingPanel';
import StatisticsCard from '~/components/StatisticsCard';
import UserFilterDate from '~/components/UserFilterDate';
import { WebContainer } from '~/components/WebGrid';
//import WebSelector from '~/components/WebSelector';
import WebModalConfirm, {
  WebModalConfirmHandler,
} from '~/components/WebModalConfirm';
import {
  Container,
  FlexRowCentered,
  FormContainer,
  Spacer,
  Name,
  FlexRow,
  GrownFlex,
  SectionLabel,
  InputFormWrapper,
  Spinner,
} from '~/components/common/style.web';
import { CAMPAIGN_TYPE_OPTIONS } from '~/data/constants';
import {
  AdsTargetingFields,
  AdsTargetingFilterFields,
} from '~/data/models/admin';
import { CalendarDateObject } from '~/data/models/calendar';
import { Asset, CampaignDetails, Regions } from '~/data/models/campaign';
import {
  AssetVisibilityType,
  CampaignType,
  CreateBannerAssetInput,
  EditCampaignInput,
} from '~/data/operations/global';
import { InstituteTypePicker } from '~/screens/Admin/EditInstitute/layout/style';
import { calendarDateObject, formattedDate } from '~/utils/dates';
import {
  TouchableOpacityWithMeasure,
  useMeasurableTouchableOpacity,
} from '~/utils/hooks/useMeasurableTouchableOpacity';
import { t } from '~/utils/i18n';
import { Statistic } from '~/utils/types/adminAds';
import CreateAssets, { CreateAssetsHandler } from '../CreateAssets';
import {
  AdIcon,
  AdIconContainer,
  AdNameContainer,
  AssetRow,
  AssetImage,
  AssetsHeader,
  SettingLabelContainer,
  AssetNameContainer,
  StatisticsCardsContainer,
  AssetsContainer,
  AssetName,
  AssetId,
  StatisticContainer,
  StatisticValue,
  StatisticName,
  AssetStatisticsContainer,
  AssetData,
  DeleteButton,
} from './style';

type OpenAssetInput = {
  id: string;
  campaignName: string;
  campaignId: string;
};
type EditAdLayoutProps = {
  campaign?: CampaignDetails;
  onBack: () => void;
  openDrawer: () => void;
  updateCampaign: (input: EditCampaignInput) => Promise<void>;
  onAddAsset: (input: Partial<CreateBannerAssetInput>) => Promise<void>;
  onOpenAsset: (input: OpenAssetInput) => void;
  targetingData?: AdsTargetingFields;
  targetingLoading?: boolean;
  filters: AdsTargetingFilterFields[];
  setFilters: React.Dispatch<React.SetStateAction<AdsTargetingFilterFields[]>>;
  campaingLoading?: boolean;
  onDeleteCampaign: () => void;
  regions: Regions[];
};
export default function EditAd({
  campaign,
  campaingLoading,
  onBack,
  openDrawer,
  updateCampaign,
  onOpenAsset,
  targetingData,
  targetingLoading = false,
  filters,
  setFilters,
  onAddAsset,
  onDeleteCampaign,
  regions,
}: EditAdLayoutProps): JSX.Element {
  const theme = useTheme();
  const isDesktop = useMediaQuery({ minWidth: theme.breakpoints.desktopMin });
  const [campaignName, setCampaignName] = useState<string>('');
  const [campaignUtmName, setCampaignUtmName] = useState<string>('');
  const [campaignType, setCampaignType] = useState<CampaignType>();
  const [showStartPicker, setShowStartPicker] = useState<boolean>(false);
  const [showEndPicker, setShowEndPicker] = useState<boolean>(false);
  const campaignNameRef = useRef<InputFieldHandle>(null);
  const campaignUtmNameRef = useRef<InputFieldHandle>(null);
  const startModalizeRef = useRef<TouchableOpacityWithMeasure>(null);
  const endModalizeRef = useRef<TouchableOpacityWithMeasure>(null);
  const createAssetsRef = useRef<CreateAssetsHandler>(null);
  const customerIdRef = useRef<InputFieldHandle>(null);
  const orderIdRef = useRef<InputFieldHandle>(null);
  const [customerId, setCustomerId] = useState<string>('');
  const [orderId, setOrderId] = useState<string>('');

  const deleteCampaignModalRef = useRef<WebModalConfirmHandler>(null);

  const getInitialDueDay = (initialDay?: string) =>
    initialDay
      ? calendarDateObject(formattedDate(initialDay, 'yyyy-MM-dd'))
      : null;
  const [startDay, setStartDay] = useState<CalendarDateObject | null>(
    getInitialDueDay(),
  );
  const [endDay, setEndDay] = useState<CalendarDateObject | null>(
    getInitialDueDay(),
  );

  const toggleStartPicker = () => {
    if (startModalizeRef.current) {
      measureButton(startModalizeRef);
    }
    setShowStartPicker(!showStartPicker);
  };

  const toggleEndPicker = () => {
    if (endModalizeRef.current) {
      measureButton(endModalizeRef);
    }
    setShowEndPicker(!showEndPicker);
  };

  const { measureButton, buttonLayout } = useMeasurableTouchableOpacity();

  useEffect(() => {
    if (!campaign) {
      return;
    }

    setCampaignName(campaign.name);
    setCampaignUtmName(campaign.utm);
    setCampaignType(campaign.type as CampaignType);
    setStartDay(calendarDateObject(campaign.startDate));
    setEndDay(calendarDateObject(campaign.endDate));
    setCustomerId(campaign.customer as string);
    setOrderId(campaign.orderId as string);
  }, [campaign]);

  const onSubmitChanges = () => {
    updateCampaign({
      id: campaign?.id,
      name: campaignName,
      utm: campaignUtmName,
      startDate: startDay,
      endDate: endDay,
      type: campaignType,
      customer: customerId,
      orderId: orderId,
    } as EditCampaignInput);
  };

  const renderStatisticsCard = ({
    item: statistic,
    index,
  }: {
    item: Statistic;
    index: number;
  }) => {
    return (
      <StatisticsCard
        key={`statisticsCard-${index}`}
        statistic={statistic.statistic}
        description={statistic.description}
      />
    );
  };

  const isDateInRange = (startDate: string, endDate: string): boolean => {
    const currentDate = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);
    return currentDate >= start && currentDate <= end;
  };
  const getBadgeDetailsForAsset = (
    asset: Asset,
  ): { badgeColor: BadgeColorTheme; badgeText: string } => {
    if (asset.visibility === AssetVisibilityType.LIVE) {
      return {
        badgeColor: 'green',
        badgeText: t(`adminPanelAds.badges.active`),
      };
    } else if (
      asset.visibility === AssetVisibilityType.SCHEDULE &&
      isDateInRange(asset.startDate, asset.endDate)
    ) {
      return {
        badgeColor: 'green',
        badgeText: t(`adminPanelAds.badges.active`),
      };
    }
    return {
      badgeColor: 'red',
      badgeText: t(`adminPanelAds.badges.inactive`),
    };
  };

  const renderAssetRow = ({
    item: asset,
    index,
  }: {
    item: Asset;
    index: number;
  }) => {
    const { badgeColor, badgeText } = getBadgeDetailsForAsset(asset);
    const imageUri =
      (asset.channelData ? asset.channelData[0]?.imageSmall : '') ?? '';
    return (
      <AssetRow
        key={`assetRow-${index}`}
        onPress={() =>
          onOpenAsset({
            id: asset.id,
            campaignName: campaign?.name as string,
            campaignId: campaign?.id as string,
          })
        }
      >
        <AssetImage source={{ uri: imageUri }} />
        <AssetData>
          <AssetNameContainer>
            <AssetName>
              {t(`adminPanelAds.assetName.${asset.channelType}`)}
            </AssetName>
            <AssetId>
              {t(`adminPanelAds.assetIds.${asset.channelType}`, {
                assetId: String(asset.assetId).padStart(7, '0'),
              })}
            </AssetId>
          </AssetNameContainer>
          <AssetStatisticsContainer>
            <StatisticContainer>
              <StatisticValue>{asset.totalViews}</StatisticValue>
              <StatisticName>{t('adminPanelAds.views')}</StatisticName>
            </StatisticContainer>
            <StatisticContainer>
              <StatisticValue>{asset.totalClicks}</StatisticValue>
              <StatisticName>{t('adminPanelAds.clicks')}</StatisticName>
            </StatisticContainer>
            <StatisticContainer>
              <StatisticValue>{asset.conversionRate}</StatisticValue>
              <StatisticName>{t('adminPanelAds.conversion')}</StatisticName>
            </StatisticContainer>
          </AssetStatisticsContainer>
          <Badge text={badgeText} colorTheme={badgeColor as BadgeColorTheme} />
        </AssetData>
      </AssetRow>
    );
  };

  const onCancelAssetsCreate = () => {
    createAssetsRef?.current?.close();
  };

  const campaignStats: Statistic[] = [
    {
      statistic: `${campaign?.totalViews || '0'}`,
      description: t('editAd.totalViews'),
    },
    {
      statistic: `${campaign?.totalClicks || '0'}`,
      description: t('editAd.totalClicks'),
    },
    {
      statistic: `${campaign?.conversionRate || '0'}%`,
      description: t('editAd.totalConversions'),
    },
    {
      statistic: `${campaign?.assets?.length ?? 0}`,
      description: t('editAd.numberOfAssets'),
    },
  ];

  if (campaingLoading) {
    return <Spinner testID="spinner" />;
  }

  const areAllFieldsFilled = (): boolean => {
    return Boolean(
      campaignName &&
        campaignUtmName &&
        campaignType &&
        customerId &&
        orderId &&
        startDay &&
        endDay,
    );
  };
  return (
    <Container>
      <AdminHeader
        title={t('screens.editAd')}
        onBack={onBack}
        breadcrumbs={['Ads', 'EditAd']}
        enableSaveChanges={areAllFieldsFilled()}
        onSubmitClick={onSubmitChanges}
        buttonTitle={t('adminPanelSearch.saveChanges')}
        onOpenDrawer={openDrawer}
      />
      <WebContainer isDesktop={isDesktop}>
        <FormContainer isDesktop={isDesktop}>
          <FlexRowCentered>
            <AdIconContainer isDesktop={isDesktop}>
              <AdIcon name="icn/teams/rocket" size={isDesktop ? 56 : 24} />
            </AdIconContainer>
            <Spacer h={isDesktop ? 32 : 10} />
            <AdNameContainer>
              <Name testID={'campaignName'} isDesktop={isDesktop}>
                {campaign?.name}
              </Name>
              <Spacer v={isDesktop ? 7 : 5} />
              <FlexRowCentered>
                <Badge
                  text={campaign?.type || CampaignType.EXTERNAL}
                  colorTheme={'tertiary'}
                />
              </FlexRowCentered>
            </AdNameContainer>
          </FlexRowCentered>
          <Spacer v={isDesktop ? 48 : 16} />
          <FlexRowCentered>
            <StatisticsCardsContainer<React.ElementType>
              testID={'statisticsCards'}
              data={campaignStats}
              renderItem={renderStatisticsCard}
              horizontal
              showsHorizontalScrollIndicator={false}
              isDesktop={isDesktop}
            />
          </FlexRowCentered>
          <Spacer v={64} />
          <FlexRow isDesktop={isDesktop}>
            <GrownFlex>
              <SectionLabel>{t('adminPanelAds.campaignDetails')}</SectionLabel>
              <Spacer v={32} />
              <InputFormWrapper>
                <InputField
                  ref={campaignNameRef}
                  testID={'inputCampaignName'}
                  iconName={'users-01'}
                  label={t('adminPanelAds.campaignName')}
                  placeholder={t('adminPanelAds.campaignName')}
                  value={campaignName}
                  onChangeText={(value) => setCampaignName(value)}
                  blurOnSubmit={false}
                  returnKeyType={'next'}
                />
                <Spacer v={16} />
                <InstituteTypePicker
                  testID="campaignTypesPicker"
                  toggleTestID="campaignTypePickerToggle"
                  label={t('adminPanelAds.campaignType')}
                  schoolTypes={CAMPAIGN_TYPE_OPTIONS}
                  selectedSchoolType={{
                    id: campaignType as string,
                    name: campaignType as string,
                  }}
                  initialShowPicker={false}
                  onSelectedSchoolType={(campaignType) =>
                    setCampaignType(campaignType?.name as CampaignType)
                  }
                />
                <Spacer v={32} />
                <InputField
                  ref={campaignUtmNameRef}
                  testID={'inputCampaignUtmName'}
                  iconName={'users-01'}
                  label={t('adminPanelAds.campaignUtmName')}
                  placeholder={t('adminPanelAds.campaignUtmName')}
                  value={campaignUtmName}
                  onChangeText={(value) => setCampaignUtmName(value)}
                  blurOnSubmit={false}
                  returnKeyType={'next'}
                />
                <Spacer v={40} />
                <SectionLabel>
                  {t('adminPanelAds.campaignDuration')}
                </SectionLabel>
                <Spacer v={16} />
                <SettingLabelContainer>
                  <>
                    <SettingLabelItem
                      buttonRef={startModalizeRef}
                      text={t('adminPanelAds.campaignStartDate')}
                      labelText={formattedDate(
                        startDay?.dateString as string,
                        'MMM d yyyy',
                      )}
                      onLabelPress={toggleStartPicker}
                    />
                    <SettingLabelItem
                      buttonRef={endModalizeRef}
                      text={t('adminPanelAds.campaignEndDate')}
                      labelText={formattedDate(
                        endDay?.dateString as string,
                        'MMM d yyyy',
                      )}
                      onLabelPress={toggleEndPicker}
                    />
                  </>
                </SettingLabelContainer>
                <Spacer v={40} />
                <SectionLabel>
                  {t('adminPanelAds.campaignDefineCustomer')}
                </SectionLabel>
                <Spacer v={16} />
                {/* <WebSelector
                  testID={'campaignCustomerSelector'}
                  label={t('adminPanelAds.campaignSelectCustomer')}
                  items={[]}
                  height={70}
                  onItemSelect={(): void => {

                  }}
                  iconName={'icn/teams/home'}
                  isEmptyText={t('adminPanelAds.campaignTypeCNumber')}
                  onSearch={(): void => {

                  }}
                />
                <Spacer v={16} />
                <WebSelector
                  testID={'campaignOrderSelector'}
                  label={t('adminPanelAds.campaignSelectOrder')}
                  items={[]}
                  height={70}
                  onItemSelect={(): void => {

                  }}
                  iconName={'icn/teams/home'}
                  isEmptyText={t('adminPanelAds.campaignTypeOrderId')}
                  onSearch={(): void => {

                  }}
                /> */}
                <InputField
                  ref={customerIdRef}
                  testID={'inputCustomerId'}
                  iconName={'home-02'}
                  label={t('addCampaignPanel.selectCustomer')}
                  placeholder={t('addCampaignPanel.customerPlaceholder')}
                  value={customerId}
                  onChangeText={(value) => setCustomerId(value)}
                  blurOnSubmit={false}
                  returnKeyType={'next'}
                />
                <Spacer v={16} />
                <InputField
                  ref={orderIdRef}
                  testID={'inputOrderId'}
                  iconName={'home-02'}
                  label={t('addCampaignPanel.selectOrder')}
                  placeholder={t('addCampaignPanel.orderPlaceholder')}
                  value={orderId}
                  onChangeText={(value) => setOrderId(value)}
                  blurOnSubmit={false}
                  returnKeyType={'next'}
                />

                <Spacer v={40} />
                <SectionLabel>
                  {t('adminPanelAds.deleteCampaignSection')}
                </SectionLabel>
                <Spacer v={15} />
                <DeleteButton
                  testID={'deleteCampaign'}
                  text={t('adminPanelAds.deleteCampaignButton')}
                  type={'large'}
                  iconStyled={'delete_red'}
                  onPress={() => {
                    deleteCampaignModalRef.current?.open();
                  }}
                  textColor={theme.color.error}
                  intent="danger"
                />
              </InputFormWrapper>
            </GrownFlex>

            <Spacer h={50} />

            <GrownFlex>
              <AssetsHeader>
                <SectionLabel>{t('adminPanelAds.campaignAssets')}</SectionLabel>
                <Button
                  testID={'openCreateAsset'}
                  text={t('adminPanelAds.addAsset')}
                  size={'sm'}
                  type={'secondary-base'}
                  icon={'left'}
                  iconName={'plus'}
                  onPress={() => {
                    createAssetsRef.current?.open();
                  }}
                />
              </AssetsHeader>
              <Spacer v={6} />
              <AssetsContainer<React.ElementType>
                testID={'assets'}
                data={campaign?.assets}
                renderItem={renderAssetRow}
                showsHorizontalScrollIndicator={false}
              />
            </GrownFlex>
          </FlexRow>
        </FormContainer>
      </WebContainer>

      {/* MODALS */}
      <UserFilterDate
        testID={'startDatePicker'}
        buttonText={t('g.confirmDate')}
        date={startDay}
        togglePicker={toggleStartPicker}
        buttonLayout={buttonLayout}
        mode={'single'}
        showPicker={showStartPicker}
        onConfirmDate={(dateObj: CalendarDateObject) => {
          setStartDay(dateObj);
          setShowStartPicker(false);
        }}
      />
      <UserFilterDate
        testID={'startDatePicker'}
        buttonText={t('g.confirmDate')}
        date={startDay}
        togglePicker={toggleEndPicker}
        mode={'single'}
        buttonLayout={buttonLayout}
        showPicker={showEndPicker}
        onConfirmDate={(dateObj: CalendarDateObject) => {
          setEndDay(dateObj);
          setShowEndPicker(false);
        }}
      />

      <CreateAssets
        campaingName={campaign?.name}
        ref={createAssetsRef}
        onCancelAssetsCreate={onCancelAssetsCreate}
        defaultOpen={false}
        targetingData={targetingData}
        targetingLoading={targetingLoading}
        filters={filters}
        setFilters={setFilters}
        regions={regions}
        onSubmitAsset={async (input) => {
          await onAddAsset(input);
          onCancelAssetsCreate();
        }}
      />

      <WebModalConfirm
        title={t('adminPanelAds.deleteCampaignModalTitle')}
        description={t('adminPanelAds.deleteCampaignModalDescription')}
        icon="settings"
        onCancel={() => deleteCampaignModalRef.current?.close()}
        ref={deleteCampaignModalRef}
        onConfirm={() => onDeleteCampaign()}
        buttonText={t('adminPanelAds.deleteCampaignModalBtn')}
        confirmButtonTestId="confirmDeleteBtn"
      />
    </Container>
  );
}
