import React from 'react';
import { Container, Description, LoadingStats, Statistic } from './style';

type StatisticsCardProps = {
  statistic: string;
  statisticFontSize?: number;
  description: string;
  descriptionFontSize?: number;
  horizontalPadding?: number;
  width?: number;
  height?: number;
  isLoading?: boolean;
};
export default function StatisticsCard({
  statistic,
  statisticFontSize,
  description,
  descriptionFontSize,
  horizontalPadding,
  width,
  height,
  isLoading,
}: StatisticsCardProps): JSX.Element {
  return (
    <Container width={width} height={height}>
      {isLoading ? (
        <LoadingStats />
      ) : (
        <>
          <Statistic fontSize={statisticFontSize}>{statistic}</Statistic>
          <Description
            fontSize={descriptionFontSize}
            horizontalPadding={horizontalPadding}
          >
            {description}
          </Description>
        </>
      )}
    </Container>
  );
}
