import React, { ReactNode } from 'react';
import { Platform } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import styled, { css, useTheme } from 'styled-components/native';
import { ResponsiveProps } from '~/components/common/style.web';

export const webContainerCss = css`
  margin: 0 auto;
  width: 100%;
  max-width: 1024px;
`;

export const WebContainer = styled.View<ResponsiveProps>`
  ${Platform.OS === 'web' && webContainerCss}

  ${({ isDesktop }) => css`
    /* This does not work on resize event of the browser, just on initial load */
    padding: ${!isDesktop ? '0 16px' : '0'};
  `};
`;

interface IIsDesktop {
  isDesktop: boolean;
}
const HalfColumnView = styled.View<IIsDesktop>`
  width: ${({ isDesktop }) => (isDesktop ? 50 : 100)}%;
  padding-horizontal: 16px;
`;

export const HalfColumn = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const theme = useTheme();
  const isDesktop = useMediaQuery({ minWidth: theme.breakpoints.desktopMin });
  return <HalfColumnView isDesktop={isDesktop}>{children}</HalfColumnView>;
};
