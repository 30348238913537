import styled from 'styled-components/native';
import { UnderlineButtonType } from './types';

export const ContainerButton = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})`
  padding: 8px;
`;

interface IText {
  type: UnderlineButtonType;
  underline?: boolean;
}
export const Text = styled.Text<IText>`
  margin-vertical: ${({ theme }) => theme.spacing.tiny};
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ type, theme }) =>
    type === 'light' ? theme.color.base.c0 : theme.color.brand_02};
  font-size: 16px;
  line-height: 24px;
  ${({ underline = true }) => underline && 'text-decoration-line: underline'};
`;
