/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation MoveProfilePageQuestionToPosition
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  MoveProfilePageQuestionToPosition,
  MoveProfilePageQuestionToPositionVariables,
} from './types/MoveProfilePageQuestionToPosition';
import { MOVE_PROFILE_PAGE_QUESTION_TO_POSITION } from './';

export function useMoveProfilePageQuestionToPositionMutation(
  options?: MutationHookOptions<
    MoveProfilePageQuestionToPosition,
    MoveProfilePageQuestionToPositionVariables
  >,
): MutationTuple<
  MoveProfilePageQuestionToPosition,
  MoveProfilePageQuestionToPositionVariables
> {
  return useMutation<
    MoveProfilePageQuestionToPosition,
    MoveProfilePageQuestionToPositionVariables
  >(MOVE_PROFILE_PAGE_QUESTION_TO_POSITION, options);
}

export function createMoveProfilePageQuestionToPositionMock({
  variables,
  data,
  error,
}: {
  variables?: MoveProfilePageQuestionToPositionVariables;
  data?: MoveProfilePageQuestionToPosition;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: MOVE_PROFILE_PAGE_QUESTION_TO_POSITION,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
