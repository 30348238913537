/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query AdminGetInstitute
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  AdminGetInstitute,
  AdminGetInstituteVariables,
} from './types/AdminGetInstitute';
import { ADMIN_GET_INSTITUTE } from './';

export function useAdminGetInstituteQuery(
  options?: QueryHookOptions<AdminGetInstitute, AdminGetInstituteVariables>,
): QueryResult<AdminGetInstitute, AdminGetInstituteVariables> {
  return useQuery<AdminGetInstitute, AdminGetInstituteVariables>(
    ADMIN_GET_INSTITUTE,
    options,
  );
}

export function useAdminGetInstituteLazyQuery(
  options?: LazyQueryHookOptions<AdminGetInstitute, AdminGetInstituteVariables>,
): QueryTuple<AdminGetInstitute, AdminGetInstituteVariables> {
  return useLazyQuery<AdminGetInstitute, AdminGetInstituteVariables>(
    ADMIN_GET_INSTITUTE,
    options,
  );
}

export function createAdminGetInstituteMock({
  variables,
  data,
  error,
}: {
  variables?: AdminGetInstituteVariables;
  data?: AdminGetInstitute;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: ADMIN_GET_INSTITUTE,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
