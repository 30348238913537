import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  ForwardRefRenderFunction,
  createRef,
} from 'react';
import {
  Animated,
  NativeSyntheticEvent,
  TextInput as RNTextInput,
  TextInputKeyPressEventData,
  TextInputProps,
} from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { useTheme } from 'styled-components/native';
import Icon, { IconName } from '~/components/Icon';
import IconStyled, { IconStyledName } from '~/components/IconStyled';
import {
  InputFieldSizeType,
  InputFieldState,
  StateColorMap,
} from '~/components/InputField/types';
import { Spacer } from '~/components/common/style.web';
import {
  SearchShortcutAction,
  SearchTagValue,
} from '~/utils/types/adminSearch';
import ButtonIcon from '../ButtonIcon';
import {
  Container,
  BoxContainer,
  TypeContent,
  AddOn,
  AnimatedLabel,
  Content,
  TextInput,
  InfoContent,
  InfoLabel,
  FocusContainer,
  InputContainer,
  TagsInput,
  Limit,
  TagWrapper,
  TagsText,
  DeleteIconWrapper,
  DeleteShortcutWrapper,
} from './style';

export interface InputFieldProps extends TextInputProps {
  value: string;
  label?: string;
  placeholder?: string;
  state?: InputFieldState;
  iconName?: IconName;
  iconStyledName?: IconStyledName;
  addOn?: string;
  infoLabel?: string;
  tags?: SearchTagValue[];
  size?: InputFieldSizeType;
  limit?: SearchShortcutAction;
  renderCounter?: number;
  onChangeText?: (text: string) => void;
  onChangeTag?: (tag: string, text: string) => void;
  handleDeleteTag?: (tag: string) => void;
  onKeyPress?: (
    event: NativeSyntheticEvent<TextInputKeyPressEventData>,
  ) => void;
}

export interface InputFieldHandle {
  focus: () => void;
}

const InputSearchField: ForwardRefRenderFunction<
  InputFieldHandle,
  InputFieldProps
> = (
  {
    value,
    label,
    renderCounter,
    placeholder = '',
    state = 'default',
    iconName,
    iconStyledName,
    addOn,
    infoLabel,
    size = 'default',
    tags,
    limit,
    onChangeText,
    onChangeTag,
    handleDeleteTag,
    onFocus,
    onBlur,
    onKeyPress,
    ...inputProps
  },
  ref,
) => {
  const theme = useTheme();
  const containerRef = useRef(null);
  const inputRef = useRef<RNTextInput>(null);
  const [hasTags, setHasTags] = useState(tags && tags.length > 0);
  const [active, setActive] = useState(false);
  const [activeAnimated] = useState(new Animated.Value(value === '' ? 0 : 1));
  const hovered = useHover(containerRef);

  const NUM_INPUTS = tags?.length as number;
  const inputRefs = useRef<Array<React.Ref<RNTextInput>>>(
    Array.from({ length: NUM_INPUTS }, () => React.createRef<RNTextInput>()),
  );
  const [tagInputWidths, setTagInputWidths] = useState<Array<string>>(
    Array(NUM_INPUTS).fill(0),
  );

  useEffect(() => {
    // Create a ref for each tag

    if (tags) {
      inputRefs.current = Array(tags.length)
        .fill(null)
        .map((_, i) => inputRefs?.current[i] || createRef());
    }
  }, [tags, renderCounter]);

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef?.current?.focus();
    },
  }));

  const getInnerState = (
    state: InputFieldState,
    hovered: boolean,
    active: boolean,
  ): InputFieldState => {
    if (state === 'error' || state === 'success') {
      return state;
    }
    return active ? 'active' : hovered ? 'hover' : state;
  };

  const innerState = getInnerState(state, hovered, active);
  useEffect(() => {
    Animated.timing(activeAnimated, {
      toValue: active || value !== '' ? 1 : 0,
      duration: 200,
      useNativeDriver: false,
    }).start();
  }, [active, value]);

  const stateColorMap: StateColorMap = {
    default: theme.color.base.c6,
    hover: theme.color.brand_02,
    focus: theme.color.brand_02,
    active: theme.color.brand_02,
    error: theme.color.error,
    success: theme.color.success,
  };
  const validationColor = stateColorMap[innerState];

  const top = activeAnimated.interpolate({
    inputRange: [0, 1],
    outputRange: [8, 0],
  });
  const fontSize = activeAnimated.interpolate({
    inputRange: [0, 1],
    outputRange: [18, 12],
  });
  const color = activeAnimated.interpolate({
    inputRange: [0, 1],
    outputRange: [theme.color.base.c6, validationColor],
  });

  const labelAnimatedStyle = {
    top,
    fontSize,
    color,
  };

  useEffect(() => {
    setHasTags(tags && tags.length > 0);
    if (limit) setHasTags(true);
  }, [tags, limit]);

  useEffect(() => {
    Animated.timing(activeAnimated, {
      toValue: active || value !== '' || hasTags ? 1 : 0,
      duration: 200,
      useNativeDriver: false,
    }).start();
  }, [active, value, hasTags]);

  const changeTagWidth = (index: number, width: string) => {
    const newWidths = [...tagInputWidths];
    newWidths[index] = width;
    setTagInputWidths(newWidths);
  };

  useEffect(() => {
    if (tags) {
      const lastTag = tags[tags.length - 1];
      const tagIndex = tags.length - 1 < 0 ? 0 : tags.length - 1;
      changeTagWidth(tagIndex, `${lastTag?.value?.length + 2}ch`);
    }
  }, [tags]);

  const renderTag = ({
    item: tag,
    index,
  }: {
    item: SearchTagValue;
    index: number;
  }) => {
    const handleDeleteTags = () => {
      changeTagWidth(index, '2ch');
      handleDeleteTag && handleDeleteTag(tag.tag);
    };

    return (
      <>
        <TagWrapper
          isError={tag.error}
          onPress={() => {
            (
              inputRefs.current[index] as React.RefObject<RNTextInput>
            )?.current?.focus();
          }}
        >
          <TagsText isError={tag.error}>{tag.tag}</TagsText>
          <TagsInput
            autoFocus
            {...inputProps}
            value={tag.value}
            isError={tag.error}
            onChangeText={(text) => {
              onChangeTag && onChangeTag(tag.tag, text);
              changeTagWidth(index, `${text.length + 2}ch`);
            }}
            width={tagInputWidths[index]}
            ref={inputRefs.current[index] as React.RefObject<RNTextInput>}
            key={`tag-${index}`}
            onKeyPress={(e) => {
              if (e.nativeEvent.key === 'Backspace' && tag.value === '') {
                onChangeTag && onChangeTag(tag.tag, '');
              }
              onKeyPress && onKeyPress(e);
            }}
          />
          {tag.tag !== '' && (
            <DeleteIconWrapper>
              <ButtonIcon
                name={'x-close'}
                size={8}
                color={'gray'}
                onPress={handleDeleteTags}
              />
            </DeleteIconWrapper>
          )}
        </TagWrapper>
        <Spacer h={10} />
      </>
    );
  };

  return (
    <Container>
      <FocusContainer ref={containerRef} validationColor={validationColor}>
        <BoxContainer
          onPress={() => {
            inputRef.current?.focus();
          }}
          validationColor={validationColor}
        >
          {(() => {
            if (addOn) {
              return (
                <TypeContent size={size}>
                  <AddOn>{addOn}</AddOn>
                </TypeContent>
              );
            }
            if (iconName) {
              return (
                <TypeContent size={size}>
                  <Icon
                    name={iconName}
                    size={size === 'default' ? 24 : 18}
                    color={theme.color.base.c4}
                  />
                </TypeContent>
              );
            }
            if (iconStyledName) {
              return (
                <TypeContent size={size}>
                  <IconStyled
                    name={iconStyledName}
                    size={size === 'default' ? 24 : 18}
                  />
                </TypeContent>
              );
            }
            return null;
          })()}
          <Content
            horizontal={true}
            showsHorizontalScrollIndicator={true}
            size={size}
          >
            {label && (
              <AnimatedLabel style={labelAnimatedStyle}>{label}</AnimatedLabel>
            )}
            <InputContainer>
              {tags?.length !== 0 &&
                tags?.map((item, index) => renderTag({ item, index }))}

              {limit && (
                <>
                  <Limit>
                    {limit.label}
                    <DeleteShortcutWrapper>
                      <ButtonIcon
                        name={'x-close'}
                        size={8}
                        color={'gray'}
                        onPress={() =>
                          handleDeleteTag && handleDeleteTag(limit.label)
                        }
                      />
                    </DeleteShortcutWrapper>
                  </Limit>
                  <Spacer h={10} />
                </>
              )}
              <TextInput
                {...inputProps}
                as={RNTextInput}
                ref={inputRef}
                value={value}
                placeholder={active || !label ? placeholder : ''}
                placeholderTextColor={theme.color.base.c6}
                onChangeText={onChangeText}
                onFocus={(event) => {
                  onFocus && onFocus(event);
                  setActive(true);
                }}
                onBlur={(event) => {
                  onBlur && onBlur(event);
                  setActive(false);
                }}
                onKeyPress={onKeyPress}
                blurOnSubmit
              />
            </InputContainer>
          </Content>
        </BoxContainer>
      </FocusContainer>
      {!!infoLabel && (
        <InfoContent>
          <InfoLabel>{infoLabel}</InfoLabel>
        </InfoContent>
      )}
    </Container>
  );
};
export default forwardRef(InputSearchField);
