import React, { useState, useCallback } from 'react';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { DATABASE_ROWS_PER_PAGE_OPTIONS } from '~/data/constants';
import { CAMPAIGN_TABLE_COLUMNS } from '~/data/constants';
import { Campaign } from '~/data/models/campaign';
import { useCampaignsQuery } from '~/data/operations/campaign/campaigns'; // Ensure you have a refetch function or similar
import { AdsStackParamList } from '~/navigation/types';
import useSort from '~/utils/hooks/useSort';
import AdsLayout from './layout';

type AdsNavProp = StackNavigationProp<AdsStackParamList, 'Ads'>;

export default function Ads(): JSX.Element {
  const navigation = useNavigation<AdsNavProp>();
  const [currentActiveTab, setCurrentActiveTab] = useState<number>(1);
  const [filterQuery, setFilterQuery] = useState<string>('');
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    DATABASE_ROWS_PER_PAGE_OPTIONS[0],
  );
  const offset = (currentActiveTab - 1) * rowsPerPage;

  const { sortedBy, toggleSort } = useSort({ initialSortBy: '-startDate' });

  const vars = {
    first: rowsPerPage,
    offset: offset,
    searchFilter: filterQuery,
    orderBy: sortedBy,
  };
  const {
    data: campaignsData,
    loading,
    refetch,
  } = useCampaignsQuery({
    variables: vars,
  });

  useFocusEffect(
    useCallback(() => {
      refetch(vars);
      navigation.setParams({ refresh: undefined });
    }, [navigation, refetch]),
  );

  const handleOnOpenCampaign = (id: string) => {
    navigation.navigate('EditAd', { id });
  };

  const campaigns: Campaign[] =
    campaignsData?.campaigns?.edges.map((edge) => edge?.node as Campaign) || [];

  const handleOnCreateCampaign = () => {
    navigation.navigate('AddCampaign');
  };

  const totalCampaigns = campaignsData?.campaigns?.totalCount ?? 0;

  return (
    <AdsLayout
      onOpenCampaign={handleOnOpenCampaign}
      loading={loading}
      campaigns={campaigns}
      totalCampaigns={totalCampaigns}
      handleOnCreateCampaign={handleOnCreateCampaign}
      setCurrentActiveTab={setCurrentActiveTab}
      filterQuery={filterQuery}
      rowsPerPage={rowsPerPage}
      sortedBy={sortedBy}
      setRowsPerPage={setRowsPerPage}
      setFilterQuery={setFilterQuery}
      toggleSort={toggleSort}
      columns={CAMPAIGN_TABLE_COLUMNS}
    />
  );
}
