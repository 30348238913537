import React from 'react';
import useAppLocale from '~/data/hooks/useAppLocale';
import ServerErrorLayout from './layout';

export default function BrokenLink(): JSX.Element {
  const { locale, onChangeLocale } = useAppLocale();

  const openContactSupport = () => {
    //web only
    //possible crash https://abihome-gmbh.sentry.io/issues/5091279511/?project=5776572&query=is%3Aunresolved&referrer=issue-stream&statsPeriod=7d&stream_index=2
    window.Intercom('showMessages');
  };

  return (
    <ServerErrorLayout
      selectedLocale={locale}
      onSelectedLocale={onChangeLocale}
      openContactSupport={openContactSupport}
    />
  );
}
