import React from 'react';
import {
  PaddedContainer,
  Container,
  Content,
  QuestionText,
  AddButtonIcon,
} from './style';

type PhotoCategoryCellProps = {
  profilePagePhotoCategory: { id: string; text: string };
  onAdd: () => void;
  onPress?: () => void;
};

//TODO setup emoji

export default function PhotoCategoryCell({
  profilePagePhotoCategory,
  onAdd,
  onPress,
}: PhotoCategoryCellProps): JSX.Element {
  return (
    <PaddedContainer
      testID={`PhotoCategoryCell:${profilePagePhotoCategory.id}`}
    >
      <Container onPress={() => onPress && onPress()}>
        <Content>
          <QuestionText>{profilePagePhotoCategory.text}</QuestionText>
        </Content>
        <AddButtonIcon onPress={onAdd} />
      </Container>
    </PaddedContainer>
  );
}
