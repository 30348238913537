import { Picker } from '@react-native-picker/picker';
import SelectDropdown from 'react-native-select-dropdown';
import styled from 'styled-components/native';
import Icon from '../Icon';

export const Container = styled.View`
  flex-direction: row;
`;

export const StepPickerTitleWrapper = styled.View`
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.base.c1};
  border: 1px solid;
  border-color: ${({ theme }) => theme.color.base.c3};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

export const StepPickerText = styled.Text`
  color: ${({ theme }) => theme.color.base.c5};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 14px;
  line-height: 17px;
`;

export const StepPickerNumberBox = styled(SelectDropdown)`
  padding: 8px 12px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.color.base.c3};
  background-color: ${({ theme }) => theme.color.base.c0};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export const StepPicker = styled(Picker)`
  width: 100%;
  appearance: none;
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.color.base.c5};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
`;

export const ArrowsView = styled.View``;

export const ArrowIcon = styled(Icon).attrs(({ theme }) => ({
  size: 16,
  color: theme.color.base.c3,
}))``;
