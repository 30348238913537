import React from 'react';
import CommonIconCell from '~/components/CommonIconCell';
import SectionButton from '~/components/SectionButton';
import { ReportInstance } from '~/data/models/yearbook';
import { t } from '~/utils/i18n';
import ModuleLayout from '../../layout/ModuleLayout';
import {
  ReportList,
  Header,
  Content,
  Loading,
  RefreshControl,
  InfoText,
  EmptyImage,
  Footer,
} from './style';

type ReportsLayoutProps = {
  reportsInstances: ReportInstance[];
  yearbookAdmin?: boolean;
  isActive?: boolean;
  viewOnly?: boolean;
  loading: boolean;
  hasNextPage?: boolean;
  isDeadlinePassed?: boolean | null;
  onBack: () => void;
  onSetup: () => void;
  onHelp: () => void;
  onRequestYearbookTeam: () => void;
  onAddReport: () => void;
  onSelectedReport: (reportId: string) => void;
  onRefresh: () => void;
  onLoadMore: () => void;
};

export default function Reports({
  reportsInstances,
  yearbookAdmin,
  isActive,
  viewOnly,
  loading,
  hasNextPage,
  isDeadlinePassed,
  onBack,
  onSetup,
  onHelp,
  onRequestYearbookTeam,
  onAddReport,
  onSelectedReport,
  onRefresh,
  onLoadMore,
}: ReportsLayoutProps): JSX.Element {
  const renderItem = ({ item: reportInstance }: { item: ReportInstance }) => {
    return (
      <CommonIconCell
        testID={`CommonIconCell:${reportInstance.id}`}
        info={{
          text: reportInstance.title,
        }}
        onPress={() => onSelectedReport(reportInstance.id)}
      />
    );
  };

  return (
    <ModuleLayout
      title={t('screens.reports')}
      yearbookAdmin={yearbookAdmin}
      viewOnly={viewOnly}
      onBack={onBack}
      onSetup={onSetup}
      isActive={isActive}
      isDeadlinePassed={isDeadlinePassed}
      onHelp={onHelp}
      onRequestYearbookTeam={onRequestYearbookTeam}
    >
      <ReportList<React.ElementType>
        testID={'reportsList'}
        refreshControl={
          <RefreshControl refreshing={loading} onRefresh={onRefresh} />
        }
        data={reportsInstances}
        renderItem={renderItem}
        ListHeaderComponent={
          <Header>
            {!viewOnly && yearbookAdmin ? (
              <SectionButton
                testID={'buttonAddReport'}
                text={t('g.addNewReport')}
                icon={'plus'}
                centerContent
                type={'large'}
                onPress={onAddReport}
              />
            ) : null}
          </Header>
        }
        ListEmptyComponent={
          !loading && reportsInstances.length === 0 ? (
            <Content>
              <EmptyImage />
              <InfoText>{t('reports.noReports')}</InfoText>
            </Content>
          ) : null
        }
        ListFooterComponent={
          <Footer>
            {loading ? <Loading /> : null}
            {!yearbookAdmin ? (
              <InfoText>{t('reports.noWritePermission')}</InfoText>
            ) : null}
          </Footer>
        }
        onEndReachedThreshold={0.3}
        onEndReached={() => hasNextPage && onLoadMore()}
      />
    </ModuleLayout>
  );
}
