/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation RemoveLikeFromComment
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  RemoveLikeFromComment,
  RemoveLikeFromCommentVariables,
} from './types/RemoveLikeFromComment';
import { REMOVE_LIKE_FROM_COMMENT } from './';

export function useRemoveLikeFromCommentMutation(
  options?: MutationHookOptions<
    RemoveLikeFromComment,
    RemoveLikeFromCommentVariables
  >,
): MutationTuple<RemoveLikeFromComment, RemoveLikeFromCommentVariables> {
  return useMutation<RemoveLikeFromComment, RemoveLikeFromCommentVariables>(
    REMOVE_LIKE_FROM_COMMENT,
    options,
  );
}

export function createRemoveLikeFromCommentMock({
  variables,
  data,
  error,
}: {
  variables?: RemoveLikeFromCommentVariables;
  data?: RemoveLikeFromComment;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: REMOVE_LIKE_FROM_COMMENT,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
