import React, { useRef } from 'react';
import { TouchableOpacity } from 'react-native';
import { useTheme } from 'styled-components/native';
import ButtonIcon from '~/components/ButtonIcon';
import { UploadStateEnum } from '~/utils/hooks/useUploadCSV';
import { t } from '~/utils/i18n';
import {
  UploadCSVText,
  UploadedCSVFile,
  UploadedCSVErrorContainer,
  UploadedCSVErrorText,
  UploadingCSVText,
  DeleteIconWrapper,
  DeleteIconContainer,
} from './style';

type CsvInputFieldProps = {
  uploadingState: UploadStateEnum;
  handleUploadCSV: (file: File) => void;
  removeUploadedFile: () => void;
  uploadedCSVFileName?: string;
  uploadedCSVFileLength?: number;
};

const CsvInputField: React.FC<CsvInputFieldProps> = ({
  uploadingState,
  handleUploadCSV,
  removeUploadedFile,
  uploadedCSVFileName,
  uploadedCSVFileLength,
}) => {
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const file = e.target.files[0];
      handleUploadCSV(file);
    }
  };

  const handleClick = () => {
    inputRef.current?.click();
  };

  return (
    <>
      {uploadingState === UploadStateEnum.Uploading && (
        <UploadingCSVText>
          {t('createAssets.createTargeting.locationFilter.uploadingCSV')}
        </UploadingCSVText>
      )}
      {uploadingState === UploadStateEnum.Uploaded && (
        <DeleteIconContainer>
          <UploadedCSVFile>
            {uploadedCSVFileName} {'(' + uploadedCSVFileLength + ')'}
          </UploadedCSVFile>
          <DeleteIconWrapper>
            <ButtonIcon
              name={'x-close'}
              size={8}
              onPress={removeUploadedFile}
              color={theme?.color.base.c2}
            />
          </DeleteIconWrapper>
        </DeleteIconContainer>
      )}
      {uploadingState === UploadStateEnum.None && (
        <>
          <TouchableOpacity onPress={handleClick}>
            <UploadCSVText>
              {t('createAssets.createTargeting.locationFilter.uploadCVS')}
            </UploadCSVText>
          </TouchableOpacity>
          <input
            type="file"
            accept=".csv"
            ref={inputRef}
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
        </>
      )}

      {uploadingState === UploadStateEnum.Error && (
        <UploadedCSVErrorContainer>
          <UploadedCSVErrorText>
            {t('createAssets.createTargeting.locationFilter.errorUploadingCSV')}
          </UploadedCSVErrorText>
          <TouchableOpacity onPress={handleClick}>
            <UploadCSVText>
              {t('createAssets.createTargeting.locationFilter.uploadCVS')}
            </UploadCSVText>
          </TouchableOpacity>
          <input
            type="file"
            accept=".csv"
            ref={inputRef}
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
        </UploadedCSVErrorContainer>
      )}
    </>
  );
};

export default CsvInputField;
