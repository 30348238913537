import React from 'react';
import {
  useNavigation,
  useRoute,
  RouteProp,
  CompositeNavigationProp,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useAuth } from '~/context/auth';
import { useIntercom } from '~/context/intercom';
import useReportInstances from '~/data/hooks/useReportInstances';
import useTeamSamples from '~/data/hooks/useTeamSamples';
import { useModuleInstanceQuery } from '~/data/operations/yearbook/moduleInstance';
import { RootStackParamList, YearbookStackParamList } from '~/navigation/types';
import { isDateExpired } from '~/utils/dates';
import ReportsLayout from './layout';

type ReportsNavProp = CompositeNavigationProp<
  StackNavigationProp<YearbookStackParamList, 'Reports'>,
  StackNavigationProp<RootStackParamList>
>;

type ReportsRouteProp = RouteProp<YearbookStackParamList, 'Reports'>;

export default function Reports(): JSX.Element {
  const navigation = useNavigation<ReportsNavProp>();
  const { onHelpCenter } = useIntercom();
  const {
    params: { moduleInstanceId, viewOnly },
  } = useRoute<ReportsRouteProp>();

  const { authGroupId, yearbookAdmin } = useAuth();
  const { teamSamples } = useTeamSamples();
  const yearbookTeam = teamSamples.filter((t) => t.type === 'YEARBOOK');

  const { data: moduleInstanceData } = useModuleInstanceQuery({
    variables: {
      id: moduleInstanceId,
    },
  });

  const isActive = moduleInstanceData?.moduleInstance?.isActive;
  const dueDate = moduleInstanceData?.moduleInstance?.dueDate;
  const isDeadlinePassed = dueDate && isDateExpired(dueDate);

  const {
    reportsInstances,
    loading: reportsInstancesLoading,
    hasNextPage: reportInstancesHasNextPage,
    onRefresh,
    onLoadMore,
  } = useReportInstances({ moduleInstanceId });

  return (
    <ReportsLayout
      yearbookAdmin={yearbookAdmin}
      isActive={isActive}
      isDeadlinePassed={isDeadlinePassed}
      reportsInstances={reportsInstances}
      loading={reportsInstancesLoading}
      viewOnly={viewOnly}
      hasNextPage={reportInstancesHasNextPage}
      onBack={() => navigation.goBack()}
      onSetup={() => navigation.navigate('ReportsSetup', { moduleInstanceId })}
      onHelp={onHelpCenter}
      onRequestYearbookTeam={() => {
        navigation.popToTop();
        navigation.navigate('MainTab', {
          screen: 'PlanningStack',
          gid: authGroupId,
          params: {
            screen: 'TeamDetail',
            params: {
              teamId: yearbookTeam[0]?.id,
            },
          },
        });
      }}
      onAddReport={() =>
        navigation.navigate('ReportDetail', { moduleInstanceId })
      }
      onSelectedReport={(reportInstanceId) =>
        navigation.navigate('ReportDetail', {
          moduleInstanceId,
          reportInstanceId,
          viewOnly,
        })
      }
      onRefresh={onRefresh}
      onLoadMore={onLoadMore}
    />
  );
}
