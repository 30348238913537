import React from 'react';
import { Container, ViewProgress } from './style';

type InputProgressProps = {
  width?: number;
  fullWidth?: boolean;
  total: number;
  progress: number;
};

export default function InputProgress({
  width = 180,
  fullWidth,
  total,
  progress,
}: InputProgressProps): JSX.Element {
  return (
    <Container width={width} fullWidth={fullWidth}>
      <ViewProgress
        progress={progress}
        total={total}
        width={width}
        fullWidth={fullWidth}
      />
    </Container>
  );
}
