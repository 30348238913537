import React, { useRef, useState } from 'react';
import { useTheme } from 'styled-components/native';
import ButtonIcon from '~/components/ButtonIcon';
import IconStyled from '~/components/IconStyled';
import InputField, { InputFieldHandle } from '~/components/InputField';
import { t } from '~/utils/i18n';
import DragAndDrop from './DragAndDrop.web';
import {
  Container,
  Title,
  UploadImageContainer,
  UploadBiggerImage,
  UploadSmallerImage,
  ImageWrapper,
  SmallerImageWrapper,
  TextWrapper,
  ImageSizeText,
  SmallerImageTextAlignWrapper,
  FormatText,
  InputContainer,
  CampaignNameContainer,
  CampaignNameLabel,
  CampaignName,
  BiggerImageContainer,
  SmallerImageContainer,
  DeleteIconWrapper,
  ImageContainer,
  WarningNoHttps,
} from './style';

type CreateDesignProps = {
  trackingUrl: string;
  setTrackingUrl: (value: string) => void;
  campaignName: string;
  setBiggerImageUri: (value: string) => void;
  setSmallerImageUri: (value: string) => void;
  biggerImageUri?: string;
  smallerImageUri?: string;
};

export default function CreateDesign({
  trackingUrl,
  setTrackingUrl,
  campaignName,
  setBiggerImageUri,
  setSmallerImageUri,
  biggerImageUri,
  smallerImageUri,
}: CreateDesignProps): JSX.Element {
  const trackingUrlRef = useRef<InputFieldHandle>(null);
  const [showHttpsWarning, setShowHttpsWarning] = useState(false);

  const theme = useTheme();
  const handleBiggerImageDrop = (file: Blob) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const uri = e.target?.result as string;
      setBiggerImageUri(uri);
    };
    reader.readAsDataURL(file);
  };

  const handleSmallerImageDrop = (file: Blob) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const uri = e.target?.result as string;
      setSmallerImageUri(uri);
    };
    reader.readAsDataURL(file);
  };

  const handleUrlInputChange = (value: string) => {
    setTrackingUrl(value);
    const isHttps = value.toLowerCase().startsWith('https://');
    setShowHttpsWarning(!isHttps);
  };

  return (
    <Container testID="createAssetModalDesign">
      <Title>{t('createAssets.createDesign.title')}</Title>
      <UploadImageContainer>
        <ImageWrapper>
          {biggerImageUri ? (
            <ImageContainer>
              {' '}
              <BiggerImageContainer source={{ uri: biggerImageUri }} />{' '}
              <DeleteIconWrapper>
                <ButtonIcon
                  name={'x-close'}
                  size={12}
                  color={theme.color.base.c2}
                  onPress={() => setBiggerImageUri('')}
                />
              </DeleteIconWrapper>
            </ImageContainer>
          ) : (
            <UploadBiggerImage>
              <DragAndDrop
                onDrop={(file) => {
                  handleBiggerImageDrop(file);
                }}
                height={180}
              >
                <IconStyled
                  name="upload_primary"
                  size={48}
                  color="none"
                ></IconStyled>
              </DragAndDrop>
            </UploadBiggerImage>
          )}

          <TextWrapper>
            <ImageSizeText>
              {t('createAssets.createDesign.bigImageResolution')}
            </ImageSizeText>
            <FormatText>{t('createAssets.createDesign.format')}</FormatText>
          </TextWrapper>
        </ImageWrapper>
        <SmallerImageWrapper>
          <SmallerImageTextAlignWrapper>
            {smallerImageUri ? (
              <ImageContainer>
                <SmallerImageContainer source={{ uri: smallerImageUri }} />
                <DeleteIconWrapper>
                  <ButtonIcon
                    name={'x-close'}
                    size={12}
                    color={theme.color.base.c2}
                    onPress={() => setSmallerImageUri('')}
                  />
                </DeleteIconWrapper>
              </ImageContainer>
            ) : (
              <UploadSmallerImage>
                <DragAndDrop onDrop={handleSmallerImageDrop}>
                  <IconStyled
                    name="upload_primary"
                    size={48}
                    color="none"
                  ></IconStyled>
                </DragAndDrop>
              </UploadSmallerImage>
            )}
            <SmallerImageTextAlignWrapper>
              <ImageSizeText>
                {t('createAssets.createDesign.smallImageResolution')}
              </ImageSizeText>
              <FormatText>{t('createAssets.createDesign.format')}</FormatText>
            </SmallerImageTextAlignWrapper>
          </SmallerImageTextAlignWrapper>
        </SmallerImageWrapper>
      </UploadImageContainer>
      <InputContainer>
        <InputField
          ref={trackingUrlRef}
          testID={'targetingUrlInput'}
          iconName={'share-07'}
          label={t('createAssets.createDesign.trackingUrl')}
          value={trackingUrl}
          onChangeText={handleUrlInputChange}
          blurOnSubmit={false}
          returnKeyType={'next'}
        />
        {showHttpsWarning && (
          <WarningNoHttps>
            {t('createAssets.createDesign.warningNoHttps')}{' '}
          </WarningNoHttps>
        )}

        <CampaignNameContainer>
          <CampaignNameLabel>
            {t('createAssets.createDesign.companyNameLabel')}{' '}
          </CampaignNameLabel>
          <CampaignName>{campaignName}</CampaignName>
        </CampaignNameContainer>
      </InputContainer>
    </Container>
  );
}
