import styled from 'styled-components/native';
import Icon from '~/components/Icon';
import { bySizeHelper, byColorThemeHelper } from './helpers';
import { BadgeType, BadgeSize, BadgeColorTheme } from './types';

interface IContainer {
  type: BadgeType;
  size: BadgeSize;
  colorTheme: BadgeColorTheme;
  remove: boolean;
}
export const Container = styled.TouchableOpacity.attrs({
  activeOpacity: 1.0,
})<IContainer>`
  flex-direction: row;
  height: ${({ size }) => bySizeHelper.containerHeight(size)};
  border-radius: ${({ size, type }) =>
    bySizeHelper.containerRadius(size, type)};
  padding-vertical: 4px;
  padding-horizontal: 8px;
  background-color: ${({ colorTheme, theme }) =>
    byColorThemeHelper.bgColor(colorTheme, theme)};
  align-items: center;
  justify-content: center;
`;

interface IText {
  size: BadgeSize;
  colorTheme: BadgeColorTheme;
}
export const Text = styled.Text<IText>`
  color: ${({ colorTheme, theme }) =>
    byColorThemeHelper.textColor(colorTheme, theme)};
  font-size: 12px;
  padding: 0 8px 0 8px;
  font-family: ${({ size, theme }) =>
    size === 'large' ? theme.fontFamily.core.w600 : theme.fontFamily.core.w600};
`;

interface ICustomIcon {
  colorTheme: BadgeColorTheme;
}
export const CustomIcon = styled(Icon).attrs<ICustomIcon>(
  ({ colorTheme, theme }) => ({
    size: 12,
    color: byColorThemeHelper.dotColor(colorTheme, theme),
  }),
)<ICustomIcon>`
  margin-right: 4px;
`;

// export const CustomIcon = styled.View<IDot>`
//   width: 8px;
//   height: 8px;
//   border-radius: 4px;
//   margin-right: 8px;
//   margin-left: 1px;
//   background-color: ${({ colorTheme, theme }) =>
//     byColorThemeHelper.dotColor(colorTheme, theme)};
// `;

export const RemoveButton = styled.TouchableOpacity.attrs({
  activeOpacity: 1.0,
})`
  padding-left: 4px;
`;

export const RemoveIcon = styled(Icon).attrs<ICustomIcon>(
  ({ colorTheme, theme }) => ({
    size: 8,
    color: byColorThemeHelper.dotColor(colorTheme, theme),
  }),
)<ICustomIcon>``;
