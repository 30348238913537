/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation RejectInstituteRequest
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  RejectInstituteRequest,
  RejectInstituteRequestVariables,
} from './types/RejectInstituteRequest';
import { REJECT_INSTITUTE_REQUEST } from './';

export function useRejectInstituteRequestMutation(
  options?: MutationHookOptions<
    RejectInstituteRequest,
    RejectInstituteRequestVariables
  >,
): MutationTuple<RejectInstituteRequest, RejectInstituteRequestVariables> {
  return useMutation<RejectInstituteRequest, RejectInstituteRequestVariables>(
    REJECT_INSTITUTE_REQUEST,
    options,
  );
}

export function createRejectInstituteRequestMock({
  variables,
  data,
  error,
}: {
  variables?: RejectInstituteRequestVariables;
  data?: RejectInstituteRequest;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: REJECT_INSTITUTE_REQUEST,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
