/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query RankingsQuestions
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  RankingsQuestions,
  RankingsQuestionsVariables,
} from './types/RankingsQuestions';
import { RANKINGS_QUESTIONS } from './';

export function useRankingsQuestionsQuery(
  options?: QueryHookOptions<RankingsQuestions, RankingsQuestionsVariables>,
): QueryResult<RankingsQuestions, RankingsQuestionsVariables> {
  return useQuery<RankingsQuestions, RankingsQuestionsVariables>(
    RANKINGS_QUESTIONS,
    options,
  );
}

export function useRankingsQuestionsLazyQuery(
  options?: LazyQueryHookOptions<RankingsQuestions, RankingsQuestionsVariables>,
): QueryTuple<RankingsQuestions, RankingsQuestionsVariables> {
  return useLazyQuery<RankingsQuestions, RankingsQuestionsVariables>(
    RANKINGS_QUESTIONS,
    options,
  );
}

export function createRankingsQuestionsMock({
  variables,
  data,
  error,
}: {
  variables?: RankingsQuestionsVariables;
  data?: RankingsQuestions;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: RANKINGS_QUESTIONS,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
