import styled from 'styled-components/native';

export const BreadcrumbsContainer = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  flex-direction: row;
  gap: 9px;
`;

interface IBreadcrumbLink {
  enabled?: boolean;
}

export const BreadcrumbLink = styled.Text<IBreadcrumbLink>`
  color: ${({ enabled, theme }) =>
    enabled ? theme.color.brand_02 : theme.color.base.c6};
  font-size: 16px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
`;

export const BreadcrumbArrow = styled.Text`
  color: ${({ theme }) => theme.color.base.c9};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
`;
