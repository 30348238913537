import styled from 'styled-components/native';

export const Container = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  width: 100%;
  padding: 20px;
  border-radius: 15px;
`;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 16px;
  padding-left: 10px;
`;

export const FilterContainer = styled.View``;

export const HeaderContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const TitleContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const DeleteButton = styled.Text`
  color: ${({ theme }) => theme.color.error};
  font-size: 12px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  text-decoration-line: underline;
`;

export const Label = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 14px;
  color: ${({ theme }) => theme.color.base.c5};
  text-transform: uppercase;
`;

export const LabelContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const UploadCSVText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 12px;
  color: ${({ theme }) => theme.color.brand_02};
  text-decoration-line: underline;
`;

export const LocationRadiusWrapper = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
`;

export const CheckBoxWrapper = styled.View`
  display: flex;
  flex-direction: row;
`;

export const CheckBoxSize = styled.View`
  width: 16px;
  height: 16px;
`;

export const CheckBoxText = styled.Text`
  text-align: center;
  margin-top: 5px;
  margin-left: 10px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c6};
`;
