import { gql } from '@apollo/client';

export const EVENT_FIELDS = gql`
  fragment EventFields on EventNode {
    title
    id
    location
    companyName
    companyLogo
    bannerImage
    isSponsored
    companyName
    eventAuthor
    authorUser {
      id
      firstName
      lastName
      avatar
      email
    }
    authorGroup {
      id
      name
      avatar
    }
    authorTeam {
      id
      name
      avatar
    }
    authorType
    eventType
    description
    startDateTime
    endDateTime
    isAllDay
    isJoinable
    isPublic
    attendees {
      id
      user {
        id
        firstName
        lastName
        avatar
        email
      }
    }
  }
`;

export const EXTERNAL_EVENT_FIELDS = gql`
  fragment ExternalEventAttendee on ExternalEventAttendeeNode {
    id
    attendee {
      email
    }
    platform
    eventId
  }
`;

//Query

export const EVENTS = gql`
  ${EVENT_FIELDS}
  query Events($offset: Int, $before: String, $after: String, $first: Int) {
    events(offset: $offset, before: $before, after: $after, first: $first) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...EventFields
        }
      }
    }
  }
`;

export const EVENT = gql`
  ${EVENT_FIELDS}
  query Event($id: ID!) {
    event(id: $id) {
      ...EventFields
    }
  }
`;

//Mutations

export const CREATE_EVENT = gql`
  ${EVENT_FIELDS}
  mutation CreateEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      event {
        ...EventFields
      }

      errors {
        field
        messages
      }
    }
  }
`;

export const JOIN_EVENT = gql`
  ${EVENT_FIELDS}
  mutation JoinEvent($input: JoinEventInput!) {
    joinEvent(input: $input) {
      attendee {
        id
        event {
          ...EventFields
        }
        user {
          id
        }
      }
    }
  }
`;

export const LEAVE_EVENT = gql`
  mutation LeaveEvent($input: LeaveEventInput!) {
    leaveEvent(input: $input) {
      success
    }
  }
`;

export const EDIT_EVENT = gql`
  ${EVENT_FIELDS}
  mutation EditEvent($input: EditEventInput!) {
    editEvent(input: $input) {
      event {
        ...EventFields
      }

      errors {
        field
        messages
      }
    }
  }
`;

export const DELETE_EVENT = gql`
  mutation DeleteEvent($input: DeleteEventInput!) {
    deleteEvent(input: $input) {
      success
    }
  }
`;

export const SET_EVENT_BANNER = gql`
  ${EVENT_FIELDS}
  mutation SetEventBanner($input: SetEventBannerInput!) {
    setEventBanner(input: $input) {
      event {
        ...EventFields
      }
    }
  }
`;
export const SAVE_EXTERNAL_EVENT = gql`
  ${EXTERNAL_EVENT_FIELDS}
  mutation SaveExternalEvent($input: SaveExternalEventInput!) {
    saveExternalEvent(input: $input) {
      externalEvent {
        ...ExternalEventAttendee
      }
      success
    }
  }
`;

export const REMOVE_EXTERNAL_EVENT = gql`
  ${EXTERNAL_EVENT_FIELDS}
  mutation RemoveExternalEvent($input: RemoveExternalEventInput!) {
    removeExternalEvent(input: $input) {
      externalEvent {
        ...ExternalEventAttendee
      }
      success
    }
  }
`;

export const TOGGLE_EXTERNAL_EVENT_SAVE = gql`
  ${EXTERNAL_EVENT_FIELDS}
  mutation ToggleExternalEventSave($input: ToggleExternalEventSaveInput!) {
    toggleExternalEventSave(input: $input) {
      externalEvent {
        ...ExternalEventAttendee
      }
      success
    }
  }
`;
