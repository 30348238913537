import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { ProfileStackParamList } from '~/navigation/types';
import AppSetup from '~/screens/Main/AppSetup';
import GroupSetup from '~/screens/Main/GroupSetup';
import NotificationSettings from '~/screens/Main/NotificationSettings';
import Profile from '~/screens/Main/Profile';
import ProfileSetup from '~/screens/Main/ProfileSetup';

const ProfileStackNavigator = createStackNavigator<ProfileStackParamList>();

export default function ProfileStack(): JSX.Element {
  return (
    <ProfileStackNavigator.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <ProfileStackNavigator.Screen name={'Profile'} component={Profile} />
      <ProfileStackNavigator.Screen
        name={'ProfileSetup'}
        component={ProfileSetup}
      />

      <ProfileStackNavigator.Screen
        name={'GroupSetup'}
        component={GroupSetup}
      />
      <ProfileStackNavigator.Screen
        name={'NotificationSettings'}
        component={NotificationSettings}
      />
      <ProfileStackNavigator.Screen name={'AppSetup'} component={AppSetup} />
    </ProfileStackNavigator.Navigator>
  );
}
