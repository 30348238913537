import React, { useState } from 'react';
import { Platform } from 'react-native';
import { COUNTRIES } from '~/data/constants';
import { t } from '~/utils/i18n';
import {
  Container,
  Header,
  TypeTitle,
  TypeValue,
  CountryPicker,
  WebContainer,
  WebPicker,
  Line,
} from './style';

export type CountryType = {
  name: string;
  country?: string;
};

type CountryTypePickerProps = {
  testID?: string;
  toggleTestID?: string;
  label: string;
  selectedCountryType?: CountryType;
  initialShowPicker?: boolean;
  includeNoSelection?: boolean;
  onSelectedCountryType: (CountryType?: CountryType) => void;
};

const noSelection: CountryType = {
  name: t('noSelection'),
};

const countryTypes: CountryType[] = Object.values(COUNTRIES);

export default function CountryTypePicker({
  testID,
  toggleTestID,
  label,
  selectedCountryType = noSelection,
  initialShowPicker = false,
  includeNoSelection = true,
  onSelectedCountryType,
}: CountryTypePickerProps): JSX.Element {
  const [showPicker, setShowPicker] = useState<boolean>(initialShowPicker);
  const allCountryTypes = includeNoSelection
    ? [noSelection, ...countryTypes]
    : countryTypes;

  return Platform.OS !== 'web' ? (
    <Container>
      <Header testID={toggleTestID} onPress={() => setShowPicker(!showPicker)}>
        <TypeTitle numberOfLines={1}>{label}</TypeTitle>
        <TypeValue numberOfLines={1}>{selectedCountryType.name}</TypeValue>
      </Header>
      {showPicker && (
        <CountryPicker<React.ElementType>
          testID={testID}
          selectedValue={selectedCountryType.name}
          onValueChange={(countryTypename: string) =>
            onSelectedCountryType(
              countryTypes.find(
                (countryType) => countryType.name === countryTypename,
              ),
            )
          }
        >
          {allCountryTypes.map((countryType) => (
            <CountryPicker.Item
              key={countryType.name}
              label={countryType.name}
            />
          ))}
        </CountryPicker>
      )}
    </Container>
  ) : (
    <>
      <WebContainer>
        <label htmlFor="web-picker">
          <TypeTitle numberOfLines={1}>{label}</TypeTitle>
        </label>
        <WebPicker<React.ElementType>
          id="web-picker"
          selectedValue={selectedCountryType.name}
          onValueChange={(countryTypename: string) =>
            onSelectedCountryType(
              countryTypes.find(
                (countryType) => countryType.name === countryTypename,
              ),
            )
          }
        >
          {allCountryTypes.map((countryType) => (
            <CountryPicker.Item
              key={countryType.name}
              label={countryType.name}
              value={countryType.name}
            />
          ))}
        </WebPicker>
      </WebContainer>
      <Line />
    </>
  );
}
