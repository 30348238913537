import { useEffect, useRef, useState, Dispatch, SetStateAction } from 'react';
import throttle from 'lodash.throttle';

const useThrottledState = <T>(
  initialValue: T,
  delay = 300,
): [T, Dispatch<SetStateAction<T>>] => {
  const [value, setValue] = useState(initialValue);
  const [throttledValue, setThrottledValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
    setThrottledValue(initialValue);
  }, [initialValue]);

  const dispatch = (value: T) => {
    setThrottledValue(value);
  };

  const throttledFunc = useRef(throttle(dispatch, delay)).current;

  useEffect(() => {
    throttledFunc(value);
  }, [value]);

  return [throttledValue, setValue];
};

export default useThrottledState;
