import React from 'react';
import Avatar from '~/components/Avatar';
import Badge from '~/components/Badge';
import Button from '~/components/Button';
import NavHeader from '~/components/NavHeader';
import PDFViewer from '~/components/PDFViewer';
import { Spacer } from '~/components/common/style.web';
import { Container, Spinner } from '~/components/common/style.web';
import { CustomPagesInstance } from '~/data/operations/yearbook/types/CustomPagesInstance';
import { t } from '~/utils/i18n';
import { ImageQualityBadgeColor } from '../../CreateCustomPage/layout';
import {
  PageContainer,
  ImageBanner,
  ImageContent,
  ImageDescriptionContainer,
  ImageDescription,
  FlexContainer,
  OwnerName,
  OwnerContainer,
  SaveButton,
  ContainerWrapper,
  PDFWrapper,
} from './style';

type CustomPageResultLayoutProps = {
  onBack: () => void;
  customPageData?: CustomPagesInstance;
  isImgUriPdf?: boolean;
  numberOfPages?: number | null;
  loading?: boolean;
  onDownloadImage: (imageUri: string) => void;
};

export default function CustomPageResult({
  onBack,
  customPageData,
  isImgUriPdf,
  numberOfPages,
  loading,
  onDownloadImage,
}: CustomPageResultLayoutProps): JSX.Element {
  if (loading) {
    return (
      <Container centerContent>
        <Spinner testID="loadingSpinner" />
      </Container>
    );
  }
  return (
    <PageContainer>
      <NavHeader
        backButtonTestID={'buttonBack'}
        title={customPageData?.customPagesInstance?.title}
        onBackPress={onBack}
      />
      <ContainerWrapper>
        <ImageContent>
          {isImgUriPdf ? (
            <PDFWrapper>
              <PDFViewer
                uri={customPageData?.customPagesInstance?.page as string}
                hidePreview={false}
              />
            </PDFWrapper>
          ) : (
            <ImageBanner
              key={customPageData?.customPagesInstance?.page as string}
              testID={`PagePhotoCategoryCardImage`}
              source={{
                uri: customPageData?.customPagesInstance?.page as string,
              }}
            ></ImageBanner>
          )}
        </ImageContent>
        <ImageDescriptionContainer>
          <FlexContainer>
            {customPageData?.customPagesInstance?.page && (
              <ImageDescription>{`${t('createCustomPage.imageType')} ${
                customPageData?.customPagesInstance?.pageType &&
                t(
                  `createCustomPage.${customPageData?.customPagesInstance?.pageType}`,
                )
              }`}</ImageDescription>
            )}
            <ImageDescription>{`${t('createCustomPage.target')} ${
              customPageData?.customPagesInstance?.pageFormat &&
              t(
                `createCustomPage.${customPageData?.customPagesInstance?.pageFormat}`,
              )
            }`}</ImageDescription>
          </FlexContainer>

          {customPageData?.customPagesInstance?.page && !isImgUriPdf && (
            <>
              <FlexContainer>
                <ImageDescription>{`${t(
                  'createCustomPage.imageSize',
                )}`}</ImageDescription>
                <ImageDescription>
                  {`${t('createCustomPage.pages')} `} {numberOfPages}
                </ImageDescription>
              </FlexContainer>

              <ImageDescription>
                {`${t('createCustomPage.imageQuality')}`}{' '}
                {customPageData?.customPagesInstance?.pageQuality && (
                  <Badge
                    colorTheme={
                      ImageQualityBadgeColor[
                        customPageData?.customPagesInstance
                          ?.pageQuality as string
                      ]
                    }
                    text={
                      customPageData?.customPagesInstance?.pageQuality as string
                    }
                  />
                )}
              </ImageDescription>
            </>
          )}
          <Spacer v={25} />
          <ImageDescription>
            {`${t('createCustomPage.uploadedBy')} `}
          </ImageDescription>
          <OwnerContainer>
            <Avatar
              uri={customPageData?.customPagesInstance?.userGroup?.avatar}
              size={32}
            />
            <OwnerName>
              {`${customPageData?.customPagesInstance?.userGroup?.user.firstName} ${customPageData?.customPagesInstance?.userGroup?.user.lastName}`}
            </OwnerName>
          </OwnerContainer>
        </ImageDescriptionContainer>
      </ContainerWrapper>
      <SaveButton>
        <Button
          testID={'buttonSaveOnDevice'}
          text={t('createCustomPage.saveOnDeviceBtn')}
          onPress={() =>
            onDownloadImage(customPageData?.customPagesInstance?.page as string)
          }
          minWidth={360}
          type="secondary-base"
        />
      </SaveButton>
    </PageContainer>
  );
}
