import { Platform } from 'react-native';
import styled from 'styled-components/native';

const isWeb = Platform.OS === 'web';

export const Container = styled.View`
  padding-vertical: ${({ theme }) => theme.spacing.medium};
`;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c10};
  font-size: ${isWeb ? 32 : 20}px;
`;
export const Descp = styled.Text`
  margin-top: 4px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 18px;
  line-height: 22px;
`;

export const InputProgressContent = styled.View`
  margin-top: ${({ theme }) => theme.spacing.medium};
`;
