/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation DestroyProfilePageAnswer
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  DestroyProfilePageAnswer,
  DestroyProfilePageAnswerVariables,
} from './types/DestroyProfilePageAnswer';
import { DESTROY_PROFILE_PAGE_ANSWER } from './';

export function useDestroyProfilePageAnswerMutation(
  options?: MutationHookOptions<
    DestroyProfilePageAnswer,
    DestroyProfilePageAnswerVariables
  >,
): MutationTuple<DestroyProfilePageAnswer, DestroyProfilePageAnswerVariables> {
  return useMutation<
    DestroyProfilePageAnswer,
    DestroyProfilePageAnswerVariables
  >(DESTROY_PROFILE_PAGE_ANSWER, options);
}

export function createDestroyProfilePageAnswerMock({
  variables,
  data,
  error,
}: {
  variables?: DestroyProfilePageAnswerVariables;
  data?: DestroyProfilePageAnswer;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: DESTROY_PROFILE_PAGE_ANSWER,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
