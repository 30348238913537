import React, { useEffect, useState } from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { PDFDocument } from 'pdf-lib';
import Snackbar from '~/components/Snackbar';
import { PageType } from '~/data/operations/global';
import { useCustomPagesInstanceQuery } from '~/data/operations/yearbook/customPagesInstance';
import { YearbookStackParamList } from '~/navigation/types';
import useDownloadImage from '~/utils/hooks/useDownloadImage';
import { t } from '~/utils/i18n';
import CustomPageResultLayout from './layout';

type CustomPageResultNavProp = StackNavigationProp<
  YearbookStackParamList,
  'CustomPageResult'
>;

type CustomRouteProp = RouteProp<YearbookStackParamList, 'CustomPageResult'>;

export default function CustomPageResult(): JSX.Element {
  const navigation = useNavigation<CustomPageResultNavProp>();
  const {
    params: { customPageId },
  } = useRoute<CustomRouteProp>();
  const { downloadImage, downloadPDF } = useDownloadImage();

  const { data, loading } = useCustomPagesInstanceQuery({
    variables: {
      id: customPageId,
    },
  });
  const isImgUriPdf = data?.customPagesInstance?.pageType === PageType.PDF;

  const [numberOfPages, setNumberOfPages] = useState<number>(1);

  useEffect(() => {
    const fetchPdfPages = async () => {
      if (isImgUriPdf && data?.customPagesInstance?.page) {
        const pdfBytes = await fetch(data.customPagesInstance.page).then(
          (res) => res.arrayBuffer(),
        );

        const pdfDoc = await PDFDocument.load(pdfBytes);
        setNumberOfPages(pdfDoc.getPageCount());
      }
    };

    fetchPdfPages();
  }, [isImgUriPdf, data]);

  const onDownloadImage = async (imageUri: string) => {
    try {
      if (data?.customPagesInstance?.pageType === PageType.PDF) {
        await downloadPDF(imageUri);
      } else {
        await downloadImage(imageUri);
      }

      Snackbar.show(t('g.fileDownloaded'), {
        duration: Snackbar.durations.SHORT,
        position: Snackbar.positions.BOTTOM_CHANGE,
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  return (
    <CustomPageResultLayout
      onBack={() => navigation.goBack()}
      customPageData={data}
      isImgUriPdf={isImgUriPdf}
      numberOfPages={numberOfPages}
      loading={loading}
      onDownloadImage={onDownloadImage}
    />
  );
}
