import React, { useState, forwardRef, ForwardRefRenderFunction } from 'react';
import { Calendar } from 'react-native-calendars';
import { Modalize } from 'react-native-modalize';
import { Portal } from 'react-native-portalize';
import { useTheme } from 'styled-components/native';
import Button from '~/components/Button';
import { CalendarDateMode, CalendarDateObject } from '~/data/models/calendar';
import { calendarDay } from '~/utils/dates';
import {
  Container,
  Content,
  Title,
  DayWrapper,
  DayCell,
  DayText,
  DayBg,
  DayBgLeft,
  DayBgRight,
} from './style';

export type ModalDatePickerHandler = Modalize;

export type ModalDatePickerProps = {
  testID?: string;
  title: string;
  buttonText: string;
  date: CalendarDateObject | null;
  todayTimestamp?: number;
  contentOnly?: boolean;
  mode?: CalendarDateMode;
  minDate?: string;
  maxDate?: string;
  onConfirmDate: (dateObject: CalendarDateObject) => void;
};

const ModalDatePicker: ForwardRefRenderFunction<
  ModalDatePickerHandler,
  ModalDatePickerProps
> = (
  {
    testID,
    title,
    buttonText,
    date,
    todayTimestamp = new Date().getTime(),
    contentOnly,
    mode = 'range',
    minDate,
    maxDate,
    onConfirmDate,
  },
  ref,
) => {
  const theme = useTheme();
  const [selectedDate, setSelectedDate] = useState<CalendarDateObject | null>(
    date,
  );

  const todayStr = calendarDay(new Date(todayTimestamp));

  const onSelectedDate = (day: CalendarDateObject) => {
    if (day.timestamp > todayTimestamp) {
      setSelectedDate(day);
    }
  };
  const renderContent = () => {
    return (
      <Content>
        <Title>{title}</Title>
        <Calendar
          minDate={minDate}
          maxDate={maxDate}
          style={{ height: 380 }}
          current={todayStr}
          monthFormat={'MMM yyyy'}
          theme={{
            calendarBackground: theme.color.base.c0,
            textSectionTitleColor: theme.color.brand_02,
            textDayHeaderFontWeight: '600',
            textDayHeaderFontSize: 10,
            dayTextColor: theme.color.base.c6,
            textDayFontSize: 14,
            textDayFontWeight: '600',
            monthTextColor: theme.color.base.c8,
            textMonthFontWeight: '600',
            textMonthFontSize: 16,
            arrowColor: theme.color.brand_02,
          }}
          enableSwipeMonths
          onDayPress={onSelectedDate}
          dayComponent={({ date, state, onPress, onLongPress }) => {
            const today = date?.dateString === todayStr;
            const selected = date?.dateString === selectedDate?.dateString;
            const marked = mode === 'single' ? selected : today || selected;
            const timestamp = date?.timestamp || 0;
            const period =
              selectedDate &&
              timestamp > todayTimestamp &&
              timestamp < selectedDate.timestamp;
            const disabled =
              state === 'disabled' || (state === 'selected' && !selected);
            return (
              <DayWrapper
                onPress={() => onPress && onPress(date)}
                onLongPress={() => onLongPress && onLongPress(date)}
              >
                {(marked || period) && mode === 'range' && (
                  <DayBg>
                    <DayBgLeft bg={!today} />
                    <DayBgRight bg={!selected && !!selectedDate} />
                  </DayBg>
                )}
                <DayCell marked={marked}>
                  <DayText disabled={disabled} marked={marked}>
                    {date?.day}
                  </DayText>
                </DayCell>
              </DayWrapper>
            );
          }}
        />
        <Button
          size={'xl'}
          text={buttonText}
          type={'primary-brand-02'}
          state={selectedDate ? 'default' : 'disabled'}
          flex
          onPress={() => {
            if (selectedDate) {
              onConfirmDate(selectedDate);
            }
          }}
        />
      </Content>
    );
  };

  if (contentOnly) {
    return renderContent();
  }

  return (
    <Container testID={testID}>
      <Portal>
        <Modalize ref={ref} adjustToContentHeight handlePosition={'inside'}>
          {renderContent()}
        </Modalize>
      </Portal>
    </Container>
  );
};

export default forwardRef(ModalDatePicker);
