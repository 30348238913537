import { LineStyle, StyledText } from 'react-native-markdown-editor';
import { DefaultTheme } from 'styled-components/native';

type TextFontInput = Omit<StyledText, 'text'> & { theme: DefaultTheme };

export const getTextFont = ({
  bold,
  italic,
  theme,
}: TextFontInput): string | undefined => {
  let textFontFamily = undefined;
  if (bold && italic) {
    textFontFamily = theme.fontFamily.core.w600Italic;
  }
  if (bold) {
    textFontFamily = theme.fontFamily.core.w600;
  }
  if (italic) {
    textFontFamily = theme.fontFamily.core.w400Italic;
  }
  return textFontFamily ? `font-family: ${textFontFamily}` : '';
};

type LineFontFamilyInput = {
  lineStyle: LineStyle;
  theme: DefaultTheme;
};

export const getLineFontFamily = ({
  lineStyle,
  theme,
}: LineFontFamilyInput): string => {
  if (lineStyle === '#') {
    return theme.fontFamily.core.w600;
  }
  if (lineStyle === '##') {
    return theme.fontFamily.core.w600;
  }
  if (lineStyle === '###') {
    return theme.fontFamily.core.w600;
  }
  return theme.fontFamily.core.w400;
};

export const getLineTextSize = (lineStyle: LineStyle): number => {
  if (lineStyle === '#') {
    return 18;
  }
  if (lineStyle === '##') {
    return 16;
  }
  if (lineStyle === '###') {
    return 14;
  }
  //body
  return 14;
};
