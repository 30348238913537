import React, { useEffect, useRef, useState } from 'react';
import { NavigationState } from '@react-navigation/native';
import { FlatList, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components/native';
import AdminHeader from '~/components/AdminHeader';
import Avatar from '~/components/Avatar';
import Badge from '~/components/Badge';
import Button from '~/components/Button';
import InputField, { InputFieldHandle } from '~/components/InputField';
import ModalCountryPicker, {
  ModalCountryPickerHandler,
} from '~/components/ModalCountryPicker';
import Paginator from '~/components/Paginator';
import { SettingPanel, SettingToggleItem } from '~/components/SettingPanel';
import Snackbar from '~/components/Snackbar';
import StepSizePicker from '~/components/StepSizePicker';
import { WebContainer } from '~/components/WebGrid';
import WebModalConfirm, {
  WebModalConfirmHandler,
} from '~/components/WebModalConfirm';
import WebModalSelector from '~/components/WebModalSelector';
import { SelectorItem } from '~/components/WebSelector';
import {
  ActionButtonsContainer,
  ActionsButton,
  Container,
  FlexRow,
  FlexRowCentered,
  FormContainer,
  GrownFlex,
  Name,
  SectionLabel,
  Spacer,
  Spinner,
} from '~/components/common/style.web';
import { COUNTRIES } from '~/data/constants';
import { mapUser } from '~/data/mappers';
import {
  EditUserGroupFields,
  EditUserGroupTeamFields,
} from '~/data/models/admin';
import { Country } from '~/data/models/marketProfile';
import { User } from '~/data/models/user';
import {
  AdminUserFields,
  AdminUserFields_groups,
} from '~/data/operations/admin/types/AdminUserFields';
import {
  AdminUpdateUserInput,
  Status,
  UsersUserGenderChoices,
} from '~/data/operations/global';
import { useAcceptUserInGroupMutation } from '~/data/operations/group/acceptUserInGroup';
import { getCountryFromPhoneCountryCode } from '~/data/utils';
import validateField from '~/screens/Auth/utils/validateField';
import { formattedDate } from '~/utils/dates';
import usePagination from '~/utils/hooks/usePagination';
import { t } from '~/utils/i18n';
import { isValidEmail, isValidPhone } from '~/utils/validators';
import { shouldAllowToSaveChanges } from '../../utils/FormUtils';
import GroupRow from './GroupRow';
import {
  GenderOption,
  GendersRow,
  RegisteredSinceText,
  UserGroupsControls,
} from './style';

type EditLayoutProps = {
  onBack: () => void;
  user?: AdminUserFields;
  navigationState?: NavigationState;
  loading?: boolean;
  onImportToERP: (
    userId: string,
    groupId: SelectorItem,
    instituteId: SelectorItem,
  ) => void;
  onCreatePasswordForgetLink: () => void;
  onAddToTeam: (team: SelectorItem) => void;
  onDeleteAccount: () => void;
  onUpdateUser: (input: AdminUpdateUserInput) => void;
  onGroupPress: (id: string) => void;
  openDrawer: () => void;
};

export type UserGroupAction = 'acceptPending' | 'removeGroup' | 'addToTeam';

export default function EditUser({
  user,
  onBack,
  onImportToERP,
  onCreatePasswordForgetLink,
  onAddToTeam,
  onDeleteAccount,
  onUpdateUser,
  loading = false,
  onGroupPress,
  openDrawer,
}: EditLayoutProps): JSX.Element {
  const theme = useTheme();
  const isDesktop = useMediaQuery({ minWidth: theme.breakpoints.desktopMin });

  // ____MODAL REFS____
  const countryModalizeRef = useRef<ModalCountryPickerHandler>(null);
  const confirmModalRef = useRef<WebModalConfirmHandler>(null);
  const addtoTeamModalRef = useRef<WebModalConfirmHandler>(null);
  const importToErpModalRef = useRef<WebModalConfirmHandler>(null);

  // ____FORM REFS____
  const firstNameRef = useRef<InputFieldHandle>(null);
  const lastNameRef = useRef<InputFieldHandle>(null);
  const emailRef = useRef<InputFieldHandle>(null);
  const phoneRef = useRef<InputFieldHandle>(null);

  // ____FORM STATE____
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [country, setCountry] = useState<Country>('DE');
  const [gender, setGender] = useState<UsersUserGenderChoices | null>(null);
  const [emarsysOptIn, setEmarsysOptIn] = useState<boolean | null>(null);
  const [accountActivated, setAccountActivated] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<{
    group: EditUserGroupFields;
    action: UserGroupAction;
  } | null>(null);
  const [selectedTeamId, setSelectedTeam] = useState<SelectorItem | null>(null);
  const [erpSelectedInstitute, setErpSelectedInstitute] =
    useState<SelectorItem | null>(null);
  const [erpFilteredGroups, setErpFilteredGroups] = useState<
    AdminUserFields_groups[] | []
  >([]);
  const [erpSelectedGroup, setErpSelectedGroup] = useState<SelectorItem | null>(
    null,
  );

  // ____MUTATIONS____
  const [acceptUserInGroup] = useAcceptUserInGroupMutation();

  const [groupsPerPage, setGroupsPerPage] = useState<number>(5);
  const userGroups = (
    user?.groups !== null ? user?.groups || [] : []
  ) as EditUserGroupFields[];
  const userGroupsCount = userGroups.length || 0;
  const userGroupInstitutes = userGroups.map((group) => group.institute);

  const {
    allowNextPage,
    allowPrevPage,
    applyPaginationOnData,
    currentPage: currentGroupsPage,
    decrementPage,
    incrementPage,
    resetPagination,
    totalNumberOfPages: totalGroupPages,
    updatePage,
  } = usePagination({
    itemsPerPage: groupsPerPage,
    totalItems: userGroupsCount,
  });

  useEffect(() => {
    if (!user) {
      return;
    }

    setFirstName(user.firstName);
    setLastName(user.lastName);
    setEmail(user.email);
    setPhone(user.phone ?? '');
    setCountry(getCountryFromPhoneCountryCode(user.phoneCountryCode));
    setGender(user.gender);
    setEmarsysOptIn(user.optIn || false);
    setAccountActivated(user.isActive);
  }, [user]);

  const renderGroupRow = ({ item: group }: { item: EditUserGroupFields }) => {
    const groupTeams = (user?.teams?.filter(
      (team) => team?.group.id === group.id,
    ) || []) as EditUserGroupTeamFields[];

    const status = group.users?.find(
      (groupUser) => groupUser?.user.id === user?.id,
    )?.status;

    return (
      <GroupRow
        group={group}
        teams={groupTeams}
        status={status}
        onPress={() => onGroupPress(group.id)}
        onActionPress={(action) => {
          if (group === null) {
            return;
          }
          setSelectedGroup({ group, action });
          confirmModalRef.current?.open();
        }}
      />
    );
  };

  // ____FORM VALIDATION____
  const { state: emailState, info: emailInfo } = validateField({
    field: email,
    isValidField: isValidEmail,
    info: {
      success: t('inputValidation.validEmail'),
    },
    ignoreValidation: email === user?.email,
  });

  const { state: phoneState, info: phoneInfo } = validateField({
    field: phone,
    isValidField: isValidPhone,
    info: {
      success: t('inputValidation.validWhatsApp'),
    },
    ignoreValidation: phone === user?.phone,
  });

  const enableSaveChanges = shouldAllowToSaveChanges([
    [firstName, user?.firstName],
    [lastName, user?.lastName],
    [email, user?.email],
    [phone, user?.phone],
    [gender, user?.gender],
    [emarsysOptIn, user?.optIn],
    [accountActivated, user?.isActive],
    [country, getCountryFromPhoneCountryCode(user?.phoneCountryCode)],
  ]);

  const isGroupActionActive = selectedGroup !== null;

  const getConfirmModalTitle = () => {
    const personName = mapUser(user as User)?.name;
    if (isGroupActionActive) {
      return selectedGroup?.action === 'removeGroup'
        ? t('adminPanelSearch.removeMember', {
            personName,
            groupName: selectedGroup.group.name,
          })
        : t('adminPanelSearch.acceptMember', {
            personName,
            groupName: selectedGroup.group.name,
          });
    }

    return t('adminPanelSearch.deleteUser', { personName });
  };

  const getConfirmModalDescription = () => {
    if (isGroupActionActive) {
      return selectedGroup?.action === 'acceptPending'
        ? t('adminPanelSearch.acceptMemberWarning')
        : undefined;
    }

    return t('adminPanelSearch.deleteUserWarning');
  };

  const getConfirmButtonText = () => {
    if (isGroupActionActive) {
      return selectedGroup?.action === 'removeGroup'
        ? t('g.remove')
        : t('adminPanelSearch.acceptPendingGroup');
    }

    return t('g.delete');
  };

  const onConfirmGroupAction = async () => {
    if (selectedGroup !== null) {
      switch (selectedGroup.action) {
        case 'acceptPending':
          await onAcceptUserInGroup();
          break;
        case 'removeGroup':
          // TODO
          // eslint-disable-next-line no-console
          console.log('REMOVE GROUP ACTION');
          break;
        default:
          break;
      }
    }

    setSelectedGroup(null);
    confirmModalRef.current?.close();
  };

  const onAcceptUserInGroup = async () => {
    let isSuccess = false;

    try {
      if (selectedGroup && user) {
        const { data } = await acceptUserInGroup({
          variables: {
            input: {
              userId: user.id,
              groupId: selectedGroup.group.id,
              accept: true,
            },
          },
        });

        isSuccess =
          data?.acceptUserInGroup?.userGroup?.status === Status.ACCEPTED ||
          false;
      }

      if (isSuccess) {
        Snackbar.show(t('adminPanelSearch.acceptMemberSuccess'));
        return;
      } else {
        Snackbar.show(t('adminPanelSearch.acceptMemberFailure'));
        return;
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const getSelectableTeamsToAdd = () => {
    const allTeams = [
      ...userGroups
        .filter(
          (group) =>
            userGroupsCount === 1 || group.id === selectedGroup?.group.id,
        )
        .map((group) => group.teams)
        .filter((teams) => teams !== null),
    ].flat();

    if (allTeams === null) {
      return [];
    }

    return allTeams
      .map((team) => {
        if (team === null) return;
        return {
          id: team.id,
          name: team.name,
        };
      })
      .filter((team) => Boolean(team)) as SelectorItem[];
  };

  const onConfirmDeleteUser = async () => {
    confirmModalRef.current?.close();
    onDeleteAccount();
  };

  const onSubmitChanges = () => {
    if (!user || !enableSaveChanges) {
      return;
    }

    const countryInfo = COUNTRIES[country];

    const data: AdminUpdateUserInput = {
      email,
      firstName,
      lastName,
      gender,
      phone,
      optIn: emarsysOptIn,
      isActive: accountActivated,
      phoneCountryCode: countryInfo.code,
      id: user.id,
    };
    onUpdateUser(data);
  };

  const handleOnAddToTeam = () => {
    if (!selectedTeamId) {
      return;
    }

    onAddToTeam(selectedTeamId);
    setSelectedGroup(null);
    setSelectedTeam(null);
    addtoTeamModalRef.current?.close();
  };

  const onSelectErpInstitute = (item: SelectorItem) => {
    setErpSelectedInstitute(item);

    const filteredGroups = userGroups.filter(
      (group) => group.institute.id === item.id,
    );

    if (filteredGroups.length === 1) {
      setErpSelectedGroup({
        id: filteredGroups[0].id,
        name: filteredGroups[0].name,
      } as SelectorItem);
    }

    setErpFilteredGroups(filteredGroups);
  };

  const handleOnImportToErp = () => {
    if (!erpSelectedGroup || !erpSelectedInstitute || !user) {
      return;
    }

    onImportToERP(user.id, erpSelectedGroup, erpSelectedInstitute);
    setErpSelectedGroup(null);
    setErpSelectedInstitute(null);
    importToErpModalRef.current?.close();
  };

  if (loading) {
    return (
      <Container centerContent>
        <Spinner testID="loadingSpinner" />
      </Container>
    );
  }

  const clearErpSelectedFields = () => {
    setErpSelectedInstitute(null);
    setErpSelectedGroup(null);
  };

  const paginatedGroups = applyPaginationOnData(userGroups);

  return (
    <Container>
      <AdminHeader
        onOpenDrawer={openDrawer}
        title={t('screens.editUser')}
        onBack={onBack}
        onSubmitClick={onSubmitChanges}
        enableSaveChanges={enableSaveChanges}
        breadcrumbs={['Search', 'EditUser']}
        buttonTitle={t('adminPanelSearch.saveChanges')}
      />
      <WebContainer isDesktop={isDesktop}>
        <FormContainer isDesktop={isDesktop}>
          <FlexRowCentered>
            <Avatar uri={user?.avatar} size={isDesktop ? 126 : 61} />
            <Spacer h={32} />
            <View>
              <FlexRowCentered>
                <Name isDesktop={isDesktop}>{mapUser(user as User)?.name}</Name>
                {user?.isImported && (
                  <Badge
                    text={t('adminPanelSearch.abihomeImport')}
                    colorTheme="primary"
                  />
                )}
              </FlexRowCentered>
              <RegisteredSinceText isDesktop={isDesktop}>
                {t('adminPanelSearch.registeredSince', {
                  date: formattedDate(user?.created),
                })}
              </RegisteredSinceText>
            </View>
          </FlexRowCentered>
          <Spacer v={40} />
          <View>
            <SectionLabel>{t('adminPanelSearch.actions')}</SectionLabel>
            <Spacer v={16} />
            <ActionButtonsContainer isDesktop={isDesktop}>
              <ActionsButton
                type={'large'}
                text={t('adminPanelSearch.importToERP')}
                onPress={() => importToErpModalRef.current?.open()}
                iconStyled={'add_user'}
                testID="importToErpBtn"
              />
              <ActionsButton
                type={'large'}
                text={t('adminPanelSearch.createPasswordForgetLink')}
                onPress={onCreatePasswordForgetLink}
                iconStyled={'icn/teams/paper_plane'}
                testID="createPasswordForgetLinkBtn"
              />
              <ActionsButton
                type={'large'}
                text={t('adminPanelSearch.addToTeam')}
                onPress={() => addtoTeamModalRef.current?.open()}
                iconStyled={'icn/teams/home'}
                testID="addToTeamBtn"
              />
              <ActionsButton
                type={'large'}
                testID={'deleteAccountBtn'}
                text={t('adminPanelSearch.deleteAccount')}
                onPress={() => {
                  setSelectedGroup(null);
                  confirmModalRef.current?.open();
                }}
                iconStyled={'delete_red'}
                textColor={theme.color.error}
                intent="danger"
              />
            </ActionButtonsContainer>
          </View>
          <Spacer v={56} />
          <FlexRow isDesktop={isDesktop}>
            <GrownFlex>
              <SectionLabel>{t('adminPanelSearch.contactData')}</SectionLabel>
              <Spacer v={isDesktop ? 24 : 18} />
              <InputField
                ref={firstNameRef}
                testID={'inputFirstName'}
                label={t('signUpName.firstNameLabel')}
                placeholder={t('signUpName.firstNamePlaceholder')}
                value={firstName}
                onChangeText={(value) => setFirstName(value)}
                blurOnSubmit={false}
                returnKeyType={'next'}
                onSubmitEditing={() => lastNameRef.current?.focus()}
              />
              <InputField
                ref={lastNameRef}
                testID={'inputLastName'}
                label={t('signUpName.lastNameLabel')}
                placeholder={t('signUpName.lastNamePlaceholder')}
                value={lastName}
                blurOnSubmit={false}
                returnKeyType={'next'}
                onChangeText={(value) => setLastName(value)}
                onSubmitEditing={() => emailRef.current?.focus()}
              />
              <InputField
                ref={emailRef}
                testID={'inputEmail'}
                state={emailState}
                infoLabel={emailInfo}
                iconName={'mail-01'}
                label={t('adminPanelSearch.emailPlaceholder')}
                placeholder={t('adminPanelSearch.emailPlaceholder')}
                value={email}
                onChangeText={(value) => setEmail(value)}
                blurOnSubmit={false}
                returnKeyType={'next'}
                autoCapitalize={'none'}
                onSubmitEditing={() => phoneRef.current?.focus()}
              />
              <InputField
                ref={phoneRef}
                testID={'inputPhone'}
                state={phoneState}
                infoLabel={phoneInfo}
                country={country}
                onPhonePress={() => countryModalizeRef.current?.open()}
                keyboardType={'numeric'}
                label={t('signUpContact.phoneLabel')}
                placeholder={t('signUpContact.phonePlaceholder')}
                value={phone}
                blurOnSubmit={false}
                returnKeyType={'next'}
                onChangeText={(value) => setPhone(value)}
                onSubmitEditing={() => null}
              />
              {country ? (
                <ModalCountryPicker
                  testID={'countryPicker'}
                  ref={countryModalizeRef}
                  initialSelectedCountry={country}
                  onUpdateCountry={(country) => {
                    countryModalizeRef.current?.close();
                    setCountry(country);
                  }}
                />
              ) : null}
            </GrownFlex>
            <Spacer h={24} v={!isDesktop ? 24 : 0} />
            <GrownFlex>
              <View>
                <SectionLabel>
                  {t('adminPanelSearch.selectGender')}
                </SectionLabel>
                <Spacer v={isDesktop ? 24 : 18} />
                <GendersRow>
                  <GenderOption
                    testID={'femaleCardOption'}
                    text={t('g.female')}
                    iconStyledName={'gender_women'}
                    isSelected={gender === UsersUserGenderChoices.FEMALE}
                    onPress={() => setGender(UsersUserGenderChoices.FEMALE)}
                  />
                  <GenderOption
                    testID={'maleCardOption'}
                    text={t('g.male')}
                    iconStyledName={'gender_men'}
                    isSelected={gender === UsersUserGenderChoices.MALE}
                    onPress={() => setGender(UsersUserGenderChoices.MALE)}
                  />
                  <GenderOption
                    testID={'diversCardOption'}
                    text={t('g.divers')}
                    iconStyledName={'gender_transgender'}
                    isSelected={gender === UsersUserGenderChoices.OTHER}
                    onPress={() => setGender(UsersUserGenderChoices.OTHER)}
                  />
                </GendersRow>
              </View>
              <Spacer v={isDesktop ? 78 : 28} />
              <View>
                <SectionLabel>{t('adminPanelSearch.settings')}</SectionLabel>
                <SettingPanel>
                  <SettingToggleItem
                    testID={'emarsysOptIn'}
                    text={t('adminPanelSearch.emarsysOptIn')}
                    checked={emarsysOptIn ?? false}
                    onToggleChange={setEmarsysOptIn}
                  />
                  <SettingToggleItem
                    testID={'accountActivated'}
                    text={t('adminPanelSearch.accountActivated')}
                    checked={accountActivated}
                    onToggleChange={setAccountActivated}
                  />
                </SettingPanel>
              </View>
            </GrownFlex>
          </FlexRow>
          <Spacer v={56} />
          <FlatList
            data={paginatedGroups}
            ListHeaderComponent={
              <SectionLabel>
                {t('adminPanelSearch.institutesAndGroups')}
              </SectionLabel>
            }
            renderItem={renderGroupRow}
            ListFooterComponent={
              userGroupsCount > 0 ? (
                <UserGroupsControls>
                  <StepSizePicker
                    title={t('adminPanelSearch.entries')}
                    onStepSizeChange={(v: number) => {
                      setGroupsPerPage(v);
                      resetPagination();
                    }}
                    stepSize={groupsPerPage}
                    options={[5, 10, 25, 50]}
                  />
                  <Paginator
                    currentPage={currentGroupsPage}
                    allowNext={allowNextPage}
                    allowPrev={allowPrevPage}
                    onNextClick={incrementPage}
                    onPrevClick={decrementPage}
                    numberOfPages={totalGroupPages}
                    onPageClick={updatePage}
                  />
                </UserGroupsControls>
              ) : null
            }
          />
          {!isDesktop && (
            <>
              <Spacer v={24} />
              <Button
                testID="saveChangesBtn"
                text={t('adminPanelSearch.saveChanges')}
                onPress={onSubmitChanges}
                state={enableSaveChanges ? 'activeWeb' : 'disabled'}
                size={'lg'}
              />
            </>
          )}
        </FormContainer>
      </WebContainer>
      <WebModalSelector
        title={t('adminPanelSearch.importToERP')}
        buttonText={t('adminPanelSearch.importToERP')}
        confirmButtonTestId="importToErpConfirmBtn"
        ref={importToErpModalRef}
        onConfirm={handleOnImportToErp}
        onClose={clearErpSelectedFields}
        webSelectors={[
          {
            id: 'ErpImportInstituteSelector',
            iconName: 'icn/teams/institute',
            label: t('adminPanelSearch.selectInstitute'),
            isEmptyText: t('adminPanelSearch.chooseInstitutePlaceholder'),
            items: userGroupInstitutes
              .filter(
                (institute, index) =>
                  userGroupInstitutes.findIndex(
                    (item) => item.internalId === institute.internalId,
                  ) === index,
              )
              .map((institute) => {
                return { id: institute.id, name: institute.name };
              }),
            onItemSelect: (item) => onSelectErpInstitute(item),
            title: t('adminPanelSearch.chooseInstitute'),
            testID: 'erpImportInstituteSelector',
          },
          {
            id: 'ErpImportGroupSelector',
            iconName: 'icn/teams/flag',
            label: t('adminPanelSearch.selectGroup'),
            isEmptyText: t('adminPanelSearch.chooseGroup'),
            hidden: !erpSelectedInstitute || !(erpFilteredGroups.length > 1),
            items: erpFilteredGroups.map((group) => {
              return {
                id: group.id,
                name: group.name,
              };
            }),
            onItemSelect: (item) => setErpSelectedGroup(item),
            title: t('adminPanelSearch.selectAGroup'),
            testID: 'eprImportGroupSelector',
          },
        ]}
      />
      <WebModalConfirm
        title={getConfirmModalTitle()}
        description={getConfirmModalDescription()}
        icon="settings"
        onCancel={() => confirmModalRef.current?.close()}
        ref={confirmModalRef}
        onConfirm={
          isGroupActionActive ? onConfirmGroupAction : onConfirmDeleteUser
        }
        buttonText={getConfirmButtonText()}
        confirmButtonTestId="confirmBtn"
      />
      <WebModalSelector
        title="Add to team"
        buttonText="Add to team"
        confirmButtonTestId="addToTeamConfirmBtn"
        ref={addtoTeamModalRef}
        onConfirm={handleOnAddToTeam}
        webSelectors={[
          {
            id: 'GroupSelector',
            iconName: 'icn/teams/home',
            label: 'Choose a group',
            isEmptyText: 'Choose a group',
            hidden: userGroupsCount === 1,
            items: userGroups.map((group) => {
              return {
                id: group.id,
                name: group.name,
              };
            }),
            onItemSelect: (item) => {
              const selected = userGroups.find((grp) => grp.id === item.id);
              if (selected) {
                setSelectedTeam(null);
                setSelectedGroup({
                  group: selected,
                  action: 'addToTeam',
                });
              }
            },
            title: 'Choose a group',
            testID: 'groupSelector',
          },
          {
            id: 'TeamSelector',
            iconName: 'icn/teams/flag',
            label: 'Choose a team',
            isEmptyText: 'Choose a team',
            items: getSelectableTeamsToAdd(),
            onItemSelect: (item) => setSelectedTeam(item),
            title: 'Choose a team',
            testID: 'teamSelector',
          },
        ]}
      />
    </Container>
  );
}
