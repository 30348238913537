import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useState,
} from 'react';
import { Modalize } from 'react-native-modalize';
import { Portal } from 'react-native-portalize';
import Carousel, { Pagination } from 'react-native-snap-carousel';
import { useTheme } from 'styled-components/native';
import {
  Container,
  Content,
  CloseButtonIcon,
  FullImageContainer,
  FullImage,
  CONTAINER_WIDTH,
  PaginationContainer,
  CarouselContainer,
} from './style';

export type ModalFullImageHandler = Modalize;

export type ModalFullImageProps = {
  testID?: string;
  imgUrls: string[];
  selectedImgIndex: number;
  contentOnly?: boolean;
  onClose: () => void;
};

const ModalFullImage: ForwardRefRenderFunction<
  ModalFullImageHandler,
  ModalFullImageProps
> = ({ testID, imgUrls, selectedImgIndex, contentOnly, onClose }, ref) => {
  const theme = useTheme();
  const [activeIndex, setActiveIndex] = useState(selectedImgIndex);

  const renderImage = ({ item: imgUrl }: { item: string }) => (
    <FullImageContainer>
      <FullImage source={{ uri: imgUrl }} />
    </FullImageContainer>
  );

  useEffect(() => {
    setActiveIndex(selectedImgIndex);
  }, [selectedImgIndex]);

  const renderContent = () => {
    return (
      <Content>
        <CarouselContainer>
          <Carousel
            key={`s${selectedImgIndex}`}
            data={imgUrls}
            renderItem={renderImage}
            sliderWidth={CONTAINER_WIDTH}
            itemWidth={CONTAINER_WIDTH}
            inactiveSlideScale={1.0}
            inactiveSlideOpacity={1.0}
            firstItem={selectedImgIndex}
            onSnapToItem={setActiveIndex}
            removeClippedSubviews={false}
          />
        </CarouselContainer>
        <PaginationContainer>
          <Pagination
            key={`s${selectedImgIndex}`}
            dotsLength={imgUrls.length}
            activeDotIndex={activeIndex}
            containerStyle={{
              backgroundColor: theme.color.base.c1,
              paddingHorizontal: 8,
              paddingVertical: 6,
              borderRadius: 10,
            }}
            dotContainerStyle={{
              marginHorizontal: 2,
            }}
            dotStyle={{
              height: 10,
              width: 10,
              borderRadius: 5,
              backgroundColor: theme.color.base.c5,
            }}
            inactiveDotStyle={{
              height: 10,
              width: 10,
              borderRadius: 5,
              backgroundColor: theme.color.base.c3,
            }}
            inactiveDotOpacity={1.0}
            inactiveDotScale={1.0}
          />
        </PaginationContainer>
        <CloseButtonIcon onPress={onClose} />
      </Content>
    );
  };

  if (contentOnly) {
    return renderContent();
  }

  return (
    <Container testID={testID}>
      <Portal>
        <Modalize
          ref={ref}
          adjustToContentHeight
          withHandle={false}
          rootStyle={{ shadowOpacity: 0.8 }}
          modalStyle={{
            flex: 1,
            justifyContent: 'center',
            backgroundColor: 'transparent',
            marginTop: 20,
          }}
        >
          {renderContent()}
        </Modalize>
      </Portal>
    </Container>
  );
};

export default forwardRef(ModalFullImage);
