import { Dimensions } from 'react-native';
import styled from 'styled-components/native';
import ButtonIcon from '~/components/ButtonIcon';

type MainCenteredProps = {
  isMainCentered: boolean;
};

type MainLeftFlexProps = {
  isMainLeftFlex: boolean;
};

type MainRightFlexProps = {
  isMainRightFlex: boolean;
};

type MainContentProps = MainRightFlexProps & {
  isContentCentered?: boolean;
  isMainFixed?: boolean;
};

const VIEW_HEIGHT = Dimensions.get('window').height;
export const WRAPPER_WIDTH = 422;

const RIGHT_PANEL_PADDING = 48;
const IMAGE_RATIO = 0.7;
const IMAGE_HEIGHT = VIEW_HEIGHT - 2 * RIGHT_PANEL_PADDING;
const IMAGE_WIDTH = IMAGE_HEIGHT * IMAGE_RATIO;

/*
|----|---------MAIN-----------|----|
|----|--LEFT----|----RIGHT----|----|

|---------MAIN-----------|
|--LEFT----|----RIGHT----|

|-------MAIN-----------|
|--LEFT--|----RIGHT----|

|-------MAIN-----------|
|--LEFT--|--RIGHT--|

|--LEFT--|
*/
//-------------------- LAY 1 - isMainCentered = VIEW_WIDTH > MAIN_WIDTH --------------------

// |----|---------MAIN-----------|----|
// |----|--LEFT----|----RIGHT----|----|
const LEFT_PANEL_WIDTH = 24 + WRAPPER_WIDTH + 140;
const RIGHT_PANEL_WIDTH = 2 * RIGHT_PANEL_PADDING + IMAGE_WIDTH;
export const MAIN_WIDTH = LEFT_PANEL_WIDTH + RIGHT_PANEL_WIDTH;

//-------------------- LAY-2 - isMainLeftFlex = VIEW_WIDTH > MAIN_WIDTH_LEFT_MIN --------------------

// |---------MAIN-----------|
// |--LEFT----|----RIGHT----|

// flex on left panel from 140 to 48 center gap
// |-------MAIN-----------|
// |--LEFT--|----RIGHT----|
const LEFT_PANEL_WIDTH_MIN = 24 + WRAPPER_WIDTH + 48;
export const MAIN_WIDTH_LEFT_MIN = LEFT_PANEL_WIDTH_MIN + RIGHT_PANEL_WIDTH;

//-------------------- LAY-3 - isMainRightFlex = VIEW_WIDTH > MAIN_WIDTH_RIGHT_MIN --------------------

// |-------MAIN-----------|
// |--LEFT--|----RIGHT----|

// flex on right panel, image flex centered to 0.7*image width
// |-------MAIN-----------|
// |--LEFT--|--RIGHT--|
const IMAGE_WIDTH_MIN = IMAGE_WIDTH * 0.7;
const RIGHT_PANEL_WIDTH_MIN = 2 * RIGHT_PANEL_PADDING + IMAGE_WIDTH_MIN;
export const MAIN_WIDTH_RIGHT_MIN =
  LEFT_PANEL_WIDTH_MIN + RIGHT_PANEL_WIDTH_MIN;

//-------------------- LAY-3 - isMainLeftOnly --------------------

// - LAY-4 - isMainLeftOnly
// |--LEFT--|

export const ScrollContainer = styled.ScrollView`
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const CenterContainer = styled.View<MainCenteredProps>`
  ${({ isMainCentered }) => (isMainCentered ? 'margin: 0 auto;' : 'flex: 1;')};
`;

export const MainContainer = styled.View<MainRightFlexProps>`
  ${({ isMainRightFlex }) => (isMainRightFlex ? 'height: 100vh;' : '')};
  flex-direction: row;
`;

export const FooterContainer = styled.View`
  z-index: -1;
  padding-top: 64px;
  padding-bottom: 48px;

  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const LeftPanel = styled.View<
  MainCenteredProps & MainLeftFlexProps & MainRightFlexProps & MainContentProps
>`
  ${({ isMainCentered, isMainLeftFlex, isMainRightFlex }) =>
    isMainCentered
      ? `width: ${LEFT_PANEL_WIDTH}px;`
      : isMainLeftFlex
      ? 'flex: 1;'
      : isMainRightFlex
      ? `width: ${LEFT_PANEL_WIDTH_MIN}px;`
      : 'flex: 1;'};
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: ${({ isMainFixed }) => (isMainFixed ? 48 : 24)}px;
`;

export const RightPanel = styled.View<MainLeftFlexProps>`
  ${({ isMainLeftFlex }) =>
    isMainLeftFlex ? `width: ${RIGHT_PANEL_WIDTH}px;` : 'flex:1;'};
  background-color: ${({ theme }) => theme.color.brand_01};
  padding: 48px;
`;

export const RightPanelImage = styled.Image`
  height: ${IMAGE_HEIGHT}px;
  aspect-ratio: ${IMAGE_RATIO};
`;

export const RightBG = styled.View`
  position: absolute;
  background-color: ${({ theme }) => theme.color.brand_01};
  right: 0px;
  top: 0px;
  width: ${RIGHT_PANEL_WIDTH}px;
  height: 100vh;
`;

export const WrapperHeader = styled.View<MainRightFlexProps>`
  ${({ isMainRightFlex }) =>
    isMainRightFlex ? ` width: ${WRAPPER_WIDTH}px;` : ''};
`;

export const WrapperFooter = styled.View`
  height: 80px;
`;

export const WrapperContent = styled.View<MainContentProps>`
  ${({ isMainRightFlex }) => (isMainRightFlex ? 'flex: 1;' : '')};
  justify-content: ${({ isContentCentered = true }) =>
    isContentCentered ? 'center' : 'flex-start'};
  min-height: auto;
  ${({ isMainFixed }) => (isMainFixed ? `width: ${WRAPPER_WIDTH}px;` : '')};
`;

export const LanguageAbsolutePlaceholder = styled.View`
  position: absolute;

  top: 0;
`;

export const ChildrenContainer = styled.View`
  padding-vertical: ${({ theme }) => theme.spacing.medium};
`;

export const AuthView = styled.View`
  flex: 1;
`;

export const BackButton = styled(ButtonIcon).attrs(({ theme }) => ({
  name: 'chevron-left',
  size: 20,
  color: theme.color.base.c7,
}))`
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 58px;
  border-radius: 29px;
  border: 2px solid ${({ theme }) => theme.color.base.c7};
`;

export const WebFormControls = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export const ScrollView = styled.ScrollView``;

export const WebScrollView = styled(ScrollView)<{ aboveBackButton?: boolean }>`
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100%;
  margin-top: ${({ aboveBackButton }) => (aboveBackButton ? '-89px' : '0')};
`;

export const WebGrid = styled.View`
  display: grid;
  grid-template-rows: auto 1fr auto;
`;

export const BackButtonContainer = styled.View`
  flex: 0.4;
`;

export const MainButtonContainer = styled.View`
  flex: 1;
`;
