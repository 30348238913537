import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { FeedStackParamList } from '~/navigation/types';
import Feed from '~/screens/Main/Feed';

const FeedStackNavigator = createStackNavigator<FeedStackParamList>();

export default function FeedStack(): JSX.Element {
  return (
    <FeedStackNavigator.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <FeedStackNavigator.Screen name={'Feed'} component={Feed} />
    </FeedStackNavigator.Navigator>
  );
}
