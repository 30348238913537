/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query GroupSample
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { GroupSample, GroupSampleVariables } from './types/GroupSample';
import { GROUP_SAMPLE } from './';

export function useGroupSampleQuery(
  options?: QueryHookOptions<GroupSample, GroupSampleVariables>,
): QueryResult<GroupSample, GroupSampleVariables> {
  return useQuery<GroupSample, GroupSampleVariables>(GROUP_SAMPLE, options);
}

export function useGroupSampleLazyQuery(
  options?: LazyQueryHookOptions<GroupSample, GroupSampleVariables>,
): QueryTuple<GroupSample, GroupSampleVariables> {
  return useLazyQuery<GroupSample, GroupSampleVariables>(GROUP_SAMPLE, options);
}

export function createGroupSampleMock({
  variables,
  data,
  error,
}: {
  variables?: GroupSampleVariables;
  data?: GroupSample;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: GROUP_SAMPLE,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
