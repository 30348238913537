import React from 'react';
import { IconName } from '~/components/Icon';
import { Container, CustomIcon, Text, RemoveButton, RemoveIcon } from './style';
import { BadgeType, BadgeSize, BadgeColorTheme } from './types';

type BadgeProps = {
  text: string;
  type?: BadgeType;
  size?: BadgeSize;
  colorTheme?: BadgeColorTheme;
  iconName?: IconName;
  remove?: boolean;
  onPress?: () => void;
  onRemovePress?: () => void;
};

export default function Badge({
  text,
  type = 'basic',
  size = 'small',
  colorTheme = 'gray',
  iconName,
  remove = false,
  onPress,
  onRemovePress,
}: BadgeProps): JSX.Element {
  return (
    <Container
      size={size}
      type={type}
      colorTheme={colorTheme}
      remove={remove}
      onPress={() => onPress && onPress()}
    >
      {iconName ? <CustomIcon name={iconName} colorTheme={colorTheme} /> : null}
      <Text size={size} colorTheme={colorTheme}>
        {text}
      </Text>
      {remove ? (
        <RemoveButton onPress={() => onRemovePress && onRemovePress()}>
          <RemoveIcon name={'x-close'} colorTheme={colorTheme} />
        </RemoveButton>
      ) : null}
    </Container>
  );
}
