import { Platform } from 'react-native';
import styled from 'styled-components/native';
import Image from '~/components/Image';
import { StackScreenContainer } from '~/screens/style';

export const SafeAreaContainer = styled(StackScreenContainer)`
  background-color: ${({ theme }) => theme.color.brand_01};
`;

export const ScrollView = styled.ScrollView`
  padding-vertical: ${({ theme }) => theme.spacing.small};
`;

export const Header = styled.View`
  align-items: flex-end;
  padding-horizontal: ${({ theme }) => theme.spacing.medium};
`;

export const HeartImage = styled.Image`
  margin-top: 16px;
  width: 217px;
  height: 176px;
  transform: rotate(15deg);
`;

export const Content = styled.View`
  padding-horizontal: ${({ theme }) => theme.spacing.medium};
  padding-bottom: ${({ theme }) => theme.spacing.medium};
`;

export const Footer = styled.View`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: ${({ theme }) => theme.safeAreaInsets.bottom}px;
  padding-top: ${({ theme }) => theme.spacing.medium};
  padding-horizontal: ${({ theme }) => theme.spacing.medium};
  gap: 8px;
`;

export const Title = styled.Text`
  margin-top: 64px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.brand_02};
  font-size: 32px;
  line-height: 38px;
`;

export const Descp = styled.Text`
  flex: 1;
  margin-top: ${({ theme }) => theme.spacing.medium};
  ${Platform.OS === 'web' && 'margin-bottom: 56px'};
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c10};
  font-size: 18px;
`;

export const WebContainer = styled.View`
  width: 100%;
  max-width: 370px;
  text-align: center;
  align-items: center;
`;

export const CongratsImage = styled(Image)`
  width: 144px;
  height: 168px;
`;
