import * as React from 'react';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { useNavigation } from '@react-navigation/native';
import { TouchableOpacity } from 'react-native';
import { general } from '~/assets/imgs';
import { MainTabParamList } from '~/navigation/types';
import { Header, Logo, Title, Burger } from './style';

export default function MobileWebHeader({
  title,
  openDrawer,
}: {
  title: string;
  openDrawer?: () => void;
}): JSX.Element {
  const { openDrawer: defaultOpenDrawer } =
    useNavigation<DrawerNavigationProp<MainTabParamList>>();

  const handleOpenDrawer = () => {
    return openDrawer ? openDrawer() : defaultOpenDrawer();
  };

  return (
    <Header>
      <Logo source={general.gradooIcon} />
      <Title>{title}</Title>
      <TouchableOpacity onPress={handleOpenDrawer}>
        <Burger source={general.burger} />
      </TouchableOpacity>
    </Header>
  );
}
