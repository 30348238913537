import React from 'react';
import { Container, Title, Content, TouchableItem, ItemText } from './style';

export type SwitchItem = {
  key: string;
  title: string;
};

export type SwitchSelectorProps = {
  testID?: string;
  title?: string;
  items: SwitchItem[];
  selectedItemKey: string;
  disable?: boolean;
  onSelectedItem: (itemKey: string) => void;
};

export default function SwitchSelector({
  testID,
  title,
  items,
  selectedItemKey,
  disable = false,
  onSelectedItem,
}: SwitchSelectorProps): JSX.Element {
  return (
    <Container testID={testID}>
      {title && <Title>{title}</Title>}
      <Content>
        {items.map((item) => {
          const selected = selectedItemKey === item.key;
          return (
            <TouchableItem
              testID={`${testID}-${item.key}`}
              key={item.key}
              selected={selected}
              onPress={() => !disable && onSelectedItem(item.key)}
            >
              <ItemText selected={selected}>{item.title}</ItemText>
            </TouchableItem>
          );
        })}
      </Content>
    </Container>
  );
}
