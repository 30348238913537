/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query ReportsInstance
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  ReportsInstance,
  ReportsInstanceVariables,
} from './types/ReportsInstance';
import { REPORTS_INSTANCE } from './';

export function useReportsInstanceQuery(
  options?: QueryHookOptions<ReportsInstance, ReportsInstanceVariables>,
): QueryResult<ReportsInstance, ReportsInstanceVariables> {
  return useQuery<ReportsInstance, ReportsInstanceVariables>(
    REPORTS_INSTANCE,
    options,
  );
}

export function useReportsInstanceLazyQuery(
  options?: LazyQueryHookOptions<ReportsInstance, ReportsInstanceVariables>,
): QueryTuple<ReportsInstance, ReportsInstanceVariables> {
  return useLazyQuery<ReportsInstance, ReportsInstanceVariables>(
    REPORTS_INSTANCE,
    options,
  );
}

export function createReportsInstanceMock({
  variables,
  data,
  error,
}: {
  variables?: ReportsInstanceVariables;
  data?: ReportsInstance;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: REPORTS_INSTANCE,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
