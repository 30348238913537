import { ApolloCache } from '@apollo/client';
import {
  TEAMS,
  TEAM_DETAIL_FIELDS,
  TEAM_SAMPLES,
  TEAM_SAMPLE_FIELDS,
} from './';
import { TeamDetailFields } from './types/TeamDetailFields';
import { TeamSampleFields } from './types/TeamSampleFields';
import { TeamSamples, TeamSamplesVariables } from './types/TeamSamples';
import { Teams, TeamsVariables } from './types/Teams';

export function readTeamsQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: TeamsVariables;
}): Teams | null {
  return cache.readQuery<Teams>({
    query: TEAMS,
    variables,
  });
}

export function writeTeamsQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: TeamsVariables;
  data: Teams;
}): void {
  cache.writeQuery<Teams>({
    query: TEAMS,
    variables,
    data,
  });
}

export function readTeamSamplesQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: TeamSamplesVariables;
}): TeamSamples | null {
  return cache.readQuery<TeamSamples>({
    query: TEAM_SAMPLES,
    variables,
  });
}

export function writeTeamSamplesQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: TeamSamplesVariables;
  data: TeamSamples;
}): void {
  cache.writeQuery<TeamSamples>({
    query: TEAM_SAMPLES,
    variables,
    data,
  });
}

export function readTeamDetailFragment({
  cache,
  id,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
}): TeamDetailFields | null {
  return cache.readFragment<TeamDetailFields>({
    id: `TeamNode:${id}`,
    fragment: TEAM_DETAIL_FIELDS,
    fragmentName: 'TeamDetailFields',
  });
}

export function writeTeamDetailFragment({
  cache,
  id,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
  data: TeamDetailFields;
}): void {
  cache.writeFragment<TeamDetailFields>({
    id: `TeamNode:${id}`,
    fragment: TEAM_DETAIL_FIELDS,
    fragmentName: 'TeamDetailFields',
    data,
  });
}

export function readTeamSampleFragment({
  cache,
  id,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
}): TeamSampleFields | null {
  return cache.readFragment<TeamSampleFields>({
    id: `TeamNode:${id}`,
    fragment: TEAM_SAMPLE_FIELDS,
    fragmentName: 'TeamSampleFields',
  });
}

export function writeTeamSampleFragment({
  cache,
  id,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
  data: TeamSampleFields;
}): void {
  cache.writeFragment<TeamSampleFields>({
    id: `TeamNode:${id}`,
    fragment: TEAM_SAMPLE_FIELDS,
    fragmentName: 'TeamSampleFields',
    data,
  });
}
