import { ApolloCache } from '@apollo/client';
import {
  BASE_POST_FIELDS,
  POLLS,
  POLL_FIELDS,
  POLL_OPTION_FIELDS,
  POSTS,
  POST_FIELDS,
  COMMENT_FIELDS,
} from './';
import { CommentFields } from './types/CommentFields';
import { PollFields } from './types/PollFields';
import { PollOptionFields } from './types/PollOptionFields';
import { Polls, PollsVariables } from './types/Polls';
import { PostFields } from './types/PostFields';
import { Posts, PostsVariables } from './types/Posts';

export function readPostsQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: PostsVariables;
}): Posts | null {
  return cache.readQuery<Posts>({
    query: POSTS,
    variables,
  });
}

export function writePostsQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: PostsVariables;
  data: Posts;
}): void {
  cache.writeQuery<Posts>({
    query: POSTS,
    variables,
    data,
  });
}

export function readBasePostFragment({
  cache,
  id,
  authUserGroupId,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
  authUserGroupId: string;
}): PostFields | null {
  return cache.readFragment<PostFields, { authUserGroupId: string }>({
    id: `PostNode:${id}`,
    fragment: BASE_POST_FIELDS,
    fragmentName: 'BasePostFields',
    variables: { authUserGroupId },
  });
}

export function readPostFragment({
  cache,
  id,
  authUserGroupId,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
  authUserGroupId: string;
}): PostFields | null {
  return cache.readFragment<PostFields, { authUserGroupId: string }>({
    id: `PostNode:${id}`,
    fragment: POST_FIELDS,
    fragmentName: 'PostFields',
    variables: { authUserGroupId },
  });
}

export function writePostFragment({
  cache,
  id,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
  data: PostFields;
}): void {
  cache.writeFragment({
    id: `PostNode:${id}`,
    fragment: POST_FIELDS,
    fragmentName: 'PostFields',
    data,
  });
}

export function readPollsQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: PollsVariables;
}): Polls | null {
  return cache.readQuery<Polls>({
    query: POLLS,
    variables,
  });
}

export function writePollsQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: PollsVariables;
  data: Polls;
}): void {
  cache.writeQuery<Polls>({
    query: POLLS,
    variables,
    data,
  });
}

export function readPollFragment({
  cache,
  id,
  authUserGroupId,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
  authUserGroupId: string;
}): PollFields | null {
  return cache.readFragment<PollFields, { authUserGroupId: string }>({
    id: `PollNode:${id}`,
    fragment: POLL_FIELDS,
    fragmentName: 'PollFields',
    variables: { authUserGroupId },
  });
}

export function writePollFragment({
  cache,
  id,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
  data: PollFields;
}): void {
  cache.writeFragment({
    id: `PollNode:${id}`,
    fragment: POLL_FIELDS,
    fragmentName: 'PollFields',
    data,
  });
}

export function readPollOptionFragment({
  cache,
  id,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
}): PollOptionFields | null {
  return cache.readFragment<PollOptionFields>({
    id: `PollOptionNode:${id}`,
    fragment: POLL_OPTION_FIELDS,
    fragmentName: 'PollOptionFields',
  });
}

export function writePollOptionFragment({
  cache,
  id,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
  data: PollOptionFields;
}): void {
  cache.writeFragment({
    id: `PollOptionNode:${id}`,
    fragment: POLL_OPTION_FIELDS,
    fragmentName: 'PollOptionFields',
    data,
  });
}

export function readCommentFragment({
  cache,
  id,
  authUserGroupId,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
  authUserGroupId: string;
}): CommentFields | null {
  return cache.readFragment<CommentFields, { authUserGroupId: string }>({
    id: `CommentNode:${id}`,
    fragment: COMMENT_FIELDS,
    fragmentName: 'CommentFields',
    variables: { authUserGroupId },
  });
}

export function wirteCommentFragment({
  cache,
  id,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
  data: CommentFields;
}): void {
  cache.writeFragment({
    id: `CommentNode:${id}`,
    fragment: COMMENT_FIELDS,
    fragmentName: 'CommentFields',
    data,
  });
}
