import React from 'react';
import { Platform } from 'react-native';
import { SvgProps } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
//styled-icons
import AbihomeSvg from '~/assets/svg/styled-icons/abihome.svg';
import AddUserLightSvg from '~/assets/svg/styled-icons/add_user-light.svg';
import AddUserSvg from '~/assets/svg/styled-icons/add_user.svg';
import ArrowDownUpSvg from '~/assets/svg/styled-icons/arrow-down-up.svg';
import AttachmentSvg from '~/assets/svg/styled-icons/attachment.svg';
import BagSvg from '~/assets/svg/styled-icons/bag.svg';
import BookBaseSvg from '~/assets/svg/styled-icons/book-base.svg';
import BookPrimarySvg from '~/assets/svg/styled-icons/book-primary.svg';
import BugSvg from '~/assets/svg/styled-icons/bug.svg';
import CalendarBaseSvg from '~/assets/svg/styled-icons/calendar-base.svg';
import CalendarPrimarySvg from '~/assets/svg/styled-icons/calendar-primary.svg';
import CheckGreenSvg from '~/assets/svg/styled-icons/check-green.svg';
import CheckPrimarySvg from '~/assets/svg/styled-icons/check-primary.svg';
import CommentSvg from '~/assets/svg/styled-icons/comment.svg';
import ContentsPrimarySvg from '~/assets/svg/styled-icons/contents-primary.svg';
import CoupleSvg from '~/assets/svg/styled-icons/couple.svg';
import CreativePrimarySvg from '~/assets/svg/styled-icons/creative-primary.svg';
import CreativeSvg from '~/assets/svg/styled-icons/creative.svg';
import DatabaseSvg from '~/assets/svg/styled-icons/database.svg';
import DeleteRedSvg from '~/assets/svg/styled-icons/delete-red.svg';
import DeleteSvg from '~/assets/svg/styled-icons/delete.svg';
import DownloadSvg from '~/assets/svg/styled-icons/download.svg';
import EditSvg from '~/assets/svg/styled-icons/edit.svg';
import EmailDarkSvg from '~/assets/svg/styled-icons/email-dark.svg';
import ErrorRedSvg from '~/assets/svg/styled-icons/error-red.svg';
import EveryoneSvg from '~/assets/svg/styled-icons/everyone.svg';
import FlameGreenSvg from '~/assets/svg/styled-icons/flame-green.svg';
import FlamePrimarySvg from '~/assets/svg/styled-icons/flame-primary.svg';
import FlameSvg from '~/assets/svg/styled-icons/flame.svg';
import FlameBaseSvg from '~/assets/svg/styled-icons/flame_base.svg';
import GenderIntersexSvg from '~/assets/svg/styled-icons/gender-intersex.svg';
import GenderMenSvg from '~/assets/svg/styled-icons/gender-men.svg';
import GenderTransgenderSvg from '~/assets/svg/styled-icons/gender-transgender.svg';
import GenderWomenSvg from '~/assets/svg/styled-icons/gender-women.svg';
import GraduationBaseSvg from '~/assets/svg/styled-icons/graduation-base.svg';
import GraduationPrimarySvg from '~/assets/svg/styled-icons/graduation-primary.svg';
import GraduationSvg from '~/assets/svg/styled-icons/graduation.svg';
import HeartRedSvg from '~/assets/svg/styled-icons/heart-red.svg';
import HeartSvg from '~/assets/svg/styled-icons/heart.svg';
import HelpDarkSvg from '~/assets/svg/styled-icons/help-dark.svg';
import ImageEmptySvg from '~/assets/svg/styled-icons/image-empty.svg';
import ImagesSvg from '~/assets/svg/styled-icons/images.svg';
import InfoSvg from '~/assets/svg/styled-icons/info.svg';
import JewelryBaseSvg from '~/assets/svg/styled-icons/jewelry-base.svg';
import JewelryPrimarySvg from '~/assets/svg/styled-icons/jewelry-primary.svg';
import JewelrySvg from '~/assets/svg/styled-icons/jewelry.svg';
import ListSvg from '~/assets/svg/styled-icons/list.svg';
import LocationDarkSvg from '~/assets/svg/styled-icons/location-dark.svg';
import LockDarkSvg from '~/assets/svg/styled-icons/lock-dark.svg';
import LogoutSvg from '~/assets/svg/styled-icons/logout.svg';
import OpenBookLightSvg from '~/assets/svg/styled-icons/open-book-light.svg';
import PersonSvg from '~/assets/svg/styled-icons/person.svg';
import PlanningBaseSvg from '~/assets/svg/styled-icons/planning-base.svg';
import PlanningPrimarySvg from '~/assets/svg/styled-icons/planning-primary.svg';
import PollLightSvg from '~/assets/svg/styled-icons/poll-light.svg';
import PollPrimarySvg from '~/assets/svg/styled-icons/poll-primary.svg';
import PollSvg from '~/assets/svg/styled-icons/poll.svg';
import SchoolSvg from '~/assets/svg/styled-icons/school.svg';
import SchoolDarkSvg from '~/assets/svg/styled-icons/school_dark.svg';
import SearchSvg from '~/assets/svg/styled-icons/search-md.svg';
import SendEmailSvg from '~/assets/svg/styled-icons/send-email.svg';
import SettingsSvg from '~/assets/svg/styled-icons/settings.svg';
import ShareSvg from '~/assets/svg/styled-icons/share.svg';
import StatisticPrimarySvg from '~/assets/svg/styled-icons/statistic-primary.svg';
import StatisticsSvg from '~/assets/svg/styled-icons/statistic.svg';
import StatisticGroupPrimarySvg from '~/assets/svg/styled-icons/statistic_group_primary.svg';
import SupporBaseSvg from '~/assets/svg/styled-icons/support-base.svg';
import TodoBaseSvg from '~/assets/svg/styled-icons/todo-base.svg';
import TodoPrimarySvg from '~/assets/svg/styled-icons/todo-primary.svg';
import UploadPrimarySvg from '~/assets/svg/styled-icons/upload-primary.svg';
import UserBaseSvg from '~/assets/svg/styled-icons/user-base.svg';
import UserDarkSvg from '~/assets/svg/styled-icons/user-dark.svg';
import UserPrimarySvg from '~/assets/svg/styled-icons/user-primary.svg';
import UserQuestionBase from '~/assets/svg/styled-icons/user-question-base.svg';
//teams
import BasketTeamSvg from '~/assets/svg/teams/basket.svg';
import BookTeamSvg from '~/assets/svg/teams/book.svg';
import BuildingTeamSvg from '~/assets/svg/teams/building.svg';
import BurgerTeamSvg from '~/assets/svg/teams/burger.svg';
import CarTeamSvg from '~/assets/svg/teams/car.svg';
import ClockTeamSvg from '~/assets/svg/teams/clock.svg';
import ClothingTeamSvg from '~/assets/svg/teams/clothing.svg';
import FlagTeamSvg from '~/assets/svg/teams/flag.svg';
import GamingTeamSvg from '~/assets/svg/teams/gaming.svg';
import GlitterTeamSvg from '~/assets/svg/teams/glitter.svg';
import HeartTeamSvg from '~/assets/svg/teams/heart.svg';
import HomeTeamSvg from '~/assets/svg/teams/home.svg';
import ImagesTeamSvg from '~/assets/svg/teams/images.svg';
import InstituteTeamSvg from '~/assets/svg/teams/institute.svg';
import LikeTeamSvg from '~/assets/svg/teams/like.svg';
import MegaphoneTeamSvg from '~/assets/svg/teams/megaphone.svg';
import MoneyEuroTeamSvg from '~/assets/svg/teams/money_euro.svg';
import PackageTeamSvg from '~/assets/svg/teams/package.svg';
import PaperPlaneTeamSvg from '~/assets/svg/teams/paper_plane.svg';
import PenTeamSvg from '~/assets/svg/teams/pen.svg';
import PlaneTeamSvg from '~/assets/svg/teams/plane.svg';
import PresentTeamSvg from '~/assets/svg/teams/present.svg';
import RocketTeamSvg from '~/assets/svg/teams/rocket.svg';
import StarTeamSvg from '~/assets/svg/teams/star.svg';
import TrophyTeamSvg from '~/assets/svg/teams/trophy.svg';
import VirusTeamSvg from '~/assets/svg/teams/virus.svg';
import WorldTeamSvg from '~/assets/svg/teams/world.svg';

type FixedColorIconStyleName =
  | 'calendar_primary'
  | 'calendar_base'
  | 'poll_primary'
  | 'todo_primary'
  | 'todo_base'
  | 'heart_red'
  | 'book_base'
  | 'book_primary'
  | 'flame_primary'
  | 'flame_green'
  | 'planning_base'
  | 'planning_primary'
  | 'user_base'
  | 'user_primary'
  | 'user_question_base'
  | 'add_user_light'
  | 'open_book_light'
  | 'poll_light'
  | 'check_primary'
  | 'check_green'
  | 'error_red'
  | 'email_dark'
  | 'lock_dark'
  | 'user_dark'
  | 'location_dark'
  | 'arrow_down_up'
  | 'help_dark'
  | 'support_base'
  | 'contents_primary'
  | 'creative_primary'
  | 'statistic_primary'
  | 'flame_base'
  | 'school_dark'
  | 'jewelry_base'
  | 'jewelry_primary'
  | 'graduation_primary'
  | 'graduation_base'
  | 'statistic_base'
  | 'creative_base'
  | 'statistic_group_primary'
  | 'upload_primary';

type FillColorIconStyleName =
  | 'comment'
  | 'add_user'
  | 'bug'
  | 'heart'
  | 'share'
  | 'settings'
  | 'jewelry'
  | 'poll'
  | 'search'
  | 'images'
  | 'info'
  | 'bag'
  | 'edit'
  | 'gender_intersex'
  | 'gender_men'
  | 'gender_transgender'
  | 'gender_women'
  | 'graduation'
  | 'everyone'
  | 'image_empty'
  | 'delete_red'
  | 'abihome'
  | 'person'
  | 'couple'
  | 'list'
  | 'download'
  | 'delete'
  | 'database'
  | 'attachment'
  | 'send_email'
  | 'school'
  | 'flame'
  | 'logout';

export type TeamIconName =
  | 'icn/teams/plane'
  | 'icn/teams/basket'
  | 'icn/teams/building'
  | 'icn/teams/book'
  | 'icn/teams/burger'
  | 'icn/teams/car'
  | 'icn/teams/clock'
  | 'icn/teams/clothing'
  | 'icn/teams/flag'
  | 'icn/teams/gaming'
  | 'icn/teams/glitter'
  | 'icn/teams/heart'
  | 'icn/teams/home'
  | 'icn/teams/images'
  | 'icn/teams/institute'
  | 'icn/teams/like'
  | 'icn/teams/megaphone'
  | 'icn/teams/money_euro'
  | 'icn/teams/package'
  | 'icn/teams/paper_plane'
  | 'icn/teams/pen'
  | 'icn/teams/present'
  | 'icn/teams/rocket'
  | 'icn/teams/star'
  | 'icn/teams/trophy'
  | 'icn/teams/virus'
  | 'icn/teams/world';

export type IconStyledName =
  | FixedColorIconStyleName
  | FillColorIconStyleName
  | TeamIconName;

type SvgIconMap = { [key in IconStyledName]: React.FC<SvgProps> };

const svgIconMap: SvgIconMap = {
  add_user: AddUserSvg,
  bug: BugSvg,
  calendar_primary: CalendarPrimarySvg,
  calendar_base: CalendarBaseSvg,
  poll_primary: PollPrimarySvg,
  support_base: SupporBaseSvg,
  todo_primary: TodoPrimarySvg,
  todo_base: TodoBaseSvg,
  comment: CommentSvg,
  heart: HeartSvg,
  heart_red: HeartRedSvg,
  share: ShareSvg,
  settings: SettingsSvg,
  book_base: BookBaseSvg,
  book_primary: BookPrimarySvg,
  flame: FlameSvg,
  flame_base: FlameBaseSvg,
  flame_primary: FlamePrimarySvg,
  flame_green: FlameGreenSvg,
  jewelry: JewelrySvg,
  planning_base: PlanningBaseSvg,
  planning_primary: PlanningPrimarySvg,
  user_base: UserBaseSvg,
  user_primary: UserPrimarySvg,
  user_question_base: UserQuestionBase,
  poll: PollSvg,
  images: ImagesSvg,
  info: InfoSvg,
  image_empty: ImageEmptySvg,
  bag: BagSvg,
  edit: EditSvg,
  gender_intersex: GenderIntersexSvg,
  gender_men: GenderMenSvg,
  gender_transgender: GenderTransgenderSvg,
  gender_women: GenderWomenSvg,
  graduation: GraduationSvg,
  everyone: EveryoneSvg,
  add_user_light: AddUserLightSvg,
  open_book_light: OpenBookLightSvg,
  poll_light: PollLightSvg,
  check_primary: CheckPrimarySvg,
  check_green: CheckGreenSvg,
  error_red: ErrorRedSvg,
  email_dark: EmailDarkSvg,
  lock_dark: LockDarkSvg,
  user_dark: UserDarkSvg,
  location_dark: LocationDarkSvg,
  logout: LogoutSvg,
  school: SchoolSvg,
  school_dark: SchoolDarkSvg,
  search: SearchSvg,
  abihome: AbihomeSvg,
  person: PersonSvg,
  couple: CoupleSvg,
  list: ListSvg,
  delete: DeleteSvg,
  attachment: AttachmentSvg,
  upload_primary: UploadPrimarySvg,
  database: DatabaseSvg,
  send_email: SendEmailSvg,
  delete_red: DeleteRedSvg,
  arrow_down_up: ArrowDownUpSvg,
  download: DownloadSvg,
  help_dark: HelpDarkSvg,
  contents_primary: ContentsPrimarySvg,
  statistic_base: StatisticsSvg,
  creative_base: CreativeSvg,
  statistic_group_primary: StatisticGroupPrimarySvg,
  creative_primary: CreativePrimarySvg,
  statistic_primary: StatisticPrimarySvg,
  jewelry_base: JewelryBaseSvg,
  jewelry_primary: JewelryPrimarySvg,
  graduation_primary: GraduationPrimarySvg,
  graduation_base: GraduationBaseSvg,
  'icn/teams/plane': PlaneTeamSvg,
  'icn/teams/basket': BasketTeamSvg,
  'icn/teams/building': BuildingTeamSvg,
  'icn/teams/book': BookTeamSvg,
  'icn/teams/burger': BurgerTeamSvg,
  'icn/teams/car': CarTeamSvg,
  'icn/teams/clock': ClockTeamSvg,
  'icn/teams/clothing': ClothingTeamSvg,
  'icn/teams/flag': FlagTeamSvg,
  'icn/teams/gaming': GamingTeamSvg,
  'icn/teams/glitter': GlitterTeamSvg,
  'icn/teams/heart': HeartTeamSvg,
  'icn/teams/home': HomeTeamSvg,
  'icn/teams/images': ImagesTeamSvg,
  'icn/teams/institute': InstituteTeamSvg,
  'icn/teams/like': LikeTeamSvg,
  'icn/teams/megaphone': MegaphoneTeamSvg,
  'icn/teams/money_euro': MoneyEuroTeamSvg,
  'icn/teams/package': PackageTeamSvg,
  'icn/teams/paper_plane': PaperPlaneTeamSvg,
  'icn/teams/pen': PenTeamSvg,
  'icn/teams/present': PresentTeamSvg,
  'icn/teams/rocket': RocketTeamSvg,
  'icn/teams/star': StarTeamSvg,
  'icn/teams/trophy': TrophyTeamSvg,
  'icn/teams/virus': VirusTeamSvg,
  'icn/teams/world': WorldTeamSvg,
};

type IconStyledProps = {
  testID?: string;
  name: IconStyledName;
  size?: number;
  color?: string;
  isActive?: boolean;
};

export default function IconStyled({
  testID,
  name,
  size = 24,
  color = useTheme().color.base.c0,
  ...style
}: IconStyledProps): JSX.Element {
  const SvgIcon = svgIconMap[name];
  const styleProps = Platform.OS === 'web' ? {} : style;
  if (!SvgIcon) {
    return <></>;
  }
  return (
    <SvgIcon
      {...styleProps}
      testID={testID}
      width={size}
      height={size}
      fill={color}
    />
  );
}
