import React, { useEffect, useRef, useState } from 'react';
import { NativeScrollEvent } from 'react-native';
import ModalDatePicker, {
  ModalDatePickerHandler,
} from '~/components/ModalDatePicker';
import ModalTimePicker, {
  ModalTimePickerHandler,
} from '~/components/ModalTimePicker';
import NavHeader from '~/components/NavHeader';
import SectionHeader from '~/components/SectionHeader';
import {
  SettingPanel,
  SettingToggleItem,
  SettingLabelItem,
} from '~/components/SettingPanel';
import { CalendarDateObject } from '~/data/models/calendar';
import { ModuleInstance } from '~/data/models/yearbook';
import { calendarDateObject, formattedDate, getUTCTime } from '~/utils/dates';
import { t } from '~/utils/i18n';
import { Container, CenterContent, Loading, ScrollView } from './style';

type ModuleSetupLayoutLoadingProps = {
  title: string;
  onBack: () => void;
};

export function ModuleSetupLayoutLoading({
  title,
  onBack,
}: ModuleSetupLayoutLoadingProps): JSX.Element {
  return (
    <Container>
      <NavHeader
        backButtonTestID={'buttonBack'}
        title={title}
        onBackPress={onBack}
      />
      <CenterContent>
        <Loading />
      </CenterContent>
    </Container>
  );
}

export type UpdateModuleInstanceInput = {
  isActive: boolean;
  dueDate: string | null;
};

type ModuleSetupLayoutLayoutProps = {
  title: string;
  moduleInstance: ModuleInstance;
  activateText: string;
  onBack: () => void;
  onUpdateModuleInstance: (
    updateModuleInstanceInput: UpdateModuleInstanceInput,
  ) => void;
  onEndReached?: () => void;
  onEndReachedBottomHeight?: number;
  children: JSX.Element;
};

export default function ModuleSetupLayout({
  title,
  moduleInstance,
  activateText,
  onBack,
  onUpdateModuleInstance,
  onEndReached,
  onEndReachedBottomHeight = 80,
  children,
}: ModuleSetupLayoutLayoutProps): JSX.Element {
  const dateModalizeRef = useRef<ModalDatePickerHandler>(null);
  const timeModalizeRef = useRef<ModalTimePickerHandler>(null);

  const getInitialDueDay = () =>
    moduleInstance.dueDate
      ? calendarDateObject(formattedDate(moduleInstance.dueDate, 'yyyy-MM-dd'))
      : null;

  const getInitialDueTime = () =>
    moduleInstance.dueDate
      ? new Date(Date.parse(moduleInstance.dueDate))
      : undefined;

  const [isActive, setActive] = useState(() => moduleInstance.isActive);

  const [dueDay, setDueDay] = useState<CalendarDateObject | null>(
    getInitialDueDay(),
  );

  const [dueTime, setDueTime] = useState<Date | undefined>(getInitialDueTime());

  const getDueDate = (): string | null => {
    if (dueDay && dueTime) {
      const day = formattedDate(dueDay.dateString, 'yyyy-MM-dd');
      const time = getUTCTime(dueTime);

      return `${day}T${time}`;
    }
    return null;
  };

  useEffect(() => {
    const updatedIsActive =
      moduleInstance && isActive != moduleInstance.isActive ? true : false;

    const newDueDate = getDueDate();

    const initialTime = getInitialDueTime();

    const updatedDueDate =
      newDueDate &&
      (dueDay?.dateString != getInitialDueDay()?.dateString ||
        (dueTime && initialTime && dueTime.getTime() != initialTime.getTime()))
        ? true
        : false;

    if (updatedIsActive || updatedDueDate) {
      onUpdateModuleInstance({
        isActive,
        dueDate: updatedDueDate ? newDueDate : moduleInstance.dueDate,
      });
    }
  }, [isActive, dueDay, dueTime]);

  const isCloseToBottom = ({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }: NativeScrollEvent) => {
    return (
      layoutMeasurement.height + contentOffset.y >=
      contentSize.height - onEndReachedBottomHeight
    );
  };

  return (
    <Container>
      <NavHeader
        backButtonTestID={'buttonBack'}
        title={title}
        onBackPress={onBack}
      />
      <ScrollView
        onScroll={({ nativeEvent }) => {
          if (isCloseToBottom(nativeEvent)) {
            onEndReached && onEndReached();
          }
        }}
        scrollEventThrottle={400}
      >
        <SectionHeader sub title={t('g.activateSection')} />
        <SettingPanel>
          <SettingToggleItem
            testID={'isActivateToggleItem'}
            text={activateText}
            checked={isActive}
            onToggleChange={setActive}
          />
        </SettingPanel>
        <SectionHeader sub title={t('g.deadline')} />
        <SettingPanel>
          <SettingLabelItem
            testID={'buttonDueDate'}
            text={t('g.dueDate')}
            labelText={
              dueDay ? formattedDate(dueDay.dateString, 'MMM d yyyy') : ''
            }
            onLabelPress={() => dateModalizeRef.current?.open()}
          />
          <SettingLabelItem
            testID={'buttonDueTime'}
            text={t('g.dueTime')}
            labelText={dueTime ? formattedDate(dueTime, 'hh:mm a') : ''}
            onLabelPress={() => timeModalizeRef.current?.open()}
          />
        </SettingPanel>
        {children}
      </ScrollView>
      <ModalDatePicker
        testID={'datePicker'}
        ref={dateModalizeRef}
        title={t('g.deadlineTime')}
        buttonText={t('g.confirmDate')}
        date={dueDay}
        onConfirmDate={(dateObj) => {
          setDueDay(dateObj);
          dateModalizeRef.current?.close();
        }}
      />
      <ModalTimePicker
        testID={'timePicker'}
        ref={timeModalizeRef}
        title={t('g.deadlineTime')}
        buttonText={t('g.confirmTime')}
        time={dueTime}
        onConfirmTime={(time) => {
          setDueTime(time);
          timeModalizeRef.current?.close();
        }}
      />
    </Container>
  );
}
