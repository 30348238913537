/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation CreateUser
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { CreateUser, CreateUserVariables } from './types/CreateUser';
import { CREATE_USER } from './';

export function useCreateUserMutation(
  options?: MutationHookOptions<CreateUser, CreateUserVariables>,
): MutationTuple<CreateUser, CreateUserVariables> {
  return useMutation<CreateUser, CreateUserVariables>(CREATE_USER, options);
}

export function createCreateUserMock({
  variables,
  data,
  error,
}: {
  variables?: CreateUserVariables;
  data?: CreateUser;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: CREATE_USER,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
