import styled from 'styled-components/native';
import Icon from '~/components/Icon';
import IconStyled from '../IconStyled';

type ContainerDesignProps = {
  backgroundColor?: string;
  border?: boolean;
  borderRadius?: number;
};

export const Container = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})<ContainerDesignProps>`
  padding-vertical: 8px;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.color.base.c2};
  border: ${({ border, theme }) =>
    border ? `1px solid ${theme.color.base.c2}` : 'none'};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : 6)}px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-horizontal: 16px;
`;

export const SectionIcon = styled(Icon).attrs(({ theme }) => ({
  size: 24,
  color: theme.color.base.c9,
}))``;

export const SectionStyledIcon = styled(IconStyled).attrs({
  size: 24,
})``;

export const Content = styled.View`
  flex: 1;
  padding-left: 16px;
  align-items: flex-start;
`;

export const Title = styled.Text`
  color: ${({ theme }) => theme.color.base.c9};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 4px;
`;

export const Descp = styled.Text`
  color: ${({ theme }) => theme.color.base.c6};
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 14px;
  line-height: 16px;
`;

export const Badge = styled.View`
  background-color: ${({ theme }) => theme.color.base.c5};
  justify-content: center;
  border-radius: 10px;
  padding: 4px 8px;
`;

export const BadgeTitle = styled.Text`
  color: ${({ theme }) => theme.color.base.c0};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 11px;
  line-height: 12px;
`;

export const RightIcon = styled(Icon).attrs(({ theme, color }) => ({
  size: 16,
  color: color ? color : theme.color.brand_02,
}))``;
