import styled from 'styled-components/native';
import Image from '~/components/Image';

export const MainImage = styled(Image)`
  margin-bottom: 20px;
  width: 93px;
  height: 130px;
`;

export const Title = styled.Text<{ align?: 'left' | 'center' | 'right' }>`
  text-align: ${({ align }) => (align ? align : 'left')};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 32px;
  margin-bottom: 10px;
`;

export const Desc = styled.Text<{ align?: 'left' }>`
  margin-bottom: 10px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c7};
  font-size: 18px;
`;
