import React, { useRef, useState } from 'react';
import { general } from '~/assets/imgs';
import Button from '~/components/Button';
import InputField from '~/components/InputField';
import MemberCell from '~/components/MemberCell';
import ModalConfirm, { ModalConfirmHandler } from '~/components/ModalConfirm';
import { FlexBox } from '~/components/ModalSwitchGroups/style';
import NavHeader from '~/components/NavHeader';
import SectionHeader from '~/components/SectionHeader';
import { mapUser } from '~/data/mappers';
import { Group, UserGroup } from '~/data/models/group';
import { getInviteUrl } from '~/utils/helpers';
import { t } from '~/utils/i18n';
import {
  Container,
  BGHeader,
  FlatList,
  Header,
  HeaderContent,
  InputView,
  MemberCellView,
  LoadingContent,
  Loading,
  RefreshControl,
  AllMembersContainer,
  ShadowContainer,
  AllMembersTextContainer,
  AllMembersNumber,
  AllMembersText,
  CheerStickerImage,
} from './style';

type GroupSetupLoadingLayoutProps = {
  onBack: () => void;
};

export function GroupSetupLoadingLayout({
  onBack,
}: GroupSetupLoadingLayoutProps): JSX.Element {
  return (
    <Container>
      <BGHeader />
      <NavHeader
        backButtonTestID={'buttonBack'}
        title={t('screens.groupSetup')}
        colorStyle={'light'}
        onBackPress={onBack}
      />
      <LoadingContent>
        <Loading />
      </LoadingContent>
    </Container>
  );
}

export type EditGroupInput = {
  name: string;
  typeId: string;
  year: string;
};

export type GroupSetupLayoutProps = {
  authUserId?: string;
  coreAdmin?: boolean;
  group: Group;
  acceptedUserGroups: UserGroup[];
  loading: boolean;
  loadingMore: boolean;
  now?: Date;
  onBack: () => void;
  onRemoveUser: (userId: string) => void;
  onRefresh: () => void;
  onShareLink: (url: string) => void;
  onLoadMoreUsers: () => void;
  onOpenSettings: () => void;
};

export default function GroupSetupLayout({
  authUserId,
  coreAdmin,
  group,
  acceptedUserGroups,
  loading,
  loadingMore,
  onBack,
  onRemoveUser,
  onShareLink,
  onRefresh,
  onLoadMoreUsers,
  onOpenSettings,
}: GroupSetupLayoutProps): JSX.Element {
  const modalizeRef = useRef<ModalConfirmHandler>(null);
  const { id: groupId, token } = group || {};
  const inviteUrl = groupId && token ? getInviteUrl(groupId, token) : '';

  const [removingUserGroup, setRemovingUserGroup] = useState<UserGroup | null>(
    null,
  );

  const [searchingText, setSearchingText] = useState('');

  const filteredUserGroups = acceptedUserGroups.filter(
    (userGroup) =>
      userGroup.user?.firstName.includes(searchingText) ||
      userGroup.user.lastName?.includes(searchingText),
  );

  const renderItem = ({ item: userGroup }: { item: UserGroup }) => {
    return (
      <MemberCellView>
        <MemberCell
          testID={`MemberCell:${userGroup.id}`}
          authUserId={authUserId}
          userGroup={userGroup}
          leaveText={t('g.leaveGroup')}
          onRemovePress={
            coreAdmin || userGroup.user.id === authUserId
              ? () => {
                  setRemovingUserGroup(userGroup);
                  modalizeRef.current?.open();
                }
              : undefined
          }
        />
      </MemberCellView>
    );
  };

  const removingUserGroupName = mapUser(removingUserGroup?.user)?.name;
  const leavingTeam = removingUserGroup?.user?.id === authUserId;

  return (
    <Container>
      <NavHeader
        backButtonTestID={'buttonBack'}
        title={t('screens.groupSetup')}
        onBackPress={onBack}
        RightComponent={
          coreAdmin && (
            <Button
              testID="buttonSettings"
              size="sm"
              text={t('settings')}
              bottomMargin={-12}
              type="secondary-base"
              onPress={() => onOpenSettings()}
            />
          )
        }
      />
      <FlatList<React.ElementType>
        testID={'flatList'}
        refreshControl={
          <RefreshControl refreshing={loading} onRefresh={onRefresh} />
        }
        ListHeaderComponent={
          <Header>
            {(() => {
              return (
                <HeaderContent>
                  <ShadowContainer>
                    <AllMembersContainer>
                      <FlexBox>
                        <AllMembersTextContainer>
                          <AllMembersNumber>
                            {`${acceptedUserGroups.length}`}
                          </AllMembersNumber>
                          <AllMembersText>{t('members')}</AllMembersText>
                        </AllMembersTextContainer>
                        <CheerStickerImage
                          source={general.stickerCheersToUs}
                          resizeMode={'contain'}
                        />
                      </FlexBox>
                    </AllMembersContainer>
                  </ShadowContainer>

                  <InputView>
                    <SectionHeader
                      title={t('manageGroup.allMembers')}
                      RightComponent={
                        <Button
                          testID={'inviteMember'}
                          size="sm"
                          text={t('manageGroup.inviteMembers')}
                          type="primary-brand-01"
                          onPress={() => onShareLink(inviteUrl)}
                        />
                      }
                    />
                    <InputField
                      testID={'inputSearch'}
                      iconName={'search-md'}
                      label={t('g.search')}
                      value={searchingText}
                      onChangeText={(value) => {
                        setSearchingText(value);
                      }}
                    />
                  </InputView>
                </HeaderContent>
              );
            })()}
          </Header>
        }
        data={filteredUserGroups}
        renderItem={renderItem}
        onEndReachedThreshold={0.3}
        onEndReached={onLoadMoreUsers}
        ListFooterComponent={
          <LoadingContent>{loadingMore ? <Loading /> : null}</LoadingContent>
        }
      />
      <ModalConfirm
        ref={modalizeRef}
        testID={'modalConfirm'}
        title={
          leavingTeam
            ? t('groupSetup.leaveModalTitle')
            : t('groupSetup.removeModalTitle', {
                name: removingUserGroupName,
              })
        }
        descp={t('groupSetup.modalDescp')}
        buttonText={
          leavingTeam
            ? t('groupSetup.leaveGroup')
            : t('groupSetup.removeUser', {
                name: removingUserGroupName,
              })
        }
        onConfirm={() => {
          modalizeRef.current?.close();
          removingUserGroup && onRemoveUser(removingUserGroup.user.id);
        }}
      />
    </Container>
  );
}
