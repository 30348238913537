import styled from 'styled-components/native';

export const Container = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  width: auto;
  min-width: 44px;
  height: 40px;
  border-radius: 5px;
  padding: 7px;
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const Day = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 20px;
`;

export const Month = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  align-items: center;
  font-size: 8px;
`;
