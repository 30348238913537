import { InputFieldProps } from '~/components/InputField';

type Info = {
  default?: string;
  success?: string;
  error?: string;
};

type ValidateFieldProps = {
  field: string;
  isValidField?: (field: string) => boolean;
  detectErrors?: (field: string) => string[];
  info?: Info;
  ignoreValidation?: boolean;
};

const validateField = ({
  field,
  isValidField,
  detectErrors,
  info,
  ignoreValidation = false,
}: ValidateFieldProps): {
  state: InputFieldProps['state'];
  info: string | undefined;
} => {
  // Default
  if (field?.length === 0 || ignoreValidation) {
    return { state: 'default', info: info?.default || '' };
  }

  // Error
  const errors: string[] = [];
  if (detectErrors) {
    errors.push(...detectErrors(field));
  }
  if (isValidField && !isValidField(field)) {
    errors.push(info?.error || '');
  }
  if (errors.length > 0) {
    const errorMessage = errors[0];
    return { state: 'error', info: errorMessage };
  }

  // Success
  return { state: 'success', info: info?.success || '' };
};

export default validateField;
