import React from 'react';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import { useTheme } from 'styled-components/native';
import { Container, Content, PctText, Text } from './style';

type PercentItem = {
  pct: number;
  text: string;
};

export type PercentCardProps = {
  percentItem: PercentItem;
};

export default function PercentCard({
  percentItem,
}: PercentCardProps): JSX.Element {
  const theme = useTheme();
  const { pct, text } = percentItem;
  const pctNum = Math.round(pct * 100);
  return (
    <Container>
      <AnimatedCircularProgress
        size={120}
        width={16}
        fill={pctNum}
        rotation={180}
        tintColor={theme.color.brand_02}
        backgroundColor={theme.color.base.c2}
      >
        {() => (
          <Content>
            <PctText>{`${pctNum}%`}</PctText>
            <Text>{text}</Text>
          </Content>
        )}
      </AnimatedCircularProgress>
    </Container>
  );
}
