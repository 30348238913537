import styled from 'styled-components/native';

export const Container = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  width: 100%;
  padding: 20px;
  border-radius: 15px;
`;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 16px;
  padding-left: 10px;
`;

export const FilterContainer = styled.View``;

export const HeaderContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const TitleContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const DeleteButton = styled.Text`
  color: ${({ theme }) => theme.color.error};
  font-size: 12px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  text-decoration-line: underline;
`;

export const Label = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 14px;
  color: ${({ theme }) => theme.color.base.c5};
  text-transform: uppercase;
`;

export const LabelContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const MembersContainer = styled.View`
  flex-direction: row;
`;

export const MembersNumber = styled.TextInput`
  width: 48px;
  height: 38px;
  border-radius: 7px;
  margin-left: 5px;
  border: 1px solid ${({ theme }) => theme.color.base.c3};
  text-align: center;
`;

export const CheckboxContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const CheckboxSelectionContainer = styled.View`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
`;

export const CheckboxText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 14px;
  text-transform: uppercase;
`;

export const CheckboxLabelContainer = styled.View``;
