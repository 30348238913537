/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query TeamSamples
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { TeamSamples, TeamSamplesVariables } from './types/TeamSamples';
import { TEAM_SAMPLES } from './';

export function useTeamSamplesQuery(
  options?: QueryHookOptions<TeamSamples, TeamSamplesVariables>,
): QueryResult<TeamSamples, TeamSamplesVariables> {
  return useQuery<TeamSamples, TeamSamplesVariables>(TEAM_SAMPLES, options);
}

export function useTeamSamplesLazyQuery(
  options?: LazyQueryHookOptions<TeamSamples, TeamSamplesVariables>,
): QueryTuple<TeamSamples, TeamSamplesVariables> {
  return useLazyQuery<TeamSamples, TeamSamplesVariables>(TEAM_SAMPLES, options);
}

export function createTeamSamplesMock({
  variables,
  data,
  error,
}: {
  variables?: TeamSamplesVariables;
  data?: TeamSamples;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: TEAM_SAMPLES,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
