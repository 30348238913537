import React from 'react';
import { general } from '~/assets/imgs';
import Button from '~/components/Button';
import NavHeader from '~/components/NavHeader';
import { t } from '~/utils/i18n';
import {
  Title,
  Container,
  TopContainer,
  BottomContainer,
  ImageContainer,
  TitleWrapper,
  BadgeText,
  DescWrapper,
  Desc,
} from './style';

type JoinGroupRequestSentLayoutProps = {
  onLogout: () => void;
  onGoToLobby: () => void;
};

export default function JoinGroupRequestSent({
  onLogout,
  onGoToLobby,
}: JoinGroupRequestSentLayoutProps): JSX.Element {
  return (
    <Container>
      <TopContainer>
        <ImageContainer source={general.invite_success} />
        <NavHeader
          backButtonTestID={'buttonBack'}
          colorStyle={'light'}
          RightComponent={
            <Button
              testID="buttonLogout"
              size="sm"
              text={t('g.logout')}
              type="secondary-base"
              onPress={onLogout}
            />
          }
        />
        <TitleWrapper>
          <BadgeText>{t('joinGroupRequestSent.badgeText')}</BadgeText>
          <Title>{t('joinGroupRequestSent.title')}</Title>
        </TitleWrapper>
      </TopContainer>

      <BottomContainer>
        <DescWrapper>
          <Desc>{t('joinGroupRequestSent.desc1')}</Desc>
          <Desc>{t('joinGroupRequestSent.desc2')}</Desc>
        </DescWrapper>
        <Button
          size="2xl"
          flex
          testID={'buttonGoToLobby'}
          text={t('joinGroupRequestSent.continueToLobby')}
          type="secondary-contrast"
          onPress={() => onGoToLobby && onGoToLobby()}
        />
      </BottomContainer>
    </Container>
  );
}
