import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import CreateGroup from '~/screens/Group/CreateGroup';
import CreateGroupSuccess from '~/screens/Group/CreateGroupSuccess';
import JoinGroupDetail from '~/screens/Group/JoinGroupDetail';
import JoinGroupRequestSent from '~/screens/Group/JoinGroupRequestSent';
import JoinSelectGroup from '~/screens/Group/JoinSelectGroup';
import JoinSelectInstitute from '~/screens/Group/JoinSelectInstitute';
import JoinSelectLocation from '~/screens/Group/JoinSelectLocation';
import RequestInstitution from '~/screens/Group/RequestInstitution';
import RequestInstitutionSent from '~/screens/Group/RequestInstitutionSent';
import { GroupStackParamList } from '../../types';

const GroupStackNavigator = createStackNavigator<GroupStackParamList>();

export default function GroupStack(): JSX.Element {
  return (
    <GroupStackNavigator.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <GroupStackNavigator.Screen
        name={'JoinSelectLocation'}
        component={JoinSelectLocation}
      />
      <GroupStackNavigator.Screen
        name={'JoinSelectInstitute'}
        component={JoinSelectInstitute}
      />
      <GroupStackNavigator.Screen
        name={'JoinSelectGroup'}
        component={JoinSelectGroup}
      />
      <GroupStackNavigator.Screen
        name={'JoinGroupDetail'}
        component={JoinGroupDetail}
      />
      <GroupStackNavigator.Screen
        name={'JoinGroupRequestSent'}
        component={JoinGroupRequestSent}
      />
      <GroupStackNavigator.Screen
        name={'RequestInstitution'}
        component={RequestInstitution}
      />
      <GroupStackNavigator.Screen
        name={'RequestInstitutionSent'}
        component={RequestInstitutionSent}
      />
      <GroupStackNavigator.Screen
        name={'CreateGroup'}
        component={CreateGroup}
      />
      <GroupStackNavigator.Screen
        name={'CreateGroupSuccess'}
        component={CreateGroupSuccess}
      />
    </GroupStackNavigator.Navigator>
  );
}
