import React from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useAuth } from '~/context/auth';
import useAppLocale from '~/data/hooks/useAppLocale';
import { AuthStackParamList } from '~/navigation/types';
import SignUpNameLayout from './layout';

type SignUpNameNavProp = StackNavigationProp<AuthStackParamList, 'SignUpName'>;
type SignUpNameRouteProp = RouteProp<AuthStackParamList, 'SignUpName'>;

export default function SignUpName(): JSX.Element {
  const navigation = useNavigation<SignUpNameNavProp>();
  const route = useRoute<SignUpNameRouteProp>();
  const groupId = route.params?.g;
  const groupToken = route.params?.t;
  const inviteCode = route.params?.r;
  const shopReturnUrlParam = route.params?.shopReturnUrl;

  const { locale, onChangeLocale } = useAppLocale({
    onReload: () =>
      navigation.replace('SignUpName', {
        g: groupId,
        t: groupToken,
        r: inviteCode,
      }),
  });

  const { onSetSignUpUser, signUpData } = useAuth();

  const onNext = (firstName: string, lastName: string) => {
    onSetSignUpUser({ firstName, lastName });

    navigation.navigate('SignUpContact', {
      g: groupId,
      t: groupToken,
      r: inviteCode,
      shopReturnUrl: shopReturnUrlParam,
    });
  };

  return (
    <SignUpNameLayout
      initialFirstName={signUpData.firstName}
      initialLastName={signUpData.lastName}
      inputProgress={1}
      selectedLocale={locale}
      onBack={() => navigation.goBack()}
      onNext={onNext}
      onSelectedLocale={onChangeLocale}
    />
  );
}
