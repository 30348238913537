import React from 'react';
import { t } from '~/utils/i18n';
import {
  Container,
  ImageContainer,
  Image,
  OptionalImageContent,
  ImageAddIcon,
  OptionalText,
  TextInput,
} from './style';

export type PollOptionInfo = {
  text: string;
  imgUrl: string;
};

type PollOptionInputProps = {
  testID?: string;
  optionInfo: PollOptionInfo;
  onChangeText: (text: string) => void;
  onImagePress: () => void;
};

export default function PollOptionInput({
  testID,
  optionInfo,
  onChangeText,
  onImagePress,
}: PollOptionInputProps): JSX.Element {
  const { text, imgUrl } = optionInfo;
  return (
    <Container testID={testID}>
      <ImageContainer onPress={onImagePress}>
        {imgUrl ? (
          <Image source={{ uri: imgUrl }} />
        ) : (
          <OptionalImageContent>
            <ImageAddIcon />
            <OptionalText>{t('g.optionalPhoto')}</OptionalText>
          </OptionalImageContent>
        )}
      </ImageContainer>
      <TextInput
        placeholder={t('g.typeHere')}
        value={text}
        onChangeText={(value) => onChangeText(value)}
      />
    </Container>
  );
}
