/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation DestroyQuotesInstance
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  DestroyQuotesInstance,
  DestroyQuotesInstanceVariables,
} from './types/DestroyQuotesInstance';
import { DESTROY_QUOTES_INSTANCE } from './';

export function useDestroyQuotesInstanceMutation(
  options?: MutationHookOptions<
    DestroyQuotesInstance,
    DestroyQuotesInstanceVariables
  >,
): MutationTuple<DestroyQuotesInstance, DestroyQuotesInstanceVariables> {
  return useMutation<DestroyQuotesInstance, DestroyQuotesInstanceVariables>(
    DESTROY_QUOTES_INSTANCE,
    options,
  );
}

export function createDestroyQuotesInstanceMock({
  variables,
  data,
  error,
}: {
  variables?: DestroyQuotesInstanceVariables;
  data?: DestroyQuotesInstance;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: DESTROY_QUOTES_INSTANCE,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
