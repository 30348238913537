import styled from 'styled-components/native';
import TeamIconComp from '~/components/TeamIcon';

export const Container = styled.View``;

export const BottomBg = styled.View`
  position: absolute;
  top: 90px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: ${({ theme }) => theme.color.base.c1};
`;

export const Content = styled.View`
  align-items: left;
  padding-top: 24px;
  padding-left: 16px;
  flex-direction: row;
`;

export const TabBarContainer = styled.View`
  margin-top: 32px;
`;

export const TeamIcon = styled(TeamIconComp)`
  border-width: 4px;
  border-color: ${({ theme }) => theme.color.base.c1};
  border-radius: 8px;
`;

export const SectionButtonContainer = styled.View`
  padding-top: 24px;
  padding-bottom: 16px;
  padding-horizontal: 16px;
`;

export const SectionButtonRow = styled(SectionButtonContainer)`
  flex-direction: row;
`;

export const PressableContainer = styled.Pressable`
  flex: 1;
`;

interface IPressable {
  pressed: boolean;
}
export const SectionCol = styled.View<IPressable>`
  flex-direction: row;
  padding: 16px;
  border-radius: 14px;
  background-color: ${({ pressed, theme }) =>
    pressed ? theme.color.brand_02 : theme.color.base.c1};
  align-items: center;
  justify-content: center;
`;

export const SectionButtonText = styled.Text<IPressable>`
  margin-left: 10px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ pressed, theme }) =>
    pressed ? theme.color.brand_02 : theme.color.base.c6};
  font-size: 14px;
`;

export const HSpacer = styled.View`
  width: 8px;
`;

export const TeamName = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c1};
  font-size: 22px;
  line-height: 26px;
  padding-left: 16px;
  margin-top: 20px;
`;

export const TeamNameAndBadgeWrapper = styled.View`
  flex-direction: column;
  align-items: left;
`;

export const TeamBadgeContainer = styled.View`
  padding-left: 16px;
  margin-top: 31px;
`;
