import React, { useEffect } from 'react';
import { BottomTabBarProps } from '@react-navigation/bottom-tabs';
import TabBarItem from '~/components/TabBarItem';
import { useIntercom } from '~/context/intercom';
import { MainTabParamList } from '~/navigation/types';
import { getRouteMeta } from '../utils/getRouteMeta';
import { TabContainer } from './style';

type CustomTabBarProps = {
  onLongPress: (label: string) => void;
  tabBarBadge?: number;
} & BottomTabBarProps;

export default function CustomTabBar({
  state,
  navigation,
  onLongPress,
}: CustomTabBarProps): JSX.Element {
  const [tabBarBadges, setTabBarBadges] = React.useState<
    Record<keyof MainTabParamList, number>
  >({
    FeedStack: 0,
    PlanningStack: 0,
    YearbookStack: 0,
    ProfileStack: 0,
  });
  const { unReadMessageCount } = useIntercom();

  useEffect(() => {
    unReadMessageCount !== undefined &&
      setTabBarBadges({ ...tabBarBadges, ProfileStack: unReadMessageCount });
  }, [unReadMessageCount]);

  return (
    <TabContainer>
      {state.routes.map((route, index) => {
        const { isFocused, iconName, label } = getRouteMeta(
          state,
          route,
          index,
        );

        const onPress = () => {
          const event = navigation.emit({
            type: 'tabPress',
            target: route.key,
            canPreventDefault: true,
          });

          if (!isFocused && !event.defaultPrevented) {
            navigation.navigate(route.name);
          }
        };

        const routeName = route.name as keyof MainTabParamList;
        const tabBarBadge = tabBarBadges[routeName] || undefined;

        return (
          <TabBarItem
            key={route.key}
            tabIcon={iconName}
            label={label}
            isFocused={isFocused}
            onPress={onPress}
            onLongPress={onLongPress}
            tabBarBadge={tabBarBadge}
          />
        );
      })}
    </TabContainer>
  );
}
