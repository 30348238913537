import { useInstituteTypesQuery } from '~/data/operations/institute/instituteTypes';
import { InstituteTypesVariables } from '~/data/operations/institute/types/InstituteTypes';
import { getDistinctValuesByProperty } from '~/utils/helpers';
import {
  SEARCH_TAG_OPTION_TYPES,
  SearchTagOption,
} from '~/utils/types/adminSearch';
import { InstitutesInstituteTypeCountryChoices } from '../operations/global';
import { useGraduationTypesCountryQuery } from '../operations/group/graduationTypesCountry';

type searchOptionsParams = InstituteTypesVariables;

const useSearchOptions = (
  type: SEARCH_TAG_OPTION_TYPES,
  params?: searchOptionsParams,
) => {
  const optionHandlers: Record<
    SEARCH_TAG_OPTION_TYPES,
    (params?: searchOptionsParams) => SearchTagOption[]
  > = {
    [SEARCH_TAG_OPTION_TYPES.INSTITUTE_TYPES]: useInstituteTypesOptions,
    [SEARCH_TAG_OPTION_TYPES.GRADUATION_TYPES]: useGraduationTypesOptions,
    // Add more types here
  };

  const optionHandler = optionHandlers[type];
  const options = optionHandler ? optionHandler(params) : [];

  return { options };
};

const useInstituteTypesOptions = (params?: InstituteTypesVariables) => {
  const { data } = useInstituteTypesQuery({
    variables: {
      first: 50,
      ...params,
    },
  });

  const options: SearchTagOption[] =
    data?.instituteTypes?.edges.map((edge) => ({
      description: edge?.node?.name,
      country: edge?.node?.country,
    })) || [];

  // Remove duplicates
  return getDistinctValuesByProperty(options, 'description');
};

const useGraduationTypesOptions = () => {
  const { data } = useGraduationTypesCountryQuery();
  const graduationTypesCountry = data?.graduationTypesCountry;
  const options: SearchTagOption[] =
    graduationTypesCountry?.map((type) => ({
      description: type?.name as string,
      country: type?.country as InstitutesInstituteTypeCountryChoices,
    })) || [];

  return getDistinctValuesByProperty(options, 'description');
};

export default useSearchOptions;
