import { LinearGradient, LinearGradientProps } from 'expo-linear-gradient';
import styled from 'styled-components/native';

export const Container = styled.View`
  background-color: ${({ theme }) => theme.color.base.c1};
  height: 32px;
  border-radius: 16px;
  flex-direction: row;
`;

interface ILNProgress {
  pct: number;
}
export const LNProgress = styled(LinearGradient).attrs<
  ILNProgress,
  LinearGradientProps
>(({ theme }) => ({
  colors: theme.gradient.primary.cs01,
  start: { x: 0, y: 0 },
  end: { x: 1, y: 0 },
}))<ILNProgress>`
  height: 32px;
  border-radius: 16px;
  width: ${({ pct }) => `${pct * 100}%`};
  align-items: center;
  justify-content: center;
`;

export const ProgressView = styled.View`
  flex: 1;
`;

export const DotView = styled.View`
  background-color: ${({ theme }) => theme.color.base.c4};
  width: 20px;
  height: 20px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-left: 8px;
  margin-right: 16px;
`;

export const Dot = styled.View`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.color.base.c4};
  border-width: 2px;
  border-color: ${({ theme }) => theme.color.base.c1};
`;

export const Text = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c0};
  font-size: 12px;
`;
