import styled from 'styled-components/native';
import IconStyled from '../IconStyled';

export const Container = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const Text = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 14px;
`;

export const Title = styled.Text`
  color: ${({ theme }) => theme.color.base.c9};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 22px;
`;

export const HeaderSection = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 48px;
`;

interface TableRowProps {
  noBorder?: boolean;
}

export const AdsTable = styled.FlatList`
  min-height: 55vh;
`;

export const TableRow = styled.TouchableOpacity<TableRowProps>`
  flex-direction: row;
  border-bottom-width: ${({ noBorder }) => (noBorder ? '0px' : '0.5px')};
  border-bottom-color: ${({ theme }) => theme.color.base.c5};
  padding: 10px 0;
  gap: 12px;
`;

export const TableCell = styled.View`
  flex: 1;
  justify-content: center;
`;

export const TableCellClickable = styled.TouchableOpacity<TableRowProps>`
  flex: 1;
  justify-content: center;
`;

export const CellText = styled.Text`
  color: ${({ theme }) => theme.color.base.c5};
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 14px;
  user-select: text;
`;

export const CellBadge = styled.Text`
  color: ${({ theme }) => theme.color.base.c5};
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 14px;
  user-select: text;
  align-self: flex-end;
`;

export const TableHeader = styled.View`
  align-items: center;
  flex-direction: row;
`;

export const TableHeaderText = styled.Text`
  color: ${({ theme }) => theme.color.base.c5};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 12px;
  user-select: none;
  text-transform: uppercase;
  padding-right: 10px;
`;

export const TableStatus = styled.View`
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 20px;
`;

export const AdsName = styled.Text`
  color: ${({ theme }) => theme.color.base.c9};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
`;

export const AdsId = styled.Text`
  color: ${({ theme }) => theme.color.base.c9};
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 14px;
  user-select: text;
`;

export const EmptyCell = styled.View`
  width: 56px;
  height: 1px;
  margin: 0 20px 0 10px;
`;

export const AdsIconContainer = styled(EmptyCell)`
  height: 56px;
  border-radius: 12px;
  border-width: 2px;
  border-color: ${({ theme }) => theme.color.base.c3};
  background-color: ${({ theme }) => theme.color.base.c2};
  justify-content: center;
  align-items: center;
`;

export const AdsIcon = styled(IconStyled).attrs(({ theme }) => ({
  name: 'icn/teams/rocket',
  size: 24,
  color: theme.color.brand_02,
}))``;

export const SortIcon = styled(IconStyled).attrs(({ theme }) => ({
  name: 'arrow_down_up',
  size: 12,
  color: theme.color.base.c5,
}))``;

export const PaginationWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
`;
export const CampaingWrapper = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;
export const ButtonWrapper = styled.View`
  width: 55%;
  margin-left: 16px;
`;
