import { Platform, Animated } from 'react-native';
import styled, { css } from 'styled-components/native';

export const Container = styled.View``;

export const FocusContainer = styled.View`
  border-radius: 18px;
  padding: 2px;
  ${Platform.OS === 'web' &&
  css`
    padding-left: 0;
    padding-right: 0;
  `}
`;

interface IBoxContainer {
  height?: number;
  width?: number;
}

export const BoxContainer = styled.TouchableOpacity.attrs({
  activeOpacity: 1.0,
})<IBoxContainer>`
  flex-direction: row;
  align-items: center;
  max-height: ${({ height }) => height ?? 'auto'}px;
  max-width: ${({ width }) => width ?? 'auto'}px;
  background-color: ${({ theme }) => theme.color.base.c1};
  border-radius: 14px;
  padding: ${({ theme }) => theme.spacing.medium};
`;

export const Content = styled.View`
  flex: 1;
`;

export const TextInput = styled.TextInput`
  ${Platform.OS == 'ios' &&
  `
    flex: 1;
  `}
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: ${({ theme }) => theme.fontSize.title};
  color: ${({ theme }) => theme.color.base.c9};
  justify-content: flex-end;
  ${Platform.OS == 'web' &&
  `
    outline-color: transparent;
    outline-style: none;
  `}
`;

export const Label = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  position: absolute;
  left: 0;
`;

export const AnimatedLabel = Animated.createAnimatedComponent(Label);

export const InfoContent = styled.View`
  height: 22px;
  padding-vertical: 4px;
  padding-horizontal: 4px;
`;

export const InfoLabel = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: ${({ theme }) => theme.fontSize.footnote};
  font-weight: 400;
  color: ${({ theme }) => theme.color.base.c9};
`;

export const TypeContent = styled.View`
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`;

export const ButtonIconView = styled.View`
  padding-left: 6px;
`;
