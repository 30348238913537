import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
} from 'react';
import { Modal } from 'react-native';
import { Portal } from 'react-native-portalize';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components/native';
import { Backdrop, CloseModalButton, ModalBody } from './style';

export type WebModalProps = {
  onCloseModal?: () => void;
  children?: React.ReactNode | React.ReactNode[];
  defaultOpen?: boolean;
  showCancelButton?: boolean;
  sizeWidth?: number;
  sizeHeight?: number;
  padding?: string;
  borderRadius?: number;
};

export interface WebModalHandler {
  open(): void;
  close(): void;
  toggle(): void;
}

/**
 * Basic component to wrap all web modals
 */
const WebModal: ForwardRefRenderFunction<WebModalHandler, WebModalProps> = (
  {
    onCloseModal,
    children,
    defaultOpen = false,
    showCancelButton = true,
    sizeWidth,
    padding,
    borderRadius,
    sizeHeight,
  },
  ref,
) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery({ minWidth: theme.breakpoints.desktopMin });

  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);
  const onClose = () => {
    setIsOpen(false);
    onCloseModal && onCloseModal();
  };

  useImperativeHandle(
    ref,
    () => ({
      open: () => setIsOpen(true),
      close: () => setIsOpen(false),
      toggle: () => setIsOpen((_: boolean) => !_),
    }),
    [],
  );

  return (
    <Portal>
      {isOpen && <Backdrop />}
      <Modal visible={isOpen} transparent animationType="slide">
        <ModalBody
          isDesktop={isDesktop}
          sizeWidth={sizeWidth}
          sizeHeight={sizeHeight}
          padding={padding}
          borderRadius={borderRadius}
        >
          {showCancelButton && <CloseModalButton onPress={onClose} />}
          {children}
        </ModalBody>
      </Modal>
    </Portal>
  );
};

export default forwardRef(WebModal);
