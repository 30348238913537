import React, { useState, useEffect, useRef, RefObject } from 'react';
import { PDFDocument } from 'pdf-lib';
import { Platform, View, Text } from 'react-native';
import {
  GestureHandlerRootView,
  PanGestureHandler,
  PanGestureHandlerGestureEvent,
  State,
} from 'react-native-gesture-handler';
import { Pagination } from 'react-native-snap-carousel';
import { useTheme } from 'styled-components';
import {
  PreviewWrapperContainer,
  StyledPdf,
  PaginationContainer,
  PreviewContainer,
  PreviewPdf,
  PreviewWrapper,
} from './style';

type PdfRefType = View & {
  setPage: (page: number) => void;
};

const PDFViewer = ({
  uri,
  hidePreview = false,
  height,
  width,
}: {
  uri: string;
  hidePreview?: boolean;
  width?: number;
  height?: number;
}) => {
  const theme = useTheme();
  const [pageCount, setPageCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pdfRef = useRef<PdfRefType>(null);

  useEffect(() => {
    const fetchPageCount = async () => {
      const response = await fetch(uri);
      const arrayBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);
      setPageCount(pdfDoc.getPageCount());
    };

    fetchPageCount();
  }, [uri]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    if (pdfRef.current) {
      pdfRef.current.setPage(page);
    }
  };

  const handleGesture = ({ nativeEvent }: PanGestureHandlerGestureEvent) => {
    const { state, translationX } = nativeEvent;
    const isEndOrFailed = state === State.END || state === State.FAILED;
    const isSwipeLeft = translationX < 0 && currentPage < pageCount;
    const isSwipeRight = translationX > 0 && currentPage > 1;

    if (!isEndOrFailed) return;

    let changePage = 0;
    if (isSwipeLeft) {
      changePage = 1;
    } else if (isSwipeRight) {
      changePage = -1;
    }

    handlePageChange(currentPage + changePage);
  };

  if (Platform.OS === 'web') {
    return (
      <View>
        <Text>PDF viewer not available on web</Text>
      </View>
    );
  }

  if (hidePreview) {
    return (
      <StyledPdf
        ref={pdfRef as RefObject<PdfRefType>}
        source={{ uri }}
        singlePage
        onPageChanged={(page) => handlePageChange(page)}
        fitPolicy={0}
        enablePaging
        width={width}
        height={height}
      />
    );
  }

  return (
    <GestureHandlerRootView>
      <PanGestureHandler onHandlerStateChange={handleGesture}>
        <PreviewWrapperContainer>
          <PreviewWrapper>
            <StyledPdf
              ref={pdfRef as RefObject<PdfRefType>}
              source={{ uri }}
              singlePage
              onPageChanged={(page) => handlePageChange(page)}
              fitPolicy={0}
              enablePaging
              width={width}
              height={height}
            />
            {currentPage < pageCount && (
              <PreviewContainer
                width={width}
                height={height}
                source={{ uri }}
                singlePage
                fitPolicy={0}
                enablePaging
              >
                <PreviewPdf
                  source={{ uri }}
                  page={currentPage + 1}
                  singlePage
                  fitPolicy={0}
                  width={width}
                  enablePaging={true}
                  height={height}
                />
              </PreviewContainer>
            )}
          </PreviewWrapper>
          <PaginationContainer>
            <Pagination
              dotsLength={pageCount}
              activeDotIndex={currentPage - 1}
              containerStyle={{
                backgroundColor: theme?.color.base.c2,
                paddingHorizontal: 5,
                paddingVertical: 5,
                borderRadius: 10,
              }}
              dotContainerStyle={{
                marginHorizontal: 2,
              }}
              dotStyle={{
                height: 6,
                width: 6,
                borderRadius: 5,
                backgroundColor: theme?.color.base.c6,
              }}
              inactiveDotStyle={{
                height: 6,
                width: 6,
                borderRadius: 5,
                backgroundColor: theme?.color.base.c4,
              }}
              inactiveDotOpacity={1.0}
              inactiveDotScale={1.0}
              tappableDots
            />
          </PaginationContainer>
        </PreviewWrapperContainer>
      </PanGestureHandler>
    </GestureHandlerRootView>
  );
};

export default PDFViewer;
