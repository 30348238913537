/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation AdminUpdateInstitute
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  AdminUpdateInstitute,
  AdminUpdateInstituteVariables,
} from './types/AdminUpdateInstitute';
import { ADMIN_UPDATE_INSTITUTE } from './';

export function useAdminUpdateInstituteMutation(
  options?: MutationHookOptions<
    AdminUpdateInstitute,
    AdminUpdateInstituteVariables
  >,
): MutationTuple<AdminUpdateInstitute, AdminUpdateInstituteVariables> {
  return useMutation<AdminUpdateInstitute, AdminUpdateInstituteVariables>(
    ADMIN_UPDATE_INSTITUTE,
    options,
  );
}

export function createAdminUpdateInstituteMock({
  variables,
  data,
  error,
}: {
  variables?: AdminUpdateInstituteVariables;
  data?: AdminUpdateInstitute;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: ADMIN_UPDATE_INSTITUTE,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
