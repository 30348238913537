import React from 'react';
import { IconName } from '~/components/Icon';
import { IconStyledName } from '../IconStyled';
import {
  Container,
  SectionIcon,
  SectionStyledIcon,
  Content,
  Title,
  Descp,
  Badge,
  BadgeTitle,
  RightIcon,
} from './style';

type TabSectionButtonProps = {
  testID?: string;
  iconName?: IconName;
  styledIconName?: IconStyledName;
  styledIconColor?: string;
  title: string;
  detail?: string;
  badgeText?: string;
  rightIconName?: IconName;
  rightIconColor?: string;
  border?: boolean;
  borderRadius?: number;
  backgroundColor?: string;
  onPress: () => void;
};

export default function TabSectionButton({
  testID,
  iconName,
  styledIconName,
  styledIconColor,
  title,
  detail,
  badgeText,
  rightIconName,
  rightIconColor,
  border = false,
  borderRadius,
  backgroundColor,
  onPress,
}: TabSectionButtonProps): JSX.Element {
  return (
    <Container
      testID={testID}
      onPress={onPress}
      border={border}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
    >
      {(() => {
        if (iconName) {
          return <SectionIcon name={iconName} />;
        } else if (styledIconName) {
          return (
            <SectionStyledIcon name={styledIconName} color={styledIconColor} />
          );
        }
      })()}
      <Content>
        <Title>{title}</Title>
        {badgeText ? (
          <Badge>
            <BadgeTitle>{badgeText}</BadgeTitle>
          </Badge>
        ) : (
          <Descp>{detail}</Descp>
        )}
      </Content>
      {rightIconName && (
        <RightIcon name={rightIconName} color={rightIconColor} />
      )}
    </Container>
  );
}
