import Emarsys from 'react-native-emarsys-sdk';

type EmarsysHookProps = {
  setContact: (userId: string) => Promise<void>;
  clearContact: () => void;
};

const useEmarsys = (): EmarsysHookProps => {
  async function setContact(userId: string) {
    const fieldId = 21392; //gradoo_userid

    try {
      await Emarsys.setContact(fieldId, userId);
    } catch (e) {
      console.log(e); //eslint-disable-line
    }
  }

  async function clearContact() {
    try {
      await Emarsys.clearContact();
    } catch (e) {
      console.log(e); //eslint-disable-line
    }
  }

  return {
    setContact,
    clearContact,
  };
};

export default useEmarsys;
