import { LinearGradient } from 'expo-linear-gradient';
import { createShimmerPlaceholder } from 'react-native-shimmer-placeholder';
import styled, { DefaultTheme } from 'styled-components/native';

const ShimmerPlaceholder = createShimmerPlaceholder(LinearGradient);

type GradientStyle = 'g0' | 'g1' | 'g2' | 'g3';

interface IStyledShimmerPlaceholder {
  w?: number;
  h?: number;
  br?: number;
  mt?: number;
  ml?: number;
  g?: GradientStyle;
}

const getGradientColors = (g: GradientStyle, theme: DefaultTheme) => {
  const gradientStyleMap: { [key in GradientStyle]: string[] } = {
    g0: [theme.color.base.c0, theme.color.base.c0, theme.color.base.c0],
    g1: [theme.color.base.c1, theme.color.base.c0, theme.color.base.c1],
    g2: [theme.color.base.c2, theme.color.base.c1, theme.color.base.c2],
    g3: [theme.color.base.c3, theme.color.base.c2, theme.color.base.c3],
  };
  return gradientStyleMap[g];
};
export default styled(ShimmerPlaceholder).attrs<IStyledShimmerPlaceholder>(
  ({ w = 80, h = 20, g = 'g1', theme }) => ({
    shimmerColors: getGradientColors(g, theme),
    width: w,
    height: h,
  }),
)<IStyledShimmerPlaceholder>`
  margin-top: ${({ mt = 0 }) => mt}px;
  margin-left: ${({ ml = 0 }) => ml}px;
  border-radius: ${({ br = 4 }) => br}px;
`;
