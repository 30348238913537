import React from 'react';
import { IconName } from '~/components/Icon';
import SectionButton from '~/components/SectionButton';
import { Container, Title, ButtonContent, ButtonText } from './style';

type SectionHeaderProps = {
  title: string;
  sub?: boolean; //not being used in v2, we can remove and refact the usage later
  buttonTestID?: string;
  buttonText?: string;
  buttonIcon?: IconName;
  buttonColor?: string;
  locked?: boolean;
  RightComponent?: React.ReactNode;
  onButtonPress?: () => void;
};

export default function SectionHeader({
  title,
  buttonTestID,
  buttonText,
  buttonIcon,
  buttonColor,
  locked = false,
  RightComponent,
  onButtonPress,
  ...style
}: SectionHeaderProps): JSX.Element {
  return (
    <Container {...style}>
      <Title>{title}</Title>
      {(() => {
        if (buttonText && buttonIcon) {
          return (
            <SectionButton
              testID={buttonTestID}
              text={buttonText}
              icon={buttonIcon}
              textColor={buttonColor}
              locked={locked}
              onPress={() => onButtonPress && onButtonPress()}
            />
          );
        } else if (buttonText) {
          return (
            <ButtonContent
              testID={buttonTestID}
              onPress={() => onButtonPress && onButtonPress()}
            >
              <ButtonText>{buttonText}</ButtonText>
            </ButtonContent>
          );
        }
        return RightComponent;
      })()}
    </Container>
  );
}
