import styled from 'styled-components/native';
import Avatar from '~/components/Avatar';
import ButtonIcon from '~/components/ButtonIcon';

export type CustomIconType = 'default' | 'primary' | 'secondary';

export const Container = styled.View`
  padding-vertical: 16px;
  flex-direction: row;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.color.base.c3};
  align-items: center;
`;

export const StyledAvatar = styled(Avatar)`
  margin-right: 16px;
`;

export const InfoContent = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})`
  flex: 1;
`;

export const Name = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 16px;
`;

export const InfoText = styled.Text`
  margin-top: 8px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.brand_02};
  font-size: 14px;
`;

interface ICustomButtonIcon {
  iconColor?: string;
  customIconType?: CustomIconType;
}
export const CustomButtonIcon = styled(ButtonIcon).attrs<ICustomButtonIcon>(
  ({ iconColor, customIconType, theme }) => ({
    size: 16,
    color: iconColor
      ? iconColor
      : customIconType === 'primary'
      ? theme.color.base.c0
      : theme.color.brand_02,
  }),
)<ICustomButtonIcon>`
  margin-left: 8px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: ${({ customIconType, theme }) =>
    customIconType === 'primary'
      ? theme.color.brand_02
      : customIconType === 'secondary'
      ? theme.color.brand_01
      : theme.color.base.c1};
  align-items: center;
  justify-content: center;
`;

export const RemoveContent = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})``;

export const RemoveText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.error};
  font-size: 16px;
  text-transform: uppercase;
`;
