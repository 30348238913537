import * as React from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components/native';
import { useAuth } from '~/context/auth';
import useAdminAvatar from '~/data/hooks/useAdminAvatar';
import { Status } from '~/data/operations/global';
import { useInstituteRequestsQuery } from '~/data/operations/instituteRequest/instituteRequests';
import SearchStack from '~/navigation/stacks/Admin/Search';
import CustomDrawerContent from '~/navigation/stacks/Admin/layout/CustomDrawerContent';
import {
  DESKTOP_DRAWER_STYLE,
  MOBILE_DRAWER_STYLE,
} from '~/navigation/stacks/Admin/layout/CustomDrawerContent/style';
import { AdminStackParamList } from '~/navigation/types';
import AdsStack from './Ads';
import InstitutesStack from './Institutes';
import ReportingStack from './Reporting';
import SettingsStack from './Settings';
import SupportStack from './Support';

const AdminStackDrawerNavigator = createDrawerNavigator<AdminStackParamList>();

export default function AdminStack(): JSX.Element {
  const theme = useTheme();
  const isDesktop = useMediaQuery({ minWidth: theme.breakpoints.desktopMin });

  const { authUser } = useAuth();

  const { avatar } = useAdminAvatar({ adminId: authUser?.id });

  const { data: requestsData } = useInstituteRequestsQuery({
    variables: {
      status: Status.PENDING,
    },
  });

  return (
    <AdminStackDrawerNavigator.Navigator
      initialRouteName={'SearchStack'}
      screenOptions={{
        drawerType: !isDesktop ? 'front' : 'permanent',
        drawerStyle: !isDesktop ? MOBILE_DRAWER_STYLE : DESKTOP_DRAWER_STYLE,
        headerShown: false,
      }}
      drawerContent={(props) => (
        <CustomDrawerContent
          isMobile={!isDesktop}
          avatar={avatar}
          requestsNumber={requestsData?.instituteRequests?.edges.length}
          adminInfo={{
            fullName: authUser?.firstName,
            email: authUser?.email,
          }}
          {...props}
        />
      )}
    >
      <AdminStackDrawerNavigator.Screen
        name="SearchStack"
        component={SearchStack}
      />
      <AdminStackDrawerNavigator.Screen
        name="InstitutesStack"
        component={InstitutesStack}
      />
      <AdminStackDrawerNavigator.Screen name="AdsStack" component={AdsStack} />
      <AdminStackDrawerNavigator.Screen
        name="ReportingStack"
        component={ReportingStack}
      />
      <AdminStackDrawerNavigator.Screen
        name="SupportStack"
        component={SupportStack}
      />
      <AdminStackDrawerNavigator.Screen
        name="SettingsStack"
        component={SettingsStack}
      />
    </AdminStackDrawerNavigator.Navigator>
  );
}
