import React, { useRef } from 'react';
import { Alert } from 'react-native';
import CollagesAlbumCell, {
  CollagesAlbumCellHandle,
} from '~/components/CollagesAlbumCell';
import SectionButton from '~/components/SectionButton';
import TrackingFlatlist from '~/components/TrackingFlatList';
import { AdsBiggerImage } from '~/components/common/style';
import { BestMatchedAsset } from '~/data/models/campaign';
import { CollagesAlbum } from '~/data/models/yearbook';
import { VariantTypeEnum } from '~/data/operations/global';
import useAdsTracking from '~/utils/hooks/useAdsTracking';
import { t } from '~/utils/i18n';
import ModuleLayout from '../../layout/ModuleLayout';
import {
  Header,
  AlbumCellWrapper,
  RefreshControl,
  AssetWrapper,
} from './style';

export type CollagesLayoutProps = {
  collagesAlbums: CollagesAlbum[];
  yearbookAdmin?: boolean;
  isActive?: boolean;
  viewOnly?: boolean;
  addAlbumEnabled?: boolean;
  loading: boolean;
  hasNextPage?: boolean;
  authUserId: string;
  isDeadlinePassed?: boolean | null;
  onBack: () => void;
  onSetup: () => void;
  onHelp: () => void;
  onRequestYearbookTeam: () => void;
  onAddAlbum: () => void;
  onSelectedAlbum: (collageAlbumId: string) => void;
  onDeleteAlbum: (collageAlbumId: string) => void;
  onRefresh: () => void;
  onLoadMore: () => void;
  openBannersInBrowser: (url: string) => void;
};

export default function Collages({
  collagesAlbums,
  yearbookAdmin,
  isActive,
  viewOnly,
  addAlbumEnabled,
  loading,
  hasNextPage,
  isDeadlinePassed,
  authUserId,
  onBack,
  onSetup,
  onHelp,
  onRequestYearbookTeam,
  onAddAlbum,
  onSelectedAlbum,
  onDeleteAlbum,
  onRefresh,
  onLoadMore,
  openBannersInBrowser,
}: CollagesLayoutProps): JSX.Element {
  const { handleViewTracking, handleAdsClick, bestMatchedAsset, randomIndex } =
    useAdsTracking({ authUserId, variantType: VariantTypeEnum.LARGE });

  const collagesAlbumCellsRef = useRef<(CollagesAlbumCellHandle | null)[]>([]);

  const renderItem = ({
    item,
    index,
  }: {
    item?: CollagesAlbum | BestMatchedAsset;
    index: number;
  }) => {
    if (item?.__typename === 'MatchedAssetNode') {
      const post = item as BestMatchedAsset;
      return (
        <AssetWrapper
          onPress={() => {
            handleAdsClick(
              post?.id as string,
              post?.channelData?.[0]?.trackingUrl as string,
              openBannersInBrowser,
            );
          }}
        >
          <AdsBiggerImage
            source={{ uri: post?.channelData?.[0]?.imageLarge as string }}
          />
        </AssetWrapper>
      );
    }
    const collagesAlbum = item as CollagesAlbum;
    return (
      <AlbumCellWrapper>
        <CollagesAlbumCell
          testID={`CollagesAlbumCell:${collagesAlbum?.id}`}
          ref={(el) => (collagesAlbumCellsRef.current[index] = el)}
          collagesAlbum={collagesAlbum}
          swipeable={yearbookAdmin}
          onPress={() => onSelectedAlbum(collagesAlbum?.id)}
          onDelete={() => {
            Alert.alert(
              t('alert.deleteAlbumTitle'),
              t('alert.deleteAlbumDescp'),
              [
                {
                  text: t('alert.cancel'),
                  style: 'cancel',
                },
                {
                  text: t('alert.deleteAlbumConfirm'),
                  onPress: () => {
                    collagesAlbumCellsRef?.current?.[index]?.collapse();
                    onDeleteAlbum(collagesAlbum.id);
                  },
                },
              ],
            );
          }}
        />
      </AlbumCellWrapper>
    );
  };
  let combinedData: (CollagesAlbum | BestMatchedAsset)[] = [...collagesAlbums];
  if (bestMatchedAsset?.[randomIndex] !== undefined) {
    combinedData = [...combinedData, bestMatchedAsset?.[randomIndex]];
  }

  return (
    <ModuleLayout
      title={t('screens.collages')}
      yearbookAdmin={yearbookAdmin}
      isActive={isActive}
      viewOnly={viewOnly}
      onBack={onBack}
      onSetup={onSetup}
      onHelp={onHelp}
      onRequestYearbookTeam={onRequestYearbookTeam}
      isDeadlinePassed={isDeadlinePassed}
    >
      <TrackingFlatlist
        testID={'albumList'}
        refreshControl={
          <RefreshControl refreshing={loading} onRefresh={onRefresh} />
        }
        data={combinedData}
        renderItem={renderItem}
        ListHeaderComponent={
          <Header>
            {!viewOnly && addAlbumEnabled ? (
              <SectionButton
                testID={'buttonAddAlbum'}
                text={t('g.addNewAlbum')}
                icon={'plus'}
                centerContent
                onPress={onAddAlbum}
              />
            ) : null}
          </Header>
        }
        loading={loading}
        handleViewTracking={handleViewTracking}
        onEndReachedThreshold={0.3}
        onEndReached={() => hasNextPage && onLoadMore()}
      />
    </ModuleLayout>
  );
}
