/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation DuplicateBannerAsset
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  DuplicateBannerAsset,
  DuplicateBannerAssetVariables,
} from './types/DuplicateBannerAsset';
import { DUPLICATE_BANNER_ASSET } from './';

export function useDuplicateBannerAssetMutation(
  options?: MutationHookOptions<
    DuplicateBannerAsset,
    DuplicateBannerAssetVariables
  >,
): MutationTuple<DuplicateBannerAsset, DuplicateBannerAssetVariables> {
  return useMutation<DuplicateBannerAsset, DuplicateBannerAssetVariables>(
    DUPLICATE_BANNER_ASSET,
    options,
  );
}

export function createDuplicateBannerAssetMock({
  variables,
  data,
  error,
}: {
  variables?: DuplicateBannerAssetVariables;
  data?: DuplicateBannerAsset;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: DUPLICATE_BANNER_ASSET,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
