import styledWeb from 'styled-components';
import styled from 'styled-components/native';
import { FlexRow, ResponsiveProps } from '~/components/common/style.web';
import { TabScreenContainer } from '~/screens/style';

export const Container = styled(TabScreenContainer)`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const SpacedFlexRow = styled(FlexRow)`
  justify-content: space-between;
`;

export const SearchSettingsContainer = styled.View`
  margin-right: 30%;
`;

export const DropDownContainer = styled.View`
  width: 135px;
`;

export const LanguageAbsolutePlaceholder = styled.View`
  position: absolute;
  top: 0;
`;

export const SettingsContainer = styled.View<ResponsiveProps>`
  gap: 16px;
  flex-direction: 'column-reverse';
`;

export const LanguageContainer = styledWeb('div')<ResponsiveProps>`
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items:  ${({ isDesktop }) => (isDesktop ? 'flex-end' : 'center')};
  width: ${({ isDesktop }) => (isDesktop ? '150px' : '100%')};
  height: 38px;
`;
