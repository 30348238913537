import React from 'react';
import { general } from '~/assets/imgs';
import Button from '~/components/Button';
import AuthLayout from '~/screens/Auth/layout/AuthLayout';
import { LanguageProps } from '~/screens/Auth/layout/AuthLayout/types';
import { t } from '~/utils/i18n';
import { CenterContent, Image } from './style';

type ResetPasswordSuccessLayoutProps = {
  onBack: () => void;
  onGoToLogin: () => void;
} & LanguageProps;

export default function ResetPasswordSuccess({
  selectedLocale,
  onGoToLogin,
  onSelectedLocale,
}: ResetPasswordSuccessLayoutProps): JSX.Element {
  const headerProps = {
    title: t('resetPasswordSuccess.title'),
    descp: t('resetPasswordSuccess.descp'),
    progress: 1,
    total: 1,
  };

  const languageProps = {
    selectedLocale,
    onSelectedLocale,
  };

  return (
    <AuthLayout headerProps={headerProps} languageProps={languageProps}>
      <>
        <CenterContent>
          <Image source={general.stickers_row} resizeMode={'contain'} />
        </CenterContent>
        <Button
          testID={'buttonGoToLogin'}
          type={'primary-brand-01'}
          size={'2xl'}
          text={t('resetPasswordSuccess.goToLogin')}
          flex
          onPress={onGoToLogin}
        />
      </>
    </AuthLayout>
  );
}
