/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query InstitutesTargeting
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  InstitutesTargeting,
  InstitutesTargetingVariables,
} from './types/InstitutesTargeting';
import { INSTITUTES_TARGETING } from './';

export function useInstitutesTargetingQuery(
  options?: QueryHookOptions<InstitutesTargeting, InstitutesTargetingVariables>,
): QueryResult<InstitutesTargeting, InstitutesTargetingVariables> {
  return useQuery<InstitutesTargeting, InstitutesTargetingVariables>(
    INSTITUTES_TARGETING,
    options,
  );
}

export function useInstitutesTargetingLazyQuery(
  options?: LazyQueryHookOptions<
    InstitutesTargeting,
    InstitutesTargetingVariables
  >,
): QueryTuple<InstitutesTargeting, InstitutesTargetingVariables> {
  return useLazyQuery<InstitutesTargeting, InstitutesTargetingVariables>(
    INSTITUTES_TARGETING,
    options,
  );
}

export function createInstitutesTargetingMock({
  variables,
  data,
  error,
}: {
  variables?: InstitutesTargetingVariables;
  data?: InstitutesTargeting;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: INSTITUTES_TARGETING,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
