import color from 'gradoo-theme/lib/color';
import { ViewStyle } from 'react-native';
import styled, { css } from 'styled-components/native';
import ButtonIcon from '~/components/ButtonIcon';
import Icon from '~/components/Icon';
import IconStyled from '~/components/IconStyled/index';

const DRAWER_GRADIENT: ViewStyle = {
  backgroundColor: color.base.c0,
};

export const DESKTOP_DRAWER_STYLE: ViewStyle = {
  width: 264,
  borderWidth: 1,
  borderColor: color.base.c1,
  ...DRAWER_GRADIENT,
};

export const MOBILE_DRAWER_STYLE: ViewStyle = {
  width: '100%',
  ...DRAWER_GRADIENT,
};

export const Logo = styled.Text`
  flex: 1;
  margin-top: 44px;
  margin-bottom: 92px;
  padding-left: 25px;
  color: ${({ theme }) => theme.color.base.c9};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 32px;
`;

export const DrawerItem = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})<IDrawer>`
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin-bottom: 5px;
  padding-vertical: 8px;
  height: 40px;
  ${({ isActive, theme }) =>
    isActive &&
    css`
      background-color: ${theme.color.base.c0};
      margin-left: 10px;
      margin-right: 10px;
    `};
`;

interface IDrawer {
  isActive: boolean;
}

export const DrawerIcon = styled(Icon).attrs(({ theme }) => ({
  size: 22,
  color: theme.color.base.c9,
}))<IDrawer>`
  margin-left: 20px;
  margin-right: 20px;

  ${({ isActive, theme }) =>
    isActive &&
    css`
      color: ${theme.color.brand_02};
      margin-left: 10px;
    `};
`;

export const StyledIcon = styled(IconStyled).attrs(
  ({ isActive, theme, size }) => ({
    size: size,
    color: isActive ? theme.color.brand_02 : theme.color.base.c4,
  }),
)<IDrawer>`
  background-color: ${({ theme }) => theme.color.brand_02};
  ${({ isActive, theme }) =>
    isActive &&
    css`
      color: ${theme.color.brand_02};
    `};
`;

export const DrawerLabel = styled.Text<IDrawer>`
  color: ${({ theme }) => theme.color.base.c7};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
  ${({ isActive, theme }) =>
    isActive &&
    css`
      color: ${theme.color.brand_02};
    `};
`;
export const DrawerStyledIcon = styled.View<IDrawer>`
  margin-left: 20px;
  margin-right: 20px;
  ${({ isActive }) =>
    isActive &&
    css`
      margin-left: 10px;
    `};
`;
export const CloseButtonIcon = styled(ButtonIcon).attrs(({ theme }) => ({
  name: 'x-close',
  size: 16,
  color: theme.color.base.c3,
}))`
  background-color: ${({ theme }) => theme.color.brand_02};
  align-self: flex-end;
  margin-top: ${({ theme }) => theme.spacing.medium};
  margin-right: ${({ theme }) => theme.spacing.medium};
  width: 32px;
  height: 32px;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
`;

export const UpperWrapper = styled.View`
  flex-direction: column;
`;

export const BottomWrapper = styled.View`
  flex-direction: column;
  margin-top: auto;
  margin-bottom: 20px;
`;
export const TopMenu = styled.View`
  justify-self: flex-start;
`;

export const BottomMenu = styled.View`
  justify-self: end;
`;
export const AccountDetailsContainer = styled.View`
  margin-left: 10px;
  margin-right: 10px;
  border-top-width: 1px;
  border-top-color: ${({ theme }) => theme.color.base.c2};
  padding-top: 15px;
  flex-direction: row;
  justify-content: space-between;
`;

export const UserDetails = styled.View``;

export const UserName = styled.Text`
  font-size: 16px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c8};
`;

export const UserEmail = styled.Text`
  font-size: 14px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c6};
`;

export const NotificationWrapper = styled.View`
  align-items: center;
  padding: 10px 0 20px;
`;
