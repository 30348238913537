import styled from 'styled-components/native';
import SectionHeaderComp from '~/components/SectionHeader';

export const CenterContent = styled.View`
  flex: 1;
  justify-content: center;
  flex-basis: 100%;
`;

export const Row = styled.View`
  flex-direction: row;
  align-items: flex-start;
  margin-top: ${({ theme }) => theme.spacing.medium};
`;

export const CheckTextView = styled.View`
  flex: 1;
  padding-left: ${({ theme }) => theme.spacing.medium};
`;

export const CheckText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 16px;
  line-height: 20px;
`;

export const CheckTextLink = styled(CheckText)`
  color: ${({ theme }) => theme.color.brand_02};
`;

export const ResidenceCountryView = styled.View`
  margin-bottom: 10px;
`;

export const SectionHeader = styled(SectionHeaderComp)`
  padding-bottom: 16px;
`;
