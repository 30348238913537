import { DefaultTheme } from 'styled-components/native';
import styled from 'styled-components/native';
import Image from '~/components/Image';

export const Container = styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 8px;
  height: 72px;
`;

export type ColorShemaKey = keyof DefaultTheme['color']['base'];

export const ImageContainer = styled.View`
  width: 56px;
  height: 56px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.color.base.c0};
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 16px;
`;

export const Picture = styled(Image)`
  width: 56px;
  height: 56px;
`;

export const ProgressWrapper = styled.View`
  flex: 1;
`;

interface IProgress {
  percentage: number;
}

export const Progress = styled.View<IProgress>`
  width: ${({ percentage }) => `${percentage}%`};
  height: 56px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.color.base.c3};
`;

export const ValuesContainer = styled.View`
  height: 56px;
  margin-top: -56px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

export const TextWrapper = styled.View<IProgress>`
  display: flex;
  flex-direction: column;
  margin-start: 16px;
  ${({ percentage }) =>
    `${percentage < 90 ? 'flex-wrap: wrap' : 'flex-grow: 1'}`};
  flex-wrap: wrap;
  justify-content: center;
`;

export const Text = styled.Text<IProgress>`
  min-width: ${({ percentage }) => `${percentage < 90 ? percentage : 0}%`};
  color: ${({ theme }) => theme.color.brand_02};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
`;

export const Percentage = styled.Text`
  color: ${({ theme }) => theme.color.base.c9};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  margin-horizontal: 8px;
  font-size: 16px;
`;

export const Footer = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const FooterText = styled.Text`
  flex: 1;
  color: ${({ theme }) => theme.color.base.c5};
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 14px;
  line-height: 24px;
  padding-vertical: 16px;
`;
