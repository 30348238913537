import styled from 'styled-components/native';
import { WebContainer } from '~/components/WebGrid';
import { TabScreenContainer } from '~/screens/style';

export const Container = styled(TabScreenContainer)`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c0};
  overflow: auto;
`;

export const InstitutesWebContainer = styled(WebContainer)`
  padding: 16px;
`;
