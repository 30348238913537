import { ApolloCache } from '@apollo/client';
import { INSTITUTE_REQUESTS } from './';
import {
  InstituteRequestsVariables,
  InstituteRequests as InstituteRequestsType,
} from './types/InstituteRequests';

export function readInstitutesRequestQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: InstituteRequestsVariables;
}): InstituteRequestsType | null {
  return cache.readQuery<InstituteRequestsType>({
    query: INSTITUTE_REQUESTS,
    variables,
  });
}

export function writeInstitutesRequestQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: InstituteRequestsVariables;
  data: InstituteRequestsType;
}): void {
  cache.writeQuery<InstituteRequestsType>({
    query: INSTITUTE_REQUESTS,
    variables,
    data,
  });
}
