import React from 'react';
import {
  CompositeNavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Linking } from 'react-native';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { LINKS } from '~/data/constants';
import useAppLocale from '~/data/hooks/useAppLocale';
import { Group } from '~/data/models/group';
import { useAdminGetUserQuery } from '~/data/operations/admin/adminGetUser';
import { useGroupQuery } from '~/data/operations/group/group';
import { LobbyStackParamList, RootStackParamList } from '~/navigation/types';
import InviteSuccessLayout from './layout';

type InviteSuccessNavProp = CompositeNavigationProp<
  StackNavigationProp<RootStackParamList, 'InviteSuccess'>,
  StackNavigationProp<LobbyStackParamList>
>;

type InviteSuccessRouteProp = RouteProp<RootStackParamList, 'InviteSuccess'>;

export default function InviteSuccess(): JSX.Element {
  const navigation = useNavigation<InviteSuccessNavProp>();
  const {
    params: { groupId },
  } = useRoute<InviteSuccessRouteProp>();

  const { locale, onChangeLocale } = useAppLocale({
    onReload: () => navigation.replace('InviteSuccess', { groupId }),
  });

  const { onLogout, authUserId } = useAuth();
  const { data } = useAdminGetUserQuery({
    variables: {
      id: authUserId,
    },
  });
  const { data: groupData } = useGroupQuery({
    skip: !groupId,
    variables: {
      id: groupId,
    },
  });

  const yearbookTeamId =
    groupData?.group?.teams?.find((team) => team?.name === 'Yearbook Team') ||
    null;

  const isYearbookAdmin = data?.user?.teams?.some(
    (team) => team?.group.id === groupId && team?.id === yearbookTeamId?.id,
  );
  const group = groupData?.group;
  const openAppStore = (os: 'ios' | 'android') => {
    const url = os === 'ios' ? LINKS.IOS_APPSTORE : LINKS.ANDROID_PLAYSTORE;

    Linking.canOpenURL(url)
      .then((supported) => {
        if (supported) {
          Linking.openURL(url);
        }
      })
      .catch((err) => Snackbar.show(err));
  };

  const openLayoutCreator = () => {
    const url = 'https://create.gradoo.app';
    Linking.openURL(url).catch((err) => Snackbar.show(err));
  };

  const openCustomerCenter = () => {
    const url = 'https://cc.gradoo.app';
    Linking.openURL(url).catch((err) => Snackbar.show(err));
  };

  return (
    <InviteSuccessLayout
      selectedLocale={locale}
      onSelectedLocale={onChangeLocale}
      onLogout={onLogout}
      onStartPlaning={() => navigation.popToTop()}
      openAppStore={openAppStore}
      openLayoutCreator={openLayoutCreator}
      openCustomerCenter={openCustomerCenter}
      group={group as Group}
      onSwitchGroup={() => navigation.navigate('Lobby', {})}
      yearbookAdmin={isYearbookAdmin}
    />
  );
}

/**
 * TODO:
 * - Setup lottie
 */
