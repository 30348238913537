import styled from 'styled-components/native';
import { ModalScreenContainer } from '~/screens/style';

export const Container = styled(ModalScreenContainer)``;

export const SectionContainer = styled.View`
  padding-top: 16px;
  padding-horizontal: 16px;
`;

export const RecommendedList = styled.FlatList`
  padding-left: 16px;
`;

export const InspirationalList = styled.FlatList.attrs({})``;

export const ButtonContainer = styled.View`
  padding: 16px;
`;
