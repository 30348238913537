import styled from 'styled-components/native';

export const Container = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const Text = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 14px;
`;

export const Title = styled.Text`
  color: ${({ theme }) => theme.color.base.c9};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 22px;
`;

export const HeaderSection = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

export const InstituteCardsSection = styled.View`
  flex-direction: row;
  gap: 16px;
`;

export const InstituteCard = styled.View`
  flex: 1;
  padding: 16px;
  background-color: ${({ theme }) => theme.color.base.c0};
  box-shadow: 3px 3px 5px rgba(203, 213, 225, 0.37);
  border-radius: 14px;
  max-width: 49%;
`;

export const InstituteName = styled(Title)`
  font-size: 18px;
`;

export const RequestedAt = styled.Text`
  align-self: end;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c5};
  font-size: 12px;
`;

export const CardContent = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 16px;
  color: ${({ theme }) => theme.color.base.c6};
  margin: 10px 0;
`;

export const CardButtons = styled.View`
  flex-direction: row;
  justify-content: center;
`;

export const InstituteRequestsNavigationWrapper = styled.View`
  margin-top: 25px;
  flex-direction: row;
  justify-content: center;
`;

export const InstituteRequestsNavigation = styled.View`
  flex-direction: row;
  gap: 5px;
  padding: 5px 8px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.color.base.c1};
`;

interface NavigationDotProps {
  isActive?: boolean;
}

export const NavigationDot = styled.TouchableOpacity<NavigationDotProps>`
  border-radius: 50%;
  height: 0.75rem;
  width: 0.75rem;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.color.base.c4 : theme.color.base.c3};
`;
