import React from 'react';
import { Group } from '~/data/models/group';
import {
  PressableContainer,
  LNContainer,
  Container,
  Content,
  Image,
  Name,
  Detail,
  ArrowIcon,
} from './style';
import { GroupCellType } from './types';

type GroupCellProps = {
  type?: GroupCellType;
  group: Group | null;
  onPress: () => void;
};

export default function GroupCell({
  type = 'default',
  group,
  onPress,
}: GroupCellProps): JSX.Element {
  return (
    <PressableContainer onPress={onPress}>
      {({ pressed: active }) => (
        <LNContainer checked={active} type={type}>
          <Container checked={active} type={type}>
            <Image
              source={{ uri: group?.avatar || group?.icon?.icon || undefined }}
            />
            <Content>
              <Name type={type}>{group?.name}</Name>
              <Detail
                type={type}
              >{`${group?.year}, ${group?.type.name}`}</Detail>
            </Content>
            <ArrowIcon name={'chevron-right'} type={type} checked={active} />
          </Container>
        </LNContainer>
      )}
    </PressableContainer>
  );
}
