/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query InstituteTypes
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  InstituteTypes,
  InstituteTypesVariables,
} from './types/InstituteTypes';
import { INSTITUTE_TYPES } from './';

export function useInstituteTypesQuery(
  options?: QueryHookOptions<InstituteTypes, InstituteTypesVariables>,
): QueryResult<InstituteTypes, InstituteTypesVariables> {
  return useQuery<InstituteTypes, InstituteTypesVariables>(
    INSTITUTE_TYPES,
    options,
  );
}

export function useInstituteTypesLazyQuery(
  options?: LazyQueryHookOptions<InstituteTypes, InstituteTypesVariables>,
): QueryTuple<InstituteTypes, InstituteTypesVariables> {
  return useLazyQuery<InstituteTypes, InstituteTypesVariables>(
    INSTITUTE_TYPES,
    options,
  );
}

export function createInstituteTypesMock({
  variables,
  data,
  error,
}: {
  variables?: InstituteTypesVariables;
  data?: InstituteTypes;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: INSTITUTE_TYPES,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
