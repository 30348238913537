import React from 'react';
import { StyleSheet, View } from 'react-native';
import SelectDropdown from 'react-native-select-dropdown';
import { DefaultTheme } from 'styled-components/native';
import { useTheme } from 'styled-components/native';
import {
  Container,
  StepPickerTitleWrapper,
  StepPickerText,
  ArrowIcon,
} from './style';

type StepSizePickerProps = {
  title: string;
  options?: number[];
  stepSize: number;
  onStepSizeChange(stepSize: number): void;
};

export default function StepSizePicker({
  title,
  options = [5, 10, 25, 50],
  stepSize,
  onStepSizeChange,
}: StepSizePickerProps): JSX.Element {
  const theme = useTheme();
  const dropdownStyles = styles(theme);
  const renderIcon = () => (
    <View>
      <ArrowIcon name={'chevron-up'} />
      <ArrowIcon name={'chevron-down'} />
    </View>
  );

  return (
    <Container>
      <StepPickerTitleWrapper>
        <StepPickerText>{title}</StepPickerText>
      </StepPickerTitleWrapper>

      <SelectDropdown
        data={options}
        onSelect={(selectedItem) => onStepSizeChange(selectedItem)}
        defaultValue={stepSize}
        buttonTextStyle={dropdownStyles.positionContainerText}
        buttonStyle={dropdownStyles.containerStyle}
        dropdownStyle={dropdownStyles.dropDownStyle}
        rowStyle={dropdownStyles.dropDownRowStyle}
        onChangeSearchInputText={() => null}
        renderDropdownIcon={renderIcon}
        dropdownOverlayColor={'none'}
      />
    </Container>
  );
}

const styles = (theme: DefaultTheme) =>
  StyleSheet.create({
    containerStyle: {
      width: '50%',
      height: 50,
      backgroundColor: theme.color.base.c0,
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
      borderWidth: 1,
      borderColor: theme.color.base.c3,
    },
    positionContainerText: { textAlign: 'left' },
    dropDownStyle: {
      borderRadius: 5,
      height: 'auto',
      backgroundColor: theme.color.base.c0,
      borderWidth: 1,
      borderTopWidth: 1,
      borderColor: theme.color.base.c3,
    },
    dropDownRowStyle: {
      borderWidth: 0,
      borderBottomWidth: 0,
      paddingBottom: 10,
      paddingTop: 10,
    },
  });
