import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { NOTIFICATION_SETTING } from '~/data/mocks';
import { AppLocale } from '~/data/models/app';
import { useMeQuery } from '~/data/operations/auth/me';
import { useUpdateMeMutation } from '~/data/operations/auth/updateMe';
import { getLanguageFromLocale, getLocaleFromLanguage } from '~/data/utils';
import { ProfileStackParamList } from '~/navigation/types';
import NotificationSettingsLayout, {
  NotificationSettingsLoading,
} from './layout';

type NotificationSettingsNavProp = StackNavigationProp<
  ProfileStackParamList,
  'NotificationSettings'
>;

export default function NotificationSettings(): JSX.Element {
  const navigation = useNavigation<NotificationSettingsNavProp>();

  const { data: meData } = useMeQuery();

  const authUser = meData?.me;

  const [updateMe] = useUpdateMeMutation();

  const onUpdateLocale = async (locale: AppLocale) => {
    try {
      if (!authUser) {
        return;
      }
      const { firstName, lastName, email, phone, phoneCountryCode } = authUser;
      const { data } = await updateMe({
        variables: {
          input: {
            firstName,
            lastName,
            email,
            phone,
            phoneCountryCode,
            communicationLanguage: getLanguageFromLocale(locale),
          },
        },
      });
      const messages = data?.updateMe?.errors?.map(
        (error) => error?.messages[0],
      );
      const errorMessage = messages?.[0];
      if (errorMessage) {
        Snackbar.show(errorMessage);
        return;
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  if (!authUser) {
    return <NotificationSettingsLoading onBack={() => navigation.goBack()} />;
  }

  //TODO: integrate settings
  return (
    <NotificationSettingsLayout
      initialNotificationSetting={NOTIFICATION_SETTING}
      initialSelectedLocale={
        authUser.communicationLanguage
          ? getLocaleFromLanguage(authUser.communicationLanguage)
          : undefined
      }
      onBack={() => navigation.goBack()}
      onUpdateLocale={onUpdateLocale}
    />
  );
}
