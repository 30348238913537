import { Platform, Animated } from 'react-native';
import styled, { css } from 'styled-components/native';
import Image from '~/components/Image';
import { InputFieldSizeType } from '~/components/InputField/types';

export const Container = styled.View``;

interface IFocusContainer {
  validationColor: string;
}
export const FocusContainer = styled.View<IFocusContainer>`
  border-width: 2px;
  border-color: ${({ validationColor }) => validationColor};
  border-radius: 14px;
  ${Platform.OS === 'web' &&
  css`
    padding-left: 0;
    padding-right: 0;
  `}
`;

interface IBoxContainer {
  validationColor: string;
}
export const BoxContainer = styled.TouchableOpacity.attrs({
  activeOpacity: 1.0,
})<IBoxContainer>`
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  padding: ${({ theme }) => theme.spacing.medium};
`;

interface ISizeAwareView {
  size: InputFieldSizeType;
}

export const Content = styled.ScrollView.attrs({
  contentContainerStyle: {
    flexGrow: 1,
    alignItems: 'center',
  },
})<ISizeAwareView>`
  min-height: ${({ size }) => (size === 'default' ? '40px' : '20px')};
`;

export const TextInput = styled.TextInput`
  ${Platform.OS == 'ios' &&
  `
    flex: 1;
  `}
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: ${({ theme }) => theme.fontSize.title};
  color: ${({ theme }) => theme.color.base.c9};
  justify-content: flex-end;
  ${Platform.OS == 'web' &&
  `
    outline-color: transparent;
    outline-style: none;
  `}
  margin-top: 13px;
`;

type ITag = {
  isError: boolean;
  width?: string;
};

export const TagWrapper = styled.TouchableOpacity<ITag>`
  color: ${({ theme, isError }) =>
    isError ? theme.color.error : theme.color.base.c9};
  background-color: ${({ theme, isError }) =>
    isError ? theme.color.base.c1 : theme.color.base.c2};
  flex-direction: row;
  border-radius: 3px;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 14px;
  padding-left: 5px;
  margin-top: 13px;
`;
export const TagsText = styled.Text<ITag>`
  color: ${({ theme, isError }) =>
    isError ? theme.color.error : theme.color.base.c9};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
`;

export const TagsInput = styled.TextInput<ITag>`
  ${Platform.OS == 'ios' &&
  `
    flex: 1;
  `}
  width: ${({ width }) => (width ? width : '2ch')};
  font-family: ${({ theme }) => theme.fontFamily.monospace.w400};
  font-size: 14px;
  color: ${({ theme, isError }) =>
    isError ? theme.color.error : theme.color.brand_02};
  background-color: ${({ theme, isError }) =>
    isError ? theme.color.base.c1 : 'transparent'};
  padding: 1px 5px;
  ${Platform.OS == 'web' &&
  `
    outline-color: transparent;
    outline-style: none;
  `}
`;

export const Limit = styled.Text`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.color.base.c0};
  color: ${({ theme }) => theme.color.brand_02};
  font-size: 14px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  padding: 3px;
  border-radius: 5px;
  justify-content: space-between;
  margin-top: 13px;
`;

export const Label = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
`;

export const AnimatedLabel = Animated.createAnimatedComponent(Label);

export const InfoContent = styled.View`
  height: 22px;
  padding-vertical: 4px;
  padding-horizontal: 4px;
`;

export const InfoLabel = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: ${({ theme }) => theme.fontSize.footnote};
  font-weight: 400;
  color: ${({ theme }) => theme.color.base.c9};
`;

export const AddOn = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: 400;
  color: ${({ theme }) => theme.color.base.c6};
`;

export const TypeContent = styled.View<ISizeAwareView>`
  height: ${({ size }) => (size === 'default' ? '40px' : '20px')};
  min-width: 30px;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`;

export const PhoneContent = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})`
  height: 40px;
  min-width: 30px;
  align-items: center;
  flex-direction: row;
  margin-right: 16px;
`;

export const PhoneSelection = styled.View`
  padding-left: ${({ theme }) => theme.spacing.tiny};
`;

export const PhoneCode = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.color.base.c6};
  padding-left: ${({ theme }) => theme.spacing.medium};
`;

export const FlagImage = styled(Image)`
  height: 15px;
  width: 24px;
`;

export const ButtonIconView = styled.View`
  padding-left: 6px;
`;

export const InputContainer = styled.View`
  flex-direction: row;
  justify-content: flex-start;
`;

export const DeleteIconWrapper = styled.View`
  align-items: center;
  padding-right: 5px;
`;

export const DeleteShortcutWrapper = styled.View`
  align-items: center;
  padding-left: 5px;
`;
