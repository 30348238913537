import * as React from 'react';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
// import Button from '~/components/Button';
import { AuthStackParamList } from '~/navigation/types';
import {
  Container,
  LogoContainer,
  LogoImage,
  CenterFlex,
  HeaderRightView,
  CursorPointer,
} from './style';

type WebLogoNavProp = StackNavigationProp<AuthStackParamList, 'Launch'>;

interface WebHeaderProps {
  onCustomPress: () => void;
  HeaderRightComponent?: JSX.Element | null;
  selectedLocale: string | undefined;
}

export default function WebHeader({
  HeaderRightComponent = null,
  selectedLocale = 'en',
}: WebHeaderProps): JSX.Element {
  const navigation = useNavigation<WebLogoNavProp>();

  // if (!HeaderRightComponent) {
  //   HeaderRightComponent = (
  //     <Button
  //       icon={'only'}
  //       iconName={'magic-wand-01'}
  //       type={'sub-action'}
  //       size={'xl'}
  //       onPress={onCustomPress}
  //     />
  //   );
  // }

  return (
    <Container>
      <LogoContainer
        onPress={() => navigation.navigate('Launch', { lang: selectedLocale })}
      >
        <CursorPointer>
          <LogoImage />
        </CursorPointer>
      </LogoContainer>
      <CenterFlex />
      <HeaderRightView>{HeaderRightComponent}</HeaderRightView>
    </Container>
  );
}
