/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation CommentPost
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { CommentPost, CommentPostVariables } from './types/CommentPost';
import { COMMENT_POST } from './';

export function useCommentPostMutation(
  options?: MutationHookOptions<CommentPost, CommentPostVariables>,
): MutationTuple<CommentPost, CommentPostVariables> {
  return useMutation<CommentPost, CommentPostVariables>(COMMENT_POST, options);
}

export function createCommentPostMock({
  variables,
  data,
  error,
}: {
  variables?: CommentPostVariables;
  data?: CommentPost;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: COMMENT_POST,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
