/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation AcceptUserInGroup
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  AcceptUserInGroup,
  AcceptUserInGroupVariables,
} from './types/AcceptUserInGroup';
import { ACCEPT_USER_IN_GROUP } from './';

export function useAcceptUserInGroupMutation(
  options?: MutationHookOptions<AcceptUserInGroup, AcceptUserInGroupVariables>,
): MutationTuple<AcceptUserInGroup, AcceptUserInGroupVariables> {
  return useMutation<AcceptUserInGroup, AcceptUserInGroupVariables>(
    ACCEPT_USER_IN_GROUP,
    options,
  );
}

export function createAcceptUserInGroupMock({
  variables,
  data,
  error,
}: {
  variables?: AcceptUserInGroupVariables;
  data?: AcceptUserInGroup;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: ACCEPT_USER_IN_GROUP,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
