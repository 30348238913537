/* eslint-disable no-console */
import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { TODO_T1_1 } from '~/data/mocks';
import { ToDoList } from '~/data/models/todo';
import { PlanningStackParamList } from '~/navigation/types';
import ToDoTasksLayout from './layout';

type ToDoTasksNavProp = StackNavigationProp<
  PlanningStackParamList,
  'ToDoTasks'
>;

// TODO to be uncommented when API is available
// type ToDoTasksRouteProp = RouteProp<PlanningStackParamList, 'ToDoTasks'>;

export default function ToDoTasks(): JSX.Element {
  const navigation = useNavigation<ToDoTasksNavProp>();

  // TODO to be uncommented when API is available
  // const {
  //   params: { listId },
  // } = useRoute<ToDoTasksRouteProp>();

  const list: ToDoList = TODO_T1_1;

  return (
    <ToDoTasksLayout
      title={list.text}
      items={list.items}
      onAddTask={() => console.log('onAddTask')}
      onPressed={() => console.log('onPressed')}
      onStateChanged={() => console.log('onStatePressed')}
      onBack={() => navigation.goBack()}
      onDeleteTask={() => console.log('onDeleteTask')}
      onEditTask={() => console.log('onEditTask')}
    />
  );
}
