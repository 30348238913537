/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query CustomLists
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { CustomLists, CustomListsVariables } from './types/CustomLists';
import { CUSTOM_LISTS } from './';

export function useCustomListsQuery(
  options?: QueryHookOptions<CustomLists, CustomListsVariables>,
): QueryResult<CustomLists, CustomListsVariables> {
  return useQuery<CustomLists, CustomListsVariables>(CUSTOM_LISTS, options);
}

export function useCustomListsLazyQuery(
  options?: LazyQueryHookOptions<CustomLists, CustomListsVariables>,
): QueryTuple<CustomLists, CustomListsVariables> {
  return useLazyQuery<CustomLists, CustomListsVariables>(CUSTOM_LISTS, options);
}

export function createCustomListsMock({
  variables,
  data,
  error,
}: {
  variables?: CustomListsVariables;
  data?: CustomLists;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: CUSTOM_LISTS,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
