import React, { useEffect, useState } from 'react';
import SectionHeader from '~/components/SectionHeader';
import { SettingPanel, SettingToggleItem } from '~/components/SettingPanel';
import { ModuleInstance, ReportsSetupConfig } from '~/data/models/yearbook';
import { t } from '~/utils/i18n';
import ModuleSetupLayout, {
  UpdateModuleInstanceInput,
} from '../../layout/ModuleSetupLayout';

export type UpdateReportsSetupInput = {
  allowImages: boolean;
};

type ReportsSetupLayoutProps = {
  moduleInstance: ModuleInstance;
  reportsSetupConfig: ReportsSetupConfig;
  onBack: () => void;
  onUpdateModuleInstance: (
    updateModuleInstanceInput: UpdateModuleInstanceInput,
  ) => void;
  onUpdateReportsSetup: (
    updateReportsSetupInput: UpdateReportsSetupInput,
  ) => void;
};

export default function ReportsSetup({
  moduleInstance,
  reportsSetupConfig,
  onUpdateModuleInstance,
  onUpdateReportsSetup,
  onBack,
}: ReportsSetupLayoutProps): JSX.Element {
  const [allowImages, setAllowImages] = useState(
    () => reportsSetupConfig.allowImages,
  );

  useEffect(() => {
    const updateAllowImages =
      reportsSetupConfig && allowImages != reportsSetupConfig.allowImages;

    if (updateAllowImages) {
      onUpdateReportsSetup({
        allowImages,
      });
    }
  }, [allowImages]);

  return (
    <ModuleSetupLayout
      title={t('screens.reportsSetup')}
      activateText={t('reportsSetup.moduleActivate')}
      moduleInstance={moduleInstance}
      onBack={onBack}
      onUpdateModuleInstance={onUpdateModuleInstance}
    >
      <>
        <SectionHeader sub title={t('g.settings')} />
        <SettingPanel>
          <SettingToggleItem
            testID={'allowImageToggleItem'}
            text={t('reportsSetup.allowImagesSection')}
            checked={allowImages}
            onToggleChange={setAllowImages}
          />
        </SettingPanel>
      </>
    </ModuleSetupLayout>
  );
}
