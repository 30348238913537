import React, { useState } from 'react';
import Button from '~/components/Button';
import InputField from '~/components/InputField';
import NavHeader from '~/components/NavHeader';
import { t } from '~/utils/i18n';
import { Container, ScrollView, InputContainer, Footer } from './style';

export type RankingsCustomListOptionLayoutProps = {
  loading?: boolean;
  onBack: () => void;
  onCreateOption: (text: string) => void;
};

export default function RankingsCustomListOption({
  loading,
  onBack,
  onCreateOption,
}: RankingsCustomListOptionLayoutProps): JSX.Element {
  const [text, setText] = useState('');

  const isButtonEnabled = text.length > 0;

  return (
    <Container>
      <NavHeader
        backButtonTestID={'buttonBack'}
        title={t('screens.rankingsCustomListOption')}
        onBackPress={onBack}
      />
      <ScrollView>
        <InputContainer>
          <InputField
            testID={'inputText'}
            value={text}
            label={t('rankingsCustomListOption.textLabel')}
            onChangeText={setText}
          />
        </InputContainer>
      </ScrollView>
      <Footer>
        <Button
          testID={'buttonSaveOption'}
          state={isButtonEnabled ? 'default' : 'disabled'}
          loading={loading}
          text={t('rankingsCustomListOption.addOption')}
          flex
          onPress={() => onCreateOption(text)}
        />
      </Footer>
    </Container>
  );
}
