import React from 'react';
import { general } from '~/assets/imgs';
import { mapUser } from '~/data/mappers';
import { UserGroup } from '~/data/models/group';
import { TeamUser } from '~/data/models/team';
import Icon, { IconName } from '../Icon';
import { Container, Image, Text, NotifyBadge } from './style';
import { BGColorType } from './types';

export type AvatarProps = {
  teamUser?: TeamUser | null;
  userGroup?: UserGroup | null;
  initials?: string;
  uri?: string | null;
  size?: number;
  bgColorType?: BGColorType;
  notify?: boolean;
  localImage?: number;
  icon?: IconName;
};

export default function Avatar({
  userGroup,
  teamUser,
  initials = mapUser(userGroup?.user)?.initials ||
    mapUser(teamUser?.user)?.initials,
  uri = userGroup?.avatar ||
    userGroup?.defaultAvatar?.avatar ||
    teamUser?.avatar ||
    teamUser?.defaultAvatar,
  size = 48,
  bgColorType = 'base',
  notify,
  icon,
  localImage,
  ...style
}: AvatarProps): JSX.Element {
  return (
    <>
      <Container
        {...style}
        bgColorType={bgColorType}
        size={size}
        noPic={!uri && !initials && !icon}
      >
        {(() => {
          if (uri) {
            return <Image size={size} source={{ uri }} />;
          }
          if (initials && initials.length == 2) {
            return <Text size={size}>{initials}</Text>;
          }
          if (localImage) {
            return <Image size={size} source={localImage} />;
          }

          if (icon) {
            return (
              <Icon name={icon} size={size / 2} strokeFill={bgColorType} />
            );
          }

          return <Image size={size} source={general.nopic} />;
        })()}
      </Container>
      {notify && <NotifyBadge size={size} />}
    </>
  );
}

/**
 * TODO:
 * - Improve icon logic to list?
 * - Handle unique icon per user?
 */
