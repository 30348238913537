/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation SetGroupAvatar
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  SetGroupAvatar,
  SetGroupAvatarVariables,
} from './types/SetGroupAvatar';
import { SET_GROUP_AVATAR } from './';

export function useSetGroupAvatarMutation(
  options?: MutationHookOptions<SetGroupAvatar, SetGroupAvatarVariables>,
): MutationTuple<SetGroupAvatar, SetGroupAvatarVariables> {
  return useMutation<SetGroupAvatar, SetGroupAvatarVariables>(
    SET_GROUP_AVATAR,
    options,
  );
}

export function createSetGroupAvatarMock({
  variables,
  data,
  error,
}: {
  variables?: SetGroupAvatarVariables;
  data?: SetGroupAvatar;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: SET_GROUP_AVATAR,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
