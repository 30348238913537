/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query Events
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { Events, EventsVariables } from './types/Events';
import { EVENTS } from './';

export function useEventsQuery(
  options?: QueryHookOptions<Events, EventsVariables>,
): QueryResult<Events, EventsVariables> {
  return useQuery<Events, EventsVariables>(EVENTS, options);
}

export function useEventsLazyQuery(
  options?: LazyQueryHookOptions<Events, EventsVariables>,
): QueryTuple<Events, EventsVariables> {
  return useLazyQuery<Events, EventsVariables>(EVENTS, options);
}

export function createEventsMock({
  variables,
  data,
  error,
}: {
  variables?: EventsVariables;
  data?: Events;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: EVENTS,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
