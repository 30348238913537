import * as React from 'react';
import {
  CompositeNavigationProp,
  useNavigation,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { durations, positions } from '~/components/Snackbar/SnackbarContainer';
import { setAbihomeUser } from '~/data/storage';
import { ImporterStackParamList, RootStackParamList } from '~/navigation/types';
import { IMPORTER_FORGOT_PASSWORD } from '~/screens/Importer/constants';
import { t } from '~/utils/i18n';
import { login } from './data';
import AbihomeLoginLayout from './layout';

type AbihomeLoginNavProp = CompositeNavigationProp<
  StackNavigationProp<RootStackParamList>,
  StackNavigationProp<ImporterStackParamList, 'AbihomeLogin'>
>;

export default function AbihomeLogin(): JSX.Element {
  const navigation = useNavigation<AbihomeLoginNavProp>();

  const onBack = () => navigation.popToTop();

  const onForgotPassword = () =>
    navigation.navigate('Browser', {
      title: IMPORTER_FORGOT_PASSWORD.title,
      url: IMPORTER_FORGOT_PASSWORD.url,
    });

  const onNext = async (email: string, password: string) => {
    const response = await login(email, password);

    if (response.success) {
      const { isAdmin, message, name, userCount } = response;
      if (isAdmin) {
        await setAbihomeUser(email, password);
        navigation.navigate('SetupGroup', { name: name || '', userCount });
      } else if (!isAdmin) {
        Snackbar.show(t('importer.adminError'), {
          position: positions.BOTTOM_STACK,
          duration: durations.LONG,
        });
      } else {
        Snackbar.show(message, {
          position: positions.BOTTOM_STACK,
          duration: durations.LONG,
        });
      }
    } else {
      const { message } = response;
      Snackbar.show(message, {
        position: positions.BOTTOM_STACK,
        duration: durations.LONG,
      });
    }
  };

  return (
    <AbihomeLoginLayout
      loading={false}
      onBack={onBack}
      onForgotPassword={onForgotPassword}
      onNext={onNext}
    />
  );
}
