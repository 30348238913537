import { gql } from '@apollo/client';

export const USER_FIELDS = gql`
  fragment UserFields on UserNode {
    id
    firstName
    lastName
    gender
  }
`;

export const USER_FIELDS_WITH_TEAMS = gql`
  ${USER_FIELDS}
  fragment UserFieldsWithTeams on UserNode {
    ...UserFields
    teams {
      id
      name
      color {
        hex
      }
      icon {
        id
        icon
      }
    }
  }
`;
