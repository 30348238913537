import styled from 'styled-components/native';
import { TabScreenContainer } from '~/screens/style';

export const Container = styled(TabScreenContainer)``;

export const Header = styled.View`
  padding-horizontal: 16px;
`;

export const List = styled.FlatList``;
