import styled from 'styled-components/native';

export const Header = styled.View`
  padding-horizontal: 16px;
  padding-vertical: 24px;
`;

export const AlbumList = styled.FlatList``;

export const AlbumCellWrapper = styled.View`
  padding-bottom: 16px;
`;

export const RefreshControl = styled.RefreshControl.attrs(({ theme }) => ({
  color: theme.color.base.c4,
}))``;

export const Content = styled.View`
  padding-vertical: 24px;
  align-items: center;
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c7,
}))``;

export const AssetWrapper = styled.TouchableOpacity`
  margin-left: 16px;
  margin-right: 16px;
`;
