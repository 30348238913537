import { DefaultTheme } from 'styled-components/native';
import { ButtonState, ButtonSize, ButtonType } from './types';

/** Size */
interface ButtonSizeHelper {
  textSize: (size: ButtonSize) => number;
  lineHeight: (size: ButtonSize) => number;
  paddingV: (size: ButtonSize) => number;
  paddingH: (size: ButtonSize) => number;
  iconSize: (size: ButtonSize) => number;
}

type SizeNumberMap = {
  [key in ButtonSize]: number;
};
export const bySizeHelper: ButtonSizeHelper = {
  textSize: (size: ButtonSize): number => {
    const sizeMap: SizeNumberMap = {
      sm: 14,
      md: 14,
      lg: 16,
      xl: 18,
      '2xl': 20,
    };
    return sizeMap[size];
  },
  lineHeight: (size: ButtonSize): number => {
    const sizeMap: SizeNumberMap = {
      sm: 18,
      md: 18,
      lg: 20,
      xl: 24,
      '2xl': 24,
    };
    return sizeMap[size];
  },
  paddingV: (size: ButtonSize): number => {
    const sizeMap: SizeNumberMap = {
      sm: 8,
      md: 10,
      lg: 12,
      xl: 12,
      '2xl': 16,
    };
    return sizeMap[size];
  },
  paddingH: (size: ButtonSize): number => {
    const sizeMap: SizeNumberMap = {
      sm: 16,
      md: 16,
      lg: 16,
      xl: 16,
      '2xl': 32,
    };
    return sizeMap[size];
  },
  iconSize: (size: ButtonSize): number => {
    const sizeMap: SizeNumberMap = {
      sm: 16,
      md: 16,
      lg: 16,
      xl: 24,
      '2xl': 24,
    };
    return sizeMap[size];
  },
};

/** Types */
interface ButtonStateMap {
  innerBorderColor: (state: ButtonState, theme: DefaultTheme) => string;
  bgColor: (state: ButtonState, theme: DefaultTheme) => string;
  textColor: (state: ButtonState, theme: DefaultTheme) => string;
}

const primaryBrand01ButtonStateMap: ButtonStateMap = {
  innerBorderColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled' ? theme.color.base.c5 : '#E5A094';
  },
  bgColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled' ? theme.color.base.c5 : theme.color.brand_01;
  },
  textColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled' ? theme.color.base.c1 : theme.color.base.c10;
  },
};

const primaryBrand02ButtonStateMap: ButtonStateMap = {
  innerBorderColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled' ? theme.color.base.c5 : '#3967C3';
  },
  bgColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled' ? theme.color.base.c5 : theme.color.brand_02;
  },
  textColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled' ? theme.color.base.c1 : theme.color.base.c0;
  },
};

const primaryBrand03ButtonStateMap: ButtonStateMap = {
  innerBorderColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled' ? theme.color.base.c5 : theme.color.error;
  },
  bgColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled' ? theme.color.base.c5 : theme.color.error;
  },
  textColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled' ? theme.color.base.c1 : theme.color.base.c0;
  },
};

const secondaryBaseButtonStateMap: ButtonStateMap = {
  innerBorderColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled' ? '#9FA7AE' : theme.color.base.c2;
  },
  bgColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled' ? theme.color.base.c4 : theme.color.base.c0;
  },
  textColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled' ? theme.color.base.c1 : theme.color.base.c9;
  },
};

const secondaryContrastButtonStateMap: ButtonStateMap = {
  innerBorderColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled' ? '#9FA7AE' : theme.color.base.c9;
  },
  bgColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled' ? theme.color.base.c4 : theme.color.base.c9;
  },
  textColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled' ? theme.color.base.c1 : theme.color.base.c0;
  },
};

const subActionButtonStateMap: ButtonStateMap = {
  innerBorderColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled' ? '#9FA7AE' : theme.color.base.c2;
  },
  bgColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled' ? theme.color.base.c3 : theme.color.base.c2;
  },
  textColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled' ? theme.color.base.c1 : theme.color.brand_02;
  },
};

const destructiveButtonStateMap: ButtonStateMap = {
  innerBorderColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled' ? '#9FA7AE' : theme.color.base.c2;
  },
  bgColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled' ? theme.color.base.c5 : theme.color.error;
  },
  textColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled' ? theme.color.base.c1 : theme.color.base.c0;
  },
};

type ButtonTypeHandler = { [key in ButtonType]: ButtonStateMap };

const buttonTypeHandler: ButtonTypeHandler = {
  'primary-brand-01': primaryBrand01ButtonStateMap,
  'primary-brand-02': primaryBrand02ButtonStateMap,
  'primary-brand-03': primaryBrand03ButtonStateMap,
  'secondary-base': secondaryBaseButtonStateMap,
  'secondary-contrast': secondaryContrastButtonStateMap,
  'sub-action': subActionButtonStateMap,
  destructive: destructiveButtonStateMap,
};

interface ButtonTypeStateHelper {
  innerBorderColor: (
    type: ButtonType,
    state: ButtonState,
    theme: DefaultTheme,
  ) => string;
  bgColor: (
    type: ButtonType,
    state: ButtonState,
    theme: DefaultTheme,
  ) => string;
  textColor: (
    type: ButtonType,
    state: ButtonState,
    theme: DefaultTheme,
  ) => string;
}

export const byTypeHelper: ButtonTypeStateHelper = {
  innerBorderColor: (
    type: ButtonType,
    state: ButtonState,
    theme: DefaultTheme,
  ): string => {
    return buttonTypeHandler[type].innerBorderColor(state, theme);
  },
  bgColor: (
    type: ButtonType,
    state: ButtonState,
    theme: DefaultTheme,
  ): string => {
    return buttonTypeHandler[type].bgColor(state, theme);
  },
  textColor: (
    type: ButtonType,
    state: ButtonState,
    theme: DefaultTheme,
  ): string => {
    return buttonTypeHandler[type].textColor(state, theme);
  },
};
