import React from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { NO_PAG_LIST_OFFSET } from '~/data/constants';
import { ProfilePageUserSummary } from '~/data/models/yearbook';
import { useModuleInstanceResultQuery } from '~/data/operations/yearbook/moduleInstanceResult';
import { useProfilePagePhotoCategoriesQuery } from '~/data/operations/yearbook/profilePagePhotoCategories';
import { useProfilePageQuestionsQuery } from '~/data/operations/yearbook/profilePageQuestions';
import { YearbookStackParamList } from '~/navigation/types';
import ProfilePageResultLayout from './layout';

type ProfilePageResultNavProp = StackNavigationProp<
  YearbookStackParamList,
  'ProfilePageResults'
>;

type ProfilePageResultRouteProp = RouteProp<
  YearbookStackParamList,
  'ProfilePageResults'
>;

export default function ProfilePageResults(): JSX.Element {
  const navigation = useNavigation<ProfilePageResultNavProp>();
  const viewOnly = true;
  const {
    params: { moduleInstanceId },
  } = useRoute<ProfilePageResultRouteProp>();

  const { data: moduleInstanceResultData } = useModuleInstanceResultQuery({
    variables: {
      id: moduleInstanceId,
    },
  });

  const moduleInstanceResult = moduleInstanceResultData?.moduleInstance;

  const usersSummary =
    (moduleInstanceResult?.profilePageSetup
      ?.usersSummary as ProfilePageUserSummary[]) || [];

  const { data: questionsData } = useProfilePageQuestionsQuery({
    variables: {
      moduleInstance: moduleInstanceId,
      first: NO_PAG_LIST_OFFSET,
    },
  });

  const totalQuestions = questionsData?.profilePageQuestions?.edges.length || 0;

  const { data: photoCategoriesData } = useProfilePagePhotoCategoriesQuery({
    variables: {
      moduleInstance: moduleInstanceId,
      first: NO_PAG_LIST_OFFSET,
    },
  });

  const totalImages =
    photoCategoriesData?.profilePagePhotoCategories?.edges.length || 0;

  return (
    <ProfilePageResultLayout
      usersSummary={usersSummary}
      totalQuestions={totalQuestions}
      totalImages={totalImages}
      onBack={() => navigation.goBack()}
      onSelectedUserGroup={(userGroupId) =>
        navigation.navigate('ProfilePage', {
          moduleInstanceId,
          userGroupId,
          viewOnly,
        })
      }
    />
  );
}
