/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query RankingsQuestion
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  RankingsQuestion,
  RankingsQuestionVariables,
} from './types/RankingsQuestion';
import { RANKINGS_QUESTION } from './';

export function useRankingsQuestionQuery(
  options?: QueryHookOptions<RankingsQuestion, RankingsQuestionVariables>,
): QueryResult<RankingsQuestion, RankingsQuestionVariables> {
  return useQuery<RankingsQuestion, RankingsQuestionVariables>(
    RANKINGS_QUESTION,
    options,
  );
}

export function useRankingsQuestionLazyQuery(
  options?: LazyQueryHookOptions<RankingsQuestion, RankingsQuestionVariables>,
): QueryTuple<RankingsQuestion, RankingsQuestionVariables> {
  return useLazyQuery<RankingsQuestion, RankingsQuestionVariables>(
    RANKINGS_QUESTION,
    options,
  );
}

export function createRankingsQuestionMock({
  variables,
  data,
  error,
}: {
  variables?: RankingsQuestionVariables;
  data?: RankingsQuestion;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: RANKINGS_QUESTION,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
