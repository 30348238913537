import styled from 'styled-components/native';

export const PressableContainer = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})``;

export const Content = styled.View`
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
`;

export const TextContainer = styled.View``;
export const Title = styled.Text`
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.brand_02};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
`;
export const Desc = styled.Text`
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.color.base.c10};
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
`;

export const IconContainer = styled.View`
  position: relative;
`;

export const BadgeText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 12px;
  color: ${({ theme }) => theme.color.base.c0};
`;

export const Badge = styled.View`
  position: absolute;
  background-color: ${({ theme }) => theme.color.error};
  top: -8px;
  right: -5px;
  width: 16px;
  height: 16px;
  flex-direction: row;
  justify-content: center;
  border-radius: 8px;
`;
