import React from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { useIntercom } from '~/context/intercom';
import { NO_PAG_LIST_OFFSET } from '~/data/constants';
import useTeams from '~/data/hooks/useTeams';
import { Color, Icon } from '~/data/models/custom';
import { TeamUser } from '~/data/models/team';
import { useColorsQuery } from '~/data/operations/custom/colors';
import { useIconsQuery } from '~/data/operations/custom/icons';
import { Status, TeamType } from '~/data/operations/global';
import { useAddUserToTeamMutation } from '~/data/operations/team/addUserToTeam';
import {
  readTeamDetailFragment,
  readTeamSampleFragment,
  writeTeamDetailFragment,
  writeTeamSampleFragment,
} from '~/data/operations/team/helpers';
import { useRemoveUserFromTeamMutation } from '~/data/operations/team/removeUserFromTeam';
import { useTeamDetailQuery } from '~/data/operations/team/teamDetail';
import { useUpdateTeamMutation } from '~/data/operations/team/updateTeam';
import { PlanningStackParamList } from '~/navigation/types';
import { t } from '~/utils/i18n';
import EditTeamLayout, { EditTeamInputProps, EditTeamLoading } from './layout';

type EditTeamNavProp = StackNavigationProp<PlanningStackParamList, 'EditTeam'>;

type EditTeamRouteProp = RouteProp<PlanningStackParamList, 'TeamDetail'>;

export default function EditTeam(): JSX.Element {
  const navigation = useNavigation<EditTeamNavProp>();
  const {
    params: { teamId },
  } = useRoute<EditTeamRouteProp>();

  const { authUserId, refetchAuthUserGroup } = useAuth();
  const { onHelpCenter } = useIntercom();

  const { removeTeamLoading, onRemoveTeam } = useTeams();

  const {
    data: teamData,
    loading: teamLoading,
    refetch: refetchTeam,
  } = useTeamDetailQuery({
    skip: !authUserId,
    variables: {
      id: teamId,
    },
  });
  const team = teamData?.team;
  const teamUsers: TeamUser[] =
    (team?.users?.map((user) => user) as TeamUser[]) || [];

  const acceptedTeamUsers: TeamUser[] = teamUsers.filter(
    (teamUser) => teamUser.status === Status.ACCEPTED,
  );

  const { data: colorsData } = useColorsQuery({
    variables: {
      first: NO_PAG_LIST_OFFSET,
    },
  });
  const { data: iconsData } = useIconsQuery({
    variables: {
      first: NO_PAG_LIST_OFFSET,
    },
  });

  const colors: Color[] =
    (colorsData?.colors?.edges.map((edge) => edge?.node) as Color[]) || [];

  const icons: Icon[] =
    (iconsData?.icons?.edges.map((edge) => edge?.node) as Icon[]) || [];

  const [updateTeam] = useUpdateTeamMutation();
  const [removeUserFromTeam] = useRemoveUserFromTeamMutation();

  const [addUserToTeam] = useAddUserToTeamMutation({
    update(cache, { data }) {
      const newTeamUser = data?.addUserToTeam?.userTeam;

      const currentTeam = readTeamDetailFragment({
        cache,
        id: teamId,
      });

      const currentTeamSample = readTeamSampleFragment({
        cache,
        id: teamId,
      });

      if (currentTeam && newTeamUser) {
        const currentTeamUsers = currentTeam?.users || [];
        const updatedTeamUsers = [...currentTeamUsers, newTeamUser];

        writeTeamDetailFragment({
          cache,
          id: teamId,
          data: {
            ...currentTeam,
            users: updatedTeamUsers,
          },
        });

        if (currentTeamSample && newTeamUser.status === Status.ACCEPTED) {
          writeTeamSampleFragment({
            cache,
            id: teamId,
            data: {
              ...currentTeamSample,
              usersCount: updatedTeamUsers?.length || 0,
              sampleMembers: updatedTeamUsers,
            },
          });
        }
      }
    },
  });

  const onEditTeam = async ({ name, colorId, iconId }: EditTeamInputProps) => {
    try {
      const { data } = await updateTeam({
        variables: {
          input: {
            id: teamId,
            name,
            color: colorId,
            icon: iconId,
          },
        },
      });
      const errors = data?.updateTeam?.errors;
      if (errors && errors.length > 0) {
        const messages = errors.map((error) => error?.messages[0]);
        const errorMessage = messages?.[0] as string;
        Snackbar.show(errorMessage);
        return;
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onRemoveUser = async (userId: string, leavingTeam: boolean) => {
    try {
      await removeUserFromTeam({
        variables: {
          input: {
            teamId,
            userId,
          },
        },
        update(cache) {
          const currentTeam = readTeamDetailFragment({
            cache,
            id: teamId,
          });

          const currentTeamSample = readTeamSampleFragment({
            cache,
            id: teamId,
          });

          if (currentTeam) {
            const currentTeamUsers = currentTeam?.users || [];
            const updatedTeamUsers = currentTeamUsers.filter(
              (teamUser) => teamUser && teamUser.user.id != userId,
            );

            writeTeamDetailFragment({
              cache,
              id: teamId,
              data: {
                ...currentTeam,
                users: updatedTeamUsers,
              },
            });

            if (currentTeamSample) {
              writeTeamSampleFragment({
                cache,
                id: teamId,
                data: {
                  ...currentTeamSample,
                  usersCount: updatedTeamUsers?.length || 0,
                  sampleMembers: updatedTeamUsers,
                },
              });
            }
          }
        },
      });
      Snackbar.show(t('editTeam.removeUserSuccess'), {
        position: Snackbar.positions.BOTTOM_TAB,
        duration: Snackbar.durations.SHORT,
      });
      leavingTeam && navigation.goBack();
      leavingTeam &&
        (team?.type === TeamType.YEARBOOK || team?.type === TeamType.CORE) &&
        refetchAuthUserGroup();
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onAddUser = async (userId: string) => {
    try {
      await addUserToTeam({
        variables: {
          input: {
            teamId,
            userId,
          },
        },
      });
      Snackbar.show(t('editTeam.addUserSuccess'), {
        position: Snackbar.positions.BOTTOM_TAB,
        duration: Snackbar.durations.SHORT,
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onAddMember = () => {
    navigation.navigate('AddTeamMember', {
      teamId,
      onAddUser: (userId) => onAddUser(userId),
    });
  };

  if (!team) {
    return <EditTeamLoading onBack={() => navigation.goBack()} />;
  }

  return (
    <EditTeamLayout
      authUserId={authUserId}
      team={team}
      colors={colors}
      icons={icons}
      teamUsers={acceptedTeamUsers}
      loading={teamLoading}
      removeUserLoading={false}
      removeTeamLoading={removeTeamLoading}
      onBack={() => navigation.goBack()}
      onEditTeam={onEditTeam}
      onRemoveUser={onRemoveUser}
      onAddMember={onAddMember}
      onHelp={onHelpCenter}
      onRefresh={() => {
        refetchTeam();
      }}
      onRemoveTeam={() =>
        onRemoveTeam(
          {
            teamId,
            teamType: team.type,
          },
          () => {
            if (team.type === TeamType.OTHER) {
              navigation.pop(2);
            } else {
              navigation.navigate('TeamDetail', { teamId });
            }
          },
        )
      }
    />
  );
}
