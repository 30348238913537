import React from 'react';
import Avatar from '~/components/Avatar';
import { UserGroup } from '~/data/models/group';
import { t } from '~/utils/i18n';
import { Container, Content, Text } from './style';

export type InputHeaderProps = {
  loggedUserGroup?: UserGroup | null;
  onCreatePost: (imgUris?: string[]) => void;
  onCreatePoll: () => void;
};

export default function InputHeader({
  loggedUserGroup,
  onCreatePost,
}: InputHeaderProps): JSX.Element {
  return (
    <Container>
      <Avatar size={38} userGroup={loggedUserGroup} />
      <Content>
        <Text testID={'textInput'} onPress={() => onCreatePost()}>
          {t('feed.inputPlaceholder')}
        </Text>
      </Content>
    </Container>
  );
}
