import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  ForwardRefRenderFunction,
} from 'react';
import {
  Animated,
  TextInput as RNTextInput,
  TextInputProps,
} from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { useTheme } from 'styled-components/native';
import { flags } from '~/assets/imgs';
import ButtonIcon from '~/components/ButtonIcon';
import Icon, { IconName } from '~/components/Icon';
import { COUNTRIES } from '~/data/constants';
import { Country } from '~/data/models/marketProfile';
import {
  Container,
  BoxContainer,
  TypeContent,
  AddOn,
  PhoneContent,
  PhoneSelection,
  PhoneCode,
  FlagImage,
  AnimatedLabel,
  Content,
  TextInput,
  InfoContent,
  InfoLabel,
  FocusContainer,
  IconView,
  InfoLink,
  InfoLinkView,
  PreTagContent,
  PreTagText,
  LabelFlex,
  LABEL_HEIGHT,
  LABEL_HEIGHT_FILLED,
} from './style';
import { InputFieldSizeType, InputFieldState, StateColorMap } from './types';

export interface InputFieldProps extends TextInputProps {
  value: string;
  label?: string;
  placeholder?: string;
  state?: InputFieldState;
  iconName?: IconName;
  addOn?: string;
  country?: Country;
  pretag?: string;
  infoLabel?: string;
  infoLink?: string;
  handleSecureTextEntry?: boolean;
  initialEnabledSecureTextEntry?: boolean;
  size?: InputFieldSizeType;
  multiline?: boolean;
  onPhonePress?: () => void;
  onChangeText?: (text: string) => void;
  onInfoLinkPress?: () => void;
}

export interface InputFieldHandle {
  focus: () => void;
}

const InputField: ForwardRefRenderFunction<
  InputFieldHandle,
  InputFieldProps
> = (
  {
    value,
    label,
    placeholder = '',
    state = 'default',
    iconName,
    addOn,
    country,
    pretag,
    infoLabel,
    infoLink,
    handleSecureTextEntry = false,
    initialEnabledSecureTextEntry = handleSecureTextEntry,
    size = 'default',
    multiline = true,
    onPhonePress,
    onChangeText,
    onFocus,
    onBlur,
    onKeyPress,
    onInfoLinkPress,
    ...inputProps
  },
  ref,
) => {
  const theme = useTheme();
  const containerRef = useRef(null);
  const inputRef = useRef<RNTextInput>(null);

  const [active, setActive] = useState(false);
  const [activeAnimated] = useState(new Animated.Value(value === '' ? 0 : 1));
  const [secureTextEntry, setSecureTextEntry] = useState(
    initialEnabledSecureTextEntry,
  );
  const [isTwoLines, setTwoLines] = useState<boolean | undefined>(undefined);

  const hovered = useHover(containerRef);

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef?.current?.focus();
    },
  }));

  const getInnerState = (
    state: InputFieldState,
    hovered: boolean,
    active: boolean,
  ): InputFieldState => {
    if (state === 'error' || state === 'success') {
      return state;
    }
    return active ? 'active' : hovered ? 'hover' : state;
  };

  const innerState = getInnerState(state, hovered, active);

  const isFilled = active || value !== '';

  useEffect(() => {
    Animated.timing(activeAnimated, {
      toValue: isFilled ? 1 : 0,
      duration: 200,
      useNativeDriver: false,
    }).start();
  }, [active, value]);

  const stateColorMap: StateColorMap = {
    default: theme.color.base.c7,
    hover: theme.color.base.c7,
    focus: theme.color.base.c7,
    active: theme.color.brand_02,
    error: theme.color.error,
    success: theme.color.success,
  };
  const validationColor = stateColorMap[innerState];
  const showInfoIcon = innerState === 'error' || innerState === 'success';

  const top = activeAnimated.interpolate({
    inputRange: [0, 1],
    outputRange: [18, 15],
  });
  const fontSize = activeAnimated.interpolate({
    inputRange: [0, 1],
    outputRange: [18, 12],
  });
  const lineHeight = activeAnimated.interpolate({
    inputRange: [0, 1],
    outputRange: [LABEL_HEIGHT, LABEL_HEIGHT_FILLED],
  });
  const color = activeAnimated.interpolate({
    inputRange: [0, 1],
    outputRange: [theme.color.base.c7, validationColor],
  });

  const labelAnimatedStyle = {
    top,
    fontSize,
    lineHeight,
    color,
  };

  return (
    <Container>
      <FocusContainer
        ref={containerRef}
        state={innerState}
        onPress={() => inputRef?.current?.focus()}
      >
        <BoxContainer
          size={size}
          validationColor={validationColor}
          isTwoLines={isTwoLines}
        >
          {(() => {
            if (country) {
              const countryInfo = COUNTRIES[country];
              return (
                <PhoneContent
                  disabled={!onPhonePress}
                  onPress={() => onPhonePress && onPhonePress()}
                >
                  <FlagImage
                    testID={`flagImage${country}`}
                    source={flags[countryInfo.country]}
                  />
                  <PhoneSelection>
                    <Icon
                      name={'chevron-selector-vertical'}
                      size={16}
                      color={theme.color.base.c6}
                    />
                  </PhoneSelection>
                  <PhoneCode>{`+${countryInfo.code}`}</PhoneCode>
                </PhoneContent>
              );
            }
            if (addOn) {
              return (
                <TypeContent size={size}>
                  <AddOn>{addOn}</AddOn>
                </TypeContent>
              );
            }
            if (iconName) {
              return (
                <TypeContent size={size}>
                  <Icon
                    name={iconName}
                    size={size === 'default' ? 24 : 18}
                    color={theme.color.base.c9}
                  />
                </TypeContent>
              );
            }
            if (pretag) {
              return (
                <PreTagContent>
                  <PreTagText>{pretag}</PreTagText>
                </PreTagContent>
              );
            }
            return null;
          })()}
          <Content size={size}>
            {label && size === 'default' && (
              <AnimatedLabel style={labelAnimatedStyle}>{label}</AnimatedLabel>
            )}
            {isFilled && label && <LabelFlex />}
            <TextInput
              {...inputProps}
              as={RNTextInput}
              ref={inputRef}
              value={value}
              placeholder={active || !label ? placeholder : ''}
              placeholderTextColor={theme.color.base.c6}
              onChangeText={onChangeText}
              onFocus={(event) => {
                onFocus && onFocus(event);
                setActive(true);
              }}
              onBlur={(event) => {
                onBlur && onBlur(event);
                setActive(false);
              }}
              blurOnSubmit
              secureTextEntry={secureTextEntry}
              onKeyPress={onKeyPress}
              multiline={multiline}
              numberOfLines={2} //android
              onContentSizeChange={(event) => {
                setTwoLines(event.nativeEvent.contentSize.height > 44);
              }}
              isFilled={isFilled}
              isTwoLines={isTwoLines}
              scrollEnabled={isTwoLines}
            />
          </Content>
          {showInfoIcon &&
          !handleSecureTextEntry &&
          (innerState === 'error' || innerState === 'success') ? (
            <IconView>
              <Icon
                name={innerState === 'error' ? 'alert-circle' : 'check-circle'}
                color={validationColor}
                size={24}
              />
            </IconView>
          ) : null}
          {handleSecureTextEntry && (
            <IconView>
              <ButtonIcon
                name={secureTextEntry ? 'eye' : 'eye-off'}
                size={24}
                color={theme.color.base.c4}
                onPress={() => setSecureTextEntry(!secureTextEntry)}
              />
            </IconView>
          )}
        </BoxContainer>
      </FocusContainer>
      {!!infoLabel && (
        <InfoContent>
          <InfoLabel
            color={
              innerState === 'error' || innerState === 'success'
                ? validationColor
                : undefined
            }
          >
            {infoLabel}
          </InfoLabel>
          {!!infoLink && (
            <InfoLinkView onPress={onInfoLinkPress}>
              <InfoLink>{infoLink}</InfoLink>
            </InfoLinkView>
          )}
        </InfoContent>
      )}
    </Container>
  );
};
export default forwardRef(InputField);
