import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  ForwardRefRenderFunction,
} from 'react';
import { TextInput as RNTextInput, TextInputProps } from 'react-native';
import { useTheme } from 'styled-components/native';
import Icon, { IconName } from '~/components/Icon';
import IconStyled, { IconStyledName } from '~/components/IconStyled';
import {
  Container,
  BoxContainer,
  TypeContent,
  Content,
  TextInput,
  FocusContainer,
} from './style';

export interface SearchInputFieldProps extends TextInputProps {
  value: string;
  label?: string;
  iconName?: IconName;
  iconStyledName?: IconStyledName;
  iconSize?: number;
  width?: number;
  height?: number;
  onChangeText?: (text: string) => void;
}

export interface SearchInputFieldHandle {
  focus: () => void;
}

const SearchInputField: ForwardRefRenderFunction<
  SearchInputFieldHandle,
  SearchInputFieldProps
> = (
  {
    value,
    label,
    iconName,
    iconStyledName,
    iconSize = 16,
    height,
    width,
    onChangeText,
    ...inputProps
  },
  ref,
) => {
  const theme = useTheme();
  const containerRef = useRef(null);
  const inputRef = useRef<RNTextInput>(null);

  const [active, setActive] = useState(false);

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef?.current?.focus();
    },
  }));

  return (
    <Container>
      <FocusContainer ref={containerRef}>
        <BoxContainer
          width={width}
          height={height}
          onPress={() => inputRef?.current?.focus()}
        >
          {(() => {
            if (iconName) {
              return (
                <TypeContent>
                  <Icon
                    name={iconName}
                    size={iconSize}
                    color={theme.color.base.c9}
                  />
                </TypeContent>
              );
            }
            if (iconStyledName) {
              return (
                <TypeContent>
                  <IconStyled name={iconStyledName} size={iconSize} />
                </TypeContent>
              );
            }
            return null;
          })()}
          <Content>
            <TextInput
              {...inputProps}
              as={RNTextInput}
              ref={inputRef}
              value={value}
              placeholder={active ? '' : label}
              placeholderTextColor={theme.color.base.c6}
              onChangeText={onChangeText}
              onFocus={() => setActive(true)}
              onBlur={() => setActive(false)}
              blurOnSubmit
            />
          </Content>
        </BoxContainer>
      </FocusContainer>
    </Container>
  );
};
export default forwardRef(SearchInputField);
