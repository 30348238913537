import styled from 'styled-components/native';
import ImageComp from '~/components/Image';

export const Container = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})`
  background-color: ${({ theme }) => theme.color.base.c0};
  flex-direction: row;
  margin-bottom: 16px;
  padding: 16px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.color.base.c3};
  border-radius: 14px;
  box-shadow: -3px 3px 2px ${({ theme }) => theme.color.base.c1};
`;

export const Image = styled(ImageComp)`
  width: 114px;
  height: 78px;
  background-color: ${({ theme }) => theme.color.base.c2};
  border-radius: 5px;
  margin-right: 16px;
`;

export const Content = styled.View`
  flex: 1;
`;

export const Name = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c7};
  font-size: 16px;
  margin-bottom: 8px;
`;

export const Footer = styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

export const Info = styled.Text`
  flex: 1;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c5};
  font-size: 12px;
`;
