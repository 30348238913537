/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query AdminSearch
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { AdminSearch, AdminSearchVariables } from './types/AdminSearch';
import { ADMIN_SEARCH } from './';

export function useAdminSearchQuery(
  options?: QueryHookOptions<AdminSearch, AdminSearchVariables>,
): QueryResult<AdminSearch, AdminSearchVariables> {
  return useQuery<AdminSearch, AdminSearchVariables>(ADMIN_SEARCH, options);
}

export function useAdminSearchLazyQuery(
  options?: LazyQueryHookOptions<AdminSearch, AdminSearchVariables>,
): QueryTuple<AdminSearch, AdminSearchVariables> {
  return useLazyQuery<AdminSearch, AdminSearchVariables>(ADMIN_SEARCH, options);
}

export function createAdminSearchMock({
  variables,
  data,
  error,
}: {
  variables?: AdminSearchVariables;
  data?: AdminSearch;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: ADMIN_SEARCH,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
