/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation AcceptUserInTeam
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  AcceptUserInTeam,
  AcceptUserInTeamVariables,
} from './types/AcceptUserInTeam';
import { ACCEPT_USER_IN_TEAM } from './';

export function useAcceptUserInTeamMutation(
  options?: MutationHookOptions<AcceptUserInTeam, AcceptUserInTeamVariables>,
): MutationTuple<AcceptUserInTeam, AcceptUserInTeamVariables> {
  return useMutation<AcceptUserInTeam, AcceptUserInTeamVariables>(
    ACCEPT_USER_IN_TEAM,
    options,
  );
}

export function createAcceptUserInTeamMock({
  variables,
  data,
  error,
}: {
  variables?: AcceptUserInTeamVariables;
  data?: AcceptUserInTeam;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: ACCEPT_USER_IN_TEAM,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
