import React from 'react';
import { GroupUser, UserGroup } from '~/data/models/group';
import { Group } from '~/data/models/group';
import { Team } from '~/data/models/team';
import {
  Container,
  FlatList,
  AuthorTouchableView,
  AuthorUser,
  AuthorTeam,
  AuthorName,
  GroupAvatar,
} from './style';

type EventToolbarProps = {
  testID?: string;
  author?: string;
  userGroup?: UserGroup | null;
  teams: Team[];
  groups: GroupUser[];
  onAuthorChange: (authorId: string, authorType: string) => void;
};

export default function EventToolbar({
  testID,
  author,
  userGroup,
  teams,
  groups,
  onAuthorChange,
}: EventToolbarProps): JSX.Element {
  type TeamAndGroupFields = Team | GroupUser;
  const renderItem = ({ item }: { item: GroupUser | UserGroup | Team }) => {
    if (item.__typename === 'TeamNode') {
      const team = item as Team;
      const authorId = team.id;
      const selected = author === authorId;
      return (
        <AuthorTouchableView
          testID={`${testID}:AuthorTeam:${authorId}`}
          onPress={() => {
            onAuthorChange(authorId, 'TEAM');
          }}
        >
          <AuthorTeam
            teamColor={team?.color?.hex}
            iconName={team?.icon?.icon}
            selected={selected}
          />
          <AuthorName selected={selected}>{team.name}</AuthorName>
        </AuthorTouchableView>
      );
    } else if (
      item.__typename === 'UserGroupNode' &&
      (item as GroupUser).group
    ) {
      const itemGroup = item as GroupUser;
      const group = itemGroup.group;
      const authorId = group?.id;
      const selected = author === authorId;
      return (
        <AuthorTouchableView
          testID={`${testID}:AuthorGroup:${authorId}`}
          onPress={() => {
            onAuthorChange(authorId as string, 'GROUP');
          }}
        >
          <GroupAvatar
            source={{
              uri: group?.avatar as string,
            }}
            selected={selected}
          />
          <AuthorName selected={selected}>{group?.name}</AuthorName>
        </AuthorTouchableView>
      );
    } else {
      const userGroup = item as UserGroup;
      const userId = item.user.id;
      const selected = author === userId;
      return (
        <AuthorTouchableView
          testID={`${testID}:AuthorUser`}
          onPress={() => {
            onAuthorChange(userId as string, 'USER');
          }}
        >
          <AuthorUser userGroup={userGroup} selected={selected} />
          <AuthorName selected={selected}>
            {userGroup?.user?.firstName}
          </AuthorName>
        </AuthorTouchableView>
      );
    }
  };

  const teamsAndGroups: TeamAndGroupFields[] = (
    [] as TeamAndGroupFields[]
  ).concat(groups, teams);

  return (
    <Container>
      <FlatList<React.ElementType>
        testID={testID}
        horizontal
        data={userGroup ? [userGroup, ...teamsAndGroups] : teamsAndGroups}
        renderItem={renderItem}
        keyExtractor={(item: UserGroup | Team | Group, i: number) =>
          item?.id + i
        }
        showsHorizontalScrollIndicator={false}
        keyboardShouldPersistTaps="always"
      />
    </Container>
  );
}
