import { Image } from 'react-native';
import styled from 'styled-components/native';

export const Container = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 20px;
  color: ${({ theme }) => theme.color.base.c9};
  padding-bottom: 50px;
`;

export const UploadImageContainer = styled.View`
  flex-direction: row;
  margin-bottom: 50px;
`;

export const UploadImage = styled.TouchableOpacity`
  background-color: ${({ theme }) => theme.color.base.c2};
  border-radius: 5px;
  border: 2px solid ${({ theme }) => theme.color.brand_02};
  width: 320px;
  margin-bottom: 15px;
  align-items: center;
  justify-content: center;
`;
export const UploadBiggerImage = styled(UploadImage)`
  height: 180px;
`;

export const UploadSmallerImage = styled(UploadImage)`
  height: 90px;
`;

export const SmallerImageWrapper = styled.View`
  flex: 1;
  justify-content: end;
  align-items: end;
`;

export const SmallerImageTextAlignWrapper = styled.View`
  width: fit-content;
`;

export const ImageWrapper = styled.View`
  flex: 1;
`;

export const TextWrapper = styled.View`
  width: 100%;
`;

export const ImageSizeText = styled.Text`
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 10px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
`;

export const FormatText = styled.Text`
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 10px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
`;

export const InputContainer = styled.View``;

export const CampaignNameContainer = styled.View`
  flex-direction: row;
  margin-bottom: 50px;
  margin-top: 10px;
`;

export const CampaignNameLabel = styled.Text`
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 10px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
`;

export const CampaignName = styled.Text`
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 10px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
`;

export const BiggerImageContainer = styled(Image)`
  width: 320px;
  height: 180px;
`;

export const SmallerImageContainer = styled(Image)`
  width: 320px;
  height: 90px;
`;

export const DeleteIconWrapper = styled.View`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.color.base.c6};
  margin-top: -9px;
  align-items: center;
  margin-left: -15px;
  justify-content: center;
`;

export const ImageContainer = styled.View`
  flex-direction: row;
  margin-bottom: 15px;
`;

export const WarningNoHttps = styled.Text`
  color: ${({ theme }) => theme.color.error};
  size: 14px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
`;
