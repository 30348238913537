/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation UpdateQuotesSetup
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  UpdateQuotesSetup,
  UpdateQuotesSetupVariables,
} from './types/UpdateQuotesSetup';
import { UPDATE_QUOTES_SETUP } from './';

export function useUpdateQuotesSetupMutation(
  options?: MutationHookOptions<UpdateQuotesSetup, UpdateQuotesSetupVariables>,
): MutationTuple<UpdateQuotesSetup, UpdateQuotesSetupVariables> {
  return useMutation<UpdateQuotesSetup, UpdateQuotesSetupVariables>(
    UPDATE_QUOTES_SETUP,
    options,
  );
}

export function createUpdateQuotesSetupMock({
  variables,
  data,
  error,
}: {
  variables?: UpdateQuotesSetupVariables;
  data?: UpdateQuotesSetup;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: UPDATE_QUOTES_SETUP,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
