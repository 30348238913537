import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import {
  InstituteStackParamList,
  SearchStackParamList,
} from '~/navigation/types';
import EditInstitute from '~/screens/Admin/EditInstitute';
import InstituteAdd from '~/screens/Admin/InstituteAdd';
import InstituteRequest from '~/screens/Admin/InstituteRequest';
import Institutes from '~/screens/Admin/Institutes';

const InstitutesStackNavigator = createStackNavigator<
  InstituteStackParamList & SearchStackParamList
>();

export default function InstitutesStack(): JSX.Element {
  return (
    <InstitutesStackNavigator.Navigator
      initialRouteName={'Institutes'}
      screenOptions={{
        headerShown: false,
      }}
    >
      <InstitutesStackNavigator.Screen
        name={'Institutes'}
        component={Institutes}
      />
      <InstitutesStackNavigator.Screen
        name={'InstituteRequest'}
        component={InstituteRequest}
      />
      <InstitutesStackNavigator.Screen
        name={'InstituteAdd'}
        component={InstituteAdd}
      />
      <InstitutesStackNavigator.Screen
        name={'EditInstitute'}
        component={EditInstitute}
      />
    </InstitutesStackNavigator.Navigator>
  );
}
