import React from 'react';
import Button from '~/components/Button';
import { IconName } from '~/components/Icon';
import { Content, Text, IconCell, IconContent } from './style';

type IconBoxProps = {
  testID?: string;
  text: string;
  icon: IconName;
  buttonText: string;
  onPress: () => void;
};

export default function IconBox({
  testID,
  text,
  onPress,
  icon,
  buttonText,
}: IconBoxProps) {
  return (
    <Content testID={testID}>
      <IconContent>
        <IconCell name={icon} />
      </IconContent>
      <Text>{text}</Text>
      <Button
        text={buttonText}
        onPress={onPress}
        size="sm"
        type="secondary-contrast"
      />
    </Content>
  );
}
