/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query TeamDetail
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { TeamDetail, TeamDetailVariables } from './types/TeamDetail';
import { TEAM_DETAIL } from './';

export function useTeamDetailQuery(
  options?: QueryHookOptions<TeamDetail, TeamDetailVariables>,
): QueryResult<TeamDetail, TeamDetailVariables> {
  return useQuery<TeamDetail, TeamDetailVariables>(TEAM_DETAIL, options);
}

export function useTeamDetailLazyQuery(
  options?: LazyQueryHookOptions<TeamDetail, TeamDetailVariables>,
): QueryTuple<TeamDetail, TeamDetailVariables> {
  return useLazyQuery<TeamDetail, TeamDetailVariables>(TEAM_DETAIL, options);
}

export function createTeamDetailMock({
  variables,
  data,
  error,
}: {
  variables?: TeamDetailVariables;
  data?: TeamDetail;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: TEAM_DETAIL,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
