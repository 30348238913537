import { Dimensions } from 'react-native';
import styled from 'styled-components/native';
import { NEWS_ITEM_WIDTH } from '~/components/BannerCarousel/style';
import Image from '~/components/Image';
import TrackingFlatlist from '~/components/TrackingFlatList';
import { TabScreenContainer } from '~/screens/style';

const { width } = Dimensions.get('window');

export const CONTAINER_PADDING = 16;
export const CONTENT_CELL_WIDTH = (width - CONTAINER_PADDING * 3) / 2;

export const Container = styled(TabScreenContainer)``;

export const Header = styled.View``;

export const Section = styled.View`
  margin-top: 24px;
  margin-bottom: 16px;
  padding-horizontal: 16px;
`;

export const List = styled.FlatList``;

export const BannerList = styled(TrackingFlatlist)`
  padding-top: 16px;
  padding-left: 16px;
  padding-bottom: 8px;
`;

interface IAssetImage {
  heightRatio: number;
}
export const AssetImage = styled(Image)<IAssetImage>`
  width: ${NEWS_ITEM_WIDTH}px;
  height: ${({ heightRatio }) => heightRatio * NEWS_ITEM_WIDTH}px;
  border-radius: 5px;
`;
