import React, { useState } from 'react';
import {
  useNavigation,
  useRoute,
  RouteProp,
  CompositeNavigationProp,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { ReactNativeFile } from 'apollo-upload-client';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { useIntercom } from '~/context/intercom';
import { DEFAULT_LIST_OFFSET } from '~/data/constants';
import useTeamSamples from '~/data/hooks/useTeamSamples';
import { mapUser } from '~/data/mappers';
import {
  ProfilePageComment,
  ProfilePagePhotoCategory,
  ProfilePageQuestion,
} from '~/data/models/yearbook';
import { useUserGroupQuery } from '~/data/operations/group/userGroup';
import { useReportPostContentMutation } from '~/data/operations/post/reportPostContent';
import { useCreateProfilePagePhotoMutation } from '~/data/operations/yearbook/createProfilePagePhoto';
import { useDestroyProfilePageCommentMutation } from '~/data/operations/yearbook/destroyProfilePageComment';
import { useDestroyProfilePagePhotoMutation } from '~/data/operations/yearbook/destroyProfilePagePhoto';
import {
  readProfilePagePhotoCategoryFragment,
  writeProfilePagePhotoCategoryFragment,
} from '~/data/operations/yearbook/helpers';
import {
  readProfilePageCommentsQuery,
  writeProfilePageCommentsQuery,
} from '~/data/operations/yearbook/helpers';
import { useModuleInstanceQuery } from '~/data/operations/yearbook/moduleInstance';
import { useProfilePageCommentsQuery } from '~/data/operations/yearbook/profilePageComments';
import { useProfilePagePhotoCategoriesQuery } from '~/data/operations/yearbook/profilePagePhotoCategories';
import { useProfilePageQuestionsQuery } from '~/data/operations/yearbook/profilePageQuestions';
import { useUpdateProfilePagePhotoMutation } from '~/data/operations/yearbook/updateProfilePagePhoto';
import { RootStackParamList, YearbookStackParamList } from '~/navigation/types';
import { isDateExpired } from '~/utils/dates';
import { getFileName } from '~/utils/helpers';
import { t } from '~/utils/i18n';
import ProfilePageLayout from './layout';

type ProfilePageNavProp = CompositeNavigationProp<
  StackNavigationProp<YearbookStackParamList, 'ProfilePage'>,
  StackNavigationProp<RootStackParamList>
>;
type ProfilePageRouteProp = RouteProp<YearbookStackParamList, 'ProfilePage'>;

export default function ProfilePage(): JSX.Element {
  const navigation = useNavigation<ProfilePageNavProp>();
  const { onHelpCenter } = useIntercom();
  const {
    params: { moduleInstanceId, userGroupId: profileUserGroupId, viewOnly },
  } = useRoute<ProfilePageRouteProp>();

  const [loadingLocalUris, setLoadingLocalUris] = useState<string[]>([]);

  const { authGroupId, authUserGroupId, yearbookAdmin, authUserId } = useAuth();
  const { teamSamples } = useTeamSamples();
  const yearbookTeam = teamSamples.filter((t) => t.type === 'YEARBOOK');

  const { data: userGroupData } = useUserGroupQuery({
    skip: !profileUserGroupId,
    variables: {
      id: profileUserGroupId as string,
    },
  });

  const profileUserGroup = userGroupData?.userGroup;
  const mappedUser = mapUser(profileUserGroup?.user);

  const userGroupId = profileUserGroupId || authUserGroupId;

  const { data: moduleInstanceData } = useModuleInstanceQuery({
    variables: {
      id: moduleInstanceId,
    },
  });

  const isActive = moduleInstanceData?.moduleInstance?.isActive;
  const dueDate = moduleInstanceData?.moduleInstance?.dueDate;
  const isDeadlinePassed = dueDate && isDateExpired(dueDate);

  const profilePageSetupConfig =
    moduleInstanceData?.moduleInstance?.profilePageSetup;
  const anonymousComments = profilePageSetupConfig?.anonymousComments;
  const photoFormat = profilePageSetupConfig?.photoFormat || undefined;
  const maxChars = profilePageSetupConfig?.maxChars;

  const photoCategoriesVar = {
    after: null,
    first: DEFAULT_LIST_OFFSET,
    moduleInstance: moduleInstanceId,
    ppqUserGroup: userGroupId,
  };
  const {
    data: photoCategoriesData,
    loading: photoCategoriesLoading,
    refetch: refetchProfilePagePhotoCategories,
    fetchMore: fetchMoreProfilePagePhotoCategories,
  } = useProfilePagePhotoCategoriesQuery({
    skip: !userGroupId,
    variables: photoCategoriesVar,
    notifyOnNetworkStatusChange: true,
  });

  const photoCategories: ProfilePagePhotoCategory[] =
    (photoCategoriesData?.profilePagePhotoCategories?.edges.map(
      (edge) => edge?.node,
    ) as ProfilePagePhotoCategory[]) || [];
  const photoCategoriesHasNextPage =
    photoCategoriesData?.profilePagePhotoCategories?.pageInfo.hasNextPage;
  const photoCategoriesEndCursor =
    photoCategoriesData?.profilePagePhotoCategories?.pageInfo.endCursor;

  const questionsVar = {
    after: null,
    first: DEFAULT_LIST_OFFSET,
    moduleInstance: moduleInstanceId,
    ppqUserGroup: userGroupId,
  };
  const {
    data: questionsData,
    loading: questionsLoading,
    refetch: refetchProfilePageQuestions,
    fetchMore: fetchMoreProfilePageQuestions,
  } = useProfilePageQuestionsQuery({
    skip: !userGroupId,
    variables: questionsVar,
    notifyOnNetworkStatusChange: true,
  });

  const questions: ProfilePageQuestion[] =
    (questionsData?.profilePageQuestions?.edges.map(
      (edge) => edge?.node,
    ) as ProfilePageQuestion[]) || [];

  const questionsHasNextPage =
    questionsData?.profilePageQuestions?.pageInfo.hasNextPage;
  const questionsEndCursor =
    questionsData?.profilePageQuestions?.pageInfo.endCursor;

  const commentsVar = {
    after: null,
    first: DEFAULT_LIST_OFFSET,
    moduleInstance: moduleInstanceId,
    profileUserGroup: userGroupId,
  };
  const {
    data: commentsData,
    loading: commentsLoading,
    refetch: refetchProfilePageComments,
    fetchMore: fetchMoreProfilePageComments,
  } = useProfilePageCommentsQuery({
    skip: !userGroupId,
    variables: commentsVar,
    notifyOnNetworkStatusChange: true,
  });

  const comments: ProfilePageComment[] =
    (commentsData?.profilePageComments?.edges.map(
      (edge) => edge?.node,
    ) as ProfilePageComment[]) || [];

  const commentsHasNextPage =
    commentsData?.profilePageComments?.pageInfo.hasNextPage;
  const commentsEndCursor =
    commentsData?.profilePageComments?.pageInfo.endCursor;

  const [createProfilePagePhoto] = useCreateProfilePagePhotoMutation();
  const [updateProfilePagePhoto] = useUpdateProfilePagePhotoMutation();
  const [destroyProfilePagePhoto] = useDestroyProfilePagePhotoMutation();

  const [reportPostContent] = useReportPostContentMutation();
  const [destroyProfilePageComment] = useDestroyProfilePageCommentMutation();

  const onUploadImage = async (
    photoCategoryId: string,
    imgUri: string,
    photoId?: string,
  ) => {
    try {
      const imageFile = new ReactNativeFile({
        uri: imgUri,
        type: 'image/jpeg',
        name: getFileName(imgUri),
      });
      setLoadingLocalUris((uris) => [...uris, imgUri]);
      if (photoId) {
        await updateProfilePagePhoto({
          variables: {
            input: {
              id: photoId,
              image: imageFile,
            },
          },
        });
      } else {
        await createProfilePagePhoto({
          variables: {
            input: {
              profilePagePhotoCategory: photoCategoryId,
              image: imageFile,
            },
          },
          update(cache, { data }) {
            const newProfilePagePhoto =
              data?.createProfilePagePhoto?.profilePagePhoto;

            const currentPhotoCategory = readProfilePagePhotoCategoryFragment({
              cache,
              id: photoCategoryId,
              ppqUserGroup: userGroupId,
            });

            if (currentPhotoCategory && newProfilePagePhoto) {
              writeProfilePagePhotoCategoryFragment({
                cache,
                id: photoCategoryId,
                ppqUserGroup: userGroupId,
                data: {
                  ...currentPhotoCategory,
                  profilePagePhotos: {
                    __typename: 'ProfilePagePhotoNodeConnection',
                    edges: [
                      {
                        __typename: 'ProfilePagePhotoNodeEdge',
                        node: newProfilePagePhoto,
                      },
                    ],
                  },
                },
              });
            }
          },
        });
      }
      setLoadingLocalUris((uris) => uris.filter((uri) => uri !== imgUri));
    } catch (e) {
      setLoadingLocalUris((uris) => uris.filter((uri) => uri !== imgUri));
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onRemovePhoto = async (photoCategoryId: string, photoId: string) => {
    try {
      await destroyProfilePagePhoto({
        variables: {
          input: {
            id: photoId,
          },
        },
        update(cache) {
          const currentPhotoCategory = readProfilePagePhotoCategoryFragment({
            cache,
            id: photoCategoryId,
            ppqUserGroup: userGroupId,
          });

          if (currentPhotoCategory) {
            writeProfilePagePhotoCategoryFragment({
              cache,
              id: photoCategoryId,
              ppqUserGroup: userGroupId,
              data: {
                ...currentPhotoCategory,
                profilePagePhotos: {
                  __typename: 'ProfilePagePhotoNodeConnection',
                  edges: [],
                },
              },
            });
          }
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onReportComment = async (commentId: string) => {
    try {
      await reportPostContent({
        variables: {
          input: {
            id: commentId,
          },
        },
      });
      Snackbar.show(t('g.commentReported'));
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onDeleteComment = async (commentId: string) => {
    try {
      await destroyProfilePageComment({
        variables: {
          input: {
            id: commentId,
          },
        },
        update(cache) {
          const currentProfilePageComments = readProfilePageCommentsQuery({
            cache,
            variables: commentsVar,
          });

          if (currentProfilePageComments?.profilePageComments?.edges) {
            writeProfilePageCommentsQuery({
              cache,
              variables: commentsVar,
              data: {
                ...currentProfilePageComments,
                profilePageComments: {
                  ...currentProfilePageComments.profilePageComments,
                  edges:
                    currentProfilePageComments.profilePageComments.edges.filter(
                      (edge) => edge?.node?.id !== commentId,
                    ),
                },
              },
            });
          }
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const openBannersInBrowser = (url: string) => {
    navigation.navigate('Browser', {
      title: 'Banner',
      url,
    });
  };
  return (
    <ProfilePageLayout
      isActive={isActive}
      isDeadlinePassed={isDeadlinePassed}
      photoFormat={photoFormat}
      photoCategories={photoCategories}
      photoCategoriesLoading={photoCategoriesLoading}
      questions={questions}
      questionsLoading={questionsLoading}
      comments={comments}
      commentsLoading={commentsLoading}
      anonymousComments={anonymousComments}
      userName={mappedUser?.name}
      yearbookAdmin={yearbookAdmin}
      loadingLocalUris={loadingLocalUris}
      disableEdit={viewOnly}
      collectedDataView={viewOnly}
      openBannersInBrowser={openBannersInBrowser}
      onBack={() => navigation.goBack()}
      onSetup={() =>
        navigation.navigate('ProfilePageSetup', { moduleInstanceId })
      }
      onHelp={onHelpCenter}
      onRequestYearbookTeam={() => {
        navigation.popToTop();
        navigation.navigate('MainTab', {
          screen: 'PlanningStack',
          gid: authGroupId,
          params: {
            screen: 'TeamDetail',
            params: {
              teamId: yearbookTeam[0]?.id,
            },
          },
        });
      }}
      onCreateComment={() =>
        navigation.navigate('ProfilePageComments', { moduleInstanceId })
      }
      onUpdateComment={(
        profileUserGroupId,
        profilePageCommentId,
        currentText,
        viewOnly,
      ) =>
        navigation.navigate('ProfileCreateComment', {
          moduleInstanceId,
          profileUserGroupId,
          profilePageCommentId,
          viewOnly,
          currentText,
        })
      }
      onAnswerQuestion={(questionTitle, questionId, answerId, text, viewOnly) =>
        maxChars &&
        navigation.navigate('ProfilePageAnswer', {
          questionTitle,
          questionId,
          answerId,
          currentText: text,
          maxChars,
          viewOnly,
        })
      }
      onUploadImage={(photoCategoryId, imgUri, photoId) =>
        onUploadImage(photoCategoryId, imgUri, photoId)
      }
      onRemovePhoto={onRemovePhoto}
      onRefresh={() => {
        refetchProfilePagePhotoCategories();
        refetchProfilePageQuestions();
        refetchProfilePageComments();
      }}
      onReportComment={onReportComment}
      onDeleteComment={onDeleteComment}
      onLoadMoreCategories={() => {
        photoCategoriesHasNextPage &&
          fetchMoreProfilePagePhotoCategories({
            variables: {
              ...photoCategoriesVar,
              after: photoCategoriesEndCursor,
            },
          });
      }}
      onLoadMoreQuestions={() => {
        questionsHasNextPage &&
          fetchMoreProfilePageQuestions({
            variables: {
              ...questionsVar,
              after: questionsEndCursor,
            },
          });
      }}
      onLoadMoreComments={() => {
        commentsHasNextPage &&
          fetchMoreProfilePageComments({
            variables: {
              ...commentsVar,
              after: commentsEndCursor,
            },
          });
      }}
      authUserId={authUserId}
    />
  );
}
