import React, { useState } from 'react';
import { AppLocale } from '~/data/models/app';
import { t } from '~/utils/i18n';
import {
  Container,
  Title,
  ButtonContainer,
  Text,
  ArrowButtonIcon,
  PickerContainer,
  ButtonContent,
} from './style';

type LanguageInfo = {
  key: AppLocale;
  name: string;
};

type LanguageDropdownProps = {
  testID?: string;
  selectedLocale: AppLocale;
  initialShowPicker?: boolean;
  onSelectedLocale: (locale: AppLocale) => void;
  openToSide?: boolean;
};

export default function LanguageDropdown({
  testID,
  selectedLocale,
  initialShowPicker = false,
  openToSide = false,
  onSelectedLocale,
}: LanguageDropdownProps): JSX.Element {
  const [showPicker, setShowPicker] = useState<boolean>(initialShowPicker);
  const languageInfos: LanguageInfo[] = [
    {
      key: 'en',
      name: t('language.englishFlag'),
    },
    {
      key: 'de',
      name: t('language.germanFlag'),
    },
    {
      key: 'tr',
      name: t('language.turkishFlag'),
    },
  ];

  const selectedLocaleInfo = languageInfos.find(
    (languageInfo) => languageInfo.key === selectedLocale,
  );

  return (
    <Container testID={testID}>
      <Title>{t('g.switchLanguage')}</Title>
      <ButtonContainer onPress={() => setShowPicker(!showPicker)}>
        <Text>{selectedLocaleInfo?.name}</Text>
        <ArrowButtonIcon onPress={() => setShowPicker(!showPicker)} />
      </ButtonContainer>
      {showPicker ? (
        <PickerContainer openToSide={openToSide}>
          {languageInfos.map((languageInfo) => (
            <ButtonContent
              key={languageInfo.key}
              onPress={() => onSelectedLocale(languageInfo.key)}
            >
              <Text>{languageInfo.name}</Text>
            </ButtonContent>
          ))}
        </PickerContainer>
      ) : null}
    </Container>
  );
}
