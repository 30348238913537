import React from 'react';
import Button from '~/components/Button';
import { InstituteRequest } from '~/data/models/instituteRequest';
import { formattedRelativeDate } from '~/utils/dates';
import usePagination from '~/utils/hooks/usePagination';
import { t } from '~/utils/i18n';
import {
  Container,
  InstituteCardsSection,
  InstituteCard,
  HeaderSection,
  Title,
  InstituteName,
  RequestedAt,
  CardContent,
  CardButtons,
  InstituteRequestsNavigation,
  InstituteRequestsNavigationWrapper,
  NavigationDot,
} from './style';

type InstituteRequestsPanelProps = {
  instituteRequests: InstituteRequest[];
  onCheckInstituteRequestPress: (id: string) => void;
  onAddInstituteRequestPress: () => void;
};

export default function InstituteRequestsPanel({
  instituteRequests,
  onCheckInstituteRequestPress,
  onAddInstituteRequestPress,
}: InstituteRequestsPanelProps): JSX.Element | null {
  const numberOfRequests = instituteRequests.length;
  const hasPendingRequests = numberOfRequests > 0;

  const { totalNumberOfPages, currentPage, updatePage, applyPaginationOnData } =
    usePagination({
      itemsPerPage: 2,
      initialPage: 1,
      totalItems: numberOfRequests,
    });
  const paginatedInstituteRequests = applyPaginationOnData(instituteRequests);

  const instituteRequestPages = Array.from(
    { length: totalNumberOfPages },
    (_, index) => index + 1,
  );

  return (
    <Container>
      <HeaderSection>
        <Title>
          {t('adminPanelInstitutes.requestsPanelTitle', { numberOfRequests })}
        </Title>
        <Button
          testID={'buttonAddInstitute'}
          text={t('adminPanelInstitutes.addInstitute')}
          size={'sm'}
          type={'secondary-base'}
          icon={'left'}
          iconName={'plus'}
          onPress={onAddInstituteRequestPress}
        />
      </HeaderSection>
      {hasPendingRequests && (
        <>
          <InstituteCardsSection>
            {paginatedInstituteRequests.map((instituteRequest, index) => (
              <InstituteCard
                key={index}
                testID={`InstituteRequest:${instituteRequest.id}`}
              >
                <RequestedAt>
                  {t('adminPanelInstitutes.requestedAt', {
                    days: formattedRelativeDate(instituteRequest.created),
                  })}
                </RequestedAt>
                <InstituteName>
                  {t(`adminPanelSearch.flags.${instituteRequest.country}`)}
                  &nbsp;
                  {instituteRequest.name}
                </InstituteName>
                <CardContent>
                  {t('adminPanelInstitutes.requestedBy', {
                    requester: `${instituteRequest.user.firstName} ${instituteRequest.user.lastName}`,
                  })}
                </CardContent>
                <CardButtons>
                  <Button
                    text={t('adminPanelInstitutes.checkInstitute')}
                    state={'activeWeb'}
                    testID={'buttonCheckRequest'}
                    size={'sm'}
                    onPress={() =>
                      onCheckInstituteRequestPress(instituteRequest.id)
                    }
                  />
                </CardButtons>
              </InstituteCard>
            ))}
          </InstituteCardsSection>
          <InstituteRequestsNavigationWrapper>
            <InstituteRequestsNavigation>
              {totalNumberOfPages > 1 &&
                instituteRequestPages.map((page) => (
                  <NavigationDot
                    key={`navigation-dot-${page}`}
                    isActive={page === currentPage}
                    onPress={() => updatePage(page)}
                  />
                ))}
            </InstituteRequestsNavigation>
          </InstituteRequestsNavigationWrapper>
        </>
      )}
    </Container>
  );
}
