import React from 'react';
import LanguagePicker from '~/components/LanguagePicker';
import NavHeader from '~/components/NavHeader';
import { AppLocale } from '~/data/models/app';
import { t } from '~/utils/i18n';
import { Container, Content } from './style';

type AppSetupLayoutProps = {
  selectedLocale?: AppLocale;
  onBack: () => void;
  onUpdateLocale: (locale: AppLocale) => void;
};

export default function AppSetup({
  selectedLocale = 'auto',
  onBack,
  onUpdateLocale,
}: AppSetupLayoutProps): JSX.Element {
  return (
    <Container>
      <NavHeader
        backButtonTestID={'buttonBack'}
        title={t('screens.appSetUp')}
        onBackPress={onBack}
      />
      <Content>
        {selectedLocale ? (
          <LanguagePicker
            testID={'languagePicker'}
            label={t('g.appLocale')}
            selectedLocale={selectedLocale}
            onSelectedLocale={(language) => {
              onUpdateLocale(language);
            }}
          />
        ) : null}
      </Content>
    </Container>
  );
}
