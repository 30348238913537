/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation LeaveEvent
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { LeaveEvent, LeaveEventVariables } from './types/LeaveEvent';
import { LEAVE_EVENT } from './';

export function useLeaveEventMutation(
  options?: MutationHookOptions<LeaveEvent, LeaveEventVariables>,
): MutationTuple<LeaveEvent, LeaveEventVariables> {
  return useMutation<LeaveEvent, LeaveEventVariables>(LEAVE_EVENT, options);
}

export function createLeaveEventMock({
  variables,
  data,
  error,
}: {
  variables?: LeaveEventVariables;
  data?: LeaveEvent;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: LEAVE_EVENT,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
