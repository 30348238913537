import React from 'react';
import { ProfilePageSuggestedQuestion } from '~/data/models/yearbook';
import { t } from '~/utils/i18n';
import {
  PaddedContainer,
  Container,
  EmojiContainer,
  EmojiText,
  Content,
  QuestionText,
  QuestionType,
  AddButtonIcon,
} from './style';

type InspirationalQuestionCellProps = {
  profilePageQuestion: ProfilePageSuggestedQuestion;
  onAdd: () => void;
  onPress?: () => void;
};

//TODO setup emoji

export default function InspirationalQuestionCell({
  profilePageQuestion,
  onAdd,
  onPress,
}: InspirationalQuestionCellProps): JSX.Element {
  const type =
    profilePageQuestion.options != null
      ? t('g.optionList')
      : t('g.freeTextAnswer');
  return (
    <PaddedContainer
      testID={`InspirationalQuestionCell:${profilePageQuestion.id}`}
    >
      <Container onPress={() => onPress && onPress()}>
        <EmojiContainer>
          <EmojiText>{''}</EmojiText>
        </EmojiContainer>
        <Content>
          <QuestionText>{profilePageQuestion.text}</QuestionText>
          <QuestionType>{type}</QuestionType>
        </Content>
        <AddButtonIcon onPress={onAdd} />
      </Container>
    </PaddedContainer>
  );
}
