import React, { useState } from 'react';
import { t } from '~/utils/i18n';
import {
  Container,
  Title,
  Description,
  CancelButton,
  AcceptButton,
  RowContainer,
  CloseModalButton,
  HeaderContainer,
  ButtonWrapper,
} from './style';

type InstituteRequestNotificationProps = {
  title: string;
  description: string;
  cancelButtonText?: string;
  acceptButtonText?: string;
  width?: number;
  onAcceptPress?: () => void;
};

export default function InstituteRequestNotification({
  title,
  description,
  cancelButtonText = t('alert.cancel'),
  acceptButtonText = t('g.accept'),
  width,
  onAcceptPress,
}: InstituteRequestNotificationProps): JSX.Element {
  const [isVisible, setIsVisible] = useState(true);

  const onClose = () => {
    setIsVisible(false);
  };
  return (
    <Container width={width} isVisible={isVisible}>
      <HeaderContainer>
        <Title>{title}</Title>
        <CloseModalButton onPress={onClose} />
      </HeaderContainer>
      <Description>{description}</Description>
      <RowContainer>
        <ButtonWrapper onPress={onClose}>
          <CancelButton>{cancelButtonText}</CancelButton>
        </ButtonWrapper>
        <ButtonWrapper onPress={onAcceptPress}>
          <AcceptButton>{acceptButtonText}</AcceptButton>
        </ButtonWrapper>
      </RowContainer>
    </Container>
  );
}
