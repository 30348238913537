/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation CreateCustomList
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  CreateCustomList,
  CreateCustomListVariables,
} from './types/CreateCustomList';
import { CREATE_CUSTOM_LIST } from './';

export function useCreateCustomListMutation(
  options?: MutationHookOptions<CreateCustomList, CreateCustomListVariables>,
): MutationTuple<CreateCustomList, CreateCustomListVariables> {
  return useMutation<CreateCustomList, CreateCustomListVariables>(
    CREATE_CUSTOM_LIST,
    options,
  );
}

export function createCreateCustomListMock({
  variables,
  data,
  error,
}: {
  variables?: CreateCustomListVariables;
  data?: CreateCustomList;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: CREATE_CUSTOM_LIST,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
