import React from 'react';
import LottieView from 'lottie-react-native';
import { Platform } from 'react-native';
import styled from 'styled-components/native';
import lottie from '~/assets/lottie';

type LottieProps = {
  enabled?: boolean;
  autoPlay?: boolean;
  loop?: boolean;
};

const ConfettiView = styled(LottieView).attrs({
  source: lottie.confetti,
})`
  position: absolute;
  width: ${({ theme }) => theme.dimensions.screenWidth}px;
  height: ${({ theme }) => theme.dimensions.screenHeight}px;
`;

export function Confetti({
  enabled = true,
  autoPlay = true,
  loop = false,
}: LottieProps): JSX.Element | null {
  if (!enabled || Platform.OS === 'web') {
    return null;
  }
  return <ConfettiView autoPlay={autoPlay} loop={loop} />;
}
