import React, { ReactNode } from 'react';
import { theme } from 'gradoo-theme';
import { ViewStyle } from 'react-native';
import { Host } from 'react-native-portalize';
import styled, { ThemeProvider } from 'styled-components/native';

interface WrapperProps {
  children: ReactNode;
  noPadding?: boolean;
  style?: ViewStyle;
  disableDark?: boolean;
}
export function LightWrapper({ children }: WrapperProps): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <Host>{children}</Host>
    </ThemeProvider>
  );
}

interface IContainer {
  noPadding: boolean;
}
const Container = styled.View<IContainer>`
  background-color: ${({ theme }) => theme.color.base.c0};
  padding: ${({ noPadding }) => (noPadding ? '0px' : '10px')};
`;

interface ISpacer {
  size?: number;
}
export const Spacer = styled.View<ISpacer>`
  width: ${({ size }) => size || 8}px;
  height: ${({ size }) => size || 8}px;
`;

export function StorybookThemesWrapper({
  children,
  noPadding = false,
  style,
}: WrapperProps): JSX.Element {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Container {...style} noPadding={noPadding}>
          {children}
        </Container>
      </ThemeProvider>
    </>
  );
}

const ModalContainer = styled.View`
  flex: 1;
  padding-top: 48px;
  background-color: ${({ theme }) => theme.color.base.c7};
  justify-content: flex-end;
`;

export function ModalThemeWrapper({ children }: WrapperProps): JSX.Element {
  return (
    <ModalContainer>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ModalContainer>
  );
}
