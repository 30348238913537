import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { SearchStackParamList } from '~/navigation/types';
import EditGroup from '~/screens/Admin/EditGroup';
import EditInstitute from '~/screens/Admin/EditInstitute';
import EditUser from '~/screens/Admin/EditUser';
import Search from '~/screens/Admin/Search';

const SearchStackNavigator = createStackNavigator<SearchStackParamList>();

export default function SearchStack(): JSX.Element {
  return (
    <SearchStackNavigator.Navigator
      initialRouteName={'Search'}
      screenOptions={{
        headerShown: false,
      }}
    >
      <SearchStackNavigator.Screen name={'Search'} component={Search} />
      <SearchStackNavigator.Screen name={'EditUser'} component={EditUser} />
      <SearchStackNavigator.Screen name={'EditGroup'} component={EditGroup} />
      <SearchStackNavigator.Screen
        name={'EditInstitute'}
        component={EditInstitute}
      />
    </SearchStackNavigator.Navigator>
  );
}
