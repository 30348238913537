/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation ActivateTeam
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { ActivateTeam, ActivateTeamVariables } from './types/ActivateTeam';
import { ACTIVATE_TEAM } from './';

export function useActivateTeamMutation(
  options?: MutationHookOptions<ActivateTeam, ActivateTeamVariables>,
): MutationTuple<ActivateTeam, ActivateTeamVariables> {
  return useMutation<ActivateTeam, ActivateTeamVariables>(
    ACTIVATE_TEAM,
    options,
  );
}

export function createActivateTeamMock({
  variables,
  data,
  error,
}: {
  variables?: ActivateTeamVariables;
  data?: ActivateTeam;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: ACTIVATE_TEAM,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
