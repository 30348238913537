import styled from 'styled-components/native';

export const GroupSecionList = styled.SectionList`
  padding-horizontal: ${({ theme }) => theme.spacing.medium};
`;

export const SectionView = styled.View`
  padding-vertical: 16px;
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const SectionTitle = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 16px;
  line-height: 22px;
`;

export const Footer = styled.View`
  padding-vertical: 16px;
  align-items: center;
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c9,
}))`
  padding-vertical: ${({ theme }) => theme.spacing.medium};
`;

export const EmptyText = styled.Text`
  margin-vertical: ${({ theme }) => theme.spacing.medium};
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 14px;
`;
