import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  ForwardRefRenderFunction,
} from 'react';
import { Animated } from 'react-native';
import Avatar from '~/components/Avatar';
import CheckBox from '~/components/CheckBox';
import { mapUser } from '~/data/mappers';
import { ToDoItem } from '~/data/models/todo';
import { formattedDate } from '~/utils/dates';
import { t } from '~/utils/i18n';
import {
  AnimatedSwipeableContainer,
  DeleteButton,
  DeleteIcon,
  EditButton,
  EditIcon,
  ClockIcon,
  Container,
  DueDate,
  Footer,
  Title,
  TitleWrapper,
  CELL_CONTAINER_HEIGHT,
  SWIPEABLE_BUTTON_WIDTH,
} from './style';

export type ToDoItemProps = {
  testID?: string;
  item: ToDoItem;
  onChecked: (checked: boolean) => void;
  onPress: () => void;
  onDelete: () => void;
  onEdit: () => void;
};

export type ToDoItemHandle = {
  collapse: () => void;
};

const ToDoItemCell: ForwardRefRenderFunction<ToDoItemHandle, ToDoItemProps> = (
  { testID, item, onChecked, onPress, onDelete, onEdit },
  ref,
) => {
  const [animated] = useState(new Animated.Value(0));

  const deleteAnimation = {
    height: animated.interpolate({
      inputRange: [0, 1],
      outputRange: [CELL_CONTAINER_HEIGHT, 0],
      extrapolate: 'clamp',
    }),
  };

  function collapse() {
    Animated.timing(animated, {
      toValue: 1,
      duration: 250,
      useNativeDriver: false,
    }).start();
  }

  useImperativeHandle(ref, () => ({
    collapse: () => collapse(),
  }));

  return (
    <AnimatedSwipeableContainer
      style={deleteAnimation}
      rightButtonWidth={SWIPEABLE_BUTTON_WIDTH}
      rightButtons={[
        <DeleteButton key={'deleteButton'} onPress={onDelete}>
          <DeleteIcon />
        </DeleteButton>,
        <EditButton key={'editButton'} onPress={onEdit}>
          <EditIcon />
        </EditButton>,
      ]}
    >
      <Container testID={testID} onPress={onPress}>
        <TitleWrapper>
          <CheckBox
            icon={'check'}
            checked={item.finished}
            onChange={onChecked}
          />
          <Title>{item.title}</Title>
        </TitleWrapper>
        <Footer>
          <ClockIcon />
          <DueDate>
            {t('toDoList.dueDate', {
              dueDate: formattedDate(item.creationDate),
            })}
          </DueDate>
          {/* // TODO add the icon, which will be done once we have it from the User */}
          <Avatar size={22} initials={mapUser(item.creator)?.initials} />
        </Footer>
      </Container>
    </AnimatedSwipeableContainer>
  );
};

export default forwardRef(ToDoItemCell);
