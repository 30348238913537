import styled from 'styled-components/native';
import ButtonIcon from '~/components/ButtonIcon';

export const Container = styled.View`
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const Number = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c10};
  font-size: 16px;
  line-height: 24px;
`;

export const ButtonView = styled.View``;

interface IButtonIcon {
  enabled: boolean;
}
export const InputButtonIcon = styled(ButtonIcon).attrs<IButtonIcon>(
  ({ enabled, theme }) => ({
    size: 16,
    color: enabled ? theme.color.brand_02 : theme.color.base.c3,
  }),
)<IButtonIcon>`
  padding-vertical: 4px;
`;
