import React from 'react';
import { StyleSheet } from 'react-native';
import SelectDropdown from 'react-native-select-dropdown';
import { DefaultTheme } from 'styled-components/native';
import { useTheme } from 'styled-components/native';
import IconStyled, { IconStyledName } from '~/components/IconStyled';
import {
  ArrowIconBox,
  ArrowIcon,
  Label,
  SelectedRow,
  OptionRow,
  SelectorContainer,
  SelectorTextContainer,
  Container,
} from './style';

export type SelectorItem = {
  id: string;
  name: string;
};

export type WebSelectorProps = {
  testID: string;
  iconName: IconStyledName;
  label: string;
  items: SelectorItem[];
  height?: number;
  isEmptyText: string;
  defaultValue?: string;
  onItemSelect: (item: SelectorItem) => void;
  onSearch?: (query: string) => void;
};

export default function WebSelector({
  label,
  items,
  iconName,
  height = 50,
  isEmptyText,
  onItemSelect,
  onSearch,
  testID,
  defaultValue,
}: WebSelectorProps): JSX.Element {
  const theme = useTheme();
  const dropdownStyles = styles(theme, height);

  const renderRow = (item: SelectorItem) => (
    <OptionRow testID={`WebSelectorItem::${item.id}`}>{item.name}</OptionRow>
  );

  const renderIcon = () => (
    <ArrowIconBox>
      <ArrowIcon name={'chevron-up'} />
      <ArrowIcon name={'chevron-down'} />
    </ArrowIconBox>
  );

  const renderButtonChild = (selectedItem: SelectorItem) => {
    return (
      <SelectorContainer>
        <IconStyled name={iconName} size={20} color={theme.color.base.c4} />
        <SelectorTextContainer>
          <Label>{label}</Label>
          <SelectedRow>
            {selectedItem ? selectedItem.name : isEmptyText}
          </SelectedRow>
        </SelectorTextContainer>
      </SelectorContainer>
    );
  };

  const shouldEnableSearch = onSearch !== undefined;

  return (
    <Container testID={testID}>
      <SelectDropdown
        data={items}
        onSelect={(selectedItem) => onItemSelect(selectedItem)}
        defaultButtonText={label}
        buttonTextStyle={dropdownStyles.positionContainerText}
        buttonStyle={dropdownStyles.containerStyle}
        dropdownStyle={dropdownStyles.dropDownStyle}
        rowStyle={dropdownStyles.dropDownRowStyle}
        renderCustomizedRowChild={renderRow}
        onChangeSearchInputText={(value: string) => {
          if (shouldEnableSearch) {
            onSearch(value);
          }
        }}
        searchPlaceHolder={isEmptyText}
        renderDropdownIcon={renderIcon}
        renderCustomizedButtonChild={renderButtonChild}
        {...(shouldEnableSearch ? { search: true } : {})}
        defaultValue={items.find((item) => defaultValue === item.id)}
      />
    </Container>
  );
}

const styles = (theme: DefaultTheme, height: number) =>
  StyleSheet.create({
    containerStyle: {
      width: '100%',
      height: height,
      backgroundColor: theme.color.base.c0,
      borderRadius: 8,
      borderWidth: 1,
      borderColor: theme.color.brand_02,
    },
    scrollViewContainer: {
      flexGrow: 1,
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingVertical: '10%',
      paddingBottom: '20%',
    },
    positionContainerText: { textAlign: 'left' },
    dropDownStyle: {
      borderRadius: 8,
      height: 'auto',
    },
    dropDownRowStyle: {
      borderWidth: 0,
      paddingBottom: 10,
      paddingTop: 10,
    },
  });
