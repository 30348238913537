import React from 'react';
import Button from '~/components/Button';
import NavHeader from '~/components/NavHeader';
import SectionHeader from '~/components/SectionHeader';
import { Module } from '~/data/models/yearbook';
import { t } from '~/utils/i18n';
import { Container, ScrollView, Image, Text, ButtonContent } from './style';

type ModuleDetailLayoutProps = {
  module?: Module | null;
  viewOnly?: boolean;
  loading: boolean;
  onBack: () => void;
  onAddModule: () => void;
};

export default function ModuleDetail({
  module,
  viewOnly = false,
  loading,
  onBack,
  onAddModule,
}: ModuleDetailLayoutProps): JSX.Element {
  return (
    <Container>
      <NavHeader
        backButtonTestID={'buttonBack'}
        title={module?.name || ''}
        onBackPress={onBack}
      />
      <ScrollView>
        <Image source={{ uri: module?.image || undefined }} />
        <SectionHeader title={t('g.aboutThisModule')} sub />
        <Text>{module?.description || ''}</Text>
        <SectionHeader title={t('g.features')} sub />
        <Text>{module?.features?.join('\n')}</Text>
        {!viewOnly && (
          <ButtonContent>
            <Button
              testID={'buttonAddModule'}
              text={t('g.addModuleToYearbook')}
              loading={loading}
              flex
              onPress={onAddModule}
            />
          </ButtonContent>
        )}
      </ScrollView>
    </Container>
  );
}
