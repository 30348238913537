import styled, { css } from 'styled-components/native';
import ImageComp from '~/components/Image';

export const ScrollView = styled.ScrollView`
  flex: 1;
`;

export const Container = styled.View`
  flex: 1;
  position: relative;
`;

export const Content = styled.View`
  padding-horizontal: ${({ theme }) => theme.spacing.medium};
  padding-bottom: ${({ theme }) => theme.spacing.medium};
`;

export const CenterContent = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const ColorBox = styled.View`
  position: absolute;
  background-color: ${({ theme }) => theme.color.brand_01};
  z-index: -1;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 200px;
`;

export const Title = styled.Text<{ align?: 'left' | 'center' | 'right' }>`
  text-align: ${({ align }) => (align ? align : 'left')};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 32px;
`;

export const Descp = styled.Text<{ align?: 'left' }>`
  margin-top: 8px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c7};
  font-size: 18px;
`;

export const GroupDetailText = styled.Text``;

export const ImageWrapper = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`;

export const Image = styled(ImageComp)<{ noMargin?: boolean }>`
  ${({ noMargin }) =>
    !noMargin &&
    css`
      margin-vertical: 36px;
    `}
  width: 160px;
  height: 160px;
  border-radius: 5px;
  transform: rotate(10deg);
  margin-left: 22px;
  border-width: 5px;
  border-color: ${({ theme }) => theme.color.base.c2};
`;

export const SmallImage = styled(ImageComp)`
  width: 92px;
  height: 74px;
  margin-left: -18px;
  margin-top: 20px;
  transform: rotate(-10deg);
`;

export const SectionTitle = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c10};
  font-size: 18px;
  line-height: 23px;
`;

export const SectionValue = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c10};
  font-size: 18px;
  line-height: 23px;
`;

export const MembersAvatarRow = styled.View`
  margin-top: 16px;
  flex-direction: row;
`;

export const AvatarContent = styled.View<{ orderNo?: number }>`
  margin-left: ${({ orderNo }) => (orderNo === 0 ? 0 : -12)}px;
`;

export const AvatarMore = styled.View`
  width: 59px;
  height: 59px;
  border-radius: 59px;
  background-color: ${({ theme }) => theme.color.base.c3};
  align-items: center;
  justify-content: center;
`;

export const AvatarMoreText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c0};
  font-size: 20px;
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c9,
}))``;

export const ErrorText = styled.Text`
  margin-bottom: 16px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c10};
  font-size: 16px;
`;

export const Footer = styled.View`
  margin-top: 78px;
  padding-horizontal: ${({ theme }) => theme.spacing.medium};
  padding-bottom: ${({ theme }) => theme.spacing.medium};
`;

export const CenteredContainer = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  width: 600px;
  margin: 0 auto;
`;

export const VSpacer = styled.View<{ space: number }>`
  height: ${({ space }) => space}px;
`;

export const RowSpaceBetween = styled.View`
  flex-direction: row;
  gap: 40px;
  margin-top: 47px;
`;

export const WebTitleSection = styled.View`
  gap: 8px;
  margin-bottom: 32px;
`;

export const WebImageSection = styled.View`
  margin-top: 48px;
  margin-bottom: 32px;
`;

export const GroupStatsSection = styled.View`
  flex-direction: row;
  margin-bottom: 16px;
`;

export const GroupStats = styled.View`
  flex: 1;
  gap: 2px;
`;

export const GroupStatsBaseText = styled.Text`
  color: ${({ theme }) => theme.color.base.c10};
  font-size: 18px;
  line-height: 130%;
  letter-spacing: -0.24px;
  font-style: normal;
`;

export const GroupStatsTitle = styled(GroupStatsBaseText)`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
`;

export const GroupStatsValue = styled(GroupStatsBaseText)`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
`;

export const WebAvatarsSection = styled.View`
  margin-bottom: 48px;
`;
