import React, { useEffect } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Platform } from 'react-native';
import { RootStackParamList } from '~/navigation/types';
import BrowserLayout from './layout';

type BrowserNavProp = StackNavigationProp<RootStackParamList, 'Browser'>;

type BrowserRouteProp = RouteProp<RootStackParamList, 'Browser'>;

export default function Browser(): JSX.Element {
  const navigation = useNavigation<BrowserNavProp>();
  const {
    params: { url, title, onGoBack },
  } = useRoute<BrowserRouteProp>();

  useEffect(() => {
    if (Platform.OS === 'web' && window) {
      window.open(url, '_blank');
      onGoBack && onGoBack();
      navigation.goBack();
    }
  }, []);

  return (
    <BrowserLayout
      url={url}
      title={title}
      onBack={() => {
        onGoBack && onGoBack();
        navigation.goBack();
      }}
    />
  );
}
