import React, { useState } from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { general } from '~/assets/imgs';
import Shimmer from '~/components/Shimmer';
import SwitchSelector, { SwitchItem } from '~/components/SwitchSelector';
import { AdsImage, AdsText } from '~/components/common/style';
import { BestMatchedAsset } from '~/data/models/campaign';
import { RankingQuestion } from '~/data/models/yearbook';
import {
  AllowedVoteType,
  UsersUserGenderChoices,
  VariantTypeEnum,
} from '~/data/operations/global';
import useAdsTracking from '~/utils/hooks/useAdsTracking';
import { t } from '~/utils/i18n';
import ModuleLayout from '../../layout/ModuleLayout';
import RankingQuestionResultsCell from './RankingQuestionResultsCell';
import RankingQuestionSelectedCell from './RankingQuestionSelectedCell';
import {
  Header,
  QuestionTitle,
  QuestionWrapper,
  RankingsList,
  Content,
  Loading,
  RefreshControl,
  FallbackScrollView,
  FallbackImage,
  FallbackText,
} from './style';

type RankingTab = 'vote' | 'results';

export type RankingsLayoutProps = {
  authUserGender?: UsersUserGenderChoices | null;
  rankingQuestions: RankingQuestion[];
  initialTabKey?: RankingTab;
  yearbookAdmin?: boolean;
  showResults?: boolean;
  isActive?: boolean;
  viewOnly?: boolean;
  loading: boolean;
  hasNextPage?: boolean;
  isDeadlinePassed?: boolean | null;
  authUserId: string;
  onBack: () => void;
  onSetup: () => void;
  onHelp: () => void;
  onRequestYearbookTeam: () => void;
  onSelectQuestion: (questionId: string) => void;
  onRefresh: () => void;
  onLoadMore: () => void;
  openBannersInBrowser: (url: string) => void;
};

export default function Rankings({
  authUserGender,
  rankingQuestions,
  initialTabKey = 'vote',
  yearbookAdmin,
  showResults,
  isActive,
  viewOnly,
  loading,
  hasNextPage,
  isDeadlinePassed,
  authUserId,
  onBack,
  onSetup,
  onHelp,
  onRequestYearbookTeam,
  onSelectQuestion,
  onRefresh,
  onLoadMore,
  openBannersInBrowser,
}: RankingsLayoutProps): JSX.Element {
  const { handleViewTracking, handleAdsClick, bestMatchedAsset, randomIndex } =
    useAdsTracking({ authUserId, variantType: VariantTypeEnum.SMALL });

  const rankingScreens: { [key in RankingTab]: SwitchItem } = {
    vote: {
      key: 'vote',
      title: t('rankings.vote'),
    },
    results: {
      key: 'results',
      title: t('rankings.results'),
    },
  };

  const tabs: SwitchItem[] = [rankingScreens.vote, rankingScreens.results];

  const [currentTabKey, setCurrentTabKey] = useState<RankingTab>(
    viewOnly ? 'results' : initialTabKey,
  );

  const renderItem = ({
    item,
    index,
  }: {
    item?: RankingQuestion | BestMatchedAsset;
    index: number;
  }) => {
    if (item?.__typename === 'MatchedAssetNode') {
      const post = item as BestMatchedAsset;
      return (
        <TouchableOpacity
          testID={`${index}:AdsCell:${post?.id}`}
          onPress={() => {
            handleAdsClick(
              post?.id as string,
              post?.channelData?.[0]?.trackingUrl as string,
              openBannersInBrowser,
            );
          }}
        >
          <AdsText>{t('asset.gratitude')}</AdsText>
          <AdsImage
            source={{ uri: post?.channelData?.[0]?.imageSmall as string }}
          />
        </TouchableOpacity>
      );
    }
    const question = item as RankingQuestion;
    const isResultsView = currentTabKey === rankingScreens.results.key;
    const resultOptions = question?.results?.options || [];
    const rankingAnswer = question?.rankingsAnswers?.edges?.[0]?.node;

    if (loading) {
      return (
        <QuestionWrapper>
          <QuestionTitle>
            <Shimmer w={240} g={'g3'} />
          </QuestionTitle>
          {isResultsView && (
            <RankingQuestionResultsCell rankingQuestionResults={undefined} />
          )}
          {!isResultsView && (
            <RankingQuestionSelectedCell
              placeholder={true}
              rankingAnswer={undefined}
              onPress={() => undefined}
            />
          )}
        </QuestionWrapper>
      );
    }

    if (question) {
      return (
        <QuestionWrapper>
          <QuestionTitle>{question.question}</QuestionTitle>
          {isResultsView && question.results && (
            <RankingQuestionResultsCell
              rankingQuestionResults={{
                ...question.results,
                options: resultOptions,
              }}
            />
          )}
          {!isResultsView && (
            <RankingQuestionSelectedCell
              rankingAnswer={rankingAnswer}
              onPress={() => onSelectQuestion(question.id)}
            />
          )}
        </QuestionWrapper>
      );
    }

    return null;
  };

  const voteeRankingQuestions: RankingQuestion[] = rankingQuestions.filter(
    (rankingQuestion) => {
      const isSameGender =
        (rankingQuestion.allowedVotes === AllowedVoteType.MALE &&
          authUserGender == UsersUserGenderChoices.MALE) ||
        (rankingQuestion.allowedVotes === AllowedVoteType.FEMALE &&
          authUserGender == UsersUserGenderChoices.FEMALE);
      const hide = rankingQuestion.onlyOppositeGender && isSameGender;
      return !hide;
    },
  );

  const tabRankingQuestions =
    currentTabKey === 'vote' ? voteeRankingQuestions : rankingQuestions;

  const data =
    loading && tabRankingQuestions.length == 0
      ? Array(3).fill(undefined)
      : tabRankingQuestions;

  const combinedData = [...data, bestMatchedAsset?.[randomIndex]];
  return (
    <ModuleLayout
      title={t('screens.rankings')}
      yearbookAdmin={yearbookAdmin}
      viewOnly={viewOnly}
      onBack={onBack}
      onSetup={onSetup}
      isActive={isActive}
      isDeadlinePassed={isDeadlinePassed}
      onHelp={onHelp}
      onRequestYearbookTeam={onRequestYearbookTeam}
    >
      <>
        <Header>
          {!viewOnly ? (
            <SwitchSelector
              testID={'tabSelector'}
              items={tabs}
              selectedItemKey={currentTabKey}
              onSelectedItem={(tabKey) =>
                setCurrentTabKey(tabKey as RankingTab)
              }
            />
          ) : null}
        </Header>
        {currentTabKey === 'results' && !showResults ? (
          <FallbackScrollView>
            <FallbackImage
              source={general.ranking_fallback}
              resizeMode={'contain'}
            />
            <FallbackText>{t('rankings.resultsHidden')}</FallbackText>
          </FallbackScrollView>
        ) : (
          <RankingsList<React.ElementType>
            testID={'rankingsList'}
            refreshControl={
              <RefreshControl refreshing={loading} onRefresh={onRefresh} />
            }
            data={combinedData}
            renderItem={renderItem}
            keyExtractor={(item: RankingQuestion, index: number) =>
              item?.id || index
            }
            ListFooterComponent={
              loading && (
                <Content>
                  <Loading />
                </Content>
              )
            }
            onEndReachedThreshold={0.3}
            onEndReached={() => hasNextPage && onLoadMore()}
            handleViewTracking={handleViewTracking}
          />
        )}
      </>
    </ModuleLayout>
  );
}
