import styled from 'styled-components/native';
import { ResponsiveProps } from '~/components/common/style.web';
import { MODAL_MAX_WIDTH, MODAL_SIDE_PADDING } from '../WebModal/style';

const CONTAINER_WIDTH = MODAL_MAX_WIDTH - 2 * MODAL_SIDE_PADDING;

export const Container = styled.View<ResponsiveProps>`
  background-color: ${({ theme }) => theme.color.base.c0};
  width: ${({ isDesktop }) => (isDesktop ? `${CONTAINER_WIDTH}px` : '100%')};
`;

export const ModalTitle = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 28px;
  text-align: left;
  padding-left: 5px;
  padding-bottom: 15px;
`;

export const ModalSelectorBox = styled.View`
  padding-left: 5px;
  padding-bottom: 30px;
`;

export const ModalSelectorSubTitle = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 18px;
  text-align: left;
  padding: 15px 0;
`;
