/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation ReportPostContent
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  ReportPostContent,
  ReportPostContentVariables,
} from './types/ReportPostContent';
import { REPORT_POST_CONTENT } from './';

export function useReportPostContentMutation(
  options?: MutationHookOptions<ReportPostContent, ReportPostContentVariables>,
): MutationTuple<ReportPostContent, ReportPostContentVariables> {
  return useMutation<ReportPostContent, ReportPostContentVariables>(
    REPORT_POST_CONTENT,
    options,
  );
}

export function createReportPostContentMock({
  variables,
  data,
  error,
}: {
  variables?: ReportPostContentVariables;
  data?: ReportPostContent;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: REPORT_POST_CONTENT,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
