import React from 'react';
import Button from '../Button';
import { Container, Number } from './style';

type NumberInputProps = {
  testID?: string;
  number: number;
  step?: number;
  min?: number;
  max?: number;
  onChangeNumber: (number: number) => void;
};

export default function NumberInput({
  testID,
  number,
  step = 1,
  min,
  max,
  onChangeNumber,
}: NumberInputProps): JSX.Element {
  const addEnabled = max === undefined ? true : number < max;
  const subtractEnabled = min === undefined ? true : number > min;
  return (
    <Container testID={testID}>
      <Button
        type="primary-brand-02"
        size="lg"
        icon="only"
        iconName="minus"
        state={subtractEnabled ? 'default' : 'disabled'}
        onPress={() => subtractEnabled && onChangeNumber(number - step)}
      />
      <Number>{number}</Number>
      <Button
        type="primary-brand-02"
        size="lg"
        icon="only"
        iconName="plus"
        state={addEnabled ? 'default' : 'disabled'}
        onPress={() => addEnabled && onChangeNumber(number + step)}
      />
    </Container>
  );
}
