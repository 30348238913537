import React from 'react';
import { Institute } from '~/data/models/institute';
import {
  PressableContainer,
  LNContainer,
  Container,
  Name,
  Row,
  Address,
  City,
  TextContainer,
  ArrowIcon,
} from './style';

type InstituteCellProps = {
  testID?: string;
  institute: Institute;
  checked?: boolean;
  onPress: () => void;
};

export default function InstituteCell({
  testID,
  institute,
  checked,
  onPress,
}: InstituteCellProps): JSX.Element {
  return (
    <PressableContainer testID={testID} onPress={onPress}>
      {({ pressed: active }) => (
        <LNContainer checked={checked || active}>
          <Container checked={active}>
            <Row>
              <TextContainer>
                <Name>{institute.name}</Name>
                <Address>{institute.street}</Address>
                <City>{institute.city}</City>
              </TextContainer>
              <ArrowIcon name={'chevron-right'} checked={active} />
            </Row>
          </Container>
        </LNContainer>
      )}
    </PressableContainer>
  );
}
