import React from 'react';
import { IconName } from '~/components/Icon';
import { Content, Text, IconCell, IconContent } from './style';

type IconBoxProps = {
  testID?: string;
  text: string;
  icon: IconName;
  onPress?: () => void;
};

export const IconBox = ({ testID, text, onPress, icon }: IconBoxProps) => {
  return (
    <Content onPress={onPress} testID={testID}>
      <IconContent>
        <IconCell name={icon} />
      </IconContent>
      <Text>{text}</Text>
    </Content>
  );
};
