import React, { useState } from 'react';
import MemberCell from '~/components/MemberCell';
import NavHeader from '~/components/NavHeader';
import SwitchSelector from '~/components/SwitchSelector';
import { UserGroup } from '~/data/models/group';
import { ProfilePageComment } from '~/data/models/yearbook';
import { t } from '~/utils/i18n';
import {
  Container,
  Header,
  UserList,
  RefreshControl,
  Footer,
  Loading,
} from './style';

type TabSwitchItem = 'commented' | 'noComment';

export type ProfilePageCommentsLayoutProps = {
  writtenProfilePageComments: ProfilePageComment[];
  notCommentUserGroups: UserGroup[];
  loading: boolean;
  onBack: () => void;
  onUpdateComment: (
    userGroupId: string,
    profilePageCommentId: string,
    currentText: string,
  ) => void;
  onCreateComment: (userGroupId: string) => void;
  onLoadMoreComments: () => void;
  onLoadMoreUsers: () => void;
  onRefresh: () => void;
};

export default function ProfilePageComments({
  writtenProfilePageComments,
  notCommentUserGroups,
  loading,
  onBack,
  onUpdateComment,
  onCreateComment,
  onLoadMoreComments,
  onLoadMoreUsers,
  onRefresh,
}: ProfilePageCommentsLayoutProps): JSX.Element {
  const [selectedItemKey, setSelectedItemKey] =
    useState<TabSwitchItem>('noComment');

  const renderItem = ({ item }: { item: ProfilePageComment | UserGroup }) => {
    if (selectedItemKey === 'commented') {
      const profilePageComment = item as ProfilePageComment;
      return (
        <MemberCell
          testID={profilePageComment.profileUserGroup.id}
          userGroup={profilePageComment.profileUserGroup}
          customIcon={'chat'}
          onCustomIconPress={() =>
            onUpdateComment(
              profilePageComment.profileUserGroup.id,
              profilePageComment.id,
              profilePageComment.text,
            )
          }
        />
      );
    } else {
      const userGroup = item as UserGroup;
      return (
        <MemberCell
          testID={userGroup.id}
          userGroup={userGroup}
          customIcon={'add'}
          onCustomIconPress={() => onCreateComment(userGroup.id)}
        />
      );
    }
  };

  return (
    <Container>
      <NavHeader
        backButtonTestID={'buttonBack'}
        title={t('screens.profilePageComments')}
        onBackPress={onBack}
      />
      <Header>
        <SwitchSelector
          testID={'commentsTabBar'}
          items={[
            {
              key: 'commented',
              title: t('profilePageComments.commentedSection'),
            },
            {
              key: 'noComment',
              title: t('profilePageComments.noCommentSection'),
            },
          ]}
          selectedItemKey={selectedItemKey}
          onSelectedItem={(itemKey) =>
            setSelectedItemKey(
              itemKey === 'commented' ? 'commented' : 'noComment',
            )
          }
        />
      </Header>
      <UserList<React.ElementType>
        testID={'userList'}
        refreshControl={
          <RefreshControl refreshing={loading} onRefresh={onRefresh} />
        }
        data={
          selectedItemKey === 'commented'
            ? writtenProfilePageComments
            : notCommentUserGroups
        }
        renderItem={renderItem}
        keyExtractor={(item: ProfilePageComment | UserGroup) => item.id}
        ListFooterComponent={<Footer>{loading ? <Loading /> : null}</Footer>}
        onEndReachedThreshold={0.3}
        onEndReached={() =>
          selectedItemKey === 'commented'
            ? onLoadMoreComments()
            : onLoadMoreUsers()
        }
      />
    </Container>
  );
}
