/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation AcceptInstituteRequest
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  AcceptInstituteRequest,
  AcceptInstituteRequestVariables,
} from './types/AcceptInstituteRequest';
import { ACCEPT_INSTITUTE_REQUEST } from './';

export function useAcceptInstituteRequestMutation(
  options?: MutationHookOptions<
    AcceptInstituteRequest,
    AcceptInstituteRequestVariables
  >,
): MutationTuple<AcceptInstituteRequest, AcceptInstituteRequestVariables> {
  return useMutation<AcceptInstituteRequest, AcceptInstituteRequestVariables>(
    ACCEPT_INSTITUTE_REQUEST,
    options,
  );
}

export function createAcceptInstituteRequestMock({
  variables,
  data,
  error,
}: {
  variables?: AcceptInstituteRequestVariables;
  data?: AcceptInstituteRequest;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: ACCEPT_INSTITUTE_REQUEST,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
