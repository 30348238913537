import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { t } from '~/utils/i18n';
import { DEFAULT_LIST_OFFSET } from '../constants';
import { Team } from '../models/team';
import { TeamType } from '../operations/global';
import { useAddUserToTeamMutation } from '../operations/team/addUserToTeam';
import { useCreateTeamMutation } from '../operations/team/createTeam';
import { useDestroyTeamMutation } from '../operations/team/destroyTeam';
import {
  readTeamDetailFragment,
  readTeamSampleFragment,
  readTeamSamplesQuery,
  readTeamsQuery,
  writeTeamDetailFragment,
  writeTeamSampleFragment,
  writeTeamSamplesQuery,
  writeTeamsQuery,
} from '../operations/team/helpers';
import { useTeamsQuery } from '../operations/team/teams';

type TeamsOptions = {
  skip?: boolean;
};

type CreateTeamInputProps = {
  name: string;
  colorId: string;
  iconId: string;
  selectedUserIds: string[];
};

type RemoveTeamInputProps = {
  teamId: string;
  teamType: TeamType | null;
};

type TeamsHookProps = {
  teams: Team[];
  loading: boolean;
  hasNextPage?: boolean;
  createTeamLoading: boolean;
  removeTeamLoading: boolean;
  onLoadMore: () => void;
  onCreateTeam: (
    teamInput: CreateTeamInputProps,
    onCreated: () => void,
  ) => void;
  onRemoveTeam: (input: RemoveTeamInputProps, onRemoved: () => void) => void;
};

const useTeams = (options?: TeamsOptions): TeamsHookProps => {
  const skip = options?.skip;

  const { authUserId, authGroupId } = useAuth();

  const teamsVar = {
    after: null,
    first: DEFAULT_LIST_OFFSET,
    group: authGroupId,
  };

  const teamSamplesVar = {
    after: null,
    first: DEFAULT_LIST_OFFSET,
    name: null,
    group: authGroupId,
  };

  const {
    loading,
    data: teamsData,
    fetchMore: fetchMoreTeams,
  } = useTeamsQuery({
    skip: !authGroupId && skip,
    variables: teamsVar,
    notifyOnNetworkStatusChange: true,
  });

  const [createTeam, { loading: createTeamLoading }] = useCreateTeamMutation();
  const [destroyTeam, { loading: removeTeamLoading }] =
    useDestroyTeamMutation();
  const [addUserToTeam] = useAddUserToTeamMutation();

  const teams: Team[] =
    (teamsData?.teams?.edges.map((edge) => edge?.node) as Team[]) || [];

  const hasNextPage = teamsData?.teams?.pageInfo.hasNextPage;
  const endCursor = teamsData?.teams?.pageInfo.endCursor;

  const onLoadMore = () => {
    !loading &&
      hasNextPage &&
      fetchMoreTeams({
        variables: {
          ...teamsVar,
          after: endCursor,
        },
      });
  };

  const onCreateTeam = async (
    { name, colorId, iconId, selectedUserIds }: CreateTeamInputProps,
    onCreated: () => void,
  ) => {
    try {
      const { data } = await createTeam({
        variables: {
          input: {
            name,
            color: colorId,
            icon: iconId,
            group: authGroupId,
          },
        },
        update(cache, { data }) {
          const newTeam = data?.createTeam?.team;

          const currentTeams = readTeamsQuery({
            cache,
            variables: teamsVar,
          });

          const currentTeamSamples = readTeamSamplesQuery({
            cache,
            variables: teamSamplesVar,
          });

          if (currentTeams?.teams?.edges && newTeam) {
            writeTeamsQuery({
              cache,
              variables: teamsVar,
              data: {
                ...currentTeams,
                teams: {
                  ...currentTeams.teams,
                  edges: [
                    ...currentTeams.teams.edges,
                    {
                      __typename: 'TeamNodeEdge',
                      node: newTeam,
                    },
                  ],
                },
              },
            });
          }

          if (currentTeamSamples?.teams?.edges && newTeam) {
            writeTeamSamplesQuery({
              cache,
              variables: teamSamplesVar,
              data: {
                ...currentTeamSamples,
                teams: {
                  ...currentTeamSamples.teams,
                  edges: [
                    ...currentTeamSamples.teams.edges,
                    {
                      __typename: 'TeamNodeEdge',
                      node: {
                        ...newTeam,
                        usersCount: newTeam.users?.length || 0,
                        sampleMembers: newTeam.users,
                      },
                    },
                  ],
                },
              },
            });
          }
        },
      });
      const teamId = data?.createTeam?.team?.id;
      if (!teamId) {
        const messages = data?.createTeam?.errors?.map(
          (error) => error?.messages[0],
        );
        const errorMessage = messages?.[0] as string;
        Snackbar.show(errorMessage);
        return;
      }
      let i;
      for (i = 0; i < selectedUserIds.length; i++) {
        const userId = selectedUserIds[i];
        if (userId === authUserId) {
          //user who create the group is included by default
          continue;
        }
        await addUserToTeam({
          variables: {
            input: {
              teamId,
              userId,
            },
          },
          update(cache, { data }) {
            const newTeamUser = data?.addUserToTeam?.userTeam;

            const currentTeam = readTeamDetailFragment({
              cache,
              id: teamId,
            });

            const currentTeamSample = readTeamSampleFragment({
              cache,
              id: teamId,
            });

            if (currentTeam && newTeamUser) {
              const currentTeamUsers = currentTeam?.users || [];
              const updatedTeamUsers = [...currentTeamUsers, newTeamUser];

              writeTeamDetailFragment({
                cache,
                id: teamId,
                data: {
                  ...currentTeam,
                  users: updatedTeamUsers,
                },
              });

              if (currentTeamSample) {
                writeTeamSampleFragment({
                  cache,
                  id: teamId,
                  data: {
                    ...currentTeamSample,
                    usersCount: updatedTeamUsers?.length || 0,
                    sampleMembers: updatedTeamUsers,
                  },
                });
              }
            }
          },
        });
      }
      Snackbar.show(t('createTeam.createTeamSuccess'), {
        position: Snackbar.positions.BOTTOM_TAB,
        duration: Snackbar.durations.SHORT,
      });
      onCreated();
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onRemoveTeam = async (
    { teamId, teamType }: RemoveTeamInputProps,
    onRemoved: () => void,
  ) => {
    try {
      await destroyTeam({
        variables: {
          input: {
            id: teamId,
          },
        },
        update(cache) {
          const currentTeams = readTeamsQuery({
            cache,
            variables: teamsVar,
          });

          const currentTeamSamples = readTeamSamplesQuery({
            cache,
            variables: teamSamplesVar,
          });

          if (currentTeams?.teams?.edges && teamType === TeamType.OTHER) {
            writeTeamsQuery({
              cache,
              variables: teamsVar,
              data: {
                ...currentTeams,
                teams: {
                  ...currentTeams.teams,
                  edges: currentTeams.teams.edges.filter(
                    (edge) => edge?.node?.id !== teamId,
                  ),
                },
              },
            });
          }

          if (currentTeamSamples?.teams?.edges && teamType === TeamType.OTHER) {
            writeTeamSamplesQuery({
              cache,
              variables: teamSamplesVar,
              data: {
                ...currentTeamSamples,
                teams: {
                  ...currentTeamSamples.teams,
                  edges: currentTeamSamples.teams.edges.filter(
                    (edge) => edge?.node?.id !== teamId,
                  ),
                },
              },
            });
          }
        },
      });

      onRemoved();
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  return {
    teams,
    loading,
    hasNextPage,
    createTeamLoading,
    removeTeamLoading,
    onLoadMore,
    onCreateTeam,
    onRemoveTeam,
  };
};

export default useTeams;
