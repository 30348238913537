import React from 'react';
import { TextInput as RNTextInput, TextInputProps } from 'react-native';
import { Dimensions } from 'react-native';
import { useTheme } from 'styled-components/native';
import { Container, TextInput, CharCountContainer, CharCount } from './style';

export interface InputAreaFieldProps extends TextInputProps {
  value: string;
  placeholder?: string;
  maxCharCount?: number;
  onChangeText: (text: string) => void;
}

export default function InputAreaField({
  value,
  placeholder,
  maxCharCount,
  onChangeText,
  ...inputProps
}: InputAreaFieldProps): JSX.Element {
  const theme = useTheme();

  const currentCharCount = value.length;
  const exceedCharCount = maxCharCount ? maxCharCount - currentCharCount : 0;
  const exceed = exceedCharCount < 0;
  const { height } = Dimensions.get('window');
  return (
    <Container>
      <TextInput
        {...inputProps}
        as={RNTextInput}
        value={value}
        placeholder={placeholder}
        placeholderTextColor={theme.color.base.c6}
        onChangeText={onChangeText}
        multiline
        textAlignVertical={'top'}
        fullScreen={inputProps.editable as boolean}
        height={height * 0.25}
      />
      {maxCharCount && (
        <CharCountContainer>
          <CharCount exceed={exceed}>{`${
            exceed ? exceedCharCount : currentCharCount
          }/${maxCharCount}`}</CharCount>
        </CharCountContainer>
      )}
    </Container>
  );
}
