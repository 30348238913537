import styled from 'styled-components/native';
import Icon from '~/components/Icon';

interface IActionButton {
  liked?: boolean;
}
export const LikeButtonTouchable = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})<IActionButton>`
  flex-direction: row;
  align-items: center;
  padding: 2px 12px;
  gap: 8px;
  border-radius: 4.5px;
  ${({ liked }) =>
    liked &&
    `background-color: #FF2900
  `}
`;

export const LikeIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.color.base.c5,
  size: 22,
}))``;

export const LikeButtonText = styled.Text<IActionButton>`
  font-family: ${({ liked, theme }) =>
    liked ? theme.fontFamily.core.w600 : theme.fontFamily.core.w400};
  color: ${({ liked, theme }) =>
    liked ? theme.color.base.c0 : theme.color.base.c7};
  align-items: center;
  font-size: 16px;
  line-height: 20px;
`;
