import React, { useMemo, useState } from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Pagination } from 'react-native-snap-carousel';
import { useTheme } from 'styled-components';
import { BannerCarouselProps, BannerInfo } from '~/components/BannerCarousel';
import {
  NEWS_ITEM_WIDTH,
  NewsCard,
  NewsCardImage,
} from '~/components/BannerCarousel/style';
import Button from '~/components/Button';
import NavHeader from '~/components/NavHeader';
import { PaginationContainer } from '~/components/NotificationCarousel/style';
import SectionHeader from '~/components/SectionHeader';
import YearbookContentCell from '~/components/YearbookContentCell';
import { RefreshControl } from '~/components/common/style';
import { Spacer } from '~/components/common/style.web';
import { BestMatchedAsset } from '~/data/models/campaign';
import { ModuleInstance } from '~/data/models/yearbook';
import { ModuleType, VariantTypeEnum } from '~/data/operations/global';
import { PollListSeparator } from '~/screens/Main/Polls/layout/style';
import { shuffleArray } from '~/utils/helpers';
import useAdsTracking from '~/utils/hooks/useAdsTracking';
import { t } from '~/utils/i18n';
import {
  Container,
  Header,
  List,
  Section,
  CONTAINER_PADDING,
  CONTENT_CELL_WIDTH,
  BannerList,
  AssetImage,
} from './style';

export type YearbookLayoutProps = {
  moduleInstances: ModuleInstance[];
  loading: boolean;
  yearbookAdmin?: boolean;
  authUserId: string;
  onYearbookModuleClick: (
    moduleType: ModuleType,
    moduleInstanceId: string,
  ) => void;
  onHelp: () => void;
  onRefresh: () => void;
  openBannersInBrowser: (url: string) => void;
  onManage: () => void;
  onManageRequest: () => void;
} & BannerCarouselProps;

export default function Yearbook({
  banners,
  moduleInstances,
  loading,
  yearbookAdmin,
  authUserId,
  onYearbookModuleClick,
  onOpenLink,
  onHelp,
  onRefresh,
  onManage,
  onManageRequest,
  openBannersInBrowser,
}: YearbookLayoutProps): JSX.Element {
  const { handleViewTracking, handleAdsClick, bestMatchedAsset, randomIndex } =
    useAdsTracking({ authUserId, variantType: VariantTypeEnum.LARGE });

  const theme = useTheme();
  const [activeNewsIndex, setActiveNewsIndex] = useState(0);

  const data =
    loading && moduleInstances.length == 0 ? ['', ''] : moduleInstances;

  const renderYearbookContent = ({
    item: moduleInstance,
  }: {
    item?: ModuleInstance;
  }) => (
    <YearbookContentCell
      moduleInstance={moduleInstance}
      width={CONTENT_CELL_WIDTH}
      onYearbookModuleClick={onYearbookModuleClick}
    />
  );

  const renderNewsItem = ({
    item,
    index,
  }: {
    item?: BannerInfo | BestMatchedAsset;
    index: number;
  }) => {
    if (!item) return null;
    if ('__typename' in item) {
      const post = item;
      return (
        <TouchableOpacity
          testID={`${index}:AdsCell:${post?.id}`}
          onPress={() => {
            handleAdsClick(
              post?.id as string,
              post?.channelData?.[0]?.trackingUrl as string,
              openBannersInBrowser,
            );
          }}
        >
          <AssetImage
            source={{ uri: post?.channelData?.[0]?.imageLarge as string }}
            heightRatio={0.57}
          />
        </TouchableOpacity>
      );
    } else {
      const banner = item;
      const source = banner.externalImgUri
        ? { uri: banner.externalImgUri }
        : banner.imgUri;

      return (
        <>
          <NewsCard
            testID={banner.id}
            onPress={() => banner.link && onOpenLink(banner.link)}
          >
            <NewsCardImage heightRatio={0.57} source={source} />
          </NewsCard>
          {index === shuffledBanners.length - 1 && <Spacer h={16} />}
        </>
      );
    }
  };

  const shuffledBanners = useMemo(() => {
    let combinedBanners = shuffleArray<BannerInfo | BestMatchedAsset>(banners);

    if (bestMatchedAsset?.[randomIndex] !== undefined) {
      combinedBanners = [
        banners?.[0],
        bestMatchedAsset?.[randomIndex],
        ...banners.slice(1),
      ];
    }

    return combinedBanners;
  }, [banners, bestMatchedAsset, randomIndex]);

  return (
    <Container>
      <NavHeader
        title={t('g.yearbook')}
        type={'main'}
        RightComponent={
          <Button
            text={t('getHelp')}
            testID="getHelp"
            size={'sm'}
            type={'secondary-base'}
            onPress={onHelp}
          />
        }
      />
      <List<React.ElementType>
        testID="modulesList"
        refreshControl={
          <RefreshControl refreshing={loading} onRefresh={onRefresh} />
        }
        numColumns={2}
        columnWrapperStyle={{
          justifyContent: 'space-between',
          paddingHorizontal: CONTAINER_PADDING,
          paddingBottom: CONTAINER_PADDING,
        }}
        data={data}
        renderItem={renderYearbookContent}
        ListHeaderComponent={
          <Header>
            <BannerList<React.ElementType>
              snapToInterval={NEWS_ITEM_WIDTH + 8}
              disableIntervalMomentum
              decelerationRate={0.5}
              horizontal={true}
              data={shuffledBanners}
              renderItem={renderNewsItem}
              ListFooterComponent={() => <Spacer h={16} />}
              ItemSeparatorComponent={() => <PollListSeparator />}
              showsHorizontalScrollIndicator={false}
              handleViewTracking={handleViewTracking}
              setActiveNewsIndex={setActiveNewsIndex}
            />
            <PaginationContainer>
              <Pagination
                dotsLength={shuffledBanners.length}
                activeDotIndex={activeNewsIndex}
                containerStyle={{
                  backgroundColor: theme?.color.base.c2,
                  paddingHorizontal: 5,
                  paddingVertical: 5,
                  borderRadius: 10,
                }}
                dotContainerStyle={{
                  marginHorizontal: 2,
                }}
                dotStyle={{
                  height: 6,
                  width: 6,
                  borderRadius: 5,
                  backgroundColor: theme?.color.base.c6,
                }}
                inactiveDotStyle={{
                  height: 6,
                  width: 6,
                  borderRadius: 5,
                  backgroundColor: theme?.color.base.c4,
                }}
                inactiveDotOpacity={1.0}
                inactiveDotScale={1.0}
              />
            </PaginationContainer>
            <Section>
              <SectionHeader
                title={t('yearbookHome.manageSection')}
                RightComponent={
                  <Button
                    text={t('yearbookHome.manage')}
                    testID="manageButton"
                    iconName={'lock-02'}
                    type="primary-brand-01"
                    size="md"
                    onPress={yearbookAdmin ? onManage : onManageRequest}
                    state={yearbookAdmin ? 'default' : 'disabled'}
                    bypassDisabled
                  />
                }
              />
            </Section>
          </Header>
        }
        keyExtractor={(item: ModuleInstance) => item.id}
      />
    </Container>
  );
}
