import React, { useState, useRef } from 'react';
import InputField, {
  InputFieldHandle,
  InputFieldProps,
} from '~/components/InputField';
import AuthLayout from '~/screens/Auth/layout/AuthLayout';
import {
  LanguageProps,
  SubmitButtonProps,
} from '~/screens/Auth/layout/AuthLayout/types';
import { t } from '~/utils/i18n';
import { isValidEmail } from '~/utils/validators';

type ForgotPasswordLayoutProps = {
  redirectedEmail: string;
  loading: boolean;
  onBack: () => void;
  onForgotPassword: (email: string) => void;
} & LanguageProps;

export default function ForgotPassword({
  selectedLocale,
  redirectedEmail,
  loading,
  onBack,
  onForgotPassword,
  onSelectedLocale,
}: ForgotPasswordLayoutProps): JSX.Element {
  const emailRef = useRef<InputFieldHandle>(null);

  const [email, setEmail] = useState<string>(redirectedEmail);

  const isValidInput = (): boolean => {
    return isValidEmail(email);
  };

  const emailState = (): InputFieldProps['state'] => {
    if (email.length === 0) {
      return 'default';
    }
    return isValidEmail(email) ? 'success' : 'error';
  };

  const handleNext = () => {
    isValidInput() && onForgotPassword(email);
  };

  const headerProps = {
    title: t('forgotPassword.title'),
    descp: t('forgotPassword.descp'),
    progress: 1,
    total: 2,
  };

  const submitButtonProps: SubmitButtonProps = {
    isValidInput,
    loading,
    onPress: handleNext,
    text: t('forgotPassword.sendLink'),
    flex: true,
    size: '2xl',
  };

  const languageProps = {
    selectedLocale,
    onSelectedLocale,
  };

  return (
    <AuthLayout
      onBack={onBack}
      submitButtonProps={submitButtonProps}
      headerProps={headerProps}
      languageProps={languageProps}
    >
      <InputField
        ref={emailRef}
        testID={'inputEmail'}
        state={emailState()}
        iconName={'mail-01'}
        label={t('login.emailLabel')}
        placeholder={t('login.emailPlaceholder')}
        value={email}
        onChangeText={(value) => setEmail(value)}
        blurOnSubmit={false}
        returnKeyType={'next'}
        autoCapitalize={'none'}
        onSubmitEditing={() => handleNext()}
      />
    </AuthLayout>
  );
}
