import React, { useState, ForwardRefRenderFunction } from 'react';
import { Modal } from 'react-native';
import { Calendar } from 'react-native-calendars';
import { Modalize } from 'react-native-modalize';
import { useTheme } from 'styled-components/native';
import Button from '~/components/Button';
import { MONTHS } from '~/data/constants';
import { CalendarDateMode, CalendarDateObject } from '~/data/models/calendar';
import {
  CalendarHeader,
  CalendarHeaderMonth,
  LeftArrow,
  MonthYear,
  RightArrow,
} from '../EventCalendar/style';
import Icon from '../Icon';
import { WebModalHandler } from '../WebModal';
import {
  Content,
  DayWrapper,
  DayCell,
  DayText,
  DayBg,
  DayBgLeft,
  DayBgRight,
  Footer,
  ShowButton,
  DropdownContainer,
  PickerContainer,
} from './style';

export type ModalDatePickerHandler = Modalize;

export type ModalUserFilterDatePickerProps = {
  testID?: string;
  buttonText: string;
  date: CalendarDateObject | null;
  todayTimestamp?: number;
  mode?: CalendarDateMode;
  onConfirmDate: (dateObject: CalendarDateObject) => void;
  buttonLayout: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  togglePicker: () => void;
  showPicker: boolean;
};

interface CustomHeaderProps {
  date: XDate;
  handleMonthChange: (newDate: CalendarDateObject) => void;
}

const UserFilterDate: ForwardRefRenderFunction<
  WebModalHandler,
  ModalUserFilterDatePickerProps
> = ({
  testID,
  buttonText,
  date,
  todayTimestamp = new Date().getTime(),
  mode = 'range',
  buttonLayout,
  onConfirmDate,
  togglePicker,
  showPicker,
}) => {
  const theme = useTheme();
  const [selectedDate, setSelectedDate] = useState<CalendarDateObject | null>(
    date,
  );
  const [currentMonth, setCurrentMonth] = useState(new Date().toISOString());

  const onSelectedDate = (day: CalendarDateObject) => {
    setSelectedDate(day);
  };

  const handleMonthChange = (newDate: CalendarDateObject) => {
    const timeStamp = newDate?.timestamp;
    const date = new Date(timeStamp as number);
    setCurrentMonth(date?.toISOString());
  };

  const getDateValues = (date: Date) => ({
    day: date.getDate(),
    month: date.getMonth(),
    year: date.getFullYear(),
    dateString: date.toDateString(),
  });
  const CustomHeader: React.FC<CustomHeaderProps> = ({
    date,
    handleMonthChange,
  }) => {
    const handlePrevMonth = () => {
      const newDate = new Date(date.toString('yyyy-MM-dd'));
      newDate.setMonth(newDate.getMonth() - 1);

      handleMonthChange({
        ...getDateValues(newDate),
        timestamp: newDate.getTime(),
      });
    };

    const handleNextMonth = () => {
      const newDate = new Date(date.toString('yyyy-MM-dd'));
      newDate.setMonth(newDate.getMonth() + 1);

      handleMonthChange({
        ...getDateValues(newDate),
        timestamp: newDate.getTime(),
      });
    };

    const year = date?.getFullYear();
    const monthNumber = date?.getMonth();
    const month = MONTHS[monthNumber as number];

    return (
      <CalendarHeader>
        <CalendarHeaderMonth>
          <LeftArrow onPress={handlePrevMonth}>
            <Icon name="chevron-left" color={theme.color.brand_02} />
          </LeftArrow>
          <MonthYear>{`${month} ${year}`}</MonthYear>
          <RightArrow onPress={handleNextMonth}>
            <Icon name="chevron-right" color={theme.color.brand_02} />
          </RightArrow>
        </CalendarHeaderMonth>
      </CalendarHeader>
    );
  };

  const renderContent = () => {
    return (
      <Content>
        <Calendar
          key={currentMonth}
          current={currentMonth}
          style={{ height: 280 }}
          monthFormat={'MMM yyyy'}
          theme={{
            calendarBackground: theme.color.base.c0,
            textSectionTitleColor: theme.color.brand_02,
            textDayHeaderFontWeight: '700',
            textDayHeaderFontSize: 10,
            dayTextColor: theme.color.base.c5,
            textDayFontSize: 12,
            textDayFontWeight: '500',
            monthTextColor: theme.color.base.c5,
            textMonthFontWeight: '500',
            textMonthFontSize: 14,
            arrowColor: theme.color.brand_02,
          }}
          renderHeader={(date) => (
            <CustomHeader
              date={date as XDate}
              handleMonthChange={handleMonthChange}
            />
          )}
          enableSwipeMonths
          onDayPress={onSelectedDate}
          dayComponent={({ date, state, onPress, onLongPress }) => {
            const today = date?.dateString === currentMonth;
            const selected = date?.dateString === selectedDate?.dateString;
            const marked = mode === 'single' ? selected : today || selected;
            const timestamp = date?.timestamp || 0;
            const period =
              selectedDate &&
              timestamp > todayTimestamp &&
              timestamp < selectedDate.timestamp;
            const disabled =
              state === 'disabled' || (state === 'selected' && !selected);
            return (
              <DayWrapper
                onPress={() => onPress && onPress(date)}
                onLongPress={() => onLongPress && onLongPress(date)}
              >
                {(marked || period) && mode === 'range' && (
                  <DayBg>
                    <DayBgLeft bg={!today} />
                    <DayBgRight bg={!selected && !!selectedDate} />
                  </DayBg>
                )}
                <DayCell marked={marked}>
                  <DayText disabled={disabled} marked={marked}>
                    {date?.day}
                  </DayText>
                </DayCell>
              </DayWrapper>
            );
          }}
        />
        <Footer>
          <Button
            size={'sm'}
            text={buttonText}
            state={selectedDate ? 'default' : 'disabled'}
            onPress={() => {
              if (selectedDate) {
                onConfirmDate(selectedDate);
              }
            }}
          />
        </Footer>
      </Content>
    );
  };

  return (
    <Modal
      visible={showPicker}
      transparent
      onRequestClose={togglePicker}
      testID={testID}
    >
      <ShowButton activeOpacity={1} onPressOut={togglePicker}>
        <DropdownContainer layout={buttonLayout}>
          <PickerContainer>{renderContent()}</PickerContainer>
        </DropdownContainer>
      </ShowButton>
    </Modal>
  );
};

export default UserFilterDate;
