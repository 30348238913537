import { gql } from '@apollo/client';
import { DEFAULT_AVATAR_FIELDS, GROUP_ICON_FIELDS } from '../custom';
import { INSTITUTE_FIELDS } from '../institute';
import { USER_FIELDS } from './../user';

export const GRADUATION_TYPE_FIELDS = gql`
  fragment GraduationTypeFields on GraduationTypeNode {
    id
    name
  }
`;

export const GROUP_FIELDS = gql`
  ${GRADUATION_TYPE_FIELDS}
  ${GROUP_ICON_FIELDS}
  fragment GroupFields on GroupNode {
    id
    name
    avatar
    icon {
      ...GroupIconFields
    }
    type {
      ...GraduationTypeFields
    }
    year
    token
    created
    institute {
      id
      internalId
      type {
        id
        name
      }
      country
      name
      street
      zip
      city
    }
  }
`;

export const USER_GROUP_FIELDS = gql`
  ${USER_FIELDS}
  ${DEFAULT_AVATAR_FIELDS}
  fragment UserGroupFields on UserGroupNode {
    id
    role
    avatar
    defaultAvatar {
      ...DefaultAvatarFields
    }
    status
    user {
      ...UserFields
    }
    created
  }
`;

export const GROUP_USER_FIELDS = gql`
  ${USER_GROUP_FIELDS}
  ${GROUP_FIELDS}
  fragment GroupUserFields on UserGroupNode {
    ...UserGroupFields
    group {
      ...GroupFields
    }
  }
`;

export const GROUP_SAMPLE_FIELDS = gql`
  ${GROUP_FIELDS}
  ${USER_GROUP_FIELDS}
  fragment GroupSampleFields on GroupNode {
    ...GroupFields
    usersCount
    sampleMembers {
      ...UserGroupFields
    }
  }
`;

export const GROUP_DETAIL_FIELDS = gql`
  ${GROUP_FIELDS}
  ${INSTITUTE_FIELDS}
  fragment GroupDetailFields on GroupNode {
    ...GroupFields
    institute {
      ...InstituteFields
    }
  }
`;

export const GRADUATION_TYPES = gql`
  ${GRADUATION_TYPE_FIELDS}
  query GraduationTypes($after: String, $first: Int!) {
    graduationTypes(after: $after, first: $first) {
      edges {
        node {
          ...GraduationTypeFields
        }
      }
    }
  }
`;

export const GRADUATION_TYPES_COUNTRY = gql`
  query GraduationTypesCountry($id: String, $name: String, $country: String) {
    graduationTypesCountry(id: $id, name: $name, country: $country) {
      id
      name
      country
    }
  }
`;

export const YEARBOOK_STATISTICS_FIELDS = gql`
  fragment YearbookStatisticsFields on YearbookStatisticsNode {
    profilePagesFilledIn
    usersWithQuote
    lastDeadline
    contentModules
    estimatedPages
    imagesUploaded
    wordCount
  }
`;

export const GROUP_STATISTICS_FIELDS = gql`
  ${YEARBOOK_STATISTICS_FIELDS}
  fragment GroupStatisticsFields on GroupNode {
    id
    yearbookStatistics {
      ...YearbookStatisticsFields
    }
  }
`;

export const GROUPS = gql`
  ${GROUP_FIELDS}
  query Groups(
    $after: String
    $first: Int!
    $name: String
    $type: ID
    $year: Int
    $institute: ID
    $marketProfile: ID
  ) {
    groups(
      after: $after
      first: $first
      name: $name
      type: $type
      year: $year
      institute: $institute
      marketProfile: $marketProfile
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...GroupFields
        }
      }
    }
  }
`;

export const GROUP = gql`
  ${GROUP_DETAIL_FIELDS}
  query Group($id: ID!) {
    group(id: $id) {
      ...GroupDetailFields
      teams {
        id
        name
      }
    }
  }
`;

export const GROUP_SAMPLE = gql`
  ${GROUP_SAMPLE_FIELDS}
  query GroupSample($id: ID!) {
    group(id: $id) {
      ...GroupSampleFields
    }
  }
`;

export const GROUP_STATISTICS = gql`
  ${GROUP_STATISTICS_FIELDS}
  query GroupStatistics($id: ID!) {
    group(id: $id) {
      ...GroupStatisticsFields
    }
  }
`;

export const ADD_USER_TO_GROUP = gql`
  ${USER_GROUP_FIELDS}
  mutation AddUserToGroup($input: AddUserToGroupInput!) {
    addUserToGroup(input: $input) {
      userGroup {
        ...UserGroupFields
      }
    }
  }
`;

export const ACCEPT_USER_IN_GROUP = gql`
  ${USER_GROUP_FIELDS}
  mutation AcceptUserInGroup($input: AcceptUserInGroupInput!) {
    acceptUserInGroup(input: $input) {
      userGroup {
        ...UserGroupFields
      }
    }
  }
`;

export const REMOVE_USER_FROM_GROUP = gql`
  ${USER_GROUP_FIELDS}
  mutation RemoveUserFromGroup($input: RemoveUserFromGroupInput!) {
    removeUserFromGroup(input: $input) {
      userGroup {
        ...UserGroupFields
      }
    }
  }
`;

//UserGroupNode by USER_GROUPS query returns all user (assuming the user inside the app) for a given group
export const USER_GROUPS = gql`
  ${USER_GROUP_FIELDS}
  query UserGroups(
    $after: String
    $first: Int!
    $group: ID
    $status: GroupsUserGroupStatusChoices
  ) {
    userGroups(after: $after, first: $first, group: $group, status: $status) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...UserGroupFields
        }
      }
    }
  }
`;

//UserGroupNode by GROUP_USERS query returns all user (with group) for a given user
export const GROUP_USERS = gql`
  ${GROUP_USER_FIELDS}
  query GroupUsers(
    $after: String
    $first: Int!
    $user: ID
    $status: GroupsUserGroupStatusChoices
  ) {
    userGroups(after: $after, first: $first, user: $user, status: $status) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...GroupUserFields
        }
      }
    }
  }
`;

export const USER_GROUP = gql`
  ${USER_GROUP_FIELDS}
  query UserGroup($id: ID!) {
    userGroup(id: $id) {
      ...UserGroupFields
    }
  }
`;

export const CREATE_GROUP = gql`
  ${GROUP_FIELDS}
  mutation CreateGroup($input: CreateGroupInput!) {
    createGroup(input: $input) {
      group {
        ...GroupFields
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const UPDATE_GROUP = gql`
  ${GROUP_FIELDS}
  mutation UpdateGroup($input: UpdateGroupInput!) {
    updateGroup(input: $input) {
      group {
        ...GroupFields
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const SET_GROUP_AVATAR = gql`
  ${GROUP_FIELDS}
  mutation SetGroupAvatar($input: SetGroupAvatarInput!) {
    setGroupAvatar(input: $input) {
      group {
        ...GroupFields
      }
    }
  }
`;

export const SET_USER_GROUP_AVATAR = gql`
  ${USER_GROUP_FIELDS}
  mutation SetUserGroupAvatar($input: SetUserGroupAvatarInput!) {
    setUserGroupAvatar(input: $input) {
      userGroup {
        ...UserGroupFields
      }
    }
  }
`;

export const UPDATE_USER_GROUP = gql`
  ${USER_GROUP_FIELDS}
  mutation UpdateUserGroup($input: UpdateUserGroupInput!) {
    updateUserGroup(input: $input) {
      userGroup {
        ...UserGroupFields
      }
    }
  }
`;

export const IMPORT_TO_ERP = gql`
  mutation ImportToErp($input: ImportToErpInput!) {
    importToErp(input: $input) {
      success
    }
  }
`;

export const LEADERBOARD_USER_FIELDS = gql`
  ${USER_FIELDS}
  fragment LeaderboardUserFields on LeaderboardUserNode {
    user {
      ...UserFields
    }
    points
    position
  }
`;

export const LEADERBOARD_USERS = gql`
  ${LEADERBOARD_USER_FIELDS}
  query LeaderboardUsers(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $user: ID
  ) {
    leaderboardUsers(
      before: $before
      after: $after
      first: $first
      last: $last
      user: $user
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...LeaderboardUserFields
        }
      }
    }
  }
`;
