import styled from 'styled-components/native';

export const UploadCSVText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 12px;
  color: ${({ theme }) => theme.color.brand_02};
  text-decoration-line: underline;
`;

export const UploadedCSVFile = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 12px;
  color: ${({ theme }) => theme.color.base.c9};
`;

export const UploadedCSVErrorContainer = styled.View`
  flex-direction: row;
`;

export const UploadedCSVErrorText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 12px;
  color: ${({ theme }) => theme.color.error};
  padding-right: 5px;
`;

export const UploadingCSVText = styled.Text`
  font-size: 12px;
  color: ${({ theme }) => theme.color.base.c6};
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
`;

export const DeleteIconWrapper = styled.View`
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.color.base.c6};
  align-items: center;
  margin-left: 10px;
  padding-left: 1.5px;
  justify-content: center;
`;

export const DeleteIconContainer = styled.View`
  flex-direction: row;
`;
