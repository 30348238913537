import { MutationUpdaterFn } from '@apollo/client';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { DeletePoll } from '~/data/operations/post/types/DeletePoll';
import { DeletePost } from '~/data/operations/post/types/DeletePost';
import { t } from '~/utils/i18n';
import { DEFAULT_LIST_OFFSET } from '../constants';
import { Post } from '../models/post';
import { useBlockUserMutation } from '../operations/post/blockUser';
import { useDeletePollMutation } from '../operations/post/deletePoll';
import { useDeletePostMutation } from '../operations/post/deletePost';
import { readPostsQuery, writePostsQuery } from '../operations/post/helpers';
import { useLikePostMutation } from '../operations/post/likePost';
import { usePostsQuery } from '../operations/post/posts';
import { useRemoveLikeFromPostMutation } from '../operations/post/removeLikeFromPost';
import { useReportMutationMutation } from '../operations/post/reportMutation';
import { useReportPostContentMutation } from '../operations/post/reportPostContent';

type PostHookOptions = { teamId?: string; skip?: boolean };

type PostsHookProps = {
  posts: Post[];
  loading: boolean;
  hasNextPage?: boolean;
  hiddenPostIds: string[];
  onRefresh: () => void;
  onLoadMore: () => void;
  onLikePost: (postId: string, liked: boolean) => void;
  onDeletePost: (postId: string, pollId?: string) => void;
  onReportPost: (postId: string) => void;
  onReportUser: (userGroupId: string, teamId?: string) => void;
  onBlockUser: (userGroupId: string, teamId?: string) => void;
  onHidePost: (postId: string) => void;
};

const usePosts = (options?: PostHookOptions): PostsHookProps => {
  const teamId = options?.teamId;
  const skip = options?.skip;

  const [likePost] = useLikePostMutation();
  const [removeLikeFromPost] = useRemoveLikeFromPostMutation();
  const [deletePost] = useDeletePostMutation();
  const [deletePoll] = useDeletePollMutation();
  const [reportPostContent] = useReportPostContentMutation();
  const [blockUser] = useBlockUserMutation();
  const [reportMutation] = useReportMutationMutation();

  const { authGroupId, authUserGroupId, hiddenPostIds, onHidePost } = useAuth();

  const postsVar = {
    after: null,
    first: DEFAULT_LIST_OFFSET,
    createdBy_Group: !teamId ? authGroupId : undefined,
    team: teamId,
    authUserGroupId,
  };

  const {
    data: postsData,
    loading,
    fetchMore: fetchMorePosts,
    refetch: refetchPosts,
  } = usePostsQuery({
    skip: !authUserGroupId && skip,
    variables: {
      ...postsVar,
    },
    notifyOnNetworkStatusChange: true,
  });
  const posts: Post[] =
    (postsData?.posts?.edges.map((edge) => edge?.node) as Post[]) || [];
  const hasNextPage = postsData?.posts?.pageInfo.hasNextPage;
  const endCursor = postsData?.posts?.pageInfo.endCursor;

  const onLikePost = async (postId: string, liked: boolean) => {
    try {
      if (liked) {
        await removeLikeFromPost({
          variables: {
            input: {
              id: postId,
            },
            authUserGroupId,
          },
        });
      } else {
        await likePost({
          variables: {
            input: {
              id: postId,
            },
            authUserGroupId,
          },
        });
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onDeletePost = async (postId: string, pollId?: string) => {
    try {
      const update: MutationUpdaterFn<DeletePost | DeletePoll> = (cache) => {
        const currentPosts = readPostsQuery({
          cache,
          variables: postsVar,
        });

        if (currentPosts && currentPosts.posts && currentPosts.posts.edges) {
          writePostsQuery({
            cache,
            variables: postsVar,
            data: {
              ...currentPosts,
              posts: {
                ...currentPosts.posts,
                edges: currentPosts.posts.edges.filter(
                  (edge) => edge?.node?.id != postId,
                ),
              },
            },
          });
        }
      };

      if (pollId) {
        await deletePoll({
          variables: {
            input: {
              id: pollId,
            },
            authUserGroupId,
          },
          update,
        });
      } else {
        await deletePost({
          variables: {
            input: {
              id: postId,
            },
            authUserGroupId,
          },
          update,
        });
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onReportPost = async (postId: string) => {
    try {
      await reportPostContent({
        variables: {
          input: {
            id: postId,
          },
        },
      });
      Snackbar.show(t('g.postReported'));
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onBlockUser = async (userGroupId: string, teamId?: string) => {
    try {
      await blockUser({
        variables: {
          input: {
            id: teamId || userGroupId,
          },
        },
      });
      teamId
        ? Snackbar.show(t('g.teamBlocked'))
        : Snackbar.show(t('g.userBlocked'));
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onReportUser = async (userGroupId: string, teamId?: string) => {
    try {
      await reportMutation({
        variables: {
          input: {
            id: teamId || userGroupId,
          },
        },
      });
      teamId
        ? Snackbar.show(t('g.teamReported'))
        : Snackbar.show(t('g.userReported'));
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onRefresh = () => {
    refetchPosts(postsVar);
  };

  const onLoadMore = () => {
    fetchMorePosts({
      variables: {
        ...postsVar,
        after: endCursor,
      },
    });
  };

  const filteredPosts = posts.filter(
    (post) => !hiddenPostIds.includes(post.id),
  );

  return {
    posts: filteredPosts,
    loading,
    hasNextPage,
    hiddenPostIds,
    onRefresh,
    onLoadMore,
    onLikePost,
    onDeletePost,
    onReportPost,
    onReportUser,
    onBlockUser,
    onHidePost,
  };
};

export default usePosts;
