export const web = {
  web_logo: require('./web_logo.png'),
} as const;

export const web_side_images = [
  require('./web_1.jpg'),
  require('./web_2.jpg'),
  require('./web_3.jpg'),
  require('./web_4.jpg'),
  require('./web_5.jpg'),
  require('./web_6.jpg'),
  require('./web_7.jpg'),
];
