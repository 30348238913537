import styled from 'styled-components/native';

export const InputContent = styled.View`
  padding-top: 16px;
  padding-bottom: 48px;
`;

export const InstituteList = styled.FlatList`
  padding-top: 12px;
  padding-horizontal: 16px;
`;

export const InstituteCellWrapper = styled.View`
  padding-bottom: 16px;
`;

export const Footer = styled.View`
  padding-vertical: 16px;
  align-items: center;
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c9,
}))`
  padding-top: 16px;
`;

export const EmptyText = styled.Text`
  margin-vertical: 16px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 14px;
`;
