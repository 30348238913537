import styled from 'styled-components/native';
import { ResponsiveProps } from '~/components/common/style.web';
import IconStyled from '../IconStyled';

export const Container = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const ModalTitle = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 22px;
  line-height: 32px;
  margin-top: 54px;
  text-align: center;
`;

export const ModalDescription = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c5};
  font-size: 18px;
  line-height: 22px;
  margin-top: 24px;
  text-align: center;
`;
export const ButtonContainer = styled.View<ResponsiveProps>`
  ${({ isDesktop }) => !isDesktop && 'flex: 1;'}
  ${({ isDesktop }) => isDesktop && 'min-width: 310px;'}
`;

export const ModalButtonRow = styled.View<ResponsiveProps>`
  flex-direction: row;
  flex-wrap: wrap-reverse;
  gap: ${({ isDesktop }) => (isDesktop ? 32 : 16)}px;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 80px;
`;

export const ModalIconContainer = styled.View`
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ModalIconBackground = styled.View`
  width: 72px;
  height: 72px;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.color.error};
  justify-content: center;
  align-items: center;
  margin-top: 108px;
`;

export const ModalIcon = styled(IconStyled).attrs(({ theme }) => ({
  size: 32,
  color: theme.color.base.c0,
}))``;
