import React from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { CalendarDateObject } from '~/data/models/calendar';
import { usePostQuery } from '~/data/operations/post/post';
import { useUpdatePollMutation } from '~/data/operations/post/updatePoll';
import { RootStackParamList } from '~/navigation/types';
import { formattedDate } from '~/utils/dates';
import { t } from '~/utils/i18n';
import EditPollLayout from './layout';

type EditPollNavProp = StackNavigationProp<RootStackParamList, 'EditPoll'>;
type CommentsRouteProp = RouteProp<RootStackParamList, 'EditPoll'>;

export default function EditPoll(): JSX.Element {
  const navigation = useNavigation<EditPollNavProp>();
  const { authUserGroupId } = useAuth();

  const {
    params: { postId },
  } = useRoute<CommentsRouteProp>();

  const {
    data: postData,
    loading,
    error,
  } = usePostQuery({
    variables: {
      id: postId,
      authUserGroupId,
    },
    skip: !postId,
  });

  const [updatePoll, { error: updateError, loading: updateLoading }] =
    useUpdatePollMutation();

  const onUpdatePoll = async (
    dueDate: CalendarDateObject | null,
    dueTime: Date | undefined,
  ) => {
    if (!postData?.post?.poll || !dueDate || !dueTime || !postData.post.owner) {
      return;
    }
    const day = formattedDate(dueDate.dateString, 'yyyy-MM-dd', undefined);
    const time = formattedDate(dueTime, 'HH:mm:ss', undefined);
    const deadline = formattedDate(`${day}T${time}`, `yyyy-MM-dd'T'HH:mm:ss`);
    const { poll } = postData.post;

    const input = {
      id: poll.id,
      owner: postData.post.owner,
      question: poll.question,
      deadline,
      answersAllowed: poll.answersAllowed,
    };

    try {
      updatePoll({
        variables: {
          input,
        },
      });

      navigation.goBack();
      Snackbar.show(t('editPoll.editPollSuccess'));
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  return (
    <EditPollLayout
      post={postData}
      loading={loading || updateLoading}
      errorMessage={
        (error && error.message) ||
        (updateError && updateError.message) ||
        undefined
      }
      onBack={() => navigation.goBack()}
      onUpdatePoll={onUpdatePoll}
    />
  );
}
