import styled from 'styled-components/native';
import { css } from 'styled-components/native';
import ImageComp from '~/components/Image';
import TabSectionButton from '~/components/TabSectionButton';

export const Container = styled.View`
  flex: 1;
  background-color: ${({ theme }) => theme.color.brand_01};
`;

export const TopContainer = styled.View`
  height: 376px;
  padding-top: ${({ theme }) => theme.safeAreaInsets.top}px;
`;

export const BottomContainer = styled.View`
  flex: 1;
  margin-top: 24px;
  margin-bottom: 64px;
  padding-horizontal: ${({ theme }) => theme.spacing.medium};
  justify-content: space-between;
`;

export const ImageContainer = styled.ImageBackground`
  height: 376px;
  width: 100%;
  position: absolute;
`;

export const TitleWrapper = styled.View`
  position: absolute;
  padding-horizontal: ${({ theme }) => theme.spacing.medium};
  bottom: 24px;
`;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c0};
  font-size: 32px;
  line-height: 44px;
`;

export const Desc = styled.Text`
  font-size: 18px;
  line-height: 24px;
`;
export const DescWrapper = styled.View`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: ${({ theme }) => theme.spacing.large};
`;

export const BadgeText = styled.Text`
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.color.base.c0};
  background-color: ${({ theme }) => theme.color.base.c9};
  padding: 2px 16px;
  border-radius: 14px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.color.base.c9};
  overflow: hidden;
`;

// ____ WEB ____

export const ImageWrapper = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`;

export const Image = styled(ImageComp)<{ noMargin?: boolean }>`
  ${({ noMargin }) =>
    !noMargin &&
    css`
      margin-vertical: 36px;
    `}
  width: 160px;
  height: 160px;
  border-radius: 5px;
  transform: rotate(10deg);
  margin-left: 22px;
  border-width: 5px;
  border-color: ${({ theme }) => theme.color.base.c2};
`;

export const SmallImage = styled(ImageComp)`
  width: 92px;
  height: 74px;
  margin-left: -18px;
  margin-top: 20px;
  transform: rotate(-10deg);
`;

export const WelcomeGroupName = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c10};
  font-size: 32px;
  line-height: 24px;
  padding: 33px 0 55px 0;
`;

export const DownloadOnMobileContainer = styled.View`
  flex-direction: column;
  align-items: left;
  justify-content: center;
  gap: 16px;
  margin-top: 30px;
`;

export const DownloadOnMobileTitle = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.brand_02};
  font-size: 16px;
  line-height: 20px;
`;

export const TabSection = styled(TabSectionButton)``;

export const TabSectionContainer = styled.View`
  gap: 16px;
  width: 100%;
`;
