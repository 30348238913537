import { Animated } from 'react-native';
import Swipeable from 'react-native-swipeable';
import styled from 'styled-components/native';
import Icon from '~/components/Icon';
import Image from '~/components/Image';

const CELL_CONTENT_ICON_HEIGHT = 90;
const CELL_CONTENT_NO_ICON_HEIGHT = 54;
export const CELL_DESCRIPTION_HEIGHT = 14;
const CELL_BOTTOM_MARGIN = 16;
export const CELL_CONTAINER_ICON_HEIGHT =
  CELL_CONTENT_ICON_HEIGHT + CELL_BOTTOM_MARGIN;
export const CELL_CONTAINER_NO_ICON_HEIGHT =
  CELL_CONTENT_NO_ICON_HEIGHT + CELL_BOTTOM_MARGIN;

export const Container = styled.View``;

export const SWIPEABLE_BUTTON_WIDTH = 92;

const SwipeableContainer = styled(Swipeable)`
  overflow: hidden;
`;

export const AnimatedSwipeableContainer =
  Animated.createAnimatedComponent(SwipeableContainer);

export const DeleteButton = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})`
  flex: 1;
  width: ${SWIPEABLE_BUTTON_WIDTH}px;
  align-items: center;
  justify-content: center;
  margin-bottom: ${CELL_BOTTOM_MARGIN}px;
  padding-right: 16px;
`;

export const DeleteIcon = styled(Icon).attrs(({ theme }) => ({
  name: 'x-close',
  size: 16,
  color: theme.color.error,
}))``;

export const DeleteText = styled.Text`
  color: ${({ theme }) => theme.color.error};
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 12px;
`;

interface IContent {
  hasIcon?: boolean;
  swipeable?: boolean;
}

export const Content = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})<IContent>`
  background-color: ${({ theme }) => theme.color.base.c0};
  flex-direction: row;
  align-items: center;
  margin-bottom: ${CELL_BOTTOM_MARGIN}px;
  margin-horizontal: ${({ swipeable }) => (swipeable ? 16 : 0)}px;
  padding: 16px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.color.base.c3};
  border-radius: 5px;
  box-shadow: -3px 3px 2px ${({ theme }) => theme.color.base.c1};
`;

export const IconContent = styled.View`
  width: 56px;
  height: 56px;
  background-color: ${({ theme }) => theme.color.base.c2};
  border-radius: 5px;
  align-items: center;
  margin-right: 16px;
  justify-content: center;
`;

export const ImageContent = styled(Image)`
  width: 82px;
  height: 56px;
  background-color: ${({ theme }) => theme.color.base.c1};
  border-radius: 5px;
  margin-right: 16px;
`;

export const CellIcon = styled(Icon).attrs(() => ({
  size: 23,
}))``;

export const MainCellIcon = styled(Icon).attrs(() => ({
  size: 32,
}))``;

interface IText {
  disabled?: boolean;
}

export const TextWrapper = styled.View`
  flex: 1;
`;

export const Text = styled.Text<IText>`
  color: ${({ disabled, theme }) =>
    disabled ? theme.color.base.c4 : theme.color.base.c10};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
  line-height: 20px;
`;

export const Desc = styled.Text<IText>`
  color: ${({ disabled, theme }) =>
    disabled ? theme.color.base.c4 : theme.color.base.c7};
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 14px;
  line-height: 18px;
`;

export const ArrowIcon = styled(Icon).attrs(({ theme }) => ({
  name: 'chevron-right',
  size: 24,
  color: theme.color.base.c9,
}))`
  padding-left: 8px;
`;
