import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { useLikeCommentMutation } from '../operations/post/likeComment';
import { useRemoveLikeFromCommentMutation } from '../operations/post/removeLikeFromComment';

type PollVoteHookProps = {
  onLikeComment: (commentId: string, liked: boolean) => void;
};

const useLikeComment = (): PollVoteHookProps => {
  const [likeComment] = useLikeCommentMutation();
  const [removeLikeFromComment] = useRemoveLikeFromCommentMutation();

  const { authUserGroupId } = useAuth();

  const onLikeComment = async (commentId: string, liked: boolean) => {
    try {
      if (liked) {
        await removeLikeFromComment({
          variables: {
            input: {
              id: commentId,
            },
            authUserGroupId,
          },
        });
      } else {
        await likeComment({
          variables: {
            input: {
              id: commentId,
            },
            authUserGroupId,
          },
        });
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };
  return {
    onLikeComment,
  };
};

export default useLikeComment;
