import React, { Component } from 'react';
import { theme } from 'gradoo-theme';
import RootSiblings from 'react-native-root-siblings';
import { ThemeProvider } from 'styled-components/native';
import SnackbarContainer, {
  positions,
  durations,
  SnackbarOptionsProps,
} from './SnackbarContainer';

export default class Snackbar extends Component {
  static displayName = 'Snackbar';
  static positions = positions;
  static durations = durations;

  static show = (
    message: string,
    options: SnackbarOptionsProps = {
      position: positions.BOTTOM_STACK,
      duration: durations.SHORT,
    },
  ): RootSiblings => {
    return new RootSiblings(
      (
        <ThemeProvider theme={theme}>
          <SnackbarContainer {...options} message={message} />
        </ThemeProvider>
      ),
    );
  };

  render(): null {
    return null;
  }
}
