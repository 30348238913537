import React, { useEffect } from 'react';
import {
  useNavigation,
  CompositeNavigationProp,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useAuth } from '~/context/auth';
import { useIntercom } from '~/context/intercom';
import useAuthTeams from '~/data/hooks/useAuthTeams';
import useEmarsys from '~/data/hooks/useEmarsys';
import usePollVote from '~/data/hooks/usePollVote';
import usePosts from '~/data/hooks/usePosts';
import { BestMatchedAsset } from '~/data/models/campaign';
import { VariantTypeEnum } from '~/data/operations/global';
import { PostFields } from '~/data/operations/post/types/PostFields';
import {
  FeedStackParamList,
  RootStackParamList,
  MainTabParamList,
} from '~/navigation/types';
import { shuffleAdsIntoPosts } from '~/utils/helpers';
import useAdsTracking from '~/utils/hooks/useAdsTracking';
import { requestNotifications } from '~/utils/permissions';
import FeedLayout from './layout';

type FeedNavProp = CompositeNavigationProp<
  StackNavigationProp<FeedStackParamList, 'Feed'>,
  CompositeNavigationProp<
    StackNavigationProp<RootStackParamList>,
    StackNavigationProp<MainTabParamList>
  >
>;

type SubLink = {
  BUG: string;
  FEEDBACK: string;
  FEATURE: string;
};

export const LINKS: { [key in 'de' | 'en' | 'tr']: SubLink } = {
  de: {
    BUG: 'https://gradoo.typeform.com/to/kuiQjlq8',
    FEEDBACK: 'https://gradoo.typeform.com/to/zlwnoHyQ',
    FEATURE: 'https://gradoo.typeform.com/to/Xnrd6VD5',
  },
  en: {
    BUG: 'https://gradoo.typeform.com/to/o9p5PH7M',
    FEEDBACK: 'https://gradoo.typeform.com/to/ZsiLp4nH',
    FEATURE: 'https://gradoo.typeform.com/to/A5QeEsng',
  },
  tr: {
    BUG: 'https://gradoo.typeform.com/to/BHrdwbIg',
    FEEDBACK: 'https://gradoo.typeform.com/to/hvDOBZDi',
    FEATURE: 'https://gradoo.typeform.com/to/jt6ZQJk2',
  },
};

export default function Feed({ now }: { now?: Date }): JSX.Element {
  const navigation = useNavigation<FeedNavProp>();

  const { authUserId, authUserGroup } = useAuth();

  const { authTeamIds } = useAuthTeams();
  const { onHelpCenter } = useIntercom();

  const { setContact } = useEmarsys();

  const {
    posts,
    loading: postsLoading,
    hasNextPage: postsHasNextPage,
    onRefresh: onRefreshPosts,
    onLoadMore: onLoadMorePosts,
    onLikePost,
    onDeletePost,
    onReportPost,
    onReportUser,
    onBlockUser,
    onHidePost,
  } = usePosts();

  const { onPollVote } = usePollVote();

  const { bestMatchedAsset, refetch: refetchAds } = useAdsTracking({
    authUserId,
    variantType: VariantTypeEnum.SMALL,
  });

  useEffect(() => {
    (async () => {
      if (authUserGroup) {
        const authUserGender = authUserGroup.user.gender;

        await setContact(authUserId);

        if (authUserGender === null) {
          navigation.navigate('Onboarding', { onboardingType: 'core' });
        } else {
          requestNotifications(['alert', 'sound']);
        }
      }
    })();
  }, [authUserGroup?.id]);

  const openBannersInBrowser = (url: string) => {
    navigation.navigate('Browser', {
      title: 'Banner',
      url,
    });
  };

  const refreshPosts = () => {
    onRefreshPosts();
    refetchAds();
  };

  let mergedArray: (BestMatchedAsset | PostFields)[] = [...posts];

  if (bestMatchedAsset !== undefined) {
    mergedArray = [...(bestMatchedAsset as BestMatchedAsset[]), ...posts];
    mergedArray = shuffleAdsIntoPosts(mergedArray);
  }

  return (
    <FeedLayout
      now={now}
      onHelp={onHelpCenter}
      authUserId={authUserId}
      loggedUserGroup={authUserGroup}
      posts={posts.length > 0 ? mergedArray : []}
      loading={postsLoading}
      authTeamIds={authTeamIds}
      hasNextPage={postsHasNextPage}
      onCreatePost={(imgUris) => navigation.navigate('CreatePost', { imgUris })}
      onCreatePoll={() => {
        navigation.navigate('PlanningStack', {
          screen: 'Polls',
          initial: false,
        });
        navigation.navigate('CreatePoll');
      }}
      openBannersInBrowser={openBannersInBrowser}
      onLike={onLikePost}
      onDelete={onDeletePost}
      onReportPost={onReportPost}
      onReportUser={onReportUser}
      onBlockUser={onBlockUser}
      onHidePost={onHidePost}
      onComment={(postId) => navigation.navigate('Comments', { postId })}
      onLoadMorePosts={onLoadMorePosts}
      onRefresh={refreshPosts}
      onPollVote={onPollVote}
      onEdit={(postId) => navigation.navigate('EditPoll', { postId })}
    />
  );
}
