/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation UpdatePoll
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { UpdatePoll, UpdatePollVariables } from './types/UpdatePoll';
import { UPDATE_POLL } from './';

export function useUpdatePollMutation(
  options?: MutationHookOptions<UpdatePoll, UpdatePollVariables>,
): MutationTuple<UpdatePoll, UpdatePollVariables> {
  return useMutation<UpdatePoll, UpdatePollVariables>(UPDATE_POLL, options);
}

export function createUpdatePollMock({
  variables,
  data,
  error,
}: {
  variables?: UpdatePollVariables;
  data?: UpdatePoll;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: UPDATE_POLL,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
