/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation SetUserGroupAvatar
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  SetUserGroupAvatar,
  SetUserGroupAvatarVariables,
} from './types/SetUserGroupAvatar';
import { SET_USER_GROUP_AVATAR } from './';

export function useSetUserGroupAvatarMutation(
  options?: MutationHookOptions<
    SetUserGroupAvatar,
    SetUserGroupAvatarVariables
  >,
): MutationTuple<SetUserGroupAvatar, SetUserGroupAvatarVariables> {
  return useMutation<SetUserGroupAvatar, SetUserGroupAvatarVariables>(
    SET_USER_GROUP_AVATAR,
    options,
  );
}

export function createSetUserGroupAvatarMock({
  variables,
  data,
  error,
}: {
  variables?: SetUserGroupAvatarVariables;
  data?: SetUserGroupAvatar;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: SET_USER_GROUP_AVATAR,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
