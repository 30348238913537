import styled from 'styled-components/native';

export const Container = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  justify-content: space-between;
`;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 20px;
  color: ${({ theme }) => theme.color.base.c9};
  padding-bottom: 150px;
`;

export const ChooseChannelContainer = styled.View`
  align-items: center;
`;

export const ChannelWapper = styled.View`
  height: 116px;
  width: 178px;
`;
export const ChooseChannelTitle = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
  color: ${({ theme }) => theme.color.base.c9};
  padding-bottom: 30px;
`;
