import React from 'react';
import { flags } from '~/assets/imgs';
import CheckBox from '~/components/CheckBox';
import { CountryInfo } from '~/data/models/marketProfile';
import { Container, FlagView, FlagImage, Name } from './style';

type CountryCellProps = {
  countryInfo: CountryInfo;
  selected: boolean;
  onSelect: () => void;
};

export default function CountryCell({
  countryInfo,
  selected,
  onSelect,
}: CountryCellProps): JSX.Element {
  return (
    <Container>
      <FlagView>
        <FlagImage source={flags[countryInfo.country]} />
      </FlagView>
      <Name selected={selected}>{countryInfo.name}</Name>
      <CheckBox checked={selected} onChange={() => onSelect()} />
    </Container>
  );
}
