import React from 'react';
import { general } from '~/assets/imgs';
import Button from '~/components/Button';
import NavHeader from '~/components/NavHeader';
import { t } from '~/utils/i18n';
import {
  ImageContainer,
  Title,
  Container,
  TopContainer,
  TitleWrapper,
  BadgeText,
  BottomContainer,
  DescWrapper,
  Desc,
} from './style';
type RequestInstitutionSentLayoutProps = {
  onLogout: () => void;
  onGoToLobby: () => void;
};

export default function RequestInstitutionSent({
  onGoToLobby,
  onLogout,
}: RequestInstitutionSentLayoutProps): JSX.Element {
  return (
    <Container>
      <TopContainer>
        <ImageContainer source={general.institute_request_sent} />
        <NavHeader
          backButtonTestID={'buttonBack'}
          colorStyle={'light'}
          RightComponent={
            <Button
              testID="buttonLogout"
              size="sm"
              text={t('g.logout')}
              type="secondary-base"
              onPress={onLogout}
            />
          }
        />
        <TitleWrapper>
          <BadgeText>{t('requestInstitutionSent.badgeText')}</BadgeText>
          <Title>{t('requestInstitutionSent.title')}</Title>
        </TitleWrapper>
      </TopContainer>
      <BottomContainer>
        <DescWrapper>
          <Desc>{t('requestInstitutionSent.desc1')}</Desc>
          <Desc>{t('requestInstitutionSent.desc2')}</Desc>
        </DescWrapper>
        <Button
          size="2xl"
          flex
          testID={'buttonGoToLobby'}
          text={t('requestInstitutionSent.continueToLobby')}
          type="secondary-contrast"
          onPress={() => onGoToLobby()}
        />
      </BottomContainer>
    </Container>
  );
}
