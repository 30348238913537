import styled from 'styled-components/native';
import ImageComp from '~/components/Image';
import { TabScreenContainer } from '~/screens/style';

export const Container = styled(TabScreenContainer)`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const Image = styled(ImageComp)<{ noMargin?: boolean }>`
  width: 361px;
  height: 361px;
  margin-bottom: 30px;
`;

export const ImageContainer = styled.View`
  margin-top: 80px;
  align-items: center;
`;

export const ImageTitle = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 28px;
  width: 273px;
  margin-bottom: 20px;
  text-align: center;
`;

export const ImageSubTitle = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 18px;
  width: 273px;
  text-align: center;
`;
