/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation UpdateUserGroup
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  UpdateUserGroup,
  UpdateUserGroupVariables,
} from './types/UpdateUserGroup';
import { UPDATE_USER_GROUP } from './';

export function useUpdateUserGroupMutation(
  options?: MutationHookOptions<UpdateUserGroup, UpdateUserGroupVariables>,
): MutationTuple<UpdateUserGroup, UpdateUserGroupVariables> {
  return useMutation<UpdateUserGroup, UpdateUserGroupVariables>(
    UPDATE_USER_GROUP,
    options,
  );
}

export function createUpdateUserGroupMock({
  variables,
  data,
  error,
}: {
  variables?: UpdateUserGroupVariables;
  data?: UpdateUserGroup;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: UPDATE_USER_GROUP,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
