import styled from 'styled-components/native';
import Image from '~/components/Image';

const MAX_HEIGHT = 800;

export const Container = styled.View`
  flex: 1;
  width: ${({ theme }) => theme.dimensions.screenWidth}px;
`;

export const TopTitle = styled.Text`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  margin-top: ${({ theme }) => theme.safeAreaInsets.top + 8}px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 17px;
  text-align: center;
`;

export const ImageView = styled.View`
  width: ${({ theme }) => theme.dimensions.screenWidth}px;
`;

export const TopImage = styled(Image).attrs(({ theme }) => ({
  autoHeightFromWidth: theme.dimensions.screenWidth,
  resizeMode: 'contain',
}))`
  margin-top: ${({ theme }) => (theme.safeAreaInsets.top > 20 ? 0 : -60)}px;
  width: ${({ theme }) => theme.dimensions.screenWidth}px;
`;

export const ProfileHeader = styled.View`
  padding-top: ${({ theme }) => theme.safeAreaInsets.top + 32}px;
`;

export const Content = styled.View`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  flex: 1;
  padding-horizontal: 16px;
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: ${({ theme }) =>
    theme.dimensions.screenHeight > MAX_HEIGHT ? 28 : 22}px;
  line-height: ${({ theme }) =>
    theme.dimensions.screenHeight > MAX_HEIGHT ? 38 : 32}px;
`;

export const ContentText = styled.Text`
  margin-top: 16px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: ${({ theme }) =>
    theme.dimensions.screenHeight > MAX_HEIGHT ? 16 : 14}px;
  line-height: ${({ theme }) =>
    theme.dimensions.screenHeight > MAX_HEIGHT ? 22 : 20}px;
`;

export const SectionRow = styled.View`
  flex-direction: row;
  padding: 8px;
`;

export const SectionView = styled.View`
  padding-horizontal: 16px;
`;

export const ButtonView = styled.View`
  padding-top: ${({ theme }) =>
    theme.dimensions.screenHeight > MAX_HEIGHT ? 32 : 16}px;
  padding-bottom: 16px;
`;

export const ButtonRowView = styled(ButtonView)`
  flex-direction: row;
  gap: 8px;
`;
