import type { ComponentType } from 'react';
import { Platform, View } from 'react-native';
import styled from 'styled-components/native';

export const Text = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 14px;
`;

type IPdf = {
  height?: number;
  width?: number;
  source: { uri: string };
  singlePage: boolean;
  onPageChanged?: (page: number) => void;
  fitPolicy: number;
  enablePaging: boolean;
};

interface IPdfWithPage extends IPdf {
  page?: number;
}

let PdfComponent: ComponentType<IPdf> | typeof View = View;
if (Platform.OS !== 'web') {
  PdfComponent = require('react-native-pdf').default;
}

export const StyledPdf = styled(PdfComponent)<IPdf>`
  flex: 1;
  width: ${({ width }) => (width ? `${width}px` : '220px')};
  height: ${({ height }) => (height ? `${height}px` : '310px')};
  margin-left: -3px;
`;

export const PaginationContainer = styled.View`
  align-items: left;
  padding-top: 8px;
`;

export const PreviewContainer = styled(View)<IPdf>`
  width: ${({ width }) => (width ? `${width}px` : '220px')};
  height: ${({ height }) => (height ? `${height}px` : '310px')};
  overflow: hidden;
  position: absolute;
  right: -83px;
  opacity: 0.5;
`;

export const PreviewPdf = styled(PdfComponent)<IPdfWithPage>`
  width: ${({ width }) => (width ? `${width}px` : '220px')};
  height: ${({ height }) => (height ? `${height}px` : '310px')};
`;

export const PreviewWrapperContainer = styled.View``;

export const PreviewWrapper = styled.View`
  flex-direction: row;
`;
