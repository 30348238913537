/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation AdminMoveGroupToInstitute
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  AdminMoveGroupToInstitute,
  AdminMoveGroupToInstituteVariables,
} from './types/AdminMoveGroupToInstitute';
import { ADMIN_MOVE_GROUP_TO_INSTITUTE } from './';

export function useAdminMoveGroupToInstituteMutation(
  options?: MutationHookOptions<
    AdminMoveGroupToInstitute,
    AdminMoveGroupToInstituteVariables
  >,
): MutationTuple<
  AdminMoveGroupToInstitute,
  AdminMoveGroupToInstituteVariables
> {
  return useMutation<
    AdminMoveGroupToInstitute,
    AdminMoveGroupToInstituteVariables
  >(ADMIN_MOVE_GROUP_TO_INSTITUTE, options);
}

export function createAdminMoveGroupToInstituteMock({
  variables,
  data,
  error,
}: {
  variables?: AdminMoveGroupToInstituteVariables;
  data?: AdminMoveGroupToInstitute;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: ADMIN_MOVE_GROUP_TO_INSTITUTE,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
