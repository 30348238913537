import styled from 'styled-components/native';

export const Container = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  padding: 22px 16px;
  border: 1.5px solid ${({ theme }) => theme.color.base.c6};
  border-radius: 14px;
  width: 100%;
`;
interface ITextInput {
  height: number;
  fullScreen: boolean;
}
export const TextInput = styled.TextInput<ITextInput>`
  height: ${({ fullScreen, height }) => (fullScreen ? '285px' : `${height}px`)};
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 16px;
  color: ${({ theme }) => theme.color.base.c9};
`;

export const CharCountContainer = styled.View`
  position: absolute;
  bottom: 16px;
  right: 16px;
`;

interface ICharCount {
  exceed: boolean;
}
export const CharCount = styled.Text<ICharCount>`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 12px;
  line-height: 20px;
  color: ${({ exceed, theme }) =>
    exceed ? theme.color.error : theme.color.base.c9};
`;
