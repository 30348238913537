import styled from 'styled-components/native';
import ButtonIcon from '../ButtonIcon';

export const Container = styled.View``;

export const ButtonContainer = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})`
  background-color: ${({ theme }) => theme.color.base.c0};
  border-width: 1px;
  border-color: ${({ theme }) => theme.color.base.c3};
  padding: 8px 16px;
  border-radius: 7px;
  flex-direction: row;
  align-items: center;
`;

export const Text = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 14px;
  line-height: 20px;
`;

export const ArrowButtonIcon = styled(ButtonIcon).attrs(({ theme }) => ({
  color: theme.color.base.c6,
  size: 16,
  name: 'chevron-down',
}))`
  margin-left: 8px;
`;

export const PickerContainer = styled.View`
  position: absolute;
  z-index: 1;
  margin-top: 50px;
  background-color: ${({ theme }) => theme.color.base.c0};
  border-width: 1px;
  border-color: ${({ theme }) => theme.color.base.c3};
  border-radius: 7px;
  align-items: flex-start;
`;

export const ButtonContent = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})`
  padding: 8px 16px;
`;

export const ShowButton = styled.TouchableOpacity`
  flex: 1;
`;

interface IDropdownContainer {
  layout: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
}

export const DropdownContainer = styled.View<IDropdownContainer>`
  position: 'absolute';
  top: ${({ layout }) => layout.y + layout.height - 50}px;
  left: ${({ layout }) => layout.x}px;
  width: ${({ layout }) => layout.width}px;
`;

export const DataScroll = styled.ScrollView`
  max-height: 300;
`;
