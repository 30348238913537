import React, { useState } from 'react';
import Button from '~/components/Button';
import InputField from '~/components/InputField';
import NavHeader from '~/components/NavHeader';
import SectionHeader from '~/components/SectionHeader';
import { t } from '~/utils/i18n';
import PhotoCategoryCell from './PhotoCategoryCell';
import {
  ButtonContainer,
  Container,
  InspirationalList,
  RecommendedList,
  SectionContainer,
} from './style';

type ProfilePageAddPhotoCategoryLayoutProps = {
  onBack: () => void;
  text?: string;
  recommendedPhotoCategories: { id: string; text: string }[];
  inspirationalPhotoCategories: { id: string; text: string }[];
  onAddPhotoCategory: (text: string) => void;
};

export default function ProfilePageAddPhotoCategory({
  onBack,
  recommendedPhotoCategories,
  inspirationalPhotoCategories,
  text = '',
  onAddPhotoCategory,
}: ProfilePageAddPhotoCategoryLayoutProps): JSX.Element {
  const [photoCategory, setPhotoCategory] = useState<string>(text);

  const renderItem = ({
    item: profilePagePhotoCategory,
  }: {
    item: { id: string; text: string };
    index: number;
  }) => (
    <PhotoCategoryCell
      profilePagePhotoCategory={profilePagePhotoCategory}
      onPress={() => setPhotoCategory(profilePagePhotoCategory.text)}
      onAdd={() => setPhotoCategory(profilePagePhotoCategory.text)}
    />
  );

  return (
    <Container>
      <NavHeader
        backButtonTestID={'buttonBack'}
        title={t('screens.profilePageAddPhotoCategory')}
        onBackPress={onBack}
      />
      <InspirationalList<React.ElementType>
        data={inspirationalPhotoCategories}
        ListHeaderComponent={
          <>
            <SectionContainer>
              <InputField
                testID={'inputPhotoCategoryInput'}
                value={photoCategory}
                label={t('profilePageAddPhotoCategory.inputPhotoCategoryLabel')}
                onChangeText={(value) => setPhotoCategory(value)}
              />
            </SectionContainer>
            <SectionContainer>
              <SectionHeader
                title={t('profilePageAddPhotoCategory.recommendationSection')}
                sub
              />
            </SectionContainer>
            <RecommendedList<React.ElementType>
              data={recommendedPhotoCategories}
              horizontal
              showsHorizontalScrollIndicator={false}
              renderItem={renderItem}
              keyExtractor={(item: { id: string; text: string }) =>
                `rq${item.id}`
              }
            />
            <SectionContainer>
              <SectionHeader
                title={t('profilePageAddPhotoCategory.inspirationSection')}
                sub
              />
            </SectionContainer>
          </>
        }
        renderItem={renderItem}
        keyExtractor={(item: { id: string; text: string }) => `iq${item.id}`}
      />
      <ButtonContainer>
        <Button
          testID={'buttonAddPhotoCategory'}
          text={t('profilePageAddPhotoCategory.addPhotoCategory')}
          flex
          onPress={() => onAddPhotoCategory(photoCategory)}
        />
      </ButtonContainer>
    </Container>
  );
}
