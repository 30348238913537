/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query Me
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { Me } from './types/Me';
import { ME } from './';

export function useMeQuery(
  options?: QueryHookOptions<Me, {}>,
): QueryResult<Me, {}> {
  return useQuery<Me, {}>(ME, options);
}

export function useMeLazyQuery(
  options?: LazyQueryHookOptions<Me, {}>,
): QueryTuple<Me, {}> {
  return useLazyQuery<Me, {}>(ME, options);
}

export function createMeMock({
  variables,
  data,
  error,
}: {
  variables?: undefined;
  data?: Me;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: ME,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
