/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query Regions
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { Regions, RegionsVariables } from './types/Regions';
import { REGIONS } from './';

export function useRegionsQuery(
  options?: QueryHookOptions<Regions, RegionsVariables>,
): QueryResult<Regions, RegionsVariables> {
  return useQuery<Regions, RegionsVariables>(REGIONS, options);
}

export function useRegionsLazyQuery(
  options?: LazyQueryHookOptions<Regions, RegionsVariables>,
): QueryTuple<Regions, RegionsVariables> {
  return useLazyQuery<Regions, RegionsVariables>(REGIONS, options);
}

export function createRegionsMock({
  variables,
  data,
  error,
}: {
  variables?: RegionsVariables;
  data?: Regions;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: REGIONS,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
