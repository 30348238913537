/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query Colors
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { Colors, ColorsVariables } from './types/Colors';
import { COLORS } from './';

export function useColorsQuery(
  options?: QueryHookOptions<Colors, ColorsVariables>,
): QueryResult<Colors, ColorsVariables> {
  return useQuery<Colors, ColorsVariables>(COLORS, options);
}

export function useColorsLazyQuery(
  options?: LazyQueryHookOptions<Colors, ColorsVariables>,
): QueryTuple<Colors, ColorsVariables> {
  return useLazyQuery<Colors, ColorsVariables>(COLORS, options);
}

export function createColorsMock({
  variables,
  data,
  error,
}: {
  variables?: ColorsVariables;
  data?: Colors;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: COLORS,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
