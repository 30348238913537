/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation UpdateProfilePagePhotoCategory
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  UpdateProfilePagePhotoCategory,
  UpdateProfilePagePhotoCategoryVariables,
} from './types/UpdateProfilePagePhotoCategory';
import { UPDATE_PROFILE_PAGE_PHOTO_CATEGORY } from './';

export function useUpdateProfilePagePhotoCategoryMutation(
  options?: MutationHookOptions<
    UpdateProfilePagePhotoCategory,
    UpdateProfilePagePhotoCategoryVariables
  >,
): MutationTuple<
  UpdateProfilePagePhotoCategory,
  UpdateProfilePagePhotoCategoryVariables
> {
  return useMutation<
    UpdateProfilePagePhotoCategory,
    UpdateProfilePagePhotoCategoryVariables
  >(UPDATE_PROFILE_PAGE_PHOTO_CATEGORY, options);
}

export function createUpdateProfilePagePhotoCategoryMock({
  variables,
  data,
  error,
}: {
  variables?: UpdateProfilePagePhotoCategoryVariables;
  data?: UpdateProfilePagePhotoCategory;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: UPDATE_PROFILE_PAGE_PHOTO_CATEGORY,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
