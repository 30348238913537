import React from 'react';
import Avatar, { AvatarProps } from '../Avatar';
import { AvatarRow, AvatarWrapper } from './style';

type AvatarStackedRowProps = {
  avatars: AvatarProps[];
  maxStack?: number;
  size?: number;
};

export default function AvatarStackedRow({
  avatars,
  maxStack = 4,
  size = 48,
}: AvatarStackedRowProps): JSX.Element {
  const hasOverflow = avatars.length > maxStack;
  const endSliceAt = hasOverflow ? maxStack : avatars.length;
  const avatarsToDisplay = avatars.slice(0, endSliceAt);

  return (
    <AvatarRow>
      {avatarsToDisplay.map((avatar, index) => (
        <AvatarWrapper key={`avatar-${index}`} orderNo={index}>
          <Avatar {...avatar} size={size} />
        </AvatarWrapper>
      ))}
      {hasOverflow && (
        <AvatarWrapper orderNo={endSliceAt}>
          <Avatar icon="users-plus" size={size} />
        </AvatarWrapper>
      )}
    </AvatarRow>
  );
}
