import { Platform } from 'react-native';
import styled from 'styled-components/native';

const isMobile = Platform.OS !== 'web';

export const Container = styled.View`
  padding-vertical: 16px;
`;

export const Title = styled.Text`
  margin-bottom: 16px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) =>
    isMobile ? theme.color.base.c7 : theme.color.base.c9};
  font-size: 16px;
  line-height: 22px;
`;

export const Content = styled.View`
  flex-direction: row;
  background-color: ${({ theme }) => theme.color.base.c1};
  border-radius: 5px;
`;

interface ITouchableItem {
  selected: boolean;
}
export const TouchableItem = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})<ITouchableItem>`
  flex: 1;
  border-radius: 5px;
  background-color: ${({ theme, selected }) =>
    selected ? theme.color.brand_02 : theme.color.base.c1};
  padding-vertical: 8px;
  ${({ theme, selected }) =>
    selected && `box-shadow: -3px 3px 4px ${theme.color.base.c3}`}
`;

export const ItemText = styled.Text<ITouchableItem>`
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme, selected }) =>
    selected ? theme.color.base.c0 : theme.color.base.c6};
  font-size: 16px;
  line-height: 24px;
`;
