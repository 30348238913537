import { gql } from '@apollo/client';
import { USER_GROUP_FIELDS } from './../group';
import { USER_FIELDS } from './../user';

export const AUTH_USER_FIELDS = gql`
  ${USER_FIELDS}
  fragment AuthUserFields on UserNode {
    ...UserFields
    email
    phoneCountryCode
    phone
    emailValidated
    communicationLanguage
    isSuperuser
    countryOfResidence
    inviteCode
    externalTokens {
      intercom
    }
  }
`;

export const AUTH_USER_GROUP_FIELDS = gql`
  ${USER_GROUP_FIELDS}
  ${AUTH_USER_FIELDS}
  fragment AuthUserGroupFields on UserGroupNode {
    ...UserGroupFields
    user {
      ...AuthUserFields
    }
    isYearbookTeamMember
    isCoreTeamMember
  }
`;

export const ME = gql`
  ${AUTH_USER_FIELDS}
  query Me {
    me {
      ...AuthUserFields
    }
  }
`;

export const AUTH_USER_GROUP = gql`
  ${AUTH_USER_GROUP_FIELDS}
  query AuthUserGroup($id: ID!) {
    userGroup(id: $id) {
      ...AuthUserGroupFields
    }
  }
`;

export const CREATE_USER = gql`
  ${AUTH_USER_FIELDS}
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        ...AuthUserFields
      }
    }
  }
`;

export const CREATE_TOKEN = gql`
  mutation CreateToken($input: ObtainJSONWebTokenInput!) {
    createToken(input: $input) {
      token
      refreshToken
    }
  }
`;

export const UPDATE_ME = gql`
  ${AUTH_USER_FIELDS}
  mutation UpdateMe($input: UpdateMeInput!) {
    updateMe(input: $input) {
      errors {
        field
        messages
      }
      user {
        ...AuthUserFields
      }
      token
      refreshToken
    }
  }
`;

export const FORGET_PASSWORD = gql`
  mutation ForgetPassword($input: ForgetPasswordInput!) {
    forgetPassword(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const DELETE_USER = gql`
  ${AUTH_USER_FIELDS}
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      user {
        ...AuthUserFields
      }
    }
  }
`;

export const USER_EXISTS = gql`
  query UserExists(
    $email: String!
    $phoneCountryCode: String!
    $phone: String!
  ) {
    userExists(
      email: $email
      phoneCountryCode: $phoneCountryCode
      phone: $phone
    ) {
      emailExists
      phoneExists
    }
  }
`;
