import React, { useState, useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components/native';
import Avatar from '~/components/Avatar';
import SearchInputField from '~/components/SearchInputField/index';
import SwitchSelector, { SwitchItem } from '~/components/SwitchSelector';
import WebModal, { WebModalHandler } from '~/components/WebModal';
import { ModalTitle } from '~/components/WebModalSelector/style';
import WebSelector from '~/components/WebSelector';
import { Spinner } from '~/components/common/style.web';
import { DATABASE_ROWS_PER_PAGE_OPTIONS } from '~/data/constants';
import { AppLocale } from '~/data/models/app';
import { AdminUser } from '~/data/models/user';
import { UpgradeUserInput } from '~/screens/Admin/Settings';
import usePagination from '~/utils/hooks/usePagination';
import { t } from '~/utils/i18n';
import { UserTypeBadgeColorMap } from '~/utils/types/adminSettings';
import Badge from '../Badge';
import Button from '../Button';
import Paginator from '../Paginator';
import StepSizePicker from '../StepSizePicker';
import { VSpacer } from '../common/style.web';
import {
  Container,
  TableCell,
  TableRow,
  UserName,
  CellText,
  AgentsTable,
  HeaderSection,
  Title,
  CampaingWrapper,
  PaginationWrapper,
  ButtonWrapper,
  CellBadge,
  TableCellRemove,
  RemoveText,
  TableCellUpgrade,
  MobileBadgeWrapper,
  ModalSubTitle,
  ModalContent,
  UpgradeText,
} from './style';

type SettingsDatabaseProps = {
  agents: AdminUser[];
  users: AdminUser[];
  agentsTotalCount: number;
  loading: boolean;
  selectedLocale?: AppLocale;
  onUserUpgrade: (id: string) => void;
  onUserDowngrade: (id: string) => void;
  onAddAgent: (data: UpgradeUserInput) => void;
  onRemoveAgent: (id: string) => void;
  setFilterQuery: (value: string) => void;
  filterQuery: string;
  setCurrentActiveTab: (tab: number) => void;
  setUserSearchValue: (query: string) => void;
  rowsPerPage?: number;
  setRowsPerPage?: (rowsPerPage: number) => void;
};

type UserRoles = 'agent' | 'admin';

const roles: SwitchItem[] = [
  {
    key: 'agent',
    title: 'Agent',
  },
  {
    key: 'admin',
    title: 'Admin',
  },
];

export default function SettingsDatabase({
  agents,
  users,
  agentsTotalCount,
  loading,
  selectedLocale,
  onUserUpgrade,
  onUserDowngrade,
  onAddAgent,
  onRemoveAgent,
  setFilterQuery,
  filterQuery,
  setCurrentActiveTab,
  setUserSearchValue,
  rowsPerPage = DATABASE_ROWS_PER_PAGE_OPTIONS[0],
  setRowsPerPage = () => {
    /* do nothing */
  },
}: SettingsDatabaseProps): JSX.Element {
  const [addAgentRole, setAddAgentRole] = useState<UserRoles>('agent');
  const [selectedUser, setSelectedUser] = useState<string>('');
  const addAgentModalRef = useRef<WebModalHandler>(null);

  const theme = useTheme();
  const isDesktop = useMediaQuery({ minWidth: theme.breakpoints.desktopMin });
  const isMobile = !isDesktop;

  const {
    totalNumberOfPages,
    currentPage,
    allowNextPage,
    allowPrevPage,
    incrementPage,
    decrementPage,
    resetPagination,
    updatePage,
  } = usePagination({
    itemsPerPage: rowsPerPage,
    initialPage: 1,
    totalItems: agentsTotalCount,
  });

  useEffect(() => {
    setCurrentActiveTab(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCurrentActiveTab(1);
    resetPagination();
  }, [selectedLocale, filterQuery, rowsPerPage]);

  const handleOnAddAgent = ({
    selectedUser,
    addAgentRole,
  }: {
    selectedUser: string;
    addAgentRole: string;
  }) => {
    const data = {
      id: selectedUser,
      role: addAgentRole,
    };
    onAddAgent(data);
    addAgentModalRef.current?.close();
  };

  const renderTableRow = ({ item: user }: { item: AdminUser }) => {
    const userType = user.isSuperuser
      ? t('settingsDatabase.admin')
      : t('settingsDatabase.agent');

    return (
      <TableRow testID={`AgentRow:${user.id}`}>
        <Avatar uri={user.avatar} />
        <TableCell>
          <UserName>{`${user.firstName} ${user.lastName}`}</UserName>
          <CellText>{user.email}</CellText>
          {isMobile && (
            <MobileBadgeWrapper>
              <Badge
                text={userType}
                colorTheme={UserTypeBadgeColorMap[userType]}
                size="small"
              />
            </MobileBadgeWrapper>
          )}
        </TableCell>
        <TableCell>
          <CellBadge isDesktop={isDesktop}>
            {isDesktop && (
              <Badge
                text={userType}
                colorTheme={UserTypeBadgeColorMap[userType]}
              />
            )}
            <TableCellUpgrade
              testID={`UpgradeAgent::${user.id}`}
              onPress={() => {
                user.isSuperuser
                  ? onUserDowngrade(user.id)
                  : onUserUpgrade(user.id);
              }}
            >
              <UpgradeText>
                {user.isSuperuser
                  ? t('settingsDatabase.downgrade')
                  : t('settingsDatabase.upgrade')}
              </UpgradeText>
            </TableCellUpgrade>
            <TableCellRemove
              testID={`RemovePermissions::${user.id}`}
              onPress={() => onRemoveAgent(user.id)}
            >
              <RemoveText isDesktop={isDesktop}>
                {t('settingsDatabase.remove')}
              </RemoveText>
            </TableCellRemove>
          </CellBadge>
        </TableCell>
      </TableRow>
    );
  };

  const renderTableFooter = () => (
    <PaginationWrapper>
      <StepSizePicker
        stepSize={rowsPerPage}
        onStepSizeChange={setRowsPerPage}
        options={DATABASE_ROWS_PER_PAGE_OPTIONS}
        title={t('adminPanelSearch.entries')}
      />
      <Paginator
        allowNext={allowNextPage}
        allowPrev={allowPrevPage}
        currentPage={currentPage}
        onPrevClick={decrementPage}
        onNextClick={incrementPage}
        numberOfPages={totalNumberOfPages}
        onPageClick={updatePage}
      />
    </PaginationWrapper>
  );

  const renderSearchBar = () => (
    <SearchInputField
      iconName={'search-md'}
      label={t('settingsPanel.search')}
      value={filterQuery}
      onChangeText={(query) => setFilterQuery(query)}
      width={isDesktop ? 300 : undefined}
      height={34}
    />
  );

  const tableTitle = `${agentsTotalCount} ${t('settingsPanel.databaseName')}`;

  return (
    <Container>
      <HeaderSection>
        <Title isDesktop={isDesktop}>{tableTitle}</Title>
        <CampaingWrapper>
          {isDesktop && renderSearchBar()}
          <ButtonWrapper>
            <Button
              testID={'buttonAddAgent'}
              text={t('settingsPanel.addAgent')}
              size={'sm'}
              type={'secondary-base'}
              icon={'left'}
              iconName={'plus'}
              flex={true}
              onPress={() => addAgentModalRef.current?.open()}
            />
          </ButtonWrapper>
        </CampaingWrapper>
      </HeaderSection>
      {isMobile && renderSearchBar()}
      <VSpacer />
      {loading ? (
        <Spinner testID="agentsSpinner" />
      ) : (
        <AgentsTable<React.ElementType>
          data={agents}
          renderItem={renderTableRow}
          ListFooterComponent={renderTableFooter}
        />
      )}

      <WebModal ref={addAgentModalRef}>
        <ModalContent isDesktop={isDesktop}>
          <ModalTitle>{t('settingsPanel.addAnAgent')}</ModalTitle>
          <ModalSubTitle>{t('settingsPanel.selectUser')}</ModalSubTitle>
          <WebSelector
            testID={'addAgentUserInput'}
            label={t('settingsPanel.selectUser')}
            items={users?.map((user) => {
              return {
                id: user.id,
                name: user.email,
              };
            })}
            onItemSelect={(item) => setSelectedUser(item.id)}
            onSearch={setUserSearchValue}
            iconName={'add_user'}
            isEmptyText={t('settingsPanel.emailAddress')}
          />
          <ModalSubTitle>{t('settingsPanel.role')}</ModalSubTitle>
          <SwitchSelector
            items={roles}
            selectedItemKey={addAgentRole}
            onSelectedItem={(role: string) =>
              setAddAgentRole(role as UserRoles)
            }
          />
          <Button
            testID={'addAgentConfirmButton'}
            text={t('settingsPanel.addAgent')}
            state={selectedUser ? 'default' : 'disabled'}
            onPress={() => handleOnAddAgent({ selectedUser, addAgentRole })}
          />
        </ModalContent>
      </WebModal>
    </Container>
  );
}
