import React from 'react';
import { flags } from '~/assets/imgs';
import { Country } from '~/data/models/marketProfile';
import { Container, FlagImage, ArrowView, ArrowIcon } from './style';

type FlagButtonProps = {
  testID?: string;
  country: Country;
  disabled?: boolean;
  onPress?: () => void;
};

export default function FlagButton({
  testID,
  country,
  disabled,
  onPress,
}: FlagButtonProps): JSX.Element {
  return (
    <Container
      testID={testID}
      disabled={disabled}
      onPress={() => !disabled && onPress && onPress()}
    >
      <FlagImage testID={`flagImage${country}`} source={flags[country]} />
      <ArrowView>{!disabled ? <ArrowIcon /> : null}</ArrowView>
    </Container>
  );
}
