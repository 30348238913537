import React from 'react';
import { getImageFromLibrary } from '~/components/ImagePicker';
import NavHeader from '~/components/NavHeader';
import { CollagesAlbum, CollagesPhoto } from '~/data/models/yearbook';
import AlbumDetailFooter from './AlbumDetailFooter';
import {
  Container,
  HeaderRightContainer,
  EditButtonIcon,
  AddButtonIcon,
  PhotoList,
  PhotoCard,
  PhotoImage,
  LoadingView,
  RefreshControl,
  NUM_COLUMNS,
  Loading,
} from './style';

export type LocalCollagesPhoto = {
  __typename: 'LocalCollagesPhoto';
  id: string;
  photoUri: string;
};

export type CollagesAlbumLayoutProps = {
  collagesAlbumName: string;
  photosCount: number;
  collagesPhotos: CollagesPhoto[];
  localCollagesPhotos: LocalCollagesPhoto[];
  loading: boolean;
  viewOnly?: boolean;
  viewAll?: boolean;
  addPhotoEnabled?: boolean;
  onBack: () => void;
  onEditAlbum: () => void;
  onUploadImages: (imgUris: string[]) => void;
  onSelectedPhoto: (photoId: string) => void;
  onRefresh: () => void;
  onLoadMore: () => void;
  isEditableByAuthUser: boolean;
};

export default function CollagesAlbumDetail({
  collagesAlbumName,
  photosCount,
  collagesPhotos,
  localCollagesPhotos,
  loading,
  viewOnly,
  viewAll,
  addPhotoEnabled,
  onBack,
  onEditAlbum,
  onUploadImages,
  onSelectedPhoto,
  onRefresh,
  onLoadMore,
  isEditableByAuthUser,
}: CollagesAlbumLayoutProps): JSX.Element {
  const renderItem = ({
    item,
  }: {
    item: CollagesPhoto | LocalCollagesPhoto;
  }) => {
    if (item.__typename === 'LocalCollagesPhoto') {
      const localCollagesPhoto = item as LocalCollagesPhoto;
      return (
        <PhotoCard testID={`LocalImageCard:${localCollagesPhoto.id}`}>
          <PhotoImage source={{ uri: localCollagesPhoto.photoUri }} />
          <LoadingView>
            <Loading color={'white'} />
          </LoadingView>
        </PhotoCard>
      );
    }
    const collagesPhoto = item as CollagesPhoto;
    return (
      <PhotoCard
        testID={`ImageCard:${collagesPhoto.id}`}
        onPress={() => onSelectedPhoto(collagesPhoto.id)}
      >
        <PhotoImage source={{ uri: collagesPhoto.photo }} />
      </PhotoCard>
    );
  };

  const onPickImage = async () => {
    const imgUris = await getImageFromLibrary({ maxFiles: 10 });
    onUploadImages(imgUris);
  };

  return (
    <Container>
      <NavHeader
        backButtonTestID={'buttonBack'}
        title={collagesAlbumName}
        RightComponent={
          !viewOnly && addPhotoEnabled ? (
            <HeaderRightContainer>
              {isEditableByAuthUser && (
                <EditButtonIcon
                  testID={'buttonEditAlbum'}
                  onPress={onEditAlbum}
                />
              )}
              <AddButtonIcon testID={'buttonAddPhoto'} onPress={onPickImage} />
            </HeaderRightContainer>
          ) : null
        }
        onBackPress={onBack}
      />
      <PhotoList<React.ElementType>
        testID={'photoList'}
        data={[...localCollagesPhotos, ...collagesPhotos]}
        refreshControl={
          <RefreshControl refreshing={loading} onRefresh={onRefresh} />
        }
        renderItem={renderItem}
        keyExtractor={(item: CollagesAlbum) => item.id}
        numColumns={NUM_COLUMNS}
        ListFooterComponent={
          <AlbumDetailFooter
            addPhotoEnabled={addPhotoEnabled}
            loading={loading}
            viewAll={viewAll}
            photosCount={photosCount}
            ownPhotosCount={collagesPhotos.length}
            localPhotosCount={localCollagesPhotos.length}
            onAddPhoto={onPickImage}
            onPickImage={onPickImage}
          />
        }
        onEndReachedThreshold={0.3}
        onEndReached={() => onLoadMore()}
      />
    </Container>
  );
}
