import styled from 'styled-components/native';

export const InputContent = styled.View``;

export const TouchView = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})`
  padding-left: 6px;
`;

interface IForgotComp {
  isWeb: boolean;
}

export const ForgotPasswordView = styled.View<IForgotComp>`
  margin-vertical: ${({ isWeb }) => (isWeb ? 32 : 8)}px;
  margin-left: ${({ isWeb }) => (isWeb ? 0 : 20)}px;
  flex-direction: row;
  gap: 3px;
`;

export const ForgotPasswordTouchView = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})``;

const ForgotPasswordTextBase = styled.Text<IForgotComp>`
  font-size: ${({ isWeb }) => (isWeb ? 16 : 14)}px;
  line-height: ${({ isWeb }) => (isWeb ? 24 : 16)}px;
`;

export const ForgotPasswordText = styled(ForgotPasswordTextBase)`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c10};
`;

export const ForgotPasswordLinkText = styled(ForgotPasswordTextBase)`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.brand_02};
  text-decoration-line: underline;
`;
