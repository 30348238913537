import * as React from 'react';
import { useCallback } from 'react';
import {
  DrawerContentScrollView,
  DrawerContentComponentProps,
} from '@react-navigation/drawer';
import {
  CommonActions,
  ParamListBase,
  RouteProp,
} from '@react-navigation/native';
import { TouchableOpacity } from 'react-native';
import Avatar from '~/components/Avatar';
import { IconStyledName } from '~/components/IconStyled';
import InstituteRequestNotification from '~/components/InstituteRequestNotification';
import { useAuth } from '~/context/auth';
import { DefaultAvatar } from '~/data/models/custom';
import { AdminStackParamList } from '~/navigation/types';
import { t } from '~/utils/i18n';
import { getRouteMeta } from '../utils/getRouteMeta';
import {
  Logo,
  DrawerItem,
  DrawerIcon,
  DrawerLabel,
  CloseButtonIcon,
  DrawerStyledIcon,
  StyledIcon,
  AccountDetailsContainer,
  UpperWrapper,
  TopMenu,
  BottomMenu,
  UserDetails,
  UserName,
  UserEmail,
  BottomWrapper,
  NotificationWrapper,
} from './style';

type AdminInfo = {
  fullName?: string;
  email?: string;
};

type RouteNames = keyof AdminStackParamList;

interface CustomDrawerContentProps extends DrawerContentComponentProps {
  isMobile: boolean;
  adminInfo: AdminInfo;
  requestsNumber?: number;
  avatar: DefaultAvatar;
}
const adminStyledDrawerIcons: Partial<
  Record<keyof AdminStackParamList, IconStyledName>
> = {
  SearchStack: 'search',
  InstitutesStack: 'school',
  AdsStack: 'flame',
  ReportingStack: 'poll',
  SupportStack: 'support_base',
  SettingsStack: 'settings',
};

const TOP_MENU_ITEMS: RouteNames[] = [
  'SearchStack',
  'InstitutesStack',
  'AdsStack',
  'ReportingStack',
];
const BOTTOM_MENU_ITEMS: RouteNames[] = ['SupportStack', 'SettingsStack'];

export default function CustomDrawerContent(
  props: CustomDrawerContentProps,
): JSX.Element {
  const { state, navigation, isMobile, adminInfo, requestsNumber, avatar } =
    props;

  const { onLogout } = useAuth();

  const renderItem = useCallback(
    (route: RouteProp<ParamListBase, string>, index: number) => {
      const {
        isFocused: isActive,
        routeName,
        iconName,
        label,
      } = getRouteMeta(state, route, index);
      let drawerIcon = <DrawerIcon name={iconName} isActive={isActive} />;

      const styledIcon =
        adminStyledDrawerIcons[routeName as keyof AdminStackParamList];
      if (routeName in adminStyledDrawerIcons && styledIcon) {
        drawerIcon = (
          <DrawerStyledIcon isActive={isActive}>
            <StyledIcon name={styledIcon} isActive={isActive} size={22} />
          </DrawerStyledIcon>
        );
      }

      const onPress = useCallback(() => {
        navigation.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [{ name: routeName }],
          }),
        );
      }, [navigation]);

      return (
        <DrawerItem key={routeName} onPress={onPress} isActive={isActive}>
          {drawerIcon}
          <DrawerLabel isActive={isActive}>{label}</DrawerLabel>
        </DrawerItem>
      );
    },
    [state, navigation],
  );

  return (
    <DrawerContentScrollView {...props} contentContainerStyle={{ flexGrow: 1 }}>
      <UpperWrapper>
        {!isMobile && <Logo>{t('gradoo')}</Logo>}
        {isMobile && <CloseButtonIcon onPress={navigation.closeDrawer} />}
        <TopMenu>
          {state.routes.map(
            (route, idx) =>
              TOP_MENU_ITEMS.includes(route.name as RouteNames) &&
              renderItem(route, idx),
          )}
        </TopMenu>
      </UpperWrapper>

      <BottomWrapper>
        <BottomMenu>
          {state.routes.map(
            (route, idx) =>
              BOTTOM_MENU_ITEMS.includes(route.name as RouteNames) &&
              renderItem(route, idx),
          )}
        </BottomMenu>
        {requestsNumber ? (
          <NotificationWrapper>
            <InstituteRequestNotification
              title={t('instituteRequest.notificationTitle')}
              description={t('instituteRequest.notificationDescription', {
                number: requestsNumber,
              })}
              cancelButtonText={t('instituteRequest.notificationDismiss')}
              acceptButtonText={t('instituteRequest.notificationAccept')}
              width={230}
              onAcceptPress={() => {
                navigation.navigate('InstitutesStack');
              }}
            />
          </NotificationWrapper>
        ) : null}
        <AccountDetailsContainer>
          <Avatar size={40} uri={avatar.avatar} />
          <UserDetails>
            <UserName>{adminInfo?.fullName}</UserName>
            <UserEmail>{adminInfo?.email}</UserEmail>
          </UserDetails>
          <TouchableOpacity onPress={onLogout}>
            <StyledIcon name={'logout'} size={20} isActive={false} />
          </TouchableOpacity>
        </AccountDetailsContainer>
      </BottomWrapper>
    </DrawerContentScrollView>
  );
}
