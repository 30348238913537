import styled from 'styled-components/native';

export const Container = styled.View``;

export const Content = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  padding: 24px 16px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

export const Title = styled.Text`
  margin-bottom: 16px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c10};
  font-size: 18px;
  line-height: 22px;
`;
