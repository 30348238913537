import React, { useState } from 'react';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { CountryType } from '~/components/CountryPicker';
import Snackbar from '~/components/Snackbar';
import { InstituteType } from '~/data/models/institute';
import { MarketProfile } from '~/data/models/marketProfile';
import {
  CreateInstituteInput,
  InstitutesInstituteCountryChoices,
} from '~/data/operations/global';
import { useCreateInstituteMutation } from '~/data/operations/institute/createInstitute';
import { useInstituteTypesQuery } from '~/data/operations/institute/instituteTypes';
import { useMarketProfilesQuery } from '~/data/operations/marketProfile/marketProfiles';
import {
  AdminStackParamList,
  InstituteStackParamList,
} from '~/navigation/types';
import InstituteAddLayout from './layout';

type InstituteAddNavProp = StackNavigationProp<
  InstituteStackParamList,
  'InstituteAdd'
>;

export default function InstituteAdd(): JSX.Element {
  const navigation = useNavigation<InstituteAddNavProp>();
  const { openDrawer } =
    useNavigation<DrawerNavigationProp<AdminStackParamList>>();

  const [createInstitute, { loading: createInstituteLoading }] =
    useCreateInstituteMutation();
  const [countrySelected, setCountrySelected] = useState<
    CountryType | undefined
  >(undefined);

  const { data: instituteTypesData } = useInstituteTypesQuery({
    variables: {
      first: 50,
      country: countrySelected?.country as InstitutesInstituteCountryChoices,
    },
  });

  const { data: marketProfileData } = useMarketProfilesQuery();

  const marketProfiles: MarketProfile[] =
    (marketProfileData?.marketProfiles?.edges
      .map((edge) => edge?.node)
      .filter((node) => Boolean(node)) as MarketProfile[]) || [];

  const instituteTypes: InstituteType[] =
    (instituteTypesData?.instituteTypes?.edges
      .map((edge) => edge?.node)
      .filter((node) => Boolean(node)) as InstituteType[]) || [];

  const onCreateInstitute = async (institute: CreateInstituteInput) => {
    try {
      const { data } = await createInstitute({
        variables: {
          input: {
            name: institute.name,
            street: institute.street,
            zip: institute.zip,
            city: institute.city,
            isPrivate: institute.isPrivate,
            country: institute.country,
            location: institute.location,
            marketProfileName: institute.marketProfileName,
            instituteTypeName: institute.instituteTypeName,
            website: institute.website,
          },
        },
      });

      const message = data?.createInstitute?.message || ''; // Provide a default value in case message is undefined.
      if (message) {
        Snackbar.show(message);
      }
      return true;
    } catch (e) {
      const errorMessage =
        e instanceof Error ? e.message : 'An error occurred.';
      Snackbar.show(errorMessage);
      return false;
    }
  };

  return (
    <InstituteAddLayout
      loading={createInstituteLoading}
      onBack={() => navigation.navigate('Institutes')}
      openDrawer={openDrawer}
      onCreateInstitute={(intitute) => onCreateInstitute(intitute)}
      instituteType={instituteTypes}
      marketProfiles={marketProfiles}
      countrySelected={countrySelected}
      setCountrySelected={setCountrySelected}
    />
  );
}
