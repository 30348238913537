/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query Modules
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { Modules, ModulesVariables } from './types/Modules';
import { MODULES } from './';

export function useModulesQuery(
  options?: QueryHookOptions<Modules, ModulesVariables>,
): QueryResult<Modules, ModulesVariables> {
  return useQuery<Modules, ModulesVariables>(MODULES, options);
}

export function useModulesLazyQuery(
  options?: LazyQueryHookOptions<Modules, ModulesVariables>,
): QueryTuple<Modules, ModulesVariables> {
  return useLazyQuery<Modules, ModulesVariables>(MODULES, options);
}

export function createModulesMock({
  variables,
  data,
  error,
}: {
  variables?: ModulesVariables;
  data?: Modules;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: MODULES,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
