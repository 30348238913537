import React, { useEffect, useState } from 'react';
import { OptionsInfo } from '~/components/Dropdown';
import generateGraduationYears from '~/components/GroupFilteringBlock/helpers';
import { COUNTRIES } from '~/data/constants';
import { useSignupsQuery } from '~/data/operations/campaign/signups';
import { useStatisticsQuery } from '~/data/operations/campaign/statistics';
import { useGraduationTypesQuery } from '~/data/operations/group/graduationTypes';
import { useInstituteTypesQuery } from '~/data/operations/institute/instituteTypes';
import { t } from '~/utils/i18n';
import {
  aggregateMonthlyCounts,
  calculateGroupPercentage,
  calculatePercentageChange,
  formatNumberWithDotSeparator,
} from '../utils/helper';
import ReportingLayout from './layout';

export type filterOptions = {
  country?: string[];
  graduationYear?: number[];
  graduationType?: string[] | null;
  instituteType?: string[] | null;
};

export type SelectionsState = {
  selections1: filterOptions;
  selections2: filterOptions;
};
export enum SelectionSetKey {
  Selections1 = 'selections1',
  Selections2 = 'selections2',
}

export enum FilterOptionsKeys {
  country = 'country',
  graduationYear = 'graduationYear',
  graduationType = 'graduationType',
  instituteType = 'instituteType',
}

const selectionsInitialState = {
  country: undefined,
  graduationYear: undefined,
  graduationType: undefined,
  instituteType: undefined,
};
export default function Reporting(): JSX.Element {
  const [chartData, setChartData] = useState({
    chartDataCondition1: Array(12).fill(0),
    chartDataCondition2: Array(12).fill(0),
  });
  const [percentageChanges, setPercentageChanges] = useState({
    institutes: 0,
    users: 0,
    usersCount: 0,
    groups: 0,
    activeGroups: 0,
  });

  const [selections, setSelections] = useState<SelectionsState>({
    selections1: selectionsInitialState,
    selections2: selectionsInitialState,
  });
  const { data: statisticsData1, loading: statisticsDataLoading1 } =
    useStatisticsQuery({
      variables: {
        filter: {
          ...selections.selections1,
        },
      },
    });
  const { data: statisticsData2, loading: statisticsDataLoading2 } =
    useStatisticsQuery({
      variables: {
        filter: {
          ...selections.selections2,
        },
      },
    });

  const { data: signupsData1, loading: signupsLoading1 } = useSignupsQuery({
    variables: {
      filter: {
        ...selections.selections1,
      },
    },
  });
  const { data: signupsData2, loading: signupsLoading2 } = useSignupsQuery({
    variables: {
      filter: {
        ...selections.selections2,
      },
    },
  });

  const { data: instituteTypes } = useInstituteTypesQuery({
    variables: {
      first: 50,
    },
  });

  const { data: graduationTypes } = useGraduationTypesQuery({
    variables: {
      first: 50,
    },
  });

  useEffect(() => {
    calculatePercentages();
    populateChartData();
  }, [statisticsData1, statisticsData2]);

  const onChangeSelection = (
    setKey: SelectionSetKey,
    key: FilterOptionsKeys,
    value: string,
  ) => {
    setSelections((prevSelections) => {
      const updatedValue =
        key === FilterOptionsKeys.graduationYear ? parseInt(value, 10) : value;

      const currentArray =
        (prevSelections[setKey][key] as Array<string | number>) || [];

      return {
        ...prevSelections,
        [setKey]: {
          ...prevSelections[setKey],
          [key]: toggleSelection(currentArray, updatedValue),
        },
      };
    });
  };

  const toggleSelection = (
    currentArray: Array<string | number>,
    value: string | number,
  ): Array<string | number> => {
    const index = currentArray.indexOf(value);
    if (index > -1) {
      return currentArray.filter((item) => item !== value);
    } else {
      return [...currentArray, value];
    }
  };

  const populateChartData = () => {
    const signupsLoaded =
      !signupsLoading1 && !signupsLoading2 && signupsData1 && signupsData2;
    if (signupsLoaded && signupsData1.signups && signupsData2.signups) {
      const filteredSignups1 = signupsData1.signups.filter((el) =>
        selections?.selections1?.country?.includes(el?.country as string),
      );
      const filteredSignups2 = signupsData2.signups.filter((el) =>
        selections.selections2.country?.includes(el?.country as string),
      );

      const chartDataCondition1 = aggregateMonthlyCounts(filteredSignups1);
      const chartDataCondition2 = aggregateMonthlyCounts(filteredSignups2);

      setChartData({
        chartDataCondition1,
        chartDataCondition2,
      });
    }
  };

  const calculatePercentages = () => {
    if (statisticsData1 && statisticsData2) {
      const newInstitutesPercentage = calculatePercentageChange(
        statisticsData2?.statistics?.institutesCount || 0,
        statisticsData1?.statistics?.institutesCount || 0,
      );
      const newUsersPercentage = calculatePercentageChange(
        statisticsData2?.statistics?.usersCount || 0,
        statisticsData1?.statistics?.usersCount || 0,
      );
      const newGroupsPercentage = calculatePercentageChange(
        statisticsData2?.statistics?.activeGroupsCount || 0,
        statisticsData1?.statistics?.activeGroupsCount || 0,
      );
      const newActiveGroupsPercentage = calculateGroupPercentage(
        statisticsData1?.statistics?.activeGroupsCount || 0,
        statisticsData1?.statistics?.inactiveGroupsCount || 0,
      );

      setPercentageChanges({
        institutes: newInstitutesPercentage,
        users: newUsersPercentage,
        usersCount: statisticsData2.statistics?.usersCount
          ? statisticsData2.statistics.usersCount
          : 0,
        groups: newGroupsPercentage,
        activeGroups: newActiveGroupsPercentage,
      });
    }
  };

  const graduationTypesOptions: OptionsInfo[] = [
    { key: 'all', name: 'all' },
    ...(graduationTypes?.graduationTypes?.edges.map((edge) => ({
      key: edge?.node?.id as string,
      name: edge?.node?.name as string,
    })) || []),
  ];
  const instituteTypesOptions: OptionsInfo[] = [
    { key: 'all', name: 'all' },
    ...(instituteTypes?.instituteTypes?.edges.map((edge) => ({
      key: edge?.node?.id as string,
      name: edge?.node?.name as string,
    })) || []),
  ];

  const totalSchools = statisticsData1?.statistics?.institutesCount;
  const globalOption: OptionsInfo = {
    key: 'global',
    name: `${t('adminPanelSearch.flags.GLOBAL')} ${t(
      'adminPanelSearch.countryNames.GLOBAL',
    )}`,
  };

  const countries: OptionsInfo[] = Object.values(COUNTRIES).map(
    (countryInfo) => ({
      key: countryInfo.country,
      name: `${t('adminPanelSearch.flags.' + countryInfo.country)} ${t(
        'adminPanelSearch.countryNames.' + countryInfo.country,
      )}`,
    }),
  );
  countries.unshift(globalOption);

  const graduationYears = generateGraduationYears();

  const loading =
    statisticsDataLoading1 ||
    statisticsDataLoading2 ||
    signupsLoading1 ||
    signupsLoading2;

  return (
    <ReportingLayout
      selections={selections}
      countries={countries}
      graduationYears={graduationYears}
      graduationTypes={graduationTypesOptions}
      statisticsData1={statisticsData1?.statistics}
      totalSchools={totalSchools}
      percentageChanges={percentageChanges}
      instituteTypes={instituteTypesOptions}
      chartData={chartData}
      formatNumberWithDotSeparator={formatNumberWithDotSeparator}
      loading={loading}
      onChangeSelection={onChangeSelection}
    />
  );
}
