/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation UpdateTeam
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { UpdateTeam, UpdateTeamVariables } from './types/UpdateTeam';
import { UPDATE_TEAM } from './';

export function useUpdateTeamMutation(
  options?: MutationHookOptions<UpdateTeam, UpdateTeamVariables>,
): MutationTuple<UpdateTeam, UpdateTeamVariables> {
  return useMutation<UpdateTeam, UpdateTeamVariables>(UPDATE_TEAM, options);
}

export function createUpdateTeamMock({
  variables,
  data,
  error,
}: {
  variables?: UpdateTeamVariables;
  data?: UpdateTeam;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: UPDATE_TEAM,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
