import { gql } from '@apollo/client';

export const MARKET_PROFILE_FIELDS = gql`
  fragment MarketProfileFields on MarketProfileNode {
    id
    name
  }
`;

export const MARKET_PROFILES = gql`
  ${MARKET_PROFILE_FIELDS}
  query MarketProfiles {
    marketProfiles {
      edges {
        node {
          ...MarketProfileFields
        }
      }
    }
  }
`;
