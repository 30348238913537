import styled from 'styled-components/native';
import ImageComp from '~/components/Image';
import { ResponsiveProps } from '~/components/common/style.web';
import { TableRow } from '../InstituteDatabase/style';
import { TableCell } from '../InstituteDatabase/style';
import { CellText } from '../InstituteDatabase/style';

export const Container = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const Text = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 14px;
`;

export const YearContainer = styled.View<ResponsiveProps>`
  width: ${({ isDesktop }) => (isDesktop ? '438px' : '100%')};
`;

export const HeaderSection = styled.View<ResponsiveProps>`
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
  justify-content: space-between;
  align-items: ${({ isDesktop }) => (isDesktop ? 'center' : 'left')};
  margin-bottom: ${({ isDesktop }) => (isDesktop ? 20 : 10)}px;
`;

export const GroupImage = styled(ImageComp)<{ isSmall?: boolean }>`
  width: ${({ isSmall }) => (isSmall ? '32px' : '56px')};
  height: ${({ isSmall }) => (isSmall ? '32px' : '56px')};
  border-radius: 12px;
  border-width: 2px;
  border-color: ${({ theme }) => theme.color.base.c2};
`;

export const GroupsTable = styled.FlatList`
  min-height: 25vh;
`;

export const GroupName = styled.Text`
  color: ${({ theme }) => theme.color.base.c9};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
  user-select: text;
`;

export const LastCell = styled(TableCell)<ResponsiveProps>`
  align-items: end;
`;

export const FirstCell = styled(TableCell)<ResponsiveProps>`
  margin-left: ${({ isDesktop }) => (isDesktop ? -50 : 5)}px;
`;

export const MiddleCell = styled(TableCell)`
  align-items: center;
`;

export const GroupTableRow = styled(TableRow)<ResponsiveProps>`
  gap: ${({ isDesktop }) => isDesktop && 50}px;
`;

export const Title = styled.Text<ResponsiveProps>`
  color: ${({ theme }) => theme.color.base.c9};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: ${({ isDesktop }) => (isDesktop ? 22 : 16)}px;
`;

export const BoldedCellText = styled(CellText)`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
`;
