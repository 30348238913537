import { BadgeColorTheme } from '~/components/Badge/types';

export type Statistic = {
  statistic: string;
  description: string;
};

export type Asset = {
  name: string;
  id: string;
  views: string;
  clicks: string;
  conversion: string;
  active: boolean;
};

export const CampaignStatusBadgeColor: { [key: string]: BadgeColorTheme } = {
  Active: 'green',
  Draft: 'tertiary',
  Inactive: 'red',
};
