import styled from 'styled-components/native';

interface IContainer {
  teamColor?: string;
  light?: boolean;
}
export const Container = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})<IContainer>`
  background-color: ${({ teamColor, light, theme }) =>
    light ? theme.color.base.c3 : teamColor || theme.color.base.c4};
  padding: 16px;
  border-radius: 5px;
  width: ${({ theme }) => theme.ratio(232)};
  height: ${({ theme }) => theme.ratio(150)};
`;

export const Header = styled.View`
  flex: 1;
`;

export const Name = styled.Text`
  color: ${({ theme }) => theme.color.base.c0};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 22px;
  line-height: 26px;
`;

export const Footer = styled.View`
  flex-direction: row;
  align-items: flex-end;
  padding-top: 8px;
`;

export const AvatarListContent = styled.View`
  margin-left: 8px;
  flex: 1;
  flex-direction: row;
  height: 24px;
`;

export const AvatarContent = styled.View`
  margin-left: -8px;
`;

export const MembersCount = styled.Text`
  color: ${({ theme }) => theme.color.base.c0};
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 12px;
`;
