import styled, { css } from 'styled-components/native';
import Icon from '~/components/Icon';
import IconStyled from '~/components/IconStyled';

export type SectionButtonType = 'default' | 'large';
export type SectionButtonIntent = 'default' | 'danger' | 'success';

interface IContainer {
  centerContent?: boolean;
  locked?: boolean;
  type?: SectionButtonType;
  intent?: SectionButtonIntent;
}

export const Container = styled.TouchableOpacity.attrs<IContainer>(
  ({ locked }) => ({
    locked: locked || false,
    activeOpacity: 0.9,
  }),
)<IContainer>`
  flex-direction: row;
  padding-vertical: ${({ type }) => (type == 'large' ? '16px' : '8px')};
  padding-horizontal: 16px;
  border-radius: 14px;
  background-color: ${({ theme, locked }) =>
    locked ? theme.color.base.c4 : theme.color.base.c1};
  align-items: center;
  ${({ centerContent }) => centerContent && `justify-content: center;`};

  ${({ intent, theme }) =>
    intent === 'danger' &&
    css`
      background-color: ${theme.color.base.c0};
    `}

  ${({ intent, theme }) =>
    intent === 'success' &&
    css`
      background-color: ${theme.color.success};
    `}
`;

export const CircleContainer = styled.TouchableOpacity.attrs<IContainer>(
  ({ locked }) => ({
    locked: locked || false,
    activeOpacity: 0.9,
  }),
)<IContainer>`
  padding: 8px;
  border-radius: 20px;
  background-color: ${({ theme, locked }) =>
    locked ? theme.color.base.c4 : theme.color.base.c1};
`;

interface IButtonIcon {
  name: string;
  size?: number;
  hasPadding?: boolean;
  textColor?: string;
  locked?: boolean;
}
export const ButtonIcon = styled(Icon).attrs<IButtonIcon>(
  ({ name, size, theme, textColor, locked }) => ({
    name,
    size: size || 16,
    color: textColor || theme.color.brand_02,
    locked: locked || false,
  }),
)<IButtonIcon>`
  ${({ hasPadding }) => hasPadding && 'padding-right: 8px;'};
  ${({ locked }) => locked && 'color: white;'};
`;

export const IconStyledContainer = styled.View<{
  hasPadding?: boolean;
  locked?: boolean;
}>`
  ${({ hasPadding }) => hasPadding && 'padding-right: 8px;'};
  ${({ locked }) => locked && 'color: white;'};
`;

export const ButtonIconStyled = styled(IconStyled).attrs<IButtonIcon>(
  ({ name, size, theme, textColor, locked }) => ({
    name,
    size: size || 16,
    color: textColor || theme.color.brand_02,
    locked: locked || false,
  }),
)<IButtonIcon>``;

interface IText {
  textColor?: string;
  locked?: boolean;
}
export const Text = styled.Text.attrs<IText>(({ locked }) => ({
  locked: locked || false,
}))<IText>`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme, textColor, locked }) =>
    locked ? 'white' : textColor || theme.color.brand_02};
  font-size: 14px;
  height: 18px;
`;
