import styled from 'styled-components/native';
import { ResponsiveProps } from '~/components/common/style.web';

export const LottieAbsContainer = styled.View`
  position: absolute;
  width: 100%;
  overflow: hidden;
`;

export const ProgressBar = styled.View<ResponsiveProps>`
  width: ${({ isDesktop }) => (isDesktop ? '366px' : '300px')};
  height: 12px;
  margin: 60px auto 48px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const ChildrenCenterContainer = styled.View`
  flex: 1;
  justify-content: center;
`;

interface IWebContainer {
  isDesktop: boolean;
  bgTransparent: boolean;
}
export const WebContainer = styled.View<IWebContainer>`
  flex-direction: row;
  width: 100%;
  height: ${({ isDesktop }) => (isDesktop ? '670px' : '530px')};
  justify-content: space-between;
  flex-grow: 0;
  padding: ${({ isDesktop, theme }) =>
    isDesktop ? theme.spacing.extraLarge : theme.spacing.small};
  margin: 0 auto;
  ${({ bgTransparent, theme }) =>
    !bgTransparent && `background:${theme.color.base.c0}`};
  border-radius: 14px;
`;
