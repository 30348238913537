import styled from 'styled-components/native';
import { TabScreenContainer } from '~/screens/style';

export const Container = styled(TabScreenContainer)`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const ScrollView = styled.ScrollView`
  flex: 1;
`;

export const Content = styled.View`
  padding-horizontal: 16px;
`;

interface ISectionView {
  row?: boolean;
}
export const SectionView = styled.View<ISectionView>`
  padding-top: 16px;
  padding-bottom: 32px;
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c9,
}))``;
