import React from 'react';
import { Platform } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components/native';
import lottie from '~/assets/lottie';
import LottieWeb from '~/components/LottieWeb';
import UnderlineButton from '~/components/UnderlineButton';
import { ImportStatuses } from '~/navigation/types';
import AuthWebLayout from '~/screens/Auth/layout/AuthLayout/AuthWebLayout';
import {
  Content,
  Descp,
  Footer,
  Title,
} from '~/screens/Common/SignUpSuccess/layout/style';
import { t } from '~/utils/i18n';
import {
  LottieAbsContainer,
  ProgressBar,
  ChildrenCenterContainer,
  WebContainer,
} from './style';

export type ImportStatusLayoutProps = {
  onBackToBeginning: () => void;
  status: ImportStatuses;
};

export default function ImportStatus({
  onBackToBeginning,
  status,
}: ImportStatusLayoutProps): JSX.Element {
  const theme = useTheme();
  const isDesktop = useMediaQuery({ minWidth: theme.breakpoints.desktopMin });

  return (
    <AuthWebLayout>
      <WebContainer isDesktop={isDesktop} bgTransparent>
        {(() => {
          switch (status) {
            case ImportStatuses.processing:
              return (
                <ChildrenCenterContainer>
                  <Content>
                    <Title>{t('importer.processingTitle')}</Title>
                    <ProgressBar isDesktop={isDesktop} />
                    <Descp>{t('importer.processingDescription')}</Descp>
                  </Content>
                  <Footer>
                    <UnderlineButton
                      testID={'backToBeginning'}
                      type={'light'}
                      text={t('importer.backToBeginning')}
                      onPress={() => onBackToBeginning()}
                    />
                  </Footer>
                </ChildrenCenterContainer>
              );
            case ImportStatuses.success:
              return (
                <ChildrenCenterContainer>
                  <LottieAbsContainer>
                    {Platform.OS === 'web' && (
                      <LottieWeb
                        animationData={lottie.confetti}
                        width={isDesktop ? 412 : 319}
                        height={isDesktop ? 491 : 380}
                      />
                    )}
                  </LottieAbsContainer>
                  <Content>
                    <Title>{t('importer.importSuccessfulTitle')}</Title>
                    <Descp>{t('importer.importSuccessfulDescription')}</Descp>
                  </Content>
                  <Footer>
                    <UnderlineButton
                      testID={'backToBeginning'}
                      type={'light'}
                      text={t('importer.continue')}
                      onPress={() => onBackToBeginning()}
                    />
                  </Footer>
                </ChildrenCenterContainer>
              );
            case ImportStatuses.error:
              return (
                <ChildrenCenterContainer>
                  <Content>
                    <Title>{t('importer.importFailedTitle')}</Title>
                    <Descp>
                      {t('importer.importFailedDescription', {
                        reason: 'We could not match the institute.',
                      })}
                    </Descp>
                  </Content>
                  <Footer>
                    <UnderlineButton
                      testID={'backToBeginning'}
                      type={'light'}
                      text={t('importer.backToBeginning')}
                      onPress={() => onBackToBeginning()}
                    />
                  </Footer>
                </ChildrenCenterContainer>
              );
          }
        })()}
      </WebContainer>
    </AuthWebLayout>
  );
}
