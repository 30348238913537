import { ApolloError } from '@apollo/client';
import { useAuth } from '~/context/auth';
import { NO_PAG_LIST_OFFSET } from '../constants';
import { GroupUser } from '../models/group';
import { GroupsUserGroupStatusChoices } from '../operations/global';
import { useGroupUsersQuery } from '../operations/group/groupUsers';

type UseGroupUsersProps = {
  groupUsers: GroupUser[];
  loading: boolean;
  error: ApolloError | undefined;
};

const useGroupUsers = (): UseGroupUsersProps => {
  const { authUserId } = useAuth();

  const {
    loading: loadingGroupUsers,
    error: groupUsersError,
    data: groupUsersData,
  } = useGroupUsersQuery({
    skip: !authUserId,
    variables: {
      first: NO_PAG_LIST_OFFSET,
      user: authUserId,
      status: GroupsUserGroupStatusChoices.ACCEPTED,
    },
  });

  const groupUsers =
    (groupUsersData?.userGroups?.edges.map(
      (userGroup) => userGroup?.node,
    ) as GroupUser[]) || [];

  return {
    loading: loadingGroupUsers,
    error: groupUsersError,
    groupUsers,
  };
};

export default useGroupUsers;
