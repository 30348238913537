import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { useLikePostMutation } from '../operations/post/likePost';
import { useRemoveLikeFromPostMutation } from '../operations/post/removeLikeFromPost';

type PollLikeHookProps = {
  onLikePost: (postId: string, liked: boolean) => void;
};

const useLikePost = (): PollLikeHookProps => {
  const [likePost] = useLikePostMutation();
  const [removeLikeFromPost] = useRemoveLikeFromPostMutation();

  const { authUserGroupId } = useAuth();

  const onLikePost = async (postId: string, liked: boolean) => {
    try {
      if (liked) {
        await removeLikeFromPost({
          variables: {
            input: {
              id: postId,
            },
            authUserGroupId,
          },
        });
      } else {
        await likePost({
          variables: {
            input: {
              id: postId,
            },
            authUserGroupId,
          },
        });
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };
  return {
    onLikePost,
  };
};

export default useLikePost;
