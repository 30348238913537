import { ApolloCache } from '@apollo/client';
import { BEST_MATCH_ASSET, CAMPAIGN } from '.';
import {
  BestMatchAsset,
  BestMatchAssetVariables,
} from './types/BestMatchAsset';
import { Campaign, CampaignVariables } from './types/Campaign';

export function readCampaignQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: CampaignVariables;
}): Campaign | null {
  return cache.readQuery<Campaign>({
    query: CAMPAIGN,
    variables,
  });
}

export function writeCampaignQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: CampaignVariables;
  data: Campaign;
}): void {
  cache.writeQuery<Campaign>({
    query: CAMPAIGN,
    variables,
    data,
  });
}

export function writeBestMatchAsseQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: BestMatchAssetVariables;
  data: BestMatchAsset;
}): void {
  cache.writeQuery<BestMatchAsset>({
    query: BEST_MATCH_ASSET,
    variables,
    data,
  });
}
