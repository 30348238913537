import React, { useEffect, useState } from 'react';
import NumberInput from '~/components/NumberInput';
import SectionHeader from '~/components/SectionHeader';
import { SettingPanel, SettingToggleItem } from '~/components/SettingPanel';
import { ModuleInstance, QuotesSetupConfig } from '~/data/models/yearbook';
import { t } from '~/utils/i18n';
import ModuleSetupLayout, {
  UpdateModuleInstanceInput,
} from '../../layout/ModuleSetupLayout';
import { CharCountContainer, ViewContainer } from './style';

export type UpdateQuotesSetupInput = {
  anonymous: boolean;
  publicQuotes: boolean;
  maxChars: number;
};

type QuotesSetupLayoutProps = {
  moduleInstance: ModuleInstance;
  quotesSetupConfig: QuotesSetupConfig;
  onBack: () => void;
  onUpdateModuleInstance: (
    updateModuleInstanceInput: UpdateModuleInstanceInput,
  ) => void;
  onUpdateQuotesSetup: (updateQuotesSetupInput: UpdateQuotesSetupInput) => void;
};

export default function QuotesSetup({
  moduleInstance,
  quotesSetupConfig,
  onUpdateModuleInstance,
  onUpdateQuotesSetup,
  onBack,
}: QuotesSetupLayoutProps): JSX.Element {
  const [anonymous, setAnonymous] = useState(() => quotesSetupConfig.anonymous);
  const [publicQuotes, setPublicQuotes] = useState(
    () => quotesSetupConfig.public,
  );
  const [maxChars, setMaxChars] = useState(() => quotesSetupConfig.maxChars);

  useEffect(() => {
    const updateAnonymous =
      quotesSetupConfig && anonymous != quotesSetupConfig.anonymous
        ? true
        : false;

    const updatePublic =
      quotesSetupConfig && publicQuotes != quotesSetupConfig.public
        ? true
        : false;

    const updatedMaxChars =
      quotesSetupConfig && maxChars != quotesSetupConfig.maxChars
        ? true
        : false;

    if (updateAnonymous || updatePublic || updatedMaxChars) {
      onUpdateQuotesSetup({
        anonymous,
        publicQuotes,
        maxChars,
      });
    }
  }, [anonymous, publicQuotes, maxChars]);

  return (
    <ModuleSetupLayout
      title={t('screens.quotesSetup')}
      activateText={t('yearbookQuotes.writingPermission')}
      moduleInstance={moduleInstance}
      onBack={onBack}
      onUpdateModuleInstance={onUpdateModuleInstance}
    >
      <>
        <SectionHeader sub title={t('yearbookQuotes.settingsSubheader')} />
        <SettingPanel>
          <SettingToggleItem
            testID={'anonymousToggleItem'}
            text={t('yearbookQuotes.allowAnonymous')}
            checked={anonymous}
            onToggleChange={setAnonymous}
          />
          <SettingToggleItem
            testID={'seeOnlyOwnQuotesToggleItem'}
            text={t('yearbookQuotes.seeOnlyOwnQuotes')}
            checked={!publicQuotes}
            onToggleChange={(visibleToOwnUser) =>
              setPublicQuotes(!visibleToOwnUser)
            }
          />
        </SettingPanel>
        <CharCountContainer>
          <ViewContainer>
            <SectionHeader sub title={t('yearbookQuotes.maxCharsSubheader')} />
          </ViewContainer>
          <NumberInput
            testID={'charCountNumberInput'}
            number={maxChars}
            step={100}
            min={500}
            max={10000}
            onChangeNumber={(number) => setMaxChars(number)}
          />
        </CharCountContainer>
      </>
    </ModuleSetupLayout>
  );
}
