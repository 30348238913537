/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation GenerateShopifyUrl
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  GenerateShopifyUrl,
  GenerateShopifyUrlVariables,
} from './types/GenerateShopifyUrl';
import { GENERATE_SHOPIFY_URL } from './';

export function useGenerateShopifyUrlMutation(
  options?: MutationHookOptions<
    GenerateShopifyUrl,
    GenerateShopifyUrlVariables
  >,
): MutationTuple<GenerateShopifyUrl, GenerateShopifyUrlVariables> {
  return useMutation<GenerateShopifyUrl, GenerateShopifyUrlVariables>(
    GENERATE_SHOPIFY_URL,
    options,
  );
}

export function createGenerateShopifyUrlMock({
  variables,
  data,
  error,
}: {
  variables?: GenerateShopifyUrlVariables;
  data?: GenerateShopifyUrl;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: GENERATE_SHOPIFY_URL,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
