import styled from 'styled-components/native';

export const Container = styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 8px;
`;

export const Title = styled.Text`
  flex: 1;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c10};
  font-size: 18px;
  line-height: 24px;
`;

export const ButtonContent = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})`
  padding-vertical: 8px;
`;

interface IButtonText {
  buttonColor?: string;
}
export const ButtonText = styled.Text<IButtonText>`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ buttonColor, theme }) => buttonColor || theme.color.brand_02};
  font-size: 14px;
  height: 18px;
`;
