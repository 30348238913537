/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation AddPhotoToCollagesAlbum
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  AddPhotoToCollagesAlbum,
  AddPhotoToCollagesAlbumVariables,
} from './types/AddPhotoToCollagesAlbum';
import { ADD_PHOTO_TO_COLLAGES_ALBUM } from './';

export function useAddPhotoToCollagesAlbumMutation(
  options?: MutationHookOptions<
    AddPhotoToCollagesAlbum,
    AddPhotoToCollagesAlbumVariables
  >,
): MutationTuple<AddPhotoToCollagesAlbum, AddPhotoToCollagesAlbumVariables> {
  return useMutation<AddPhotoToCollagesAlbum, AddPhotoToCollagesAlbumVariables>(
    ADD_PHOTO_TO_COLLAGES_ALBUM,
    options,
  );
}

export function createAddPhotoToCollagesAlbumMock({
  variables,
  data,
  error,
}: {
  variables?: AddPhotoToCollagesAlbumVariables;
  data?: AddPhotoToCollagesAlbum;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: ADD_PHOTO_TO_COLLAGES_ALBUM,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
