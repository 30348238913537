import styled from 'styled-components/native';
import ImageComp from '~/components/Image';
import { ModalScreenContainer } from '~/screens/style';

export const Container = styled(ModalScreenContainer)`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const ScrollView = styled.ScrollView`
  padding: 16px;
`;

export const Image = styled(ImageComp)`
  width: 100%;
  height: 240px;
  background-color: ${({ theme }) => theme.color.base.c2};
  border-radius: 15px;
  margin-top: 16px;
  margin-bottom: 32px;
`;

export const Text = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 16px;
  line-height: 25px;
  margin-top: 8px;
  margin-bottom: 32px;
`;

export const ButtonContent = styled.View`
  padding-bottom: 24px;
`;
