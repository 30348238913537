import { Signups } from '~/data/models/campaign';

export const formatNumberWithDotSeparator = (num?: number | null): string => {
  const formatter = new Intl.NumberFormat('de-DE');
  const formatted = formatter.format(num || 0);
  return formatted.replace(/,/g, '.');
};

export const aggregateMonthlyCounts = (signups: (Signups | null)[]) => {
  const monthlyCounts = new Array(12).fill(0);
  signups.forEach((signup) => {
    if (signup?.counts) {
      signup.counts.forEach((count) => {
        const month = new Date(count?.date as string).getMonth();
        monthlyCounts[month] += count?.count;
      });
    }
  });
  return monthlyCounts;
};

export const calculatePercentageChange = (
  oldNumber: number,
  newNumber: number,
) => {
  if (oldNumber === 0) return newNumber > 0 ? Infinity : 0;
  const difference = newNumber - oldNumber;
  return (difference / oldNumber) * 100;
};

export const calculateGroupPercentage = (active: number, inactive: number) => {
  const totalGroups = active + inactive;
  return totalGroups > 0 ? (active / totalGroups) * 100 : 0;
};
