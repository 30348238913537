import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import styled from 'styled-components/native';
import IconStyled from '~/components/IconStyled';
import ImageComp from '~/components/Image';
import { TabScreenContainer } from '~/screens/style';

export const Container = styled(TabScreenContainer)`
  padding: 0 5px 0 5px;
`;

export const ScrollView = styled(KeyboardAwareScrollView)`
  padding-horizontal: 16px;
`;

export const Title = styled.Text`
  margin-top: 24px;
  margin-bottom: 20px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 16px;
`;

export const TypeTitle = styled.Text`
  margin-top: 24px;
  margin-bottom: 20px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 20px;
`;

export const Descp = styled.Text`
  margin-vertical: 16px;
  text-align: left;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c8};
  font-size: 18px;
`;

interface ISectionRow {
  topPadding?: boolean;
  largeBottomPadding?: boolean;
}
export const SectionRow = styled.View<ISectionRow>`
  flex-direction: row;
  padding-top: ${({ topPadding }) => (topPadding ? '16px' : '0px')};
  padding-bottom: ${({ largeBottomPadding }) =>
    largeBottomPadding ? '16px' : '8px'};
  align-items: center;
`;

export const SectionValue = styled.Text`
  margin-left: 8px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 16px;
`;

export const ImageContent = styled.TouchableOpacity``;

export const EmptyImageIcon = styled(IconStyled).attrs(({ theme }) => ({
  name: 'image_empty',
  color: theme.color.base.c6,
  size: 48,
}))``;

export const ImageBanner = styled(ImageComp)`
  background-color: ${({ theme }) => theme.color.base.c3};
  border-radius: 5px;
  aspect-ratio: 1.8;
  overflow: hidden;
  align-items: center;
  justify-content: center;
`;

export const AboutEvent = styled.TextInput.attrs(({ theme }) => ({
  placeholderTextColor: theme.color.base.c6,
}))`
  height: 245px;
  border-color: ${({ theme }) => theme.color.base.c6};
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 18px;
  border-width: 2px;
  justify-content: flex-start;
  padding: 22px 16px;
  border-radius: 18px;
  color: ${({ theme }) => theme.color.base.c6};
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c9,
}))``;
