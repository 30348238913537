import styled from 'styled-components/native';
import IconStyled from '../IconStyled';
import { CommonTouchableOpacity } from '../common/style';

export const Container = CommonTouchableOpacity`
  padding-vertical: 16px;
  flex-direction: row;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.color.base.c3};
  align-items: center;
`;

export const Content = styled.View`
  flex: 1;
  padding-left: 16px;
`;

export const Name = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 16px;
`;

export const InfoText = styled.Text`
  margin-top: 8px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.brand_02};
  font-size: 14px;
`;

interface ICustomIcon {
  finished: boolean;
}
export const CustomIcon = styled(IconStyled).attrs<ICustomIcon>(
  ({ finished, theme }) => ({
    size: 24,
    color: finished ? theme.color.brand_02 : theme.color.base.c6,
  }),
)<ICustomIcon>`
  margin-left: 8px;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
`;
