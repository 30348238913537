import styled from 'styled-components/native';

type IContainer = {
  width?: number;
  height?: number;
};

type IText = {
  fontSize?: number;
  horizontalPadding?: number;
};

export const Container = styled.View<IContainer>`
  width: ${({ width }) => (width ? width : 174)}px;
  height: ${({ height }) => (height ? height : 132)}px;
  border-radius: 15px;
  border: 1px solid ${({ theme }) => theme.color.base.c3};
  justify-content: center;
  align-items: center;
`;

export const Statistic = styled.Text<IText>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : 36)}px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.brand_02};
  padding-bottom: 8px;
`;

export const Description = styled.Text<IText>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : 12)}px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c6}
  text-align: center;
  padding-horizontal: ${({ horizontalPadding }) =>
    horizontalPadding ? horizontalPadding : 32}px;
`;

export const LoadingStats = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c4,
  size: 'small',
}))`
  margin: 20px 0;
`;
