import React from 'react';
import {
  CompositeNavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useAuth } from '~/context/auth';
import { useIntercom } from '~/context/intercom';
import { DEFAULT_LIST_OFFSET } from '~/data/constants';
import useAuthTeams from '~/data/hooks/useAuthTeams';
import useTeamSamples from '~/data/hooks/useTeamSamples';
import { TeamType } from '~/data/operations/global';
import { useCustomPagesInstancesQuery } from '~/data/operations/yearbook/customPagesInstances';
import { useCustomPagesInstancesResultQuery } from '~/data/operations/yearbook/customPagesInstancesResult';
import { useModuleInstanceQuery } from '~/data/operations/yearbook/moduleInstance';
import { CustomPageFields } from '~/data/operations/yearbook/types/CustomPageFields';
import {
  MainTabParamList,
  RootStackParamList,
  YearbookStackParamList,
} from '~/navigation/types';
import { isDateExpired } from '~/utils/dates';
import CustomLayout from './layout';

type CustomNavProp = CompositeNavigationProp<
  StackNavigationProp<YearbookStackParamList, 'Custom'>,
  CompositeNavigationProp<
    StackNavigationProp<RootStackParamList>,
    StackNavigationProp<MainTabParamList>
  >
>;

type CustomRouteProp = RouteProp<YearbookStackParamList, 'Custom'>;

export default function Custom(): JSX.Element {
  const navigation = useNavigation<CustomNavProp>();
  const {
    params: { moduleInstanceId, viewOnly },
  } = useRoute<CustomRouteProp>();
  const { yearbookAdmin, authGroupId, authUserGroupId } = useAuth();
  const { onHelpCenter } = useIntercom();
  const { teamSamples } = useTeamSamples();
  const yearbookTeam = teamSamples.filter((t) => t.type === TeamType.YEARBOOK);
  const { data: moduleInstanceData } = useModuleInstanceQuery({
    variables: {
      id: moduleInstanceId,
    },
  });
  const { authTeamIds } = useAuthTeams();
  const customPageVar = {
    after: null,
    first: DEFAULT_LIST_OFFSET,
    moduleInstance: moduleInstanceId,
    userGroup: authUserGroupId,
  };
  const customPagesSetup = moduleInstanceData?.moduleInstance?.customPagesSetup;
  const {
    data: customPagesData,
    loading: customPagesLoading,
    refetch: refetchCustomPages,
    fetchMore: fetchMoreCustomPages,
  } = useCustomPagesInstancesQuery({
    skip: !customPagesSetup,
    variables: customPageVar,
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: allCustomPagesData,
    loading: allCustomPagesLoading,
    fetchMore: fetchMoreAllCustomPages,
  } = useCustomPagesInstancesResultQuery({
    variables: customPageVar,
  });

  const createPagesTeam = customPagesSetup?.createPagesTeam;
  const viewPagesTeam = customPagesSetup?.viewPagesTeam;

  const addPagesEnabled =
    createPagesTeam === null ||
    authTeamIds.includes(createPagesTeam?.id as string);

  const viewPagesEnabled =
    viewPagesTeam === null || authTeamIds.includes(viewPagesTeam?.id as string);

  const isActive = moduleInstanceData?.moduleInstance?.isActive;
  const dueDate = moduleInstanceData?.moduleInstance?.dueDate;
  const isDeadlinePassed = dueDate && isDateExpired(dueDate);

  const customPagesInstances: CustomPageFields[] =
    (customPagesData?.customPagesInstances?.edges.map(
      (edge) => edge?.node,
    ) as CustomPageFields[]) || [];
  const hasNextPage =
    customPagesData?.customPagesInstances?.pageInfo.hasNextPage;
  const endCursor = customPagesData?.customPagesInstances?.pageInfo.endCursor;

  const allCustomPagesInstances: CustomPageFields[] =
    (allCustomPagesData?.customPagesInstancesResult?.edges.map(
      (edge) => edge?.node,
    ) as CustomPageFields[]) || [];

  const allHasNextPage =
    allCustomPagesData?.customPagesInstancesResult?.pageInfo.hasNextPage;
  const allEndCursor =
    allCustomPagesData?.customPagesInstancesResult?.pageInfo.endCursor;

  return (
    <CustomLayout
      onBack={() => navigation.goBack()}
      yearbookAdmin={yearbookAdmin}
      isActive={isActive}
      isDeadlinePassed={isDeadlinePassed}
      viewOnly={viewOnly}
      hasNextPage={hasNextPage}
      allHasNextPage={allHasNextPage}
      userCustomPages={allCustomPagesInstances}
      guide={moduleInstanceData?.moduleInstance?.customPagesSetup?.guide}
      onSetup={() => navigation.navigate('CustomSetup', { moduleInstanceId })}
      addPagesEnabled={addPagesEnabled}
      viewPagesEnabled={viewPagesEnabled}
      restrictedToTeam={viewPagesTeam?.name}
      onRefresh={refetchCustomPages}
      loading={customPagesLoading && allCustomPagesLoading}
      customPagesInstances={customPagesInstances}
      onAllLoadMore={() => {
        fetchMoreAllCustomPages({
          variables: {
            ...customPageVar,
            after: allEndCursor,
          },
        });
      }}
      onLoadMore={() => {
        fetchMoreCustomPages({
          variables: {
            ...customPageVar,
            after: endCursor,
          },
        });
      }}
      onAddPages={() =>
        navigation.navigate('CreateCustomPage', {
          moduleInstanceId: moduleInstanceId,
        })
      }
      onOpenCustomPage={(customPageId: string) =>
        navigation.navigate('EditCustomPage', {
          moduleInstanceId: moduleInstanceId,
          customPageId,
        })
      }
      onHelp={onHelpCenter}
      onRequestYearbookTeam={() => {
        navigation.popToTop();
        navigation.navigate('MainTab', {
          screen: 'PlanningStack',
          gid: authGroupId,
          params: {
            screen: 'TeamDetail',
            params: {
              teamId: yearbookTeam[0]?.id,
            },
          },
        });
      }}
    />
  );
}
