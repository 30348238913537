import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components/native';
import AdminHeader from '~/components/AdminHeader';
import InlineNotification from '~/components/InlineNotification';
import { SeverityEnum } from '~/components/InlineNotification/style';
import InputSearchField from '~/components/InputSearchField';
import Paginator from '~/components/Paginator';
import StepSizePicker from '~/components/StepSizePicker';
import { SEARCH_SHORTCUTS, SEARCH_TAGS } from '~/data/constants';
import { AdminSearchResultType } from '~/data/models/admin';
import { Country } from '~/data/models/marketProfile';
import NoResults from '~/screens/Admin/Search/layout/NoResults';
import Shortcut from '~/screens/Admin/Search/layout/Shortcut';
import { useAdminSearch } from '~/screens/Admin/utils/useAdminSearch';
import useClickOutside from '~/utils/hooks/useClickOutside';
import usePagination from '~/utils/hooks/usePagination';
import { t } from '~/utils/i18n';
import {
  SearchTag,
  SearchTagOption,
  SearchTagOptions,
  SearchTagValue,
  SearchTags,
} from '~/utils/types/adminSearch';
import ResultRow from './ResultRow';
import {
  Container,
  DisplayTags,
  SearchDropdown,
  SearchDropdownContainer,
  SearchDropdownCountryText,
  SearchDropdownFooter,
  SearchDropdownFooterLabel,
  SearchDropdownItem,
  SearchDropdownItemText,
  SearchDropdownSection,
  SearchDropdownSectionTitle,
  SearchList,
  SearchListControls,
  Spinner,
  StyledWebContainer,
  Title,
} from './style';

type SearchLayoutProps = {
  onEditPress: (id: string, type: string) => void;
  setSearchString: (value: string) => void;
  loading?: boolean;
  searchResults: AdminSearchResultType[];
  searchString: string;
  openDrawer: () => void;
};

const ENTRIES_PER_PAGE_OPTIONS = [5, 10, 25, 50];

export default function Search({
  onEditPress,
  setSearchString,
  loading = false,
  searchResults,
  searchString,
  openDrawer,
}: SearchLayoutProps): JSX.Element {
  const theme = useTheme();
  const isDesktop = useMediaQuery({ minWidth: theme.breakpoints.desktopMin });
  const [entriesPerPage, setEntries] = useState<number>(
    ENTRIES_PER_PAGE_OPTIONS[0],
  );
  const [dropdownData, setDropdownData] = useState<
    SearchTags[] | SearchTagOptions[]
  >(SEARCH_TAGS);

  const {
    value,
    tags,
    limit,
    setLimit,
    showSearchDropdown,
    setSearchDropdown,
    dropdownRef,
    handleDropdownItemPress,
    handleDropdownOptionPress,
    setDropdownDefaultItems,
    onTagChange,
    onTextChange,
    onRecognisedTag,
    handleDeleteTag,
  } = useAdminSearch(setSearchString, setDropdownData);

  useClickOutside(dropdownRef, () => setSearchDropdown(false));
  const numberOfResults = searchResults.length;

  const searchErrors = tags.current.some((tag: SearchTagValue) => tag.error);

  const {
    applyPaginationOnData,
    allowNextPage,
    allowPrevPage,
    currentPage,
    incrementPage,
    decrementPage,
    totalNumberOfPages,
    resetPagination,
    updatePage,
  } = usePagination({
    itemsPerPage: entriesPerPage,
    totalItems: numberOfResults,
  });

  useEffect(() => {
    resetPagination();
  }, [numberOfResults]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, true);
  }, []);
  const [renderCounter, setRenderCounter] = useState(0);
  const valueRef = useRef(value.current);

  useEffect(() => {
    valueRef.current = value.current;
  }, [value.current]);

  const pressedKeys: React.MutableRefObject<string[]> = useRef([]);
  const lastPressed: React.MutableRefObject<string> = useRef('');
  const rowRefs = useRef<(TouchableOpacity | null)[]>([]);
  const textRefs = useRef<(Text | null)[]>([]);
  const focusIndex: React.MutableRefObject<number> = useRef<number>(-1);

  const styles = StyleSheet.create({
    focus_row: {
      backgroundColor: theme.color.brand_02,
    },
    focus_text: {
      color: theme.color.brand_02,
    },
    unfocused_row_even: {
      backgroundColor: theme.color.base.c0,
    },
    unfocused_row_odd: {
      backgroundColor: theme.color.base.c0,
    },
    unfocused_text: {
      color: theme.color.base.c6,
    },
  });

  const setRowStyle = (
    index: number,
    style: {
      [key: string]: string;
    },
    textStyle: {
      [key: string]: string;
    },
  ) => {
    rowRefs.current[index]?.setNativeProps({
      style: style,
    });

    textRefs.current[index]?.setNativeProps({
      style: textStyle,
    });
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    let newKey = '';

    switch (event.key) {
      case 'Dead':
        if (event.code === 'KeyI') newKey = 'I';
        if (event.code === 'KeyU') newKey = 'U';
        break;
      case '©':
      case 'ẞ':
        newKey = 'G';
        break;
      default:
        // Handle other keys or do nothing
        break;
    }

    if (newKey) {
      event.preventDefault();
      const newEvent = new KeyboardEvent('keydown', {
        key: newKey,
        code: event.code,
        altKey: event.altKey,
      });
      document.dispatchEvent(newEvent);
      return;
    }

    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      setRowStyle(
        focusIndex.current,
        focusIndex.current % 2 === 0
          ? styles.unfocused_row_even
          : styles.unfocused_row_odd,
        styles.unfocused_text,
      );

      if (event.key === 'ArrowDown') {
        focusIndex.current =
          focusIndex.current + 1 > rowRefs.current.length - 1
            ? 0
            : focusIndex.current + 1;
      } else {
        focusIndex.current =
          focusIndex.current - 1 < 0
            ? rowRefs.current.length - 1
            : focusIndex.current - 1;
      }

      rowRefs.current[focusIndex.current]?.focus();
      setRowStyle(
        focusIndex.current,
        { ...styles.focus_row, outline: 'none' },
        styles.focus_text,
      );
    }

    if (event.key === 'Enter' && showSearchDropdown) {
      setSearchDropdown(false);
      return;
    }

    if (event.key === 'Enter' || event.key === ' ') {
      onRecognisedTag(valueRef.current);
    }

    if (lastPressed.current === event.key) {
      return;
    }

    pressedKeys.current.push(event.key);
    lastPressed.current = event.key;

    SEARCH_SHORTCUTS.forEach((shortcut) => {
      const pressedKeysLower = pressedKeys.current.map((key) =>
        key.length === 1 ? key.toLowerCase() : key,
      );
      const inputsLower = shortcut.inputs.map((key) =>
        key.length === 1 ? key.toLowerCase() : key,
      );
      const isPressedKeysMatch =
        pressedKeysLower.every(
          (input, index) => input === inputsLower[index],
        ) && pressedKeysLower.length === inputsLower.length;
      if (isPressedKeysMatch) {
        setLimit((limit) =>
          limit && limit.param === shortcut.action.param
            ? undefined
            : shortcut.action,
        );
        pressedKeys.current = [];
      }
    });
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    if (pressedKeys.current.includes(event.key)) {
      pressedKeys.current = [];
    }

    lastPressed.current = '';
  };

  useEffect(() => {
    if (document) {
      document.addEventListener('keyup', handleKeyUp);

      // Cleanup function to remove the event listener
      return () => {
        document.removeEventListener('keyup', handleKeyUp);
      };
    }
  }, [handleKeyUp, document]);

  const renderItem = ({ item }: { item: AdminSearchResultType }) => (
    <ResultRow
      isDesktop={isDesktop}
      result={item}
      onPress={() => onEditPress(item.id, item.__typename)}
    />
  );

  const resetFocusIndex = () => {
    focusIndex.current = -1;
  };

  type DataType = {
    tag: string;
    description: string;
    options?: SearchTagOption;
  }[];
  const renderDropdownSection = ({
    item: section,
    index: sectionIndex,
  }: {
    item: SearchTags | SearchTagOptions;
    index: number;
  }) => {
    let data: DataType | undefined;
    let description = section.description;
    if ('options' in section) {
      data = section.options?.map((option) => ({
        tag: section.tag,
        description: `${section.description}:`,
        options: option,
      }));

      description = description + ' ' + t('adminPanelSearch.options');
    } else if ('tags' in section) {
      if (section.tags.length) {
        data = section.tags;
      }
    }

    const sortedData = data?.reduce<Record<string, DataType>>((acc, type) => {
      const country = type?.options?.country;
      if (!acc[country as Country]) {
        acc[country as Country] = [];
      }
      acc[country as Country].push(type);
      return acc;
    }, {});

    const dataWithCountryHeaders = sortedData
      ? Object.entries(sortedData as Record<string, DataType>).reduce(
          (
            acc: (
              | SearchTag
              | SearchTagOptions
              | { header: boolean; country: string }
            )[],
            [country, countryData],
          ) => {
            acc.push({ header: true, country });
            acc.push(...(countryData as Array<SearchTagOptions>));
            return acc;
          },
          [],
        )
      : [];

    return (
      data &&
      sortedData && (
        <SearchDropdownSection<React.ElementType>
          ListHeaderComponent={
            <SearchDropdownSectionTitle index={sectionIndex}>
              {description}
            </SearchDropdownSectionTitle>
          }
          data={
            sortedData.undefined === undefined ? dataWithCountryHeaders : data
          }
          renderItem={
            'options' in section ? renderDropdownOption : renderDropdownItem
          }
        />
      )
    );
  };

  const renderDropdownOption = ({
    item,
    index,
  }: {
    item: {
      tag: string;
      description: string;
      options: SearchTagOptions;
      header?: boolean;
      country?: string;
    };
    index: number;
  }) => {
    if (item.header === undefined) {
      return (
        <SearchDropdownItem
          index={index}
          onPress={() => {
            handleDropdownOptionPress(item);
            resetFocusIndex();
            setDropdownData(SEARCH_TAGS);
          }}
          ref={(row) => row && rowRefs.current.push(row)}
        >
          <SearchDropdownItemText
            ref={(text) => text && textRefs.current.push(text)}
          >
            {item.options.description}
          </SearchDropdownItemText>
        </SearchDropdownItem>
      );
    } else {
      return (
        <SearchDropdownItem index={index}>
          <SearchDropdownCountryText>
            {item?.country &&
              `${t(`adminPanelSearch.flags.${item.country}`)} ${t(
                `adminPanelSearch.countryNames.${item.country}`,
              )}`}
          </SearchDropdownCountryText>
        </SearchDropdownItem>
      );
    }
  };

  const renderDropdownItem = ({
    item,
    index,
  }: {
    item: SearchTag;
    index: number;
  }) => {
    return (
      <SearchDropdownItem
        index={index}
        onPress={() => {
          handleDropdownItemPress(item, searchString);
          resetFocusIndex();
        }}
        ref={(row) => row && rowRefs.current.push(row)}
      >
        <SearchDropdownItemText
          ref={(text) => text && textRefs.current.push(text)}
        >
          {item.description}
        </SearchDropdownItemText>
      </SearchDropdownItem>
    );
  };

  const paginatedData = applyPaginationOnData(searchResults);
  const resultsFound = numberOfResults > 0;
  rowRefs.current = [];
  textRefs.current = [];
  const tagsWithError =
    tags.current.filter((tag) => tag.error).map((tag) => tag.tag) || [];

  return (
    <Container>
      <AdminHeader
        title={t('adminPanelSearch.search')}
        onBack={() => null}
        breadcrumbs={['Search']}
        buttonTitle={t('adminPanelSearch.saveChanges')}
        onOpenDrawer={openDrawer}
      />
      <StyledWebContainer isDesktop={isDesktop}>
        <InputSearchField
          testID={'inputSearch'}
          iconName={'search-md'}
          label={t('g.search')}
          value={value.current}
          renderCounter={renderCounter}
          onChangeText={(text) => {
            onTextChange(text, setRenderCounter, renderCounter);
          }}
          onChangeTag={(tag, text) => {
            onTagChange(tag, text);
          }}
          tags={tags.current}
          limit={limit}
          handleDeleteTag={handleDeleteTag}
          onFocus={() => {
            setDropdownDefaultItems();
            setSearchDropdown(true);
          }}
        />
        {(showSearchDropdown || searchErrors) && (
          <SearchDropdownContainer ref={dropdownRef}>
            {searchErrors && !showSearchDropdown ? (
              <>
                {tagsWithError.length > 0 && (
                  <DisplayTags>{tagsWithError.join(', ')}</DisplayTags>
                )}
                <InlineNotification
                  severity={SeverityEnum.Error}
                  text={t('adminPanelSearch.inlineNotification')}
                />
              </>
            ) : null}
            {showSearchDropdown && (
              <SearchDropdown<React.ElementType>
                data={dropdownData}
                renderItem={renderDropdownSection}
              />
            )}
            <SearchDropdownFooter>
              <SearchDropdownFooterLabel>
                {t('adminPanelSearch.limitResults')}
              </SearchDropdownFooterLabel>
              {SEARCH_SHORTCUTS.map((shortcut, index) => (
                <Shortcut
                  keys={shortcut.keys}
                  label={shortcut.label}
                  key={`shortcut-${index}`}
                />
              ))}
            </SearchDropdownFooter>
          </SearchDropdownContainer>
        )}
        <Title>{t('adminPanelSearch.results')}</Title>
        {loading ? (
          <Spinner testID="searchLoading" />
        ) : (
          <SearchList<React.ElementType>
            data={paginatedData}
            renderItem={renderItem}
            ListFooterComponent={
              resultsFound && (
                <SearchListControls>
                  <StepSizePicker
                    title={t('adminPanelSearch.entries')}
                    onStepSizeChange={(v: number) => {
                      setEntries(v);
                      resetPagination();
                    }}
                    options={ENTRIES_PER_PAGE_OPTIONS}
                    stepSize={entriesPerPage}
                  />
                  <Paginator
                    currentPage={currentPage}
                    allowNext={allowNextPage}
                    allowPrev={allowPrevPage}
                    onNextClick={incrementPage}
                    onPrevClick={decrementPage}
                    numberOfPages={totalNumberOfPages}
                    onPageClick={updatePage}
                  />
                </SearchListControls>
              )
            }
            ListEmptyComponent={<NoResults />}
            keyExtractor={(searchData: AdminSearchResultType) => searchData.id}
          />
        )}
      </StyledWebContainer>
    </Container>
  );
}
