import React, { useState } from 'react';
import { t } from '~/utils/i18n';
import {
  Container,
  IconButton,
  YearContainer,
  YearText,
  SectionYearLabel,
} from './style';

type YearSelectorProps = {
  initialYear: number; // Changed to accept a number
  sectionColor?: string;
  setYear: (year: number) => void; // Changed to accept a number
};

const YearSelector = ({
  initialYear,
  sectionColor,
  setYear,
}: YearSelectorProps): JSX.Element => {
  const [year, setInternalYear] = useState(initialYear);

  const incrementYear = () => {
    const nextYear = year + 1;
    setInternalYear(nextYear);
    setYear(nextYear); // Update the external state, if needed
  };

  const decrementYear = () => {
    const prevYear = year - 1;
    setInternalYear(prevYear);
    setYear(prevYear); // Update the external state, if needed
  };

  return (
    <Container>
      <SectionYearLabel sectionColor={sectionColor}>
        {t('groupSetup.graduationYear')}
      </SectionYearLabel>
      <YearContainer>
        <IconButton
          testID="decrementYear"
          name={'minus'}
          onPress={decrementYear}
        />
        <YearText>{year}</YearText>
        <IconButton
          testID="incrementYear"
          name={'plus'}
          onPress={incrementYear}
        />
      </YearContainer>
    </Container>
  );
};

export default YearSelector;
