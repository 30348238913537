import React, { useState } from 'react';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { clearText } from 'react-native-markdown-editor';
import Snackbar from '~/components/Snackbar';
import {
  DEFAULT_LIST_OFFSET,
  REPORT_DEFAULT_MAX_CHARS,
} from '~/data/constants';
import { useCreateReportsInstanceMutation } from '~/data/operations/yearbook/createReportsInstance';
import {
  readReportsInstancesQuery,
  writeReportsInstancesQuery,
} from '~/data/operations/yearbook/helpers';
import { useModuleInstanceQuery } from '~/data/operations/yearbook/moduleInstance';
import { useReportsInstanceQuery } from '~/data/operations/yearbook/reportsInstance';
import { useUpdateReportsInstanceMutation } from '~/data/operations/yearbook/updateReportsInstance';
import { YearbookStackParamList } from '~/navigation/types';
import { t } from '~/utils/i18n';
import ReportDetailLayout from './layout';

type ReportDetailNavProp = StackNavigationProp<
  YearbookStackParamList,
  'ReportDetail'
>;

type ReportDetailRouteProp = RouteProp<YearbookStackParamList, 'ReportDetail'>;

export default function ReportDetail(): JSX.Element {
  const navigation = useNavigation<ReportDetailNavProp>();

  const {
    params: { moduleInstanceId, reportInstanceId, viewOnly },
  } = useRoute<ReportDetailRouteProp>();

  const [currentReportInstanceId, setCurrentReportInstanceId] = useState(
    () => reportInstanceId,
  );

  const { data: moduleInstanceData } = useModuleInstanceQuery({
    variables: {
      id: moduleInstanceId,
    },
  });

  const reportsSetupConfig = moduleInstanceData?.moduleInstance?.reportsSetup;

  const { data: reportsInstanceData } = useReportsInstanceQuery({
    skip: !currentReportInstanceId,
    variables: {
      id: currentReportInstanceId as string,
    },
  });

  const reportInstance = reportsInstanceData?.reportsInstance;

  const [createReportsInstance, { loading: createReportLoading }] =
    useCreateReportsInstanceMutation({
      update(cache, { data }) {
        const newReportsInstance = data?.createReportsInstance?.reportsInstance;

        const reportInstancesVar = {
          after: null,
          first: DEFAULT_LIST_OFFSET,
          moduleInstance: moduleInstanceId,
        };

        const currentReportsInstancesQuery = readReportsInstancesQuery({
          cache,
          variables: reportInstancesVar,
        });

        if (
          currentReportsInstancesQuery?.reportsInstances?.edges &&
          newReportsInstance
        ) {
          writeReportsInstancesQuery({
            cache,
            variables: reportInstancesVar,
            data: {
              ...currentReportsInstancesQuery,
              reportsInstances: {
                ...currentReportsInstancesQuery.reportsInstances,
                edges: [
                  ...currentReportsInstancesQuery.reportsInstances.edges,
                  {
                    __typename: 'ReportsInstanceNodeEdge',
                    node: newReportsInstance,
                  },
                ],
              },
            },
          });
        }
      },
    });

  const [updateReportsInstance, { loading: updateReportLoading }] =
    useUpdateReportsInstanceMutation();

  const onCreateReportInstance = async (text: string) => {
    try {
      const textLines = text.split('\n');
      const firstLineText = textLines.length > 0 ? textLines[0] : text;
      const cleanText = clearText(firstLineText);

      const { data } = await createReportsInstance({
        variables: {
          input: {
            moduleInstance: moduleInstanceId,
            text,
            title: cleanText,
            maxChars: REPORT_DEFAULT_MAX_CHARS,
          },
        },
      });
      const reportInstanceId = data?.createReportsInstance?.reportsInstance?.id;
      setCurrentReportInstanceId(reportInstanceId);
      return reportInstanceId;
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onSave = async (text: string) => {
    try {
      if (currentReportInstanceId && reportInstance) {
        await updateReportsInstance({
          variables: {
            input: {
              id: currentReportInstanceId,
              text,
              title: reportInstance.title,
              maxChars: reportInstance.maxChars,
              editTeam: reportInstance.editTeam?.id,
              viewTeam: reportInstance.viewTeam?.id,
            },
          },
        });
      } else {
        await onCreateReportInstance(text);
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onSetting = async (text?: string) => {
    if (currentReportInstanceId) {
      navigation.navigate('ReportSettings', {
        moduleInstanceId,
        reportInstanceId: currentReportInstanceId,
      });
    } else {
      // note: for the case when the report instance is not created
      // yet but user try to navigate setting.
      const reportInstanceId = await onCreateReportInstance(
        text || t('reportDetail.defaultTitle'),
      );
      reportInstanceId &&
        navigation.navigate('ReportSettings', {
          moduleInstanceId,
          reportInstanceId,
        });
    }
  };

  return (
    <ReportDetailLayout
      reportInstance={reportInstance}
      saving={createReportLoading || updateReportLoading}
      viewOnly={viewOnly}
      allowImages={reportsSetupConfig?.allowImages}
      onBack={() => navigation.goBack()}
      onSetting={onSetting}
      onSave={onSave}
    />
  );
}
