import styled from 'styled-components/native';
import { WebContainer } from '~/components/WebGrid';
import { ResponsiveProps } from '~/components/common/style.web';
import { TabScreenContainer } from '~/screens/style';

export const Container = styled(TabScreenContainer)`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c0};
  overflow: auto;
`;

export const StyledWebContainer = styled(WebContainer)`
  height: calc(100% - 117px);
`;

export const SearchDropdownContainer = styled.View<ResponsiveProps>`
  border-width: 2px;
  border-radius: 18px;
  border-color: ${({ theme }) => theme.color.base.c4};
  background-color: ${({ theme }) => theme.color.base.c0};
  flex-grow: 1;
  overflow-y: auto;
  position: absolute;
  top: 88px;
  right: 0;
  left: 0;
  max-height: calc(100% - 88px);
  z-index: 1;
  margin-horizontal: ${({ isDesktop }) => (!isDesktop ? 16 : 0)}px;
`;

export const SearchDropdown = styled.FlatList`
  padding: 24px 24px 64px 24px;
  flex-shrink: 1;
`;

interface IIndexed {
  index: number;
}

export const SearchDropdownSectionTitle = styled.Text<IIndexed>`
  padding-top: ${({ index }) => (index === 0 ? 0 : 32)}px;
  padding-bottom: 8px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
`;

export const SearchDropdownSection = styled.FlatList``;

export const SearchDropdownItem = styled.TouchableOpacity<IIndexed>`
  flex: 1;
  background-color: ${({ index, theme }) =>
    index % 2 == 1 && theme.color.base.c0};
  padding-vertical: 6px;
  padding-horizontal: 18px;
  min-height: 32px;
  justify-content: center;
`;

export const SearchDropdownItemText = styled.Text`
  color: ${({ theme }) => theme.color.base.c6};
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 12px;
`;

export const SearchDropdownCountryText = styled.Text`
  color: ${({ theme }) => theme.color.base.c9};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 12px;
`;

export const SearchList = styled.FlatList`
  padding: 16px;
  min-height: 55vh;
`;

export const SearchDropdownFooter = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.color.base.c0};
  padding: 10px 24px 18px 24px;
  flex-wrap: wrap;
`;

export const SearchDropdownFooterLabel = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 12px;
  padding-top: 8px;
`;

export const Title = styled.Text`
  margin-top: 48px;
  color: ${({ theme }) => theme.color.base.c9};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 26px;
`;

export const SearchListControls = styled.View`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 16px 0;
`;

export const Spinner = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c4,
  size: 'large',
}))`
  margin: 20px 0;
`;

export const DisplayTags = styled.View`
  margin-top: 13px;
  margin-left: 17px;
  color: ${({ theme }) => theme.color.base.c9};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
`;
