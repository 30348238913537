/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation CreateCustomPagesInstance
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  CreateCustomPagesInstance,
  CreateCustomPagesInstanceVariables,
} from './types/CreateCustomPagesInstance';
import { CREATE_CUSTOM_PAGES_INSTANCE } from './';

export function useCreateCustomPagesInstanceMutation(
  options?: MutationHookOptions<
    CreateCustomPagesInstance,
    CreateCustomPagesInstanceVariables
  >,
): MutationTuple<
  CreateCustomPagesInstance,
  CreateCustomPagesInstanceVariables
> {
  return useMutation<
    CreateCustomPagesInstance,
    CreateCustomPagesInstanceVariables
  >(CREATE_CUSTOM_PAGES_INSTANCE, options);
}

export function createCreateCustomPagesInstanceMock({
  variables,
  data,
  error,
}: {
  variables?: CreateCustomPagesInstanceVariables;
  data?: CreateCustomPagesInstance;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: CREATE_CUSTOM_PAGES_INSTANCE,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
