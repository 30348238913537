import React, { useState } from 'react';
import { Keyboard } from 'react-native';
import Button from '~/components/Button';
import InputAreaField from '~/components/InputAreaField';
import NavHeader from '~/components/NavHeader';
import { t } from '~/utils/i18n';
import {
  Container,
  ScrollView,
  SaveButtonContainer,
  InputAreaView,
} from './style';

type AddQuoteLayoutProps = {
  maxCharCount?: number;
  loading: boolean;
  onBack: () => void;
  onSave: (text: string) => void;
};

export default function AddQuote({
  maxCharCount,
  loading,
  onBack,
  onSave,
}: AddQuoteLayoutProps): JSX.Element {
  const [quote, setQuote] = useState<string>('');

  const handleTextChange = (text: string) => {
    setQuote(text);
  };

  return (
    <Container>
      <NavHeader
        backButtonTestID={'buttonBack'}
        title={t('screens.addQuote')}
        onBackPress={onBack}
      />
      <ScrollView>
        <InputAreaView>
          <InputAreaField
            testID={'inputText'}
            value={quote}
            placeholder={t('yearbookQuotes.writeQuoteInputHint')}
            maxCharCount={maxCharCount}
            onChangeText={handleTextChange}
          />
        </InputAreaView>
        <SaveButtonContainer>
          <Button
            testID={'buttonPost'}
            state={
              maxCharCount && quote.length > maxCharCount
                ? 'disabled'
                : 'default'
            }
            text={t('yearbookQuotes.writeQuoteButton')}
            loading={loading}
            flex
            onPress={() => {
              Keyboard.dismiss();
              onSave(quote);
            }}
          />
        </SaveButtonContainer>
      </ScrollView>
    </Container>
  );
}
