import styled from 'styled-components/native';

export const QuestionsWrapper = styled.View`
  margin-vertical: 24px;
  margin-horizontal: -16px;
`;

export const SectionButtonWrapper = styled.View`
  padding-horizontal: 16px;
`;

export const QuestionsFooter = styled.View`
  align-items: center;
  padding-bottom: 12px;
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c7,
}))``;
