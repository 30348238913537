import { Platform } from 'react-native';
import styled from 'styled-components/native';
import Badge from '~/components/Badge';
import Image from '~/components/Image';
import { EventType } from '~/data/operations/global';

export const Container = styled.View``;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 16px;
  margin-bottom: 3px;
`;
export const SubTitle = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c5};
  font-size: 12px;
`;
export const BottomWrapper = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
`;
export const IconWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  padding: 4px;
  border-radius: 6px;
  flex: 1;
`;
export const Card = styled.TouchableOpacity`
  background-color: ${({ theme }) => theme.color.base.c0};
  max-width: ${({ theme }) => theme.ratio(373)};
  height: ${({ theme }) => theme.ratio(118)};
  border-radius: 12px;
  box-shadow: 0px 3px 3px ${({ theme }) => theme.color.base.c3};
  padding: 16px;
  shadow-opacity: 0.1;
  shadow-radius: 2.22px;
  width: 100%;
  ${Platform.select({
    ios: `
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
    `,
    android: `
      elevation: 5;
    `,
  })};
`;

export const TopWrapper = styled.View`
  flex-direction: row;
  width: 100%;
`;

export const ImageBox = styled(Image)`
  background-color: ${({ theme }) => theme.color.base.c3};
  width: 40px;
  height: 40px;
  border-radius: 5px;
  margin-right: 15px;
`;

export const TitleAndDate = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 85%;
`;

export const TitleWrapper = styled.View``;

export const LocationText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 12px;
  margin-left: 10px;
`;

export const AttendeesText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 12px;
  text-align: right;
  flex: 1;
`;

const badgeColorMap: Record<EventType, string> = {
  [EventType.MEETING]: 'green',
  [EventType.EVENT]: 'tertiary',
  [EventType.PARTY]: 'primary',
};

interface Badge {
  eventType: EventType | null;
}

export const CustomBadge = styled(Badge).attrs<Badge>(({ eventType }) => ({
  colorTheme: eventType ? badgeColorMap[eventType] : 'gray',
}))<Badge>``;
