import React from 'react';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import {
  DEFAULT_RANKINGS_LIST_OFFSET,
  NO_PAG_LIST_OFFSET,
} from '~/data/constants';
import { CustomList } from '~/data/models/yearbook';
import { useCreateRankingsQuestionMutation } from '~/data/operations/yearbook/createRankingsQuestion';
import { useCustomListsQuery } from '~/data/operations/yearbook/customLists';
import {
  readRankingsQuestionsQuery,
  writeRankingsQuestionsQuery,
} from '~/data/operations/yearbook/helpers';
import { useRankingsQuestionQuery } from '~/data/operations/yearbook/rankingsQuestion';
import { useUpdateRankingsQuestionMutation } from '~/data/operations/yearbook/updateRankingsQuestion';
import { YearbookStackParamList } from '~/navigation/types';
import RankingsAddQuestionLayout, { RankingQuestionInput } from './layout';

type RankingsAddQuestionNavProp = StackNavigationProp<
  YearbookStackParamList,
  'RankingsAddQuestion'
>;

type RankingsAddQuestionRouteProp = RouteProp<
  YearbookStackParamList,
  'RankingsAddQuestion'
>;

export default function RankingsAddQuestion(): JSX.Element {
  const navigation = useNavigation<RankingsAddQuestionNavProp>();
  const {
    params: { moduleInstanceId, rankingQuestionId },
  } = useRoute<RankingsAddQuestionRouteProp>();

  const { authUserGroupId } = useAuth();

  const { data: rankingsQuestionData, loading: rankingQuestionLoading } =
    useRankingsQuestionQuery({
      skip: !rankingQuestionId,
      variables: {
        id: rankingQuestionId as string,
      },
    });

  const initialRankingQuestion =
    rankingsQuestionData?.rankingsQuestion || undefined;

  const { data: customListsData } = useCustomListsQuery({
    variables: {
      first: NO_PAG_LIST_OFFSET,
      moduleInstance: moduleInstanceId,
    },
  });
  const customLists: CustomList[] =
    (customListsData?.customLists?.edges.map(
      (edge) => edge?.node,
    ) as CustomList[]) || [];

  const [createRankingsQuestion, { loading: createRankingsQuestionLoading }] =
    useCreateRankingsQuestionMutation({
      update(cache, { data }) {
        const rankingQuestionsVar = {
          after: null,
          first: DEFAULT_RANKINGS_LIST_OFFSET,
          moduleInstance: moduleInstanceId,
          authUserGroupId,
        };

        const newRankingsQuestion =
          data?.createRankingsQuestion?.rankingsQuestion;
        const currentRankingsQuestionsQuery = readRankingsQuestionsQuery({
          cache,
          variables: rankingQuestionsVar,
        });
        if (
          currentRankingsQuestionsQuery?.rankingsQuestions?.edges &&
          newRankingsQuestion
        ) {
          writeRankingsQuestionsQuery({
            cache,
            variables: rankingQuestionsVar,
            data: {
              ...currentRankingsQuestionsQuery,
              rankingsQuestions: {
                ...currentRankingsQuestionsQuery.rankingsQuestions,
                edges: [
                  ...currentRankingsQuestionsQuery.rankingsQuestions.edges,
                  {
                    __typename: 'RankingsQuestionNodeEdge',
                    node: newRankingsQuestion,
                  },
                ],
              },
            },
          });
        }
      },
    });

  const [updateRankingsQuestion, { loading: updateRankingsQuestionLoading }] =
    useUpdateRankingsQuestionMutation();

  const onAddQuestion = async ({
    question,
    answerType,
    allowedVotes,
    onlyOppositeGender,
    customListId,
  }: RankingQuestionInput) => {
    try {
      if (rankingQuestionId) {
        const { data } = await updateRankingsQuestion({
          variables: {
            input: {
              id: rankingQuestionId,
              question,
              answerType,
              allowedVotes,
              onlyOppositeGender,
              customList: customListId,
            },
          },
        });

        const errors = data?.updateRankingsQuestion?.errors;
        if (errors && errors.length > 0) {
          const messages = errors.map((error) => error?.messages[0]);
          const errorMessage = messages?.[0] as string;
          Snackbar.show(errorMessage);
          return;
        }
      } else {
        const { data } = await createRankingsQuestion({
          variables: {
            input: {
              moduleInstance: moduleInstanceId,
              question,
              answerType,
              allowedVotes,
              onlyOppositeGender,
              customList: customListId,
            },
          },
        });
        const errors = data?.createRankingsQuestion?.errors;
        if (errors && errors.length > 0) {
          const messages = errors.map((error) => error?.messages[0]);
          const errorMessage = messages?.[0] as string;
          Snackbar.show(errorMessage);
          return;
        }
      }
      navigation.goBack();
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  return (
    <RankingsAddQuestionLayout
      initialRankingQuestion={initialRankingQuestion}
      loading={rankingQuestionLoading}
      saveLoading={
        createRankingsQuestionLoading || updateRankingsQuestionLoading
      }
      customLists={customLists}
      onAddQuestion={onAddQuestion}
      onBack={() => navigation.goBack()}
      onSelectedList={(customListId, customListTitle) =>
        navigation.navigate('RankingsCustomList', {
          moduleInstanceId,
          customListId,
          customListTitle,
        })
      }
    />
  );
}
