import React from 'react';
import { general } from '~/assets/imgs';
import NavHeader from '~/components/NavHeader';
import { t } from '~/utils/i18n';
import {
  Container,
  Image,
  ImageContainer,
  ImageSubTitle,
  ImageTitle,
} from './style';

export default function WorkInProgress(): JSX.Element {
  return (
    <Container>
      <NavHeader
        backButtonTestID={'buttonBack'}
        title={t('screens.workInProgress')}
      />
      <ImageContainer>
        <Image source={general.workInprogress} />
        <ImageTitle>This feature is coming soon</ImageTitle>
        <ImageSubTitle>We are working on it!</ImageSubTitle>
      </ImageContainer>
    </Container>
  );
}
