import styled from 'styled-components/native';
import SectionHeaderComp from '~/components/SectionHeader';
import { TabScreenContainer } from '~/screens/style';

export const Container = styled(TabScreenContainer)``;

export const HeaderContainer = styled.View`
  padding-bottom: 16px;
`;

export const TeamTypeView = styled.View`
  padding-horizontal: 16px;
  padding-top: 16px;
  padding-bottom: 32px;
`;

export const MemberList = styled.FlatList``;

export const MemberCellView = styled.View`
  padding-horizontal: 16px;
`;

export const Name = styled.Text`
  margin-top: 8px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 22px;
`;

export const Header = styled.View`
  align-items: center;
  padding: 16px;
`;

export const InputContent = styled.View`
  padding-horizontal: 16px;
  padding-bottom: 24px;
`;

export const SectionHeader = styled(SectionHeaderComp)`
  padding-horizontal: 16px;
`;

export const LoadingContent = styled.View`
  padding: 16px;
  align-items: center;
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c4,
}))``;

export const RefreshControl = styled.RefreshControl.attrs(({ theme }) => ({
  tintColor: theme.color.base.c4,
  colors: [theme.color.base.c4],
}))``;

export const Footer = styled.View`
  padding-top: 48px;
`;

export const ButtonView = styled.View`
  padding: 16px;
`;
