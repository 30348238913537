/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation DeleteEvent
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { DeleteEvent, DeleteEventVariables } from './types/DeleteEvent';
import { DELETE_EVENT } from './';

export function useDeleteEventMutation(
  options?: MutationHookOptions<DeleteEvent, DeleteEventVariables>,
): MutationTuple<DeleteEvent, DeleteEventVariables> {
  return useMutation<DeleteEvent, DeleteEventVariables>(DELETE_EVENT, options);
}

export function createDeleteEventMock({
  variables,
  data,
  error,
}: {
  variables?: DeleteEventVariables;
  data?: DeleteEvent;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: DELETE_EVENT,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
