import React from 'react';
import {
  CompositeNavigationProp,
  useNavigation,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import useAppLocale from '~/data/hooks/useAppLocale';
import { AuthStackParamList, RootStackParamList } from '~/navigation/types';
import NotFoundLayout from './layout';

type NotFoundNavProps = CompositeNavigationProp<
  StackNavigationProp<AuthStackParamList>,
  StackNavigationProp<RootStackParamList>
>;

export default function NotFound(): JSX.Element {
  const { locale, onChangeLocale } = useAppLocale();
  const navigation = useNavigation<NotFoundNavProps>();

  const goToMainPage = () => {
    navigation.navigate('Launch', {});
  };

  const openContactSupport = () => {
    window.Intercom('showMessages');
  };

  return (
    <NotFoundLayout
      selectedLocale={locale}
      onSelectedLocale={onChangeLocale}
      goToMainPage={goToMainPage}
      openContactSupport={openContactSupport}
    />
  );
}
