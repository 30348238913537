import styled from 'styled-components/native';
import { TabScreenContainer } from '~/screens/style';

export const SafeAreaContainer = styled(TabScreenContainer)``;

export const ScrollView = styled.ScrollView``;

export const Section = styled.View`
  padding-horizontal: 16px;
  padding-top: 8px;
  padding-bottom: 16px;
`;

export const SectionContent = styled.View`
  padding-horizontal: 16px;
`;

export const TeamList = styled.FlatList`
  padding-bottom: 16px;
`;

export const TeamListHeaderSeparator = styled.View`
  width: 16px;
`;

export const TeamListSeparator = styled.View`
  width: 8px;
`;

export const Content = styled.View`
  height: ${({ theme }) => theme.ratio(150)};
  align-items: center;
  justify-content: center;
`;

export const EmptyText = styled.Text`
  color: ${({ theme }) => theme.color.base.c7};
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 16px;
`;
