import {
  parseISO,
  differenceInDays,
  formatRelative,
  formatDistance,
  getDate,
  differenceInMilliseconds,
  getUnixTime,
} from 'date-fns';
import { format } from 'date-fns-tz';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';
import tr from 'date-fns/locale/tr';
import * as Localization from 'expo-localization';
import { CalendarDateObject } from '~/data/models/calendar';
import { getI18nLocale } from '../i18n';

export function formattedDate(
  date: string | Date,
  formatStr = 'dd/MM/yyyy',
  timeZone = Localization.timezone,
): string {
  try {
    if (typeof date === 'string') {
      return format(parseISO(date), formatStr, {
        timeZone,
      });
    }
    return format(date, formatStr, {
      timeZone,
    });
  } catch (e) {
    return '';
  }
}

export function getUTCTime(date: Date): string {
  //seems weird but it's the only way I found so far for getting the time that is stored with timezome
  //but it always format date with locale timezone even without date-fns-tz (not sure if due to js, rn or expo)

  const hoursStr = date.getUTCHours().toString().padStart(2, '0');
  const minutesStr = date.getUTCMinutes().toString().padStart(2, '0');
  const secondsStr = date.getUTCSeconds().toString().padStart(2, '0');
  return `${hoursStr}:${minutesStr}:${secondsStr}`;
}

export function calendarDay(date = new Date()): string {
  try {
    return format(date, 'yyyy-MM-dd', { timeZone: Localization.timezone });
  } catch (e) {
    return '';
  }
}

export function calendarDateObject(dateString: string): CalendarDateObject {
  const date = dateString.includes('T')
    ? parseISO(dateString)
    : parseISO(`${dateString}T00:00:00.000000+00:00`);
  return {
    year: date.getUTCFullYear(),
    month: date.getUTCMonth() + 1,
    day: getDate(date),
    timestamp: date.getTime(),
    dateString,
  };
}

export function daysSince(date: string, now = new Date()): number {
  try {
    return differenceInDays(now, parseISO(date));
  } catch (e) {
    return 0;
  }
}

export function daysTo(date: string, now = new Date()): number {
  try {
    return differenceInDays(parseISO(date), now);
  } catch (e) {
    return 0;
  }
}

export function formattedRelativeDate(date: string, now = new Date()): string {
  try {
    const enFormatRelativeLocale: { [key: string]: string } = {
      lastWeek: "'last' eeee 'at' p",
      yesterday: "'yesterday at' p",
      today: "'today at' p",
      tomorrow: "'tomorrow at' p",
      nextWeek: "eeee 'at' p",
      other: "do 'of' MMMM 'at' HH:mm",
    };
    const enLocale = {
      ...en,
      formatRelative: (token: string) => enFormatRelativeLocale[token],
    };

    const deFormatRelativeLocale: { [key: string]: string } = {
      lastWeek: "'letzten' eeee 'um' p 'Uhr'",
      yesterday: "'gestern um' p 'Uhr'",
      today: "'heute um' p 'Uhr'",
      tomorrow: "'morgen um' p 'Uhr'",
      nextWeek: "eeee 'um' p 'Uhr'",
      other: "do MMMM 'um' HH:mm 'Uhr'",
    };
    const deLocale = {
      ...de,
      formatRelative: (token: string) => deFormatRelativeLocale[token],
    };

    const trFormatRelativeLocale: { [key: string]: string } = {
      lastWeek: "'geçen hafta,' eeee p",
      yesterday: "'dün,' p",
      today: "'bugün,' p",
      tomorrow: "'yarın,' p",
      nextWeek: "eeee',' p",
      other: "do MMMM',' HH:mm",
    };
    const trLocale = {
      ...tr,
      formatRelative: (token: string) => trFormatRelativeLocale[token],
    };
    const localeMap: Record<string, typeof deLocale> = {
      // any other locale type okay as well deLocale, enLocale, trLocale
      en: enLocale,
      de: deLocale,
      tr: trLocale,
    };

    const i18nLocale = getI18nLocale();

    const locale = localeMap[i18nLocale] || enLocale;

    return formatRelative(parseISO(date), now, { locale });
  } catch (e) {
    return '';
  }
}

export function formattedTimeAgo(date: string, now = new Date()): string {
  try {
    return formatDistance(parseISO(date), now, { addSuffix: true });
  } catch (e) {
    return '';
  }
}

export function isDateExpired(date: string, now = new Date()): boolean {
  try {
    return differenceInMilliseconds(parseISO(date), now) < 0;
  } catch (e) {
    return true;
  }
}

export function isTokenDateExpired(exp: number, now = new Date()): boolean {
  try {
    return getUnixTime(now) >= exp;
  } catch (e) {
    return true;
  }
}

export const combineDateAndTime = (
  dateString: string | undefined,
  timeDate: string | undefined,
): string => {
  const now = new Date();
  const date = dateString ? parseISO(dateString) : now;

  const hours = timeDate ? parseISO(`1970-01-01T${timeDate}`).getUTCHours() : 0;
  const minutes = timeDate
    ? parseISO(`1970-01-01T${timeDate}`).getUTCMinutes()
    : 0;

  const combinedDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    hours,
    minutes,
  );

  return format(combinedDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
};

/**
 * TODO:
 * - Handle locale
 * - Handle timezone
 */
