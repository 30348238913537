/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation CreatePost
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { CreatePost, CreatePostVariables } from './types/CreatePost';
import { CREATE_POST } from './';

export function useCreatePostMutation(
  options?: MutationHookOptions<CreatePost, CreatePostVariables>,
): MutationTuple<CreatePost, CreatePostVariables> {
  return useMutation<CreatePost, CreatePostVariables>(CREATE_POST, options);
}

export function createCreatePostMock({
  variables,
  data,
  error,
}: {
  variables?: CreatePostVariables;
  data?: CreatePost;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: CREATE_POST,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
