/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query CollagesAlbums
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  CollagesAlbums,
  CollagesAlbumsVariables,
} from './types/CollagesAlbums';
import { COLLAGES_ALBUMS } from './';

export function useCollagesAlbumsQuery(
  options?: QueryHookOptions<CollagesAlbums, CollagesAlbumsVariables>,
): QueryResult<CollagesAlbums, CollagesAlbumsVariables> {
  return useQuery<CollagesAlbums, CollagesAlbumsVariables>(
    COLLAGES_ALBUMS,
    options,
  );
}

export function useCollagesAlbumsLazyQuery(
  options?: LazyQueryHookOptions<CollagesAlbums, CollagesAlbumsVariables>,
): QueryTuple<CollagesAlbums, CollagesAlbumsVariables> {
  return useLazyQuery<CollagesAlbums, CollagesAlbumsVariables>(
    COLLAGES_ALBUMS,
    options,
  );
}

export function createCollagesAlbumsMock({
  variables,
  data,
  error,
}: {
  variables?: CollagesAlbumsVariables;
  data?: CollagesAlbums;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: COLLAGES_ALBUMS,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
