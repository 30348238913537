import React from 'react';
import Avatar from '~/components/Avatar';
import Shimmer from '~/components/Shimmer';
import { mapUser } from '~/data/mappers';
import { ProfilePageComment } from '~/data/models/yearbook';
import { formattedDate } from '~/utils/dates';
import { t } from '~/utils/i18n';
import {
  Container,
  Content,
  Text,
  Footer,
  FooterContent,
  Name,
  Date,
  ButtonContainer,
  ReportIcon,
  ReportText,
  DeleteIcon,
  DeleteText,
} from './style';

type PageCommentCellProps = {
  profilePageComment?: ProfilePageComment;
  viewOnly?: boolean;
  anonymous?: boolean;
  disableEdit?: boolean;
  onReport: () => void;
  onDelete: () => void;
  onCommentPress: (
    userGroupId: string,
    profilePageCommentId: string,
    currentText: string,
    disableEdit: boolean,
  ) => void;
};

export default function PageCommentCell({
  profilePageComment,
  viewOnly,
  anonymous,
  disableEdit,
  onReport,
  onDelete,
  onCommentPress,
}: PageCommentCellProps): JSX.Element {
  if (!profilePageComment) {
    return (
      <Container>
        <Content>
          <Shimmer w={240} g={'g2'} />
          <Shimmer w={200} mt={8} g={'g2'} />
        </Content>
        <Footer>
          <Shimmer w={32} h={32} g={'g2'} br={24} />
          <FooterContent>
            <Shimmer h={14} g={'g3'} />
            <Shimmer h={14} mt={4} g={'g1'} />
          </FooterContent>
        </Footer>
      </Container>
    );
  }

  const mappedUser = mapUser(profilePageComment.comentatorUserGroup.user);
  return (
    <Container testID={`PageCommentCell:${profilePageComment.id}`}>
      <Content
        onPress={() => {
          if (disableEdit) {
            onCommentPress(
              profilePageComment.profileUserGroup.id,
              profilePageComment.id,
              profilePageComment.text,
              disableEdit as boolean,
            );
          }
        }}
      >
        <Text>{profilePageComment.text}</Text>
      </Content>
      <Footer>
        <Avatar
          size={32}
          userGroup={anonymous ? null : profilePageComment.comentatorUserGroup}
        />
        <FooterContent>
          <Name>{mappedUser && !anonymous ? mappedUser.name : ''}</Name>
          <Date>{formattedDate(profilePageComment.created, 'dd.MM.yyyy')}</Date>
        </FooterContent>
        {!viewOnly && (
          <>
            <ButtonContainer onPress={onReport}>
              <ReportIcon />
              <ReportText>{t('g.report')}</ReportText>
            </ButtonContainer>
            <ButtonContainer onPress={onDelete}>
              <DeleteIcon />
              <DeleteText>{t('g.delete')}</DeleteText>
            </ButtonContainer>
          </>
        )}
      </Footer>
    </Container>
  );
}
