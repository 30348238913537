import React from 'react';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { flattenAdminSearchResults } from '~/data/mappers';
import { AdminSearchResults } from '~/data/models/admin';
import { useAdminSearchQuery } from '~/data/operations/admin/adminSearch';
import { AdminStackParamList, SearchStackParamList } from '~/navigation/types';
import { extractParametersFromString } from '~/utils/helpers/index';
import useDebouncedState from '~/utils/hooks/useDebouncedState';
import SearchLayout from './layout';

type SearchNavProp = StackNavigationProp<SearchStackParamList, 'Search'>;

const DEBOUNCE_BEFORE_SEARCH = 500;

export default function Search(): JSX.Element {
  const navigation = useNavigation<SearchNavProp>();
  const { openDrawer } =
    useNavigation<DrawerNavigationProp<AdminStackParamList>>();

  const [searchString, setSearchString] = useDebouncedState(
    '',
    DEBOUNCE_BEFORE_SEARCH,
  );

  const { data: apiData, loading } = useAdminSearchQuery({
    variables: { searchString },
  });

  const handleOnEditPress = (id: string, type: string) => {
    const route =
      type !== `SearchUserType`
        ? type === `SearchGroupType`
          ? 'EditGroup'
          : 'EditInstitute'
        : 'EditUser';

    navigation.navigate(route, {
      id,
    });
  };
  const allParameters = extractParametersFromString(searchString);

  const searchResults = flattenAdminSearchResults(
    apiData?.adminSearchResults as AdminSearchResults,
    allParameters,
  );

  return (
    <SearchLayout
      onEditPress={handleOnEditPress}
      searchResults={searchResults}
      setSearchString={setSearchString}
      loading={loading}
      openDrawer={openDrawer}
      searchString={searchString}
    />
  );
}
