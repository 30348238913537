import React, { useRef } from 'react';
import { useHover } from 'react-native-web-hooks';
import { Container, Switch } from './style';
import { ToggleState } from './types';

type ToggleComponentProps = {
  testID?: string;
  state?: ToggleState;
  checked: boolean;
  onChange: (checked: boolean) => void;
};

export default function Toggle({
  testID,
  state = 'default',
  checked = false,
  onChange,
}: ToggleComponentProps): JSX.Element {
  const ref = useRef(null);
  const hovered = useHover(ref);

  const innerState: ToggleState = hovered ? 'hover' : state;

  return (
    <Container ref={ref} state={innerState} testID={testID}>
      <Switch
        state={innerState}
        checked={checked}
        value={checked}
        onValueChange={(value) => onChange(value)}
      />
    </Container>
  );
}
