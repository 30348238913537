/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation CreateQuotesInstance
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  CreateQuotesInstance,
  CreateQuotesInstanceVariables,
} from './types/CreateQuotesInstance';
import { CREATE_QUOTES_INSTANCE } from './';

export function useCreateQuotesInstanceMutation(
  options?: MutationHookOptions<
    CreateQuotesInstance,
    CreateQuotesInstanceVariables
  >,
): MutationTuple<CreateQuotesInstance, CreateQuotesInstanceVariables> {
  return useMutation<CreateQuotesInstance, CreateQuotesInstanceVariables>(
    CREATE_QUOTES_INSTANCE,
    options,
  );
}

export function createCreateQuotesInstanceMock({
  variables,
  data,
  error,
}: {
  variables?: CreateQuotesInstanceVariables;
  data?: CreateQuotesInstance;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: CREATE_QUOTES_INSTANCE,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
