import styled from 'styled-components/native';

export const CenterContent = styled.View`
  padding-vertical: ${({ theme }) => theme.spacing.large};
  align-items: center;
`;

export const Image = styled.Image`
  width: 100%;
  height: 150px;
  margin-bottom: 30px;
`;
