import AsyncStorage from '@react-native-async-storage/async-storage';
import { AppLocale } from './models/app';
import { AppInfo, UserGroupInfo } from './models/local';

const TOKEN = '@token';
const REFRESH_TOKEN = '@refreshToken';
const USER_ID = '@userId';
const USER_GROUP_INFO = '@userGroupInfo';
const APP_LOCALE = '@appLocale';
const APP_INFO = '@appInfo';
const ABIHOME_USER_EMAIL = '@abihomeUserEmail';
const ABIHOME_USER_PW = '@abihomeUserPw';
const SIGN_UP_DATA = '@signUpData';

export async function getToken(): Promise<string | null> {
  return await AsyncStorage.getItem(TOKEN);
}

export async function setToken(token: string): Promise<void> {
  return await AsyncStorage.setItem(TOKEN, token);
}

export async function removeToken(): Promise<void> {
  return await AsyncStorage.removeItem(TOKEN);
}

export async function getRefreshToken(): Promise<string | null> {
  return await AsyncStorage.getItem(REFRESH_TOKEN);
}

export async function setRefreshToken(refreshToken: string): Promise<void> {
  return await AsyncStorage.setItem(REFRESH_TOKEN, refreshToken);
}

export async function removeRefreshToken(): Promise<void> {
  return await AsyncStorage.removeItem(REFRESH_TOKEN);
}

export async function getUserId(): Promise<string | null> {
  return await AsyncStorage.getItem(USER_ID);
}

export async function setUserId(userId: string): Promise<void> {
  return await AsyncStorage.setItem(USER_ID, userId);
}

export async function removeUserId(): Promise<void> {
  return await AsyncStorage.removeItem(USER_ID);
}

export async function getUserGroupInfo(): Promise<UserGroupInfo | null> {
  const userGroupInfoStr = await AsyncStorage.getItem(USER_GROUP_INFO);
  return userGroupInfoStr ? JSON.parse(userGroupInfoStr) : null;
}

export async function setUserGroupInfo(
  userGroupInfo: UserGroupInfo,
): Promise<void> {
  const userGroupInfoStr: string = JSON.stringify(userGroupInfo);
  return await AsyncStorage.setItem(USER_GROUP_INFO, userGroupInfoStr);
}

export async function removeUserGroupInfo(): Promise<void> {
  return await AsyncStorage.removeItem(USER_GROUP_INFO);
}

export async function getAppLocale(): Promise<AppLocale | null> {
  return (await AsyncStorage.getItem(APP_LOCALE)) as AppLocale;
}

export async function setAppLocale(appLocale: AppLocale): Promise<void> {
  return await AsyncStorage.setItem(APP_LOCALE, appLocale);
}

export async function getSignUpData(): Promise<string | null> {
  return await AsyncStorage.getItem(SIGN_UP_DATA);
}

export async function setSignUpData(signUpData: string): Promise<void> {
  return await AsyncStorage.setItem(SIGN_UP_DATA, signUpData);
}

export async function removeSignUpData(): Promise<void> {
  return await AsyncStorage.removeItem(SIGN_UP_DATA);
}

export async function getAppInfo(): Promise<AppInfo> {
  const appInfoStr = await AsyncStorage.getItem(APP_INFO);
  const parsedObj = appInfoStr ? JSON.parse(appInfoStr) : undefined;
  return {
    country: parsedObj?.country || null,
    hiddenPostIds: parsedObj?.hiddenPostIds || [],
  };
}

export async function setAppInfo({
  country,
  hiddenPostIds = [],
}: AppInfo): Promise<void> {
  const appInfoStr: string = JSON.stringify({
    country,
    hiddenPostIds,
  });
  return await AsyncStorage.setItem(APP_INFO, appInfoStr);
}

export async function setAbihomeUser(
  email: string,
  password: string,
): Promise<void> {
  await AsyncStorage.setItem(ABIHOME_USER_EMAIL, email);
  await AsyncStorage.setItem(ABIHOME_USER_PW, password);
  return;
}

export async function getAbihomeUser(): Promise<{
  email: string;
  password: string;
}> {
  const email = (await AsyncStorage.getItem(ABIHOME_USER_EMAIL)) as string;
  const password = (await AsyncStorage.getItem(ABIHOME_USER_PW)) as string;
  return { email, password };
}

export async function clearAbihomeUser(): Promise<void> {
  await AsyncStorage.removeItem(ABIHOME_USER_EMAIL);
  await AsyncStorage.removeItem(ABIHOME_USER_PW);
  return;
}

export async function setAuth(
  token: string,
  refreshToken: string,
): Promise<void> {
  await setToken(token);
  await setRefreshToken(refreshToken);
}

export async function clearAuth(): Promise<void> {
  await removeToken();
  await removeRefreshToken();
  await removeUserId();
  await removeUserGroupInfo();
}
