import styled from 'styled-components/native';

export const Container = styled.View`
  flex-direction: row;
  padding: 8px 14px;
`;

interface IDot {
  active: boolean;
}
export const Dot = styled.TouchableOpacity.attrs({ activeOpacity: 0.8 })<IDot>`
  flex: 1;
  height: 8px;
  border-radius: 3px;
  background-color: ${({ active, theme }) =>
    active ? theme.color.brand_01 : theme.color.base.c2};
  margin-horizontal: 2px;
`;
