import styled from 'styled-components/native';
import { general } from '~/assets/imgs';
import { TabScreenContainer } from '~/screens/style';

export const Container = styled(TabScreenContainer)``;

export const ScrollView = styled.ScrollView`
  flex: 1;
`;

export const Content = styled.View`
  padding-vertical: 48px;
  padding-horizontal: 16px;
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c4,
  size: 'small',
}))`
;`;

export const LockImage = styled.Image.attrs({
  source: general.lock,
})`
  height: 64px;
  width: 47px;
`;

export const CheckImage = styled.Image.attrs({
  source: general.check,
})`
  height: 64px;
  width: 76px;
`;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c10};
  font-size: 24px;
  line-height: 24px;
`;

export const Descp = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 18px;
  line-height: 24px;
`;
