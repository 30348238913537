import React from 'react';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { ReactNativeFile } from 'apollo-upload-client';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { DEFAULT_LIST_OFFSET } from '~/data/constants';
import useAuthTeams from '~/data/hooks/useAuthTeams';
import { Owner } from '~/data/operations/global';
import { useAddImagesToPostMutation } from '~/data/operations/post/addImagesToPost';
import { useCreatePostMutation } from '~/data/operations/post/createPost';
import {
  writePostsQuery,
  readPostsQuery,
} from '~/data/operations/post/helpers';
import { useTeamQuery } from '~/data/operations/team/team';
import { RootStackParamList } from '~/navigation/types';
import { getFileName } from '~/utils/helpers';
import { t } from '~/utils/i18n';
import CreatePostLayout, { CreatePostInputProps } from './layout';

type CreatePostNavProp = StackNavigationProp<RootStackParamList, 'CreatePost'>;

type CreatePostRouteProp = RouteProp<RootStackParamList, 'CreatePost'>;

export default function CreatePost(): JSX.Element {
  const navigation = useNavigation<CreatePostNavProp>();
  const {
    params: { teamId, imgUris },
  } = useRoute<CreatePostRouteProp>();
  const lockedTeamId = teamId;

  const { authGroupId, authUserGroupId, authUserGroup } = useAuth();

  const { teams } = useAuthTeams({ skip: !!lockedTeamId });

  const { data: teamData } = useTeamQuery({
    skip: !teamId,
    variables: {
      id: teamId as string,
    },
  });
  const lockedTeam = teamData?.team;

  const [createPost, { loading: createPostLoading }] = useCreatePostMutation({
    update(cache, { data }) {
      const newPost = data?.createPost?.post;

      const postsVar = {
        after: null,
        first: DEFAULT_LIST_OFFSET,
        createdBy_Group: !teamId ? authGroupId : undefined,
        team: teamId,
        authUserGroupId,
      };

      const currentPosts = readPostsQuery({
        cache,
        variables: postsVar,
      });

      if (currentPosts?.posts?.edges && newPost) {
        writePostsQuery({
          cache,
          variables: postsVar,
          data: {
            ...currentPosts,
            posts: {
              ...currentPosts.posts,
              edges: [
                {
                  __typename: 'PostNodeEdge',
                  node: newPost,
                },
                ...currentPosts.posts.edges,
              ],
            },
          },
        });
      }
    },
  });

  const [addImagesToPost, { loading: addImagesToPostLoading }] =
    useAddImagesToPostMutation();

  const onCreatePost = async ({
    authorTeamId,
    text,
    imageUris,
  }: CreatePostInputProps) => {
    try {
      const { data } = await createPost({
        variables: {
          input: {
            owner: authorTeamId ? Owner.TEAM : Owner.USER,
            team: authorTeamId,
            group: authGroupId,
            text,
          },
          authUserGroupId,
        },
      });
      const postId = data?.createPost?.post?.id;
      if (!postId) {
        const messages = data?.createPost?.errors?.map(
          (error) => error?.messages[0],
        );
        const errorMessage = messages?.[0] as string;
        Snackbar.show(errorMessage);
        return;
      }

      let i;
      for (i = 0; i < imageUris.length; i++) {
        const imageUri = imageUris[i];
        const imageFile = new ReactNativeFile({
          uri: imageUri,
          type: 'image/jpeg',
          name: getFileName(imageUri),
        });
        await addImagesToPost({
          variables: {
            input: {
              id: postId,
              images: imageFile,
            },
            authUserGroupId,
          },
        });
      }
      navigation.goBack();
      Snackbar.show(t('createPost.createPostSuccess'));
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  return (
    <CreatePostLayout
      loggedUserGroup={authUserGroup}
      teams={lockedTeam ? [lockedTeam] : teams}
      lockedTeamId={lockedTeamId}
      loading={createPostLoading || addImagesToPostLoading}
      initialImgUris={imgUris}
      onBack={() => navigation.goBack()}
      onCreatePoll={() => navigation.navigate('CreatePoll')}
      onCreatePost={(postInput) => onCreatePost(postInput)}
    />
  );
}
