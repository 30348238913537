import styled from 'styled-components/native';
import { ModalScreenContainer } from '~/screens/style';

export const Container = styled(ModalScreenContainer)`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const ExportList = styled.FlatList`
  padding-top: 16px;
  padding-horizontal: 16px;
`;
