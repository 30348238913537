import React, { ReactNode, useCallback, useEffect, useRef } from 'react';

type DragAndDropProps = {
  onDrop: (file: File) => void;
  children?: ReactNode;
  height?: number;
};

const DragAndDrop: React.FC<DragAndDropProps> = ({ onDrop, children }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const files = e.dataTransfer.files;
      if (files.length) {
        onDrop(files[0]);
      }
    },
    [onDrop],
  );

  const handleClick = () => {
    inputRef.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      onDrop(e.target.files[0]);
    }
  };

  useEffect(() => {
    const preventDefaultBehavior = (e: Event) => e.preventDefault();

    // Add global event listeners
    document.addEventListener('dragover', preventDefaultBehavior);
    document.addEventListener('drop', preventDefaultBehavior);

    // Cleanup the event listeners on component unmount
    return () => {
      document.removeEventListener('dragover', preventDefaultBehavior);
      document.removeEventListener('drop', preventDefaultBehavior);
    };
  }, []);

  return (
    <div
      onDrop={handleDrop}
      onDragOver={(e) => e.preventDefault()}
      onClick={handleClick}
      style={{ padding: '60px 140px' }}
    >
      {children}
      <input
        type="file"
        ref={inputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </div>
  );
};
export default DragAndDrop;
