import React from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '~/navigation/types';
import ProfilePageAddPhotoCategoryLayout from './layout';

type ProfilePageAddPhotoCategoryNavProp = StackNavigationProp<
  RootStackParamList,
  'ProfilePageAddPhotoCategory'
>;
type ProfilePageAddPhotoCategoryRouteProp = RouteProp<
  RootStackParamList,
  'ProfilePageAddPhotoCategory'
>;
export default function ProfilePageAddPhotoCategory(): JSX.Element {
  const navigation = useNavigation<ProfilePageAddPhotoCategoryNavProp>();

  const {
    params: { currentText, onAddedPhotoCategory },
  } = useRoute<ProfilePageAddPhotoCategoryRouteProp>();

  // TODO : get recommended and inspirational photo categories from backend
  const recommendedPhotoCategories: { id: string; text: string }[] = [];

  // TODO : get recommended and inspirational photo categories from backend
  const inspirationalPhotoCategories: { id: string; text: string }[] = [];

  return (
    <ProfilePageAddPhotoCategoryLayout
      onBack={() => navigation.goBack()}
      text={currentText}
      recommendedPhotoCategories={recommendedPhotoCategories}
      inspirationalPhotoCategories={inspirationalPhotoCategories}
      onAddPhotoCategory={(text) => {
        onAddedPhotoCategory(text);
        navigation.goBack();
      }}
    />
  );
}
