/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation AddUserToTeam
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { AddUserToTeam, AddUserToTeamVariables } from './types/AddUserToTeam';
import { ADD_USER_TO_TEAM } from './';

export function useAddUserToTeamMutation(
  options?: MutationHookOptions<AddUserToTeam, AddUserToTeamVariables>,
): MutationTuple<AddUserToTeam, AddUserToTeamVariables> {
  return useMutation<AddUserToTeam, AddUserToTeamVariables>(
    ADD_USER_TO_TEAM,
    options,
  );
}

export function createAddUserToTeamMock({
  variables,
  data,
  error,
}: {
  variables?: AddUserToTeamVariables;
  data?: AddUserToTeam;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: ADD_USER_TO_TEAM,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
