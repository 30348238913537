import { Platform } from 'react-native';
import styled from 'styled-components/native';
import { WebContainer, webContainerCss } from '~/components/WebGrid';
import { TabScreenContainer } from '~/screens/style';

export const Container = styled(TabScreenContainer)`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c0};
  overflow: auto;
`;

export const HeaderContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${Platform.OS === 'web' && `${webContainerCss}`};
  padding-horizontal: 16px;
  padding-top: 50px;
  padding-bottom: 32px;
`;

export const Title = styled.Text`
  font-size: 32px;
  color: ${({ theme }) => theme.color.base.c9};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
`;

export const CampaingContainer = styled.View`
  margin: 0px 150px 0px 150px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CampaingWrapper = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 35px;
  padding-right: 35px;
`;

export const CampaingText = styled.Text`
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 22px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
`;

export const SelectorWrapper = styled.View`
  width: 400px;
`;

export const AdsDatabaseWrapper = styled(WebContainer)`
  padding: 16px;
`;
