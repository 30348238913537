import { DefaultTheme } from 'styled-components/native';
import {
  SelectionControlType,
  SelectionControlState,
  SelectionControlSize,
  SelectionControlColorStyle,
} from './types';

interface SelectionControlSizeHelper {
  contentSize: (size: SelectionControlSize) => string;
  contentRadius: (
    type: SelectionControlType,
    size: SelectionControlSize,
  ) => string;
  focusContentRadius: (
    type: SelectionControlType,
    size: SelectionControlSize,
  ) => string;
  borderWidth: (
    size: SelectionControlSize,
    state: SelectionControlState,
    checked: boolean,
  ) => string;
}
type SizeMap = {
  [key in SelectionControlSize]: string;
};
export const bySizeHelper: SelectionControlSizeHelper = {
  contentSize: (size: SelectionControlSize): string => {
    const sizeMap: SizeMap = {
      small: '16px',
      medium: '24px',
      large: '32px',
    };
    return sizeMap[size];
  },
  contentRadius: (
    type: SelectionControlType,
    size: SelectionControlSize,
  ): string => {
    if (type === 'checkBox') {
      return '4px';
    }
    const sizeMap: SizeMap = {
      small: '8px',
      medium: '12px',
      large: '16px',
    };
    return sizeMap[size];
  },
  focusContentRadius: (
    type: SelectionControlType,
    size: SelectionControlSize,
  ): string => {
    if (type === 'checkBox') {
      return '7px';
    }
    const sizeMap: SizeMap = {
      small: '12px',
      medium: '16px',
      large: '20px',
    };
    return sizeMap[size];
  },
  borderWidth: (
    size: SelectionControlSize,
    state: SelectionControlState,
    checked: boolean,
  ): string => {
    if (checked) {
      const checkedSizeMap: SizeMap = {
        small: '3px',
        medium: '4px',
        large: '5px',
      };
      return checkedSizeMap[size];
    }
    if (state === 'focus') {
      const focusSizeMap: SizeMap = {
        small: '2px',
        medium: '2px',
        large: '3px',
      };
      return focusSizeMap[size];
    }
    return '1px';
  },
};

interface SelectionControlStatusHelper {
  bgColor: (
    state: SelectionControlState,
    colorStyle: SelectionControlColorStyle,
    checked: boolean,
    theme: DefaultTheme,
  ) => string;
  borderColor: (
    state: SelectionControlState,
    checked: boolean,
    theme: DefaultTheme,
  ) => string;
  focusBorderColor: (
    colorStyle: SelectionControlColorStyle,
    theme: DefaultTheme,
  ) => string;
}
type StateMap = {
  [key in SelectionControlState]: string;
};
const byStatusHelperLight: SelectionControlStatusHelper = {
  bgColor: (
    state: SelectionControlState,
    colorStyle: SelectionControlColorStyle,
    checked: boolean,
    theme: DefaultTheme,
  ): string => {
    const stateMap: StateMap = {
      default: theme.color.base.c0,
      hover: theme.color.brand_02,
      focus: theme.color.base.c0,
      disabled: theme.color.base.c2,
    };

    const checkColor =
      colorStyle === 'tertiary' ? theme.color.brand_02 : theme.color.brand_02;

    const checkedStateMap: StateMap = {
      default: checkColor,
      hover: checkColor,
      focus: checkColor,
      disabled: theme.color.base.c4,
    };
    return checked ? checkedStateMap[state] : stateMap[state];
  },
  borderColor: (
    state: SelectionControlState,
    checked: boolean,
    theme: DefaultTheme,
  ): string => {
    const stateMap: StateMap = {
      default: theme.color.base.c3,
      hover: theme.color.base.c3,
      focus: theme.color.base.c3,
      disabled: theme.color.base.c3,
    };

    const checkedStateMap: StateMap = {
      default: theme.color.base.c0,
      hover: theme.color.brand_02,
      focus: theme.color.base.c0,
      disabled: theme.color.base.c2,
    };
    return checked ? checkedStateMap[state] : stateMap[state];
  },
  focusBorderColor: (
    colorStyle: SelectionControlColorStyle,
    theme: DefaultTheme,
  ): string => {
    return colorStyle === 'tertiary'
      ? theme.color.brand_02
      : theme.color.brand_02;
  },
};

export const byStatusHelper: SelectionControlStatusHelper = {
  bgColor: (
    state: SelectionControlState,
    colorStyle: SelectionControlColorStyle,
    checked: boolean,
    theme: DefaultTheme,
  ): string => {
    return byStatusHelperLight.bgColor(state, colorStyle, checked, theme);
  },
  borderColor: (
    state: SelectionControlState,
    checked: boolean,
    theme: DefaultTheme,
  ): string => {
    return byStatusHelperLight.borderColor(state, checked, theme);
  },
  focusBorderColor: (
    colorStyle: SelectionControlColorStyle,
    theme: DefaultTheme,
  ): string => {
    return byStatusHelperLight.focusBorderColor(colorStyle, theme);
  },
};
