import React, { useEffect, useState } from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useAuth } from '~/context/auth';
import { DEFAULT_LIST_OFFSET } from '~/data/constants';
import { useCustomPagesInstancesResultQuery } from '~/data/operations/yearbook/customPagesInstancesResult';
import { CustomPageFields } from '~/data/operations/yearbook/types/CustomPageFields';
import { YearbookStackParamList } from '~/navigation/types';
import CustomPageResultsLayout from './layout';

type CustomPageResultsNavProp = StackNavigationProp<
  YearbookStackParamList,
  'CustomPageResults'
>;

type CustomPageResultsRouteProp = RouteProp<
  YearbookStackParamList,
  'CustomPageResults'
>;
export default function CustomPageResults(): JSX.Element {
  const navigation = useNavigation<CustomPageResultsNavProp>();
  const {
    params: { moduleInstanceId },
  } = useRoute<CustomPageResultsRouteProp>();
  const { authUserId } = useAuth();
  const [userCustomPages, setUserCustomPages] = useState<CustomPageFields[]>(
    [],
  );

  const customPageVar = {
    after: null,
    first: DEFAULT_LIST_OFFSET,
    moduleInstance: moduleInstanceId,
  };

  const {
    data: customPagesData,
    loading: customPagesLoading,
    refetch: refetchCustomPages,
    fetchMore: fetchMoreCustomPages,
  } = useCustomPagesInstancesResultQuery({
    variables: customPageVar,
  });

  const customPagesInstances: CustomPageFields[] =
    (customPagesData?.customPagesInstancesResult?.edges.map(
      (edge) => edge?.node,
    ) as CustomPageFields[]) || [];
  const hasNextPage =
    customPagesData?.customPagesInstancesResult?.pageInfo.hasNextPage;
  const endCursor =
    customPagesData?.customPagesInstancesResult?.pageInfo.endCursor;

  useEffect(() => {
    if (!customPagesLoading && customPagesData) {
      // Filter custom pages where the current user is the owner
      const filteredPages = customPagesInstances.filter(
        (page) => page?.userGroup?.user.id === authUserId,
      );
      setUserCustomPages(filteredPages || []);
    }
  }, [customPagesData, customPagesLoading]);
  return (
    <CustomPageResultsLayout
      onBack={() => navigation.goBack()}
      hasNextPage={hasNextPage}
      userCustomPages={userCustomPages}
      onRefresh={refetchCustomPages}
      loading={customPagesLoading}
      onLoadMore={() => {
        fetchMoreCustomPages({
          variables: {
            ...customPageVar,
            after: endCursor,
          },
        });
      }}
      onOpenCustomPage={(customPageId: string) =>
        navigation.navigate('CustomPageResult', {
          moduleInstanceId: moduleInstanceId,
          customPageId,
        })
      }
    />
  );
}
