import styled from 'styled-components/native';
import Icon from '../Icon';
import TeamIconComp from '../TeamIcon';

export const Container = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  box-shadow: 2px 3px 2px rgba(248, 249, 250, 0.8);
  border: 1px solid ${({ theme }) => theme.color.base.c2};
  border-radius: 5px;
  padding: 15px;
`;

export const DateText = styled.Text`
  text-align: right;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 12px;
`;

export const Title = styled.Text`
  margin-left: 16px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c10};
  font-size: 18px;
  line-height: 22px;
`;

export const Descp = styled.Text`
  margin-top: 8px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c7};
  font-size: 16px;
`;

export const Row = styled.View`
  padding-top: 8px;
  flex-direction: row;
  align-items: center;
`;

export const ArrowIcon = styled(Icon).attrs(({ theme }) => ({
  name: 'chevron-right',
  color: theme.color.base.c9,
  size: 16,
}))`
  margin-horizontal: 4px;
`;

export const TeamIcon = styled(TeamIconComp)``;

export const Footer = styled.View`
  padding-top: 16px;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 8px;
`;
