/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation AdminToggleGroupActive
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  AdminToggleGroupActive,
  AdminToggleGroupActiveVariables,
} from './types/AdminToggleGroupActive';
import { ADMIN_TOGGLE_GROUP_ACTIVE } from './';

export function useAdminToggleGroupActiveMutation(
  options?: MutationHookOptions<
    AdminToggleGroupActive,
    AdminToggleGroupActiveVariables
  >,
): MutationTuple<AdminToggleGroupActive, AdminToggleGroupActiveVariables> {
  return useMutation<AdminToggleGroupActive, AdminToggleGroupActiveVariables>(
    ADMIN_TOGGLE_GROUP_ACTIVE,
    options,
  );
}

export function createAdminToggleGroupActiveMock({
  variables,
  data,
  error,
}: {
  variables?: AdminToggleGroupActiveVariables;
  data?: AdminToggleGroupActive;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: ADMIN_TOGGLE_GROUP_ACTIVE,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
