import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import styled from 'styled-components/native';
import Icon from '~/components/Icon';
import ImageComp from '~/components/Image';

const COLOR_BOX_HEIGHT_MOBILE = 200;

export const ScrollView = styled(KeyboardAwareScrollView).attrs({
  contentContainerStyle: {
    flex: 1,
  },
})`
  padding-horizontal: ${({ theme }) => theme.spacing.medium};
`;

export const Content = styled.View`
  flex: 1;
`;

export const InputContent = styled.View`
  flex: 1;
`;

export const Footer = styled.View`
  padding-bottom: 24px;
`;

export const ColorBox = styled.View<{ isDesktop?: boolean }>`
  position: absolute;
  background-color: ${({ theme }) => theme.color.brand_01};
  z-index: -1;
  top: 0px;
  left: 0px;
  right: 0px;
  height: ${({ theme }) =>
    COLOR_BOX_HEIGHT_MOBILE + theme.safeAreaInsets.top}px;
`;

export const SmallImage = styled(ImageComp)`
  width: 92px;
  height: 74px;
  margin-left: -18px;
  margin-top: 20px;
  transform: rotate(-10deg);
`;

export const SelectGroupIconButton = styled.Pressable``;

export const Image = styled(ImageComp)`
  width: 160px;
  height: 160px;
  border-radius: 5px;
  transform: rotate(10deg);
  margin-left: 22px;
  border-width: 5px;
  border-color: ${({ theme }) => theme.color.base.c2};
  margin-vertical: 36px;
  background-color: ${({ theme }) => theme.color.base.c1};
`;

export const UploadBox = styled.View`
  width: 160px;
  height: 160px;
  border-radius: 5px;
  transform: rotate(10deg);
  margin-left: 22px;
  border-width: 5px;
  border-color: ${({ theme }) => theme.color.base.c2};
  margin-vertical: 36px;
  background-color: ${({ theme }) => theme.color.brand_02};
  justify-content: center;
  align-items: center;
`;

export const UploadIcon = styled(Icon).attrs(({ theme }) => ({
  name: 'upload-01',
  size: 48,
  color: theme.color.base.c0,
}))``;

export const ImageWrapper = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`;
