import styled from 'styled-components/native';
import { webContainerCss } from '~/components/WebGrid';
import ButtonIcon from './../ButtonIcon';

export type CustomIconType = 'default' | 'primary' | 'noBg';

export const Container = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})`
  flex-direction: row;
  align-items: center;
  padding: 16px;
  border-bottom-width: 1px;
  border-color: ${({ theme }) => theme.color.base.c3};

  ${webContainerCss};
`;

export const Name = styled.Text`
  flex: 1;
  padding-left: 16px;
  color: ${({ theme }) => theme.color.base.c9};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
  line-height: 20px;
`;

interface ICustomButtonIcon {
  customIconType?: CustomIconType;
}
export const CustomButtonIcon = styled(ButtonIcon).attrs<ICustomButtonIcon>(
  ({ customIconType, theme }) => ({
    size: 16,
    color:
      customIconType === 'primary' ? theme.color.base.c0 : theme.color.brand_02,
  }),
)<ICustomButtonIcon>`
  margin-left: 8px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  ${({ customIconType, theme }) =>
    customIconType !== 'noBg' &&
    `background-color: ${
      customIconType === 'primary' ? theme.color.brand_02 : theme.color.base.c1
    }`};
  align-items: center;
  justify-content: center;
`;
export const SkeletonContainer = styled.View`
  flex-direction: row;
  align-items: center;
  padding: 16px;
  border-bottom-width: 1px;
  border-color: ${({ theme }) => theme.color.base.c3};
`;
export const SkeletonIcon = styled.View`
  margin-right: 16px;
`;
export const SkeletonText = styled.Text`
  padding-right: 16px;
`;
export const SkeletonArrow = styled.Text`
  padding-right: 16px;
`;
