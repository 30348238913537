/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation DeleteCampaign
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  DeleteCampaign,
  DeleteCampaignVariables,
} from './types/DeleteCampaign';
import { DELETE_CAMPAIGN } from './';

export function useDeleteCampaignMutation(
  options?: MutationHookOptions<DeleteCampaign, DeleteCampaignVariables>,
): MutationTuple<DeleteCampaign, DeleteCampaignVariables> {
  return useMutation<DeleteCampaign, DeleteCampaignVariables>(
    DELETE_CAMPAIGN,
    options,
  );
}

export function createDeleteCampaignMock({
  variables,
  data,
  error,
}: {
  variables?: DeleteCampaignVariables;
  data?: DeleteCampaign;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: DELETE_CAMPAIGN,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
