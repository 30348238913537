import React from 'react';
import { GroupUser } from '~/data/models/group';
import { t } from '~/utils/i18n';
import Badge from '../Badge';
import {
  Container,
  Image,
  Content,
  Name,
  Detail,
  RemoveView,
  RemoveButton,
} from './style';
import { GroupRequestCellType } from './types';

type GroupRequestCellProps = {
  groupUser: GroupUser;
  checked?: boolean;
  onPress?: () => void;
  onRemove: () => void;
  type?: GroupRequestCellType;
  testID?: string;
};

export default function GroupRequestCell({
  groupUser,
  onPress,
  onRemove,
  testID,
  type = 'default',
}: GroupRequestCellProps): JSX.Element {
  const pending: boolean = groupUser.status === 'PENDING';
  const badgeText = pending ? t('requestPending') : t('requestDeclined');
  const badgeColorTheme = pending ? 'dark2Gray' : 'red';
  return (
    <Container testID={testID} type={type} onPress={() => onPress && onPress()}>
      <Image
        source={{
          uri:
            groupUser.group?.avatar ||
            groupUser?.group?.icon?.icon ||
            undefined,
        }}
      />
      <Content>
        <Name type={type}>{groupUser.group?.name}</Name>
        <Detail
          type={type}
        >{`${groupUser.group?.year}, ${groupUser.group?.type.name}`}</Detail>
        <Badge text={badgeText} colorTheme={badgeColorTheme} />
        <RemoveView>
          <RemoveButton onPress={onRemove} />
        </RemoveView>
      </Content>
    </Container>
  );
}
