import * as ImagePicker from 'expo-image-picker';

interface Options {
  circleCrop?: boolean;
}

async function launchImageLibrary(): Promise<string | undefined> {
  const result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.Images,
    quality: 0.8,
  });

  if (!result.canceled) {
    return result.assets[0].uri;
  }
}

export async function getImageFromLibrary(
  options?: Options, //eslint-disable-line
): Promise<string | undefined> {
  return launchImageLibrary();
}

export async function getImageFromCamera(
  options?: Options, //eslint-disable-line
): Promise<string | undefined> {
  return launchImageLibrary();
}
