import React, { useRef, useState } from 'react';
import { TextInput as RNTextInput } from 'react-native';
import { useTheme } from 'styled-components/native';
import Button from '~/components/Button';
import { getImageFromLibrary } from '~/components/ImagePicker';
import NavHeader from '~/components/NavHeader';
import Toolbar from '~/components/Toolbar';
import { UserGroup } from '~/data/models/group';
import { Team } from '~/data/models/team';
import { t } from '~/utils/i18n';
import {
  Container,
  ScrollView,
  ImageContainer,
  ImageView,
  RemoveButtonIcon,
  Image,
  TextInput,
  ToolbarContainer,
  Controls,
} from './style';

export type CreatePostInputProps = {
  authorTeamId?: string;
  text: string;
  imageUris: string[];
};

type CreatePostLayoutProps = {
  loggedUserGroup?: UserGroup | null;
  teams: Team[];
  lockedTeamId?: string;
  loading: boolean;
  autoFocus?: boolean;
  initialImgUris?: string[];
  onBack: () => void;
  onCreatePoll: () => void;
  onCreatePost: (postInput: CreatePostInputProps) => void;
};

const MAX_FILES = 4;

export default function CreatePost({
  loggedUserGroup,
  teams,
  lockedTeamId,
  loading,
  autoFocus = true,
  initialImgUris = [],
  onBack,
  onCreatePoll,
  onCreatePost,
}: CreatePostLayoutProps): JSX.Element {
  const inputAccessoryViewID = 'toolbarAcessoryView';
  const inputRef = useRef<RNTextInput>(null);
  const theme = useTheme();

  const [authorTeamId, setAuthorTeamId] = useState<string | undefined>(
    lockedTeamId,
  );
  const [text, setText] = useState<string>('');
  const [imageUris, setImageUris] = useState<string[]>(initialImgUris);

  const onUploadImage = async () => {
    const maxFiles = MAX_FILES - imageUris.length;
    maxFiles > 0 &&
      getImageFromLibrary({ maxFiles }).then((uris) => {
        if (uris.length + imageUris.length <= MAX_FILES) {
          setImageUris((imgUris) => [...imgUris, ...uris]);
        }
        inputRef.current?.focus();
      });
  };

  const createPostEnabled = text != '';

  const onCreate = () => {
    if (createPostEnabled) {
      onCreatePost({
        authorTeamId,
        text,
        imageUris,
      });
    }
  };

  return (
    <Container>
      <NavHeader
        backButtonTestID={'buttonBack'}
        title={t('screens.createPost')}
        onBackPress={onBack}
      />
      <ScrollView
        keyboardShouldPersistTaps="always"
        keyboardDismissMode="on-drag"
      >
        <TextInput
          testID={'inputText'}
          placeholderTextColor={theme.color.base.c7}
          as={RNTextInput}
          ref={inputRef}
          placeholder={t('createPost.inputText')}
          autoCapitalize={'sentences'}
          multiline
          autoFocus={autoFocus}
          inputAccessoryViewID={inputAccessoryViewID}
          onChangeText={setText}
        />
        <ImageContainer>
          {imageUris.map((imageUri, i) => (
            <ImageView key={`img${i}`}>
              <Image
                testID={`postImage:${imageUri}`}
                source={{ uri: imageUri }}
              />
              <RemoveButtonIcon
                onPress={() =>
                  setImageUris((imgUris) =>
                    imgUris.filter((imgUri) => imgUri != imageUri),
                  )
                }
              />
            </ImageView>
          ))}
        </ImageContainer>
      </ScrollView>
      <ToolbarContainer nativeID={inputAccessoryViewID}>
        <Controls>
          <Button
            text={t('createPost.addImage')}
            onPress={onUploadImage}
            testID={'postPickerImage'}
            size="sm"
            icon="left"
            iconName="image-03"
            type="sub-action"
          />

          <Button
            text={t('createPost.addPoll')}
            testID={'postPoll'}
            onPress={onCreatePoll}
            size="sm"
            icon="left"
            iconName="horizontal-bar-chart-02"
            type="sub-action"
          />
        </Controls>
        <Toolbar
          testID={loggedUserGroup ? 'toolbarInput' : ''}
          authorTeamId={authorTeamId}
          userGroup={!lockedTeamId ? loggedUserGroup : undefined}
          teams={teams}
          createEnabled={createPostEnabled}
          loading={loading}
          onAuthorTeamChange={setAuthorTeamId}
          onCreate={onCreate}
        />
      </ToolbarContainer>
    </Container>
  );
}
