import React from 'react';
import { NavigationState } from '@react-navigation/native';
import { Platform } from 'react-native';
import Breadcrumbs from '~/components/Breadcrumbs';
import { IconName } from '~/components/Icon';
import { Spacer } from '~/theme/wrappers';
import { t } from '~/utils/i18n';
import { IconStyledName } from '../IconStyled';
import {
  Container,
  Right,
  Title,
  FirstButtonIcon,
  SecondButtonIcon,
  StyledButtonIcon,
  RightComponentContainer,
  LeftSideWrapper,
  SmallerTitle,
  Left,
  BackButton,
  BackIcon,
  LeftTextOnBackButton,
  MobileLeftSideWrapper,
} from './style';
import { NavHeaderStyle, NavHeaderType } from './types';

type StyledButtonProps = {
  testID?: string;
  iconName: IconStyledName;
  onPress: () => void;
};

type NavHeaderProps = {
  backButtonTestID?: string;
  firstButtonTestID?: string;
  secondButtonTestID?: string;
  title?: string;
  colorStyle?: NavHeaderStyle;
  type?: NavHeaderType;
  firstButtonIconName?: IconName;
  secondButtonIconName?: IconName;
  showBackIcon?: boolean;
  RightComponent?: React.ReactNode;
  styledButtonProps?: StyledButtonProps;
  titleAlign?: string;
  onBackPress?: () => void;
  onFirstButtonPress?: () => void;
  onSecondButtonPress?: () => void;
  navigationState?: NavigationState;
  breadcrumbs?: string[];
  backgroundColor?: string;
};

export default function NavHeader({
  backButtonTestID,
  firstButtonTestID,
  secondButtonTestID,
  title = '',
  colorStyle = 'default',
  type = 'default',
  firstButtonIconName,
  secondButtonIconName,
  showBackIcon = true,
  RightComponent,
  styledButtonProps,
  titleAlign = 'center',
  onBackPress,
  onFirstButtonPress,
  onSecondButtonPress,
  breadcrumbs = [],
}: NavHeaderProps): JSX.Element {
  const showBreadcrumbs = breadcrumbs.length > 1 && Platform.OS === 'web';

  return (
    <Container>
      {showBreadcrumbs ? (
        <LeftSideWrapper type={type}>
          <SmallerTitle
            colorStyle={colorStyle}
            type={'main'}
            textAlign={titleAlign}
          >
            {title}
          </SmallerTitle>
          <Spacer />
          <Breadcrumbs
            onNavigatePress={() => onBackPress && onBackPress()}
            routes={breadcrumbs}
          />{' '}
        </LeftSideWrapper>
      ) : (
        <>
          <Left type={type}>
            {onBackPress && (
              <BackButton testID={backButtonTestID} onPress={onBackPress}>
                {showBackIcon && (
                  <MobileLeftSideWrapper>
                    <BackIcon name={'chevron-left'} colorStyle={colorStyle} />
                    <LeftTextOnBackButton colorStyle={colorStyle}>
                      {t('back')}
                    </LeftTextOnBackButton>
                  </MobileLeftSideWrapper>
                )}
              </BackButton>
            )}
          </Left>
          <Title
            colorStyle={colorStyle}
            textAlign={titleAlign}
            type={Platform.OS === 'web' ? 'main' : type}
          >
            {title}
          </Title>
        </>
      )}

      {Platform.OS !== 'web' && (
        <Right>
          {firstButtonIconName ? (
            <FirstButtonIcon
              testID={firstButtonTestID}
              name={firstButtonIconName}
              colorStyle={colorStyle}
              onPress={() => onFirstButtonPress && onFirstButtonPress()}
            />
          ) : secondButtonIconName ? (
            <SecondButtonIcon
              testID={secondButtonTestID}
              name={secondButtonIconName}
              colorStyle={colorStyle}
              onPress={() => onSecondButtonPress && onSecondButtonPress()}
            />
          ) : styledButtonProps ? (
            <StyledButtonIcon
              testID={styledButtonProps.testID}
              styledName={styledButtonProps.iconName}
              onPress={() => styledButtonProps.onPress()}
            />
          ) : null}
        </Right>
      )}
      {!!RightComponent && (
        <RightComponentContainer>{RightComponent}</RightComponentContainer>
      )}
    </Container>
  );
}
