/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation AdminClearUserFlags
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  AdminClearUserFlags,
  AdminClearUserFlagsVariables,
} from './types/AdminClearUserFlags';
import { ADMIN_CLEAR_USER_FLAGS } from './';

export function useAdminClearUserFlagsMutation(
  options?: MutationHookOptions<
    AdminClearUserFlags,
    AdminClearUserFlagsVariables
  >,
): MutationTuple<AdminClearUserFlags, AdminClearUserFlagsVariables> {
  return useMutation<AdminClearUserFlags, AdminClearUserFlagsVariables>(
    ADMIN_CLEAR_USER_FLAGS,
    options,
  );
}

export function createAdminClearUserFlagsMock({
  variables,
  data,
  error,
}: {
  variables?: AdminClearUserFlagsVariables;
  data?: AdminClearUserFlags;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: ADMIN_CLEAR_USER_FLAGS,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
