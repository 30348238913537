import React, { useState } from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { ReactNativeFile } from 'apollo-upload-client';
import {
  FileDetails,
  getFileWithDetailsFromLibrary,
} from '~/components/ImagePicker';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { DEFAULT_LIST_OFFSET } from '~/data/constants';
import { PageFormat } from '~/data/operations/global';
import { useCreateCustomPagesInstanceMutation } from '~/data/operations/yearbook/createCustomPagesInstance';
import {
  readCustomPagesInstanceQuery,
  writeCustomPagesInstanceQuery,
} from '~/data/operations/yearbook/helpers';
import { RootStackParamList } from '~/navigation/types';
import CreateCustomPageLayout from './layout';

type CreateCustomPageNavProp = StackNavigationProp<
  RootStackParamList,
  'CreateCustomPage'
>;

type CreateCustomPageRouteProp = RouteProp<
  RootStackParamList,
  'CreateCustomPage'
>;

export default function CreateCustomPage(): JSX.Element {
  const navigation = useNavigation<CreateCustomPageNavProp>();
  const {
    params: { moduleInstanceId },
  } = useRoute<CreateCustomPageRouteProp>();
  const { authUserGroupId } = useAuth();
  const [imgUri, setImgUri] = useState<FileDetails>();
  const [customPageTitle, setCustomPageTitle] = useState('');
  const [selectedPhotoFormat, setSelectedPhotoFormat] = useState<PageFormat>(
    PageFormat.PORTRAIT,
  );

  const [createCustomPage] = useCreateCustomPagesInstanceMutation({
    update(cache, { data }) {
      const customPagesInstance =
        data?.createCustomPagesInstance?.customPagesInstance;

      const customPagesInstancesQuery = readCustomPagesInstanceQuery({
        cache,
        variables: {
          after: null,
          first: DEFAULT_LIST_OFFSET,
          moduleInstance: moduleInstanceId,
          userGroup: authUserGroupId,
        },
      });

      if (
        customPagesInstancesQuery &&
        customPagesInstancesQuery?.customPagesInstances?.edges &&
        customPagesInstance
      ) {
        writeCustomPagesInstanceQuery({
          cache,
          variables: {
            after: null,
            first: DEFAULT_LIST_OFFSET,
            moduleInstance: moduleInstanceId,
            userGroup: authUserGroupId,
          },
          data: {
            ...customPagesInstancesQuery,
            customPagesInstances: {
              ...customPagesInstancesQuery.customPagesInstances,
              edges: [
                ...customPagesInstancesQuery.customPagesInstances.edges,
                {
                  __typename: 'CustomPagesInstanceNodeEdge',
                  cursor: '',
                  node: customPagesInstance,
                },
              ],
            },
          },
        });
      }
    },
  });

  const onPickImage = async () => {
    const imgUri = await getFileWithDetailsFromLibrary();
    setImgUri(imgUri?.[0]);
  };

  const onCreateCustomPage = async () => {
    try {
      const imageFile = new ReactNativeFile({
        uri: imgUri?.uri as string,
        type: imgUri?.type as string,
        name: imgUri?.name as string,
      });

      await createCustomPage({
        variables: {
          input: {
            title: customPageTitle,
            pageFormat: selectedPhotoFormat,
            moduleInstance: moduleInstanceId,
            page: imageFile,
            pageType: imgUri?.type,
            pageQuality: imgUri?.quality,
          },
        },
      });
      navigation.goBack();
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onRemoveImage = () => {
    setImgUri({
      name: '',
      type: '',
      uri: '',
    });
  };
  return (
    <CreateCustomPageLayout
      onBack={() => navigation.goBack()}
      onPickImage={onPickImage}
      imgUri={imgUri}
      customPageTitle={customPageTitle}
      selectedPhotoFormat={selectedPhotoFormat}
      setCustomPageTitle={setCustomPageTitle}
      setSelectedPhotoFormat={setSelectedPhotoFormat}
      onCreateCustomPage={onCreateCustomPage}
      onRemoveImage={onRemoveImage}
    />
  );
}
