import styled from 'styled-components/native';
import Icon from '~/components/Icon';
import ImageComp from '~/components/Image';

export const Container = styled.View`
  flex-direction: row;
  background-color: ${({ theme }) => theme.color.base.c0};
  padding: 16px;
  border-radius: 5px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.color.base.c3};
  align-items: center;
  margin-bottom: 16px;

  box-shadow: 1px 2px 6px ${({ theme }) => theme.color.base.c2};
`;

export const ImageContainer = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})``;

export const Image = styled(ImageComp)`
  width: 90px;
  height: 70px;
  border-radius: 14px;
`;

export const OptionalImageContent = styled.View`
  width: 72px;
  padding-vertical: 8px;
  align-items: center;
  justify-content: center;
  background-color: #eef2ff;
  border-radius: 5px;
  border: 2px dashed ${({ theme }) => theme.color.brand_02};
`;

export const ImageAddIcon = styled(Icon).attrs(({ theme }) => ({
  name: 'image-03',
  size: 24,
  color: theme.color.brand_02,
}))``;

export const OptionalText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.brand_02};
  font-size: 10px;
  font-style: italic;
`;

export const TextInput = styled.TextInput.attrs(({ theme }) => ({
  placeholderTextColor: theme.color.brand_02,
}))`
  margin-left: 16px;
  flex: 1;
  color: ${({ theme }) => theme.color.brand_02};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
  line-height: 20px;
`;
