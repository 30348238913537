import React from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import useTeams from '~/data/hooks/useTeams';
import { CustomPagesSetupConfig, ModuleInstance } from '~/data/models/yearbook';
import { useModuleInstanceQuery } from '~/data/operations/yearbook/moduleInstance';
import { useUpdateCustomPagesSetupMutation } from '~/data/operations/yearbook/updateCustomPagesSetup';
import { useUpdateModuleInstanceMutation } from '~/data/operations/yearbook/updateModuleInstance';
import { YearbookStackParamList } from '~/navigation/types';
import { UpdateModuleInstanceInput } from '../layout/ModuleSetupLayout';
import CustomSetupLayout, { UpdateCustomPagesSetupInput } from './layout';

type CustomSetupNavProp = StackNavigationProp<
  YearbookStackParamList,
  'CustomSetup'
>;

type CustomSetupRouteProp = RouteProp<YearbookStackParamList, 'CustomSetup'>;

export default function CustomSetup(): JSX.Element {
  const navigation = useNavigation<CustomSetupNavProp>();
  const {
    params: { moduleInstanceId },
  } = useRoute<CustomSetupRouteProp>();
  const {
    teams,
    loading: teamsLoading,
    onLoadMore: onLoadMoreTeams,
  } = useTeams();
  const [updateCustomPagesSetup] = useUpdateCustomPagesSetupMutation();
  const [updateModuleInstance] = useUpdateModuleInstanceMutation();

  const { data: moduleInstanceData, loading: moduleInstanceLoading } =
    useModuleInstanceQuery({
      variables: {
        id: moduleInstanceId,
      },
    });

  const moduleInstance = moduleInstanceData?.moduleInstance;
  const customSetupConfig =
    moduleInstanceData?.moduleInstance?.customPagesSetup;

  const onUpdateModuleInstance = async ({
    isActive,
    dueDate,
  }: UpdateModuleInstanceInput) => {
    try {
      await updateModuleInstance({
        variables: {
          input: {
            id: moduleInstanceId,
            isActive,
            dueDate,
          },
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onUpdateCustomPagesSetup = async ({
    addCustomPagesTeamId,
    viewCustomPagesTeamId,
    quoteContent,
  }: UpdateCustomPagesSetupInput) => {
    try {
      if (moduleInstance && customSetupConfig) {
        await updateCustomPagesSetup({
          variables: {
            input: {
              id: customSetupConfig.id,
              isActive: moduleInstance.isActive,
              dueDate: moduleInstance.dueDate,
              createPagesTeam: addCustomPagesTeamId,
              viewPagesTeam: viewCustomPagesTeamId,
              guide: quoteContent,
            },
          },
        });
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };
  const loading = !moduleInstance || moduleInstanceLoading || teamsLoading;

  return (
    <CustomSetupLayout
      onBack={() => navigation.goBack()}
      moduleInstance={moduleInstance as ModuleInstance}
      onUpdateModuleInstance={onUpdateModuleInstance}
      teams={teams}
      loading={loading}
      customSetupConfig={customSetupConfig as CustomPagesSetupConfig}
      onLoadMoreTeams={onLoadMoreTeams}
      onUpdateCustomPagesSetup={onUpdateCustomPagesSetup}
    />
  );
}
