/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation UpdateProfilePageAnswer
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  UpdateProfilePageAnswer,
  UpdateProfilePageAnswerVariables,
} from './types/UpdateProfilePageAnswer';
import { UPDATE_PROFILE_PAGE_ANSWER } from './';

export function useUpdateProfilePageAnswerMutation(
  options?: MutationHookOptions<
    UpdateProfilePageAnswer,
    UpdateProfilePageAnswerVariables
  >,
): MutationTuple<UpdateProfilePageAnswer, UpdateProfilePageAnswerVariables> {
  return useMutation<UpdateProfilePageAnswer, UpdateProfilePageAnswerVariables>(
    UPDATE_PROFILE_PAGE_ANSWER,
    options,
  );
}

export function createUpdateProfilePageAnswerMock({
  variables,
  data,
  error,
}: {
  variables?: UpdateProfilePageAnswerVariables;
  data?: UpdateProfilePageAnswer;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: UPDATE_PROFILE_PAGE_ANSWER,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
