import React from 'react';
import { t } from '~/utils/i18n';
import {
  Container,
  ProgressView,
  LNProgress,
  Text,
  DotView,
  Dot,
} from './style';

type TimeProgressProps = {
  daysLeft: number;
  daysTotal?: number;
  minPct?: number;
};

export default function TimeProgress({
  daysLeft,
  daysTotal = Math.max(daysLeft, 100),
  minPct = 0.4,
}: TimeProgressProps): JSX.Element {
  const pct =
    daysLeft >= 0 ? Math.max((daysTotal - daysLeft) / daysTotal, minPct) : 1;
  const textInfo =
    daysLeft >= 0
      ? t('g.nDaysLeft', { n: daysLeft })
      : t('g.nDaysPassed', { n: -daysLeft });
  return (
    <Container>
      <ProgressView>
        <LNProgress pct={pct}>
          <Text>{textInfo}</Text>
        </LNProgress>
      </ProgressView>
      <DotView>
        <Dot />
      </DotView>
    </Container>
  );
}
