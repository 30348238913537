import React from 'react';
import { theme } from 'gradoo-theme';
import { Platform } from 'react-native';
import { general } from '~/assets/imgs';
import Button from '~/components/Button';
import NavHeader from '~/components/NavHeader';
import { Group } from '~/data/models/group';
import AuthWebLayout from '~/screens/Auth/layout/AuthLayout/AuthWebLayout';
import { LanguageProps } from '~/screens/Auth/layout/AuthLayout/types';
import { t } from '~/utils/i18n';
import {
  ImageContainer,
  Title,
  Container,
  TopContainer,
  BottomContainer,
  Desc,
  DescWrapper,
  BadgeText,
  TitleWrapper,
  ImageWrapper,
  Image,
  SmallImage,
  WelcomeGroupName,
  DownloadOnMobileContainer,
  DownloadOnMobileTitle,
  TabSection,
  TabSectionContainer,
} from './style';

type InviteSuccessLayoutProps = {
  group?: Group;
  yearbookAdmin?: boolean;
  onStartPlaning: () => void;
  onLogout: () => void;
  openAppStore: (os: 'ios' | 'android') => void;
  openLayoutCreator: () => void;
  openCustomerCenter: () => void;
  onSwitchGroup: () => void;
} & LanguageProps;

export default function InviteSuccess({
  selectedLocale,
  onSelectedLocale,
  group,
  yearbookAdmin,
  onStartPlaning,
  onLogout,
  openAppStore,
  openLayoutCreator,
  openCustomerCenter,
  onSwitchGroup,
}: InviteSuccessLayoutProps): JSX.Element {
  const isWeb = Platform.OS === 'web';

  if (isWeb) {
    return (
      <AuthWebLayout
        isContentCentered={false}
        languageProps={{ selectedLocale, onSelectedLocale }}
        HeaderRightComponent={
          <Button
            testID="btnSwitchGroup"
            text={t('inviteSuccess.switchGroup')}
            onPress={onSwitchGroup}
            type="primary-brand-02"
            size={'sm'}
          />
        }
      >
        <>
          <ImageWrapper>
            <Image
              noMargin
              source={{ uri: group?.avatar || group?.icon?.icon || undefined }}
            />
            <SmallImage source={general.double_hearts} />
          </ImageWrapper>
          <WelcomeGroupName>
            {t('onboarding.welcomeToGradooGroup', { group: group?.name || '' })}
          </WelcomeGroupName>
          {yearbookAdmin && (
            <TabSectionContainer>
              <TabSection
                testID="goToCustomerCenter"
                title={t('inviteSuccess.goToCustomerCenter.title')}
                detail={t('inviteSuccess.goToCustomerCenter.description')}
                rightIconName={'chevron-right'}
                rightIconColor={theme.color.base.c9}
                backgroundColor={theme.color.base.c0}
                border={true}
                borderRadius={5}
                onPress={openCustomerCenter}
              ></TabSection>
              <TabSection
                testID="layoutCreator"
                title={t('inviteSuccess.layoutCreator.title')}
                detail={t('inviteSuccess.layoutCreator.description')}
                rightIconName={'chevron-right'}
                rightIconColor={theme.color.base.c9}
                backgroundColor={theme.color.base.c0}
                borderRadius={5}
                border={true}
                onPress={openLayoutCreator}
              ></TabSection>
            </TabSectionContainer>
          )}

          <DownloadOnMobileContainer>
            <DownloadOnMobileTitle>
              {t('inviteSuccess.mobileSectionTitle')}
            </DownloadOnMobileTitle>
            <Button
              testID="buttonDownloadAndroid"
              text={t('inviteSuccess.btnDownloadOnAndroid')}
              onPress={() => openAppStore('android')}
              type="secondary-base"
              flex
              size={'2xl'}
            />
            <Button
              testID="buttonDownloadiOS"
              text={t('inviteSuccess.btnDownloadOnIos')}
              onPress={() => openAppStore('ios')}
              flex
              type="secondary-contrast"
              size={'2xl'}
            />
          </DownloadOnMobileContainer>
          {/* ImageWrapper */}
          {/* Title */}
          {/* Two Go To Buttons */}
          {/* Two buttons for download either android or iOS */}
        </>
      </AuthWebLayout>
    );
  }

  return (
    <Container>
      <TopContainer>
        <ImageContainer source={general.invite_success} />
        <NavHeader
          backButtonTestID={'buttonBack'}
          colorStyle={'light'}
          RightComponent={
            <Button
              testID="buttonLogout"
              size="sm"
              text={t('g.logout')}
              type="secondary-base"
              onPress={onLogout}
            />
          }
        />
        <TitleWrapper>
          <BadgeText>{t('inviteSuccess.badgeText')}</BadgeText>
          <Title>{t('inviteSuccess.title')}</Title>
        </TitleWrapper>
      </TopContainer>
      <BottomContainer>
        <DescWrapper>
          <Desc>{t('inviteSuccess.desc1')}</Desc>
          <Desc>{t('inviteSuccess.desc2')}</Desc>
        </DescWrapper>
        <Button
          size="2xl"
          flex
          testID={'buttonStartPlanning'}
          text={t('inviteSuccess.buttonText')}
          type="secondary-contrast"
          onPress={onStartPlaning}
        />
      </BottomContainer>
    </Container>
  );
}
