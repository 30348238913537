import styled from 'styled-components/native';
import Icon from '../Icon';

interface ISelectable {
  selected: boolean;
}

export const Container = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})<ISelectable>`
  background-color: ${({ theme }) => theme.color.base.c0};

  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  padding: 16px;
  border-width: 1px;
  border-color: ${({ selected, theme }) =>
    selected ? theme.color.base.c2 : theme.color.base.c2};
  border-radius: 14px;
  box-shadow: -3px 3px 2px ${({ theme }) => theme.color.base.c1};
`;

export const Text = styled.Text<ISelectable>`
  margin-left: 16px;
  flex: 1;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ selected, theme }) =>
    selected ? theme.color.brand_02 : theme.color.base.c7};
  font-size: 16px;
`;

export const ArrowIcon = styled(Icon).attrs<ISelectable>(
  ({ selected, theme }) => ({
    size: 16,
    color: selected ? theme.color.brand_02 : theme.color.base.c7,
  }),
)<ISelectable>`
  padding-left: 8px;
`;
