import styled from 'styled-components/native';

export const Container = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  width: 100%;
  padding: 20px;
  border-radius: 15px;
`;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 16px;
  padding-left: 10px;
`;

export const HeaderContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const TitleContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const DeleteButton = styled.Text`
  color: ${({ theme }) => theme.color.error};
  font-size: 12px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  text-decoration-line: underline;
`;

export const Label = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 14px;
  color: ${({ theme }) => theme.color.base.c5};
  text-transform: uppercase;
`;

export const LabelContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const UploadCSVText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 12px;
  color: ${({ theme }) => theme.color.brand_02};
  text-decoration-line: underline;
`;

export const UploadedCSVFile = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 12px;
  color: ${({ theme }) => theme.color.base.c9};
`;

export const UploadedCSVErrorContainer = styled.View`
  flex-direction: row;
`;

export const UploadedCSVErrorText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 12px;
  color: ${({ theme }) => theme.color.error};
  padding-right: 5px;
`;

export const UploadingCSVText = styled.Text`
  font-size: 12px;
  color: ${({ theme }) => theme.color.base.c6};
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
`;

export const DeleteIconWrapper = styled.View`
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.color.base.c6};
  align-items: center;
  margin-left: 10px;
  justify-content: center;
`;

export const DeleteIconContainer = styled.View`
  flex-direction: row;
`;

export const DropdownWrapper = styled.View`
  display: flex;
  flex-direction: row;
`;

export const RegistrationDate = styled.TouchableOpacity`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  background-color: ${({ theme }) => theme.color.base.c0};
  color: ${({ theme }) => theme.color.base.c6};
  border-width: 1px;
  border-color: ${({ theme }) => theme.color.base.c3};
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 7px;
  flex-direction: row;
  align-items: center;
  margin-left: 8px;
  width: 109px;
`;
