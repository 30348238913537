import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components/native';
import { t } from '~/utils/i18n';
import Breadcrumbs from '../Breadcrumbs';
import Button from '../Button';
import MobileWebHeader from '../MobileWebHeader';
import NavHeader from '../NavHeader';
import { Content, MobileTitle } from './style';

type AdminHeaderProps = {
  title: string;
  onBack: () => void;
  onSubmitClick?: () => void;
  enableSaveChanges?: boolean;
  breadcrumbs?: string[];
  buttonTitle: string;
  mobileTitle?: string;
  onOpenDrawer: () => void;
};

export default function AdminHeader({
  title,
  onBack,
  onSubmitClick,
  enableSaveChanges,
  breadcrumbs,
  buttonTitle,
  onOpenDrawer,
  mobileTitle = t('adminPanelSearch.search'),
}: AdminHeaderProps): JSX.Element {
  const theme = useTheme();
  const isDesktop = useMediaQuery({ minWidth: theme.breakpoints.desktopMin });

  const showMobileSubtitleContent = breadcrumbs && breadcrumbs.length > 1;

  if (isDesktop) {
    return (
      <NavHeader
        backButtonTestID={'buttonBack'}
        showBackIcon={false}
        title={title}
        onBackPress={onBack}
        breadcrumbs={breadcrumbs}
        RightComponent={
          onSubmitClick &&
          isDesktop && (
            <Button
              testID="saveChangesBtn"
              text={buttonTitle}
              onPress={onSubmitClick}
              state={enableSaveChanges ? 'activeWeb' : 'disabled'}
              size={'lg'}
            />
          )
        }
      />
    );
  }

  return (
    <>
      <MobileWebHeader title={mobileTitle} openDrawer={onOpenDrawer} />

      {showMobileSubtitleContent && (
        <Content>
          <MobileTitle>{title}</MobileTitle>
          <Breadcrumbs routes={breadcrumbs} onNavigatePress={onBack} />
        </Content>
      )}
    </>
  );
}
