import React from 'react';
import { FlatList, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components/native';
import IconStyled from '~/components/IconStyled';
import { Spacer } from '~/components/common/style.web';
import { EditInstituteActivityFields } from '~/data/models/admin';
import {
  Container,
  RowOverview,
  RowCell,
  RowCellText,
  IconContainer,
  RowYearCell,
  RowYearCellText,
} from './style';

type ActivityOverviewProps = {
  activities: EditInstituteActivityFields[];
};

// const ICONS: IconStyledName[] = [
//   'icn/teams/clothing',
//   'icn/teams/book',
//   'graduation',
// ];

export default function ActivityOverview({
  activities,
}: ActivityOverviewProps): JSX.Element {
  const theme = useTheme();
  const isDesktop = useMediaQuery({ minWidth: theme.breakpoints.desktopMin });

  const renderCell = ({
    item: activity,
  }: {
    item: EditInstituteActivityFields;
  }) => {
    const { status, year } = activity;
    return (
      <View>
        <Spacer h={2} />
        <RowCell status={status} isDesktop={isDesktop}>
          <RowCellText status={status} isDesktop={isDesktop}>
            {status}
          </RowCellText>
        </RowCell>
        {year !== null && renderYear(year.toString())}
      </View>
    );
  };

  const renderYear = (year: string) => {
    return (
      <RowYearCell isDesktop={isDesktop}>
        <RowYearCellText>{year}</RowYearCellText>
      </RowYearCell>
    );
  };

  return (
    <Container>
      <RowOverview>
        <IconContainer>
          <IconStyled
            name={'graduation'}
            size={24}
            color={theme.color.brand_02}
          />
        </IconContainer>
        <Spacer h={10} />
        <FlatList horizontal data={activities} renderItem={renderCell} />
      </RowOverview>
    </Container>
  );
}
