import styled from 'styled-components/native';
import Icon from '~/components/Icon';
import SectionHeaderComp from '~/components/SectionHeader';
import { TabScreenContainer } from '~/screens/style';

export const Container = styled(TabScreenContainer)``;

export const SectionHeader = styled(SectionHeaderComp)`
  padding-horizontal: 16px;
  padding-top: 24px;
  margin-bottom: 16px;
`;

export const List = styled.SectionList``;

export const CircularButton = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})`
  align-self: flex-start;
  margin-left: 16px;
  margin-top: 8px;
  padding: 8px 16px;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.color.base.c1};
  flex-direction: row;
  align-items: center;
`;

export const CircularButtonText = styled.Text`
  margin-left: 8px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.brand_02};
  font-size: 14px;
  line-height: 18px;
`;

export const AddIcon = styled(Icon).attrs(({ theme }) => ({
  name: 'plus',
  size: 16,
  color: theme.color.brand_02,
}))``;
