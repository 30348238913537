import React from 'react';
import {
  useNavigation,
  useRoute,
  RouteProp,
  CompositeNavigationProp,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { useIntercom } from '~/context/intercom';
import {
  DEFAULT_ALBUM_PHOTOS_OFFSET,
  DEFAULT_LIST_OFFSET,
} from '~/data/constants';
import useAuthTeams from '~/data/hooks/useAuthTeams';
import useTeamSamples from '~/data/hooks/useTeamSamples';
import { CollagesAlbum } from '~/data/models/yearbook';
import { useCollagesAlbumsQuery } from '~/data/operations/yearbook/collagesAlbums';
import { useDestroyCollagesAlbumMutation } from '~/data/operations/yearbook/destroyCollagesAlbum';
import {
  readCollagesAlbumsQuery,
  writeCollagesAlbumsQuery,
} from '~/data/operations/yearbook/helpers';
import { useModuleInstanceQuery } from '~/data/operations/yearbook/moduleInstance';
import { RootStackParamList, YearbookStackParamList } from '~/navigation/types';
import { isDateExpired } from '~/utils/dates';
import CollagesLayout from './layout';

type CollagesNavProp = CompositeNavigationProp<
  StackNavigationProp<YearbookStackParamList, 'Collages'>,
  StackNavigationProp<RootStackParamList>
>;

type CollagesRouteProp = RouteProp<YearbookStackParamList, 'Collages'>;

export default function Collages(): JSX.Element {
  const navigation = useNavigation<CollagesNavProp>();
  const { onHelpCenter } = useIntercom();
  const {
    params: { moduleInstanceId, viewOnly },
  } = useRoute<CollagesRouteProp>();

  const { authGroupId, authUserGroupId, yearbookAdmin, authUserId } = useAuth();

  const { authTeamIds } = useAuthTeams();

  const { teamSamples } = useTeamSamples();
  const yearbookTeam = teamSamples.filter((t) => t.type === 'YEARBOOK');

  const { data: moduleInstanceData } = useModuleInstanceQuery({
    variables: {
      id: moduleInstanceId,
    },
  });

  const isActive = moduleInstanceData?.moduleInstance?.isActive;
  const dueDate = moduleInstanceData?.moduleInstance?.dueDate;
  const isDeadlinePassed = dueDate && isDateExpired(dueDate);

  const collagesSetup = moduleInstanceData?.moduleInstance?.collagesSetup;
  const createAlbumsTeam = collagesSetup?.createAlbumsTeam;

  const addAlbumEnabled =
    createAlbumsTeam === null ||
    authTeamIds.includes(createAlbumsTeam?.id as string);

  const collagesAlbumsVar = {
    after: null,
    first: DEFAULT_LIST_OFFSET,
    cpFirst: DEFAULT_ALBUM_PHOTOS_OFFSET,
    moduleInstance: moduleInstanceId,
    authUserGroupId,
  };

  const {
    data: collagesAlbumsData,
    loading: collagesAlbumsLoading,
    refetch: refetchCollagesAlbums,
    fetchMore: fetchCollagesAlbums,
  } = useCollagesAlbumsQuery({
    skip: !authUserGroupId,
    variables: collagesAlbumsVar,
    notifyOnNetworkStatusChange: true,
  });

  const collagesAlbums: CollagesAlbum[] =
    (collagesAlbumsData?.collagesAlbums?.edges.map(
      (edge) => edge?.node,
    ) as CollagesAlbum[]) || [];
  const hasNextPage = collagesAlbumsData?.collagesAlbums?.pageInfo.hasNextPage;
  const endCursor = collagesAlbumsData?.collagesAlbums?.pageInfo.endCursor;

  const [destroyCollagesAlbum] = useDestroyCollagesAlbumMutation();

  const onDeleteAlbum = async (collagesAlbumId: string) => {
    try {
      await destroyCollagesAlbum({
        variables: {
          input: {
            id: collagesAlbumId,
          },
        },
        update(cache) {
          const currentCollagesAlbumQuery = readCollagesAlbumsQuery({
            cache,
            variables: collagesAlbumsVar,
          });
          if (currentCollagesAlbumQuery?.collagesAlbums?.edges) {
            const filteredEdges =
              currentCollagesAlbumQuery.collagesAlbums.edges.filter(
                (edge) => edge?.node?.id !== collagesAlbumId,
              );
            writeCollagesAlbumsQuery({
              cache,
              variables: collagesAlbumsVar,
              data: {
                ...currentCollagesAlbumQuery,
                collagesAlbums: {
                  ...currentCollagesAlbumQuery.collagesAlbums,
                  edges: filteredEdges,
                },
              },
            });
          }
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const openBannersInBrowser = (url: string) => {
    navigation.navigate('Browser', {
      title: 'Banner',
      url,
    });
  };

  return (
    <CollagesLayout
      collagesAlbums={collagesAlbums}
      isActive={isActive}
      isDeadlinePassed={isDeadlinePassed}
      yearbookAdmin={yearbookAdmin}
      viewOnly={viewOnly}
      addAlbumEnabled={addAlbumEnabled}
      loading={collagesAlbumsLoading}
      hasNextPage={hasNextPage}
      openBannersInBrowser={openBannersInBrowser}
      onBack={() => navigation.goBack()}
      onSetup={() => navigation.navigate('CollagesSetup', { moduleInstanceId })}
      onHelp={onHelpCenter}
      onRequestYearbookTeam={() => {
        navigation.popToTop();
        navigation.navigate('MainTab', {
          screen: 'PlanningStack',
          gid: authGroupId,
          params: {
            screen: 'TeamDetail',
            params: {
              teamId: yearbookTeam[0]?.id,
            },
          },
        });
      }}
      onAddAlbum={() =>
        navigation.navigate('CreateCollage', { moduleInstanceId })
      }
      onSelectedAlbum={(collageAlbumId) =>
        navigation.navigate('CollagesAlbumDetail', {
          moduleInstanceId,
          collageAlbumId,
          viewOnly,
        })
      }
      onDeleteAlbum={onDeleteAlbum}
      onRefresh={() => {
        refetchCollagesAlbums();
      }}
      onLoadMore={() => {
        fetchCollagesAlbums({
          variables: {
            ...collagesAlbumsVar,
            after: endCursor,
          },
        });
      }}
      authUserId={authUserId}
    />
  );
}
