import React from 'react';
import {
  CompositeNavigationProp,
  useNavigation,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useAuth } from '~/context/auth';
import { DEFAULT_LIST_OFFSET } from '~/data/constants';
import { DataExport } from '~/data/models/yearbook';
import { useDataExportsQuery } from '~/data/operations/yearbook/dataExports';
import { RootStackParamList, YearbookStackParamList } from '~/navigation/types';
import ExportHistoryLayout from './layout';

type ExportHistoryNavProp = CompositeNavigationProp<
  StackNavigationProp<YearbookStackParamList, 'ExportHistory'>,
  StackNavigationProp<RootStackParamList>
>;

export default function ExportHistory(): JSX.Element {
  const navigation = useNavigation<ExportHistoryNavProp>();

  const { authGroupId } = useAuth();

  const dataExportsVar = {
    after: null,
    first: DEFAULT_LIST_OFFSET,
    group: authGroupId,
  };

  const {
    data: dataExportsData,
    loading: dataExportsLoading,
    refetch: refetchDataExports,
    fetchMore: fetchMoreDataExports,
  } = useDataExportsQuery({
    skip: !authGroupId,
    variables: dataExportsVar,
    notifyOnNetworkStatusChange: true,
  });

  const dataExports: DataExport[] =
    (dataExportsData?.dataExports?.edges.map(
      (edge) => edge?.node,
    ) as DataExport[]) || [];
  const hasNextPage = dataExportsData?.dataExports?.pageInfo.hasNextPage;
  const endCursor = dataExportsData?.dataExports?.pageInfo.endCursor;

  return (
    <ExportHistoryLayout
      dataExports={dataExports}
      loading={dataExportsLoading}
      onBack={() => navigation.goBack()}
      onSelectedExport={(exportLink) =>
        navigation.navigate('Browser', { title: '', url: exportLink })
      }
      onRefresh={() => {
        refetchDataExports();
      }}
      onLoadMore={() => {
        hasNextPage &&
          fetchMoreDataExports({
            variables: {
              ...dataExportsVar,
              after: endCursor,
            },
          });
      }}
    />
  );
}
