import styled from 'styled-components/native';
import Icon from '../Icon';

export const PaginatorContainer = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  flex-direction: row;
  max-height: fit-content;
`;

interface IPageTextProps {
  isActive?: boolean;
}

export const PageText = styled.Text<IPageTextProps>`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme, isActive }) =>
    isActive ? theme.color.base.c9 : theme.color.base.c4};
  font-size: 18px;
  line-height: 22px;
  user-select: none;
`;

export const PaginatorBox = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  padding: 4px 12px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.color.base.c3};
  justify-content: center;
  align-items: center;
`;

/* border-radius: 5px 0 0 5px;  Does not work like this in RN & Styled components (https://github.com/styled-components/styled-components/issues/2048)*/
export const LeftPaginatorBox = styled(PaginatorBox)`
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

export const RightPaginatorBox = styled(LeftPaginatorBox)`
  transform: rotate(-180deg);
`;

// TODO add icons from mockups
interface IArrowIcon {
  enabled: boolean;
}
export const ArrowIcon = styled(Icon).attrs<IArrowIcon>(
  ({ theme, enabled }) => ({
    name: 'chevron-left',
    size: 16,
    color: enabled ? theme.color.brand_02 : theme.color.base.c3,
  }),
)<IArrowIcon>``;
