/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query InstituteRequests
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  InstituteRequests,
  InstituteRequestsVariables,
} from './types/InstituteRequests';
import { INSTITUTE_REQUESTS } from './';

export function useInstituteRequestsQuery(
  options?: QueryHookOptions<InstituteRequests, InstituteRequestsVariables>,
): QueryResult<InstituteRequests, InstituteRequestsVariables> {
  return useQuery<InstituteRequests, InstituteRequestsVariables>(
    INSTITUTE_REQUESTS,
    options,
  );
}

export function useInstituteRequestsLazyQuery(
  options?: LazyQueryHookOptions<InstituteRequests, InstituteRequestsVariables>,
): QueryTuple<InstituteRequests, InstituteRequestsVariables> {
  return useLazyQuery<InstituteRequests, InstituteRequestsVariables>(
    INSTITUTE_REQUESTS,
    options,
  );
}

export function createInstituteRequestsMock({
  variables,
  data,
  error,
}: {
  variables?: InstituteRequestsVariables;
  data?: InstituteRequests;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: INSTITUTE_REQUESTS,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
