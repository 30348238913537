/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation UpdateProfilePageComment
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  UpdateProfilePageComment,
  UpdateProfilePageCommentVariables,
} from './types/UpdateProfilePageComment';
import { UPDATE_PROFILE_PAGE_COMMENT } from './';

export function useUpdateProfilePageCommentMutation(
  options?: MutationHookOptions<
    UpdateProfilePageComment,
    UpdateProfilePageCommentVariables
  >,
): MutationTuple<UpdateProfilePageComment, UpdateProfilePageCommentVariables> {
  return useMutation<
    UpdateProfilePageComment,
    UpdateProfilePageCommentVariables
  >(UPDATE_PROFILE_PAGE_COMMENT, options);
}

export function createUpdateProfilePageCommentMock({
  variables,
  data,
  error,
}: {
  variables?: UpdateProfilePageCommentVariables;
  data?: UpdateProfilePageComment;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: UPDATE_PROFILE_PAGE_COMMENT,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
