/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query GroupStatistics
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  GroupStatistics,
  GroupStatisticsVariables,
} from './types/GroupStatistics';
import { GROUP_STATISTICS } from './';

export function useGroupStatisticsQuery(
  options?: QueryHookOptions<GroupStatistics, GroupStatisticsVariables>,
): QueryResult<GroupStatistics, GroupStatisticsVariables> {
  return useQuery<GroupStatistics, GroupStatisticsVariables>(
    GROUP_STATISTICS,
    options,
  );
}

export function useGroupStatisticsLazyQuery(
  options?: LazyQueryHookOptions<GroupStatistics, GroupStatisticsVariables>,
): QueryTuple<GroupStatistics, GroupStatisticsVariables> {
  return useLazyQuery<GroupStatistics, GroupStatisticsVariables>(
    GROUP_STATISTICS,
    options,
  );
}

export function createGroupStatisticsMock({
  variables,
  data,
  error,
}: {
  variables?: GroupStatisticsVariables;
  data?: GroupStatistics;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: GROUP_STATISTICS,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
