import React, { useState, useEffect, useRef } from 'react';
import { FlatList, Platform } from 'react-native';
import { useTheme } from 'styled-components/native';
import { GroupIcon } from '~/data/models/custom';
import { t } from '~/utils/i18n';
import {
  ImageContainer,
  SelectedImage,
  ImageContent,
  ImageListContent,
  IconContent,
  LeftButton,
  RightButton,
  ImageList,
  ImageItemButton,
  ImageItem,
  UploadButton,
  PhotoIcon,
  UploadText,
  FileInput,
  FileInputLabel,
} from './style';

// TODO: This component is old design version of ImagePicker in screens/CreateGroup/CustomIconPicker. When we redesign here we should remove this component and use ImagePicker instead.

type ImageGroupPickerProps = {
  testID?: string;
  selectedImageTestID?: string;
  selectedImgUrl?: string;
  groupIcons: GroupIcon[];
  onSelectedIcon: (icon: GroupIcon) => void;
  onUploadOwnPhoto(): Promise<void>;
};

export default function OldImageGroupPicker({
  testID,
  selectedImageTestID,
  selectedImgUrl,
  groupIcons,
  onSelectedIcon,
  onUploadOwnPhoto,
}: ImageGroupPickerProps): JSX.Element {
  const INITIAL_INDEX = 0;
  const imageListRef = useRef<FlatList>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(INITIAL_INDEX);
  const theme = useTheme();

  useEffect(() => {
    if (imageListRef.current && currentIndex > INITIAL_INDEX) {
      imageListRef.current.scrollToIndex({
        animated: true,
        index: currentIndex,
      });
    }
  }, [currentIndex]);
  const renderItem = ({ item: groupIcon }: { item: GroupIcon }) => (
    <ImageItemButton onPress={() => onSelectedIcon(groupIcon)}>
      <ImageItem source={{ uri: groupIcon.icon || undefined }} />
    </ImageItemButton>
  );

  return (
    <ImageContainer testID={testID}>
      <SelectedImage
        testID={selectedImageTestID}
        source={{ uri: selectedImgUrl }}
      />
      <ImageContent>
        <ImageListContent>
          <IconContent>
            <LeftButton
              testID={'buttonImageListLeft'}
              onPress={() => {
                if (currentIndex > INITIAL_INDEX) {
                  setCurrentIndex(currentIndex - 1);
                }
              }}
            />
          </IconContent>
          <ImageList<React.ElementType>
            ref={imageListRef}
            horizontal
            showsHorizontalScrollIndicator={false}
            data={groupIcons}
            renderItem={renderItem}
            keyExtractor={(icon: GroupIcon) => icon.id}
          />
          <IconContent>
            <RightButton
              testID={'buttonImageListRight'}
              onPress={() => {
                if (currentIndex < groupIcons.length - 1) {
                  setCurrentIndex(currentIndex + 1);
                }
              }}
            />
          </IconContent>
        </ImageListContent>
        {Platform.OS !== 'web' ? (
          <UploadButton onPress={onUploadOwnPhoto}>
            <PhotoIcon />
            <UploadText>{t('createGroup.uploadOwnPhoto')}</UploadText>
          </UploadButton>
        ) : (
          <FileInputLabel htmlFor="groupFile" theme={theme}>
            <PhotoIcon />
            <UploadText>{t('createGroup.uploadOwnPhoto')}</UploadText>
            <FileInput
              id="groupFile"
              type="file"
              onChange={onUploadOwnPhoto}
              onClick={(event) => {
                const target = event.target as HTMLInputElement;
                return (target.value = '');
              }}
            />
          </FileInputLabel>
        )}
      </ImageContent>
    </ImageContainer>
  );
}
