/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation ImportFromAbihome
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  ImportFromAbihome,
  ImportFromAbihomeVariables,
} from './types/ImportFromAbihome';
import { IMPORT_FROM_ABIHOME } from './';

export function useImportFromAbihomeMutation(
  options?: MutationHookOptions<ImportFromAbihome, ImportFromAbihomeVariables>,
): MutationTuple<ImportFromAbihome, ImportFromAbihomeVariables> {
  return useMutation<ImportFromAbihome, ImportFromAbihomeVariables>(
    IMPORT_FROM_ABIHOME,
    options,
  );
}

export function createImportFromAbihomeMock({
  variables,
  data,
  error,
}: {
  variables?: ImportFromAbihomeVariables;
  data?: ImportFromAbihome;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: IMPORT_FROM_ABIHOME,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
