import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import Lobby from '~/screens/Lobby/Lobby';
import { LobbyStackParamList } from '../../types';

const LobbyStackNavigator = createStackNavigator<LobbyStackParamList>();

export default function LobbyStack(): JSX.Element {
  return (
    <LobbyStackNavigator.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <LobbyStackNavigator.Screen
        name={'Lobby'}
        component={Lobby}
        options={{ animationTypeForReplace: 'pop' }}
      />
    </LobbyStackNavigator.Navigator>
  );
}
