import { Picker } from '@react-native-picker/picker';
import styled from 'styled-components/native';

export const Container = styled.View``;

export const Header = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})`
  flex-direction: row;
  padding-top: 16px;
  padding-bottom: 8px;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.color.base.c3};
`;

export const TypeTitle = styled.Text`
  flex: 1;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 16px;
  line-height: 22px;
`;

export const TypeValue = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.brand_02};
  font-size: 16px;
  line-height: 22px;
`;
export const LangPicker = styled(Picker).attrs(({ theme }) => ({
  itemStyle: {
    color: theme.color.brand_02,
    fontFamily: theme.fontFamily.core.w600,
  },
}))``;
