import React from 'react';
import { View } from 'react-native';
import { Module } from '~/data/models/yearbook';
import { IconName } from '../Icon';
import {
  CellIcon,
  Container,
  DisclamerText,
  IconContainer,
  ModuleImage,
  LeftSideContent,
  ModuleName,
} from './style';

type AddModuleCellProps = {
  testID?: string;
  module: Module;
  isAdded?: boolean;
  disclaimerText?: string;
  disableRemove?: boolean;
  onCellPress?: () => void;
  onAddPress: () => void;
};

export default function AddModuleCell({
  testID,
  module,
  isAdded = false,
  disclaimerText,
  disableRemove,
  onAddPress,
  onCellPress,
}: AddModuleCellProps): JSX.Element {
  const iconName: IconName = isAdded ? 'check' : 'plus';

  return (
    <Container
      testID={testID}
      isAdded={isAdded}
      disabled={!onCellPress}
      onPress={() => onCellPress && onCellPress()}
    >
      <LeftSideContent>
        <ModuleImage source={{ uri: module.image || '' }} isAdded={isAdded} />
        <View>
          <ModuleName>{module.name}</ModuleName>
          {isAdded && disclaimerText ? (
            <DisclamerText testID={`added:${testID}`}>
              {disclaimerText}
            </DisclamerText>
          ) : null}
        </View>
      </LeftSideContent>
      <IconContainer
        testID={`onAdd:${testID}`}
        disabled={disableRemove && isAdded}
        isAdded={isAdded}
        onPress={onAddPress}
      >
        <CellIcon
          testID={`${isAdded ? 'addedIcon' : 'addIcon'}:${testID}`}
          name={iconName}
          isAdded={isAdded}
        />
      </IconContainer>
    </Container>
  );
}
