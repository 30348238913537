import React, { useState } from 'react';
import CheckBox from '~/components/CheckBox';
import IconStyled, { IconStyledName } from '~/components/IconStyled';
import { ImportStatuses } from '~/navigation/types';
import {
  CheckText,
  CheckTextLink,
  CheckTextView,
  Row,
} from '~/screens/Auth/SignUpTerm/layout/style';
import ImportStatus from '~/screens/Importer/ImportStatus/layout';
import { IMPORTER_TOTAL_PROGRESS } from '~/screens/Importer/constants';
import ImporterLayout from '~/screens/Importer/layout';
import { t } from '~/utils/i18n';
import { ImportItem, Label, SubHeader } from './style';

type OverviewLayoutProps = {
  onBack: () => void;
  onShowPolicy: () => void;
  onShowTerm: () => void;
  onNext: (accepted: boolean) => void;
  loading: boolean;
  userCount: number | undefined;
  onBackToBeginning: () => void;
  hasError: boolean;
  hasSuccess: boolean;
};

const IMPORTING: { icon: IconStyledName; t: string }[] = [
  { icon: 'add_user_light', t: 'importer.importingUsers' },
  { icon: 'open_book_light', t: 'importer.importingYearbook' },
  { icon: 'poll_light', t: 'importer.importingPolls' },
];

export default function Overview({
  onBack,
  onNext,
  onShowPolicy,
  onShowTerm,
  loading,
  userCount,
  onBackToBeginning,
  hasError,
  hasSuccess,
}: OverviewLayoutProps): JSX.Element {
  const [dataPolicyAcceptance, setDataPolicyAcceptance] =
    useState<boolean>(false);
  const [termsAcceptance, setTermsAcceptance] = useState<boolean>(false);

  const isValidInput = (): boolean => {
    return dataPolicyAcceptance && termsAcceptance;
  };

  const handleNext = () => {
    isValidInput() && onNext && onNext(dataPolicyAcceptance && termsAcceptance);
  };

  const headerProps = {
    title: t('importer.letsGetStarted'),
    descp: t('importer.weAreImporting'),
    progress: 3,
    total: IMPORTER_TOTAL_PROGRESS,
  };

  const submitButtonProps = {
    isValidInput,
    text: t('importer.startImport'),
    loading,
    onPress: handleNext,
  };

  if (hasError) {
    return (
      <ImportStatus
        onBackToBeginning={onBackToBeginning}
        status={ImportStatuses.error}
      />
    );
  }

  if (hasSuccess) {
    return (
      <ImportStatus
        onBackToBeginning={onBackToBeginning}
        status={ImportStatuses.success}
      />
    );
  }

  return loading ? (
    <ImportStatus
      onBackToBeginning={onBackToBeginning}
      status={ImportStatuses.processing}
    />
  ) : (
    <ImporterLayout
      headerProps={headerProps}
      submitButtonProps={submitButtonProps}
      onBack={onBack}
    >
      <SubHeader mb={24}>{t('importer.weImport')}</SubHeader>
      {IMPORTING.map((IMPORT) => (
        <ImportItem key={IMPORT.icon}>
          <IconStyled name={IMPORT.icon} size={32} />
          <Label>
            {IMPORT.t.includes('importingUsers')
              ? t(IMPORT.t, { n: userCount })
              : t(IMPORT.t)}
          </Label>
          <IconStyled name="check_primary" size={16} />
        </ImportItem>
      ))}
      <SubHeader mb={8}>{t('importer.dataStayingSafe')}</SubHeader>
      <Row>
        <CheckBox
          testID={'checkBoxPolicy'}
          size={'medium'}
          checked={dataPolicyAcceptance}
          onChange={(checked) => setDataPolicyAcceptance(checked)}
        />
        <CheckTextView>
          <CheckText>
            {t('signUpTerm.checkText1')}
            <CheckTextLink
              testID={'buttonLinkPolicy'}
              onPress={() => onShowPolicy && onShowPolicy()}
            >
              {t('dataPolicy')}
            </CheckTextLink>
            {t('signUpTerm.checkText2')}
          </CheckText>
        </CheckTextView>
      </Row>
      <Row>
        <CheckBox
          testID={'checkBoxTerm'}
          size={'medium'}
          checked={termsAcceptance}
          onChange={(checked) => setTermsAcceptance(checked)}
        />
        <CheckTextView>
          <CheckText>
            {t('signUpTerm.checkText1')}
            <CheckTextLink
              testID={'buttonLinkTerm'}
              onPress={() => onShowTerm && onShowTerm()}
            >
              {t('term')}
            </CheckTextLink>
            {t('signUpTerm.checkText2')}
          </CheckText>
        </CheckTextView>
      </Row>
    </ImporterLayout>
  );
}
