import React, { useState } from 'react';
import Button from '~/components/Button';
import CheckBox from '~/components/CheckBox';
import NavHeader from '~/components/NavHeader';
import { t } from '~/utils/i18n';
import {
  Container,
  ScrollView,
  Image,
  Title,
  Descp,
  CheckRow,
  CheckText,
  Footer,
  SuccessContainer,
  SuccessTitle,
} from './style';

type DeleteAccountLayoutProps = {
  deleteSuccess: boolean;
  loading: boolean;
  initialChecked?: boolean;
  onBack: () => void;
  onDelete: () => void;
  onBackToStart: () => void;
};

export default function DeleteAccount({
  deleteSuccess,
  loading,
  initialChecked = false,
  onBack,
  onDelete,
  onBackToStart,
}: DeleteAccountLayoutProps): JSX.Element {
  const [checked, setChecked] = useState(initialChecked);
  if (deleteSuccess) {
    return (
      <SuccessContainer>
        <ScrollView>
          <Image source={require('~/assets/imgs/gif/delete_user_2.gif')} />
          <SuccessTitle>{t('deleteUserSuccess.title')}</SuccessTitle>
          <Descp>{t('deleteUserSuccess.descp')}</Descp>
        </ScrollView>
        <Footer>
          <Button
            testID={'buttonBackToStart'}
            type={'secondary-base'}
            text={t('deleteUserSuccess.backToStart')}
            onPress={() => onBackToStart()}
          />
        </Footer>
      </SuccessContainer>
    );
  }
  return (
    <Container>
      <NavHeader
        backButtonTestID={'buttonBack'}
        title={t('screens.deleteAccount')}
        onBackPress={onBack}
      />
      <ScrollView>
        <Image source={require('~/assets/imgs/gif/delete_user_1.gif')} />
        <Title>{t('deleteUser.title')}</Title>
        <Descp>{t('deleteUser.descp')}</Descp>
        <CheckRow>
          <CheckBox
            testID={'checkBox'}
            checked={checked}
            onChange={setChecked}
          />
          <CheckText>{t('deleteUser.checkText')}</CheckText>
        </CheckRow>
      </ScrollView>
      <Footer>
        <Button
          testID={'buttonDelete'}
          state={checked ? 'default' : 'disabled'}
          type={'destructive'}
          text={t('deleteUser.deleteButton')}
          loading={loading}
          onPress={() => onDelete()}
        />
      </Footer>
    </Container>
  );
}
