/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query CollagesAlbum
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { CollagesAlbum, CollagesAlbumVariables } from './types/CollagesAlbum';
import { COLLAGES_ALBUM } from './';

export function useCollagesAlbumQuery(
  options?: QueryHookOptions<CollagesAlbum, CollagesAlbumVariables>,
): QueryResult<CollagesAlbum, CollagesAlbumVariables> {
  return useQuery<CollagesAlbum, CollagesAlbumVariables>(
    COLLAGES_ALBUM,
    options,
  );
}

export function useCollagesAlbumLazyQuery(
  options?: LazyQueryHookOptions<CollagesAlbum, CollagesAlbumVariables>,
): QueryTuple<CollagesAlbum, CollagesAlbumVariables> {
  return useLazyQuery<CollagesAlbum, CollagesAlbumVariables>(
    COLLAGES_ALBUM,
    options,
  );
}

export function createCollagesAlbumMock({
  variables,
  data,
  error,
}: {
  variables?: CollagesAlbumVariables;
  data?: CollagesAlbum;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: COLLAGES_ALBUM,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
