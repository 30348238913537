/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query Groups
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { Groups, GroupsVariables } from './types/Groups';
import { GROUPS } from './';

export function useGroupsQuery(
  options?: QueryHookOptions<Groups, GroupsVariables>,
): QueryResult<Groups, GroupsVariables> {
  return useQuery<Groups, GroupsVariables>(GROUPS, options);
}

export function useGroupsLazyQuery(
  options?: LazyQueryHookOptions<Groups, GroupsVariables>,
): QueryTuple<Groups, GroupsVariables> {
  return useLazyQuery<Groups, GroupsVariables>(GROUPS, options);
}

export function createGroupsMock({
  variables,
  data,
  error,
}: {
  variables?: GroupsVariables;
  data?: Groups;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: GROUPS,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
