/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query AdminGetUsers
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { AdminGetUsers, AdminGetUsersVariables } from './types/AdminGetUsers';
import { ADMIN_GET_USERS } from './';

export function useAdminGetUsersQuery(
  options?: QueryHookOptions<AdminGetUsers, AdminGetUsersVariables>,
): QueryResult<AdminGetUsers, AdminGetUsersVariables> {
  return useQuery<AdminGetUsers, AdminGetUsersVariables>(
    ADMIN_GET_USERS,
    options,
  );
}

export function useAdminGetUsersLazyQuery(
  options?: LazyQueryHookOptions<AdminGetUsers, AdminGetUsersVariables>,
): QueryTuple<AdminGetUsers, AdminGetUsersVariables> {
  return useLazyQuery<AdminGetUsers, AdminGetUsersVariables>(
    ADMIN_GET_USERS,
    options,
  );
}

export function createAdminGetUsersMock({
  variables,
  data,
  error,
}: {
  variables?: AdminGetUsersVariables;
  data?: AdminGetUsers;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: ADMIN_GET_USERS,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
