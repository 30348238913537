/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query GroupUsers
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { GroupUsers, GroupUsersVariables } from './types/GroupUsers';
import { GROUP_USERS } from './';

export function useGroupUsersQuery(
  options?: QueryHookOptions<GroupUsers, GroupUsersVariables>,
): QueryResult<GroupUsers, GroupUsersVariables> {
  return useQuery<GroupUsers, GroupUsersVariables>(GROUP_USERS, options);
}

export function useGroupUsersLazyQuery(
  options?: LazyQueryHookOptions<GroupUsers, GroupUsersVariables>,
): QueryTuple<GroupUsers, GroupUsersVariables> {
  return useLazyQuery<GroupUsers, GroupUsersVariables>(GROUP_USERS, options);
}

export function createGroupUsersMock({
  variables,
  data,
  error,
}: {
  variables?: GroupUsersVariables;
  data?: GroupUsers;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: GROUP_USERS,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
