import styled from 'styled-components/native';
import IconStyled from '~/components/IconStyled';

export const Container = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  padding: 16px 16px 16px;
  flex-direction: row;
`;

export const Content = styled.View`
  flex-direction: column;
  justify-content: center;
`;

export const Text = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 18px;
  line-height: 20px;
  padding-left: 16px;
`;

export const ImageIcon = styled(IconStyled).attrs(({ theme }) => ({
  name: 'images',
  size: 24,
  color: theme.color.base.c7,
}))``;

export const PollIcon = styled(IconStyled).attrs(({ theme }) => ({
  name: 'poll',
  size: 24,
  color: theme.color.base.c7,
}))``;
