/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query Institutes
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { Institutes, InstitutesVariables } from './types/Institutes';
import { INSTITUTES } from './';

export function useInstitutesQuery(
  options?: QueryHookOptions<Institutes, InstitutesVariables>,
): QueryResult<Institutes, InstitutesVariables> {
  return useQuery<Institutes, InstitutesVariables>(INSTITUTES, options);
}

export function useInstitutesLazyQuery(
  options?: LazyQueryHookOptions<Institutes, InstitutesVariables>,
): QueryTuple<Institutes, InstitutesVariables> {
  return useLazyQuery<Institutes, InstitutesVariables>(INSTITUTES, options);
}

export function createInstitutesMock({
  variables,
  data,
  error,
}: {
  variables?: InstitutesVariables;
  data?: Institutes;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: INSTITUTES,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
