import React from 'react';
import { IconName } from '../Icon';
import { IconStyledName } from '../IconStyled';
import {
  Container,
  IconContainer,
  OptionIcon,
  Text,
  OptionIconStyled,
} from './style';

type CardOptionCellProps = {
  testID?: string;
  iconStyledName?: IconStyledName;
  iconName?: IconName;
  iconSize?: number;
  text: string;
  isSelected: boolean;
  onPress: () => void;
};

export default function CardOptionCell({
  testID,
  iconStyledName,
  iconName,
  iconSize = 32,
  text,
  isSelected,
  onPress,
}: CardOptionCellProps): JSX.Element {
  return (
    <Container testID={testID} selected={isSelected} onPress={onPress}>
      <IconContainer size={50} selected={isSelected}>
        {iconStyledName ? (
          <OptionIconStyled
            name={iconStyledName}
            selected={isSelected}
            size={iconSize}
          />
        ) : iconName ? (
          <OptionIcon name={iconName} selected={isSelected} size={iconSize} />
        ) : null}
      </IconContainer>
      <Text selected={isSelected}>{text}</Text>
    </Container>
  );
}
