import React, { useState } from 'react';
import { AppLocale } from '~/data/models/app';
import { t } from '~/utils/i18n';
import { Container, Header, TypeTitle, TypeValue, LangPicker } from './style';

type LanguageInfo = {
  locale: AppLocale;
  name: string;
};

type LanguagePickerProps = {
  testID?: string;
  toggleTestID?: string;
  label: string;
  selectedLocale: AppLocale;
  initialShowPicker?: boolean;
  includeAuto?: boolean;
  onSelectedLocale: (locale: AppLocale) => void;
};

export default function LanguagePicker({
  testID,
  toggleTestID,
  label,
  selectedLocale,
  initialShowPicker = false,
  includeAuto,
  onSelectedLocale,
}: LanguagePickerProps): JSX.Element {
  const [showPicker, setShowPicker] = useState<boolean>(initialShowPicker);

  const auto: LanguageInfo = {
    locale: 'auto',
    name: t('g.auto'),
  };

  const defaultLanguageInfos: LanguageInfo[] = [
    {
      locale: 'en',
      name: t('language.english'),
    },
    {
      locale: 'de',
      name: t('language.german'),
    },
    {
      locale: 'tr',
      name: t('language.turkish'),
    },
  ];

  const languageInfos = includeAuto
    ? [auto, ...defaultLanguageInfos]
    : defaultLanguageInfos;

  const selectedLocaleInfo = languageInfos.find(
    (languageInfo) => languageInfo.locale === selectedLocale,
  );

  return (
    <Container testID={testID}>
      <Header testID={toggleTestID} onPress={() => setShowPicker(!showPicker)}>
        <TypeTitle>{label}</TypeTitle>
        <TypeValue>{selectedLocaleInfo?.name}</TypeValue>
      </Header>
      {showPicker && (
        <LangPicker<React.ElementType>
          selectedValue={selectedLocaleInfo?.locale}
          onValueChange={(locale: string) =>
            onSelectedLocale(locale as AppLocale)
          }
        >
          {languageInfos.map((languageInfo) => (
            <LangPicker.Item
              key={languageInfo.locale}
              label={languageInfo.name}
              value={languageInfo.locale}
            />
          ))}
        </LangPicker>
      )}
    </Container>
  );
}
