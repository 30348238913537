import React from 'react';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { DEFAULT_RANKINGS_LIST_OFFSET } from '~/data/constants';
import useTeams from '~/data/hooks/useTeams';
import { RankingQuestion } from '~/data/models/yearbook';
import { useDestroyRankingsQuestionMutation } from '~/data/operations/yearbook/destroyRankingsQuestion';
import {
  readRankingsQuestionsQuery,
  writeRankingsQuestionsQuery,
} from '~/data/operations/yearbook/helpers';
import { useModuleInstanceQuery } from '~/data/operations/yearbook/moduleInstance';
import { useRankingsQuestionsQuery } from '~/data/operations/yearbook/rankingsQuestions';
import { useUpdateModuleInstanceMutation } from '~/data/operations/yearbook/updateModuleInstance';
import { useUpdateRankingsSetupMutation } from '~/data/operations/yearbook/updateRankingsSetup';
import { YearbookStackParamList } from '~/navigation/types';
import { t } from '~/utils/i18n';
import {
  ModuleSetupLayoutLoading,
  UpdateModuleInstanceInput,
} from '../layout/ModuleSetupLayout';
import RankingsSetupLayout, { UpdateRankingSetupInput } from './layout';

type RankingsSetupNavProp = StackNavigationProp<
  YearbookStackParamList,
  'RankingsSetup'
>;
type RankingsSetupRouteProp = RouteProp<
  YearbookStackParamList,
  'RankingsSetup'
>;

export default function RankingsSetup(): JSX.Element {
  const navigation = useNavigation<RankingsSetupNavProp>();
  const {
    params: { moduleInstanceId },
  } = useRoute<RankingsSetupRouteProp>();

  const {
    teams,
    loading: teamsLoading,
    onLoadMore: onLoadMoreTeams,
  } = useTeams();

  const { authUserGroupId } = useAuth();

  const { data: moduleInstanceData, loading: moduleInstanceLoading } =
    useModuleInstanceQuery({
      variables: {
        id: moduleInstanceId,
      },
    });

  const rankingQuestionsVar = {
    after: null,
    first: DEFAULT_RANKINGS_LIST_OFFSET,
    moduleInstance: moduleInstanceId,
    authUserGroupId,
  };

  const {
    data: rankingQuestionsData,
    loading: rankingQuestionsLoading,
    fetchMore: fetchRankingsQuestions,
  } = useRankingsQuestionsQuery({
    variables: rankingQuestionsVar,
    notifyOnNetworkStatusChange: true,
  });

  const rankingQuestions: RankingQuestion[] =
    (rankingQuestionsData?.rankingsQuestions?.edges.map(
      (edge) => edge?.node,
    ) as RankingQuestion[]) || [];
  const hasNextPage =
    rankingQuestionsData?.rankingsQuestions?.pageInfo.hasNextPage;
  const endCursor = rankingQuestionsData?.rankingsQuestions?.pageInfo.endCursor;

  const moduleInstance = moduleInstanceData?.moduleInstance;

  const rankingSetupConfig = moduleInstanceData?.moduleInstance?.rankingsSetup;

  const [updateModuleInstance] = useUpdateModuleInstanceMutation();
  const [updateRankingsSetup] = useUpdateRankingsSetupMutation();
  const [destroyRankingsQuestion] = useDestroyRankingsQuestionMutation();

  const onUpdateModuleInstance = async ({
    isActive,
    dueDate,
  }: UpdateModuleInstanceInput) => {
    try {
      await updateModuleInstance({
        variables: {
          input: {
            id: moduleInstanceId,
            isActive,
            dueDate,
          },
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onUpdateRankingSetup = async ({
    resultsTeamId,
  }: UpdateRankingSetupInput) => {
    try {
      if (moduleInstance && rankingSetupConfig) {
        await updateRankingsSetup({
          variables: {
            input: {
              id: rankingSetupConfig.id,
              isActive: moduleInstance.isActive,
              dueDate: moduleInstance.dueDate,
              resultsTeam: resultsTeamId,
            },
          },
        });
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onDeleteQuestion = async (rankingQuestionId: string) => {
    try {
      await destroyRankingsQuestion({
        variables: {
          input: {
            id: rankingQuestionId,
          },
        },
        update(cache) {
          const currentRankingsQuestionsQuery = readRankingsQuestionsQuery({
            cache,
            variables: rankingQuestionsVar,
          });

          if (
            currentRankingsQuestionsQuery &&
            currentRankingsQuestionsQuery.rankingsQuestions &&
            currentRankingsQuestionsQuery.rankingsQuestions.edges
          ) {
            const filteredEdges =
              currentRankingsQuestionsQuery.rankingsQuestions.edges.filter(
                (edge) => edge?.node?.id !== rankingQuestionId,
              );
            writeRankingsQuestionsQuery({
              cache,
              variables: rankingQuestionsVar,
              data: {
                ...currentRankingsQuestionsQuery,
                rankingsQuestions: {
                  ...currentRankingsQuestionsQuery.rankingsQuestions,
                  edges: filteredEdges,
                },
              },
            });
          }
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  if (
    !moduleInstance ||
    !rankingSetupConfig ||
    moduleInstanceLoading ||
    teamsLoading
  ) {
    return (
      <ModuleSetupLayoutLoading
        title={t('screens.rankingsSetup')}
        onBack={() => navigation.goBack()}
      />
    );
  }

  return (
    <RankingsSetupLayout
      moduleInstance={moduleInstance}
      rankingSetupConfig={rankingSetupConfig}
      rankingQuestions={rankingQuestions}
      rankingQuestionsLoading={rankingQuestionsLoading}
      teams={teams}
      onBack={() => navigation.goBack()}
      onUpdateModuleInstance={onUpdateModuleInstance}
      onUpdateRankingSetup={onUpdateRankingSetup}
      onAddQuestion={() =>
        navigation.navigate('RankingsAddQuestion', { moduleInstanceId })
      }
      onUpdateQuestion={(rankingQuestionId) =>
        navigation.navigate('RankingsAddQuestion', {
          moduleInstanceId,
          rankingQuestionId,
        })
      }
      onDeleteQuestion={onDeleteQuestion}
      onLoadMoreQuestions={() => {
        hasNextPage &&
          fetchRankingsQuestions({
            variables: {
              ...rankingQuestionsVar,
              after: endCursor,
            },
          });
      }}
      onLoadMoreTeams={onLoadMoreTeams}
    />
  );
}
