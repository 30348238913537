import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { theme } from 'gradoo-theme';
import { Dimensions, Platform } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { Host } from 'react-native-portalize';
import {
  SafeAreaProvider,
  SafeAreaInsetsContext,
} from 'react-native-safe-area-context';
import * as Sentry from 'sentry-expo';
import { ThemeProvider } from 'styled-components/native';
import { AuthProvider } from './context/auth';
import { IntercomProvider } from './context/intercom';
import client from './data/client';
import RootNavigation, { routingInstrumentation } from './navigation';
const { width, height } = Dimensions.get('window');

const isMobile = Platform.OS !== 'web';

Sentry.init({
  dsn: 'https://fc0a999c7d534550a0b53a0c06fb8494@o697731.ingest.sentry.io/5776572',
  enableInExpoDevelopment: false,
  tracesSampleRate: 0.4,
  integrations:
    isMobile && routingInstrumentation
      ? [
          new Sentry.Native.ReactNativeTracing({
            tracingOrigins: ['localhost', 'gradoo.app', /^\//],
            routingInstrumentation,
          }),
        ]
      : [],
});

function App(): JSX.Element {
  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <ApolloProvider client={client}>
        <SafeAreaProvider>
          <SafeAreaInsetsContext.Consumer>
            {(insets) => (
              <ThemeProvider
                theme={{
                  ...theme,
                  safeAreaInsets: insets || theme.safeAreaInsets,
                  dimensions: {
                    screenHeight: height,
                    screenWidth: width,
                  },
                }}
              >
                <IntercomProvider>
                  <AuthProvider>
                    <Host>
                      <RootNavigation />
                    </Host>
                  </AuthProvider>
                </IntercomProvider>
              </ThemeProvider>
            )}
          </SafeAreaInsetsContext.Consumer>
        </SafeAreaProvider>
      </ApolloProvider>
    </GestureHandlerRootView>
  );
}

export default isMobile ? Sentry.Native.wrap(App) : App;
