import React from 'react';
import {
  useNavigation,
  useRoute,
  RouteProp,
  CompositeNavigationProp,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useAuth } from '~/context/auth';
import { useIntercom } from '~/context/intercom';
import { DEFAULT_RANKINGS_LIST_OFFSET } from '~/data/constants';
import useAuthTeams from '~/data/hooks/useAuthTeams';
import useTeamSamples from '~/data/hooks/useTeamSamples';
import { RankingQuestion } from '~/data/models/yearbook';
import { useModuleInstanceQuery } from '~/data/operations/yearbook/moduleInstance';
import { useRankingsQuestionsQuery } from '~/data/operations/yearbook/rankingsQuestions';
import { RootStackParamList, YearbookStackParamList } from '~/navigation/types';
import { isDateExpired } from '~/utils/dates';
import RankingsLayout from './layout';

type RankingsNavProp = CompositeNavigationProp<
  StackNavigationProp<YearbookStackParamList, 'Rankings'>,
  StackNavigationProp<RootStackParamList>
>;
type RankingsRouteProp = RouteProp<YearbookStackParamList, 'Rankings'>;

export default function Rankings(): JSX.Element {
  const navigation = useNavigation<RankingsNavProp>();
  const { onHelpCenter } = useIntercom();
  const {
    params: { moduleInstanceId, viewOnly },
  } = useRoute<RankingsRouteProp>();

  const {
    authGroupId,
    authUserGroupId,
    yearbookAdmin,
    authUserGroup,
    authUserId,
  } = useAuth();
  const { teamSamples } = useTeamSamples();
  const yearbookTeam = teamSamples.filter((t) => t.type === 'YEARBOOK');

  const { authTeamIds } = useAuthTeams();

  const { data: moduleInstanceData } = useModuleInstanceQuery({
    variables: {
      id: moduleInstanceId,
    },
  });

  const rankingsSetup = moduleInstanceData?.moduleInstance?.rankingsSetup;

  const resultsTeam = rankingsSetup?.resultsTeam;

  const showResults =
    resultsTeam === null || authTeamIds.includes(resultsTeam?.id as string);

  const isActive = moduleInstanceData?.moduleInstance?.isActive;
  const dueDate = moduleInstanceData?.moduleInstance?.dueDate;
  const isDeadlinePassed = dueDate && isDateExpired(dueDate);

  const rankingQuestionsVar = {
    after: null,
    first: DEFAULT_RANKINGS_LIST_OFFSET,
    moduleInstance: moduleInstanceId,
    authUserGroupId,
  };

  const {
    data: rankingQuestionsData,
    loading: rankingQuestionsLoading,
    refetch: refetchRankingsQuestions,
    fetchMore: fetchRankingsQuestions,
  } = useRankingsQuestionsQuery({
    variables: rankingQuestionsVar,
    notifyOnNetworkStatusChange: true,
  });

  const rankingQuestions: RankingQuestion[] =
    (rankingQuestionsData?.rankingsQuestions?.edges.map(
      (edge) => edge?.node,
    ) as RankingQuestion[]) || [];
  const hasNextPage =
    rankingQuestionsData?.rankingsQuestions?.pageInfo.hasNextPage;
  const endCursor = rankingQuestionsData?.rankingsQuestions?.pageInfo.endCursor;

  const openBannersInBrowser = (url: string) => {
    navigation.navigate('Browser', {
      title: 'Banner',
      url,
    });
  };

  return (
    <RankingsLayout
      authUserGender={authUserGroup?.user.gender}
      rankingQuestions={rankingQuestions}
      isActive={isActive}
      isDeadlinePassed={isDeadlinePassed}
      yearbookAdmin={yearbookAdmin}
      showResults={showResults}
      viewOnly={viewOnly}
      loading={rankingQuestionsLoading}
      hasNextPage={hasNextPage}
      onSetup={() => navigation.navigate('RankingsSetup', { moduleInstanceId })}
      onHelp={onHelpCenter}
      onRequestYearbookTeam={() => {
        navigation.popToTop();
        navigation.navigate('MainTab', {
          screen: 'PlanningStack',
          gid: authGroupId,
          params: {
            screen: 'TeamDetail',
            params: {
              teamId: yearbookTeam[0]?.id,
            },
          },
        });
      }}
      onBack={() => navigation.goBack()}
      openBannersInBrowser={openBannersInBrowser}
      onSelectQuestion={(rankingQuestionId) =>
        navigation.navigate('RankingVote', { rankingQuestionId })
      }
      onRefresh={() => {
        refetchRankingsQuestions();
      }}
      onLoadMore={() => {
        fetchRankingsQuestions({
          variables: {
            ...rankingQuestionsVar,
            after: endCursor,
          },
        });
      }}
      authUserId={authUserId}
    />
  );
}
