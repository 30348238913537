import { Dimensions } from 'react-native';
import styled from 'styled-components/native';
const { width } = Dimensions.get('window');

export const CONTAINER_WIDTH = width;
export const ICON_CARD_WIDTH = 80;

export const Container = styled.View`
  padding-vertical: 24px;
`;
