import React from 'react';
import { TouchableOpacity } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components/native';
import {
  PaginatorContainer,
  PaginatorBox,
  LeftPaginatorBox,
  RightPaginatorBox,
  ArrowIcon,
  PageText,
} from './style';

type PaginatorProps = {
  currentPage: number;
  allowPrev: boolean;
  allowNext: boolean;
  onNextClick: () => void;
  onPrevClick: () => void;
  onPageClick: (pageNumber: number) => void;
  numberOfPages: number;
};

const SHOW_EXPANDED_PAGINATOR_THRESHOLD = 4;

export default function Paginator({
  currentPage,
  allowPrev,
  allowNext,
  onNextClick,
  onPrevClick,
  numberOfPages,
  onPageClick,
}: PaginatorProps): JSX.Element {
  const theme = useTheme();
  const isDesktop = useMediaQuery({ minWidth: theme.breakpoints.desktopMin });

  const expandedPaginator =
    isDesktop && numberOfPages >= SHOW_EXPANDED_PAGINATOR_THRESHOLD;

  const renderExpandedPaginator = () => {
    const isAtStartPages = currentPage <= 2;
    const isAtLastPages = currentPage >= numberOfPages - 1;
    const nextPage = currentPage + 1;
    const previousPage = currentPage - 1;
    const isOdd = !!(currentPage % 2);

    if (isAtStartPages) {
      const isFirstPage = currentPage === 1;
      return (
        <>
          <PaginatorBox as={TouchableOpacity} onPress={() => onPageClick(1)}>
            <PageText isActive={isFirstPage}>1</PageText>
          </PaginatorBox>
          <PaginatorBox
            as={TouchableOpacity}
            onPress={() => onPageClick(currentPage + 1)}
          >
            <PageText isActive={!isFirstPage}>
              {!isFirstPage ? currentPage : currentPage + 1}
            </PageText>
          </PaginatorBox>
          <PaginatorBox>
            <PageText>{'...'}</PageText>
          </PaginatorBox>
          <PaginatorBox
            as={TouchableOpacity}
            onPress={() => onPageClick(numberOfPages)}
          >
            <PageText>{numberOfPages}</PageText>
          </PaginatorBox>
        </>
      );
    }

    if (isAtLastPages) {
      const isLastPage = currentPage === numberOfPages;
      return (
        <>
          <PaginatorBox as={TouchableOpacity} onPress={() => onPageClick(1)}>
            <PageText>1</PageText>
          </PaginatorBox>
          <PaginatorBox>
            <PageText>{'...'}</PageText>
          </PaginatorBox>
          <PaginatorBox
            as={TouchableOpacity}
            onPress={() => onPageClick(currentPage - 1)}
          >
            <PageText isActive={!isLastPage}>
              {!isLastPage ? currentPage : currentPage - 1}
            </PageText>
          </PaginatorBox>
          <PaginatorBox
            as={TouchableOpacity}
            onPress={() => onPageClick(numberOfPages)}
          >
            <PageText isActive={isLastPage}>{numberOfPages}</PageText>
          </PaginatorBox>
        </>
      );
    }

    return (
      <>
        <PaginatorBox>
          <PageText as={TouchableOpacity} onPress={() => onPageClick(1)}>
            1
          </PageText>
        </PaginatorBox>
        <PaginatorBox>
          <PageText>{'...'}</PageText>
        </PaginatorBox>
        {isOdd ? (
          <>
            <PaginatorBox>
              <PageText isActive>{currentPage}</PageText>
            </PaginatorBox>
            <PaginatorBox
              as={TouchableOpacity}
              onPress={() => onPageClick(nextPage)}
            >
              <PageText>{nextPage}</PageText>
            </PaginatorBox>
          </>
        ) : (
          <>
            <PaginatorBox
              as={TouchableOpacity}
              onPress={() => onPageClick(previousPage)}
            >
              <PageText>{previousPage}</PageText>
            </PaginatorBox>
            <PaginatorBox>
              <PageText isActive>{currentPage}</PageText>
            </PaginatorBox>
          </>
        )}

        <PaginatorBox>
          <PageText>{'...'}</PageText>
        </PaginatorBox>
        <PaginatorBox
          as={TouchableOpacity}
          onPress={() => onPageClick(numberOfPages)}
        >
          <PageText>{numberOfPages}</PageText>
        </PaginatorBox>
      </>
    );
  };

  return (
    <PaginatorContainer>
      <LeftPaginatorBox
        disabled={!allowPrev}
        as={TouchableOpacity}
        onPress={onPrevClick}
      >
        <ArrowIcon name="chevron-left" enabled={allowPrev} />
      </LeftPaginatorBox>
      {expandedPaginator ? (
        renderExpandedPaginator()
      ) : (
        <PaginatorBox>
          <PageText isActive>{currentPage}</PageText>
        </PaginatorBox>
      )}

      <RightPaginatorBox
        disabled={!allowNext}
        as={TouchableOpacity}
        onPress={onNextClick}
      >
        <ArrowIcon name="chevron-right" enabled={allowNext} />
      </RightPaginatorBox>
    </PaginatorContainer>
  );
}
