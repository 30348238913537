import { useEffect, useRef, useState } from 'react';
import { decode } from 'base-64';
import { SEARCH_TAGS } from '~/data/constants';
import useSearchOptions from '~/data/hooks/useSearchOptions';
import {
  SEARCH_TAG_TYPES,
  SearchShortcutAction,
  SearchTag,
  SearchTagOptions,
  SearchTags,
  SearchTagValue,
  SelectSearchTag,
} from '~/utils/types/adminSearch';

enum ObjectTypesEnum {
  Group = 'GroupNode',
  User = 'UserNode',
}

const SearchTagObjectIndexMap: Record<ObjectTypesEnum, number[]> = {
  [ObjectTypesEnum.Group]: [1, 1],
  [ObjectTypesEnum.User]: [0, 2],
};

export const useAdminSearch = (
  setSearchString: (value: string) => void,
  setDropdownData: (items: SearchTags[] | SearchTagOptions[]) => void,
) => {
  const [limit, setLimit] = useState<SearchShortcutAction | undefined>(
    undefined,
  );
  const [showSearchDropdown, setSearchDropdown] = useState<boolean>(false);

  const dropdownRef = useRef(null);
  const tags = useRef<SearchTagValue[]>([]);
  const stringTags = useRef<string[]>([]);
  const value = useRef<string>('');

  const searchTagOptions: SearchTagOptions[] = SEARCH_TAGS.flatMap((section) =>
    section.tags
      .filter((tag) => tag.type === SEARCH_TAG_TYPES.SELECT)
      .map((tag) => {
        const { options } = useSearchOptions(
          (tag as SelectSearchTag).getOptions,
        );
        return {
          tag: tag.tag,
          description: tag.description,
          options: options,
        };
      }),
  );

  useEffect(() => {
    updateSearchString({
      stringTags: stringTags.current,
      inputText: value.current,
    });
  }, [limit, stringTags, value]);

  const handleDropdownItemPress = (
    item: SearchTag,
    searchString: string,
    itemValue?: string,
  ) => {
    let updatedStringTags: string[] = [];

    if (item.type === SEARCH_TAG_TYPES.SELECT) {
      const dropdownItems = searchTagOptions.filter(
        (option) => option.tag === item.tag,
      );

      updateDropdownItems(dropdownItems);
    } else {
      setSearchDropdown(false);
      setDropdownData(SEARCH_TAGS);
    }

    const newTagValue = itemValue ?? '';
    const newTag = {
      tag: item.description + ':',
      value: newTagValue,
      error: false,
    };
    const newStringTag = `${item.tag}:${newTagValue}`;
    const tagIndex = tags.current.findIndex((searchTagValue) =>
      searchTagValue.tag.startsWith(item.description),
    );
    const stringTagIndex = stringTags.current.findIndex((tag) =>
      tag.startsWith(item.tag),
    );

    if (tagIndex >= 0) {
      tags.current = tags.current.map((tag, index) =>
        index === tagIndex ? newTag : tag,
      );
    } else {
      tags.current = [...tags.current, newTag];
    }

    if (stringTagIndex >= 0) {
      updatedStringTags = stringTags.current.map((tag, index) =>
        index === stringTagIndex ? newStringTag : tag,
      );
    } else {
      updatedStringTags = [...stringTags.current, newStringTag];
    }

    stringTags.current = updatedStringTags;
    value.current = '';

    updateSearchString({
      stringTags: updatedStringTags,
      inputText: searchString,
    });
  };

  const handleDropdownOptionPress = (item: {
    tag: string;
    description: string;
    options: SearchTagOptions;
  }) => {
    onTagChange(item.description, item.options.description);
    setSearchDropdown(false);

    setDropdownDefaultItems();
  };

  const updateDropdownItems = (items: SearchTags[] | SearchTagOptions[]) => {
    setDropdownData(items);
  };

  const setDropdownDefaultItems = () => {
    updateDropdownItems(SEARCH_TAGS);
  };

  const updateSearchString = ({
    stringTags,
    inputText,
  }: {
    stringTags: string[];
    inputText: string;
  }) => {
    const searchStringTags: string = stringTags.join(', ');
    const searchStringLimit: string = limit ? ', ' + limit.param : '';
    const searchString =
      `${searchStringTags} ${inputText}${searchStringLimit}`.trim();

    if (
      searchString.endsWith('¨, s: u') ||
      searchString.endsWith('ˆ, s: i') ||
      searchString.endsWith('¯, s: i') ||
      searchString.endsWith('˘, s: u')
    ) {
      value.current = '';
    }

    setSearchString(searchString);
  };

  const handleDeleteTag = (tagToDelete: string) => {
    // Remove tagToDelete from tags
    tags.current = tags.current.filter((tag) => tag.tag !== tagToDelete);

    // Remove string tags that match tagToDelete
    stringTags.current = stringTags.current.filter((tag) => {
      const [tagName] = tag.split(':');

      // Find the searchTag that matches tagToDelete
      const searchTag = SEARCH_TAGS.flatMap((st) => st.tags).find(
        (t) => t.description === tagToDelete.replace(':', ''),
      );

      // Exclude string tags where the searchTag matches tagToDelete
      return !(searchTag && tagName === searchTag.tag);
    });

    // Remove tag shortcut if it matches tagToDelete
    setLimit((prevLimit) => {
      if (prevLimit && prevLimit.label === tagToDelete) {
        return undefined;
      }
      return prevLimit;
    });

    updateSearchString({
      stringTags: stringTags.current,
      inputText: value.current,
    });
  };

  const onTagChange = (changedTag: string, text: string) => {
    let updatedStringTags: string[] = [];
    const matchingTag = tags.current.find((searchTag) =>
      searchTag.tag.startsWith(changedTag),
    );

    if (matchingTag?.value === '' && text === '') {
      tags.current = tags.current.filter(
        (searchTag) => !searchTag.tag.startsWith(changedTag),
      );

      const searchTag = SEARCH_TAGS.flatMap((st) => st.tags).find(
        (t) => t.description === changedTag.replace(':', ''),
      );
      updatedStringTags = stringTags.current.filter(
        (tag) => tag.replace(':', '') !== searchTag?.tag,
      );
    } else {
      tags.current.forEach((tag) => {
        if (tag.tag === changedTag) {
          tag.value = text;
        }
      });

      updatedStringTags = stringTags.current.map((tag) => {
        const [tagName] = tag.split(':');
        const searchTag = SEARCH_TAGS.flatMap((st) => st.tags).find(
          (t) => t.description === changedTag.replace(':', ''),
        );

        filterOptions(tagName, text);

        if (searchTag && tagName === searchTag.tag) {
          return `${tagName}:${text}`;
        } else {
          return tag;
        }
      });
    }

    tags.current = validateTags(tags.current);
    stringTags.current = updatedStringTags;
    updateSearchString({
      stringTags: updatedStringTags,
      inputText: value.current,
    });
  };

  const filterOptions = (tag: string, value: string) => {
    const tagOptions = searchTagOptions?.filter((option) => option.tag === tag);

    if (tagOptions && tagOptions[0]) {
      const filteredOptions = tagOptions[0].options?.filter((option) =>
        option.description?.toLowerCase().includes(value.toLowerCase()),
      );

      updateDropdownItems([
        {
          tag: tagOptions[0].tag,
          description: tagOptions[0].description,
          options: filteredOptions,
        },
      ]);

      setSearchDropdown(true);
    }
  };

  const filterDropdownData = (value: string) => {
    return SEARCH_TAGS.map((section) => ({
      key: section.key,
      description: section.description,
      tags: section.tags.filter((tag) =>
        tag.description.toLowerCase().includes(value.toLowerCase()),
      ),
    }));
  };

  const onTextChange = (
    text: string,
    setRenderCounter: React.Dispatch<React.SetStateAction<number>>,
    renderCounter: number,
  ) => {
    value.current = text;
    setDropdownData(filterDropdownData(text));
    tags.current = validateTags(tags.current);
    updateSearchString({ stringTags: stringTags.current, inputText: text });
    if (text.includes(':')) {
      onCheckUserInputTag(text, setRenderCounter, renderCounter);
    }
  };

  const onCheckUserInputTag = (
    text: string,
    setRenderCounter: React.Dispatch<React.SetStateAction<number>>,
    renderCounter: number,
  ) => {
    const colonIndex = text.indexOf(':');
    const wordBeforeColon = text.substring(0, colonIndex).trim();
    const trimmedWordBeforeColon = wordBeforeColon.trim();
    const matchedTag = trimmedWordBeforeColon
      ? SEARCH_TAGS.map((tagCategory) =>
          tagCategory.tags.find(
            (tag) =>
              tag.tag
                .toLowerCase()
                .includes(trimmedWordBeforeColon.toLowerCase()) ||
              tag.description
                .toLowerCase()
                .includes(trimmedWordBeforeColon.toLowerCase()),
          ),
        ).filter(Boolean)[0]
      : undefined;

    if (matchedTag) {
      value.current = '';

      handleDropdownItemPress(matchedTag, text);
    }
    setRenderCounter(renderCounter + 1);
  };

  const onRecognisedTag = (text: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+\d+$/;
    const internalIdRegex = /^C\d+$/;
    const UserAndGroupIDregex =
      /^[A-Za-z0-9+/]+([A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;

    let recognisedTag: SearchTag | undefined = undefined;
    let recognisedTagName = '';

    if (emailRegex.test(text)) {
      recognisedTagName = 'email';
    } else if (phoneRegex.test(text)) {
      recognisedTagName = 'phone';
    } else if (internalIdRegex.test(text)) {
      recognisedTagName = 'cid';
    } else if (UserAndGroupIDregex.test(text)) {
      const decodedString = decode(text);
      const [objectType] = decodedString.split(':');
      const [searchTagIndex, tagIndex] =
        SearchTagObjectIndexMap[objectType as ObjectTypesEnum];
      const tag = SEARCH_TAGS[searchTagIndex]?.tags[tagIndex]?.tag;
      recognisedTagName = tag;
    }

    if (recognisedTagName) {
      SEARCH_TAGS.map((tagCategory) =>
        tagCategory.tags.find((tag) => {
          if (tag.tag === recognisedTagName) {
            recognisedTag = tag;
          }
        }),
      );

      if (recognisedTag) {
        value.current = '';
        handleDropdownItemPress(recognisedTag, '', text);
      }
    }
  };

  const validateTags = (tags: SearchTagValue[]) => {
    const updatedTags = tags.map((tag: SearchTagValue) => {
      if (tag.value === '') {
        return { ...tag, error: true };
      }
      return { ...tag, error: false };
    });
    return updatedTags;
  };
  return {
    value,
    tags,
    limit,
    setLimit,
    showSearchDropdown,
    setSearchDropdown,
    dropdownRef,
    stringTags,
    handleDropdownItemPress,
    handleDropdownOptionPress,
    updateSearchString,
    onTagChange,
    onTextChange,
    setDropdownDefaultItems,
    validateTags,
    onRecognisedTag,
    handleDeleteTag,
  };
};
