/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query MarketProfiles
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { MarketProfiles } from './types/MarketProfiles';
import { MARKET_PROFILES } from './';

export function useMarketProfilesQuery(
  options?: QueryHookOptions<MarketProfiles, {}>,
): QueryResult<MarketProfiles, {}> {
  return useQuery<MarketProfiles, {}>(MARKET_PROFILES, options);
}

export function useMarketProfilesLazyQuery(
  options?: LazyQueryHookOptions<MarketProfiles, {}>,
): QueryTuple<MarketProfiles, {}> {
  return useLazyQuery<MarketProfiles, {}>(MARKET_PROFILES, options);
}

export function createMarketProfilesMock({
  variables,
  data,
  error,
}: {
  variables?: undefined;
  data?: MarketProfiles;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: MARKET_PROFILES,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
