/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation AddImagesToPost
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  AddImagesToPost,
  AddImagesToPostVariables,
} from './types/AddImagesToPost';
import { ADD_IMAGES_TO_POST } from './';

export function useAddImagesToPostMutation(
  options?: MutationHookOptions<AddImagesToPost, AddImagesToPostVariables>,
): MutationTuple<AddImagesToPost, AddImagesToPostVariables> {
  return useMutation<AddImagesToPost, AddImagesToPostVariables>(
    ADD_IMAGES_TO_POST,
    options,
  );
}

export function createAddImagesToPostMock({
  variables,
  data,
  error,
}: {
  variables?: AddImagesToPostVariables;
  data?: AddImagesToPost;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: ADD_IMAGES_TO_POST,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
