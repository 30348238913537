/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation CreateProfilePagePhoto
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  CreateProfilePagePhoto,
  CreateProfilePagePhotoVariables,
} from './types/CreateProfilePagePhoto';
import { CREATE_PROFILE_PAGE_PHOTO } from './';

export function useCreateProfilePagePhotoMutation(
  options?: MutationHookOptions<
    CreateProfilePagePhoto,
    CreateProfilePagePhotoVariables
  >,
): MutationTuple<CreateProfilePagePhoto, CreateProfilePagePhotoVariables> {
  return useMutation<CreateProfilePagePhoto, CreateProfilePagePhotoVariables>(
    CREATE_PROFILE_PAGE_PHOTO,
    options,
  );
}

export function createCreateProfilePagePhotoMock({
  variables,
  data,
  error,
}: {
  variables?: CreateProfilePagePhotoVariables;
  data?: CreateProfilePagePhoto;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: CREATE_PROFILE_PAGE_PHOTO,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
