import React, { useState } from 'react';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { CommonActions, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { DATABASE_ROWS_PER_PAGE_OPTIONS } from '~/data/constants';
import { Institute } from '~/data/models/institute';
import { InstituteRequest } from '~/data/models/instituteRequest';
import { Status } from '~/data/operations/global';
import { useInstitutesQuery } from '~/data/operations/institute/institutes';
import { useInstituteRequestsQuery } from '~/data/operations/instituteRequest/instituteRequests';
import {
  AdminStackParamList,
  InstituteStackParamList,
} from '~/navigation/types';
import InstitutesLayout from './layout';

type InstitutesNavProp = StackNavigationProp<
  InstituteStackParamList,
  'Institutes'
>;

type SearchNavProp = StackNavigationProp<AdminStackParamList, 'SearchStack'>;

export default function Institutes(): JSX.Element {
  const navigation = useNavigation<InstitutesNavProp & SearchNavProp>();
  const [currentActiveTab, setCurrentActiveTab] = useState<number>(1);
  const { openDrawer } =
    useNavigation<DrawerNavigationProp<AdminStackParamList>>();
  const [filterQuery, setFilterQuery] = useState<string>('');
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    DATABASE_ROWS_PER_PAGE_OPTIONS[0],
  );

  const { data: requestsData, loading: requestsLoading } =
    useInstituteRequestsQuery({
      variables: {
        status: Status.PENDING,
      },
    });

  const isQueryInternalId = /^C\d+$/.test(filterQuery);
  const offset = (currentActiveTab - 1) * rowsPerPage;

  const vars = {
    first: rowsPerPage,
    offset: offset,
    name: !isQueryInternalId ? filterQuery : '',
    internalId: isQueryInternalId ? filterQuery : '',
  };

  const { data: institutesData, loading: institutesLoading } =
    useInstitutesQuery({
      variables: vars,
    });

  const navigateToInstituteRequestScreen = (instituteId: string): void =>
    navigation.navigate('InstituteRequest', {
      id: instituteId,
    });

  const navigateToInstituteAddScreen = (): void =>
    navigation.navigate('InstituteAdd');

  const handleOpeningInstitutesGroups = (id: string) => {
    navigation.dispatch(
      CommonActions.navigate({ name: 'EditInstitute', params: { id } }),
    );
  };

  const instituteRequests =
    (requestsData?.instituteRequests?.edges.map(
      (edge) => edge?.node,
    ) as InstituteRequest[]) || [];

  const institutes =
    (institutesData?.institutes?.edges.map(
      (edge) => edge?.node,
    ) as Institute[]) || [];

  const totalInstitutes = institutesData?.institutes?.totalCount ?? 0;

  return (
    <InstitutesLayout
      instituteRequests={instituteRequests}
      requestsLoading={requestsLoading}
      institutesLoading={institutesLoading}
      institutes={institutes}
      onCheckInstituteRequestPress={navigateToInstituteRequestScreen}
      institutesTotalCount={totalInstitutes}
      setCurrentActiveTab={setCurrentActiveTab}
      onAddInstituteRequestPress={navigateToInstituteAddScreen}
      openDrawer={openDrawer}
      onOpenInstitutesGroup={handleOpeningInstitutesGroups}
      setFilterQuery={setFilterQuery}
      filterQuery={filterQuery}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
    />
  );
}
