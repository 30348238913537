import React from 'react';
import { ScrollView } from 'react-native';
import Button from '~/components/Button';
import { UserGroup } from '~/data/models/group';
import { Team } from '~/data/models/team';
import { t } from '~/utils/i18n';
import {
  Container,
  Header,
  InfoText,
  AuthorTouchableView,
  AuthorUser,
  AuthorTeam,
  AuthorName,
  PostContainer,
} from './style';

type ToolbarProps = {
  testID?: string;
  postButtonText?: string;
  authorTeamId?: string;
  userGroup?: UserGroup | null;
  teams: Team[];
  createEnabled: boolean;
  loading: boolean;
  onAuthorTeamChange: (teamId?: string) => void;
  onCreate: () => void;
};

export default function Toolbar({
  testID,
  postButtonText = t('createPost.createPost'),
  authorTeamId,
  userGroup,
  teams,
  createEnabled,
  loading,
  onAuthorTeamChange,
  onCreate,
}: ToolbarProps): JSX.Element {
  const isTeamNode = (item: UserGroup | Team): item is Team => {
    return item.__typename === 'TeamNode';
  };
  const renderItem = ({ item }: { item: UserGroup | Team }) => {
    if (isTeamNode(item)) {
      const team = item as Team;
      const authorId = team.id;
      const selected = authorTeamId === authorId;
      return (
        <AuthorTouchableView
          testID={`${testID}:AuthorTeam:${authorId}`}
          onPress={() => onAuthorTeamChange(authorId)}
        >
          <AuthorTeam
            teamColor={team?.color?.hex}
            iconName={team?.icon?.icon}
            selected={selected}
          />
          <AuthorName selected={selected}>{team.name}</AuthorName>
        </AuthorTouchableView>
      );
    } else {
      const userGroup = item as UserGroup;
      const selected = authorTeamId === undefined;
      return (
        <AuthorTouchableView
          testID={'AuthorUser'}
          onPress={() => onAuthorTeamChange(undefined)}
        >
          <AuthorUser userGroup={userGroup} selected={selected} />
          <AuthorName selected={selected}>
            {userGroup.user.firstName}
          </AuthorName>
        </AuthorTouchableView>
      );
    }
  };

  return (
    <Container>
      <ScrollView
        testID={testID}
        horizontal
        showsHorizontalScrollIndicator={false}
        keyboardShouldPersistTaps="always"
      >
        <Header>
          <InfoText>{t('createPost.postAs')}</InfoText>
        </Header>
        {(userGroup ? [userGroup, ...teams] : teams).map((item, index) => {
          return (
            <React.Fragment key={index}>{renderItem({ item })}</React.Fragment>
          );
        })}
      </ScrollView>
      <PostContainer>
        <Button
          testID={
            testID ? `${testID}:buttonCreatePost` : `Toolbar:buttonCreatePost`
          }
          state={createEnabled ? 'default' : 'disabled'}
          text={postButtonText}
          minWidth={118}
          loading={loading}
          onPress={onCreate}
        />
      </PostContainer>
    </Container>
  );
}
