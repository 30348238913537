import styled from 'styled-components/native';
import ButtonIcon from '../ButtonIcon';

type ResponsiveWidth = {
  width?: number;
  isVisible?: boolean;
};
export const Container = styled.View<ResponsiveWidth>`
  background-color: ${({ theme }) => theme.color.base.c0};
  border-radius: 8px;
  padding: 15px;
  max-width: ${({ width }) => width !== undefined && width}px;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
`;

export const Title = styled.Text`
  font-size: 14px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  margin-bottom: 10px;
  color: ${({ theme }) => theme.color.base.c9};
`;

export const Description = styled.Text`
  font-size: 12px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  margin-bottom: 15px;
  color: ${({ theme }) => theme.color.base.c6};
`;

export const CancelButton = styled.Text`
  font-size: 14px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c7};
  padding-right: 10px;
`;

export const AcceptButton = styled.Text`
  font-size: 14px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.brand_02};
`;

export const RowContainer = styled.View`
  flex-direction: row;
`;

export const HeaderContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

export const CloseModalButton = styled(ButtonIcon).attrs(({ theme }) => ({
  name: 'x-close',
  size: 20,
  color: theme.color.base.c9,
}))`
  background-color: transparent;
`;

export const ButtonWrapper = styled.TouchableOpacity``;
