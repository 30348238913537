import { useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Platform } from 'react-native';
import { RootStackParamList } from '~/navigation/types';
import { eventEmitter } from '~/utils/helpers/eventEmitter';

type NetworkErrorNavProps = StackNavigationProp<RootStackParamList>;

export default function NetworkError() {
  const navigation = useNavigation<NetworkErrorNavProps>();

  useEffect(() => {
    const handleNetworkError = () => {
      //ServerError is currently web only
      //crashing on mobile https://abihome-gmbh.sentry.io/issues/5091279511/?project=5776572&query=is%3Aunresolved&referrer=issue-stream&statsPeriod=7d&stream_index=2
      Platform.OS === 'web' && navigation.navigate('ServerError');
    };

    eventEmitter.on('networkError', handleNetworkError);

    return () => {
      eventEmitter.removeListener('networkError', handleNetworkError);
    };
  }, [navigation]);

  return null;
}
