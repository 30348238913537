import styled from 'styled-components/native';
import { byStateHelper } from './helpers';
import { ToggleState } from './types';

interface IContainer {
  state: ToggleState;
}
export const Container = styled.View<IContainer>`
  border-width: ${({ state }) => (state === 'focus' ? '2px' : '0px')};
  border-color: ${({ theme }) => theme.color.brand_02};
  border-radius: 20px;
  padding: 2px;
  margin: 2px;
`;

interface ISwitch {
  state: ToggleState;
  checked: boolean;
}
export const Switch = styled.Switch.attrs<ISwitch>(
  ({ state, checked, theme }) => ({
    ios_backgroundColor: byStateHelper.innactiveTrackColor(state, theme),
    activeThumbColor: byStateHelper.activeThumbColor(theme),
    thumbColor: checked
      ? byStateHelper.activeThumbColor(theme)
      : byStateHelper.innactiveThumbColor(theme),
    trackColor: {
      false: byStateHelper.innactiveTrackColor(state, theme),
      true: byStateHelper.activeTrackColor(state, theme),
    },
  }),
)<ISwitch>``;
