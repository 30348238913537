/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation UpdateRankingsQuestion
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  UpdateRankingsQuestion,
  UpdateRankingsQuestionVariables,
} from './types/UpdateRankingsQuestion';
import { UPDATE_RANKINGS_QUESTION } from './';

export function useUpdateRankingsQuestionMutation(
  options?: MutationHookOptions<
    UpdateRankingsQuestion,
    UpdateRankingsQuestionVariables
  >,
): MutationTuple<UpdateRankingsQuestion, UpdateRankingsQuestionVariables> {
  return useMutation<UpdateRankingsQuestion, UpdateRankingsQuestionVariables>(
    UPDATE_RANKINGS_QUESTION,
    options,
  );
}

export function createUpdateRankingsQuestionMock({
  variables,
  data,
  error,
}: {
  variables?: UpdateRankingsQuestionVariables;
  data?: UpdateRankingsQuestion;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: UPDATE_RANKINGS_QUESTION,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
