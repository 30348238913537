import { KeyboardAwareFlatList } from 'react-native-keyboard-aware-scroll-view';
import styled from 'styled-components/native';

export const FlatList = styled(KeyboardAwareFlatList)``;

export const PlaceholderContainer = styled.View`
  padding: 16px;
  border: 2px solid ${({ theme }) => theme.color.base.c6};
  border-radius: 18px;
  margin-bottom: 16px;
`;

export const PaddedCell = styled.View`
  padding-horizontal: 16px;
`;

export const TouchView = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})``;

export const Header = styled.View`
  margin-bottom: 24px;
  padding-horizontal: 16px;
`;

export const PhotosFlatList = styled.FlatList`
  padding-vertical: 16px;
  padding-left: 16px;
`;

export const TabBarContainer = styled.View`
  margin-vertical: 32px;
`;

export const TabSectionButtonContainer = styled.View`
  margin-bottom: 24px;
`;

export const RefreshControl = styled.RefreshControl.attrs(({ theme }) => ({
  color: theme.color.base.c4,
}))``;
