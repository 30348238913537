import React from 'react';
import {
  CompositeNavigationProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { NO_PAG_LIST_OFFSET } from '~/data/constants';
import { DATA_POLICY, TERM } from '~/data/derivedConstants';
import useAppLocale from '~/data/hooks/useAppLocale';
import { Country } from '~/data/models/marketProfile';
import { useCreateUserMutation } from '~/data/operations/auth/createUser';
import { UserType } from '~/data/operations/global';
import { GROUP_USERS } from '~/data/operations/group';
import { useAddUserToGroupMutation } from '~/data/operations/group/addUserToGroup';
import { useGenerateShopifyUrlMutation } from '~/data/operations/shop/generateShopifyUrl';
import { clearAuth } from '~/data/storage';
import { getLanguageFromLocale } from '~/data/utils';
import {
  RootStackParamList,
  LobbyStackParamList,
  AuthStackParamList,
} from '~/navigation/types';
import { redirectToShop } from '~/utils/helpers';
import SignUpTermLayout from './layout';

type SignUpTermNavProp = CompositeNavigationProp<
  StackNavigationProp<AuthStackParamList, 'SignUpTerm'>,
  CompositeNavigationProp<
    StackNavigationProp<RootStackParamList>,
    StackNavigationProp<LobbyStackParamList>
  >
>;
type SignUpTermRouteProp = RouteProp<AuthStackParamList, 'SignUpTerm'>;

export default function SignUpTerm(): JSX.Element {
  const navigation = useNavigation<SignUpTermNavProp>();
  const {
    params: { g: groupId, t: groupToken, r: inviteCode, shopReturnUrl },
  } = useRoute<SignUpTermRouteProp>();
  const hasInviteParams = groupId && groupToken;

  const { locale, onChangeLocale } = useAppLocale({
    onReload: () =>
      navigation.replace('SignUpTerm', {
        g: groupId,
        t: groupToken,
        r: inviteCode,
      }),
  });

  const {
    loading: authLoading,
    country,
    onUpdateCountry,
    onAuth,
    signUpData,
    onClearSignUpUser,
  } = useAuth();

  const [createUser, { loading: createUserLoading }] = useCreateUserMutation();

  const [addUserToGroup, { loading: addUserToGroupLoading }] =
    useAddUserToGroupMutation();

  const [generateShopifyUrl] = useGenerateShopifyUrlMutation();

  async function onCreateUser(
    dataPolicyAcceptance: boolean,
    termsAcceptance: boolean,
    subscribeNewsletter: boolean,
  ) {
    try {
      const communicationLanguage = getLanguageFromLocale();

      const { firstName, lastName, email, phoneCountryCode, phone, password } =
        signUpData;
      const { data: createUserData } = await createUser({
        variables: {
          input: {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneCountryCode: phoneCountryCode,
            phone: phone,
            password: password,
            passwordConfirmation: password,
            dataPolicyAcceptance,
            dataPolicyVersion: DATA_POLICY.version,
            termsAcceptance,
            termsVersion: TERM.version,
            subscribeNewsletter,
            communicationLanguage,
            inviteCode,
            countryOfResidence: country,
          },
        },
      });
      const authUser = createUserData?.createUser?.user;
      const userId = authUser?.id;

      if (!userId) {
        throw new Error('invalid data');
      }
      await onAuth(email, password);

      if (hasInviteParams) {
        handleInvite(userId, groupId, groupToken);
        return;
      }

      await onClearSignUpUser();

      if (shopReturnUrl) {
        const { data } = await generateShopifyUrl({
          variables: {
            input: {
              returnUrl: decodeURIComponent(shopReturnUrl),
            },
          },
        });

        if (data?.generateShopifyUrl?.redirectUrl) {
          redirectToShop(data?.generateShopifyUrl?.redirectUrl);
          return;
        }
      }

      navigation.navigate('SignUpSuccess');
    } catch (e) {
      clearAuth();
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  }

  async function handleInvite(
    userId: string,
    groupId: string,
    groupToken: string,
  ) {
    try {
      await addUserToGroup({
        variables: {
          input: { groupId, userId, role: UserType.STUDENT, token: groupToken },
        },
        refetchQueries: [
          {
            query: GROUP_USERS,
            variables: {
              user: userId,
              first: NO_PAG_LIST_OFFSET,
            },
          },
        ],
      });
      navigation.navigate('InviteSuccess', { groupId });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
      navigation.navigate('SignUpSuccess');
    }
  }

  return (
    <SignUpTermLayout
      inputProgress={4}
      loading={createUserLoading || authLoading || addUserToGroupLoading}
      selectedLocale={locale}
      onBack={() => navigation.goBack()}
      onShowPolicy={() =>
        navigation.navigate('Browser', {
          title: DATA_POLICY.title,
          url: DATA_POLICY.url,
        })
      }
      onShowTerm={() =>
        navigation.navigate('Browser', {
          title: TERM.title,
          url: TERM.url,
        })
      }
      onNext={(dataPolicyAcceptance, termsAcceptance, subscribeNewsletter) =>
        onCreateUser(dataPolicyAcceptance, termsAcceptance, subscribeNewsletter)
      }
      onSelectedLocale={onChangeLocale}
      onUpdateCountry={onUpdateCountry}
      country={country as Country}
    />
  );
}
