import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { DEFAULT_LIST_OFFSET } from '../constants';
import { Poll } from '../models/post';
import { useDeletePollMutation } from '../operations/post/deletePoll';
import { readPollsQuery, writePollsQuery } from '../operations/post/helpers';
import { usePollsQuery } from '../operations/post/polls';

type PollsHookProps = {
  polls: Poll[];
  loading: boolean;
  hasNextPage?: boolean;
  onRefresh: () => void;
  onLoadMore: () => void;
  onDeletePoll: (pollId: string) => void;
};

const usePolls = (): PollsHookProps => {
  const { authGroupId, authUserGroupId } = useAuth();

  const pollsVar = {
    after: null,
    first: DEFAULT_LIST_OFFSET,
    post_CreatedBy_Group: authGroupId,
    authUserGroupId,
  };
  const {
    data: pollsData,
    loading,
    fetchMore: fetchMorePolls,
    refetch: refetchPolls,
  } = usePollsQuery({
    skip: !authGroupId,
    variables: pollsVar,
    notifyOnNetworkStatusChange: true,
  });

  const [deletePoll] = useDeletePollMutation();

  const polls: Poll[] =
    (pollsData?.polls?.edges.map((edge) => edge?.node) as Poll[]) || [];
  const hasNextPage = pollsData?.polls?.pageInfo.hasNextPage;
  const endCursor = pollsData?.polls?.pageInfo.endCursor;

  const onRefresh = () => {
    refetchPolls();
  };

  const onLoadMore = () => {
    hasNextPage &&
      fetchMorePolls({
        variables: {
          ...pollsVar,
          after: endCursor,
        },
      });
  };

  const onDeletePoll = async (pollId: string) => {
    try {
      if (pollId) {
        await deletePoll({
          variables: {
            input: {
              id: pollId,
            },
            authUserGroupId,
          },
          update(cache) {
            const currentPolls = readPollsQuery({
              cache,
              variables: pollsVar,
            });

            if (
              currentPolls &&
              currentPolls.polls &&
              currentPolls.polls.edges
            ) {
              writePollsQuery({
                cache,
                variables: pollsVar,
                data: {
                  ...currentPolls,
                  polls: {
                    ...currentPolls.polls,
                    edges: currentPolls.polls.edges.filter(
                      (edge) => edge?.node?.id != pollId,
                    ),
                  },
                },
              });
            }
          },
        });
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  return {
    polls,
    loading,
    hasNextPage,
    onRefresh,
    onLoadMore,
    onDeletePoll,
  };
};

export default usePolls;
