/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation AdminDowngradeUser
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  AdminDowngradeUser,
  AdminDowngradeUserVariables,
} from './types/AdminDowngradeUser';
import { ADMIN_DOWNGRADE_USER } from './';

export function useAdminDowngradeUserMutation(
  options?: MutationHookOptions<
    AdminDowngradeUser,
    AdminDowngradeUserVariables
  >,
): MutationTuple<AdminDowngradeUser, AdminDowngradeUserVariables> {
  return useMutation<AdminDowngradeUser, AdminDowngradeUserVariables>(
    ADMIN_DOWNGRADE_USER,
    options,
  );
}

export function createAdminDowngradeUserMock({
  variables,
  data,
  error,
}: {
  variables?: AdminDowngradeUserVariables;
  data?: AdminDowngradeUser;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: ADMIN_DOWNGRADE_USER,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
