/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query UserGroup
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { UserGroup, UserGroupVariables } from './types/UserGroup';
import { USER_GROUP } from './';

export function useUserGroupQuery(
  options?: QueryHookOptions<UserGroup, UserGroupVariables>,
): QueryResult<UserGroup, UserGroupVariables> {
  return useQuery<UserGroup, UserGroupVariables>(USER_GROUP, options);
}

export function useUserGroupLazyQuery(
  options?: LazyQueryHookOptions<UserGroup, UserGroupVariables>,
): QueryTuple<UserGroup, UserGroupVariables> {
  return useLazyQuery<UserGroup, UserGroupVariables>(USER_GROUP, options);
}

export function createUserGroupMock({
  variables,
  data,
  error,
}: {
  variables?: UserGroupVariables;
  data?: UserGroup;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: USER_GROUP,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
