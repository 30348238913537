/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query AdminGetGradooStaff
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  AdminGetGradooStaff,
  AdminGetGradooStaffVariables,
} from './types/AdminGetGradooStaff';
import { ADMIN_GET_GRADOO_STAFF } from './';

export function useAdminGetGradooStaffQuery(
  options?: QueryHookOptions<AdminGetGradooStaff, AdminGetGradooStaffVariables>,
): QueryResult<AdminGetGradooStaff, AdminGetGradooStaffVariables> {
  return useQuery<AdminGetGradooStaff, AdminGetGradooStaffVariables>(
    ADMIN_GET_GRADOO_STAFF,
    options,
  );
}

export function useAdminGetGradooStaffLazyQuery(
  options?: LazyQueryHookOptions<
    AdminGetGradooStaff,
    AdminGetGradooStaffVariables
  >,
): QueryTuple<AdminGetGradooStaff, AdminGetGradooStaffVariables> {
  return useLazyQuery<AdminGetGradooStaff, AdminGetGradooStaffVariables>(
    ADMIN_GET_GRADOO_STAFF,
    options,
  );
}

export function createAdminGetGradooStaffMock({
  variables,
  data,
  error,
}: {
  variables?: AdminGetGradooStaffVariables;
  data?: AdminGetGradooStaff;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: ADMIN_GET_GRADOO_STAFF,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
