/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query Module
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { Module, ModuleVariables } from './types/Module';
import { MODULE } from './';

export function useModuleQuery(
  options?: QueryHookOptions<Module, ModuleVariables>,
): QueryResult<Module, ModuleVariables> {
  return useQuery<Module, ModuleVariables>(MODULE, options);
}

export function useModuleLazyQuery(
  options?: LazyQueryHookOptions<Module, ModuleVariables>,
): QueryTuple<Module, ModuleVariables> {
  return useLazyQuery<Module, ModuleVariables>(MODULE, options);
}

export function createModuleMock({
  variables,
  data,
  error,
}: {
  variables?: ModuleVariables;
  data?: Module;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: MODULE,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
