/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation RemoveUserFromTeam
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  RemoveUserFromTeam,
  RemoveUserFromTeamVariables,
} from './types/RemoveUserFromTeam';
import { REMOVE_USER_FROM_TEAM } from './';

export function useRemoveUserFromTeamMutation(
  options?: MutationHookOptions<
    RemoveUserFromTeam,
    RemoveUserFromTeamVariables
  >,
): MutationTuple<RemoveUserFromTeam, RemoveUserFromTeamVariables> {
  return useMutation<RemoveUserFromTeam, RemoveUserFromTeamVariables>(
    REMOVE_USER_FROM_TEAM,
    options,
  );
}

export function createRemoveUserFromTeamMock({
  variables,
  data,
  error,
}: {
  variables?: RemoveUserFromTeamVariables;
  data?: RemoveUserFromTeam;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: REMOVE_USER_FROM_TEAM,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
