import { DefaultTheme } from 'styled-components/native';
import { GroupCellType } from './types';

// TODO: GroupCell design variations are not implemented yet, waiting UI. Now all variation conditions are prepared but all return default type for now.

interface CellTypeHelper {
  bgColors: (
    type: GroupCellType,
    checked: boolean,
    theme: DefaultTheme,
  ) => string[];
  bgColor: (
    type: GroupCellType,
    theme: DefaultTheme,
    checked: boolean,
  ) => string;
  tintColor: (type: GroupCellType, theme: DefaultTheme) => string;
  secTintColor: (
    type: GroupCellType,
    theme: DefaultTheme,
    checked: boolean,
  ) => string;
}

export const byTypeHelper: CellTypeHelper = {
  bgColors: (
    type: GroupCellType,
    checked: boolean,
    theme: DefaultTheme,
  ): string[] => {
    if (type === 'primary') {
      return [theme.color.base.c3, theme.color.base.c3];
    }
    if (checked) {
      return [theme.color.base.c4, theme.color.base.c4];
    }
    return [theme.color.base.c3, theme.color.base.c3];
  },
  bgColor: (
    type: GroupCellType,
    theme: DefaultTheme,
    checked: boolean,
  ): string => {
    switch (type) {
      case 'primary':
        return checked ? theme.color.base.c1 : theme.color.base.c0;
      case 'secondary':
        return checked ? theme.color.base.c1 : theme.color.base.c0;
      default:
        return checked ? theme.color.base.c1 : theme.color.base.c0;
    }
  },
  tintColor: (type: GroupCellType, theme: DefaultTheme): string => {
    switch (type) {
      case 'primary':
        return theme.color.base.c10;
      case 'secondary':
        return theme.color.base.c10;
      default:
        return theme.color.base.c10;
    }
  },
  secTintColor: (
    type: GroupCellType,
    theme: DefaultTheme,
    checked: boolean,
  ): string => {
    switch (type) {
      case 'primary':
        return checked ? theme.color.brand_02 : theme.color.base.c7;
      case 'secondary':
        return checked ? theme.color.brand_02 : theme.color.base.c7;
      default:
        return checked ? theme.color.brand_02 : theme.color.base.c7;
    }
  },
};
