import React from 'react';
import { IconName } from '~/components/Icon';
import { IconStyledName } from '~/components/IconStyled';
import {
  Container,
  CircleContainer,
  ButtonIcon,
  ButtonIconStyled,
  Text,
  SectionButtonType,
  IconStyledContainer,
  SectionButtonIntent,
} from './style';

type SectionButtonProps = {
  testID?: string;
  text?: string;
  icon?: IconName;
  iconStyled?: IconStyledName;
  centerContent?: boolean;
  textColor?: string;
  locked?: boolean;
  type?: SectionButtonType;
  intent?: SectionButtonIntent;
  onPress: () => void;
};

export default function SectionButton({
  testID,
  text,
  icon,
  iconStyled,
  centerContent,
  textColor,
  locked = false,
  type = 'default',
  intent = 'default',
  onPress,
  ...style
}: SectionButtonProps): JSX.Element {
  if (icon && !text) {
    return (
      <CircleContainer
        {...style}
        testID={testID}
        locked={locked}
        onPress={onPress}
      >
        <ButtonIcon
          locked={locked}
          name={icon}
          size={20}
          textColor={textColor}
        />
      </CircleContainer>
    );
  }
  return (
    <Container
      {...style}
      testID={testID}
      centerContent={centerContent}
      locked={locked}
      type={type}
      intent={intent}
      onPress={onPress}
    >
      {icon && (
        <ButtonIcon
          name={icon}
          hasPadding
          textColor={textColor}
          locked={locked}
        />
      )}
      {iconStyled && (
        <IconStyledContainer hasPadding locked={locked}>
          <ButtonIconStyled name={iconStyled} textColor={textColor} />
        </IconStyledContainer>
      )}
      <Text locked={locked} textColor={textColor}>
        {text}
      </Text>
    </Container>
  );
}
