import styled from 'styled-components/native';
import CardOptionCell from '~/components/CardOptionCell';
import SectionButton from '~/components/SectionButton';
import { ResponsiveProps } from '~/components/common/style.web';

export const GendersRow = styled.View`
  flex-direction: row;
  gap: 8px;
`;

export const GenderOption = styled(CardOptionCell)`
  margin: 0;
`;

export const Spinner = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c4,
  size: 'large',
}))`
  margin: 20px 0;
`;

export const UserGroupsControls = styled.View<ResponsiveProps>`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: ${({ isDesktop }) => (isDesktop ? '16px' : '16px 0')};
`;

export const ActionsButton = styled(SectionButton)<ResponsiveProps>`
  text-align: center;
  justify-content: ${({ isDesktop }) => (isDesktop ? 'flex-start' : 'center')};
`;

export const RegisteredSinceText = styled.Text<ResponsiveProps>`
  color: ${({ theme }) => theme.color.base.c6};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: ${({ isDesktop }) => (isDesktop ? '16' : '14')}px;
  line-height: 20px;
`;
