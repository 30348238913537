import { InputAccessoryView, Platform } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import styled from 'styled-components/native';
import ButtonIcon from '~/components/ButtonIcon';
import ImageComp from '~/components/Image';

interface ITextEditor {
  minHeight?: number;
}

export const Container = styled.View`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const LoadingHeader = styled.View`
  height: 32px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const LoadingText = styled.Text`
  color: ${({ theme }) => theme.color.base.c4};
  font-size: 12px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  margin-right: 12px;
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c4,
  size: 'small',
}))``;

export const ScrollView = styled(KeyboardAwareScrollView)`
  padding-horizontal: 32px;
  padding-bottom: 32px;
`;

export const TextInput = styled.TextInput<ITextEditor>`
  text-align-vertical: top;
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : 'auto')};
`;

export const TextInputList = styled.FlatList``;

const Footer = styled.View`
  position: absolute;
  left: 0px;
  bottom: 0px;
  right: 0px;
`;

export const ToolbarContainer = styled(
  Platform.OS === 'ios' ? InputAccessoryView : Footer,
)``;

export const CharCountContainer = styled.View`
  padding: 8px 16px;
  align-items: flex-end;
`;

interface ICharCount {
  exceed: boolean;
}
export const CharCount = styled.Text<ICharCount>`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 12px;
  line-height: 20px;
  color: ${({ exceed, theme }) =>
    exceed ? theme.color.error : theme.color.base.c9};
`;

export const ImageContainer = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})`
  margin-vertical: 8px;
`;

export const Image = styled(ImageComp)`
  height: 200px;
`;

export const RemoveButton = styled(ButtonIcon).attrs(({ theme }) => ({
  name: 'x-close',
  size: 16,
  color: theme.color.brand_02,
}))`
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: ${({ theme }) => theme.color.base.c0};
  width: 28px;
  height: 28px;
  border-radius: 14px;
  align-items: center;
  justify-content: center;
`;
