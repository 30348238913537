import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import useAppLocale from '~/data/hooks/useAppLocale';
import { AuthStackParamList } from '~/navigation/types';
import ResetPasswordSuccessLayout from './layout';

type ResetPasswordSuccessNavProp = StackNavigationProp<
  AuthStackParamList,
  'ResetPasswordSuccess'
>;

export default function ResetPasswordSuccess(): JSX.Element {
  const navigation = useNavigation<ResetPasswordSuccessNavProp>();

  const { locale, onChangeLocale } = useAppLocale({
    onReload: () => navigation.replace('ResetPasswordSuccess'),
  });
  return (
    <ResetPasswordSuccessLayout
      selectedLocale={locale}
      onBack={() => navigation.goBack()}
      onGoToLogin={() => navigation.pop(2)}
      onSelectedLocale={onChangeLocale}
    />
  );
}

/**
 * TODO:
 * - Setup lottie
 */
