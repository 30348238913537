import styled, { DefaultTheme } from 'styled-components/native';

export enum SeverityEnum {
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
}

export const ErrorView = styled.View`
  padding: 10px;
  margin: 10px;
`;
export const NotificationText = styled.Text`
  color: ${({ theme }) => theme.color.error};
`;

const containerBackground = (severity: SeverityEnum, theme: DefaultTheme) => {
  const colorMap = {
    [SeverityEnum.Error]: theme.color.base.c0,
    [SeverityEnum.Warning]: theme.color.base.c0,
    [SeverityEnum.Info]: theme.color.base.c0,
  };
  return colorMap[severity];
};

interface NotificationContainerProps {
  severity: SeverityEnum;
}
export const NotificationContainer = styled.View<NotificationContainerProps>`
  padding: 10px;
  margin: 15px;
  background-color: ${({ severity, theme }) =>
    containerBackground(severity, theme)};
`;
