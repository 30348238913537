/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation SetEventBanner
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  SetEventBanner,
  SetEventBannerVariables,
} from './types/SetEventBanner';
import { SET_EVENT_BANNER } from './';

export function useSetEventBannerMutation(
  options?: MutationHookOptions<SetEventBanner, SetEventBannerVariables>,
): MutationTuple<SetEventBanner, SetEventBannerVariables> {
  return useMutation<SetEventBanner, SetEventBannerVariables>(
    SET_EVENT_BANNER,
    options,
  );
}

export function createSetEventBannerMock({
  variables,
  data,
  error,
}: {
  variables?: SetEventBannerVariables;
  data?: SetEventBanner;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: SET_EVENT_BANNER,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
