import React, { useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import { Modalize } from 'react-native-modalize';
import { Portal } from 'react-native-portalize';
import { getRoundedPercentage } from '~/data/mappers';
import { BasePoll, PollVoteOption } from '~/data/models/post';
import { isDateExpired } from '~/utils/dates';
import { t } from '~/utils/i18n';
import {
  Container,
  Title,
  Descp,
  OptionButton,
  OptionContentImage,
  OptionImage,
  OptionTitle,
  OptionResult,
  OptionResultContent,
  OptionResultBG,
  OptionResultTitle,
  OptionResultPct,
  RevoteButton,
  FullImageModalContainer,
  FullImageCloseButtonIcon,
  FullImage,
} from './style';
export interface PollCellProps {
  poll: BasePoll;
  votedOptions?: PollVoteOption[];
  largeTitle?: boolean;
  ownerTeamId?: string;
  authTeamIds: string[];
  isPostOwner?: boolean;
  now?: Date;
  onVote?: (optionId: string, revoteId?: string) => void;
}

export default function PollCell({
  poll,
  votedOptions,
  largeTitle = false,
  ownerTeamId,
  authTeamIds,
  isPostOwner,
  now = new Date(),
  onVote,
}: PollCellProps): JSX.Element {
  const imgModalizeRef = useRef<Modalize>(null);
  const [fullImgUrl, setFullImgUrl] = useState<string | null>(null);
  const [revoteId, setRevoteId] = useState<string | undefined>(undefined);
  const voted =
    (!revoteId && votedOptions && votedOptions.length == poll.answersAllowed) ||
    false;

  const deadlineReached = isDateExpired(poll.deadline, now);

  const isOwnerTeamMember = ownerTeamId
    ? authTeamIds?.includes(ownerTeamId)
    : false;

  const debouncedOnVote = useMemo(() => {
    return debounce((optionId: string, revoteId?: string) => {
      onVote && onVote(optionId, revoteId);
      revoteId && setRevoteId(undefined);
    }, 300);
  }, [onVote]);

  useEffect(() => {
    return debouncedOnVote.cancel();
  }, []);

  return (
    <Container testID={`PollCell:${poll.id}`}>
      <Title largeTitle={largeTitle}>{poll.question}</Title>
      <Descp>
        {voted && poll.privateResults && !isOwnerTeamMember && !isPostOwner
          ? t('poll.hiddenResults')
          : deadlineReached
          ? t('poll.deadlineReached')
          : voted && !poll.allowRevote
          ? t('poll.votedRevoteDisabled')
          : t('poll.markOption', {
              count: poll.answersAllowed,
            })}
      </Descp>
      {poll.options?.map((pollOption, index) => {
        if (!pollOption) {
          return null;
        }
        const hasImg = !!pollOption?.image;
        const totalVoteForOption = pollOption.votesNumber;
        const votedOption =
          (votedOptions &&
            votedOptions.find((vo) => vo.optionId === pollOption.id)) ||
          null;

        if (
          (!poll.privateResults && (voted || deadlineReached)) ||
          (poll.privateResults && voted && (isOwnerTeamMember || isPostOwner))
        ) {
          const pct = getRoundedPercentage(pollOption.percentage || 0);
          return (
            <OptionResult key={`po${index}`}>
              <OptionResultBG voted={!!votedOption} pct={pct} />
              <OptionResultContent hasImg={hasImg}>
                {pollOption?.image ? (
                  <OptionContentImage
                    onPress={() => {
                      setFullImgUrl(pollOption.image);
                      imgModalizeRef.current?.open();
                    }}
                  >
                    <OptionImage source={{ uri: pollOption.image }} />
                  </OptionContentImage>
                ) : null}
                <OptionResultTitle hasImg={hasImg} voted={!!votedOption}>
                  {pollOption?.text}
                </OptionResultTitle>
                {votedOption && poll.allowRevote && (
                  <RevoteButton
                    onPress={() => setRevoteId(votedOption.voteId)}
                  />
                )}
                <OptionResultPct>
                  {totalVoteForOption
                    ? `${pct}% (${totalVoteForOption})`
                    : `${pct}%`}
                </OptionResultPct>
              </OptionResultContent>
            </OptionResult>
          );
        }
        return (
          <OptionButton
            key={`po${index}`}
            testID={pollOption.id}
            hasImg={hasImg}
            disabled={voted || deadlineReached}
            voted={voted || deadlineReached}
            votedOption={!!votedOption && !revoteId}
            onPress={() => {
              if (!votedOption && !deadlineReached && onVote) {
                debouncedOnVote(pollOption.id, revoteId);
              }
            }}
          >
            {pollOption.image ? (
              <OptionContentImage
                onPress={() => {
                  setFullImgUrl(pollOption.image);
                  imgModalizeRef.current?.open();
                }}
              >
                <OptionImage source={{ uri: pollOption.image }} />
              </OptionContentImage>
            ) : null}
            <OptionTitle
              voted={voted || deadlineReached}
              votedOption={!!votedOption && !revoteId}
              hasImg={hasImg}
            >
              {pollOption.text}
            </OptionTitle>
          </OptionButton>
        );
      })}
      <Portal>
        <Modalize
          ref={imgModalizeRef}
          adjustToContentHeight
          withHandle={false}
          rootStyle={{ shadowOpacity: 0.8 }}
          modalStyle={{
            flex: 1,
            margin: 16,
            justifyContent: 'center',
            backgroundColor: 'transparent',
          }}
        >
          <FullImageModalContainer>
            <FullImageCloseButtonIcon
              onPress={() => {
                setFullImgUrl(null);
                imgModalizeRef.current?.close();
              }}
            />
            <FullImage source={{ uri: fullImgUrl || '' }} />
          </FullImageModalContainer>
        </Modalize>
      </Portal>
    </Container>
  );
}
