import React from 'react';
import { LineStyle, TextStyle } from 'react-native-markdown-editor';
import { t } from '~/utils/i18n';
import {
  Container,
  Header,
  Title,
  CloseButtonIcon,
  LineStyleContainer,
  LineStyleButton,
  LineStyleButtonText,
  TextStyleContainer,
  TextStyleButton,
  TextStyleButtonText,
} from './style';

type StyleBarProps = {
  testID?: string;
  currentLineStyle?: LineStyle;
  currentTextStyles?: TextStyle[];
  onClose: () => void;
  onLineStyle: (lineStyle: LineStyle) => void;
  onTextStyle: (textStyle: TextStyle) => void;
};

const LINE_TEXT_STYLES: LineStyle[] = ['#', '##', '###', 'body'];

const LINE_TEXT_NAME_MAP: { [key in LineStyle]: string } = {
  '#': t('textStyle.title'),
  '##': t('textStyle.heading'),
  '###': t('textStyle.subheading'),
  body: t('textStyle.body'),
};

const TEXT_STYLES: TextStyle[] = ['**', '__', '--', '~~'];

const TEXT_STYLE_NAME_MAP: { [key in TextStyle]: string } = {
  '**': 'B',
  __: 'i',
  '--': 'U',
  '~~': 'S',
};

export default function StyleBar({
  testID,
  currentLineStyle = 'body',
  currentTextStyles = [],
  onClose,
  onLineStyle,
  onTextStyle,
}: StyleBarProps): JSX.Element {
  return (
    <Container testID={testID}>
      <Header>
        <Title>{t('g.textStyle')}</Title>
        <CloseButtonIcon onPress={onClose} />
      </Header>
      <LineStyleContainer>
        {LINE_TEXT_STYLES.map((lineStyle) => {
          const selected = lineStyle === currentLineStyle;
          return (
            <LineStyleButton
              key={lineStyle}
              selected={selected}
              lineStyle={lineStyle}
              onPress={() => onLineStyle(lineStyle)}
            >
              <LineStyleButtonText selected={selected} lineStyle={lineStyle}>
                {LINE_TEXT_NAME_MAP[lineStyle]}
              </LineStyleButtonText>
            </LineStyleButton>
          );
        })}
      </LineStyleContainer>
      <TextStyleContainer>
        {TEXT_STYLES.map((textStyle, i) => {
          const selected = currentTextStyles.includes(textStyle);
          return (
            <TextStyleButton
              key={textStyle}
              marginRight={i != TEXT_STYLES.length - 1}
              leftBorderRadius={i == 0}
              rightBorderRadius={i == TEXT_STYLES.length - 1}
              textStyle={textStyle}
              selected={selected}
              onPress={() => onTextStyle(textStyle)}
            >
              <TextStyleButtonText selected={selected} textStyle={textStyle}>
                {TEXT_STYLE_NAME_MAP[textStyle]}
              </TextStyleButtonText>
            </TextStyleButton>
          );
        })}
      </TextStyleContainer>
    </Container>
  );
}
