import { BottomTabBarProps } from '@react-navigation/bottom-tabs';
import { DrawerContentComponentProps } from '@react-navigation/drawer';
import { Route } from '@react-navigation/native';
import { IconName } from '~/components/Icon';
import { MainTabParamList } from '~/navigation/types';
import { t } from '~/utils/i18n';

// Note: getMobileRouteMeta is deleted because both web and mobile use the same icons with V2 design.

type MainTabName = keyof MainTabParamList;

const tabIcons: { [key in MainTabName]: IconName } = {
  FeedStack: 'flash',
  PlanningStack: 'check-done-02',
  YearbookStack: 'book-open-01',
  ProfileStack: 'user-circle',
};

const getRouteLabel = (routeName: MainTabName): string => {
  const tabLabels: { [key in MainTabName]: string } = {
    FeedStack: t('g.feed'),
    PlanningStack: t('g.planning'),
    YearbookStack: t('g.yearbook'),
    ProfileStack: t('g.me'),
  };

  return tabLabels[routeName];
};

interface IRouteMeta {
  isFocused: boolean;
  routeName: string;
  iconName: IconName;
  label: string;
}

export const getRouteMeta = (
  state: DrawerContentComponentProps['state'] | BottomTabBarProps['state'],
  route: Route<string>,
  index: number,
): IRouteMeta => {
  const isFocused = state.index === index;
  const routeName = route.name as MainTabName;

  const iconName: IconName = tabIcons[routeName];

  const label = getRouteLabel(routeName);

  return {
    isFocused,
    routeName,
    iconName,
    label,
  };
};
