/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation CreateDataExport
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  CreateDataExport,
  CreateDataExportVariables,
} from './types/CreateDataExport';
import { CREATE_DATA_EXPORT } from './';

export function useCreateDataExportMutation(
  options?: MutationHookOptions<CreateDataExport, CreateDataExportVariables>,
): MutationTuple<CreateDataExport, CreateDataExportVariables> {
  return useMutation<CreateDataExport, CreateDataExportVariables>(
    CREATE_DATA_EXPORT,
    options,
  );
}

export function createCreateDataExportMock({
  variables,
  data,
  error,
}: {
  variables?: CreateDataExportVariables;
  data?: CreateDataExport;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: CREATE_DATA_EXPORT,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
