import React, { useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { useDeleteUserMutation } from '~/data/operations/auth/deleteUser';
import { RootStackParamList } from '~/navigation/types';
import DeleteAccountLayout from './layout';

type DeleteAccountNavProp = StackNavigationProp<
  RootStackParamList,
  'DeleteAccount'
>;

export default function DeleteAccount(): JSX.Element {
  const navigation = useNavigation<DeleteAccountNavProp>();

  const { authUserId, onLogout } = useAuth();

  const [deleteSuccess, setDeleteSuccess] = useState(false);

  const [deleteUser, { loading }] = useDeleteUserMutation();

  const onDelete = async () => {
    try {
      await deleteUser({
        variables: {
          input: {
            id: authUserId,
          },
        },
      });
      setDeleteSuccess(true);
      onLogout();
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  return (
    <DeleteAccountLayout
      deleteSuccess={deleteSuccess}
      loading={loading}
      onBack={() => navigation.goBack()}
      onDelete={onDelete}
      onBackToStart={() =>
        navigation.reset({
          routes: [{ name: 'AuthStack' }],
        })
      }
    />
  );
}
