/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation CreateCustomListOption
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  CreateCustomListOption,
  CreateCustomListOptionVariables,
} from './types/CreateCustomListOption';
import { CREATE_CUSTOM_LIST_OPTION } from './';

export function useCreateCustomListOptionMutation(
  options?: MutationHookOptions<
    CreateCustomListOption,
    CreateCustomListOptionVariables
  >,
): MutationTuple<CreateCustomListOption, CreateCustomListOptionVariables> {
  return useMutation<CreateCustomListOption, CreateCustomListOptionVariables>(
    CREATE_CUSTOM_LIST_OPTION,
    options,
  );
}

export function createCreateCustomListOptionMock({
  variables,
  data,
  error,
}: {
  variables?: CreateCustomListOptionVariables;
  data?: CreateCustomListOption;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: CREATE_CUSTOM_LIST_OPTION,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
