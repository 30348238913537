import { LinearGradient } from 'expo-linear-gradient';
import styled from 'styled-components/native';

export const Container = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  width: auto;
  min-width: 881px;
  flex-direction: row;
  justify-content: space-between;

  align-items: center;
`;

export const StepContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;

interface IStepper {
  isActive: boolean;
}
export const StepperIconContainer = styled.View<IStepper>`
  width: 32;
  height: 32;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.color.brand_02 : theme.color.base.c2};
  border: ${({ theme, isActive }) =>
    isActive ? theme.color.base.c3 : theme.color.base.c3};
  border-radius: 16;
  justify-content: center;
  align-items: center;
`;

export const LeftStepperIconContainer = styled.View`
  margin-right: -5px;
  margin-top: 8px;
  z-index: 2;
`;

export const RightStepperIconContainer = styled.View`
  margin-left: -5px;
  margin-top: 8px;
  z-index: 2;
`;

interface IStepper {
  isActive: boolean;
}

interface IStepperPipeLine {
  fillPercentage?: number;
}

export const StepperPipeLine = styled(LinearGradient).attrs<IStepperPipeLine>(
  ({ theme, fillPercentage = 100 }) => {
    const activeColor = theme.gradient.primary.cs01;
    const inactiveColor = [theme.color.base.c2, theme.color.base.c2];
    let gradientColors;
    let gradientLocations;

    if (fillPercentage > 0) {
      gradientColors = [...activeColor, ...inactiveColor];
      gradientLocations = [0, fillPercentage / 100, fillPercentage / 100, 1];
    } else {
      gradientColors = inactiveColor;
      gradientLocations = [0, 1];
    }

    return {
      colors: gradientColors,
      locations: gradientLocations,
      start: { x: 0, y: 0 },
      end: { x: 1, y: 0 },
    };
  },
)<IStepperPipeLine>`
  height: 12px;
  width: 173px;
  z-index: 1;
`;

export const MiddleStepperIcon = styled(StepperIconContainer)``;

export const MiddleStepperContainer = styled.View`
  position: absolute;
  left: 45%;
  margin-top: 8px;
  z-index: 3;
  align-items: center;
`;

export const CancelButtonWrapper = styled.View`
  width: 144px;
`;

export const ContinueButtonWrapper = styled.View`
  margin-right: 5px;
`;

export const BackButtonWrapper = styled.View`
  margin-right: 10px;
`;

export const ButtonWrapper = styled.View`
  flex-direction: row;
`;

export const StepperIconText = styled.Text<IStepper>`
  font-size: 8px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme, isActive }) =>
    isActive ? theme.color.brand_02 : theme.color.base.c4};
  align-self: center;
`;

export const MiddleStepperIconText = styled.Text<IStepper>`
  font-size: 8px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  top: 10px;
  color: ${({ theme, isActive }) =>
    isActive ? theme.color.brand_02 : theme.color.base.c4};
`;

/** Ensures that there is no jumping left or right when switching steps */
export const EmptyButtonPlaceholder = styled.View`
  min-width: 97px;
`;
