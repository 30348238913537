import React, { ReactNode } from 'react';
import Toggle from '~/components/Toggle';
import { TouchableOpacityWithMeasure } from '~/utils/hooks/useMeasurableTouchableOpacity';
import { Container, ItemContainer, ItemText, TouchableView } from './style';

type SettingPanelProps = {
  children: ReactNode;
};

export function SettingPanel({ children }: SettingPanelProps): JSX.Element {
  return <Container>{children}</Container>;
}

type SettingToggleItemProps = {
  testID?: string;
  text: string;
  checked: boolean;
  onToggleChange: (checked: boolean) => void;
};

export function SettingToggleItem({
  testID,
  text,
  checked,
  onToggleChange,
}: SettingToggleItemProps): JSX.Element {
  return (
    <ItemContainer testID={testID}>
      <ItemText>{text}</ItemText>
      <Toggle checked={checked} onChange={onToggleChange} />
    </ItemContainer>
  );
}

type SettingLabelItemProps = {
  testID?: string;
  text: string;
  buttonRef?: React.RefObject<TouchableOpacityWithMeasure>;
  labelText: string;
  onLabelPress: () => void;
};

export function SettingLabelItem({
  testID,
  text,
  labelText,
  buttonRef,
  onLabelPress,
}: SettingLabelItemProps): JSX.Element {
  return (
    <ItemContainer testID={testID}>
      <ItemText>{text}</ItemText>
      <TouchableView onPress={onLabelPress} ref={buttonRef}>
        <ItemText>{labelText}</ItemText>
      </TouchableView>
    </ItemContainer>
  );
}
