import styled from 'styled-components/native';
import Icon from '~/components/Icon';
import Image from '~/components/Image';

export const Container = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})`
  flex-direction: row;
  align-items: center;
`;

export const FlagImage = styled(Image)`
  height: 15px;
  width: 24px;
`;

export const ArrowView = styled.View`
  margin-left: 8px;
  width: 8px;
  align-items: center;
`;

export const ArrowIcon = styled(Icon).attrs(({ theme }) => ({
  name: 'chevron-down',
  size: 16,
  color: theme.color.base.c6,
}))``;
