import React, { useState, useEffect } from 'react';
import { Dimensions, FlatList } from 'react-native';
import { onboarding } from '~/assets/imgs';
import Button from '~/components/Button';
import { AppLocale } from '~/data/models/app';
import { DefaultAvatar } from '~/data/models/custom';
import { UserGroup } from '~/data/models/group';
import { UserType, UsersUserGenderChoices } from '~/data/operations/global';
import { OnboardingType } from '~/navigation/types';
import { t } from '~/utils/i18n';
import { InfoPage, PageKey, PageItemMap, ProfilePage } from './Page';
import { ButtonView, ButtonRowView } from './Page/style';
import Progress from './Progress';
import { Container, PageList, Footer } from './style';

const { width } = Dimensions.get('window');
//button need to have specific widths due tho the svg shadows
//from figma total = 336 = 156 (skip width) + 180 (width) = 46% (skip) + 54% (next)
const H_PADDINGS = 2 * 16 + 8 + 6; //horizontal container padding + gap + button extra border width
const BUTTON_ROW_WIDTH = width - H_PADDINGS;
const BUTTON_ROW_SKIP_WIDTH = BUTTON_ROW_WIDTH * 0.46;
const BUTTON_ROW_NEXT_WIDTH = BUTTON_ROW_WIDTH * 0.54;

export type UserGroupInput = {
  role: UserType;
  uri?: string | null;
  defaultAvatarId?: string | null;
};

export type OnboardingLayoutProps = {
  locale: AppLocale;
  onboardingType: OnboardingType;
  authUserGroup?: UserGroup | null;
  firstName?: string;
  defaultAvatars: DefaultAvatar[];
  uploadingImage: boolean;
  onUpdateAvatar: (uri: string | null, iconId: string | null) => void;
  onUpdateGender: (gender: string) => void;
  onUpdateRole: (role: UserType) => void;
  onRequestNotification: () => void;
  onFinish: () => void;
};

const corePageKeys: PageKey[] = [
  'core-1',
  'core-2',
  'core-3',
  'core-4-profile',
  'core-5-notification',
  'core-6',
];

const coreHelpPageKeys: PageKey[] = ['core-1', 'core-2', 'core-3', 'core-6'];

const pageKeysMap: { [key in OnboardingType]: PageKey[] } = {
  core: corePageKeys,
  core_help: coreHelpPageKeys,
};

export default function Onboarding({
  locale,
  onboardingType,
  authUserGroup,
  defaultAvatars,
  uploadingImage,
  onUpdateAvatar,
  onUpdateGender,
  onUpdateRole,
  onRequestNotification,
  onFinish,
}: OnboardingLayoutProps): JSX.Element {
  const pageKeys = pageKeysMap[onboardingType];

  const pageListRef = React.useRef<FlatList>(null);
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);

  const [gender, setGender] = useState<UsersUserGenderChoices | null>(
    () => authUserGroup?.user.gender || null,
  );
  const [role, setRole] = useState<UserType | undefined>(
    () => authUserGroup?.role || undefined,
  );
  const onNext = () =>
    currentPageIndex < pageKeys.length &&
    setCurrentPageIndex(currentPageIndex + 1);

  const onSkip = () => setCurrentPageIndex(3);

  const filledProfileInfo =
    (!!authUserGroup?.avatar || !!authUserGroup?.defaultAvatar) &&
    !!authUserGroup?.user.gender &&
    !!authUserGroup?.role;

  const isDe = locale === 'de' || locale === 'de-at';

  const firstName = authUserGroup?.user.firstName || '';

  const pageItemMap: PageItemMap = {
    'core-1': {
      pageType: 'info',
      imgSrc: isDe ? onboarding.core1_de : onboarding.core1,
      headerTitle: t('onboarding.welcomeToGradoo'),
      title: t('onboarding.core1Title', {
        firstName,
      }),
      content: t('onboarding.core1Descp'),
      FooterComponent: (
        <ButtonView>
          <Button
            testID={'buttonNextCore1'}
            type={'secondary-contrast'}
            text={t('onboarding.startShortIntro')}
            size={'xl'}
            flex
            onPress={onNext}
          />
        </ButtonView>
      ),
    },
    'core-2': {
      pageType: 'info',
      imgSrc: isDe ? onboarding.core2_de : onboarding.core2,
      headerTitle: t('onboarding.welcomeToGradoo'),
      title: t('onboarding.core2Title'),
      content: t('onboarding.core2Descp'),
      FooterComponent: (
        <ButtonRowView>
          <Button
            testID={'buttonSkip2'}
            type={'secondary-base'}
            text={t('onboarding.skip')}
            minWidth={BUTTON_ROW_SKIP_WIDTH}
            size={'xl'}
            onPress={onSkip}
          />
          <Button
            testID={'buttonNextCore2'}
            type={'secondary-contrast'}
            text={t('onboarding.next')}
            minWidth={BUTTON_ROW_NEXT_WIDTH}
            size={'xl'}
            onPress={onNext}
          />
        </ButtonRowView>
      ),
    },
    'core-3': {
      pageType: 'info',
      imgSrc: isDe ? onboarding.core3_de : onboarding.core3,
      headerTitle: t('onboarding.welcomeToGradoo'),
      title: t('onboarding.core3Title'),
      content: t('onboarding.core3Descp'),
      FooterComponent: (
        <ButtonRowView>
          <Button
            testID={'buttonSkip3'}
            type={'secondary-base'}
            text={t('onboarding.skip')}
            minWidth={BUTTON_ROW_SKIP_WIDTH}
            size={'xl'}
            onPress={onSkip}
          />
          <Button
            testID={'buttonNextCore3'}
            type={'secondary-contrast'}
            text={t('onboarding.next')}
            minWidth={BUTTON_ROW_NEXT_WIDTH}
            size={'xl'}
            onPress={onNext}
          />
        </ButtonRowView>
      ),
    },
    'core-4-profile': {
      pageType: 'setup',
      headerTitle: t('onboarding.completeYourProfile'),
      FooterComponent: (
        <ButtonView>
          <Button
            testID={'buttonNextCore4'}
            type={'secondary-contrast'}
            text={t('onboarding.completeYourProfile')}
            state={filledProfileInfo ? 'default' : 'disabled'}
            size={'xl'}
            flex
            onPress={onNext}
          />
        </ButtonView>
      ),
    },
    'core-5-notification': {
      pageType: 'info',
      imgSrc: onboarding.core5,
      headerTitle: t('onboarding.welcomeToGradoo'),
      title: t('onboarding.core5Title'),
      content: t('onboarding.core5Descp'),
      FooterComponent: (
        <ButtonView>
          <Button
            testID={'buttonNextCore5'}
            type={'secondary-contrast'}
            text={t('onboarding.next')}
            size={'xl'}
            flex
            onPress={() => {
              onRequestNotification();
              onNext();
            }}
          />
        </ButtonView>
      ),
    },
    'core-6': {
      pageType: 'info',
      imgSrc: onboarding.core6,
      title: t('onboarding.core6Title'),
      content: t('onboarding.core6Descp'),
      FooterComponent: (
        <ButtonView>
          <Button
            testID={'buttonFinishCore'}
            type={'secondary-contrast'}
            text={t('onboarding.jumpBackToTheApp')}
            size={'xl'}
            flex
            onPress={onFinish}
          />
        </ButtonView>
      ),
    },
  };

  useEffect(() => {
    if (currentPageIndex != undefined) {
      pageListRef.current?.scrollToIndex({
        animated: true,
        index: currentPageIndex,
      });
    }
  }, [currentPageIndex]);

  const renderItem = ({ item: pageKey }: { item: PageKey }) => {
    const pageItem = pageItemMap[pageKey];
    if (pageItem.pageType === 'info') {
      return <InfoPage pageItem={pageItem} />;
    } else {
      return (
        <ProfilePage
          testID={'profilePage'}
          pageItem={pageItem}
          userGroup={authUserGroup}
          avatarUri={authUserGroup?.avatar || null}
          defaultAvatars={defaultAvatars}
          gender={gender}
          role={role}
          uploadingImage={uploadingImage}
          onUpdateAvatar={onUpdateAvatar}
          onSelectedGender={(gender) => {
            setGender(gender);
            onUpdateGender(gender);
          }}
          onSelectedRole={(role) => {
            setRole(role);
            onUpdateRole(role);
          }}
        />
      );
    }
  };

  return (
    <Container>
      <PageList<React.ElementType>
        ref={pageListRef}
        horizontal
        scrollEnabled={false}
        nestedScrollEnabled
        showsHorizontalScrollIndicator={false}
        data={pageKeys}
        renderItem={renderItem}
        keyExtractor={(_: string, index: number) => `${onboardingType}${index}`}
        onScrollToIndexFailed={() => {}} //eslint-disable-line
      />
      <Footer>
        <Progress
          current={currentPageIndex}
          max={pageKeys.length}
          onSelectedPage={(index) => setCurrentPageIndex(index)}
        />
      </Footer>
    </Container>
  );
}
