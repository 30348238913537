import React, { useState } from 'react';
import {
  CompositeNavigationProp,
  useNavigation,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { ReactNativeFile } from 'apollo-upload-client';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import useAuthTeams from '~/data/hooks/useAuthTeams';
import useGroupUsers from '~/data/hooks/useGroupUsers';
import { useCreateEventMutation } from '~/data/operations/calendar/createEvent';
import { useSetEventBannerMutation } from '~/data/operations/calendar/setEventBanner';
import { CreateEventInput } from '~/data/operations/global';
import { useGroupQuery } from '~/data/operations/group/group';
import { RootStackParamList, PlanningStackParamList } from '~/navigation/types';
import { getFileName } from '~/utils/helpers';
import { t } from '~/utils/i18n';
import CreateEventLayout from './layout';

type CreateEventNavProp = CompositeNavigationProp<
  StackNavigationProp<RootStackParamList, 'CreateEvent'>,
  CompositeNavigationProp<
    StackNavigationProp<RootStackParamList>,
    StackNavigationProp<PlanningStackParamList>
  >
>;

export default function CreateEvent(): JSX.Element {
  const navigation = useNavigation<CreateEventNavProp>();
  const [submitting, setIsSubmitting] = useState<boolean>(false);
  const { groupUsers } = useGroupUsers();
  const { teams } = useAuthTeams();
  const [createEvent] = useCreateEventMutation();
  const [setEventBanner] = useSetEventBannerMutation();
  const { authGroupId, authUserGroup, authUserId } = useAuth();

  const { data: groupData } = useGroupQuery({
    skip: !authGroupId,
    variables: {
      id: authGroupId,
    },
  });

  const filteredGroupUsers = groupUsers.filter(
    (groupUser) => groupUser?.group?.id === groupData?.group?.id,
  );

  const onCreateEvent = async (input: CreateEventInput) => {
    const {
      title,
      location,
      description,
      authorUser,
      authorGroup,
      authorTeam,
    } = input;

    const requiredFields = {
      title: {
        val: title,
        label: t('createEvent.nameLabel'),
      },
      location: {
        val: location,
        label: t('createEvent.locationTitle'),
      },
      description: {
        val: description,
        label: t('createEvent.aboutEventTitle'),
      },
      authorUser: {
        val: authorUser || authorGroup || authorTeam,
        label: t('createEvent.postAs'),
      },
    };

    const anyEmptyField = Object.values(requiredFields).find(
      (field) => !field.val,
    );

    if (anyEmptyField) {
      Snackbar.show(
        t('createEvent.fieldRequired', {
          fieldName: anyEmptyField.label,
        }),
      );
      return;
    }

    try {
      setIsSubmitting(true);
      const { data } = await createEvent({
        variables: {
          input: { ...input },
        },
      });

      if (data?.createEvent?.errors) {
        Snackbar.show(data?.createEvent?.errors[0]?.messages[0] as string);
      }
      const eventId = data?.createEvent?.event?.id;
      if (!eventId) {
        const messages = data?.createEvent?.errors?.map(
          (error) => error?.messages[0],
        );
        const errorMessage = messages?.[0] as string;

        Snackbar.show(errorMessage);
        return;
      }

      const bannerImageUri = input.bannerImage;
      if (bannerImageUri) {
        const imageFile = bannerImageUri
          ? new ReactNativeFile({
              uri: bannerImageUri,
              type: 'image/jpeg',
              name: getFileName(bannerImageUri),
            })
          : null;
        await setEventBanner({
          variables: {
            input: {
              id: eventId,
              bannerImage: imageFile,
            },
          },
        });
      }

      navigation.goBack();
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <CreateEventLayout
      teams={teams}
      groupsUser={filteredGroupUsers}
      loggedUserGroup={authUserGroup}
      loading={submitting}
      onBack={() => navigation.goBack()}
      onCreateEvent={onCreateEvent}
      authUserId={authUserId}
    />
  );
}
