/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation ReportMutation
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  ReportMutation,
  ReportMutationVariables,
} from './types/ReportMutation';
import { REPORT_MUTATION } from './';

export function useReportMutationMutation(
  options?: MutationHookOptions<ReportMutation, ReportMutationVariables>,
): MutationTuple<ReportMutation, ReportMutationVariables> {
  return useMutation<ReportMutation, ReportMutationVariables>(
    REPORT_MUTATION,
    options,
  );
}

export function createReportMutationMock({
  variables,
  data,
  error,
}: {
  variables?: ReportMutationVariables;
  data?: ReportMutation;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: REPORT_MUTATION,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
