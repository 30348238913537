import { Link } from '@react-navigation/native';
import styled from 'styled-components/native';
import IconStyled from '~/components/IconStyled';
import SchoolTypePicker from '~/components/SchoolTypePicker';
import { ResponsiveProps } from '~/components/common/style.web';

export const InstituteLink = styled(Link)`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.brand_02};
  font-size: 16px;
  line-height: 20px;
  user-select: text;
`;

export const InstituteNameContainer = styled.View`
  flex-shrink: 1;
`;

export const Spinner = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c4,
  size: 'large',
}))`
  margin: 20px 0;
`;

export const InstituteIconContainer = styled.View<ResponsiveProps>`
  width: ${({ isDesktop }) => (isDesktop ? 130 : 64)}px;
  height: ${({ isDesktop }) => (isDesktop ? 130 : 64)}px;
  border-radius: 12px;
  border-width: ${({ isDesktop }) => (isDesktop ? 5 : 2)}px;
  border-color: ${({ theme }) => theme.color.base.c3};
  background-color: ${({ theme }) => theme.color.base.c2};
  justify-content: center;
  align-items: center;
`;

export const InstituteIcon = styled(IconStyled).attrs(({ theme }) => ({
  name: 'icn/teams/building',
  color: theme.color.brand_02,
}))``;

export const InstituteTypePicker = styled(SchoolTypePicker)``;
export const MarketTypePicker = styled(SchoolTypePicker)``;
