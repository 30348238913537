import { LinearGradient, LinearGradientProps } from 'expo-linear-gradient';
import styled from 'styled-components/native';
import Icon from '~/components/Icon';
import ImageComp from '~/components/Image';
import { byTypeHelper } from './helpers';
import { GroupCellType } from './types';

interface IVariations {
  checked: boolean;
  type: GroupCellType;
}
interface IType {
  type: GroupCellType;
}

export const PressableContainer = styled.Pressable`
  margin-bottom: 8px;
`;

export const LNContainer = styled(LinearGradient).attrs<
  IVariations,
  LinearGradientProps
>(({ type, checked, theme }) => ({
  colors: byTypeHelper.bgColors(type, checked, theme),
  start: { x: 0, y: 0 },
  end: { x: 1, y: 0 },
}))<IVariations>`
  border-radius: 5px;
  padding: 1px;
`;

export const Container = styled.View<IVariations>`
  background-color: ${({ type, theme, checked }) =>
    byTypeHelper.bgColor(type, theme, checked)};
  padding-vertical: 16px;
  padding-horizontal: 16px;
  border-radius: 5px;
  flex-direction: row;
  align-items: center;
`;

export const Image = styled(ImageComp)`
  width: 56px;
  height: 56px;
  border-radius: 5px;
  border-width: 2px;
  border-color: ${({ theme }) => theme.color.base.c2};
`;

export const Content = styled.View`
  flex: 1;
  padding-left: 16px;
`;

export const Name = styled.Text<IType>`
  color: ${({ type, theme }) => byTypeHelper.tintColor(type, theme)};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
  line-height: 20px;
`;

export const Detail = styled.Text<IType>`
  color: ${({ type, theme }) => byTypeHelper.tintColor(type, theme)};
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 14px;
  line-height: 18px;
`;

export const ArrowIcon = styled(Icon).attrs<IVariations>(
  ({ type, theme, checked }) => ({
    size: 24,
    color: byTypeHelper.secTintColor(type, theme, checked),
  }),
)<IVariations>`
  padding-horizontal: 2px;
`;
