import styled from 'styled-components/native';

interface IContainer {
  width: number;
  selected: boolean;
}
export const Container = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})<IContainer>`
  background-color: ${({ theme }) => theme.color.base.c0};
  width: ${({ width }) => width}px;
  padding-vertical: 16px;
  border: 1px solid
    ${({ selected, theme }) =>
      selected ? theme.color.brand_02 : theme.color.base.c3};
  box-shadow: -3px 3px 2px ${({ theme }) => theme.color.base.c1};
  border-radius: 5px;
  align-items: center;
`;

interface IText {
  selected: boolean;
}
export const Text = styled.Text<IText>`
  margin-top: 8px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ selected, theme }) =>
    selected ? theme.color.brand_02 : theme.color.base.c7};
  font-size: 10px;
`;

export const FormatViewContainer = styled.View`
  width: 50px;
  height: 50px;
  justify-content: flex-end;
  align-items: center;
`;

interface IFormatView {
  width: number;
  height: number;
  selected: boolean;
  dark?: boolean;
  center?: boolean;
}
export const FormatView = styled.View<IFormatView>`
  position: absolute;
  background-color: ${({ dark, selected, theme }) =>
    selected
      ? dark
        ? theme.color.brand_02
        : theme.color.brand_02
      : dark
      ? theme.color.base.c7
      : theme.color.base.c5};
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  border-radius: 2px;
  ${({ center }) => center && 'align-self: center;'};
`;
