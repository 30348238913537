import { Dimensions } from 'react-native';
import styled from 'styled-components/native';

export const MAX_MODAL_HEIGHT = Dimensions.get('window').height - 100;
export const MAX_MODAL_WIDTH = 956;
export const MODAL_RADIUS = 35;
export const MODAL_HEIGHT = 625;
export const MODAL_EXTERNAL_PADDING = '32px 28px';

export const ModalBodyWrapper = styled.View`
  height: 100%;
  justify-content: space-between;
`;
