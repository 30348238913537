import React from 'react';
import { Platform, useWindowDimensions } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useTheme } from 'styled-components/native';
import Button from '~/components/Button';
import CommonIconCell, {
  CommonIconCellInfo,
} from '~/components/CommonIconCell';
import MobileWebHeader from '~/components/MobileWebHeader';
import NavHeader from '~/components/NavHeader';
import SectionHeader from '~/components/SectionHeader';
import TeamCard from '~/components/TeamCard';
import TrackingFlatlist from '~/components/TrackingFlatList';
import { AdsImage, AdsText } from '~/components/common/style';
import { BestMatchedAsset } from '~/data/models/campaign';
import { Post } from '~/data/models/post';
import { TeamSample, TeamUser } from '~/data/models/team';
import { ModuleInstanceResult } from '~/data/models/yearbook';
import { VariantTypeEnum } from '~/data/operations/global';
import useAdsTracking from '~/utils/hooks/useAdsTracking';
import { t } from '~/utils/i18n';
import {
  SafeAreaContainer,
  ScrollView,
  Section,
  SectionContent,
  TeamList,
  TeamListHeaderSeparator,
  TeamListSeparator,
  Content,
  EmptyText,
} from './style';

type PlanningLayoutProps = {
  teams: TeamSample[];
  loading: boolean;
  errorMessage?: string;
  authUserId: string;
  onSeeAllTeams: () => void;
  onSelectedTeam: (teamId: string) => void;
  onPoll: () => void;
  // onTodo: () => void;
  onCalendar: () => void;
  onLoadMoreTeams: () => void;
  onHelp: () => void;
  openBannersInBrowser: (url: string) => void;
};

export default function Planning({
  teams,
  loading,
  errorMessage,
  authUserId,
  onSeeAllTeams,
  onSelectedTeam,
  onPoll,
  onCalendar,
  onLoadMoreTeams,
  onHelp,
  openBannersInBrowser,
}: PlanningLayoutProps): JSX.Element {
  type PlanningCellOption = 'poll' | 'calendar';

  const planningCellInfos: {
    [key in PlanningCellOption]: CommonIconCellInfo;
  } = {
    poll: {
      text: t('g.polls'),
      mainIcon: 'horizontal-bar-chart-02',
      description: t('planningHome.pollDesc'),
    },
    calendar: {
      text: t('g.calendar'),
      mainIcon: 'calendar-date',
      description: t('planningHome.calendarDesc'),
    },
    // todo: {
    //   text: t('g.todo'),
    //   styledIcon: 'todo_base',
    //   disabled: true,
    // },
  };

  const { handleViewTracking, handleAdsClick, bestMatchedAsset, randomIndex } =
    useAdsTracking({ authUserId, variantType: VariantTypeEnum.SMALL });

  const renderTeamItem = ({ item: team }: { item?: TeamSample }) => (
    <TeamCard
      testID={`TeamCard:${team?.id}`}
      team={team}
      onPress={() => team && onSelectedTeam(team.id)}
    />
  );

  const renderItem = ({
    item,
    index,
  }: {
    item?: BestMatchedAsset | Post | TeamUser | ModuleInstanceResult;
    index: number;
  }) => {
    const post = item as BestMatchedAsset;
    return (
      <TouchableOpacity
        testID={`${index}:AdsCell:${post?.id}`}
        onPress={() => {
          handleAdsClick(
            post?.id as string,
            post?.channelData?.[0]?.trackingUrl as string,
            openBannersInBrowser,
          );
        }}
      >
        <AdsText>{t('asset.gratitude')}</AdsText>
        <AdsImage
          source={{ uri: post?.channelData?.[0]?.imageSmall as string }}
        />
      </TouchableOpacity>
    );
  };

  const window = useWindowDimensions();
  const theme = useTheme();
  const isMobileView = window.width < theme.breakpoints.desktopMin;
  const title = t('g.planning');

  const data = loading && teams.length == 0 ? [undefined, undefined] : teams;

  return (
    <SafeAreaContainer>
      {Platform.OS === 'web' && isMobileView ? (
        <MobileWebHeader title={title} />
      ) : (
        <NavHeader
          title={title}
          type={'main'}
          RightComponent={
            <Button
              text={t('getHelp')}
              testID="getHelp"
              size={'sm'}
              type={'secondary-base'}
              onPress={onHelp}
            />
          }
        />
      )}
      <ScrollView>
        <Section>
          <SectionHeader
            buttonTestID={'buttonSeeAllTeams'}
            title={t('planningHome.teamsSection')}
            buttonText={t('planningHome.showAll')}
            onButtonPress={onSeeAllTeams}
          />
        </Section>
        {(() => {
          if (errorMessage) {
            return (
              <Content>
                <EmptyText>{errorMessage}</EmptyText>
              </Content>
            );
          }
          return (
            <TeamList<React.ElementType>
              testID={'teamList'}
              horizontal
              data={data}
              renderItem={renderTeamItem}
              ListHeaderComponent={<TeamListHeaderSeparator />}
              ItemSeparatorComponent={() => <TeamListSeparator />}
              keyExtractor={(_: TeamSample | undefined, i: number) => `tc:${i}`}
              showsHorizontalScrollIndicator={false}
              onEndReachedThreshold={0.3}
              onEndReached={() => onLoadMoreTeams()}
            />
          );
        })()}
        <Section>
          <SectionHeader title={t('planningHome.organizeSection')} />
        </Section>
        <SectionContent>
          <CommonIconCell
            testID={'buttonCellPoll'}
            info={planningCellInfos['poll']}
            onPress={onPoll}
          />
          <CommonIconCell
            testID={'buttonCellCalendar'}
            info={planningCellInfos['calendar']}
            onPress={onCalendar}
          />
          {/* <CommonIconCell
            testID={'buttonCellTodo'}
            info={planningCellInfos['todo']}
            onPress={onTodo}
          /> */}
        </SectionContent>
        {bestMatchedAsset?.[randomIndex] !== undefined && (
          <TrackingFlatlist
            testID={'flatList'}
            handleViewTracking={handleViewTracking}
            data={[bestMatchedAsset[randomIndex]]}
            renderItem={renderItem}
            scrollEnabled={false}
          />
        )}
      </ScrollView>
    </SafeAreaContainer>
  );
}
