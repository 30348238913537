/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query Institute
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { Institute, InstituteVariables } from './types/Institute';
import { INSTITUTE } from './';

export function useInstituteQuery(
  options?: QueryHookOptions<Institute, InstituteVariables>,
): QueryResult<Institute, InstituteVariables> {
  return useQuery<Institute, InstituteVariables>(INSTITUTE, options);
}

export function useInstituteLazyQuery(
  options?: LazyQueryHookOptions<Institute, InstituteVariables>,
): QueryTuple<Institute, InstituteVariables> {
  return useLazyQuery<Institute, InstituteVariables>(INSTITUTE, options);
}

export function createInstituteMock({
  variables,
  data,
  error,
}: {
  variables?: InstituteVariables;
  data?: Institute;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: INSTITUTE,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
