import styled from 'styled-components/native';
import ButtonIcon from '~/components/ButtonIcon';
import Icon from '~/components/Icon';

export const Container = styled.View`
  flex-direction: row;
  background-color: ${({ theme }) => theme.color.base.c1};
  padding: 8px 16px;
  align-items: center;
`;

export const ItemView = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})`
  flex-direction: row;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.color.base.c2};
  padding: 6px 12px;
  align-items: center;
  margin-right: 16px;
`;

export const ItemText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.brand_02};
  font-size: 14px;
`;

export const ItemIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.color.brand_02,
  size: 20,
}))`
  margin-right: 8px;
`;

export const CloseView = styled.View`
  flex: 1;
  align-items: flex-end;
`;

export const CloseButtonIcon = styled(ButtonIcon).attrs(({ theme }) => ({
  color: theme.color.base.c6,
  size: 20,
  name: 'x-close',
}))``;
