/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query Post
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { Post, PostVariables } from './types/Post';
import { POST } from './';

export function usePostQuery(
  options?: QueryHookOptions<Post, PostVariables>,
): QueryResult<Post, PostVariables> {
  return useQuery<Post, PostVariables>(POST, options);
}

export function usePostLazyQuery(
  options?: LazyQueryHookOptions<Post, PostVariables>,
): QueryTuple<Post, PostVariables> {
  return useLazyQuery<Post, PostVariables>(POST, options);
}

export function createPostMock({
  variables,
  data,
  error,
}: {
  variables?: PostVariables;
  data?: Post;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: POST,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
