/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query ModuleInstanceResults
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  ModuleInstanceResults,
  ModuleInstanceResultsVariables,
} from './types/ModuleInstanceResults';
import { MODULE_INSTANCE_RESULTS } from './';

export function useModuleInstanceResultsQuery(
  options?: QueryHookOptions<
    ModuleInstanceResults,
    ModuleInstanceResultsVariables
  >,
): QueryResult<ModuleInstanceResults, ModuleInstanceResultsVariables> {
  return useQuery<ModuleInstanceResults, ModuleInstanceResultsVariables>(
    MODULE_INSTANCE_RESULTS,
    options,
  );
}

export function useModuleInstanceResultsLazyQuery(
  options?: LazyQueryHookOptions<
    ModuleInstanceResults,
    ModuleInstanceResultsVariables
  >,
): QueryTuple<ModuleInstanceResults, ModuleInstanceResultsVariables> {
  return useLazyQuery<ModuleInstanceResults, ModuleInstanceResultsVariables>(
    MODULE_INSTANCE_RESULTS,
    options,
  );
}

export function createModuleInstanceResultsMock({
  variables,
  data,
  error,
}: {
  variables?: ModuleInstanceResultsVariables;
  data?: ModuleInstanceResults;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: MODULE_INSTANCE_RESULTS,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
