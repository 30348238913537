import styled from 'styled-components/native';

export const MobileTitle = styled.Text`
  margin: 32px 0 16px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 22px;
  line-height: 26px;
`;

export const Content = styled.View`
  padding: 0 16px;
`;
