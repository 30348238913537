import React, { useEffect, useState } from 'react';
import AuthorPicker, {
  AuthorCommonType,
  AuthorTeamType,
} from '~/components/AuthorPicker';
import SectionHeader from '~/components/SectionHeader';
import { Team } from '~/data/models/team';
import { CollagesSetupConfig, ModuleInstance } from '~/data/models/yearbook';
import { t } from '~/utils/i18n';
import ModuleSetupLayout, {
  UpdateModuleInstanceInput,
} from '../../layout/ModuleSetupLayout';

export type UpdateCollagesSetupInput = {
  createAlbumsTeamId: string | null;
  addPhotosTeamId: string | null;
  viewPhotosTeamId: string | null;
};

export type CollagesSetupLayoutProps = {
  moduleInstance: ModuleInstance;
  collagesSetupConfig: CollagesSetupConfig;
  teams: Team[];
  onBack: () => void;
  onUpdateModuleInstance: (
    updateModuleInstanceInput: UpdateModuleInstanceInput,
  ) => void;
  onUpdateCollagesSetup: (
    updateCollagesSetupInput: UpdateCollagesSetupInput,
  ) => void;
  onLoadMoreTeams: () => void;
};

export default function CollagesSetup({
  moduleInstance,
  collagesSetupConfig,
  teams,
  onBack,
  onUpdateModuleInstance,
  onUpdateCollagesSetup,
  onLoadMoreTeams,
}: CollagesSetupLayoutProps): JSX.Element {
  const getInitialCreateAlbumsTeamId = () =>
    collagesSetupConfig.createAlbumsTeam?.id || null;

  const getInitialAddPhotosTeamId = () =>
    collagesSetupConfig.addPhotosTeam?.id || null;

  const getInitialViewPhotosTeamId = () =>
    collagesSetupConfig.viewPhotosTeam?.id || null;

  const [createAlbumsTeamId, setCreateAlbumsTeamId] = useState(
    getInitialCreateAlbumsTeamId(),
  );

  const [addPhotosTeamId, setAddPhotosTeamId] = useState(
    getInitialAddPhotosTeamId(),
  );

  const [viewPhotosTeamId, setViewPhotosTeamId] = useState(
    getInitialViewPhotosTeamId(),
  );

  const authorEverybody: AuthorCommonType = {
    id: 'all',
    authorType: 'common',
    info: {
      description: t('g.everybody'),
      styledIcon: 'everyone',
    },
  };

  const teamAuthors: AuthorTeamType[] = teams.map((team) => {
    return {
      id: team.id,
      authorType: 'team',
      team,
    };
  });

  const authors = [authorEverybody, ...teamAuthors];

  useEffect(() => {
    const updatedCreateAlbumsTeam =
      collagesSetupConfig &&
      createAlbumsTeamId != getInitialCreateAlbumsTeamId()
        ? true
        : false;

    const updatedAddPhotosTeamId =
      collagesSetupConfig && addPhotosTeamId != getInitialAddPhotosTeamId()
        ? true
        : false;

    const updatedViewPhotosTeamId =
      collagesSetupConfig && viewPhotosTeamId != getInitialViewPhotosTeamId()
        ? true
        : false;

    if (
      updatedCreateAlbumsTeam ||
      updatedAddPhotosTeamId ||
      updatedViewPhotosTeamId
    ) {
      onUpdateCollagesSetup({
        createAlbumsTeamId:
          createAlbumsTeamId === authorEverybody.id ? null : createAlbumsTeamId,
        addPhotosTeamId:
          addPhotosTeamId === authorEverybody.id ? null : addPhotosTeamId,
        viewPhotosTeamId:
          viewPhotosTeamId === authorEverybody.id ? null : viewPhotosTeamId,
      });
    }
  }, [createAlbumsTeamId, addPhotosTeamId, viewPhotosTeamId]);

  return (
    <ModuleSetupLayout
      title={t('screens.collagesSetup')}
      activateText={t('collagesSetup.moduleActivate')}
      moduleInstance={moduleInstance}
      onBack={onBack}
      onUpdateModuleInstance={onUpdateModuleInstance}
    >
      <>
        <SectionHeader
          sub
          title={t('collagesSetup.publicCreateAlbumsSection')}
        />
        <AuthorPicker
          testID={'createAlbumsTeamAuthorPicker'}
          authors={authors}
          selectedAuthorId={
            createAlbumsTeamId === null
              ? authorEverybody.id
              : createAlbumsTeamId
          }
          onSelectedAuthor={(authorId) =>
            setCreateAlbumsTeamId(
              authorId === authorEverybody.id ? null : authorId,
            )
          }
          onLoadMore={onLoadMoreTeams}
        />
        <SectionHeader sub title={t('collagesSetup.publicAddPhotosSection')} />
        <AuthorPicker
          testID={'addPhotosTeamAuthorPicker'}
          authors={authors}
          selectedAuthorId={addPhotosTeamId || authorEverybody.id}
          onSelectedAuthor={(authorId) => setAddPhotosTeamId(authorId)}
          onLoadMore={onLoadMoreTeams}
        />
        <SectionHeader sub title={t('collagesSetup.publicViewPhotosSection')} />
        <AuthorPicker
          testID={'viewPhotosTeamAuthorPicker'}
          authors={authors}
          selectedAuthorId={viewPhotosTeamId || authorEverybody.id}
          onSelectedAuthor={(authorId) => setViewPhotosTeamId(authorId)}
          onLoadMore={onLoadMoreTeams}
        />
      </>
    </ModuleSetupLayout>
  );
}
