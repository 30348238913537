import * as React from 'react';
import { useRef, useState } from 'react';
import InputField, { InputFieldHandle } from '~/components/InputField';
import {
  TouchView,
  ForgotPasswordText,
  InputContent,
} from '~/screens/Auth/Login/layout/style';
import validateField from '~/screens/Auth/utils/validateField';
import { IMPORTER_TOTAL_PROGRESS } from '~/screens/Importer/constants';
import ImporterLayout from '~/screens/Importer/layout';
import { t } from '~/utils/i18n';
import { isValidEmail, isValidPassword } from '~/utils/validators';

type AbihomeLoginLayoutProps = {
  loading: boolean;
  onBack: () => void;
  onForgotPassword: () => void;
  onNext: (email: string, password: string) => void;
};

export default function AbihomeLoginLayout({
  loading,
  onBack,
  onForgotPassword,
  onNext,
}: AbihomeLoginLayoutProps): JSX.Element {
  const emailRef = useRef<InputFieldHandle>(null);
  const passwordRef = useRef<InputFieldHandle>(null);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const isValidInput = (): boolean => {
    return isValidEmail(email) && isValidPassword(password);
  };

  const { state: emailState, info: emailInfo } = validateField({
    field: email,
    isValidField: isValidEmail,
  });

  const { state: passwordState, info: passwordInfo } = validateField({
    field: password,
    isValidField: isValidPassword,
  });

  const handleNext = () => {
    if (isValidInput()) {
      onNext(email, password);
    }
  };

  const headerProps = {
    title: t('importer.loginTitle'),
    descp: t('importer.loginDescp'),
    total: IMPORTER_TOTAL_PROGRESS,
    progress: 1,
  };

  const submitButtonProps = {
    isValidInput,
    loading,
    onPress: handleNext,
    text: t('importer.loginToAbihome'),
  };

  return (
    <ImporterLayout
      headerProps={headerProps}
      submitButtonProps={submitButtonProps}
      onBack={onBack}
    >
      <InputContent>
        <InputField
          ref={emailRef}
          testID={'inputEmail'}
          state={emailState}
          iconName={'mail-01'}
          label={t('login.emailLabel')}
          placeholder={t('login.emailPlaceholder')}
          value={email}
          infoLabel={emailInfo}
          onChangeText={(value) => setEmail(value)}
          blurOnSubmit={false}
          returnKeyType={'next'}
          autoCapitalize={'none'}
          onSubmitEditing={() => passwordRef.current?.focus()}
        />
        <InputField
          ref={passwordRef}
          testID={'inputPassword'}
          state={passwordState}
          iconName={'lock-02'}
          label={t('login.passwordLabel')}
          placeholder={t('login.passwordPlaceholder')}
          value={password}
          infoLabel={passwordInfo}
          onChangeText={(value) => setPassword(value)}
          blurOnSubmit={false}
          returnKeyType={'next'}
          autoCapitalize={'none'}
          handleSecureTextEntry
          onSubmitEditing={() => handleNext()}
        />
        <TouchView
          testID={'buttonForgotPassword'}
          onPress={() => onForgotPassword()}
        >
          <ForgotPasswordText isWeb>
            {t('login.forgotPassword')}
          </ForgotPasswordText>
        </TouchView>
      </InputContent>
    </ImporterLayout>
  );
}
