import { Platform } from 'react-native';
import styled from 'styled-components/native';
import ButtonIcon from '~/components/ButtonIcon';
import Icon from '~/components/Icon';
import { webContainerCss } from '~/components/WebGrid';
import { NavHeaderStyle, NavHeaderType } from './types';

interface INavHeaderType {
  colorStyle: NavHeaderStyle;
}

interface ILeft {
  type: NavHeaderType;
}

interface ITitle extends INavHeaderType {
  type: NavHeaderType;
  textAlign: string;
}

interface IButtonIcon extends INavHeaderType {
  name: string;
}

export const Container = styled.View`
  height: ${Platform.OS !== 'web' && '48px'};
  flex-direction: row;
  padding-top: ${Platform.OS === 'web' ? 56 : 8}px;
  align-items: center;
  padding-horizontal: ${Platform.OS === 'web' ? 0 : 16}px;
  ${Platform.OS === 'web' && `${webContainerCss} padding-bottom: 28px;`};
`;

export const Left = styled.View<ILeft>`
  width: ${({ type }) =>
    type === 'main' || Platform.OS === 'web' ? 'auto' : '80px'};
  ${Platform.OS === 'web' && 'margin-top: -8px'};
`;

export const LeftSideWrapper = styled.View<ILeft>`
  width: ${({ type }) =>
    type === 'main' || Platform.OS === 'web' ? 'auto' : '80px'};
  ${Platform.OS === 'web' &&
  `
    margin-top: -8px;
  `};
`;

export const Right = styled.View`
  width: 80px;
  justify-content: flex-end;
  flex-direction: row;
`;

export const BackButton = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})`
  height: 24px;
  width: ${Platform.OS === 'web' ? 'auto' : '80px'};
  flex-direction: row;
  align-items: center;
`;

export const BackIcon = styled(Icon).attrs<INavHeaderType>(
  ({ theme, colorStyle }) => ({
    size: 24,
    color:
      colorStyle === 'default' ? theme.color.brand_02 : theme.color.base.c0,
  }),
)<INavHeaderType>`
  padding-right: 4px;
`;

export const Title = styled.Text<ITitle>`
  flex: 1;
  text-align: ${({ type, textAlign }) =>
    type === 'main' ? 'left' : textAlign};
  color: ${({ colorStyle, theme }) =>
    colorStyle === 'light' ? theme.color.base.c0 : theme.color.base.c9};
  font-family: ${({ type, theme }) =>
    type === 'main' ? theme.fontFamily.core.w600 : theme.fontFamily.core.w600};
  font-size: ${({ type }) => (type === 'main' ? '32px' : '17px')};
  height: ${({ type }) => (type === 'main' ? '41px' : '22px')};
  margin-top: ${({ type }) => (type === 'main' ? '-8px' : '0px')};
`;

export const SmallerTitle = styled(Title)`
  font-size: ${({ type }) => (type === 'main' ? '22px' : '10px')};
  height: ${({ type }) => (type === 'main' ? '26px' : '12px')};
`;

export const FirstButtonIcon = styled(ButtonIcon).attrs<IButtonIcon>(
  ({ name, colorStyle, theme }) => ({
    name,
    size: 24,
    color: colorStyle === 'light' ? theme.color.base.c0 : theme.color.base.c9,
  }),
)<IButtonIcon>``;

export const SecondButtonIcon = styled(ButtonIcon).attrs<IButtonIcon>(
  ({ name, colorStyle, theme }) => ({
    name,
    size: 24,
    color: colorStyle === 'light' ? theme.color.base.c0 : theme.color.base.c9,
  }),
)<IButtonIcon>`
  padding-left: 18px;
  padding-right: 2px;
`;

export const StyledButtonIcon = styled(ButtonIcon).attrs(({ theme }) => ({
  size: 24,
  color: theme.color.base.c9,
}))``;

export const RightComponentContainer = styled.View`
  position: absolute;
  right: ${Platform.OS === 'web' ? 2 : 16}px;
`;

export const LeftTextOnBackButton = styled.Text<INavHeaderType>`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme, colorStyle }) =>
    colorStyle === 'default' ? theme.color.brand_02 : theme.color.base.c0};
  font-size: 17px;
`;

export const MobileLeftSideWrapper = styled.View`
  width: 80px;
  flex-direction: row;
`;
