import React, { ForwardRefRenderFunction, forwardRef, useState } from 'react';
import WebModal, {
  WebModalHandler,
  WebModalProps,
} from '~/components/WebModal';
import {
  AdsTargetingFields,
  AdsTargetingFilterFields,
} from '~/data/models/admin';
import { Regions } from '~/data/models/campaign';
import { CreateBannerAssetInput } from '~/data/operations/global';
import { useGraduationTypesQuery } from '~/data/operations/group/graduationTypes';
import { useInstituteTypesQuery } from '~/data/operations/institute/instituteTypes';
import ChannelSelection from './ChannelSelection';
import CreateAssetStepper from './CreateAssetStepper';
import CreateDesign from './CreateDesign';
import CreateTargeting from './CreateTargeting';
import { ASSETS_CREATION_STEP } from './CreateTargeting/types';
import {
  MAX_MODAL_WIDTH,
  MODAL_EXTERNAL_PADDING,
  MODAL_RADIUS,
  MODAL_HEIGHT,
  ModalBodyWrapper,
} from './style';

export type CreateAssetsHandler = WebModalHandler;

export enum CHANNEL_TYPES {
  BANNER = 'banner',
}

type CreateAssetsProps = {
  campaingName?: string;
  onCancelAssetsCreate: () => void;
  onSubmitAsset: (input: Partial<CreateBannerAssetInput>) => Promise<void>;
  defaultOpen: boolean;
  targetingData?: AdsTargetingFields;
  targetingLoading?: boolean;
  filters: AdsTargetingFilterFields[];
  setFilters: React.Dispatch<React.SetStateAction<AdsTargetingFilterFields[]>>;
  regions: Regions[];
} & WebModalProps;

const CreateAssets: ForwardRefRenderFunction<
  WebModalHandler,
  CreateAssetsProps
> = (
  {
    campaingName,
    onCancelAssetsCreate,
    defaultOpen = false,
    targetingData,
    targetingLoading,
    filters,
    setFilters,
    onSubmitAsset,
    regions,
  },
  ref,
): JSX.Element => {
  const [assetsCreationStep, setAssetsCreationStep] =
    useState<ASSETS_CREATION_STEP>(ASSETS_CREATION_STEP.ChannelSelection);

  const [selectChannel, setSelectChannel] = useState<CHANNEL_TYPES>(
    CHANNEL_TYPES.BANNER,
  );
  const [trackingUrl, setTrackingUrl] = useState<string>('');
  const [biggerImageUri, setBiggerImageUri] = useState<string>('');
  const [smallerImageUri, setSmallerImageUri] = useState<string>('');

  const isContinueButtonDisabled =
    assetsCreationStep === ASSETS_CREATION_STEP.CreateAssetsDesign &&
    (biggerImageUri === '' || smallerImageUri === '' || trackingUrl === '');

  const addFilter = (filter: AdsTargetingFilterFields) => {
    setFilters((prevFilters) => [...prevFilters, filter]);
  };

  const updateFilter = (index: number, newFilter: AdsTargetingFilterFields) => {
    setFilters((prevFilters) =>
      prevFilters.map((filter, i) => (i === index ? newFilter : filter)),
    );
  };

  const removeFilter = (index: number) => {
    setFilters((filters) =>
      filters.filter((_, filterIndex) => filterIndex !== index),
    );
  };

  const onCancelAndClearAssetsCreate = () => {
    onCancelAssetsCreate();
    setFilters([]);
    setTrackingUrl('');
    setBiggerImageUri('');
    setSmallerImageUri('');
    setAssetsCreationStep(ASSETS_CREATION_STEP.ChannelSelection);
  };

  const { data: instituteTypes } = useInstituteTypesQuery({
    variables: {
      first: 50,
    },
  });

  const { data: graduationTypes } = useGraduationTypesQuery({
    variables: {
      first: 50,
    },
  });

  const renderAssetCreationStep = (step: ASSETS_CREATION_STEP) => {
    switch (step) {
      case ASSETS_CREATION_STEP.ChannelSelection:
        return (
          <ChannelSelection
            setSelectChannel={setSelectChannel}
            selectChannel={selectChannel}
          />
        );
      case ASSETS_CREATION_STEP.CreateTargeting:
        return (
          <CreateTargeting
            addFilter={addFilter}
            updateFilter={updateFilter}
            filters={filters}
            instituteTypes={instituteTypes}
            graduationTypes={graduationTypes}
            removeFilter={removeFilter}
            regions={regions}
            targetingData={targetingData}
            targetingLoading={targetingLoading}
          />
        );
      case ASSETS_CREATION_STEP.CreateAssetsDesign:
      default:
        return (
          <CreateDesign
            trackingUrl={trackingUrl}
            setTrackingUrl={setTrackingUrl}
            campaignName={campaingName ?? ''}
            setBiggerImageUri={setBiggerImageUri}
            setSmallerImageUri={setSmallerImageUri}
            biggerImageUri={biggerImageUri}
            smallerImageUri={smallerImageUri}
          />
        );
    }
  };

  return (
    <WebModal
      ref={ref}
      defaultOpen={defaultOpen}
      showCancelButton={false}
      sizeWidth={MAX_MODAL_WIDTH}
      sizeHeight={MODAL_HEIGHT}
      borderRadius={MODAL_RADIUS}
      padding={MODAL_EXTERNAL_PADDING}
    >
      <ModalBodyWrapper testID="createAssetModal">
        {renderAssetCreationStep(assetsCreationStep)}
        <CreateAssetStepper
          assetsCreationStep={assetsCreationStep}
          setAssetsCreationStep={setAssetsCreationStep}
          onCancelAssetsCreate={onCancelAndClearAssetsCreate}
          onSubmitAsset={() => {
            onSubmitAsset({
              trackingUrl: trackingUrl,
              assetSmallImage: smallerImageUri,
              assetLargeImage: biggerImageUri,
            });
            onCancelAndClearAssetsCreate();
          }}
          isContinueButtonDisabled={isContinueButtonDisabled}
        />
      </ModalBodyWrapper>
    </WebModal>
  );
};

export default forwardRef(CreateAssets);
