/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation CreateCampaign
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  CreateCampaign,
  CreateCampaignVariables,
} from './types/CreateCampaign';
import { CREATE_CAMPAIGN } from './';

export function useCreateCampaignMutation(
  options?: MutationHookOptions<CreateCampaign, CreateCampaignVariables>,
): MutationTuple<CreateCampaign, CreateCampaignVariables> {
  return useMutation<CreateCampaign, CreateCampaignVariables>(
    CREATE_CAMPAIGN,
    options,
  );
}

export function createCreateCampaignMock({
  variables,
  data,
  error,
}: {
  variables?: CreateCampaignVariables;
  data?: CreateCampaign;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: CREATE_CAMPAIGN,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
