import React, { useState } from 'react';
import Button from '~/components/Button';
import InputField from '~/components/InputField';
import NavHeader from '~/components/NavHeader';
import SectionHeader from '~/components/SectionHeader';
import TeamCell from '~/components/TeamCell';
import { Team } from '~/data/models/team';
import { t } from '~/utils/i18n';
import {
  Container,
  Header,
  TeamList,
  Content,
  Loading,
  EmptyText,
} from './style';

export type TeamsLayoutProps = {
  teams: Team[];
  loading: boolean;
  onBack: () => void;
  onSearchTeamChange: (searchingText: string) => void;
  onAddTeam: () => void;
  onSelectedTeam: (team: Team) => void;
  onLoadMoreTeams: () => void;
  onHelp: () => void;
};

export default function Teams({
  teams,
  loading,
  onBack,
  onSearchTeamChange,
  onAddTeam,
  onSelectedTeam,
  onLoadMoreTeams,
  onHelp,
}: TeamsLayoutProps): JSX.Element {
  const [searchingTeam, setSearchingTeam] = useState<string>('');

  const renderTeamItem = ({ item: team }: { item?: Team }) => (
    <TeamCell
      testID={`TeamCell:${team?.id}`}
      team={team}
      customIconType={'noBg'}
      onCustomIconPress={() => team && onSelectedTeam(team)}
    />
  );

  const data =
    loading && teams.length == 0 ? [undefined, undefined, undefined] : teams;

  return (
    <Container>
      <NavHeader
        backButtonTestID={'buttonGoBack'}
        title={t('screens.teams')}
        onBackPress={onBack}
        RightComponent={
          <Button
            testID="getHelp"
            size="sm"
            text={t('getHelp')}
            type="secondary-base"
            onPress={onHelp}
          />
        }
      />
      <TeamList<React.ElementType>
        testID={'teamList'}
        ListHeaderComponent={
          <Header>
            <SectionHeader
              title={t('teams.teamsSection')}
              RightComponent={
                <Button
                  testID={'buttonAddTeam'}
                  text={t('teams.newTeam')}
                  onPress={onAddTeam}
                  size="sm"
                ></Button>
              }
            />
            <InputField
              testID={'inputTeam'}
              iconName={'search-md'}
              label={t('search')}
              placeholder={t('search')}
              value={searchingTeam}
              onChangeText={(value) => {
                setSearchingTeam(value);
                onSearchTeamChange(value);
              }}
              blurOnSubmit={false}
              returnKeyType={'done'}
            />
          </Header>
        }
        ListEmptyComponent={
          !loading && (
            <Content>
              <EmptyText>{t('teams.teamsEmpty')}</EmptyText>
            </Content>
          )
        }
        ListFooterComponent={
          <Content>{loading && teams.length > 0 && <Loading />}</Content>
        }
        data={data}
        renderItem={renderTeamItem}
        keyExtractor={(item?: Team, index?: number) => `${item?.id}${index}`}
        onEndReachedThreshold={0.3}
        onEndReached={() => onLoadMoreTeams()}
      />
    </Container>
  );
}
