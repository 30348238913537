import React from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { ReactNativeFile } from 'apollo-upload-client';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { useIntercom } from '~/context/intercom';
import { NO_PAG_LIST_OFFSET } from '~/data/constants';
import { GroupIcon } from '~/data/models/custom';
import { GraduationType } from '~/data/models/group';
import { useGroupIconsQuery } from '~/data/operations/custom/groupIcons';
import { UserType } from '~/data/operations/global';
import { GROUP_USERS } from '~/data/operations/group';
import { useCreateGroupMutation } from '~/data/operations/group/createGroup';
import { useSetGroupAvatarMutation } from '~/data/operations/group/setGroupAvatar';
import { useInstituteQuery } from '~/data/operations/institute/institute';
import { GroupStackParamList } from '~/navigation/types';
import { getFileName } from '~/utils/helpers';
import CreateGroupLayout, { CreateGroupInput } from './layout';

type CreateGroupNavProp = StackNavigationProp<
  GroupStackParamList,
  'CreateGroup'
>;
type CreateGroupRouteProp = RouteProp<GroupStackParamList, 'CreateGroup'>;

export default function CreateGroupScreen(): JSX.Element {
  const navigation = useNavigation<CreateGroupNavProp>();
  const { onHelpCenter } = useIntercom();
  const {
    params: { instituteId },
  } = useRoute<CreateGroupRouteProp>();

  const { authUserId } = useAuth();

  const { data: groupIconsData } = useGroupIconsQuery({
    variables: {
      first: NO_PAG_LIST_OFFSET,
    },
  });

  const groupIcons: GroupIcon[] =
    (groupIconsData?.groupIcons?.edges.map(
      (edge) => edge?.node,
    ) as GroupIcon[]) || [];

  const { data: instituteData } = useInstituteQuery({
    variables: {
      id: instituteId,
    },
  });
  const graduationTypes =
    (instituteData?.institute?.type.graduationTypes as GraduationType[]) || [];

  const [createGroup, { loading: createGroupLoading }] =
    useCreateGroupMutation();
  const [setGroupAvatar, { loading: setGroupAvatarLoading }] =
    useSetGroupAvatarMutation();

  async function onCreateGroup({
    name,
    typeId,
    year,
    avatarUri,
    iconId,
  }: CreateGroupInput) {
    try {
      const { data } = await createGroup({
        variables: {
          input: {
            name,
            type: typeId,
            year: parseInt(year),
            institute: instituteId,
            role: UserType.STUDENT,
          },
        },
        refetchQueries: [
          {
            query: GROUP_USERS,
            variables: {
              user: authUserId,
              first: NO_PAG_LIST_OFFSET,
            },
          },
        ],
      });

      const groupId = data?.createGroup?.group?.id;
      if (!groupId) {
        const messages = data?.createGroup?.errors?.map(
          (error) => error?.messages[0],
        );
        const errorMessage = messages?.[0] as string;
        Snackbar.show(errorMessage);
        return;
      }

      if (avatarUri || iconId) {
        const imageFile = avatarUri
          ? new ReactNativeFile({
              uri: avatarUri,
              type: 'image/jpeg',
              name: getFileName(avatarUri),
            })
          : null;
        await setGroupAvatar({
          variables: {
            input: {
              id: groupId,
              avatar: imageFile,
              iconId,
            },
          },
        });
      }

      navigation.navigate('CreateGroupSuccess', { groupId });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  }

  return (
    <CreateGroupLayout
      graduationTypes={graduationTypes}
      groupIcons={groupIcons}
      loading={createGroupLoading || setGroupAvatarLoading}
      onBack={() => navigation.goBack()}
      onHelp={onHelpCenter}
      onCreateGroup={(groupInput) => onCreateGroup(groupInput)}
    />
  );
}
