import styled from 'styled-components/native';
import IconStyled from '~/components/IconStyled';
import ImageComp from '~/components/Image';
import SchoolTypePicker from '~/components/SchoolTypePicker';
import SectionButton from '~/components/SectionButton';
import { ResponsiveProps } from '~/components/common/style.web';

export const AdIconContainer = styled.View<ResponsiveProps>`
  width: ${({ isDesktop }) => (isDesktop ? 130 : 64)}px;
  height: ${({ isDesktop }) => (isDesktop ? 130 : 64)}px;
  border-radius: 12px;
  border-width: ${({ isDesktop }) => (isDesktop ? 5 : 2)}px;
  border-color: ${({ theme }) => theme.color.base.c3};
  background-color: ${({ theme }) => theme.color.base.c2};
  justify-content: center;
  align-items: center;
`;

export const AdIcon = styled(IconStyled).attrs(({ theme }) => ({
  name: 'icn/teams/rocket',
  color: theme.color.brand_02,
}))``;

export const AdNameContainer = styled.View`
  flex-shrink: 1;
`;

export const StatisticsCardsContainer = styled.FlatList.attrs(() => ({
  contentContainerStyle: {
    columnGap: 16,
  },
}))`
  flex-direction: row;
  overflow-x: scroll;
`;

export const AssetsContainer = styled.FlatList`
  flex-direction: column;
  overflow-x: scroll;
`;

export const AssetNameContainer = styled.View`
  flex-direction: column;
`;

export const AssetName = styled.Text`
  color: ${({ theme }) => theme.color.base.c9};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
`;

export const AssetId = styled.Text`
  color: ${({ theme }) => theme.color.base.c6};
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 14px;
`;

export const AssetsHeader = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const AssetImage = styled(ImageComp)`
  background-color: ${({ theme }) => theme.color.base.c1};
  width: 70px;
  height: 40px;
  margin-right: 16px;
`;

export const SettingLabelContainer = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  padding: 16px;
  border-radius: 14px;
`;

export const AssetData = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const AssetRow = styled.TouchableOpacity`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-vertical: 18px;
  border-bottom-color: ${({ theme }) => theme.color.base.c3};
  border-bottom-width: 1px;
`;

export const AssetStatisticsContainer = styled.View`
  flex-direction: row;
`;

export const StatisticContainer = styled.View`
  flex-direction: column;
  padding-right: 10px;
`;

export const StatisticValue = styled.Text`
  color: ${({ theme }) => theme.color.base.c9};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 10px;
`;

export const StatisticName = styled.Text`
  color: ${({ theme }) => theme.color.base.c6};
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 10px;
`;

export const CampaignTypePicker = styled(SchoolTypePicker)``;

export const DeleteButton = styled(SectionButton)<ResponsiveProps>`
  text-align: center;
  justify-content: ${({ isDesktop }) => (isDesktop ? 'flex-start' : 'center')};
  width: 294px;
`;
