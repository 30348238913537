import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { POST } from '~/data/operations/post';
import { useCreatePollVoteMutation } from '~/data/operations/post/createPollVote';
import { useDeletePollVoteMutation } from '~/data/operations/post/deletePollVote';

type PollVoteHookProps = {
  onPollVote: (postId: string, optionId: string, revoteId?: string) => void;
};

const usePollVote = (): PollVoteHookProps => {
  const [createPollVote] = useCreatePollVoteMutation();
  const [deletePollVote] = useDeletePollVoteMutation();

  const { authUserGroupId } = useAuth();

  const onPollVote = async (
    postId: string,
    optionId: string,
    revoteId?: string,
  ) => {
    try {
      if (revoteId) {
        await deletePollVote({
          variables: {
            input: {
              pollOptionId: revoteId,
            },
          },
        });
      }
      await createPollVote({
        variables: {
          input: {
            pollOption: optionId,
          },
        },
        refetchQueries: [
          {
            query: POST,
            variables: {
              id: postId,
              authUserGroupId,
            },
          },
        ],
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };
  return {
    onPollVote,
  };
};

export default usePollVote;
