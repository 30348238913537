/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation DeleteCustomList
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  DeleteCustomList,
  DeleteCustomListVariables,
} from './types/DeleteCustomList';
import { DELETE_CUSTOM_LIST } from './';

export function useDeleteCustomListMutation(
  options?: MutationHookOptions<DeleteCustomList, DeleteCustomListVariables>,
): MutationTuple<DeleteCustomList, DeleteCustomListVariables> {
  return useMutation<DeleteCustomList, DeleteCustomListVariables>(
    DELETE_CUSTOM_LIST,
    options,
  );
}

export function createDeleteCustomListMock({
  variables,
  data,
  error,
}: {
  variables?: DeleteCustomListVariables;
  data?: DeleteCustomList;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: DELETE_CUSTOM_LIST,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
