import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { SupportStackParamList } from '~/navigation/types';
import WorkInProgress from '~/screens/Admin/WorkInProgress/layout';

const SupportStackNavigator = createStackNavigator<SupportStackParamList>();

export default function SupportStack(): JSX.Element {
  return (
    <SupportStackNavigator.Navigator
      initialRouteName={'Support'}
      screenOptions={{
        headerShown: false,
      }}
    >
      <SupportStackNavigator.Screen
        name={'Support'}
        component={WorkInProgress}
      />
    </SupportStackNavigator.Navigator>
  );
}
