import { Linking } from 'react-native';
import Snackbar from '~/components/Snackbar';

const useOpenInBrowser = () => {
  const openInBrowser = (url: string) => {
    Linking.openURL(url).catch((err) => {
      Snackbar.show(err.message);
    });
  };

  return { openInBrowser };
};

export default useOpenInBrowser;
