import React from 'react';
import Avatar from '~/components/Avatar';
import InputProgress from '~/components/InputProgress';
import { mapUser } from '~/data/mappers';
import { ProfilePageUserSummary } from '~/data/models/yearbook';
import { t } from '~/utils/i18n';
import {
  Container,
  Content,
  Name,
  InputProgressView,
  Descp,
  ArrowContent,
  ArrowIcon,
} from './style';

type UserSummaryCellProps = {
  testID?: string;
  profilePageUserSummary: ProfilePageUserSummary;
  totalQuestions: number;
  totalImages: number;
  onPress: () => void;
};

export default function UserSummaryCell({
  testID,
  profilePageUserSummary,
  totalQuestions,
  totalImages,
  onPress,
}: UserSummaryCellProps): JSX.Element {
  const mappedUser = mapUser(profilePageUserSummary.userGroup.user);
  const currentProgress =
    (profilePageUserSummary.questionsAnswered || 0) +
    (profilePageUserSummary.imagesUploaded || 0);
  const totalProgress = totalQuestions + totalImages;
  return (
    <Container testID={testID} onPress={onPress}>
      <Avatar size={48} userGroup={profilePageUserSummary.userGroup} />
      <Content>
        <Name>{mappedUser?.name}</Name>
        <InputProgressView>
          <InputProgress
            progress={currentProgress}
            total={totalProgress}
            fullWidth
          />
        </InputProgressView>
        <Descp>{`${
          profilePageUserSummary.questionsAnswered
        }/${totalQuestions} ${t('g.answers')} ${
          profilePageUserSummary.imagesUploaded
        }/${totalImages} ${t('g.images')}`}</Descp>
      </Content>
      <ArrowContent>
        <ArrowIcon />
      </ArrowContent>
    </Container>
  );
}
