/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation DeleteComment
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { DeleteComment, DeleteCommentVariables } from './types/DeleteComment';
import { DELETE_COMMENT } from './';

export function useDeleteCommentMutation(
  options?: MutationHookOptions<DeleteComment, DeleteCommentVariables>,
): MutationTuple<DeleteComment, DeleteCommentVariables> {
  return useMutation<DeleteComment, DeleteCommentVariables>(
    DELETE_COMMENT,
    options,
  );
}

export function createDeleteCommentMock({
  variables,
  data,
  error,
}: {
  variables?: DeleteCommentVariables;
  data?: DeleteComment;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: DELETE_COMMENT,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
