import React, { useState } from 'react';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { AdsTargetingFilterFields } from '~/data/models/admin';
import { CampaignDetails, Regions } from '~/data/models/campaign';
import { useInstitutesTargetingQuery } from '~/data/operations/admin/institutesTargeting';
import { useAssetQuery } from '~/data/operations/campaign/asset';
import { useCampaignQuery } from '~/data/operations/campaign/campaign';
import { useDeleteBannerAssetMutation } from '~/data/operations/campaign/deleteBannerAsset';
import { useDuplicateBannerAssetMutation } from '~/data/operations/campaign/duplicateBannerAsset';
import { useEditBannerAssetMutation } from '~/data/operations/campaign/editBannerAsset';
import { writeCampaignQuery } from '~/data/operations/campaign/helpers';
import { useRegionsQuery } from '~/data/operations/campaign/regions';
import { Asset } from '~/data/operations/campaign/types/Asset';
import {
  EditBannerAdAssetInput,
  TargetingFilterInput,
  DuplicateAssetInput,
  DeleteAssetInput,
} from '~/data/operations/global';
import { useGraduationTypesQuery } from '~/data/operations/group/graduationTypes';
import { useInstituteTypesQuery } from '~/data/operations/institute/instituteTypes';
import { AdminStackParamList, AdsStackParamList } from '~/navigation/types';
import { removeKeyFromObject } from '~/utils/helpers';
import { t } from '~/utils/i18n';
import { getImageBlob } from '../EditAd/utils';
import EditAssetLayout from './layout';

type EditAssetRouteProp = RouteProp<AdsStackParamList, 'EditAsset'>;
export default function EditAsset(): JSX.Element {
  type EditAdNavProp = StackNavigationProp<AdsStackParamList, 'EditAsset'>;
  const navigation = useNavigation<EditAdNavProp>();
  const [updateAsset] = useEditBannerAssetMutation();
  const [duplicateAsset] = useDuplicateBannerAssetMutation({
    update(cache, { data }) {
      const oldAssets = campaignData?.campaign?.assets ?? [];
      const newAsset = data?.duplicateAsset?.newAsset;
      const vars = { id: campaignId };

      const updatedAssets = newAsset
        ? [...oldAssets, newAsset]
        : [...oldAssets];

      if (!campaignData?.campaign) {
        return;
      }
      const updatedCampaign: CampaignDetails = {
        ...campaignData?.campaign,
        assets: updatedAssets,
      };

      writeCampaignQuery({
        cache,
        variables: vars,
        data: {
          campaign: updatedCampaign,
        },
      });
    },
  });

  const [deleteAsset] = useDeleteBannerAssetMutation({
    update(cache, { data }) {
      const deletedAsset = data?.deleteAsset?.assetId;
      const oldAssets = campaignData?.campaign?.assets ?? [];
      const vars = { id: campaignId };

      const updatedOldAssets = oldAssets.filter(
        (asset) => asset?.id !== deletedAsset,
      );
      if (!campaignData?.campaign) {
        return;
      }
      const updatedCampaign: CampaignDetails = {
        ...campaignData?.campaign,
        assets: updatedOldAssets,
      };

      writeCampaignQuery({
        cache,
        variables: vars,
        data: { campaign: updatedCampaign },
      });
    },
  });

  const {
    params: { id, campaignName, campaignId },
  } = useRoute<EditAssetRouteProp>();

  const { data: campaignData } = useCampaignQuery({
    variables: { id: campaignId },
  });

  const { data: assetData, loading: assetLoading } = useAssetQuery({
    variables: {
      id,
    },
  });
  const [filters, setFilters] = useState<AdsTargetingFilterFields[]>(
    assetData?.asset?.targetingFilters as AdsTargetingFilterFields[],
  );

  const { data: regionsData } = useRegionsQuery();

  const filteredTargetingFilters = removeKeyFromObject(
    filters ?? ({} as object),
    '__typename',
  );
  const { data: targetingData, loading: targetingLoading } =
    useInstitutesTargetingQuery({
      variables: {
        targetingFilters: filteredTargetingFilters,
      },
    });

  const { openDrawer } =
    useNavigation<DrawerNavigationProp<AdminStackParamList>>();

  const { data: instituteTypes } = useInstituteTypesQuery({
    variables: {
      first: 50,
    },
  });

  const { data: graduationTypes } = useGraduationTypesQuery({
    variables: {
      first: 50,
    },
  });

  // Function to prepare images for the API
  const prepareImagesForApi = async ({
    smallImage,
    largeImage,
  }: {
    smallImage: string;
    largeImage: string;
  }) => {
    const regex = /^(https|http)/;
    return {
      smallImage: regex.test(smallImage)
        ? null
        : await getImageBlob(smallImage),
      largeImage: regex.test(largeImage)
        ? null
        : await getImageBlob(largeImage),
    };
  };

  const onUpdateAsset = async ({
    id,
    visibility,
    startDate,
    endDate,
    assetLargeImage,
    assetSmallImage,
    targetingFilters,
    trackingUrl,
  }: EditBannerAdAssetInput) => {
    const filteredTargetingFilters = removeKeyFromObject(
      targetingFilters as object,
      '__typename',
    );
    const { smallImage, largeImage } = await prepareImagesForApi({
      smallImage: assetSmallImage,
      largeImage: assetLargeImage,
    });

    try {
      const { data } = await updateAsset({
        variables: {
          input: {
            id,
            visibility,
            startDate: startDate ? new Date(startDate).toISOString() : null,
            endDate: endDate ? new Date(endDate).toISOString() : null,
            assetLargeImage: largeImage,
            assetSmallImage: smallImage,
            targetingFilters:
              filteredTargetingFilters as TargetingFilterInput[],
            trackingUrl,
          },
        },
      });
      if (data) {
        Snackbar.show(t('editAsset.assetUpdated'));
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onDuplicateAsset = async ({ assetId }: DuplicateAssetInput) => {
    try {
      const { data } = await duplicateAsset({
        variables: {
          input: {
            assetId,
          },
        },
      });
      if (data) {
        Snackbar.show(t('editAsset.assetDuplicated'));
      }
      navigation.navigate('EditAd', { id: campaignId });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onDeleteAsset = async ({ assetId }: DeleteAssetInput) => {
    try {
      const { data } = await deleteAsset({
        variables: {
          input: {
            assetId,
          },
        },
      });
      if (data) {
        Snackbar.show(t('editAsset.assetDeleted'));
      }
      navigation.navigate('EditAd', { id: campaignId });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  return (
    <EditAssetLayout
      asset={assetData as Asset}
      campaignName={campaignName}
      onBack={() => navigation.goBack()}
      openDrawer={openDrawer}
      regions={regionsData?.regions as Regions[]}
      filters={filters as TargetingFilterInput[]}
      setFilters={setFilters}
      instituteTypes={instituteTypes}
      graduationTypes={graduationTypes}
      onUpdateAsset={onUpdateAsset}
      onDuplicateAsset={onDuplicateAsset}
      onDeleteAsset={onDeleteAsset}
      loading={assetLoading}
      targetingData={targetingData}
      targetingLoading={targetingLoading}
    />
  );
}
