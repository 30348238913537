import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useAuth } from '~/context/auth';
import useAppLocale from '~/data/hooks/useAppLocale';
import { RootStackParamList } from '~/navigation/types';
import SignUpSuccessLayout from './layout';

type SignUpSuccessNavProp = StackNavigationProp<
  RootStackParamList,
  'SignUpSuccess'
>;

export default function SignUpSuccess(): JSX.Element {
  const navigation = useNavigation<SignUpSuccessNavProp>();

  const { authUser, onLogout } = useAuth();

  const firstName = authUser?.firstName || '';

  const { locale, onChangeLocale } = useAppLocale({
    onReload: () => navigation.replace('SignUpSuccess'),
  });

  return (
    <SignUpSuccessLayout
      name={firstName}
      selectedLocale={locale}
      onJoinNow={() => {
        navigation.goBack();
        navigation.navigate('GroupStack', {
          screen: 'JoinSelectLocation',
        });
      }}
      onJoinLater={() => navigation.goBack()}
      onLogout={() => {
        navigation.goBack();
        onLogout();
      }}
      onSelectedLocale={onChangeLocale}
    />
  );
}
