import React from 'react';
import Text from '~/components/Text';
import { Container, Footer, Image } from './style';

type SplashProps = {
  version: string;
};
export default function Splash({ version }: SplashProps): JSX.Element {
  return (
    <Container>
      <Image
        source={require('~/assets/imgs/v2/brand_logo.png')}
        resizeMode="contain"
      />
      <Footer>
        <Text weight="w600">
          <Text color="c6" weight="w600">
            {'V '}
          </Text>
          {version}
        </Text>
      </Footer>
    </Container>
  );
}
