import { gql } from '@apollo/client';
import { COLOR_FIELDS, ICON_FIELDS } from '../custom';
import { GROUP_FIELDS } from '../group';
import { USER_FIELDS } from '../user';

export const TEAM_USER_FIELDS = gql`
  ${USER_FIELDS}
  fragment TeamUserFields on UserTeamNode {
    id
    isAdmin
    status
    avatar
    defaultAvatar
    user {
      ...UserFields
    }
    created
    modified
  }
`;

export const TEAM_FIELDS = gql`
  ${COLOR_FIELDS}
  ${ICON_FIELDS}
  fragment TeamFields on TeamNode {
    id
    name
    type
    approvalSetting
    color {
      ...ColorFields
    }
    icon {
      ...IconFields
    }
    isHidden
    created
  }
`;

export const TEAM_SAMPLE_FIELDS = gql`
  ${TEAM_FIELDS}
  ${TEAM_USER_FIELDS}
  fragment TeamSampleFields on TeamNode {
    ...TeamFields
    usersCount
    sampleMembers {
      ...TeamUserFields
    }
  }
`;

export const TEAM_DETAIL_FIELDS = gql`
  ${TEAM_FIELDS}
  ${TEAM_USER_FIELDS}
  fragment TeamDetailFields on TeamNode {
    ...TeamFields
    users {
      ...TeamUserFields
    }
  }
`;

export const TEAMS = gql`
  ${TEAM_FIELDS}
  query Teams(
    $after: String
    $first: Int!
    $name: String
    $group: ID
    $userGroupId: String
  ) {
    teams(
      after: $after
      first: $first
      name: $name
      group: $group
      userGroupId: $userGroupId
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...TeamFields
        }
      }
    }
  }
`;

export const TEAM_SAMPLES = gql`
  ${TEAM_SAMPLE_FIELDS}
  query TeamSamples($after: String, $first: Int!, $name: String, $group: ID) {
    teams(after: $after, first: $first, name: $name, group: $group) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...TeamSampleFields
        }
      }
    }
  }
`;

export const TEAM = gql`
  ${TEAM_FIELDS}
  query Team($id: ID!) {
    team(id: $id) {
      ...TeamFields
    }
  }
`;

export const TEAM_DETAIL = gql`
  ${TEAM_DETAIL_FIELDS}
  query TeamDetail($id: ID!) {
    team(id: $id) {
      ...TeamDetailFields
    }
  }
`;

export const ADD_USER_TO_TEAM = gql`
  ${TEAM_USER_FIELDS}
  ${TEAM_FIELDS}
  ${GROUP_FIELDS}
  mutation AddUserToTeam($input: AddUserToTeamInput!) {
    addUserToTeam(input: $input) {
      userTeam {
        ...TeamUserFields
      }
      teams {
        ...TeamFields
        avatar
        group {
          ...GroupFields
        }
      }
    }
  }
`;

export const REMOVE_USER_FROM_TEAM = gql`
  ${TEAM_USER_FIELDS}
  mutation RemoveUserFromTeam($input: RemoveUserFromTeamInput!) {
    removeUserFromTeam(input: $input) {
      userTeam {
        ...TeamUserFields
      }
    }
  }
`;

export const CREATE_TEAM = gql`
  ${TEAM_DETAIL_FIELDS}
  mutation CreateTeam($input: CreateTeamInput!) {
    createTeam(input: $input) {
      team {
        ...TeamDetailFields
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const UPDATE_TEAM = gql`
  ${TEAM_DETAIL_FIELDS}
  mutation UpdateTeam($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      team {
        ...TeamDetailFields
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const DESTROY_TEAM = gql`
  ${TEAM_DETAIL_FIELDS}
  mutation DestroyTeam($input: DestroyTeamInput!) {
    destroyTeam(input: $input) {
      team {
        ...TeamDetailFields
      }
      success
    }
  }
`;

export const ACTIVATE_TEAM = gql`
  ${TEAM_DETAIL_FIELDS}
  mutation ActivateTeam($input: ActivateTeamInput!) {
    activateTeam(input: $input) {
      team {
        ...TeamDetailFields
      }
      success
    }
  }
`;

export const ACCEPT_USER_IN_TEAM = gql`
  ${TEAM_USER_FIELDS}
  mutation AcceptUserInTeam($input: AcceptUserInTeamInput!) {
    acceptUserInTeam(input: $input) {
      userTeam {
        ...TeamUserFields
      }
    }
  }
`;

export const USER_TEAM = gql`
  ${TEAM_USER_FIELDS}
  query UserTeam($id: ID!) {
    userTeam(id: $id) {
      ...TeamUserFields
    }
  }
`;
