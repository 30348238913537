/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation AdminForgetPasswordLink
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  AdminForgetPasswordLink,
  AdminForgetPasswordLinkVariables,
} from './types/AdminForgetPasswordLink';
import { ADMIN_FORGET_PASSWORD_LINK } from './';

export function useAdminForgetPasswordLinkMutation(
  options?: MutationHookOptions<
    AdminForgetPasswordLink,
    AdminForgetPasswordLinkVariables
  >,
): MutationTuple<AdminForgetPasswordLink, AdminForgetPasswordLinkVariables> {
  return useMutation<AdminForgetPasswordLink, AdminForgetPasswordLinkVariables>(
    ADMIN_FORGET_PASSWORD_LINK,
    options,
  );
}

export function createAdminForgetPasswordLinkMock({
  variables,
  data,
  error,
}: {
  variables?: AdminForgetPasswordLinkVariables;
  data?: AdminForgetPasswordLink;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: ADMIN_FORGET_PASSWORD_LINK,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
