import styled from 'styled-components/native';

export const Container = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  padding: 32px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.color.base.c3};
  border-radius: 15px;
`;

export const PctText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 20px;
  text-align: center;
`;

export const Text = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 9px;
  text-align: center;
`;

export const Content = styled.View`
  padding: 8px;
`;
