import styled from 'styled-components/native';
import Icon from '../Icon';

export const Container = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})`
  background-color: ${({ theme }) => theme.color.base.c0};
  flex-direction: row;
  align-items: center;
  margin-horizontal: 16px;
  margin-bottom: 16px;
  padding: 16px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.color.base.c3};
  border-radius: 14px;
  box-shadow: -3px 3px 2px ${({ theme }) => theme.color.base.c1};
`;

export const IconContent = styled.View`
  width: 56px;
  height: 56px;
  background-color: ${({ theme }) => theme.color.base.c1};
  border-radius: 14px;
  align-items: center;
  justify-content: center;
`;

export const ToDoItemIcon = styled(Icon).attrs(({ theme }) => ({
  size: 23,
  color: theme.color.brand_02,
}))``;

export const Text = styled.Text`
  flex: 1;
  padding-left: 16px;
  color: ${({ theme }) => theme.color.base.c9};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
  line-height: 20px;
`;

export const CounterBadge = styled.Text`
  width: 28px;
  height: 28px;
  text-align: center;
  text-align-vertical: center;
  border-radius: 14px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.brand_02};
  color: ${({ theme }) => theme.color.base.c0};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 12px;
  line-height: 28px;
`;

export const ArrowIcon = styled(Icon).attrs(({ theme }) => ({
  name: 'chevron-right',
  size: 16,
  color: theme.color.brand_02,
}))`
  padding-horizontal: 8px;
`;
