import * as React from 'react';
import {
  DrawerContentScrollView,
  DrawerContentComponentProps,
} from '@react-navigation/drawer';
import { t } from '~/utils/i18n';
import { getRouteMeta } from '../utils/getRouteMeta';
import {
  Logo,
  DrawerItem,
  DrawerIcon,
  DrawerLabel,
  FocusedItemIndicator,
  CloseButtonIcon,
} from './style';

interface CustomDrawerContentProps extends DrawerContentComponentProps {
  isMobile: boolean;
}

export default function CustomDrawerContent(
  props: CustomDrawerContentProps,
): JSX.Element {
  const { state, navigation, isMobile } = props;
  return (
    <DrawerContentScrollView {...props}>
      {!isMobile && <Logo>{t('gradoo')}</Logo>}
      {isMobile && <CloseButtonIcon onPress={navigation.closeDrawer} />}
      {state.routes.map((route, index) => {
        const {
          isFocused: isActive,
          routeName,
          iconName,
          label,
        } = getRouteMeta(state, route, index);

        const onPress = () => navigation.navigate(routeName);

        return (
          <DrawerItem key={routeName} onPress={onPress} isActive={isActive}>
            {isActive && <FocusedItemIndicator />}
            <DrawerIcon name={iconName} />
            <DrawerLabel>{label}</DrawerLabel>
          </DrawerItem>
        );
      })}
    </DrawerContentScrollView>
  );
}
