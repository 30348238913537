import styled from 'styled-components/native';

export const Container = styled.View<{ isCompact: boolean }>`
  flex-direction: ${({ isCompact }) => (isCompact ? 'column' : 'row')}
  flex-wrap: wrap;
  gap: 32px;

  padding-left: 24px;
  padding-right: 48px;
`;

export const LeftView = styled.View<{ isCompact: boolean }>`
  ${({ isCompact }) => !isCompact && 'flex: 1'}
  flex-direction: row;
  flex-wrap: wrap;
`;

export const RightView = styled.View<{ isCompact: boolean }>`
  ${({ isCompact }) => isCompact && 'margin-top: 32px'}
  flex-direction: row;
  gap: 24px;
`;

export const FooterText = styled.Text.attrs({
  numberOfLines: 1,
})`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c7};
  font-size: 16px;
  line-height: 24px;
`;

export const VSeparator = styled.View<{ color?: 'light' | 'dark' }>`
  margin-vertical: 2px;
  width: 1px;
  background-color: ${({ theme, color }) =>
    color === 'light' ? theme.color.base.c0 : theme.color.base.c5};
  margin-horizontal: 8px;
`;

export const LinkView = styled.TouchableOpacity.attrs({ activeOpacity: 0.9 })``;
