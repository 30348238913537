import styled from 'styled-components/native';
import ButtonIcon from '~/components/ButtonIcon';
import Image from '~/components/Image';

export const Container = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})`
  flex-direction: row;
  align-items: center;
  padding: 16px;
  background-color: ${({ theme }) => theme.color.base.c1};
  border-radius: 15px;
`;

export const FlagView = styled.View`
  width: 42px;
  height: 42px;
  border-radius: 5px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.color.brand_02};
  align-items: center;
  justify-content: center;
`;

export const FlagImage = styled(Image)`
  height: 15px;
  width: 24px;
`;

export const Name = styled.Text`
  flex: 1;
  padding-left: 16px;
  color: ${({ theme }) => theme.color.brand_02};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
  line-height: 20px;
`;

export const SelectedButtonIcon = styled(ButtonIcon).attrs(({ theme }) => ({
  name: 'refresh-ccw-02',
  size: 24,
  color: theme.color.brand_02,
}))`
  margin-left: 8px;
`;
