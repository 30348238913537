import styled from 'styled-components/native';
import ButtonIcon from '~/components/ButtonIcon';

export const PaddedContainer = styled.View`
  padding-horizontal: 16px;
`;

export const Container = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})`
  padding-vertical: 16px;
  border-color: ${({ theme }) => theme.color.base.c1};
  border-bottom-width: 1px;
  flex-direction: row;
  align-items: center;
`;

export const EmojiContainer = styled.View`
  background-color: ${({ theme }) => theme.color.base.c2};
  height: 42px;
  width: 42px;
  margin-right: 8px;
  border-radius: 21px;
  align-items: center;
  justify-content: center;
`;

export const EmojiText = styled.Text`
  font-size: 20px;
`;

export const Content = styled.View`
  flex: 1;
`;

export const QuestionText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 14px;
`;

export const QuestionType = styled.Text`
  margin-top; 8px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 10px;
`;

export const AddButtonIcon = styled(ButtonIcon).attrs(({ theme }) => ({
  name: 'plus',
  size: 16,
  color: theme.color.base.c9,
}))``;
