import { BottomTabBarProps } from '@react-navigation/bottom-tabs';
import { DrawerContentComponentProps } from '@react-navigation/drawer';
import { Route } from '@react-navigation/native';
import { IconName } from '~/components/Icon';
import { AdminStackParamList } from '~/navigation/types';
import { t } from '~/utils/i18n';

type AdminTabName = keyof AdminStackParamList;

type TabIconName = 'search-md' | 'user-03';

const tabIcons: { [key in AdminTabName]: TabIconName } = {
  SearchStack: 'search-md',
  InstitutesStack: 'user-03',
  AdsStack: 'user-03',
  ReportingStack: 'user-03',
  SupportStack: 'user-03',
  SettingsStack: 'user-03',
};

const getRouteLabel = (routeName: AdminTabName): string => {
  const tabLabels: { [key in AdminTabName]: string } = {
    SearchStack: t('g.search'),
    InstitutesStack: t('g.institutes'),
    AdsStack: t('g.ads'),
    ReportingStack: t('g.reporting'),
    SupportStack: t('g.support'),
    SettingsStack: t('g.settings'),
  };

  return tabLabels[routeName];
};

interface IRouteMeta {
  isFocused: boolean;
  routeName: string;
  iconName: IconName;
  label: string;
}

export const getRouteMeta = (
  state: DrawerContentComponentProps['state'] | BottomTabBarProps['state'],
  route: Route<string>,
  index: number,
): IRouteMeta => {
  const isFocused = state.index === index;
  const routeName = route.name as AdminTabName;
  const tabIcon = tabIcons[routeName];
  const iconName: IconName = `${tabIcon}`;
  const label = getRouteLabel(routeName);

  return {
    isFocused,
    routeName,
    iconName,
    label,
  };
};
