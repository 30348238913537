import { Animated } from 'react-native';
import Swipeable from 'react-native-swipeable';
import styled from 'styled-components/native';
import Avatar from '~/components/Avatar';
import ButtonIcon from '~/components/ButtonIcon';
import Icon from '~/components/Icon';

export const SWIPEABLE_BUTTON_WIDTH = 92;

export const Container = styled.View``;

const SwipeableContainer = styled(Swipeable)`
  overflow: hidden;
`;

export const AnimatedSwipeableContainer =
  Animated.createAnimatedComponent(SwipeableContainer);

export const DeleteButton = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})`
  flex: 1;
  width: ${SWIPEABLE_BUTTON_WIDTH}px;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  padding-right: 16px;
`;

export const DeleteIcon = styled(Icon).attrs(({ theme }) => ({
  name: 'x-close',
  size: 16,
  color: theme.color.error,
}))``;

export const DeleteText = styled.Text`
  color: ${({ theme }) => theme.color.error};
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 12px;
`;

export const Content = styled.View`
  background-color: ${({ theme }) => theme.color.base.c7};
  border-radius: 14px;
  padding: 16px;
  margin-horizontal: 16px;
  margin-bottom: 16px;
`;

interface IText {
  size?: number;
}

export const Text = styled.Text.attrs<IText>(({ size }) => ({
  size: size || 22,
}))<IText>`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c0};
  font-size: ${({ size }) => size}px;
`;

export const FooterContainer = styled.View`
  flex-direction: row;
  align-items: flex-start;
`;

export const AuthorContainer = styled.View`
  flex: 1;
  flex-direction: row;
  margin-top: 8px;
`;

interface IAuthor {
  hidden?: boolean;
}

export const AuthorAvatar = styled(Avatar)<IAuthor>`
  margin-top: 2px;
  margin-right: 8px;
  opacity: ${({ hidden }) => (hidden ? '0.5' : '1.0')};
`;

export const AuthorName = styled.Text<IAuthor>`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c0};
  font-size: 16px;
  opacity: ${({ hidden }) => (hidden ? '0.5' : '1.0')};
`;

export const AuthorSeparator = styled.View`
  width: 120px;
  border: 1px ${({ theme }) => theme.color.base.c0};
  margin-top: 32px;
`;

export const LikeContainer = styled.View`
  align-items: center;
`;

interface ILikeButtonIcon {
  checked: boolean;
}

export const LikeButtonIcon = styled(ButtonIcon).attrs<ILikeButtonIcon>(
  ({ theme, checked }) => ({
    name: 'thumbs-up',
    size: 24,
    color: checked ? theme.color.base.c0 : theme.color.brand_02,
    checked,
  }),
)<ILikeButtonIcon>`
  background-color: ${({ theme, checked }) =>
    checked ? theme.color.brand_02 : theme.color.base.c0};
  align-self: flex-end;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  align-items: center;
  justify-content: center;
`;

export const LikeText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c0};
  font-size: 10px;
  margin-top: 4px;
`;
