import styled from 'styled-components/native';
import { ModalScreenContainer } from '~/screens/style';

export const Container = styled(ModalScreenContainer)`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const ScrollView = styled.ScrollView``;

export const InputContainer = styled.View`
  margin-top: 32px;
  margin-bottom: 32px;
  padding-horizontal: 16px;
`;

export const Footer = styled.View`
  padding: 16px;
`;
