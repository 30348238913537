import React from 'react';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import moment from 'moment';
import Snackbar from '~/components/Snackbar';
import { useCreateCampaignMutation } from '~/data/operations/campaign/createCampaign';
import { CreateCampaignInput } from '~/data/operations/global';
import { AdminStackParamList, AdsStackParamList } from '~/navigation/types';
import AddCampaignLayout from './layout';

type AddCampaignNavProp = StackNavigationProp<AdsStackParamList, 'AddCampaign'>;

export default function AddCampaign(): JSX.Element {
  const { openDrawer } =
    useNavigation<DrawerNavigationProp<AdminStackParamList>>();

  const navigation = useNavigation<AddCampaignNavProp>();

  const [createCampaign] = useCreateCampaignMutation();

  const onCreateCampaign = async ({
    name,
    utm,
    startDate,
    endDate,
    type,
    customer,
    orderId,
  }: CreateCampaignInput) => {
    try {
      const { data } = await createCampaign({
        variables: {
          input: {
            name,
            utm,
            startDate: moment(startDate.dateString).toISOString(),
            endDate: moment(endDate.dateString).toISOString(),
            type,
            customer,
            orderId,
          },
        },
      });

      if (data !== null) {
        navigation.navigate('EditAd', {
          id: data?.createCampaign?.campaign?.id as string,
        });
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };
  return (
    <AddCampaignLayout
      onBack={() => navigation.goBack()}
      openDrawer={openDrawer}
      onCreateCampaign={onCreateCampaign}
    />
  );
}
