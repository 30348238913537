/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation UpdateProfilePageSetup
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  UpdateProfilePageSetup,
  UpdateProfilePageSetupVariables,
} from './types/UpdateProfilePageSetup';
import { UPDATE_PROFILE_PAGE_SETUP } from './';

export function useUpdateProfilePageSetupMutation(
  options?: MutationHookOptions<
    UpdateProfilePageSetup,
    UpdateProfilePageSetupVariables
  >,
): MutationTuple<UpdateProfilePageSetup, UpdateProfilePageSetupVariables> {
  return useMutation<UpdateProfilePageSetup, UpdateProfilePageSetupVariables>(
    UPDATE_PROFILE_PAGE_SETUP,
    options,
  );
}

export function createUpdateProfilePageSetupMock({
  variables,
  data,
  error,
}: {
  variables?: UpdateProfilePageSetupVariables;
  data?: UpdateProfilePageSetup;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: UPDATE_PROFILE_PAGE_SETUP,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
