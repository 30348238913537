import React from 'react';
import Icon, { IconName } from '~/components/Icon';
import IconStyled, { IconStyledName } from '~/components/IconStyled';
import { Container, Loading } from './style';

type ButtonIconProps = {
  testID?: string;
  name?: IconName;
  styledName?: IconStyledName;
  size?: number;
  color?: string;
  loading?: boolean;
  onPress: () => void;
};

export default function ButtonIcon({
  testID,
  name,
  styledName,
  size,
  color,
  loading,
  onPress,
  ...style
}: ButtonIconProps): JSX.Element {
  return (
    <Container
      {...style}
      testID={testID}
      onPress={() => onPress && onPress()}
      disabled={!!loading}
    >
      {loading ? (
        <Loading color={color} />
      ) : name ? (
        <Icon name={name} size={size} color={color} />
      ) : styledName ? (
        <IconStyled name={styledName} size={size} color={color} />
      ) : null}
    </Container>
  );
}
