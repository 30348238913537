import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import ForgotPassword from '~/screens/Auth/ForgotPassword';
import ForgotPasswordInstruction from '~/screens/Auth/ForgotPasswordInstruction';
import Launch from '~/screens/Auth/Launch';
import Login from '~/screens/Auth/Login';
import ResetPassword from '~/screens/Auth/ResetPassword';
import ResetPasswordSuccess from '~/screens/Auth/ResetPasswordSuccess';
import SignUpContact from '~/screens/Auth/SignUpContact';
import SignUpName from '~/screens/Auth/SignUpName';
import SignUpPassword from '~/screens/Auth/SignUpPassword';
import SignUpTerm from '~/screens/Auth/SignUpTerm';
import { AuthStackParamList } from '../../types';

const AuthStackNavigator = createStackNavigator<AuthStackParamList>();

export default function AuthStack(): JSX.Element {
  return (
    <AuthStackNavigator.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <AuthStackNavigator.Screen
        name={'Launch'}
        component={Launch}
        options={{ animationTypeForReplace: 'pop', animationEnabled: true }}
      />
      <AuthStackNavigator.Screen name={'Login'} component={Login} />
      <AuthStackNavigator.Screen name={'SignUpName'} component={SignUpName} />
      <AuthStackNavigator.Screen
        name={'SignUpContact'}
        component={SignUpContact}
      />
      <AuthStackNavigator.Screen
        name={'SignUpPassword'}
        component={SignUpPassword}
      />
      <AuthStackNavigator.Screen name={'SignUpTerm'} component={SignUpTerm} />
      <AuthStackNavigator.Screen
        name={'ForgotPassword'}
        component={ForgotPassword}
      />
      <AuthStackNavigator.Screen
        name={'ForgotPasswordInstruction'}
        component={ForgotPasswordInstruction}
      />
      <AuthStackNavigator.Screen
        name={'ResetPassword'}
        component={ResetPassword}
      />
      <AuthStackNavigator.Screen
        name={'ResetPasswordSuccess'}
        component={ResetPasswordSuccess}
      />
    </AuthStackNavigator.Navigator>
  );
}
