import React, { useState } from 'react';
import Carousel, { Pagination } from 'react-native-snap-carousel';
import { useTheme } from 'styled-components/native';
import {
  NotificationTeamRequestCell,
  NotificationGroupRequestCell,
} from '~/components/NotificationCell';
import { UserGroup } from '~/data/models/group';
import { Team, TeamUser } from '~/data/models/team';
import {
  Container,
  NotificationCellContainer,
  PaginationContainer,
  CONTAINER_WIDTH,
} from './style';

export type NotificationTeamRequestCarouselProps = {
  teamUsers: TeamUser[];
  team: Team;
  now?: Date;
  onDeclineUser: (userId: string) => void;
  onAcceptUser: (userId: string) => void;
  noPaginationMargin?: boolean;
};

export function NotificationTeamRequestCarousel({
  teamUsers,
  team,
  now = new Date(),
  onAcceptUser,
  onDeclineUser,
  noPaginationMargin,
}: NotificationTeamRequestCarouselProps): JSX.Element {
  const theme = useTheme();
  const [activeTeamUserIndex, setActiveTeamUserIndex] = useState(0);

  return (
    <Container>
      <Carousel
        data={teamUsers}
        renderItem={({ item: teamUser }: { item: TeamUser }) => (
          <NotificationCellContainer>
            <NotificationTeamRequestCell
              testID={`NotificationTeamRequestCell:${teamUser.id}`}
              teamUser={teamUser}
              team={team}
              now={now}
              onDecline={() => onDeclineUser(teamUser.user.id)}
              onAccept={() => onAcceptUser(teamUser.user.id)}
            />
          </NotificationCellContainer>
        )}
        sliderWidth={CONTAINER_WIDTH}
        itemWidth={CONTAINER_WIDTH}
        inactiveSlideScale={1.0}
        inactiveSlideOpacity={1.0}
        onSnapToItem={setActiveTeamUserIndex}
      />
      <PaginationContainer noPaginationMargin={noPaginationMargin}>
        <Pagination
          dotsLength={teamUsers.length}
          activeDotIndex={activeTeamUserIndex}
          containerStyle={{
            backgroundColor: theme?.color.base.c2,
            paddingHorizontal: 5,
            paddingVertical: 5,
            borderRadius: 10,
            marginTop: 8,
          }}
          dotContainerStyle={{
            marginHorizontal: 2,
          }}
          dotStyle={{
            height: 6,
            width: 6,
            borderRadius: 5,
            backgroundColor: theme?.color.base.c6,
          }}
          inactiveDotStyle={{
            height: 6,
            width: 6,
            borderRadius: 5,
            backgroundColor: theme?.color.base.c4,
          }}
          inactiveDotOpacity={1.0}
          inactiveDotScale={1.0}
        />
      </PaginationContainer>
    </Container>
  );
}

type NotificationGroupRequestCarouselProps = {
  userGroups: UserGroup[];
  loading: boolean;
  now?: Date;
  onDeclineUser: (userId: string) => void;
  onAcceptUser: (userId: string) => void;
  noPaginationMargin?: boolean;
};

export function NotificationGroupRequestCarousel({
  userGroups,
  loading,
  now = new Date(),
  onAcceptUser,
  onDeclineUser,
  noPaginationMargin,
}: NotificationGroupRequestCarouselProps): JSX.Element {
  const theme = useTheme();
  const [activeTeamUserIndex, setActiveTeamUserIndex] = useState(0);
  const data = loading && userGroups.length === 0 ? [undefined] : userGroups;

  return (
    <Container>
      <Carousel
        data={data}
        renderItem={({ item: userGroup }: { item?: UserGroup }) => (
          <NotificationCellContainer>
            <NotificationGroupRequestCell
              testID={`NotificationGroupRequestCell:${userGroup?.id}`}
              userGroup={userGroup}
              now={now}
              onDecline={() =>
                userGroup?.user.id && onDeclineUser(userGroup.user.id)
              }
              onAccept={() =>
                userGroup?.user.id && onAcceptUser(userGroup.user.id)
              }
            />
          </NotificationCellContainer>
        )}
        sliderWidth={CONTAINER_WIDTH}
        itemWidth={CONTAINER_WIDTH}
        inactiveSlideScale={1.0}
        inactiveSlideOpacity={1.0}
        onSnapToItem={setActiveTeamUserIndex}
      />
      <PaginationContainer noPaginationMargin={noPaginationMargin}>
        <Pagination
          dotsLength={userGroups.length}
          activeDotIndex={activeTeamUserIndex}
          containerStyle={{
            backgroundColor: theme?.color.base.c2,
            paddingHorizontal: 5,
            paddingVertical: 5,
            borderRadius: 10,
            marginTop: 8,
          }}
          dotContainerStyle={{
            marginHorizontal: 2,
          }}
          dotStyle={{
            height: 6,
            width: 6,
            borderRadius: 5,
            backgroundColor: theme?.color.base.c6,
          }}
          inactiveDotStyle={{
            height: 6,
            width: 6,
            borderRadius: 5,
            backgroundColor: theme?.color.base.c4,
          }}
          inactiveDotOpacity={1.0}
          inactiveDotScale={1.0}
        />
      </PaginationContainer>
    </Container>
  );
}
