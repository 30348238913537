import styled from 'styled-components/native';

export const CharCountContainer = styled.View`
  flex-direction: row;
  align-items: center;
  padding-bottom: 24px;
`;

export const ViewContainer = styled.View`
  flex: 1;
`;
