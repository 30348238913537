import { Picker } from '@react-native-picker/picker';
import styled from 'styled-components/native';

export const Container = styled.View``;

export const Header = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})`
  flex-direction: row;
  padding-top: 16px;
  padding-bottom: 8px;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.color.base.c3};
`;

export const TypeTitle = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 16px;
  line-height: 22px;
`;

export const TypeValue = styled.Text`
  flex: 1;
  margin-left: 4px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.brand_02};
  font-size: 16px;
  line-height: 22px;
  text-align: right;
`;

export const CountryPicker = styled(Picker).attrs(({ theme }) => ({
  itemStyle: {
    color: theme.color.brand_02,
    fontFamily: theme.fontFamily.core.w600,
  },
}))``;

export const WebContainer = styled.View`
  position: relative;
  width: 100%;
  padding: 16px 0 8px;
  flex-direction: row;
  justify-content: space-between;
`;

export const WebPicker = styled(Picker)`
  appearance: none;
  background-color: transparent;
  text-align: end;
  border: none;
  font-size: 16px;
  color: ${({ theme }) => theme.color.brand_02};
  cursor: pointer;
`;

export const Line = styled.View`
  border-bottom-width: 1px;
  border-color: ${({ theme }) => theme.color.base.c3};
`;
