import { ApolloCache } from '@apollo/client';
import {
  MODULE_INSTANCE_DETAIL_FIELDS,
  PROFILE_PAGE_SETUP_FIELDS,
  PROFILE_PAGE_QUESTION_FIELDS,
  PROFILE_PAGE_QUESTIONS,
  PROFILE_PAGE_PHOTO_CATEGORY_FIELDS,
  PROFILE_PAGE_PHOTO_CATEGORIES,
  PROFILE_PAGE_COMMENTS,
  COLLAGES_ALBUMS,
  REPORTS_INSTANCES,
  QUOTES_INSTANCES,
  QUOTE_FIELDS,
  RANKINGS_QUESTIONS,
  MODULE_INSTANCES,
  CUSTOM_LISTS,
  CUSTOM_LIST_OPTIONS,
  COLLAGES_PHOTOS,
  CUSTOM_PAGES_INSTANCES,
} from './';
import {
  CollagesAlbums,
  CollagesAlbumsVariables,
} from './types/CollagesAlbums';
import {
  CollagesPhotos,
  CollagesPhotosVariables,
} from './types/CollagesPhotos';
import {
  CustomListOptions,
  CustomListOptionsVariables,
} from './types/CustomListOptions';
import { CustomLists, CustomListsVariables } from './types/CustomLists';
import {
  CustomPagesInstances,
  CustomPagesInstancesVariables,
} from './types/CustomPagesInstances';
import { ModuleInstanceDetailFields } from './types/ModuleInstanceDetailFields';
import {
  ModuleInstances,
  ModuleInstancesVariables,
} from './types/ModuleInstances';
import {
  ProfilePageComments,
  ProfilePageCommentsVariables,
} from './types/ProfilePageComments';
import {
  ProfilePagePhotoCategoriesVariables,
  ProfilePagePhotoCategories,
} from './types/ProfilePagePhotoCategories';
import { ProfilePagePhotoCategoryFields } from './types/ProfilePagePhotoCategoryFields';
import { ProfilePageQuestionFields } from './types/ProfilePageQuestionFields';
import {
  ProfilePageQuestionsVariables,
  ProfilePageQuestions,
} from './types/ProfilePageQuestions';
import { ProfilePageSetupFields } from './types/ProfilePageSetupFields';
import { QuoteFields } from './types/QuoteFields';
import {
  QuotesInstances,
  QuotesInstancesVariables,
} from './types/QuotesInstances';
import {
  RankingsQuestions,
  RankingsQuestionsVariables,
} from './types/RankingsQuestions';
import {
  ReportsInstances,
  ReportsInstancesVariables,
} from './types/ReportsInstances';

export function readModuleInstanceDetailFragment({
  cache,
  id,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
}): ModuleInstanceDetailFields | null {
  return cache.readFragment<ModuleInstanceDetailFields>({
    id: `ModuleInstanceNode:${id}`,
    fragment: MODULE_INSTANCE_DETAIL_FIELDS,
    fragmentName: 'ModuleInstanceDetailFields',
  });
}

export function readModuleInstancesQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: ModuleInstancesVariables;
}): ModuleInstances | null {
  return cache.readQuery<ModuleInstances>({
    query: MODULE_INSTANCES,
    variables,
  });
}

export function writeModuleInstancesQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: ModuleInstancesVariables;
  data: ModuleInstances;
}): void {
  cache.writeQuery<ModuleInstances>({
    query: MODULE_INSTANCES,
    variables,
    data,
  });
}

export function readProfilePageSetupFragment({
  cache,
  id,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
}): ProfilePageSetupFields | null {
  return cache.readFragment<ProfilePageSetupFields>({
    id: `ProfilePageSetupNode:${id}`,
    fragment: PROFILE_PAGE_SETUP_FIELDS,
    fragmentName: 'ProfilePageSetupFields',
  });
}

export function readProfilePageQuestionFragment({
  cache,
  id,
  ppqUserGroup,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
  ppqUserGroup: string;
}): ProfilePageQuestionFields | null {
  return cache.readFragment<
    ProfilePageQuestionFields,
    { ppqUserGroup: string }
  >({
    id: `ProfilePageQuestionNode:${id}`,
    fragment: PROFILE_PAGE_QUESTION_FIELDS,
    fragmentName: 'ProfilePageQuestionFields',
    variables: { ppqUserGroup },
  });
}

export function writeProfilePageQuestionFragment({
  cache,
  id,
  ppqUserGroup,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
  ppqUserGroup: string;
  data: ProfilePageQuestionFields;
}): void {
  cache.writeFragment<ProfilePageQuestionFields, { ppqUserGroup: string }>({
    id: `ProfilePageQuestionNode:${id}`,
    fragment: PROFILE_PAGE_QUESTION_FIELDS,
    fragmentName: 'ProfilePageQuestionFields',
    variables: { ppqUserGroup },
    data,
  });
}

export function readProfilePagePhotoCategoryFragment({
  cache,
  id,
  ppqUserGroup,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
  ppqUserGroup: string;
}): ProfilePagePhotoCategoryFields | null {
  return cache.readFragment<
    ProfilePagePhotoCategoryFields,
    { ppqUserGroup: string }
  >({
    id: `ProfilePagePhotoCategoryNode:${id}`,
    fragment: PROFILE_PAGE_PHOTO_CATEGORY_FIELDS,
    fragmentName: 'ProfilePagePhotoCategoryFields',
    variables: { ppqUserGroup },
  });
}

export function writeProfilePagePhotoCategoryFragment({
  cache,
  id,
  ppqUserGroup,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
  ppqUserGroup: string;
  data: ProfilePagePhotoCategoryFields;
}): void {
  cache.writeFragment<ProfilePagePhotoCategoryFields, { ppqUserGroup: string }>(
    {
      id: `ProfilePagePhotoCategoryNode:${id}`,
      fragment: PROFILE_PAGE_PHOTO_CATEGORY_FIELDS,
      fragmentName: 'ProfilePagePhotoCategoryFields',
      variables: { ppqUserGroup },
      data,
    },
  );
}

export function readProfilePagePhotoCategoriesQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: ProfilePagePhotoCategoriesVariables;
}): ProfilePagePhotoCategories | null {
  return cache.readQuery<ProfilePagePhotoCategories>({
    query: PROFILE_PAGE_PHOTO_CATEGORIES,
    variables,
  });
}

export function writeProfilePagePhotoCategoriesQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: ProfilePagePhotoCategoriesVariables;
  data: ProfilePagePhotoCategories;
}): void {
  cache.writeQuery<ProfilePagePhotoCategories>({
    query: PROFILE_PAGE_PHOTO_CATEGORIES,
    variables,
    data,
  });
}

export function readProfilePageQuestionsQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: ProfilePageQuestionsVariables;
}): ProfilePageQuestions | null {
  return cache.readQuery<ProfilePageQuestions>({
    query: PROFILE_PAGE_QUESTIONS,

    variables,
  });
}

export function writeProfilePageQuestionsQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: ProfilePageQuestionsVariables;
  data: ProfilePageQuestions;
}): void {
  cache.writeQuery<ProfilePageQuestions>({
    query: PROFILE_PAGE_QUESTIONS,
    variables,
    data,
  });
}

export function readProfilePageCommentsQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: ProfilePageCommentsVariables;
}): ProfilePageComments | null {
  return cache.readQuery<ProfilePageComments>({
    query: PROFILE_PAGE_COMMENTS,
    variables,
  });
}

export function writeProfilePageCommentsQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: ProfilePageCommentsVariables;
  data: ProfilePageComments;
}): void {
  cache.writeQuery<ProfilePageComments>({
    query: PROFILE_PAGE_COMMENTS,
    variables,
    data,
  });
}

export function readCollagesAlbumsQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: CollagesAlbumsVariables;
}): CollagesAlbums | null {
  return cache.readQuery<CollagesAlbums>({
    query: COLLAGES_ALBUMS,
    variables,
  });
}

export function writeCollagesAlbumsQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: CollagesAlbumsVariables;
  data: CollagesAlbums;
}): void {
  cache.writeQuery<CollagesAlbums>({
    query: COLLAGES_ALBUMS,
    variables,
    data,
  });
}

export function readCollagesPhotosQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: CollagesPhotosVariables;
}): CollagesPhotos | null {
  return cache.readQuery<CollagesPhotos>({
    query: COLLAGES_PHOTOS,
    variables,
  });
}

export function writeCollagesPhotosQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: CollagesPhotosVariables;
  data: CollagesPhotos;
}): void {
  cache.writeQuery<CollagesPhotos>({
    query: COLLAGES_PHOTOS,
    variables,
    data,
  });
}

export function readReportsInstancesQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: ReportsInstancesVariables;
}): ReportsInstances | null {
  return cache.readQuery<ReportsInstances>({
    query: REPORTS_INSTANCES,
    variables,
  });
}

export function writeReportsInstancesQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: ReportsInstancesVariables;
  data: ReportsInstances;
}): void {
  cache.writeQuery<ReportsInstances>({
    query: REPORTS_INSTANCES,
    variables,
    data,
  });
}

export function readQuotesInstancesQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: QuotesInstancesVariables;
}): QuotesInstances | null {
  return cache.readQuery<QuotesInstances>({
    query: QUOTES_INSTANCES,
    variables,
  });
}

export function writeQuotesInstancesQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: QuotesInstancesVariables;
  data: QuotesInstances;
}): void {
  cache.writeQuery<QuotesInstances>({
    query: QUOTES_INSTANCES,
    variables,
    data,
  });
}

export function readQuotesInstanceFragment({
  cache,
  id,
  authUserGroupId,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
  authUserGroupId: string;
}): QuoteFields | null {
  return cache.readFragment<QuoteFields>({
    id: `QuotesInstanceNode:${id}`,
    fragment: QUOTE_FIELDS,
    fragmentName: 'QuoteFields',
    variables: { authUserGroupId },
  });
}

export function readRankingsQuestionsQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: RankingsQuestionsVariables;
}): RankingsQuestions | null {
  return cache.readQuery<RankingsQuestions>({
    query: RANKINGS_QUESTIONS,
    variables,
  });
}

export function writeRankingsQuestionsQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: RankingsQuestionsVariables;
  data: RankingsQuestions;
}): void {
  cache.writeQuery<RankingsQuestions>({
    query: RANKINGS_QUESTIONS,
    variables,
    data,
  });
}

export function readCustomListsQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: CustomListsVariables;
}): CustomLists | null {
  return cache.readQuery<CustomLists>({
    query: CUSTOM_LISTS,
    variables,
  });
}

export function writeCustomListsQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: CustomListsVariables;
  data: CustomLists;
}): void {
  cache.writeQuery<CustomLists>({
    query: CUSTOM_LISTS,
    variables,
    data,
  });
}

export function readCustomListOptionsQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: CustomListOptionsVariables;
}): CustomListOptions | null {
  return cache.readQuery<CustomListOptions>({
    query: CUSTOM_LIST_OPTIONS,
    variables,
  });
}

export function writeCustomListOptionsQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: CustomListOptionsVariables;
  data: CustomListOptions;
}): void {
  cache.writeQuery<CustomListOptions>({
    query: CUSTOM_LIST_OPTIONS,
    variables,
    data,
  });
}

export function readCustomPagesInstanceQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: CustomPagesInstancesVariables;
}): CustomPagesInstances | null {
  return cache.readQuery<CustomPagesInstances>({
    query: CUSTOM_PAGES_INSTANCES,
    variables,
  });
}

export function writeCustomPagesInstanceQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  data: CustomPagesInstances;
  variables?: CustomPagesInstancesVariables;
}): void {
  cache.writeQuery<CustomPagesInstances>({
    query: CUSTOM_PAGES_INSTANCES,
    variables,
    data,
  });
}
