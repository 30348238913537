/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query Statistics
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { Statistics, StatisticsVariables } from './types/Statistics';
import { STATISTICS } from './';

export function useStatisticsQuery(
  options?: QueryHookOptions<Statistics, StatisticsVariables>,
): QueryResult<Statistics, StatisticsVariables> {
  return useQuery<Statistics, StatisticsVariables>(STATISTICS, options);
}

export function useStatisticsLazyQuery(
  options?: LazyQueryHookOptions<Statistics, StatisticsVariables>,
): QueryTuple<Statistics, StatisticsVariables> {
  return useLazyQuery<Statistics, StatisticsVariables>(STATISTICS, options);
}

export function createStatisticsMock({
  variables,
  data,
  error,
}: {
  variables?: StatisticsVariables;
  data?: Statistics;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: STATISTICS,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
