/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation RemoveLikeFromPost
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  RemoveLikeFromPost,
  RemoveLikeFromPostVariables,
} from './types/RemoveLikeFromPost';
import { REMOVE_LIKE_FROM_POST } from './';

export function useRemoveLikeFromPostMutation(
  options?: MutationHookOptions<
    RemoveLikeFromPost,
    RemoveLikeFromPostVariables
  >,
): MutationTuple<RemoveLikeFromPost, RemoveLikeFromPostVariables> {
  return useMutation<RemoveLikeFromPost, RemoveLikeFromPostVariables>(
    REMOVE_LIKE_FROM_POST,
    options,
  );
}

export function createRemoveLikeFromPostMock({
  variables,
  data,
  error,
}: {
  variables?: RemoveLikeFromPostVariables;
  data?: RemoveLikeFromPost;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: REMOVE_LIKE_FROM_POST,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
