import React from 'react';
import { t } from '~/utils/i18n';
import {
  Container,
  ItemView,
  ItemText,
  ItemIcon,
  CloseView,
  CloseButtonIcon,
} from './style';

export type ToolbarProps = {
  testID?: string;
  allowImages?: boolean;
  onAddPhoto: () => void;
  onTextStyle: () => void;
  onClose: () => void;
};

export default function Toolbar({
  testID,
  allowImages,
  onAddPhoto,
  onTextStyle,
  onClose,
}: ToolbarProps): JSX.Element {
  return (
    <Container testID={testID}>
      {allowImages ? (
        <ItemView testID={'toolbarAddPhoto'} onPress={onAddPhoto}>
          <ItemIcon name={'camera-01'} />
          <ItemText>{t('g.addPhoto')}</ItemText>
        </ItemView>
      ) : null}
      <ItemView testID={'toolbarTextStyle'} onPress={onTextStyle}>
        <ItemIcon name={'share-07'} />
        <ItemText>{t('g.textStyle')}</ItemText>
      </ItemView>
      <CloseView>
        <CloseButtonIcon onPress={onClose} />
      </CloseView>
    </Container>
  );
}
