import React from 'react';
import {
  CompositeNavigationProp,
  useNavigation,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useAuth } from '~/context/auth';
import { useIntercom } from '~/context/intercom';
import useTeamSamples from '~/data/hooks/useTeamSamples';
import { RootStackParamList, YearbookStackParamList } from '~/navigation/types';
import ManageRequestLayout from './layout';

// TODO: add intercom to onHelp button.

type CombinedNavProp = CompositeNavigationProp<
  StackNavigationProp<YearbookStackParamList, 'ManageRequest'>,
  StackNavigationProp<RootStackParamList>
>;

export default function ManageRequest(): JSX.Element {
  const navigation = useNavigation<CombinedNavProp>();
  const { coreAdmin, authGroupId } = useAuth();
  const { teamSamples } = useTeamSamples();
  const { onHelpCenter } = useIntercom();

  const yearbookTeam = teamSamples
    .filter((t) => (coreAdmin ? true : !t.isHidden) && t.type === 'YEARBOOK')
    .sort((t1, t2) => (coreAdmin ? +t1.isHidden - +t2.isHidden : 0));

  return (
    <ManageRequestLayout
      onHelp={() => onHelpCenter()}
      onBack={() => navigation.goBack()}
      onRequestYearbookTeam={() => {
        navigation.popToTop();
        navigation.navigate('MainTab', {
          screen: 'PlanningStack',
          gid: authGroupId,
          params: {
            screen: 'TeamDetail',
            params: {
              teamId: yearbookTeam[0]?.id,
            },
          },
        });
      }}
    />
  );
}
