import React, { useEffect } from 'react';
import { Spinner } from '~/components/common/style.web';
import { DATABASE_ROWS_PER_PAGE_OPTIONS } from '~/data/constants';
import { Institute } from '~/data/models/institute';
import usePagination from '~/utils/hooks/usePagination';
import { t } from '~/utils/i18n';
import InputField from '../InputField';
import Paginator from '../Paginator';
import StepSizePicker from '../StepSizePicker';
import {
  Container,
  Title,
  TableCell,
  TableHeader,
  TableRow,
  HeaderSection,
  InstituteName,
  InstituteId,
  InstituteIconContainer,
  InstituteIcon,
  PaginationWrapper,
  InstituteTable,
  EmptyCell,
  CellText,
  TableHeaderText,
  SortIcon,
} from './style';

type InstituteDatabaseProps = {
  institutes: Institute[];
  institutesLoading: boolean;
  onOpenInstitutesGroup: (id: string) => void;
  institutesTotalCount: number;
  setCurrentActiveTab: (tab: number) => void;
  setFilterQuery: (value: string) => void;
  filterQuery: string;
  rowsPerPage?: number;
  setRowsPerPage?: (value: number) => void;
};

export default function InstituteDatabase({
  onOpenInstitutesGroup,
  institutes,
  institutesLoading,
  institutesTotalCount,
  setCurrentActiveTab,
  setFilterQuery,
  filterQuery,
  rowsPerPage = 5,
  setRowsPerPage = () => null,
}: InstituteDatabaseProps): JSX.Element {
  const {
    totalNumberOfPages,
    currentPage,
    allowNextPage,
    allowPrevPage,
    incrementPage,
    decrementPage,
    resetPagination,
    updatePage,
  } = usePagination({
    itemsPerPage: rowsPerPage,
    initialPage: 1,
    totalItems: institutesTotalCount,
  });

  useEffect(() => {
    setCurrentActiveTab(currentPage);
  }, [currentPage]);

  useEffect(() => {
    resetPagination();
  }, [filterQuery, rowsPerPage]);

  const renderTableHeader = () => (
    <TableRow noBorder>
      <EmptyCell />
      <TableCell>
        <TableHeader>
          <TableHeaderText>
            {t('adminPanelInstitutes.databaseInternalId')}
          </TableHeaderText>
          <SortIcon />
        </TableHeader>
      </TableCell>
      <TableCell>
        <TableHeader>
          <TableHeaderText>
            {t('adminPanelInstitutes.databaseType')}
          </TableHeaderText>
          <SortIcon />
        </TableHeader>
      </TableCell>
      <TableCell>
        <TableHeader>
          <TableHeaderText>
            {t('adminPanelInstitutes.databaseStreet')}
          </TableHeaderText>
          <SortIcon />
        </TableHeader>
      </TableCell>
      <TableCell>
        <TableHeader>
          <TableHeaderText>
            {t('adminPanelInstitutes.databaseZip')}
          </TableHeaderText>
          <SortIcon />
        </TableHeader>
      </TableCell>
      <TableCell>
        <TableHeader>
          <TableHeaderText>
            {t('adminPanelInstitutes.databaseCity')}
          </TableHeaderText>
          <SortIcon />
        </TableHeader>
      </TableCell>
    </TableRow>
  );

  const renderTableFooter = () => (
    <PaginationWrapper>
      <StepSizePicker
        stepSize={rowsPerPage}
        onStepSizeChange={setRowsPerPage}
        options={DATABASE_ROWS_PER_PAGE_OPTIONS}
        title={t('adminPanelSearch.entries')}
      />
      <Paginator
        allowNext={allowNextPage}
        allowPrev={allowPrevPage}
        currentPage={currentPage}
        onPrevClick={decrementPage}
        onNextClick={incrementPage}
        numberOfPages={totalNumberOfPages}
        onPageClick={updatePage}
      />
    </PaginationWrapper>
  );

  const renderTableRow = ({ item: institute }: { item: Institute }) => (
    <TableRow
      testID={`InstituteRow:${institute.id}`}
      onPress={() => onOpenInstitutesGroup(institute.id)}
    >
      <InstituteIconContainer>
        <InstituteIcon />
      </InstituteIconContainer>
      <TableCell>
        <InstituteName>{institute.name}</InstituteName>
        <InstituteId>
          {t(`adminPanelSearch.flags.${institute.country}`)}
          &nbsp;
          {institute.internalId}
        </InstituteId>
      </TableCell>
      <TableCell>
        <CellText>{institute.type.name}</CellText>
      </TableCell>
      <TableCell>
        <CellText>{institute.street}</CellText>
      </TableCell>
      <TableCell>
        <CellText>{institute.zip}</CellText>
      </TableCell>
      <TableCell>
        <CellText>{institute.city}</CellText>
      </TableCell>
    </TableRow>
  );

  return (
    <Container>
      <HeaderSection>
        <Title>{t('adminPanelInstitutes.database')}</Title>
        <InputField
          testID={'instituteSearchInput'}
          iconName={'search-md'}
          value={filterQuery}
          onChangeText={(query) => setFilterQuery(query)}
          placeholder={t('g.search')}
          size={'small'}
        />
      </HeaderSection>
      {institutesLoading ? (
        <Spinner testID="institutesSpinner" />
      ) : (
        <InstituteTable<React.ElementType>
          data={institutes}
          renderItem={renderTableRow}
          ListHeaderComponent={renderTableHeader}
          ListFooterComponent={renderTableFooter}
          keyExtractor={(institute: Institute) => institute.id}
        />
      )}
    </Container>
  );
}
