import { LineStyle, StyledText } from 'react-native-markdown-editor';
import styled from 'styled-components/native';
import { getLineFontFamily, getLineTextSize, getTextFont } from './utils';

export type IText = Omit<StyledText, 'text'>;

export const Text = styled.Text<IText>`
  color: ${({ theme }) => theme.color.base.c9};
  ${({ bold, italic, theme }) => getTextFont({ bold, italic, theme })};
  ${({ underline }) => underline && 'text-decoration: underline;'};
  ${({ strikethrough }) => strikethrough && 'text-decoration:  line-through;'};
`;

interface ILineText {
  lineStyle: LineStyle;
}

export const LineText = styled.Text<ILineText>`
  font-family: ${({ lineStyle, theme }) =>
    getLineFontFamily({ lineStyle, theme })};
  font-size: ${({ lineStyle }) => getLineTextSize(lineStyle)}px;
`;
