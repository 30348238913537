import React from 'react';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import {
  DEFAULT_ALBUM_PHOTOS_OFFSET,
  DEFAULT_LIST_OFFSET,
} from '~/data/constants';
import { useCreateCollagesAlbumMutation } from '~/data/operations/yearbook/createCollagesAlbum';
import {
  readCollagesAlbumsQuery,
  writeCollagesAlbumsQuery,
} from '~/data/operations/yearbook/helpers';
import { useUpdateCollagesAlbumMutation } from '~/data/operations/yearbook/updateCollagesAlbum';
import { YearbookStackParamList } from '~/navigation/types';
import CreateCollageLayout from './layout';

type CreateCollageNavProp = StackNavigationProp<
  YearbookStackParamList,
  'CreateCollage'
>;

type CreateCollageRouteProp = RouteProp<
  YearbookStackParamList,
  'CreateCollage'
>;

export default function CreateCollage(): JSX.Element {
  const navigation = useNavigation<CreateCollageNavProp>();
  const {
    params: { moduleInstanceId, collageAlbumId, currentText },
  } = useRoute<CreateCollageRouteProp>();

  const [createCollagesAlbum, { loading: createCollagesAlbumLoading }] =
    useCreateCollagesAlbumMutation({
      update(cache, { data }) {
        const newCollagesAlbum = data?.createCollagesAlbum?.collagesAlbum;

        const currentCollagesAlbumQuery = readCollagesAlbumsQuery({
          cache,
          variables: {
            after: null,
            first: DEFAULT_LIST_OFFSET,
            cpFirst: DEFAULT_ALBUM_PHOTOS_OFFSET,
            moduleInstance: moduleInstanceId,
          },
        });

        if (
          currentCollagesAlbumQuery &&
          currentCollagesAlbumQuery.collagesAlbums &&
          newCollagesAlbum
        ) {
          writeCollagesAlbumsQuery({
            cache,
            variables: {
              after: null,
              first: DEFAULT_LIST_OFFSET,
              cpFirst: DEFAULT_ALBUM_PHOTOS_OFFSET,
              moduleInstance: moduleInstanceId,
            },
            data: {
              ...currentCollagesAlbumQuery,
              collagesAlbums: {
                ...currentCollagesAlbumQuery.collagesAlbums,
                edges: [
                  ...currentCollagesAlbumQuery.collagesAlbums.edges,
                  {
                    __typename: 'CollagesAlbumNodeEdge',
                    node: {
                      ...newCollagesAlbum,
                      photosCount: 0,
                      collagesPhotos: {
                        __typename: 'CollagesPhotoNodeConnection',
                        edges: [],
                      },
                    },
                  },
                ],
              },
            },
          });
        }
      },
    });

  const [updateCollagesAlbum, { loading: updateCollagesAlbumLoading }] =
    useUpdateCollagesAlbumMutation();

  const onSave = async (text: string) => {
    try {
      if (collageAlbumId) {
        await updateCollagesAlbum({
          variables: {
            input: {
              id: collageAlbumId,
              name: text,
            },
          },
        });
      } else {
        await createCollagesAlbum({
          variables: {
            input: {
              moduleInstance: moduleInstanceId,
              name: text,
            },
          },
        });
      }

      navigation.goBack();
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  return (
    <CreateCollageLayout
      initialName={currentText}
      loading={createCollagesAlbumLoading || updateCollagesAlbumLoading}
      onBack={() => navigation.goBack()}
      onSave={onSave}
    />
  );
}
