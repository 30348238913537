import styled from 'styled-components/native';
import Icon from '~/components/Icon';
import TeamIcon from '~/components/TeamIcon';

interface IActionButton {
  liked?: boolean;
}

interface ITooltipButton {
  hasBorderBottom?: boolean;
}

export const Container = styled.View`
  padding: 0 16px;
`;

export const Header = styled.View`
  flex-direction: row;
  z-index: 1;
`;

export const AuthorTeamIcon = styled(TeamIcon)`
  border-radius: 8px;
`;

export const Content = styled.View`
  margin-left: 11px;
  flex-direction: column;
  border-left-color: ${({ theme }) => theme.color.base.c4};
  border-left-width: 1px;
  margin-top: -12px;
  padding-top: 8px;
  padding-bottom: 16px;
`;

export const HeaderText = styled.View`
  flex: 1;
  margin-left: 8px;
`;

export const CommentCard = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  elevation: 3;
  margin-left: 18px;
  border-radius: 5px;
  margin-top: 10px;
  padding: 16px;
`;

export const CommentText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 16px;
`;

export const Date = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 10px;
  line-height: 12px;
`;

export const Author = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 12px;
  line-height: 16px;
`;

export const OptionsIcon = styled(Icon).attrs(({ theme }) => ({
  name: 'dots-horizontal',
  size: 24,
  color: theme.color.base.c5,
}))`
  justify-content: center;
`;
export const TooltipButtonText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 16px;
  line-height: 20px;
`;

export const TOOLTIP_BUTTON_HEIGHT = 36;

export const TooltipButton = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})<ITooltipButton>`
  height: ${TOOLTIP_BUTTON_HEIGHT}px;
  padding-horizontal: 16px;
  justify-content: center;
  border-bottom-width: ${({ hasBorderBottom = true }) =>
    hasBorderBottom ? 1 : 0}px;
  border-color: ${({ theme }) => theme.color.brand_02};
`;

export const Footer = styled.View`
  flex-direction: row;
  align-items: center;
  padding-top: 24px;
`;

export const LikeCount = styled.Text<IActionButton>`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ liked, theme }) =>
    liked ? theme.color.error : theme.color.base.c5};
  padding: 2px 8px;
  font-size: 14px;
`;
