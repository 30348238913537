import React from 'react';
import { Container, TabCol, TabItemTitle } from './style';

export type TabItem = {
  key: string;
  title: string;
};

export type TabBarProps = {
  testID?: string;
  tabItems: TabItem[];
  selectedTabItemKey: string;
  tabColor?: string;
  onTabItemPress: (itemKey: string) => void;
};

export default function TabBar({
  testID,
  tabItems,
  selectedTabItemKey,
  tabColor,
  onTabItemPress,
  ...style
}: TabBarProps): JSX.Element {
  return (
    <Container testID={testID} {...style}>
      {tabItems.map((tabItem, index) => (
        <TabCol
          key={tabItem.key}
          hasTabSeparator={index < tabItems.length - 1}
          onPress={() => onTabItemPress(tabItem.key)}
        >
          <TabItemTitle
            selected={selectedTabItemKey === tabItem.key}
            tabColor={tabColor}
          >
            {tabItem.title}
          </TabItemTitle>
        </TabCol>
      ))}
    </Container>
  );
}
