import React, { useState } from 'react';
import Shimmer from '~/components/Shimmer';
import UnderlineButton from '~/components/UnderlineButton';
import { mapUser } from '~/data/mappers';
import { User } from '~/data/models/user';
import {
  RankingQuestionResult,
  RankingQuestionResults,
} from '~/data/models/yearbook';
import { t } from '~/utils/i18n';
import {
  Container,
  ImageContainer,
  Percentage,
  Picture,
  Progress,
  ProgressWrapper,
  Text,
  TextWrapper,
  ValuesContainer,
  Footer,
  FooterText,
} from './style';

type RankingQuestionResultCellProps = {
  rankingQuestionResult?: RankingQuestionResult;
};
function RankingQuestionResultCell({
  rankingQuestionResult,
}: RankingQuestionResultCellProps): JSX.Element {
  if (!rankingQuestionResult) {
    return (
      <Container>
        <ImageContainer>
          <Shimmer w={56} h={56} g={'g2'} />
        </ImageContainer>
        <ProgressWrapper>
          <Shimmer h={56} w={200} br={12} g={'g2'} />
        </ProgressWrapper>
      </Container>
    );
  }
  const formattedName = (user: User) => mapUser(user)?.name;

  const { votee1UserGroup, votee2UserGroup, voteeListOption, percentage } =
    rankingQuestionResult;
  const formattedPercentage = percentage ? percentage * 100 : 0;

  return (
    <Container>
      {votee1UserGroup ? (
        <ImageContainer>
          <Picture
            source={{
              uri:
                votee1UserGroup?.avatar ||
                votee1UserGroup?.defaultAvatar?.avatar ||
                '',
            }}
          />
          {votee2UserGroup && (
            <Picture
              source={{
                uri:
                  votee2UserGroup.avatar ||
                  votee2UserGroup.defaultAvatar?.avatar ||
                  '',
              }}
            />
          )}
        </ImageContainer>
      ) : null}
      <ProgressWrapper>
        <Progress percentage={formattedPercentage} />
        <ValuesContainer>
          <TextWrapper percentage={formattedPercentage}>
            <Text percentage={formattedPercentage} numberOfLines={1}>
              {votee1UserGroup?.user
                ? formattedName(votee1UserGroup.user)
                : voteeListOption?.text}
            </Text>
            {votee2UserGroup && (
              <Text percentage={formattedPercentage} numberOfLines={1}>
                {formattedName(votee2UserGroup.user)}
              </Text>
            )}
          </TextWrapper>
          <Percentage>{`${formattedPercentage.toFixed(0)}%`}</Percentage>
        </ValuesContainer>
      </ProgressWrapper>
    </Container>
  );
}

const RESULTS_PREVIEW_COUNT = 3;

type RankingQuestionResultsCellProps = {
  rankingQuestionResults?: RankingQuestionResults;
};

export default function RankingQuestionResultsCell({
  rankingQuestionResults,
}: RankingQuestionResultsCellProps): JSX.Element {
  if (!rankingQuestionResults) {
    return (
      <>
        <RankingQuestionResultCell rankingQuestionResult={undefined} />
        <Footer>
          <FooterText>
            <Shimmer />
          </FooterText>
        </Footer>
      </>
    );
  }

  const { options, alreadyVoted, possibleVoters } = rankingQuestionResults;
  const resultOptions = (options as RankingQuestionResult[]) || [];
  const sortedOptions = resultOptions
    .filter((op) => !!op)
    .sort((op1, op2) => Number(op2.percentage) - Number(op1.percentage));

  const hasMoreThanPreview = sortedOptions.length > RESULTS_PREVIEW_COUNT;
  const [showAll, setShowAll] = useState(false);

  const filteredResultOptions = sortedOptions.slice(
    0,
    showAll ? sortedOptions.length : RESULTS_PREVIEW_COUNT,
  );
  return (
    <>
      {filteredResultOptions.map((rankingQuestionResultOption, i) => (
        <RankingQuestionResultCell
          key={`rqr${i}`}
          rankingQuestionResult={rankingQuestionResultOption}
        />
      ))}
      <Footer>
        <FooterText>
          {t('g.voteInfo', {
            alreadyVoted,
            possibleVoters,
          })}
        </FooterText>
        {hasMoreThanPreview && (
          <UnderlineButton
            text={showAll ? t('g.showLess') : t('g.showAllVotes')}
            onPress={() => setShowAll(!showAll)}
          />
        )}
      </Footer>
    </>
  );
}
