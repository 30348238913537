import React from 'react';
import Button from '~/components/Button';
import GroupCell from '~/components/GroupCell';
import InputHeader from '~/components/InputHeader';
import NavHeader from '~/components/NavHeader';
import { Group, GroupSectionItem } from '~/data/models/group';
import { StackScreenContainer } from '~/screens/style';
import { t } from '~/utils/i18n';
import {
  GroupSecionList,
  SectionView,
  SectionTitle,
  Footer,
  Loading,
  EmptyText,
} from './style';

export type JoinSelectGroupLayoutProps = {
  inputProgressTotal: number;
  inputProgress: number;
  groupsByYear: GroupSectionItem[];
  loading: boolean;
  onBack: () => void;
  onSelectedGroup: (group: Group) => void;
  onCreateGroup: () => void;
  onLoadMore: () => void;
};

export default function JoinSelectGroup({
  inputProgressTotal,
  inputProgress,
  groupsByYear,
  loading,
  onBack,
  onSelectedGroup,
  onCreateGroup,
  onLoadMore,
}: JoinSelectGroupLayoutProps): JSX.Element {
  const renderItem = ({ item: group }: { item: Group }) => (
    <GroupCell group={group} onPress={() => onSelectedGroup(group)} />
  );

  return (
    <StackScreenContainer>
      <NavHeader backButtonTestID={'buttonBack'} onBackPress={onBack} />
      <GroupSecionList<React.ElementType>
        flat
        testID={'groupList'}
        ListHeaderComponent={
          <>
            <InputHeader
              title={t('joinSelectGroup.title')}
              descp={t('joinSelectGroup.descp')}
              total={inputProgressTotal}
              progress={inputProgress}
            />
          </>
        }
        ListEmptyComponent={
          <>{!loading && <EmptyText>{t('joinSelectGroup.empty')}</EmptyText>}</>
        }
        ListFooterComponent={loading && <Loading />}
        sections={groupsByYear}
        renderItem={renderItem}
        renderSectionHeader={({
          section: { title },
        }: {
          section: { title: string };
        }) => (
          <SectionView>
            <SectionTitle>{title}</SectionTitle>
          </SectionView>
        )}
        keyExtractor={(item: Group) => item.id}
        onEndReachedThreshold={0.3}
        onEndReached={() => onLoadMore()}
      />
      <Footer>
        <Button
          testID={'buttonCreateGroup'}
          text={t('joinSelectGroup.createGroup')}
          onPress={onCreateGroup}
          type="secondary-contrast"
          flex
          size="2xl"
        />
      </Footer>
    </StackScreenContainer>
  );
}
