import * as Localization from 'expo-localization';
import { I18n, Scope, TranslateOptions } from 'i18n-js';
import { LocaleConfig } from 'react-native-calendars';
import { getAppLocale } from '~/data/storage';
import de from './locales/de.json';
import en from './locales/en.json';
import tr from './locales/tr.json';

const i18n = new I18n({
  en,
  de,
  tr,
});

i18n.defaultLocale = 'en';
i18n.locale = Localization.locale;

i18n.enableFallback = true;

async function init(i18n: I18n) {
  const appLocale = await getAppLocale();
  if (appLocale && appLocale != 'auto') {
    i18n.locale = appLocale;
  }
}

export const updateLocale = (locale: string): void => {
  i18n.locale = locale;
};

init(i18n);

export const t = (scope: Scope, options?: TranslateOptions): string =>
  i18n.t(scope, options);

export const getI18nLocale = (): string => i18n.locale;

LocaleConfig.locales['en'] = {
  monthNames: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  monthNamesShort: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
  dayNames: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
  dayNamesShort: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
};

LocaleConfig.locales['de'] = {
  monthNames: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],
  monthNamesShort: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dez',
  ],
  dayNames: [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
  ],
  dayNamesShort: ['SO', 'MO', 'DI', 'MI', 'DO', 'FR', 'SA'],
};

LocaleConfig.defaultLocale = 'en';

/**
 * TODO:
 * - Interpolate values
 */
