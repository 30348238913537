import styled from 'styled-components/native';
import { ResponsiveProps } from '~/components/common/style.web';
import ButtonIcon from '../ButtonIcon';

export const MODAL_MAX_WIDTH = 800;
export const MODAL_MAX_HEIGHT = 600;
export const MODAL_SIDE_PADDING = 24;
const sizeToPx = (size: number) => `${size}px`;

interface ModileResponsiveProps extends ResponsiveProps {
  sizeWidth: number | undefined;
  sizeHeight: number | undefined;
  padding: string | undefined;
  borderRadius: number | undefined;
}
export const ModalBody = styled.View<ModileResponsiveProps>`
  background-color: ${({ theme }) => theme.color.base.c0};
  margin: ${({ isDesktop }) => (isDesktop ? 'auto' : 'auto 16px')};
  min-width: ${({ isDesktop, sizeWidth }) =>
    isDesktop && sizeWidth ? sizeToPx(sizeWidth) : 'auto'};
  min-height: ${({ isDesktop, sizeHeight }) =>
    isDesktop && sizeHeight ? sizeToPx(sizeHeight) : 'auto'};
  box-shadow: 9px 7px 18px rgba(100, 116, 139, 0.08);
  border-radius: ${({ borderRadius }) => borderRadius || 15}px;
  padding: ${({ padding }) =>
    padding ||
    `24px ${sizeToPx(
      MODAL_SIDE_PADDING,
    )} 64px`}; // Use padding prop or default value
  position: relative;
  z-index: 1;
`;

export const Backdrop = styled.View`
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
`;

export const CloseModalButton = styled(ButtonIcon).attrs(({ theme }) => ({
  name: 'x-close',
  size: 16,
  color: theme.color.brand_02,
}))`
  background-color: transparent;
  align-self: center;
  width: 28px;
  height: 28px;
  border-radius: 24px;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 32px;
  right: 32px;
  z-index: 2;
`;
