/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation DeletePoll
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { DeletePoll, DeletePollVariables } from './types/DeletePoll';
import { DELETE_POLL } from './';

export function useDeletePollMutation(
  options?: MutationHookOptions<DeletePoll, DeletePollVariables>,
): MutationTuple<DeletePoll, DeletePollVariables> {
  return useMutation<DeletePoll, DeletePollVariables>(DELETE_POLL, options);
}

export function createDeletePollMock({
  variables,
  data,
  error,
}: {
  variables?: DeletePollVariables;
  data?: DeletePoll;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: DELETE_POLL,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
