/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation AddUserToGroup
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  AddUserToGroup,
  AddUserToGroupVariables,
} from './types/AddUserToGroup';
import { ADD_USER_TO_GROUP } from './';

export function useAddUserToGroupMutation(
  options?: MutationHookOptions<AddUserToGroup, AddUserToGroupVariables>,
): MutationTuple<AddUserToGroup, AddUserToGroupVariables> {
  return useMutation<AddUserToGroup, AddUserToGroupVariables>(
    ADD_USER_TO_GROUP,
    options,
  );
}

export function createAddUserToGroupMock({
  variables,
  data,
  error,
}: {
  variables?: AddUserToGroupVariables;
  data?: AddUserToGroup;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: ADD_USER_TO_GROUP,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
