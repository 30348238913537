import { Dimensions } from 'react-native';
import styled from 'styled-components/native';
const { width } = Dimensions.get('window');

type PaginationContainerType = {
  noPaginationMargin?: boolean;
};

export const CONTAINER_WIDTH = width * 0.92;
export const LEFT_MARGIN_FOR_PAGINATION = (width - CONTAINER_WIDTH) / 2;
export const Container = styled.View`
  background-color: inherit;
  align-items: center;
`;

export const NotificationCellContainer = styled.View`
  width: ${CONTAINER_WIDTH}px;
`;

export const PaginationContainer = styled.View<PaginationContainerType>`
  align-self: start;
  margin-left: ${({ noPaginationMargin }) =>
    noPaginationMargin ? 0 : LEFT_MARGIN_FOR_PAGINATION}px;
`;
