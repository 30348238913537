import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components/native';
import ActivityOverview from '~/components/ActivityOverview';
import AdminHeader from '~/components/AdminHeader';
import Button from '~/components/Button';
import CountryTypePicker, { CountryType } from '~/components/CountryPicker';
import GroupsDatabase from '~/components/GroupsDatabase';
import InputField, { InputFieldHandle } from '~/components/InputField';
import { WebContainer } from '~/components/WebGrid';
import {
  FormContainer,
  FlexRowCentered,
  Spacer,
  Name,
  SectionLabel,
  FlexRow,
  GrownFlex,
  Container,
  InputWrapper,
  InputFormWrapper,
  InputContent,
} from '~/components/common/style.web';
import { COUNTRIES } from '~/data/constants';
import {
  EditInstituteActivityFields,
  EditInstituteFields,
  EditInstituteGroupFields,
} from '~/data/models/admin';
import { InstituteType } from '~/data/models/institute';
import { CountryInfo, MarketProfile } from '~/data/models/marketProfile';
import { AdminUpdateInstituteInput } from '~/data/operations/global';
import { t } from '~/utils/i18n';
import {
  allowToSaveChangesAllFieldsFilled,
  shouldAllowToSaveChanges,
} from '../../utils/FormUtils';
import {
  InstituteLink,
  Spinner,
  InstituteIconContainer,
  InstituteIcon,
  InstituteTypePicker,
  MarketTypePicker,
  InstituteNameContainer,
} from './style';

type InstituteAddLayoutProps = {
  onBack: () => void;
  groups: EditInstituteGroupFields[];
  institute?: EditInstituteFields;
  loading?: boolean;
  instituteActivity: EditInstituteActivityFields[];
  marketProfiles: MarketProfile[];
  instituteTypes: InstituteType[];
  onUpdateInstitute: (data: AdminUpdateInstituteInput) => void;
  openDrawer: () => void;
  onOpenGroups: (id: string) => void;
  onCountryChange?: (country: CountryType | undefined) => void;
};

export default function EditInstitute({
  onBack,
  groups,
  institute,
  instituteActivity,
  marketProfiles,
  instituteTypes,
  loading = false,
  onUpdateInstitute,
  openDrawer,
  onOpenGroups,
  onCountryChange,
}: InstituteAddLayoutProps): JSX.Element {
  const theme = useTheme();
  const isDesktop = useMediaQuery({ minWidth: theme.breakpoints.desktopMin });

  // ____FORM REFS____
  const instituteNameRef = useRef<InputFieldHandle>(null);
  const instituteStreetRef = useRef<InputFieldHandle>(null);
  const instituteZipRef = useRef<InputFieldHandle>(null);
  const instituteCityRef = useRef<InputFieldHandle>(null);
  const instituteWebsiteRef = useRef<InputFieldHandle>(null);
  const countryRef = useRef<CountryType | undefined>(undefined);

  // ____FORM STATE____
  const [instituteName, setInstituteName] = useState<string>('');
  const [instituteStreet, setInstituteStreet] = useState<string>('');
  const [instituteZip, setInstituteZip] = useState<string>('');
  const [instituteCity, setInstituteCity] = useState<string>('');
  const [instituteWebsite, setInstituteWebsite] = useState<string>('');

  const [instituteType, setInstituteType] = useState<InstituteType | undefined>(
    undefined,
  );

  const [marketProfileType, setMarketProfileType] = useState<
    MarketProfile | undefined
  >(undefined);

  useEffect(() => {
    if (!institute) {
      return;
    }

    setInstituteName(institute.name);
    setInstituteStreet(institute?.street || '');
    setInstituteZip(institute?.zip || '');
    setInstituteCity(institute?.city || '');
    setInstituteWebsite(institute?.website || '');
    if (!countryRef.current) {
      countryRef.current = (() => {
        const countryCodes = Object.keys(COUNTRIES);
        if (countryCodes.includes(institute.country)) {
          return COUNTRIES[institute.country as 'DE' | 'AT' | 'TR'];
        }

        return COUNTRIES.DE;
      })();
    }

    setInstituteType(
      instituteTypes.find((type) => type.id === institute.type.id),
    );
    setMarketProfileType(
      marketProfiles.find(
        (profile) => profile.id === institute?.marketProfile?.id,
      ),
    );
    if (onCountryChange) {
      onCountryChange(countryRef.current as CountryInfo);
    }
  }, [institute]);

  const onSubmitChanges = () => {
    if (!institute || !enableSubmitButton) {
      return;
    }

    const data: AdminUpdateInstituteInput = {
      name: instituteName,
      street: instituteStreet,
      city: instituteCity,
      zip: instituteZip,
      country: (countryRef.current?.country ||
        institute?.country ||
        '') as string,
      marketProfile: marketProfileType?.id || institute?.marketProfile?.id,
      marketProfileName: marketProfileType?.name as string,
      instituteTypeName: instituteType?.name as string,
      type: instituteType?.id || institute.type.id,
      website: instituteWebsite,
    };

    onUpdateInstitute(data);
  };

  const instituteFieldsChanged = shouldAllowToSaveChanges([
    [instituteName, institute?.name],
    [instituteStreet, institute?.street],
    [instituteZip, institute?.zip],
    [instituteCity, institute?.city],
    [instituteWebsite, institute?.website || 'www.gradoo.com'],
    [countryRef.current?.country, institute?.country],
    [marketProfileType?.id, institute?.marketProfile?.id],
    [instituteType?.id, institute?.type?.id],
  ]);

  const requiredFieldsAreFilled = allowToSaveChangesAllFieldsFilled([
    [instituteType?.id, undefined],
    [instituteName, ''],
    [countryRef.current?.country, undefined],
    [marketProfileType?.id, undefined],
  ]);

  const enableSubmitButton = instituteFieldsChanged && requiredFieldsAreFilled;

  if (loading) {
    return (
      <Container centerContent>
        <Spinner testID="loadingSpinner" />
      </Container>
    );
  }

  return (
    <Container>
      <AdminHeader
        title={t('screens.editInstitute')}
        onBack={onBack}
        breadcrumbs={['Institutes', 'EditInstitute']}
        enableSaveChanges={enableSubmitButton}
        onSubmitClick={onSubmitChanges}
        buttonTitle={t('adminPanelSearch.saveChanges')}
        onOpenDrawer={openDrawer}
      />

      <WebContainer isDesktop={isDesktop}>
        <FormContainer isDesktop={isDesktop}>
          <FlexRowCentered>
            <InstituteIconContainer isDesktop={isDesktop}>
              <InstituteIcon
                name="icn/teams/building"
                size={isDesktop ? 56 : 24}
              />
            </InstituteIconContainer>
            <Spacer h={isDesktop ? 32 : 10} />
            <InstituteNameContainer>
              <FlexRowCentered>
                <Name isDesktop={isDesktop}>{institute?.name}</Name>
              </FlexRowCentered>
              <Spacer v={isDesktop ? 8 : 5} />
              <InstituteLink to={'https://www.google.com/'}>
                {t('adminPanelInstitutes.navigateTo', {
                  instituteId: institute?.internalId,
                })}
              </InstituteLink>
            </InstituteNameContainer>
          </FlexRowCentered>
          {isDesktop && <Spacer v={40} />}
          <FlexRow isDesktop={isDesktop}>
            {!isDesktop && <Spacer v={50} />}
            <GrownFlex>
              <SectionLabel>
                {t('adminPanelInstitutes.instituteDetails')}
              </SectionLabel>
              <Spacer v={24} />
              <InputField
                ref={instituteNameRef}
                testID={'inputName'}
                iconName={'users-01'}
                label={t('instituteRequest.instituteName')}
                placeholder={t('createGroup.namePlaceholder')}
                value={instituteName}
                onChangeText={(value) => setInstituteName(value)}
                blurOnSubmit={false}
                returnKeyType={'next'}
              />
              <InputField
                ref={instituteStreetRef}
                testID={'inputStreet'}
                iconName={'users-01'}
                label={t('instituteRequest.street')}
                placeholder={t('createGroup.namePlaceholder')}
                value={instituteStreet}
                onChangeText={(value) => setInstituteStreet(value)}
                blurOnSubmit={false}
                returnKeyType={'next'}
              />
              <FlexRow isDesktop={true}>
                <InputWrapper isDesktop={isDesktop}>
                  <InputField
                    ref={instituteZipRef}
                    testID={'inputZip'}
                    iconName={'users-01'}
                    label={t('instituteRequest.zip')}
                    placeholder={t('createGroup.namePlaceholder')}
                    value={instituteZip}
                    onChangeText={(value) => setInstituteZip(value)}
                    blurOnSubmit={false}
                    returnKeyType={'next'}
                  />
                </InputWrapper>
                <InputWrapper isDesktop={isDesktop}>
                  <InputField
                    ref={instituteCityRef}
                    testID={'inputCity'}
                    iconName={'users-01'}
                    label={t('instituteRequest.city')}
                    placeholder={t('createGroup.namePlaceholder')}
                    value={instituteCity}
                    onChangeText={(value) => setInstituteCity(value)}
                    blurOnSubmit={false}
                    returnKeyType={'next'}
                  />
                </InputWrapper>
              </FlexRow>

              <FlexRow isDesktop={isDesktop}>
                <InputContent>
                  <InputFormWrapper isDesktop={isDesktop}>
                    <CountryTypePicker
                      testID={'countryPicker'}
                      toggleTestID={'buttonPickerToggle'}
                      label={t('adminPanelInstitutes.country')}
                      selectedCountryType={countryRef.current}
                      initialShowPicker={false}
                      onSelectedCountryType={(countryType) => {
                        countryRef.current = countryType;
                        if (onCountryChange) {
                          onCountryChange(countryType as CountryInfo);
                        }
                      }}
                    />
                    <Spacer v={20} />
                    <InstituteTypePicker
                      testID="instituteTypesPicker"
                      toggleTestID="instituteTypePickerToggle"
                      label={t('adminPanelInstitutes.instituteType')}
                      schoolTypes={instituteTypes}
                      selectedSchoolType={instituteType}
                      initialShowPicker={false}
                      onSelectedSchoolType={(instituteType) =>
                        setInstituteType(instituteType as InstituteType)
                      }
                    />
                    <Spacer v={20} />
                    <MarketTypePicker
                      testID="marketTypePicker"
                      toggleTestID="marketTypePickerToggle"
                      label={t('adminPanelInstitutes.marketProfile')}
                      schoolTypes={marketProfiles}
                      selectedSchoolType={marketProfileType}
                      initialShowPicker={false}
                      onSelectedSchoolType={(MarketProfileType) =>
                        setMarketProfileType(MarketProfileType as MarketProfile)
                      }
                    />
                  </InputFormWrapper>
                </InputContent>
              </FlexRow>
              <FlexRow isDesktop={isDesktop}>
                <InputFormWrapper isDesktop={isDesktop}>
                  <InputField
                    ref={instituteWebsiteRef}
                    testID={'inputWebsite'}
                    iconName={'users-01'}
                    label={t('instituteRequest.website')}
                    placeholder={t('createGroup.namePlaceholder')}
                    value={instituteWebsite}
                    onChangeText={(value) => setInstituteWebsite(value)}
                    blurOnSubmit={false}
                    returnKeyType={'next'}
                  />
                </InputFormWrapper>
              </FlexRow>
            </GrownFlex>
            <Spacer h={50} />

            <GrownFlex>
              {!isDesktop && <Spacer v={50} />}
              <SectionLabel>
                {t('adminPanelInstitutes.activityOverview')}
              </SectionLabel>
              <Spacer v={24} />
              <ActivityOverview activities={instituteActivity} />
            </GrownFlex>
          </FlexRow>
          <Spacer v={25} />
          <GroupsDatabase groups={groups} onOpenGroups={onOpenGroups} />
          {!isDesktop && (
            <Button
              testID="saveChangesBtn"
              text={t('adminPanelSearch.saveChanges')}
              onPress={onSubmitChanges}
              state={enableSubmitButton ? 'activeWeb' : 'disabled'}
              size={'lg'}
            />
          )}
        </FormContainer>
      </WebContainer>
    </Container>
  );
}
