/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query Team
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { Team, TeamVariables } from './types/Team';
import { TEAM } from './';

export function useTeamQuery(
  options?: QueryHookOptions<Team, TeamVariables>,
): QueryResult<Team, TeamVariables> {
  return useQuery<Team, TeamVariables>(TEAM, options);
}

export function useTeamLazyQuery(
  options?: LazyQueryHookOptions<Team, TeamVariables>,
): QueryTuple<Team, TeamVariables> {
  return useLazyQuery<Team, TeamVariables>(TEAM, options);
}

export function createTeamMock({
  variables,
  data,
  error,
}: {
  variables?: TeamVariables;
  data?: Team;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: TEAM,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
