import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components/native';
import { t } from '~/utils/i18n';
import Button from '../Button';
import { IconStyledName } from '../IconStyled';
import WebModal, { WebModalProps, WebModalHandler } from '../WebModal';
import {
  ButtonContainer,
  Container,
  ModalButtonRow,
  ModalDescription,
  ModalIcon,
  ModalIconBackground,
  ModalIconContainer,
  ModalTitle,
} from './style';

export type WebModalConfirmHandler = WebModalHandler;

type WebModalConfirmProps = {
  onConfirm(): void;
  onCancel(): void;
  title: string;
  description?: string;
  buttonText?: string;
  cancelButtonText?: string;
  icon?: IconStyledName;
  confirmButtonTestId?: string;
  cancelButtonTestId?: string;
  onConfirmSecondButton?(): void;
} & WebModalProps;

const WebModalConfirm: ForwardRefRenderFunction<
  WebModalConfirmHandler,
  WebModalConfirmProps
> = (
  {
    title,
    description,
    buttonText,
    icon,
    onConfirm,
    defaultOpen = false,
    onCancel,
    onConfirmSecondButton,
    cancelButtonTestId,
    cancelButtonText,
    confirmButtonTestId,
  },
  ref,
): JSX.Element => {
  const theme = useTheme();
  const isDesktop = useMediaQuery({ minWidth: theme.breakpoints.desktopMin });

  return (
    <WebModal ref={ref} defaultOpen={defaultOpen}>
      <Container>
        {icon && (
          <ModalIconContainer>
            <ModalIconBackground>
              <ModalIcon name={icon} />
            </ModalIconBackground>
          </ModalIconContainer>
        )}
        <ModalTitle>{title}</ModalTitle>
        {description && <ModalDescription>{description}</ModalDescription>}
        <ModalButtonRow isDesktop={isDesktop}>
          <ButtonContainer isDesktop={isDesktop}>
            <Button
              flex={!isDesktop}
              testID={cancelButtonTestId}
              text={cancelButtonText || t('alert.cancel')}
              onPress={onConfirmSecondButton ? onConfirmSecondButton : onCancel}
              type={'secondary-base'}
            />
          </ButtonContainer>
          <ButtonContainer isDesktop={isDesktop}>
            <Button
              flex={!isDesktop}
              testID={confirmButtonTestId}
              text={buttonText || t('alert.confirm')}
              onPress={onConfirm}
            />
          </ButtonContainer>
        </ModalButtonRow>
      </Container>
    </WebModal>
  );
};

export default forwardRef(WebModalConfirm);
