import React from 'react';
import SectionButton from '~/components/SectionButton';
import { t } from '~/utils/i18n';
import {
  AllowedView,
  HiddenPhoto,
  HiddenPhotoList,
  LockIcon,
  LockIconContainer,
  Content,
  EmptyView,
  EmptyImageIcon,
  Loading,
  FooterBoldText,
  FooterText,
} from './style';

export type AlbumDetailFooterProps = {
  loading: boolean;
  viewAll?: boolean;
  photosCount: number;
  ownPhotosCount: number;
  localPhotosCount: number;
  addPhotoEnabled?: boolean;
  onAddPhoto: () => void;
  onPickImage: () => void;
};

export default function AlbumDetailFooter({
  loading,
  viewAll,
  photosCount,
  ownPhotosCount,
  localPhotosCount,
  addPhotoEnabled,
  onAddPhoto,
  onPickImage,
}: AlbumDetailFooterProps): JSX.Element {
  const footerCountText = (alt: boolean) => {
    return (
      <FooterText>
        {t('collagesAlbumDetail.footerInfo1', {
          count: photosCount,
        })}
        <FooterBoldText>
          {t('collagesAlbumDetail.photosCount', {
            count: photosCount,
          })}
        </FooterBoldText>
        {t(
          alt
            ? 'collagesAlbumDetail.footerInfo2alt'
            : 'collagesAlbumDetail.footerInfo2',
        )}
      </FooterText>
    );
  };

  return loading ? (
    <Content>
      <Loading />
    </Content>
  ) : photosCount > 0 && !viewAll ? (
    <>
      <AllowedView
        testID={'albumDetailAllowed'}
        noImages={localPhotosCount === 0 && ownPhotosCount === 0}
      >
        <LockIconContainer>
          <LockIcon />
        </LockIconContainer>
        <HiddenPhotoList>
          <HiddenPhoto />
          <HiddenPhoto />
          <HiddenPhoto />
          <HiddenPhoto />
        </HiddenPhotoList>
      </AllowedView>
      {footerCountText(true)}
      {ownPhotosCount === 0 && localPhotosCount === 0 && addPhotoEnabled && (
        <SectionButton
          testID={'buttonAddFirstPhoto'}
          text={t('collagesAlbumDetail.addYourFirstPhoto')}
          icon={'plus'}
          centerContent
          type={'large'}
          onPress={onAddPhoto}
        />
      )}
    </>
  ) : photosCount > 0 ? (
    footerCountText(false)
  ) : photosCount == 0 && localPhotosCount === 0 ? (
    <EmptyView>
      <>
        <EmptyImageIcon testID={'iconEmptyImage'} />
        {footerCountText(false)}
        {addPhotoEnabled ? (
          <SectionButton
            testID={'buttonAddFirstPhoto'}
            text={t('collagesAlbumDetail.addFirstPhoto')}
            icon={'plus'}
            centerContent
            type={'large'}
            onPress={onPickImage}
          />
        ) : null}
      </>
    </EmptyView>
  ) : (
    <></>
  );
}
