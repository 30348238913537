/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query FindDuplicateInstitutes
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  FindDuplicateInstitutes,
  FindDuplicateInstitutesVariables,
} from './types/FindDuplicateInstitutes';
import { FIND_DUPLICATE_INSTITUTES } from './';

export function useFindDuplicateInstitutesQuery(
  options?: QueryHookOptions<
    FindDuplicateInstitutes,
    FindDuplicateInstitutesVariables
  >,
): QueryResult<FindDuplicateInstitutes, FindDuplicateInstitutesVariables> {
  return useQuery<FindDuplicateInstitutes, FindDuplicateInstitutesVariables>(
    FIND_DUPLICATE_INSTITUTES,
    options,
  );
}

export function useFindDuplicateInstitutesLazyQuery(
  options?: LazyQueryHookOptions<
    FindDuplicateInstitutes,
    FindDuplicateInstitutesVariables
  >,
): QueryTuple<FindDuplicateInstitutes, FindDuplicateInstitutesVariables> {
  return useLazyQuery<
    FindDuplicateInstitutes,
    FindDuplicateInstitutesVariables
  >(FIND_DUPLICATE_INSTITUTES, options);
}

export function createFindDuplicateInstitutesMock({
  variables,
  data,
  error,
}: {
  variables?: FindDuplicateInstitutesVariables;
  data?: FindDuplicateInstitutes;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: FIND_DUPLICATE_INSTITUTES,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
