/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation LikeComment
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { LikeComment, LikeCommentVariables } from './types/LikeComment';
import { LIKE_COMMENT } from './';

export function useLikeCommentMutation(
  options?: MutationHookOptions<LikeComment, LikeCommentVariables>,
): MutationTuple<LikeComment, LikeCommentVariables> {
  return useMutation<LikeComment, LikeCommentVariables>(LIKE_COMMENT, options);
}

export function createLikeCommentMock({
  variables,
  data,
  error,
}: {
  variables?: LikeCommentVariables;
  data?: LikeComment;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: LIKE_COMMENT,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
