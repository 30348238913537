import React from 'react';
import { general } from '~/assets/imgs';
import Button from '~/components/Button';
import AuthWebLayout from '~/screens/Auth/layout/AuthLayout/AuthWebLayout';
import { LanguageProps } from '~/screens/Auth/layout/AuthLayout/types';
import { t } from '~/utils/i18n';
import { MainImage, Title, Desc } from './style';

type BrokenLinkLayoutProps = LanguageProps & {
  openContactSupport: () => void;
};

export default function NotFound({
  selectedLocale,
  onSelectedLocale,
  openContactSupport,
}: BrokenLinkLayoutProps): JSX.Element {
  return (
    <AuthWebLayout languageProps={{ selectedLocale, onSelectedLocale }}>
      <>
        <MainImage source={general.flame} resizeMode={'contain'}></MainImage>
        <Title>{t('brokenLink.title')}</Title>
        <Desc>{t('brokenLink.desc')}</Desc>
        <Button
          type={'secondary-contrast'}
          text={t('brokenLink.contactSupport')}
          onPress={openContactSupport}
          flex={true}
        ></Button>
      </>
    </AuthWebLayout>
  );
}
