import React from 'react';
import {
  useNavigation,
  useRoute,
  RouteProp,
  CompositeNavigationProp,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import useAuthTeams from '~/data/hooks/useAuthTeams';
import usePollVote from '~/data/hooks/usePollVote';
import usePosts from '~/data/hooks/usePosts';
import { BestMatchedAsset } from '~/data/models/campaign';
import { TeamDetail, TeamUser } from '~/data/models/team';
import { Status, TeamType, VariantTypeEnum } from '~/data/operations/global';
import { PostFields } from '~/data/operations/post/types/PostFields';
import { useAcceptUserInTeamMutation } from '~/data/operations/team/acceptUserInTeam';
import { useActivateTeamMutation } from '~/data/operations/team/activateTeam';
import { useAddUserToTeamMutation } from '~/data/operations/team/addUserToTeam';
import {
  readTeamDetailFragment,
  readTeamSampleFragment,
  writeTeamDetailFragment,
  writeTeamSampleFragment,
} from '~/data/operations/team/helpers';
import { useRemoveUserFromTeamMutation } from '~/data/operations/team/removeUserFromTeam';
import { useTeamDetailQuery } from '~/data/operations/team/teamDetail';
import { useUserTeamQuery } from '~/data/operations/team/userTeam';
import { PlanningStackParamList, RootStackParamList } from '~/navigation/types';
import useAdsTracking from '~/utils/hooks/useAdsTracking';
import { t } from '~/utils/i18n';
import TeamDetailLayout from './layout';

type TeamDetailNavProp = CompositeNavigationProp<
  StackNavigationProp<PlanningStackParamList, 'TeamDetail'>,
  StackNavigationProp<RootStackParamList>
>;

type TeamDetailRouteProp = RouteProp<PlanningStackParamList, 'TeamDetail'>;

export default function TeamDetailScreen(): JSX.Element {
  const navigation = useNavigation<TeamDetailNavProp>();
  const {
    params: { teamId },
  } = useRoute<TeamDetailRouteProp>();

  const { authUserId, refetchAuthUserGroup } = useAuth();

  const { authTeamIds } = useAuthTeams();

  const {
    data: teamData,
    loading: teamLoading,
    refetch: refetchTeam,
  } = useTeamDetailQuery({
    skip: !authUserId,
    variables: {
      id: teamId,
    },
  });
  const team = teamData?.team as TeamDetail;
  const teamUsers: TeamUser[] =
    (team?.users?.map((user) => user) as TeamUser[]) || [];

  const acceptedTeamUsers = teamUsers.filter(
    (teamUser) => teamUser.status === Status.ACCEPTED,
  );

  const pendingTeamUsers = teamUsers.filter(
    (teamUser) => teamUser.status === Status.PENDING,
  );

  const adminUserIds = teamUsers
    .filter((teamUser) => teamUser.isAdmin)
    .map((teamUser) => teamUser.user.id);

  const loggedTeamUser = teamUsers.find(
    (teamUser) => teamUser.user.id === authUserId,
  );

  const checkAuthUserRefetch = (userTeam: TeamUser) => {
    if (
      userTeam?.status === Status.ACCEPTED &&
      (team.type === TeamType.YEARBOOK || team.type === TeamType.CORE)
    ) {
      refetchAuthUserGroup();
    }
  };

  useUserTeamQuery({
    skip:
      !loggedTeamUser ||
      loggedTeamUser.status !== Status.PENDING ||
      team.isHidden,
    variables: {
      id: loggedTeamUser?.id as string,
    },
    pollInterval: 5000,
    onCompleted: ({ userTeam }) => {
      userTeam && checkAuthUserRefetch(userTeam);
    },
  });

  const {
    posts,
    loading: postsLoading,
    hasNextPage: postsHasNextPage,
    onRefresh: onRefreshPosts,
    onLoadMore: onLoadMorePosts,
    onLikePost,
    onDeletePost,
    onReportPost,
    onReportUser,
    onBlockUser,
    onHidePost,
  } = usePosts({ teamId, skip: !team || team.isHidden || !authUserId });
  const { onPollVote } = usePollVote();
  const [removeUserFromTeam] = useRemoveUserFromTeamMutation({});
  const [acceptUserInTeam] = useAcceptUserInTeamMutation();
  const [activateTeam, { loading: activateTeamLoading }] =
    useActivateTeamMutation();

  const {
    bestMatchedAsset,
    refetch: refetchAds,
    randomIndex,
  } = useAdsTracking({
    authUserId,
    variantType: VariantTypeEnum.SMALL,
  });

  let combinedData: (BestMatchedAsset | PostFields)[] = [...posts];

  if (bestMatchedAsset?.[randomIndex] !== undefined) {
    combinedData = [
      bestMatchedAsset?.[randomIndex] as BestMatchedAsset,
      ...posts,
    ];
  }

  const [addUserToTeam] = useAddUserToTeamMutation({
    update(cache, { data }) {
      const newTeamUser = data?.addUserToTeam?.userTeam;

      const currentTeam = readTeamDetailFragment({
        cache,
        id: teamId,
      });

      const currentTeamSample = readTeamSampleFragment({
        cache,
        id: teamId,
      });

      if (currentTeam && newTeamUser) {
        const currentTeamUsers = currentTeam?.users || [];
        const updatedTeamUsers = [...currentTeamUsers, newTeamUser];

        writeTeamDetailFragment({
          cache,
          id: teamId,
          data: {
            ...currentTeam,
            users: updatedTeamUsers,
          },
        });

        if (currentTeamSample && newTeamUser.status === Status.ACCEPTED) {
          writeTeamSampleFragment({
            cache,
            id: teamId,
            data: {
              ...currentTeamSample,
              usersCount: updatedTeamUsers?.length || 0,
              sampleMembers: updatedTeamUsers,
            },
          });
        }
      }
    },
  });

  async function onRequestAccess() {
    try {
      const { data } = await addUserToTeam({
        variables: {
          input: {
            userId: authUserId,
            teamId,
          },
        },
      });

      const userTeam = data?.addUserToTeam?.userTeam;

      if (userTeam?.status === Status.PENDING) {
        Snackbar.show(t('teamDetail.requestSent'), {
          duration: Snackbar.durations.LONG,
          position: Snackbar.positions.BOTTOM_TAB,
          actionText: t('undo'),
          onActionPress: async () => {
            onRemoveRequest();
          },
        });
      } else {
        userTeam && checkAuthUserRefetch(userTeam);
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message, {
          duration: Snackbar.durations.LONG,
          position: Snackbar.positions.BOTTOM_TAB,
        });
      }
    }
  }

  async function onRemoveRequest() {
    try {
      await removeUserFromTeam({
        variables: {
          input: {
            userId: authUserId,
            teamId,
          },
        },
        update(cache) {
          const currentTeam = readTeamDetailFragment({
            cache,
            id: teamId,
          });

          const currentTeamSample = readTeamSampleFragment({
            cache,
            id: teamId,
          });

          if (currentTeam) {
            const currentTeamUsers = currentTeam?.users || [];
            const updatedTeamUsers = currentTeamUsers.filter(
              (teamUser) => teamUser && teamUser.user.id != authUserId,
            );
            writeTeamDetailFragment({
              cache,
              id: teamId,
              data: {
                ...currentTeam,
                users: updatedTeamUsers,
              },
            });

            if (currentTeamSample) {
              writeTeamSampleFragment({
                cache,
                id: teamId,
                data: {
                  ...currentTeamSample,
                  usersCount: updatedTeamUsers?.length || 0,
                  sampleMembers: updatedTeamUsers,
                },
              });
            }
          }
        },
      });
      Snackbar.show(t('teamDetail.requestRemoved'), {
        duration: Snackbar.durations.LONG,
        position: Snackbar.positions.BOTTOM_TAB,
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message, {
          duration: Snackbar.durations.LONG,
          position: Snackbar.positions.BOTTOM_TAB,
        });
      }
    }
  }

  const onAcceptUserInTeam = async (userId: string, accept: boolean) => {
    try {
      if (teamId) {
        await acceptUserInTeam({
          variables: {
            input: {
              teamId,
              userId,
              accept,
            },
          },
          update(cache, { data }) {
            const acceptedTeamUser = data?.acceptUserInTeam?.userTeam;

            const currentTeam = readTeamDetailFragment({
              cache,
              id: teamId,
            });

            const currentTeamSample = readTeamSampleFragment({
              cache,
              id: teamId,
            });

            if (currentTeamSample && acceptedTeamUser) {
              writeTeamSampleFragment({
                cache,
                id: teamId,
                data: {
                  ...currentTeamSample,
                  usersCount: currentTeam?.users?.length || 0,
                  sampleMembers: currentTeam?.users || [],
                },
              });
            }
          },
        });
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onActivateTeam = async () => {
    try {
      await activateTeam({
        variables: {
          input: {
            id: teamId,
          },
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const openBannersInBrowser = (url: string) => {
    navigation.navigate('Browser', {
      title: 'Banner',
      url,
    });
  };

  return (
    <TeamDetailLayout
      authUserId={authUserId}
      loggedTeamUser={loggedTeamUser}
      team={team}
      loading={teamLoading || postsLoading}
      posts={combinedData}
      teamUsers={acceptedTeamUsers}
      pendingTeamUsers={pendingTeamUsers}
      adminUserIds={adminUserIds}
      postsHasNextPage={postsHasNextPage}
      authTeamIds={authTeamIds}
      activateTeamLoading={activateTeamLoading}
      openBannersInBrowser={openBannersInBrowser}
      onBack={() => navigation.goBack()}
      onSetting={() => navigation.navigate('EditTeam', { teamId })}
      onChat={() => console.log('onChat')} //eslint-disable-line
      onNotification={() => console.log('onNotification')} //eslint-disable-line
      onCreatePost={() => navigation.navigate('CreatePost', { teamId })}
      onCreatePoll={() => navigation.navigate('CreatePoll', { teamId })}
      onRequestAccess={() => onRequestAccess()}
      onLike={onLikePost}
      onDelete={onDeletePost}
      onReportPost={onReportPost}
      onReportUser={onReportUser}
      onBlockUser={onBlockUser}
      onHidePost={onHidePost}
      onPollVote={onPollVote}
      onComment={(postId) => navigation.navigate('Comments', { postId })}
      onLoadMorePosts={onLoadMorePosts}
      onRefresh={() => {
        onRefreshPosts();
        refetchAds();
        refetchTeam();
      }}
      onDeclineUser={(userId) => onAcceptUserInTeam(userId, false)}
      onAcceptUser={(userId) => onAcceptUserInTeam(userId, true)}
      onActivateTeam={onActivateTeam}
      onEdit={(postId) => navigation.navigate('EditPoll', { postId })}
    />
  );
}
