import styled from 'styled-components/native';
import { ResponsiveProps } from '~/components/common/style.web';

export const StyledGroupRow = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})`
  padding-vertical: 16px;
  flex-direction: row;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.color.base.c3};
  align-items: center;
  column-gap: 16px;
`;

export const BoldText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  user-select: text;
`;

export const DetailText = styled.Text`
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 14px;
  line-height: 18px;
  user-select: text;
`;

export const GroupNameTypeColumn = styled.View<ResponsiveProps>`
  flex: auto;
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
`;

export const GroupNameTypeWrapper = styled.View<ResponsiveProps>`
  flex: ${({ isDesktop }) => (isDesktop ? 1 : 'auto')};
`;

export const GroupName = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
  line-height: 20px;
  width: 100%;
  user-select: text;
`;

export const TeamColumn = styled.View`
  flex: 1;
  row-gap: 8px;
`;

export const TeamName = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 12px;
  user-select: text;
`;

export const FlexEnd = styled.View`
  flex: 1;
  align-items: flex-end;
`;
