import { Pressable } from 'react-native';
import styled from 'styled-components/native';
import SwitchSelector from '~/components/SwitchSelector';
import { webContainerCss } from '~/components/WebGrid';
import { ResponsiveProps } from '~/components/common/style.web';

export const InstituteDetail = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const InstituteLink = styled(Pressable)`
  color: ${({ theme }) => theme.color.brand_02};
`;

export const DetailLabel = styled.Text`
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 16px;
  line-height: 20px;
  user-select: text;
`;

export const DetailValue = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 16px;
  line-height: 20px;
  user-select: text;
  text-align: right;
`;

export const DetailText = styled.Text`
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 14px;
  line-height: 18px;
  user-select: text;
`;

export const ActionContainer = styled.View`
  ${webContainerCss};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const PhoneColumn = styled.View<{ isCompact?: boolean }>`
  width: ${({ isCompact }) => (isCompact ? 30 : 48)}%;
  align-items: center;
`;

export const FlexEnd = styled.View`
  flex: 1;
  align-items: flex-end;
  margin-right: 5%;
`;

export const UserNameColumn = styled.View<ResponsiveProps>`
  width: ${({ isDesktop }) => (isDesktop ? 25 : 45)}%;
  padding-left: ${({ isDesktop }) => (isDesktop ? 0 : 10)}px;
`;

export const GraduationYearSelector = styled(SwitchSelector)`
  padding: 24px 0 0;
`;

export const MemberName = styled.Text`
  color: ${({ theme }) => theme.color.base.c9};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
  line-height: 20px;
  user-select: text;
`;

export const TeamName = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 12px;
  user-select: text;
`;

export const MemberRow = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})`
  padding-vertical: 16px;
  flex-direction: row;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.color.base.c3};
  align-items: center;
  column-gap: 16px;
`;

export const MemberListControls = styled.View`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 16px 0;
  gap: 55px;
`;

export const CreatedAt = styled.Text<ResponsiveProps>`
  size: ${({ isDesktop }) => (isDesktop ? '16' : '14')}px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c6};
`;
