import styled from 'styled-components/native';
import IconStyled from '~/components/IconStyled';
import ImageComp from '~/components/Image';
import { ResponsiveProps } from '~/components/common/style.web';

export const SearchResultRow = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})`
  padding-vertical: 16px;
  flex-direction: row;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.color.base.c3};
  align-items: center;
  column-gap: 16px;
`;

export const Name = styled.Text`
  color: ${({ theme }) => theme.color.base.c9};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
  line-height: 20px;
  user-select: text;
`;

export const DetailText = styled.Text`
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 14px;
  line-height: 18px;
  user-select: text;
`;

export const InternalNumberText = styled(DetailText)`
  color: ${({ theme }) => theme.color.base.c9};
  margin-top: 4px;
`;

export const SmallDetailText = styled(DetailText)`
  font-size: 12px;
  line-height: 16px;
`;

export const BoldText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  user-select: text;
`;

export const UserNameColumn = styled.View<ResponsiveProps>`
  width: ${({ isDesktop }) => (isDesktop ? 23 : 48)}%;
`;

export const MiddleColumn = styled.View<{ isCompact?: boolean }>`
  width: ${({ isCompact }) => (isCompact ? 30 : 48)}%;
`;

export const GroupImage = styled(ImageComp)`
  width: 56px;
  height: 56px;
  border-radius: 12px;
  border-width: 2px;
  border-color: ${({ theme }) => theme.color.base.c2};
`;

export const MiniGroupImage = styled(ImageComp)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 4px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.color.base.c2};
`;

export const UserGroupView = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const UserGroupsColumn = styled.View`
  gap: 10px;
`;

export const GroupNameColumn = styled.View<ResponsiveProps>`
  width: ${({ isDesktop }) => (isDesktop ? 22 : 48)}%;
`;

export const DetailsColumn = styled.View`
  margin-left: auto;
  margin-right: 16px;
  width: 20%;
  gap: 5px;
`;

export const InstituteIconContainer = styled.View`
  width: 56px;
  height: 56px;
  border-radius: 12px;
  border-width: 2px;
  border-color: ${({ theme }) => theme.color.base.c3};
  background-color: ${({ theme }) => theme.color.base.c2};
  justify-content: center;
  align-items: center;
`;

export const InstituteIcon = styled(IconStyled).attrs(({ theme }) => ({
  name: 'icn/teams/building',
  size: 24,
  color: theme.color.brand_02,
}))``;
