import React, { useState, useRef } from 'react';
import InputField, { InputFieldHandle } from '~/components/InputField';
import AuthLayout from '~/screens/Auth/layout/AuthLayout';
import {
  LanguageProps,
  SubmitButtonProps,
} from '~/screens/Auth/layout/AuthLayout/types';
import { t } from '~/utils/i18n';
import { validatePassword } from '~/utils/validators';
import validateField from '../../utils/validateField';

type ResetPasswordLayoutProps = {
  loading: boolean;
  onResetPassword: (password: string, passwordConfirmation: string) => void;
} & LanguageProps;

export default function ResetPassword({
  loading,
  selectedLocale,
  onResetPassword,
  onSelectedLocale,
}: ResetPasswordLayoutProps): JSX.Element {
  const passwordRef = useRef<InputFieldHandle>(null);
  const passwordConfRef = useRef<InputFieldHandle>(null);

  const [password, setPassword] = useState<string>('');
  const [passwordConf, setPasswordConf] = useState<string>('');

  const { state: passwordState, info: passwordInfo } = validateField({
    field: password,
    detectErrors: validatePassword,
    info: {
      success: t('inputValidation.validPassword'),
    },
  });
  const { state: passwordConfirmationState, info: passwordConfirmationInfo } =
    validateField({
      field: passwordConf,
      detectErrors: validatePassword,
      isValidField: (field) => password === field,
      info: {
        error: t('inputValidation.passwordsNotMatch'),
        success: t('inputValidation.passwordsMatch'),
      },
    });

  const isValidInput = (): boolean => {
    return (
      passwordState === 'success' && passwordConfirmationState === 'success'
    );
  };

  const handleNext = () => {
    isValidInput() && onResetPassword(password, passwordConf);
  };

  const headerProps = {
    title: t('resetPassword.title'),
    descp: t('resetPassword.descp'),
    progress: 1,
    total: 1,
  };

  const submitButtonProps: SubmitButtonProps = {
    isValidInput,
    loading,
    onPress: handleNext,
    text: t('resetPassword.resetPassword'),
    flex: true,
    size: '2xl',
  };

  const languageProps = {
    selectedLocale,
    onSelectedLocale,
  };

  return (
    <AuthLayout
      submitButtonProps={submitButtonProps}
      headerProps={headerProps}
      languageProps={languageProps}
    >
      <>
        <InputField
          ref={passwordRef}
          testID={'inputPassword'}
          state={passwordState}
          iconName={'lock-02'}
          label={t('input.passwordLabel')}
          placeholder={t('input.passwordPlaceholder')}
          value={password}
          infoLabel={passwordInfo}
          onChangeText={(value) => setPassword(value)}
          blurOnSubmit={false}
          returnKeyType={'next'}
          autoCapitalize={'none'}
          handleSecureTextEntry
          onSubmitEditing={() => passwordConfRef.current?.focus()}
        />
        <InputField
          ref={passwordConfRef}
          testID={'inputPasswordConf'}
          state={passwordConfirmationState}
          iconName={'lock-02'}
          label={t('input.passwordConfLabel')}
          placeholder={t('input.passwordConfPlaceholder')}
          value={passwordConf}
          infoLabel={passwordConfirmationInfo}
          blurOnSubmit={false}
          returnKeyType={'next'}
          autoCapitalize={'none'}
          onChangeText={(value) => setPasswordConf(value)}
          handleSecureTextEntry
          onSubmitEditing={() => handleNext()}
        />
      </>
    </AuthLayout>
  );
}
