/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation CreateCollagesAlbum
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  CreateCollagesAlbum,
  CreateCollagesAlbumVariables,
} from './types/CreateCollagesAlbum';
import { CREATE_COLLAGES_ALBUM } from './';

export function useCreateCollagesAlbumMutation(
  options?: MutationHookOptions<
    CreateCollagesAlbum,
    CreateCollagesAlbumVariables
  >,
): MutationTuple<CreateCollagesAlbum, CreateCollagesAlbumVariables> {
  return useMutation<CreateCollagesAlbum, CreateCollagesAlbumVariables>(
    CREATE_COLLAGES_ALBUM,
    options,
  );
}

export function createCreateCollagesAlbumMock({
  variables,
  data,
  error,
}: {
  variables?: CreateCollagesAlbumVariables;
  data?: CreateCollagesAlbum;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: CREATE_COLLAGES_ALBUM,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
