import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components/native';
import { general } from '~/assets/imgs';
import Button from '~/components/Button';
import InputHeader from '~/components/InputHeader';
import WebFooter from '~/components/WebFooter';
import {
  BackButton,
  WebFormControls,
} from '~/screens/Auth/layout/AuthLayout/AuthWebLayout/style';
import { ImporterLayoutProps } from '~/screens/Importer/layout/types';
import {
  PageGrid,
  Content,
  Mocks,
  AbihomeMock,
  GradooMock,
  ImporterLogos,
  Container,
  VSpacer,
  MobileWhiteContainer,
} from './style';

export default function ImporterWebLayout({
  children,
  submitButtonProps,
  onBack,
  headerProps: { title, descp, total, progress },
}: ImporterLayoutProps): JSX.Element {
  const theme = useTheme();
  const isDesktop = useMediaQuery({ minWidth: theme.breakpoints.desktopMin });

  return (
    <PageGrid isDesktop={isDesktop}>
      <Content isDesktop={isDesktop}>
        <Container isDesktop={isDesktop}>
          <ImporterLogos
            isDesktop={isDesktop}
            source={
              isDesktop ? general.importerLogos : general.importerLogosMobile
            }
          />
          <MobileWhiteContainer isDesktop={isDesktop}>
            <InputHeader
              title={title}
              descp={descp}
              total={total}
              progress={progress}
            />
            {children}
            <VSpacer />
            {(() => {
              if (submitButtonProps) {
                const { isValidInput, loading, onPress, text } =
                  submitButtonProps;
                return (
                  <WebFormControls>
                    <BackButton
                      testID={'buttonBack'}
                      onPress={() => onBack && onBack()}
                    />
                    <Button
                      testID={'buttonNext'}
                      state={isValidInput() ? 'default' : 'disabled'}
                      text={text}
                      loading={loading}
                      onPress={onPress}
                      type={'secondary-contrast'}
                    />
                  </WebFormControls>
                );
              }
              return null;
            })()}
          </MobileWhiteContainer>
          <WebFooter isCompact={isDesktop} />
        </Container>
      </Content>
      {isDesktop && (
        <Mocks>
          <AbihomeMock />
          <GradooMock />
        </Mocks>
      )}
    </PageGrid>
  );
}
