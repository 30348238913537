import { map as mapImgs } from '~/assets/imgs';
import { IconMapper } from '~/components/MapBoxWrapper';
import { AdsTargetingInstituteFields } from '~/data/models/admin';
import { InstituteTargetingActivityEnum } from '~/data/operations/global';

type LocationPoint = {
  type: 'Point';
  coordinates: number[];
};

const iconMapper: IconMapper[] = [
  {
    id: 'instituteWithoutGroups',
    icon: mapImgs.schoolGrey.toString(),
    matcher: [
      'match',
      ['get', 'activity'],
      InstituteTargetingActivityEnum.EMPTY,
      true,
      false,
    ],
  },
  {
    id: 'instituteWithInactiveGroups',
    icon: mapImgs.schoolOrange.toString(),
    matcher: [
      'match',
      ['get', 'activity'],
      InstituteTargetingActivityEnum.INACTIVE,
      true,
      false,
    ],
  },
  {
    id: 'instituteWithGroups',
    icon: mapImgs.schoolGreen.toString(),
    matcher: [
      'match',
      ['get', 'activity'],
      InstituteTargetingActivityEnum.ACTIVE,
      true,
      false,
    ],
  },
];

interface IMapboxHelpers {
  convertInstitutesToGeoJSON: (
    institutes: (AdsTargetingInstituteFields | null)[],
  ) => GeoJSON.FeatureCollection<GeoJSON.Geometry>;
  iconMapper: IconMapper[];
  stringify: (num?: number | null) => string;
}

export default <IMapboxHelpers>{
  convertInstitutesToGeoJSON(institutes) {
    const institutesWithLocationData = institutes.filter(
      (institute) => institute !== null && institute.location !== null,
    );

    const features =
      institutesWithLocationData?.map((institute) => {
        const instituteLocation = institute?.location as LocationPoint;
        return {
          type: 'Feature',
          geometry: {
            type: instituteLocation.type,
            coordinates: [...instituteLocation.coordinates, 0],
          },
          properties: {
            id: institute?.id,
            activity: institute?.activity,
          },
        };
      }) || [];

    return {
      type: 'FeatureCollection',
      features,
    };
  },
  iconMapper,
  stringify: (num?: number | null): string => (num ?? 0).toString(),
};
