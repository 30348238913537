import { gql } from '@apollo/client';

export const INSTITUTE_TYPE_FIELDS = gql`
  fragment InstituteTypeFields on InstituteTypeNode {
    id
    name
    country
    graduationTypes {
      id
      name
    }
  }
`;

export const INSTITUTE_FIELDS = gql`
  ${INSTITUTE_TYPE_FIELDS}
  fragment InstituteFields on InstituteNode {
    id
    internalId
    name
    street
    zip
    city
    website
    type {
      ...InstituteTypeFields
    }
    country
  }
`;

export const INSTITUTE_TYPES = gql`
  ${INSTITUTE_TYPE_FIELDS}
  query InstituteTypes(
    $after: String
    $first: Int!
    $name: String
    $country: InstitutesInstituteCountryChoices
  ) {
    instituteTypes(
      after: $after
      first: $first
      name: $name
      country: $country
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...InstituteTypeFields
        }
      }
    }
  }
`;

export const INSTITUTES = gql`
  ${INSTITUTE_FIELDS}
  query Institutes(
    $after: String
    $first: Int!
    $name: String
    $city: String
    $type: ID
    $country: InstitutesInstituteCountryChoices
    $internalId: String
    $offset: Int
  ) {
    institutes(
      after: $after
      first: $first
      name: $name
      city: $city
      type: $type
      country: $country
      internalId: $internalId
      offset: $offset
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...InstituteFields
        }
      }
      totalCount
    }
  }
`;

export const INSTITUTE = gql`
  ${INSTITUTE_FIELDS}
  query Institute($id: ID!) {
    institute(id: $id) {
      ...InstituteFields
    }
  }
`;

export const REQUEST_MISSING_INSTITUTE = gql`
  mutation RequestMissingInstitute($input: RequestMissingInstituteInput!) {
    requestMissingInstitute(input: $input) {
      responseCode
      message
    }
  }
`;

export const CITIES_ZIP = gql`
  query CitiesZip($query: String, $country: String) {
    citiesZip(query: $query, country: $country) {
      name
    }
  }
`;

export const CREATE_INSTITUTE = gql`
  ${INSTITUTE_FIELDS}
  mutation CreateInstitute($input: CreateInstituteInput!) {
    createInstitute(input: $input) {
      responseCode
      message
      institute {
        ...InstituteFields
      }
    }
  }
`;
