import React, { useState, useEffect } from 'react';
import {
  useNavigation,
  CompositeNavigationProp,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Share } from 'react-native';
import { useAuth } from '~/context/auth';
import { useIntercom } from '~/context/intercom';
import { LINKS } from '~/data/constants';
import { NO_PAG_LIST_OFFSET } from '~/data/constants';
import { DATA_POLICY, TERM } from '~/data/derivedConstants';
import useUserGroups from '~/data/hooks/useUserGroups';
import { UserGroup } from '~/data/models/group';
import { GroupsUserGroupStatusChoices } from '~/data/operations/global';
import { useGroupQuery } from '~/data/operations/group/group';
import { useUserGroupsQuery } from '~/data/operations/group/userGroups';
import { ProfileStackParamList, RootStackParamList } from '~/navigation/types';
import { getCurrentVersion, versionNeedUpdated } from '~/utils/helpers';
import ProfileLayout from './layout';

type ProfileNavProp = CompositeNavigationProp<
  StackNavigationProp<ProfileStackParamList, 'Profile'>,
  StackNavigationProp<RootStackParamList>
>;

export default function Profile(): JSX.Element {
  const navigation = useNavigation<ProfileNavProp>();

  const [needUpdate, setNeedUpdate] = useState<boolean | undefined>();
  const { authGroupId, authUserGroup, coreAdmin, authUserId } = useAuth();
  const { unReadMessageCount, onMessenger, onHelpCenter } = useIntercom();

  const { data: groupData } = useGroupQuery({
    skip: !authGroupId,
    variables: {
      id: authGroupId,
    },
  });

  const group = groupData?.group;

  const version = getCurrentVersion(true);

  useEffect(() => {
    versionNeedUpdated().then((isNeeded) => {
      setNeedUpdate(isNeeded);
    });
  }, []);

  const { onAnswerRequest } = useUserGroups();

  const { data: userGroupsPendingData, loading: userGroupsPendingLoading } =
    useUserGroupsQuery({
      skip: !coreAdmin || !authGroupId,
      variables: {
        first: NO_PAG_LIST_OFFSET,
        group: authGroupId,
        status: GroupsUserGroupStatusChoices.PENDING,
      },
      pollInterval: 10000,
    });

  const pendingUserGroups: UserGroup[] =
    (userGroupsPendingData?.userGroups?.edges.map(
      (edge) => edge?.node,
    ) as UserGroup[]) || [];

  const openBannersInBrowser = (url: string) => {
    navigation.navigate('Browser', {
      title: 'Banner',
      url,
    });
  };

  return (
    <ProfileLayout
      unReadMessageCount={unReadMessageCount}
      userGroup={authUserGroup}
      group={group}
      coreAdmin={coreAdmin}
      version={version}
      needUpdate={needUpdate}
      openBannersInBrowser={openBannersInBrowser}
      pendingUserGroups={pendingUserGroups}
      onDeclineUser={(userId) => onAnswerRequest(userId, false)}
      onAcceptUser={(userId) => onAnswerRequest(userId, true)}
      loadingMore={userGroupsPendingLoading}
      onSettings={() => navigation.navigate('GroupSetup')}
      onShareLink={(url) =>
        Share.share({
          message: url,
          url,
        })
      }
      onHelpCenter={onHelpCenter}
      onContact={onMessenger}
      onEditProfile={() => navigation.navigate('ProfileSetup')}
      onNotification={() => navigation.navigate('NotificationSettings')}
      onAppSetup={() => navigation.navigate('AppSetup')}
      onRateApp={() =>
        navigation.navigate('Browser', {
          title: '',
          url: LINKS.RATE,
        })
      }
      onImprintAndLicences={() =>
        navigation.navigate('Browser', {
          title: TERM.title,
          url: TERM.url,
        })
      }
      onDataPolicy={() =>
        navigation.navigate('Browser', {
          title: DATA_POLICY.title,
          url: DATA_POLICY.url,
        })
      }
      authUserId={authUserId}
    />
  );
}
