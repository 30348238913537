import styled from 'styled-components/native';

export const Container = styled.View`
  padding-horizontal: 16px;
  padding-vertical: 8px;
  flex-direction: row;
  align-items: center;
`;

interface ITabCol {
  hasTabSeparator: boolean;
}
export const TabCol = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})<ITabCol>`
  flex: 1;
  align-items: center;
  padding-horizontal: 8px;
  border-color: ${({ theme }) => theme.color.base.c3};
  ${({ hasTabSeparator }) => hasTabSeparator && 'border-right-width: 1px;'};
`;

interface ITabItemTitle {
  selected: boolean;
  tabColor?: string;
}
export const TabItemTitle = styled.Text<ITabItemTitle>`
  color: ${({ selected, tabColor, theme }) =>
    selected ? tabColor || theme.color.brand_02 : theme.color.base.c6};
  font-family: ${({ selected, theme }) =>
    selected ? theme.fontFamily.core.w600 : theme.fontFamily.core.w400};
  font-size: 18px;
  line-height: 22px;
`;
