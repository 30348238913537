type GraduationYear = { key: string; name: string };
const YEARS_PRIOR_CURRENT = 3;
const YEARS_AFTER_CURRENT = 3;
const YEARS_OPTION_LENGTH = YEARS_PRIOR_CURRENT + YEARS_AFTER_CURRENT + 1;
const generateGraduationYears = (): GraduationYear[] => {
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - YEARS_PRIOR_CURRENT;

  return Array.from({ length: YEARS_OPTION_LENGTH }, (_, i) => {
    const isFirstOption = i === 0;
    const year = isFirstOption ? 'all' : (startYear + i).toString();
    return { key: year, name: year };
  });
};

export default generateGraduationYears;
