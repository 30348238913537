import { gql } from '@apollo/client';

export const IMPORT_FROM_ABIHOME = gql`
  mutation ImportFromAbihome(
    $email: String!
    $password: String!
    $groupName: String!
    $graduationType: String!
    $graduationYear: Int!
    $approved: Boolean
    $iconId: ID
    $avatar: Upload
  ) {
    importAbihome(
      email: $email
      password: $password
      groupName: $groupName
      graduationType: $graduationType
      graduationYear: $graduationYear
      approved: $approved
      iconId: $iconId
      avatar: $avatar
    ) {
      ok
      userNumber
      error
    }
  }
`;
