import { ScrollView } from 'react-native';
import styled from 'styled-components/native';
import { TabScreenContainer } from '~/screens/style';

export const Container = styled(TabScreenContainer)`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const ContainerWrapper = styled(ScrollView)`
  padding-horizontal: 16px;
`;
