/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query GroupIcons
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { GroupIcons, GroupIconsVariables } from './types/GroupIcons';
import { GROUP_ICONS } from './';

export function useGroupIconsQuery(
  options?: QueryHookOptions<GroupIcons, GroupIconsVariables>,
): QueryResult<GroupIcons, GroupIconsVariables> {
  return useQuery<GroupIcons, GroupIconsVariables>(GROUP_ICONS, options);
}

export function useGroupIconsLazyQuery(
  options?: LazyQueryHookOptions<GroupIcons, GroupIconsVariables>,
): QueryTuple<GroupIcons, GroupIconsVariables> {
  return useLazyQuery<GroupIcons, GroupIconsVariables>(GROUP_ICONS, options);
}

export function createGroupIconsMock({
  variables,
  data,
  error,
}: {
  variables?: GroupIconsVariables;
  data?: GroupIcons;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: GROUP_ICONS,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
