import React from 'react';
import { useEffect, useRef } from 'react';
import { AnimationObject } from 'lottie-react-native';
import lottie, { AnimationItem } from 'lottie-web';

interface LottieWebProps {
  animationData: AnimationObject;
  width: number;
  height: number;
  autoplay?: boolean;
  loop?: boolean;
}

const LottieWeb = ({
  animationData,
  width,
  height,
  autoplay = true,
  loop = false,
}: LottieWebProps): JSX.Element => {
  const element = useRef<HTMLDivElement>(null);
  const lottieInstance = useRef<AnimationItem>();

  useEffect(() => {
    if (element.current) {
      lottieInstance.current = lottie.loadAnimation({
        animationData,
        container: element.current,
        autoplay,
        loop,
      });
    }
    return () => {
      lottieInstance.current?.destroy();
    };
  }, [animationData]);

  return <div style={{ width, height }} ref={element} />;
};

export default LottieWeb;
