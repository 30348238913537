/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation LikePost
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { LikePost, LikePostVariables } from './types/LikePost';
import { LIKE_POST } from './';

export function useLikePostMutation(
  options?: MutationHookOptions<LikePost, LikePostVariables>,
): MutationTuple<LikePost, LikePostVariables> {
  return useMutation<LikePost, LikePostVariables>(LIKE_POST, options);
}

export function createLikePostMock({
  variables,
  data,
  error,
}: {
  variables?: LikePostVariables;
  data?: LikePost;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: LIKE_POST,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
