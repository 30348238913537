import React from 'react';
import Avatar from '~/components/Avatar';
import { AdminSearchGroup, AdminSearchResultType } from '~/data/models/admin';
import { t } from '~/utils/i18n';
import {
  SearchResultRow,
  Name,
  DetailText,
  UserNameColumn,
  MiddleColumn,
  GroupImage,
  MiniGroupImage,
  UserGroupView,
  GroupNameColumn,
  BoldText,
  DetailsColumn,
  UserGroupsColumn,
  InstituteIconContainer,
  InstituteIcon,
  InternalNumberText,
  SmallDetailText,
} from './style';

type ResultRowProps = {
  onPress: () => void;
  isDesktop?: boolean;
  result: AdminSearchResultType;
};

const ResultRow = ({
  result,
  onPress,
  isDesktop = true,
}: ResultRowProps): JSX.Element => {
  const getGroupImage = (group: AdminSearchGroup | null) =>
    group?.avatar || undefined;

  const renderResultRow = (result: AdminSearchResultType) => {
    switch (result.__typename) {
      case 'SearchUserType':
        return (
          <>
            <Avatar uri={result.avatar} />
            <UserNameColumn isDesktop={isDesktop}>
              <Name>
                {result.firstName} {result.lastName}
              </Name>
              <DetailText>{result.email}</DetailText>
            </UserNameColumn>
            <MiddleColumn isCompact>
              <DetailText>{`+${result?.phoneCountryCode || ''} ${
                result.phone
              }`}</DetailText>
            </MiddleColumn>
            {isDesktop && (
              <DetailsColumn>
                {result.groups && result.groups?.length ? (
                  <UserGroupsColumn>
                    {result.groups?.map((group) => (
                      <UserGroupView key={group?.id}>
                        <MiniGroupImage
                          source={{
                            uri: getGroupImage(group),
                          }}
                        />
                        <SmallDetailText>
                          <BoldText>{group?.graduationYear}</BoldText> -{' '}
                          {group?.name}
                        </SmallDetailText>
                      </UserGroupView>
                    ))}
                  </UserGroupsColumn>
                ) : (
                  <SmallDetailText>
                    {t('adminPanelSearch.noGroup')}
                  </SmallDetailText>
                )}
              </DetailsColumn>
            )}
          </>
        );
      case 'SearchGroupType':
        return (
          <>
            <GroupImage
              source={{
                uri: getGroupImage(result),
              }}
            />
            <GroupNameColumn isDesktop={isDesktop}>
              <Name>{result.name}</Name>
              <InternalNumberText>
                {t(`adminPanelSearch.flags.${result.institute.country}`)}
                {'  '}
                {result.institute.internalId}
              </InternalNumberText>
            </GroupNameColumn>
            {isDesktop && (
              <DetailsColumn>
                <BoldText>{result.type}</BoldText>
                <DetailText>{result.graduationYear}</DetailText>
              </DetailsColumn>
            )}
          </>
        );
      case 'SearchInstituteType':
        return (
          <>
            <InstituteIconContainer>
              <InstituteIcon />
            </InstituteIconContainer>
            <GroupNameColumn isDesktop={isDesktop}>
              <Name>{result.name}</Name>
              <InternalNumberText>
                {t(`adminPanelSearch.flags.${result.country}`)}
                {'  '}
                {result.internalId}
              </InternalNumberText>
            </GroupNameColumn>
            <MiddleColumn isCompact>
              <DetailText>{result.type}</DetailText>
            </MiddleColumn>
            {isDesktop && (
              <DetailsColumn>
                <BoldText>{result.city}</BoldText>
                <DetailText>{result.zip}</DetailText>
              </DetailsColumn>
            )}
          </>
        );
    }
  };

  return (
    <SearchResultRow onPress={onPress} testID={`s-row-${result.id}`}>
      {renderResultRow(result)}
    </SearchResultRow>
  );
};

export default ResultRow;
