import styled from 'styled-components/native';

export const SearchDropdownShortcutContainer = styled.View`
  justify-content: center;
  padding-left: 32px;
  padding-top: 8px;
`;

export const SearchDropdownShortcutlabel = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 10px;
  text-align: center;
  padding-top: 6px;
`;

export const SearchDropdownShortcut = styled.View`
  align-items: center;
  flex-direction: row;
`;

export const SearchDropdownShortcutPlus = styled.Text`
  font-size: 12px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  padding-horizontal: 2px;
`;
