/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query ReportsInstances
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  ReportsInstances,
  ReportsInstancesVariables,
} from './types/ReportsInstances';
import { REPORTS_INSTANCES } from './';

export function useReportsInstancesQuery(
  options?: QueryHookOptions<ReportsInstances, ReportsInstancesVariables>,
): QueryResult<ReportsInstances, ReportsInstancesVariables> {
  return useQuery<ReportsInstances, ReportsInstancesVariables>(
    REPORTS_INSTANCES,
    options,
  );
}

export function useReportsInstancesLazyQuery(
  options?: LazyQueryHookOptions<ReportsInstances, ReportsInstancesVariables>,
): QueryTuple<ReportsInstances, ReportsInstancesVariables> {
  return useLazyQuery<ReportsInstances, ReportsInstancesVariables>(
    REPORTS_INSTANCES,
    options,
  );
}

export function createReportsInstancesMock({
  variables,
  data,
  error,
}: {
  variables?: ReportsInstancesVariables;
  data?: ReportsInstances;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: REPORTS_INSTANCES,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
