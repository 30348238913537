/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query CitiesZip
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { CitiesZip, CitiesZipVariables } from './types/CitiesZip';
import { CITIES_ZIP } from './';

export function useCitiesZipQuery(
  options?: QueryHookOptions<CitiesZip, CitiesZipVariables>,
): QueryResult<CitiesZip, CitiesZipVariables> {
  return useQuery<CitiesZip, CitiesZipVariables>(CITIES_ZIP, options);
}

export function useCitiesZipLazyQuery(
  options?: LazyQueryHookOptions<CitiesZip, CitiesZipVariables>,
): QueryTuple<CitiesZip, CitiesZipVariables> {
  return useLazyQuery<CitiesZip, CitiesZipVariables>(CITIES_ZIP, options);
}

export function createCitiesZipMock({
  variables,
  data,
  error,
}: {
  variables?: CitiesZipVariables;
  data?: CitiesZip;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: CITIES_ZIP,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
