import React, { useRef } from 'react';
import { useHover } from 'react-native-web-hooks';
import { IconName } from '~/components/Icon';
import { Container, Content, FocusContent, Icon, IconWrapper } from './style';
import {
  SelectionControlSize,
  SelectionControlState,
  SelectionControlType,
  SelectionControlColorStyle,
} from './types';

export interface SelectionControlProps {
  testID?: string;
  state?: SelectionControlState;
  size?: SelectionControlSize;
  checked: boolean;
  icon?: IconName;
  colorStyle?: SelectionControlColorStyle;
  onChange: (checked: boolean) => void;
}

interface SelectionControlInnerProps extends SelectionControlProps {
  type: SelectionControlType;
}

export default function SelectionControl({
  testID,
  type,
  state = 'default',
  size = 'small',
  checked = false,
  icon,
  colorStyle = 'primary',
  onChange,
}: SelectionControlInnerProps): JSX.Element {
  const ref = useRef(null);
  const hovered = useHover(ref);

  const innerState: SelectionControlState =
    state !== 'disabled' && hovered ? 'hover' : state;
  return (
    <Container
      ref={ref}
      testID={testID}
      type={type}
      state={innerState}
      size={size}
      onPress={() => state !== 'disabled' && onChange && onChange(!checked)}
    >
      <FocusContent
        type={type}
        state={innerState}
        colorStyle={colorStyle}
        size={size}
        checked={checked}
      >
        <Content
          type={type}
          state={innerState}
          colorStyle={colorStyle}
          size={size}
          checked={!icon && checked}
        >
          {icon && checked && (
            <IconWrapper state={innerState}>
              <Icon name={icon} />
            </IconWrapper>
          )}
        </Content>
      </FocusContent>
    </Container>
  );
}
