import styledWeb from 'styled-components';
import styled from 'styled-components/native';
import { ModalScreenContainer } from '~/screens/style';

export const Container = styled(ModalScreenContainer)`
  flex: 1;
`;

export const IFrame = styledWeb('iframe')`
  height: 100%;
  border: 0;
`;
