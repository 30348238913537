import React from 'react';
import Shimmer from '~/components/Shimmer';
import { ModuleInstance } from '~/data/models/yearbook';
import { ModuleType } from '~/data/operations/global';
import { formattedDate } from '~/utils/dates';
import { ClockIcon, Container, DueDate, Footer, Image, Title } from './style';

type YearbookContentCellProps = {
  moduleInstance?: ModuleInstance;
  width?: number;
  onYearbookModuleClick: (
    moduleType: ModuleType,
    moduleInstanceId: string,
  ) => void;
};

export default function YearbookContentCell({
  moduleInstance,
  width = 140,
  onYearbookModuleClick,
}: YearbookContentCellProps): JSX.Element {
  if (!moduleInstance) {
    return (
      <Container width={width}>
        <Shimmer w={125} h={90} ml={12} g={'g2'} />
        <Shimmer h={22} w={100} ml={12} mt={8} g={'g3'} />
        <Footer>
          <Shimmer w={16} h={16} ml={12} g={'g1'} />
          <Shimmer w={90} h={14} ml={8} g={'g1'} />
        </Footer>
      </Container>
    );
  }

  return (
    <Container
      testID={`YearbookContentCell:${moduleInstance.id}`}
      onPress={() =>
        onYearbookModuleClick(
          moduleInstance.module.type as ModuleType,
          moduleInstance.id,
        )
      }
      width={width}
    >
      <Image source={{ uri: moduleInstance.module.image || undefined }} />
      <Title>{moduleInstance.module.name}</Title>
      <Footer>
        <ClockIcon />
        <DueDate>{formattedDate(moduleInstance.dueDate, 'dd.MM.yyyy')}</DueDate>
      </Footer>
    </Container>
  );
}
