import styled from 'styled-components/native';
import Image from '~/components/Image';
import TrackingFlatlist from '~/components/TrackingFlatList';

export const Header = styled.View`
  padding-horizontal: 16px;
`;

export const RankingsList = styled(TrackingFlatlist)`
  padding-horizontal: 0px;
`;

export const QuestionWrapper = styled.View`
  padding: 16px;
`;

export const QuestionTitle = styled.Text`
  padding-vertical: 16px;
  color: ${({ theme }) => theme.color.base.c9};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
`;

export const Content = styled.View`
  padding-vertical: 24px;
  align-items: center;
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c7,
}))``;

export const RefreshControl = styled.RefreshControl.attrs(({ theme }) => ({
  color: theme.color.base.c4,
}))``;

export const FallbackScrollView = styled.ScrollView`
  padding: 96px 16px;
`;

export const FallbackImage = styled(Image)`
  width: 100%;
  height: 174px;
`;

export const FallbackText = styled.Text`
  margin-top: 32px;
  color: ${({ theme }) => theme.color.base.c7};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 18px;
  line-height: 24px;
  text-align: center;
`;
