import React, { useState } from 'react';
import { theme } from 'gradoo-theme';
import InputField from '~/components/InputField';
import MemberCell from '~/components/MemberCell';
import NavHeader from '~/components/NavHeader';
import { NotificationTeamRequestCarousel } from '~/components/NotificationCarousel';
import SectionHeader from '~/components/SectionHeader';
import { UserGroup } from '~/data/models/group';
import { Team, TeamUser } from '~/data/models/team';
import { User } from '~/data/models/user';
import { t } from '~/utils/i18n';
import { userHasText } from '~/utils/validators';
import {
  Container,
  UserList,
  MemberCellContainer,
  Header,
  Section,
  RefreshControl,
  Footer,
  Loading,
} from './style';

export type AddTeamMemberLayoutProps = {
  userGroups: UserGroup[];
  teamUsers: TeamUser[];
  includedUserIds: string[];
  team?: Team;
  loading: boolean;
  loadingMore: boolean;
  now?: Date;
  onBack: () => void;
  onDeclineUser: (userId: string) => void;
  onAcceptUser: (userId: string) => void;
  onSelectedUser: (userId: string) => void;
  onRefresh: () => void;
  onLoadMoreUsers: () => void;
};

export default function AddTeamMember({
  userGroups,
  teamUsers,
  includedUserIds,
  team,
  loading,
  loadingMore,
  now = new Date(),
  onBack,
  onDeclineUser,
  onAcceptUser,
  onSelectedUser,
  onRefresh,
  onLoadMoreUsers,
}: AddTeamMemberLayoutProps): JSX.Element {
  const [searchingText, setSearchingText] = useState('');

  const renderMemberItem = ({ item: userGroup }: { item: UserGroup }) => {
    const included = includedUserIds.includes(userGroup.user.id);
    return (
      <MemberCellContainer>
        <MemberCell
          testID={`MemberCell:${userGroup.id}`}
          userGroup={userGroup}
          teamColor={theme.color.base.c9}
          customIconType="secondary"
          customIcon={!included ? 'add' : undefined}
          checked={included}
          onCustomIconPress={() =>
            !included && onSelectedUser(userGroup.user.id)
          }
        />
      </MemberCellContainer>
    );
  };

  const filteredUserGroups = userGroups.filter((userGroup) =>
    userHasText(userGroup.user, searchingText),
  );

  return (
    <Container>
      <NavHeader
        backButtonTestID={'buttonBack'}
        title={t('screens.addTeamMember')}
        onBackPress={onBack}
      />
      <UserList<React.ElementType>
        testID={'userList'}
        data={filteredUserGroups}
        refreshControl={
          <RefreshControl refreshing={loading} onRefresh={onRefresh} />
        }
        renderItem={renderMemberItem}
        ListHeaderComponent={
          <>
            <Header>
              <InputField
                testID={'inputSearch'}
                iconName={'search-md'}
                label={t('g.search')}
                value={searchingText}
                onChangeText={(value) => {
                  setSearchingText(value);
                }}
              />
            </Header>
            {team ? (
              <NotificationTeamRequestCarousel
                teamUsers={teamUsers}
                team={team}
                now={now}
                onDeclineUser={onDeclineUser}
                onAcceptUser={onAcceptUser}
              />
            ) : null}
            <Section>
              <SectionHeader title={t('sections.chooseFromList')} />
            </Section>
          </>
        }
        keyExtractor={(user: User) => user.id}
        ListFooterComponent={
          <Footer>{loadingMore ? <Loading /> : null}</Footer>
        }
        onEndReachedThreshold={0.3}
        onEndReached={() => onLoadMoreUsers()}
      />
    </Container>
  );
}
