import { SafeAreaView } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

//for screens in AuthStackParamList
interface IStackScreenContainer {
  transparent?: boolean;
}
export const StackScreenContainer = styled(SafeAreaView)<IStackScreenContainer>`
  flex: 1;
  ${({ transparent, theme }) =>
    !transparent && `background-color: ${theme.color.base.c0}`};
`;

//for screens inside MainTabParamList
export const TabScreenContainer = styled(SafeAreaView).attrs({
  edges: ['right', 'left', 'top'],
})`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c0};
`;

//for screens in RootStackParamList
export const ModalScreenContainer = styled(SafeAreaView).attrs({
  edges: ['right', 'left', 'bottom'],
})`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c0};
  padding-top: 8px;
`;
