import { LngLatLike } from 'mapbox-gl';
import { t } from '~/utils/i18n';
import {
  SEARCH_TAG_OPTION_TYPES,
  SEARCH_TAG_TYPES,
  SearchShortcut,
  SearchTags,
} from '~/utils/types/adminSearch';
import { AdsTargetingFilterFields } from './models/admin';
import { Country, CountryInfo } from './models/marketProfile';
import { CampaignType } from './operations/global';

export const COUNTRIES: { [key in Country]: CountryInfo } = {
  DE: {
    country: 'DE',
    code: '49',
    name: t('de'),
  },
  AT: {
    country: 'AT',
    code: '43',
    name: t('at'),
  },
  TR: {
    country: 'TR',
    code: '90',
    name: t('tr'),
  },
};

export const COUNTRIES_BY_CODE: Record<
  string,
  Pick<CountryInfo, 'country' | 'name'>
> = {
  '49': {
    country: 'DE',
    name: t('de'),
  },
  '43': {
    country: 'AT',
    name: t('at'),
  },
  '90': {
    country: 'TR',
    name: t('tr'),
  },
};

export const LINKS = {
  WPP: 'https://wa.me/4915792486493',
  RATE: 'https://gradoo.click/spread-some-love',
  TR: {
    WEBSITE_URL: 'https://tr.gradoo.com',
    WEBSITE_URL_BLOG: 'https://tr.gradoo.com/blog/',
    SLIDE_1: 'https://chat.whatsapp.com/I6eOhWG3gNYF3PIjZnALrE',
    SLIDE_2: 'https://gradoo.click/discord-tuerkiye',
    WPP: 'https://chat.whatsapp.com/JVRNTirwlcg0iVNbrX9lFW',
    YEARBOOK_QUOTE: 'https://gradoo.typeform.com/yearbook',
  },
  DE: {
    YEARBOOK_QUOTE: 'https://gradoo.typeform.com/to/ZZQD1JBD',
    SLIDE_2: 'https://gradoo.typeform.com/to/Tg8Ef4j5',
    SLIDE_3: 'https://gradoo.click/bewerbung2go',
  },
  LAYOUTS: 'https://gradoo.click/layouts',
  DESIGN_DATA:
    'https://fluglotsewerden.dfs.de/?pk_source=gradoo&pk_medium=paid-inapp&pk_campaign=fluglotse-2023-wp&pk_content=feed-ads',
  ANDROID_PLAYSTORE:
    'https://play.google.com/store/apps/details?id=de.abihome.gradoo',
  IOS_APPSTORE:
    'https://apps.apple.com/de/app/gradoo-abschluss-planer/id1451815816',
  IMPRINT: 'https://gradoo.com/de/pages/impressum',
  DATA_CENTER: 'https://gradoo.com/de/pages/datenschutz',
  SOCIAL_MEDIA: {
    INSTAGRAM: 'https://www.instagram.com/gradoo_app/?hl=de',
    LINKEDIN: 'https://www.linkedin.com/company/gradoo-app/',
    YOUTUBE: 'https://www.youtube.com/channel/UCHLbaQKe4-8og04oPMyMB8Q',
    TIKTOK: 'https://www.tiktok.com/@gradoo_app?lang=de-DE',
  },
} as const;

export const DEFAULT_LIST_OFFSET = 20;

export const DEFAULT_AGENTS_DATABASE_FIRST = 5;

export const DATABASE_ROWS_PER_PAGE_OPTIONS = [5, 10, 20, 50, 100];

export const DEFAULT_INSTITUTE_DATABASE_FIRST = 5;

/*
for queries that we cannot paginate atm, like:
  - auth teams query. which we need to know the user auth team ids

great candidate queries to refact the logic in the future
*/
export const NO_PAG_LIST_OFFSET = 100;

export const DEFAULT_ALBUM_PHOTOS_OFFSET = 5;

export const DEFAULT_RANKINGS_LIST_OFFSET = 10;

export const DEFAULT_MAX_CHARS = 500;

export const REPORT_DEFAULT_MAX_CHARS = DEFAULT_MAX_CHARS;

export type GraduationYearsType = [string, string, string];
export const GRADUATION_YEARS: GraduationYearsType = ['2023', '2024', '2025'];

export const SEARCH_TAGS: SearchTags[] = [
  {
    key: 'user',
    description: t('adminPanelSearch.user'),
    tags: [
      {
        tag: 'firstname',
        description: t('adminPanelSearch.searchTagLabels.firstName'),
        type: SEARCH_TAG_TYPES.INPUT,
      },
      {
        tag: 'lastname',
        description: t('adminPanelSearch.searchTagLabels.lastName'),
        type: SEARCH_TAG_TYPES.INPUT,
      },
      {
        tag: 'uid',
        description: t('adminPanelSearch.searchTagLabels.userId'),
        type: SEARCH_TAG_TYPES.INPUT,
      },
      {
        tag: 'email',
        description: t('adminPanelSearch.searchTagLabels.userEmail'),
        type: SEARCH_TAG_TYPES.INPUT,
      },
      {
        tag: 'phone',
        description: t('adminPanelSearch.searchTagLabels.userPhone'),
        type: SEARCH_TAG_TYPES.INPUT,
      },
    ],
  },
  {
    key: 'group',
    description: t('adminPanelSearch.groups'),
    tags: [
      {
        tag: 'gname',
        description: t('adminPanelSearch.searchTagLabels.groupName'),
        type: SEARCH_TAG_TYPES.INPUT,
      },
      {
        tag: 'gid',
        description: t('adminPanelSearch.searchTagLabels.groupId'),
        type: SEARCH_TAG_TYPES.INPUT,
      },
      {
        tag: 'year',
        description: t('adminPanelSearch.searchTagLabels.groupGraduationYear'),
        type: SEARCH_TAG_TYPES.INPUT,
      },
      {
        tag: 'gtype',
        description: t('adminPanelSearch.searchTagLabels.groupGraduationType'),
        type: SEARCH_TAG_TYPES.SELECT,
        getOptions: SEARCH_TAG_OPTION_TYPES.GRADUATION_TYPES,
      },
    ],
  },
  {
    key: 'institute',
    description: t('adminPanelSearch.institutes'),
    tags: [
      {
        tag: 'cid',
        description: t('adminPanelSearch.searchTagLabels.instituteId'),
        type: SEARCH_TAG_TYPES.INPUT,
      },
      {
        tag: 'institute',
        description: t('adminPanelSearch.searchTagLabels.instituteName'),
        type: SEARCH_TAG_TYPES.INPUT,
      },
      {
        tag: 'itype',
        description: t('adminPanelSearch.searchTagLabels.instituteType'),
        type: SEARCH_TAG_TYPES.SELECT,
        getOptions: SEARCH_TAG_OPTION_TYPES.INSTITUTE_TYPES,
      },
      {
        tag: 'city',
        description: t('adminPanelSearch.searchTagLabels.instituteCity'),
        type: SEARCH_TAG_TYPES.INPUT,
      },
      {
        tag: 'zip',
        description: t('adminPanelSearch.searchTagLabels.instituteZip'),
        type: SEARCH_TAG_TYPES.INPUT,
      },
      {
        tag: 'street',
        description: t('adminPanelSearch.searchTagLabels.instituteStreet'),
        type: SEARCH_TAG_TYPES.INPUT,
      },
    ],
  },
];

export const SEARCH_SHORTCUTS: SearchShortcut[] = [
  {
    keys: [
      { imageSource: 'icn/search/keyOption', height: 29, width: 32 },
      { imageSource: 'icn/search/keyI', height: 29, width: 29 },
    ],
    inputs: ['Alt', 'i'],
    action: {
      param: 's: i',
      label: '!Institutes',
    },
    label: t('adminPanelSearch.shortcutLabels.onlyInstitutes'),
  },
  {
    keys: [
      { imageSource: 'icn/search/keyOption', height: 29, width: 32 },
      { imageSource: 'icn/search/keyG', height: 29, width: 29 },
    ],
    inputs: ['Alt', 'g'],
    action: {
      param: 's: g',
      label: '!Groups',
    },
    label: t('adminPanelSearch.shortcutLabels.onlyGroups'),
  },
  {
    keys: [
      { imageSource: 'icn/search/keyOption', height: 29, width: 32 },
      { imageSource: 'icn/search/keyU', height: 29, width: 29 },
    ],
    inputs: ['Alt', 'u'],
    action: {
      param: 's: u',
      label: '!User',
    },
    label: t('adminPanelSearch.shortcutLabels.onlyUser'),
  },
];

export const MONTHS = [
  t('calendarMonths.Jan'),
  t('calendarMonths.Feb'),
  t('calendarMonths.Mar'),
  t('calendarMonths.Apr'),
  t('calendarMonths.May'),
  t('calendarMonths.Jun'),
  t('calendarMonths.Jul'),
  t('calendarMonths.Aug'),
  t('calendarMonths.Sep'),
  t('calendarMonths.Oct'),
  t('calendarMonths.Nov'),
  t('calendarMonths.Dec'),
];

export type LocationFilteringBlockTypeType = {
  operator: 'and' | 'or'; // or any other possible values
  type: 'location';
  location: {
    type: 'zip' | 'city' | 'region'; // or any other possible values
    country: string; // or any other possible values
    zip: {
      zipCode: string;
    };
  };
  user: null;
  group: null;
};

export const EMPTY_LOCATION_FILTER: AdsTargetingFilterFields = {
  operator: 'and',
  type: 'location',
  location: null,
  user: null,
  group: null,
};

export const EMPTY_USER_FILTER: AdsTargetingFilterFields = {
  operator: 'and',
  type: 'location',
  location: null,
  user: null,
  group: null,
};

export const createPlaceholderFilter = (
  type: 'location' | 'user' | 'group',
): AdsTargetingFilterFields => ({
  operator: 'and',
  type: type,
  location: null,
  user: null,
  group: null,
});

export const locationOptions = [
  {
    key: 'city',
    title: t('createAssets.createTargeting.locationFilter.city'),
  },
  {
    key: 'zip',
    title: t('createAssets.createTargeting.locationFilter.zip'),
  },
  {
    key: 'region',
    title: t('createAssets.createTargeting.locationFilter.region'),
  },
];

export const CAMPAIGN_TYPE_OPTIONS = Object.keys(CampaignType).map((key) => {
  const value = CampaignType[key as keyof typeof CampaignType];
  return {
    id: `${value}`,
    name: value,
  };
});

export const CAMPAIGN_TABLE_COLUMNS = [
  { key: 'name', label: 'adminPanelAds.campName' },
  { key: 'utm', label: 'adminPanelAds.campaignId' },
  { key: 'customer', label: 'adminPanelAds.campaignCustomer' },
  { key: 'startDate', label: 'adminPanelAds.campaignStartDate' },
  { key: 'status', label: 'adminPanelAds.campaignStatus' },
];

// MAP CONSTANTS
export type LocationData = {
  [key in Country | 'global']: {
    coordinates: LngLatLike;
    zoom: number;
  };
};

export const CENTER_GERMANY_AND_TURKEY: LngLatLike = [25, 48];
export const DEFAULT_ZOOM = 2.5;
export const LOCATION_DATA: LocationData = {
  global: {
    coordinates: CENTER_GERMANY_AND_TURKEY,
    zoom: DEFAULT_ZOOM,
  },
  DE: {
    coordinates: [10.4515, 51.1657],
    zoom: 4.5,
  },
  TR: {
    coordinates: [35.2433, 38.9637],
    zoom: 3.5,
  },
  AT: {
    coordinates: [13.4658, 47.3862],
    zoom: 4.5,
  },
};

export const MAX_ALLOWED_ZIPCODES = 1000;
