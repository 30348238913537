/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation LikeQuotesInstance
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  LikeQuotesInstance,
  LikeQuotesInstanceVariables,
} from './types/LikeQuotesInstance';
import { LIKE_QUOTES_INSTANCE } from './';

export function useLikeQuotesInstanceMutation(
  options?: MutationHookOptions<
    LikeQuotesInstance,
    LikeQuotesInstanceVariables
  >,
): MutationTuple<LikeQuotesInstance, LikeQuotesInstanceVariables> {
  return useMutation<LikeQuotesInstance, LikeQuotesInstanceVariables>(
    LIKE_QUOTES_INSTANCE,
    options,
  );
}

export function createLikeQuotesInstanceMock({
  variables,
  data,
  error,
}: {
  variables?: LikeQuotesInstanceVariables;
  data?: LikeQuotesInstance;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: LIKE_QUOTES_INSTANCE,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
