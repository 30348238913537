import React, { useRef, useState } from 'react';
import Button from '~/components/Button';
import CommonIconCell, {
  CommonIconCellHandle,
} from '~/components/CommonIconCell';
import InputField from '~/components/InputField';
import NavHeader from '~/components/NavHeader';
import SectionButton from '~/components/SectionButton';
import SectionHeader from '~/components/SectionHeader';
import { CustomListOption } from '~/data/models/yearbook';
import { t } from '~/utils/i18n';
import {
  Container,
  FlatList,
  InputContainer,
  SectionContainer,
  Footer,
  Loading,
} from './style';

export type RankingsCustomListLayoutProps = {
  initialTitle?: string;
  deleteEnabled?: boolean;
  customListOptions: CustomListOption[];
  loading?: boolean;
  listOptionsLoading?: boolean;
  onBack: () => void;
  onDeleteList: () => void;
  onAddOption: () => void;
  onDeleteOption: (optionId: string) => void;
  onSaveList: (title: string, navBack: boolean) => void;
  onLoadMoreOptions: () => void;
};

export default function RankingsCustomList({
  initialTitle = '',
  deleteEnabled,
  customListOptions,
  loading,
  listOptionsLoading,
  onBack,
  onDeleteList,
  onAddOption,
  onDeleteOption,
  onSaveList,
  onLoadMoreOptions,
}: RankingsCustomListLayoutProps): JSX.Element {
  const commonIconCellsRef = useRef<(CommonIconCellHandle | null)[]>([]);

  const [title, setTitle] = useState(() => initialTitle);

  const isButtonEnabled = title.length > 0;

  const renderItem = ({
    item: customListOption,
    index: i,
  }: {
    item: CustomListOption;
    index: number;
  }) => {
    return (
      <CommonIconCell
        testID={`CommonIconCell:${customListOption.id}`}
        ref={(el) => (commonIconCellsRef.current[i] = el)}
        key={customListOption.id}
        info={{ text: customListOption.text || '', hideArrowIcon: true }}
        swipeable
        onDelete={() => {
          commonIconCellsRef?.current?.[i]?.collapse();
          onDeleteOption(customListOption.id);
        }}
      />
    );
  };

  return (
    <Container>
      <NavHeader
        backButtonTestID={'buttonBack'}
        title={t('screens.rankingsCustomList')}
        onBackPress={onBack}
        styledButtonProps={
          deleteEnabled
            ? {
                testID: 'buttonDeleteList',
                iconName: 'delete',
                onPress: () => onDeleteList(),
              }
            : undefined
        }
      />
      <FlatList<React.ElementType>
        testID={'flatList'}
        data={customListOptions}
        renderItem={renderItem}
        ListHeaderComponent={
          <>
            <InputContainer>
              <InputField
                testID={'inputTitle'}
                value={title}
                label={t('rankingsCustomList.titleLabel')}
                onChangeText={(title) => setTitle(title)}
                onEndEditing={() => title && onSaveList(title, false)}
              />
            </InputContainer>
            <SectionContainer>
              <SectionHeader
                title={t('rankingsCustomList.optionsSection')}
                sub
              />
            </SectionContainer>
          </>
        }
        ListFooterComponent={
          <SectionContainer>
            {listOptionsLoading ? <Loading /> : null}
            <SectionButton
              testID={'buttonAddOption'}
              text={t('rankingsCustomList.addOption')}
              icon={'plus'}
              centerContent
              onPress={() => onAddOption()}
            />
          </SectionContainer>
        }
        onEndReachedThreshold={0.3}
        onEndReached={() => onLoadMoreOptions()}
      />
      <Footer>
        <Button
          testID={'saveListButton'}
          state={isButtonEnabled ? 'default' : 'disabled'}
          loading={loading}
          text={t('rankingsCustomList.saveList')}
          flex
          onPress={() => onSaveList(title, true)}
        />
      </Footer>
    </Container>
  );
}
