import styled from 'styled-components/native';
import Image from '~/components/Image';

interface ISelected {
  selected: boolean;
}

export const Container = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})`
  flex-direction: row;
  align-items: center;
  padding: 16px;
  border-radius: 5px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.color.base.c3};
  box-shadow: 2px 3px 2px rgba(248, 249, 250, 0.8);
`;

export const FlagView = styled.View`
  width: 56px;
  height: 56px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.base.c2}};
  align-items: center;
  justify-content: center;
`;

export const FlagImage = styled(Image)`
  height: 20px;
  width: 32px;
`;

export const Name = styled.Text<ISelected>`
  flex: 1;
  padding-left: 16px;
  color: ${({ selected, theme }) =>
    selected ? theme.color.brand_02 : theme.color.base.c9}};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
  line-height: 20px;
`;
