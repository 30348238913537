import { gql } from '@apollo/client';

export const CAMPAIGN_FIELDS = gql`
  fragment CampaignFields on CampaignNode {
    id
    name
    utm
    startDate
    endDate
    customer
    status
    orderId
    type
    isDeleted
  }
`;

export const ASSET_FIELDS = gql`
  fragment AssetFields on AssetNode {
    id
    assetId
    channelType
    name
    channelData {
      trackingUrl
      imageLarge
      imageSmall
    }
    targetingConfig
    startDate
    endDate
    visibility
    targetingFilters {
      location {
        country
        zip
        region
        city
        radius
      }
      user {
        gender
        registration {
          date
          operator
        }
        userIds
      }
      group {
        graduationType
        instituteType
        graduationYear
        members {
          value
          operator
        }
        groupIds
        teams
      }
      type
      operator
    }
    totalViews
    totalClicks
    conversionRate
  }
`;

export const MATCHED_ASSET_FIELDS = gql`
  fragment MatchedAssetFields on MatchedAssetNode {
    id
    assetId
    channelType
    name
    channelData {
      trackingUrl
      imageLarge
      imageSmall
    }
    targetingConfig
    startDate
    endDate
    visibility
    isActive
    targetingFilters {
      location {
        country
        zip
        region
        city
        radius
      }
      user {
        gender
        registration {
          date
          operator
        }
        userIds
      }
      group {
        graduationType
        instituteType
        graduationYear
        members {
          value
          operator
        }
        groupIds
        teams
      }
      type
      operator
    }
  }
`;

export const CAMPAIGN_DETAILS_FIELDS = gql`
  ${ASSET_FIELDS}
  fragment CampaignDetailsFields on CampaignNode {
    id
    name
    utm
    startDate
    endDate
    type
    customer
    orderId
    totalViews
    totalClicks
    status
    assets {
      ...AssetFields
    }
    conversionRate
  }
`;

export const CAMPAIGNS = gql`
  ${CAMPAIGN_FIELDS}

  query Campaigns(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $searchFilter: String
    $orderBy: String
  ) {
    campaigns(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      searchFilter: $searchFilter
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...CampaignFields
        }
      }
      totalCount
    }
  }
`;

export const CAMPAIGN = gql`
  ${CAMPAIGN_DETAILS_FIELDS}

  query Campaign($id: ID!) {
    campaign(id: $id) {
      ...CampaignDetailsFields
    }
  }
`;

// createBannerAsset(input: CreateBannerAssetInput!): CreateBannerAssetPayload
// export const ADMIN_MOVE_GROUP_TO_INSTITUTE = gql`
//   ${ADMIN_GROUP_FIELDS}
//   mutation AdminMoveGroupToInstitute($input: MoveGroupToInstituteInput!) {
//     moveGroupToInstitute(input: $input) {
//       group {
//         ...AdminGroupFields
//       }
//     }
//   }
// `;

export const CREATE_BANNER_ASSET = gql`
  ${ASSET_FIELDS}
  mutation CreateBannerAsset($input: CreateBannerAssetInput!) {
    createBannerAsset(input: $input) {
      asset {
        ...AssetFields
      }
    }
  }
`;

export const CREATE_CAMPAIGN = gql`
  ${CAMPAIGN_FIELDS}
  mutation CreateCampaign($input: CreateCampaignInput!) {
    createCampaign(input: $input) {
      campaign {
        ...CampaignFields
      }
    }
  }
`;

export const ASSET = gql`
  ${ASSET_FIELDS}
  query Asset($id: ID!) {
    asset(id: $id) {
      ...AssetFields
    }
  }
`;

export const EDIT_BANNER_ASSET = gql`
  ${ASSET_FIELDS}
  mutation EditBannerAsset($input: EditBannerAdAssetInput!) {
    editBannerAdAsset(input: $input) {
      asset {
        ...AssetFields
      }
    }
  }
`;

export const DUPLICATE_BANNER_ASSET = gql`
  ${ASSET_FIELDS}
  mutation DuplicateBannerAsset($input: DuplicateAssetInput!) {
    duplicateAsset(input: $input) {
      newAsset {
        ...AssetFields
      }
    }
  }
`;

export const DELETE_BANNER_ASSET = gql`
  mutation DeleteBannerAsset($input: DeleteAssetInput!) {
    deleteAsset(input: $input) {
      success
      assetId
    }
  }
`;

export const BEST_MATCH_ASSET = gql`
  ${MATCHED_ASSET_FIELDS}
  query BestMatchAsset($channelType: ChannelType, $userId: String) {
    bestMatchAssets(channelType: $channelType, userId: $userId) {
      ...MatchedAssetFields
    }
  }
`;

export const CREATE_ASSET_EVENT = gql`
  mutation CreateAssetEvent($input: CreateAssetEventInput!) {
    createAssetEvent(input: $input) {
      success
    }
  }
`;

export const REGIONS = gql`
  query Regions($country: String) {
    regions(country: $country) {
      region
      country
    }
  }
`;

export const EDIT_CAMPAIGN = gql`
  ${CAMPAIGN_FIELDS}
  mutation EditCampaign($input: EditCampaignInput!) {
    editCampaign(input: $input) {
      campaign {
        ...CampaignFields
      }
    }
  }
`;

export const DELETE_CAMPAIGN = gql`
  mutation DeleteCampaign($input: DeleteCampaignInput!) {
    deleteCampaign(input: $input) {
      success
    }
  }
`;

export const STATISTICS = gql`
  query Statistics($filter: DashboardFilterInput) {
    statistics(filter: $filter) {
      institutesCount
      usersCount
      activeGroupsCount
      inactiveGroupsCount
    }
  }
`;

export const SIGNUPS = gql`
  query Signups($filter: DashboardFilterInput) {
    signups(filter: $filter) {
      country
      counts {
        date
        count
      }
    }
  }
`;

export const K_P_IS = gql`
  query KPIs($filter: DashboardFilterInput) {
    kpis(filter: $filter) {
      averageStudentsPerGroup
      groupTimeToActive
      profileQuestionsPerYear {
        year
        average
        instances
        groups
      }
      quotesPerGroupPerYear {
        year
        average
        instances
        groups
      }
      rankingQuestionsPerGroupPerYear {
        year
        average
        instances
        groups
      }
      albumsPerGroupPerYear {
        year
        average
        instances
        groups
      }
      photosPerGroupPerYear {
        year
        average
        instances
        groups
      }
    }
  }
`;
