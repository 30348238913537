import React from 'react';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { ReactNativeFile } from 'apollo-upload-client';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { DEFAULT_LIST_OFFSET } from '~/data/constants';
import useAuthTeams from '~/data/hooks/useAuthTeams';
import { Owner } from '~/data/operations/global';
import { useCreatePollMutation } from '~/data/operations/post/createPoll';
import { useCreatePollOptionMutation } from '~/data/operations/post/createPollOption';
import {
  readPollsQuery,
  writePollsQuery,
  readPollFragment,
  writePollFragment,
  readPostsQuery,
  writePostsQuery,
} from '~/data/operations/post/helpers';
import { useTeamQuery } from '~/data/operations/team/team';
import { RootStackParamList } from '~/navigation/types';
import { getFileName } from '~/utils/helpers';
import { t } from '~/utils/i18n';
import CreatePollLayout, { CreatePollInputProps } from './layout';

type CreatePollNavProp = StackNavigationProp<RootStackParamList, 'CreatePoll'>;

type CreatePollRouteProp = RouteProp<RootStackParamList, 'CreatePoll'>;

export default function CreatePoll(): JSX.Element {
  const navigation = useNavigation<CreatePollNavProp>();
  const route = useRoute<CreatePollRouteProp>();
  const lockedTeamId = route.params?.teamId;

  const { authGroupId, authUserGroupId, authUserGroup } = useAuth();

  const { teams } = useAuthTeams({ skip: !!lockedTeamId });

  const { data: teamData } = useTeamQuery({
    skip: !lockedTeamId,
    variables: {
      id: lockedTeamId as string,
    },
  });

  const lockedTeam = teamData?.team;

  const [createPoll, { loading: createPollLoading }] = useCreatePollMutation({
    update(cache, { data }) {
      const newPoll = data?.createPoll?.poll;

      if (lockedTeamId) {
        //Update query from TeamDetail screen

        const postsVar = {
          after: null,
          first: DEFAULT_LIST_OFFSET,
          team: lockedTeamId,
          authUserGroupId,
        };

        const currentPosts = readPostsQuery({
          cache,
          variables: postsVar,
        });

        if (
          currentPosts &&
          currentPosts.posts &&
          currentPosts.posts.edges &&
          newPoll?.post
        ) {
          const newPost = {
            ...newPoll?.post,
            poll: newPoll,
          };

          writePostsQuery({
            cache,
            variables: postsVar,
            data: {
              ...currentPosts,
              posts: {
                ...currentPosts.posts,
                edges: [
                  {
                    __typename: 'PostNodeEdge',
                    node: newPost,
                  },
                  ...currentPosts.posts.edges,
                ],
              },
            },
          });
        }
      } else {
        //Update query from Polls screen

        const pollsVar = {
          after: null,
          first: DEFAULT_LIST_OFFSET,
          post_CreatedBy_Group: authGroupId,
          authUserGroupId,
        };

        const currentPolls = readPollsQuery({
          cache,
          variables: pollsVar,
        });

        if (currentPolls?.polls?.edges && newPoll) {
          writePollsQuery({
            cache,
            variables: pollsVar,
            data: {
              ...currentPolls,
              polls: {
                ...currentPolls.polls,
                edges: [
                  {
                    __typename: 'PollNodeEdge',
                    node: newPoll,
                  },
                  ...currentPolls.polls.edges,
                ],
              },
            },
          });
        }
      }
    },
  });

  const [createPollOption, { loading: createPollOptionLoading }] =
    useCreatePollOptionMutation();

  const onCreatePoll = async ({
    selectedTeamId,
    question,
    deadline,
    answersAllowed,
    randomizeOptions,
    allowRevote,
    allowComments,
    anonymousVotes,
    privateResults,
    options,
  }: CreatePollInputProps) => {
    try {
      const { data } = await createPoll({
        variables: {
          input: {
            owner: selectedTeamId ? Owner.TEAM : Owner.USER,
            team: selectedTeamId,
            group: authGroupId,
            question,
            deadline,
            answersAllowed,
            randomizeOptions,
            allowRevote,
            allowComments,
            anonymousVotes,
            privateResults,
          },
          authUserGroupId,
        },
      });
      const pollId = data?.createPoll?.poll?.id;
      if (!pollId) {
        const messages = data?.createPoll?.errors?.map(
          (error) => error?.messages[0],
        );
        const errorMessage = messages?.[0] as string;
        Snackbar.show(errorMessage);
        return;
      }

      let i;
      for (i = 0; i < options.length; i++) {
        const option = options[i];
        const imageUri = option.imgUrl;

        let imageFile = null;
        if (imageUri) {
          imageFile = new ReactNativeFile({
            uri: imageUri,
            type: 'image/jpeg',
            name: getFileName(imageUri),
          });
        }

        await createPollOption({
          variables: {
            input: {
              poll: pollId,
              text: option.text,
              image: imageFile,
            },
          },
          update(cache, { data }) {
            const newPollOption = data?.createPollOption?.pollOption;

            const currentPoll = readPollFragment({
              cache,
              id: pollId,
              authUserGroupId,
            });

            if (currentPoll && currentPoll.options && newPollOption) {
              writePollFragment({
                cache,
                id: pollId,
                data: {
                  ...currentPoll,
                  options: [...currentPoll.options, newPollOption],
                },
              });
            }
          },
        });
      }
      navigation.goBack();
      Snackbar.show(t('createPoll.createPollSuccess'));
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  return (
    <CreatePollLayout
      loggedUserGroup={authUserGroup}
      teams={teams}
      lockedTeam={lockedTeam}
      loading={createPollLoading || createPollOptionLoading}
      onBack={() => navigation.goBack()}
      onCreatePoll={(pollInput) => onCreatePoll(pollInput)}
    />
  );
}
