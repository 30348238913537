/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation UpdateCollagesAlbum
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  UpdateCollagesAlbum,
  UpdateCollagesAlbumVariables,
} from './types/UpdateCollagesAlbum';
import { UPDATE_COLLAGES_ALBUM } from './';

export function useUpdateCollagesAlbumMutation(
  options?: MutationHookOptions<
    UpdateCollagesAlbum,
    UpdateCollagesAlbumVariables
  >,
): MutationTuple<UpdateCollagesAlbum, UpdateCollagesAlbumVariables> {
  return useMutation<UpdateCollagesAlbum, UpdateCollagesAlbumVariables>(
    UPDATE_COLLAGES_ALBUM,
    options,
  );
}

export function createUpdateCollagesAlbumMock({
  variables,
  data,
  error,
}: {
  variables?: UpdateCollagesAlbumVariables;
  data?: UpdateCollagesAlbum;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: UPDATE_COLLAGES_ALBUM,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
