import React from 'react';
import { PageFormat } from '~/data/operations/global';
import { t } from '~/utils/i18n';
import { Container, Text, FormatViewContainer, FormatView } from './style';

type PhotoFormatCellProps = {
  testID?: string;
  format: PageFormat;
  selected: boolean;
  width?: number;
  onPress: () => void;
};

type TextMap = { [key in PageFormat]: string };
type FormatMap = { [key in PageFormat]: React.ReactNode };
export default function PhotoSizeCell({
  testID,
  format,
  selected,
  width = 140,
  onPress,
}: PhotoFormatCellProps): JSX.Element {
  const FormatPortrait = (
    <FormatView width={36} height={50} center selected={selected} />
  );
  const FormatSquare = (
    <>
      <FormatView width={36} height={50} selected={selected} />
      <FormatView width={36} height={25} dark={true} selected={selected} />
    </>
  );
  const FormatAsUploaded = (
    <FormatView width={50} height={50} selected={selected} />
  );

  const formatMap: FormatMap = {
    PORTRAIT: FormatPortrait,
    LANDSCAPE: FormatSquare,
    CUSTOM: FormatAsUploaded,
  };

  const photoFormat = formatMap[format];

  const textMap: TextMap = {
    PORTRAIT: t('g.a4format'),
    LANDSCAPE: t('g.a5format'),
    CUSTOM: t('g.custom'),
  };
  const text = textMap[format];
  return (
    <Container
      testID={testID}
      width={width}
      selected={selected}
      onPress={onPress}
    >
      <FormatViewContainer>{photoFormat}</FormatViewContainer>
      <Text selected={selected}>{text}</Text>
    </Container>
  );
}
