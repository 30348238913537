import React from 'react';
import { useTheme } from 'styled-components/native';
import { IconStyledName } from '~/components/IconStyled';
import Icon, { IconName } from '../Icon';
import {
  Container,
  IconWrapper,
  StyledIcon,
  TabBarBadge,
  Title,
} from './style';

// Note: Turkey Entrance part has no new design for now, so this component support for both one and multicolor icons. In V2 design, the tabs are not multicolor anymore so we can stop to support styled icon after we switch to V2 design totally.

export type TabBarStyledIconName =
  | 'flame'
  | 'planning'
  | 'book'
  | 'user'
  | 'calendar'
  | 'jewelry'
  | 'graduation';

type TabBarItemProps = {
  tabStyledIcon?: TabBarStyledIconName;
  tabIcon?: IconName;
  label: string;
  isFocused: boolean;
  onPress: () => void;
  onLongPress?: (label: string) => void;
  tabBarBadge?: number;
};

export default function TabBarItem({
  tabStyledIcon,
  tabIcon,
  label,
  isFocused,
  onPress,
  onLongPress,
  tabBarBadge,
}: TabBarItemProps): JSX.Element {
  const styledIconVariant = isFocused ? '_primary' : '_base';
  const styledIconName: IconStyledName | undefined =
    tabStyledIcon && `${tabStyledIcon}${styledIconVariant}`;

  const theme = useTheme();
  const color = isFocused ? theme.color.brand_02 : theme.color.base.c10;

  return (
    <Container
      onPress={onPress}
      onLongPress={() => onLongPress && onLongPress(label)}
    >
      <IconWrapper>
        {tabStyledIcon && styledIconName && (
          <StyledIcon name={styledIconName} isFocused={isFocused} />
        )}
        {tabIcon && <Icon name={tabIcon} color={color} />}

        {Boolean(tabBarBadge) && <TabBarBadge>{tabBarBadge}</TabBarBadge>}
      </IconWrapper>
      <Title isFocused={isFocused}>{label}</Title>
    </Container>
  );
}
