import React, { useRef } from 'react';
import { FlatListProps } from 'react-native';
import { Modalize } from 'react-native-modalize';
import { Portal } from 'react-native-portalize';
import { useTheme } from 'styled-components/native';
import { general } from '~/assets/imgs';
import Button from '~/components/Button';
import { getImageFromLibrary } from '~/components/ImagePicker';
import { t } from '~/utils/i18n';
import { Loading } from '../common/style';
import {
  Title,
  Footer,
  Container,
  ImageSeperator,
  ImageItem,
  IMAGE_CONTAINER_HEIGHT,
  COLUMN_NUM,
  ITEM_HORIZONTAL_SPACE,
  UploadIcon,
  UploadIconLabel,
  UploadItemButton,
  UnPickIcon,
  UnPickButton,
  PADDING_FOR_UNSELECT_ICON,
  PickedImageCover,
  PickedIconLabel,
  PickedIcon,
  ImageItemButton,
  SelectedImageWrapper,
  SelectedImage,
  UploadButtonIcon,
  SmallImage,
  SelectedImagePressable,
  UploadBox,
  CenterLayer,
} from './style';

type ModalCustomIconPickerHandler = Modalize;

export type CustomIcon = {
  id: string;
  uri: string | null;
};

type SelectIconInputs = {
  customIcon: CustomIcon | null;
  imgUrl: string | null;
};

type CustomIconPickerProps = {
  testID?: string;
  selectedIconStyle?: 'default' | 'create_group';
  modalTitle: string;
  customIcons: CustomIcon[];
  selectedIcon?: CustomIcon | null;
  selectedUri?: string | null;
  center?: boolean;
  loading?: boolean;
  onSelect: (inputs: SelectIconInputs) => void;
};

const uploadButtonItem: CustomIcon = {
  id: 'upload',
  uri: null,
};

export default function CustomIconPicker({
  testID,
  selectedIconStyle,
  modalTitle,
  customIcons,
  selectedIcon = null,
  selectedUri = null,
  center,
  loading,
  onSelect,
}: CustomIconPickerProps) {
  const modalizeGroupPickerRef = useRef<ModalCustomIconPickerHandler>(null);

  const theme = useTheme();
  const iconList = [uploadButtonItem, ...customIcons];
  const [pickedIcon, setPickedIcon] = React.useState<CustomIcon | null>(
    () => selectedIcon,
  );
  const [pickedUplodedImage, setPickedIploadedImage] = React.useState<
    string | null
  >(() => selectedUri);

  const onPickUploadImage = async () => {
    const imgUris = await getImageFromLibrary();

    const uri = imgUris?.[0];
    if (uri) {
      setPickedIploadedImage(uri);
      setPickedIcon(null);
    }
  };

  const onPickedIcon = (icon: CustomIcon) => {
    setPickedIcon(icon);
    setPickedIploadedImage(null);
  };

  const onUnpicked = () => {
    setPickedIcon(null);
    setPickedIploadedImage(null);
  };

  const onSelected = () => {
    onSelect({
      customIcon: pickedIcon,
      imgUrl: pickedUplodedImage,
    });
  };

  const renderHeader = () => {
    return <Title>{modalTitle}</Title>;
  };

  const renderFooter = () => {
    return (
      <Footer>
        <Button
          text={modalTitle}
          size={'2xl'}
          type={'secondary-contrast'}
          flex
          onPress={() => {
            modalizeGroupPickerRef.current?.close();
            onSelected();
          }}
        />
      </Footer>
    );
  };

  const renderItem = ({ item: customIcon }: { item: CustomIcon }) => {
    const isPicked = pickedIcon?.id === customIcon.id;

    if (customIcon.id === 'upload')
      return pickedUplodedImage ? (
        <>
          <ImageItem source={{ uri: pickedUplodedImage }} />
          <PickedImageCover>
            <UnPickButton onPress={onUnpicked}>
              <UnPickIcon />
            </UnPickButton>
            <PickedIcon />
            <PickedIconLabel>{t('createGroup.selected')}</PickedIconLabel>
          </PickedImageCover>
        </>
      ) : (
        <UploadItemButton onPress={onPickUploadImage}>
          <UploadIcon color={theme.color.brand_02} />
          <UploadIconLabel>{t('createGroup.uploadIconLabel')}</UploadIconLabel>
        </UploadItemButton>
      );

    return (
      <ImageItemButton onPress={() => onPickedIcon(customIcon)}>
        <ImageItem source={{ uri: customIcon.uri || undefined }} />
        {isPicked && (
          <PickedImageCover>
            <UnPickButton onPress={onUnpicked}>
              <UnPickIcon />
            </UnPickButton>
            <PickedIcon />
            <PickedIconLabel>{t('createGroup.selected')}</PickedIconLabel>
          </PickedImageCover>
        )}
      </ImageItemButton>
    );
  };

  const flatListProps: FlatListProps<CustomIcon> = {
    style: {
      maxHeight: IMAGE_CONTAINER_HEIGHT,
      padding: PADDING_FOR_UNSELECT_ICON,
    },
    numColumns: COLUMN_NUM,
    data: iconList,
    renderItem: renderItem,
    keyExtractor: (item) => item.id,
    showsVerticalScrollIndicator: false,
    ItemSeparatorComponent: () => <ImageSeperator />,
    columnWrapperStyle: {
      gap: ITEM_HORIZONTAL_SPACE,
    },
  };

  const selectedImageUri = pickedUplodedImage || pickedIcon?.uri || undefined;

  return (
    <>
      {(() => {
        if (selectedIconStyle === 'create_group') {
          return (
            <SelectedImageWrapper lg>
              <SelectedImagePressable
                onPress={() => {
                  modalizeGroupPickerRef.current?.open();
                }}
              >
                <SelectedImage
                  testID="selectedImage"
                  lg
                  source={{
                    uri: selectedImageUri,
                  }}
                >
                  {!selectedImageUri && (
                    <UploadBox>
                      <UploadIcon color={theme.color.base.c0} />
                    </UploadBox>
                  )}
                </SelectedImage>
              </SelectedImagePressable>
              <SmallImage source={general.double_hearts} />
            </SelectedImageWrapper>
          );
        }
        return (
          <SelectedImageWrapper center={center}>
            <SelectedImagePressable
              onPress={() => {
                modalizeGroupPickerRef.current?.open();
              }}
            >
              <SelectedImage
                testID="selectedImage"
                source={{
                  uri: selectedImageUri,
                }}
              >
                {loading && (
                  <CenterLayer>
                    <Loading light />
                  </CenterLayer>
                )}
              </SelectedImage>
              <UploadButtonIcon
                onPress={() => {
                  modalizeGroupPickerRef.current?.open();
                }}
              />
            </SelectedImagePressable>
          </SelectedImageWrapper>
        );
      })()}
      <Container testID={testID}>
        <Portal>
          <Modalize
            modalStyle={{
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
              paddingHorizontal: 16,
              backgroundColor: theme.color.base.c0,
            }}
            modalHeight={IMAGE_CONTAINER_HEIGHT + 230}
            HeaderComponent={renderHeader()}
            FooterComponent={renderFooter()}
            ref={modalizeGroupPickerRef}
            handlePosition={'inside'}
            flatListProps={flatListProps}
          />
        </Portal>
      </Container>
    </>
  );
}
