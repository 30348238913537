import styled from 'styled-components/native';

interface IProgress {
  width: number;
  fullWidth?: boolean;
}

export const Container = styled.View<IProgress>`
  height: 8px;
  width: ${({ width, fullWidth }) => (fullWidth ? 'auto' : `${width}px`)};
  background-color: ${({ theme }) => theme.color.base.c2};
  border-radius: 4px;
  overflow: hidden;
  margin-vertical: 2px;
`;

interface IViewProgress extends IProgress {
  total: number;
  progress: number;
}
export const ViewProgress = styled.View.attrs<IViewProgress>(({ theme }) => ({
  colors: theme.gradient.primary.cs01,
  start: { x: 0, y: 0 },
  end: { x: 1, y: 0 },
}))<IViewProgress>`
  height: 8px;
  background-color: ${({ theme }) => theme.color.brand_01};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.color.brand_01};
  width: ${({ total, progress, width, fullWidth }) =>
    fullWidth
      ? `${(progress / total) * 100}%`
      : `${(progress / total) * width}px`};
`;
