import styled from 'styled-components/native';
import { ModalScreenContainer } from '~/screens/style';

export const Container = styled(ModalScreenContainer)`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const ScrollView = styled.ScrollView`
  padding-horizontal: 16px;
`;

export const Descp = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c7};
  font-size: 16px;
  line-height: 24px;
  margin-vertical: 16px;
`;

export const Image = styled.Image`
  margin-vertical: 32px;
  width: 100%;
  height: 226px;
  border-radius: 15px;
`;

export const HistoryView = styled.View`
  align-items: flex-start;
`;

export const Footer = styled.View`
  padding-horizontal: 16px;
  padding-bottom: ${({ theme }) => theme.safeAreaInsets.bottom + 16}px;
`;

export const AdsText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 12px;
  color: ${({ theme }) => theme.color.base.c5};
  margin-right: 15px;
  margin-bottom: -17px;
`;
