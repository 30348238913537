import { useState } from 'react';

type UsePaginationOptions = {
  totalItems: number;
  initialPage?: number;
  itemsPerPage: number;
};

/**
 * usePagination can be used everywhere clientside paginations is needed
 * API: setOptions to match your usecase and applyPaginationOnData(DATA_SET) on render
 */
const usePagination = ({
  initialPage = 1,
  totalItems,
  itemsPerPage,
}: UsePaginationOptions) => {
  const [currentPage, setCurrentPage] = useState<number>(
    initialPage > 0 ? initialPage : 0,
  );

  const totalNumberOfPages = Math.ceil(totalItems / itemsPerPage);

  const applyPaginationOnData = <T>(data: T[]) => {
    const firstIndex = (currentPage - 1) * itemsPerPage;
    let lastIndex = itemsPerPage * (currentPage + 1) - itemsPerPage;
    // Ensures that we do not overflow the index but rather take the last item
    lastIndex = lastIndex > totalItems ? totalItems : lastIndex;
    return data.slice(firstIndex, lastIndex);
  };

  const updatePage = (newPage: number) =>
    setCurrentPage((currentPage) => {
      const pageOverflow = newPage > totalNumberOfPages;
      const pageNegative = newPage <= 0;
      return pageOverflow || pageNegative ? currentPage : newPage;
    });

  const hasNextPage = (currentPage: number, totalNumberOfPages: number) =>
    currentPage < totalNumberOfPages;

  const hasPrevPage = (currentPage: number) => currentPage > 1;

  const incrementPage = () =>
    hasNextPage(currentPage, totalNumberOfPages) && updatePage(currentPage + 1);
  const decrementPage = () =>
    hasPrevPage(currentPage) && updatePage(currentPage - 1);

  return {
    currentPage,
    applyPaginationOnData,
    totalNumberOfPages,
    incrementPage,
    decrementPage,
    allowNextPage: hasNextPage(currentPage, totalNumberOfPages),
    allowPrevPage: hasPrevPage(currentPage),
    updatePage,
    resetPagination: () => setCurrentPage(initialPage),
  };
};

export default usePagination;
