import React, { useState } from 'react';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { DEFAULT_LIST_OFFSET, NO_PAG_LIST_OFFSET } from '~/data/constants';
import { CustomListOption } from '~/data/models/yearbook';
import { useCreateCustomListMutation } from '~/data/operations/yearbook/createCustomList';
import { useCustomListOptionsQuery } from '~/data/operations/yearbook/customListOptions';
import { useDeleteCustomListMutation } from '~/data/operations/yearbook/deleteCustomList';
import { useDeleteCustomListOptionMutation } from '~/data/operations/yearbook/deleteCustomListOption';
import {
  readCustomListOptionsQuery,
  readCustomListsQuery,
  writeCustomListOptionsQuery,
  writeCustomListsQuery,
} from '~/data/operations/yearbook/helpers';
import { useUpdateCustomListMutation } from '~/data/operations/yearbook/updateCustomList';
import { YearbookStackParamList } from '~/navigation/types';
import RankingsCustomListLayout from './layout';

type RankingsCustomListNavProp = StackNavigationProp<
  YearbookStackParamList,
  'RankingsCustomList'
>;

type RankingsCustomListRouteProp = RouteProp<
  YearbookStackParamList,
  'RankingsCustomList'
>;

export default function RankingsCustomList(): JSX.Element {
  const navigation = useNavigation<RankingsCustomListNavProp>();
  const {
    params: {
      moduleInstanceId,
      customListId: navCustomListId,
      customListTitle,
    },
  } = useRoute<RankingsCustomListRouteProp>();

  const [customListId, setCustomListId] = useState(() => navCustomListId);

  const listsVar = {
    first: NO_PAG_LIST_OFFSET,
    moduleInstance: moduleInstanceId,
  };

  const listOptionsVar = {
    after: null,
    first: DEFAULT_LIST_OFFSET,
    customList: customListId as string,
  };

  const {
    data: customListOptionsData,
    loading: listOptionsLoading,
    fetchMore: fetchMoreOptions,
  } = useCustomListOptionsQuery({
    skip: !customListId,
    variables: listOptionsVar,
    notifyOnNetworkStatusChange: true,
  });

  const customListOptions: CustomListOption[] =
    (customListOptionsData?.customListOptions?.edges.map(
      (edge) => edge?.node,
    ) as CustomListOption[]) || [];
  const listOptionsHasNextPage =
    customListOptionsData?.customListOptions?.pageInfo.hasNextPage;
  const listOptionsEndCursor =
    customListOptionsData?.customListOptions?.pageInfo.endCursor;

  const [createCustomList, { loading: createCustomListLoading }] =
    useCreateCustomListMutation({
      update(cache, { data }) {
        const newCustomList = data?.createCustomList?.customList;

        const customListsQuery = readCustomListsQuery({
          cache,
          variables: listsVar,
        });

        if (customListsQuery?.customLists?.edges && newCustomList) {
          writeCustomListsQuery({
            cache,
            variables: listsVar,
            data: {
              ...customListsQuery,
              customLists: {
                ...customListsQuery.customLists,
                edges: [
                  ...customListsQuery.customLists.edges,
                  {
                    __typename: 'CustomListNodeEdge',
                    node: newCustomList,
                  },
                ],
              },
            },
          });
        }
      },
    });

  const [updateCustomList, { loading: updateCustomListLoading }] =
    useUpdateCustomListMutation();

  const [deleteCustomList, { loading: deleteCustomListLoading }] =
    useDeleteCustomListMutation({
      update(cache) {
        const customListsQuery = readCustomListsQuery({
          cache,
          variables: listsVar,
        });

        if (customListsQuery?.customLists?.edges && customListId) {
          writeCustomListsQuery({
            cache,
            variables: listsVar,
            data: {
              ...customListsQuery,
              customLists: {
                ...customListsQuery.customLists,
                edges: customListsQuery.customLists.edges.filter(
                  (edge) => edge?.node?.id !== customListId,
                ),
              },
            },
          });
        }
      },
    });

  const [deleteCustomListOption] = useDeleteCustomListOptionMutation();

  const onSaveList = async (title: string, navBack: boolean) => {
    try {
      if (customListId) {
        await updateCustomList({
          variables: {
            input: {
              id: customListId,
              title,
            },
          },
        });
      } else {
        const { data } = await createCustomList({
          variables: {
            input: {
              moduleId: moduleInstanceId,
              title,
            },
          },
        });

        setCustomListId(data?.createCustomList?.customList?.id);
      }
      navBack && navigation.goBack();
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onDeleteList = async () => {
    try {
      if (customListId) {
        await deleteCustomList({
          variables: {
            input: {
              id: customListId,
            },
          },
        });
      }
      navigation.goBack();
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onDeleteOption = async (optionId: string) => {
    try {
      await deleteCustomListOption({
        variables: {
          input: {
            id: optionId,
          },
        },
        update(cache) {
          const customListOptionsQuery = readCustomListOptionsQuery({
            cache,
            variables: listOptionsVar,
          });

          if (
            customListOptionsQuery?.customListOptions?.edges &&
            customListId
          ) {
            writeCustomListOptionsQuery({
              cache,
              variables: listOptionsVar,
              data: {
                ...customListOptionsQuery,
                customListOptions: {
                  ...customListOptionsQuery.customListOptions,
                  edges: customListOptionsQuery.customListOptions.edges.filter(
                    (edge) => edge?.node?.id !== optionId,
                  ),
                },
              },
            });
          }
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onLoadMoreOptions = () => {
    listOptionsHasNextPage &&
      !listOptionsLoading &&
      fetchMoreOptions({
        variables: {
          ...listOptionsVar,
          after: listOptionsEndCursor,
        },
      });
  };

  return (
    <RankingsCustomListLayout
      initialTitle={customListTitle}
      deleteEnabled={!!customListId}
      customListOptions={customListOptions}
      loading={
        createCustomListLoading ||
        updateCustomListLoading ||
        deleteCustomListLoading
      }
      listOptionsLoading={listOptionsLoading}
      onBack={() => navigation.goBack()}
      onSaveList={onSaveList}
      onDeleteList={onDeleteList}
      onAddOption={() =>
        customListId &&
        navigation.navigate('RankingsCustomListOption', {
          customListId,
        })
      }
      onDeleteOption={onDeleteOption}
      onLoadMoreOptions={onLoadMoreOptions}
    />
  );
}
