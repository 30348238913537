import React, { useEffect, useState } from 'react';
import Badge from '~/components/Badge';
import Button from '~/components/Button';
import {
  FileDetails,
  getFileWithDetailsFromLibrary,
} from '~/components/ImagePicker';
import InputField from '~/components/InputField';
import NavHeader from '~/components/NavHeader';
import PDFViewer from '~/components/PDFViewer';
import PhotoSizeCell from '~/components/PhotoSizeCells';
import { Spacer, Container } from '~/components/common/style.web';
import {
  PageFormat,
  PageType,
  UpdateCustomPagesInstanceInput,
} from '~/data/operations/global';
import { CustomPagesInstance } from '~/data/operations/yearbook/types/CustomPagesInstance';
import { t } from '~/utils/i18n';
import { ImageQualityBadgeColor } from '../../CreateCustomPage/layout';
import {
  ButtonCreate,
  Section,
  ImageContainer,
  ImageContent,
  ImageBanner,
  DeleteIconContainer,
  DeleteIcon,
  FormatSection,
  PhotoFormatContainer,
  PhotoFormatDescription,
  ImageDescriptionContainer,
  ImageDescription,
} from '../../CreateCustomPage/layout/style';
import { PHOTO_FORMAT_CELL_WIDTH } from '../../ProfilePageSetup/layout/style';
import { ContainerWrapper } from './style';

type EditCustomPageLayoutProps = {
  onBack: () => void;
  customPageData?: CustomPagesInstance;
  onEditCustomPage: (data: UpdateCustomPagesInstanceInput) => void;
  onDeleteCustomPage: () => void;
};

const photoFormats: PageFormat[] = [
  PageFormat.PORTRAIT,
  PageFormat.LANDSCAPE,
  PageFormat.CUSTOM,
];
export default function EditCustomPage({
  onBack,
  onEditCustomPage,
  onDeleteCustomPage,
  customPageData,
}: EditCustomPageLayoutProps): JSX.Element {
  const [customPageTitle, setCustomPageTitle] = useState(
    customPageData?.customPagesInstance?.title || '',
  );
  const [imgUri, setImgUri] = useState<FileDetails>({
    uri: customPageData?.customPagesInstance?.page || '',
    quality: customPageData?.customPagesInstance?.pageQuality || undefined,
    type: customPageData?.customPagesInstance?.pageType || undefined,
  });
  const [selectedPhotoFormat, setSelectedPhotoFormat] = useState<PageFormat>(
    customPageData?.customPagesInstance?.pageFormat || PageFormat.PORTRAIT,
  );

  // we will use this when we have a function to check the image quality
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [customPageImageQuality, setCustomPageImageQuality] = useState(
    customPageData?.customPagesInstance?.pageQuality || '',
  );

  const onPickImage = async () => {
    const imgUri = await getFileWithDetailsFromLibrary();
    setImgUri(imgUri?.[0]);
  };
  const isImgUriPdf =
    imgUri?.type === 'application/pdf' || imgUri?.type === PageType.PDF;
  useEffect(() => {
    if (customPageTitle && imgUri && selectedPhotoFormat) {
      onEditCustomPage({
        id: customPageData?.customPagesInstance?.id || '',
        title: customPageTitle,
        pageFormat: selectedPhotoFormat,
      });
    }
  }, [customPageTitle, imgUri, selectedPhotoFormat]);

  const onRemoveImage = () => {
    setImgUri({
      name: '',
      type: '',
      uri: '',
      size: '',
      quality: undefined,
    });
  };

  return (
    <Container>
      <NavHeader
        backButtonTestID={'buttonBack'}
        title={t('screens.createCustomPage')}
        onBackPress={onBack}
      />
      <ContainerWrapper>
        <Section title={t('createCustomPage.titleSection')} />
        <InputField
          testID={'CustomPageTitle'}
          value={customPageTitle}
          onChangeText={(query) => setCustomPageTitle(query)}
          label={t('createCustomPage.titleInput')}
        />
        <ImageContainer>
          <ImageContent>
            {isImgUriPdf ? (
              <PDFViewer
                uri={imgUri?.uri}
                hidePreview={true}
                height={170}
                width={121}
              />
            ) : (
              <ImageBanner
                key={imgUri.uri}
                testID={`PagePhotoCategoryCardImage`}
                source={{ uri: imgUri?.uri }}
              ></ImageBanner>
            )}
          </ImageContent>
          {imgUri?.uri && (
            <DeleteIconContainer onPress={onRemoveImage}>
              <DeleteIcon />
            </DeleteIconContainer>
          )}
          <ImageDescriptionContainer>
            {imgUri?.uri && (
              <ImageDescription>
                {`${t('createCustomPage.imageType')} ${
                  imgUri?.type ? t(`createCustomPage.${imgUri.type}`) : ''
                }`}
              </ImageDescription>
            )}

            {imgUri?.uri && !isImgUriPdf && (
              <>
                <ImageDescription>{`${t('createCustomPage.imageSize')} ${
                  imgUri?.size ? imgUri?.size : ''
                }`}</ImageDescription>
                <ImageDescription>
                  {`${t('createCustomPage.imageQuality')}`}{' '}
                  {imgUri?.quality && (
                    <Badge
                      colorTheme={
                        ImageQualityBadgeColor[imgUri?.quality as string]
                      }
                      text={customPageImageQuality as string}
                    />
                  )}
                </ImageDescription>
              </>
            )}
          </ImageDescriptionContainer>
        </ImageContainer>
        <Button
          text={t('createCustomPage.uploadImage')}
          onPress={onPickImage}
          type="secondary-base"
          size="sm"
          minWidth={360}
        />
        <FormatSection title={t('createCustomPage.formatSection')} />
        <PhotoFormatContainer>
          {photoFormats.map((photoFormat) => (
            <PhotoSizeCell
              key={photoFormat}
              testID={`buttonFormat${photoFormat}`}
              format={photoFormat}
              selected={photoFormat === selectedPhotoFormat}
              width={PHOTO_FORMAT_CELL_WIDTH}
              onPress={() => setSelectedPhotoFormat(photoFormat)}
            />
          ))}
        </PhotoFormatContainer>
        <PhotoFormatDescription>
          {t('createCustomPage.formatDescription')}
        </PhotoFormatDescription>
        <FormatSection title={t('createCustomPage.dangerZone')} />
        <Spacer v={15} />
        <ButtonCreate
          text={t('createCustomPage.deleteBtn')}
          onPress={onDeleteCustomPage}
          size="lg"
          minWidth={360}
          type={'destructive'}
        />
        <Spacer v={20} />
      </ContainerWrapper>
    </Container>
  );
}
