/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation DestroyProfilePagePhoto
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  DestroyProfilePagePhoto,
  DestroyProfilePagePhotoVariables,
} from './types/DestroyProfilePagePhoto';
import { DESTROY_PROFILE_PAGE_PHOTO } from './';

export function useDestroyProfilePagePhotoMutation(
  options?: MutationHookOptions<
    DestroyProfilePagePhoto,
    DestroyProfilePagePhotoVariables
  >,
): MutationTuple<DestroyProfilePagePhoto, DestroyProfilePagePhotoVariables> {
  return useMutation<DestroyProfilePagePhoto, DestroyProfilePagePhotoVariables>(
    DESTROY_PROFILE_PAGE_PHOTO,
    options,
  );
}

export function createDestroyProfilePagePhotoMock({
  variables,
  data,
  error,
}: {
  variables?: DestroyProfilePagePhotoVariables;
  data?: DestroyProfilePagePhoto;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: DESTROY_PROFILE_PAGE_PHOTO,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
