import { Animated } from 'react-native';
import Swipeable from 'react-native-swipeable';
import styled from 'styled-components/native';
import Icon from '../Icon';

const CELL_CONTENT_HEIGHT = 108;
const CELL_BOTTOM_MARGIN = 16;
export const CELL_CONTAINER_HEIGHT = CELL_CONTENT_HEIGHT + CELL_BOTTOM_MARGIN;

export const SWIPEABLE_BUTTON_WIDTH = 56;

const SwipeableContainer = styled(Swipeable)`
  overflow: hidden;
`;

export const AnimatedSwipeableContainer =
  Animated.createAnimatedComponent(SwipeableContainer);

const SwipeableButton = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})`
  flex: 1;
  width: ${SWIPEABLE_BUTTON_WIDTH}px;
  border-radius: 14px;
  align-items: center;
  justify-content: center;
  margin-bottom: ${CELL_BOTTOM_MARGIN}px;
`;

export const DeleteButton = styled(SwipeableButton)`
  background-color: ${({ theme }) => theme.color.base.c2};
`;

export const DeleteIcon = styled(Icon).attrs(({ theme }) => ({
  name: 'x-close',
  size: 24,
  color: theme.color.error,
}))``;

export const EditButton = styled(SwipeableButton)`
  background-color: ${({ theme }) => theme.color.base.c2};
`;

export const EditIcon = styled(Icon).attrs(({ theme }) => ({
  name: 'edit-05',
  size: 24,
  color: theme.color.brand_02,
}))``;

export const Container = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})`
  height: ${CELL_CONTENT_HEIGHT}px;
  background-color: ${({ theme }) => theme.color.base.c0};
  margin-horizontal: 16px;
  margin-bottom: ${CELL_BOTTOM_MARGIN}px;
  padding: 16px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.color.base.c3};
  border-radius: 14px;
  box-shadow: -3px 3px 2px ${({ theme }) => theme.color.base.c1};
`;

export const TitleWrapper = styled.View`
  flex-direction: row;
`;

export const Title = styled.Text`
  flex: 1;
  padding-left: 16px;
  color: ${({ theme }) => theme.color.base.c9};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 18px;
  line-height: 22px;
`;

export const Footer = styled.View`
  flex-direction: row;
  align-items: center;
  padding-top: 12.5px;
`;

export const ClockIcon = styled(Icon).attrs(({ theme }) => ({
  name: 'clock',
  size: 16,
  color: theme.color.base.c5,
}))`
  margin: 0px 8px;
`;

export const DueDate = styled.Text`
  flex: 1;
  color: ${({ theme }) => theme.color.base.c6};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 12px;
  line-height: 14.5px;
`;
