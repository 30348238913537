import React from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { useModuleInstanceQuery } from '~/data/operations/yearbook/moduleInstance';
import { useUpdateModuleInstanceMutation } from '~/data/operations/yearbook/updateModuleInstance';
import { useUpdateQuotesSetupMutation } from '~/data/operations/yearbook/updateQuotesSetup';
import { YearbookStackParamList } from '~/navigation/types';
import { t } from '~/utils/i18n';
import {
  ModuleSetupLayoutLoading,
  UpdateModuleInstanceInput,
} from '../layout/ModuleSetupLayout';
import QuotesSetupLayout, { UpdateQuotesSetupInput } from './layout';

type QuotesSetupNavProp = StackNavigationProp<
  YearbookStackParamList,
  'QuotesSetup'
>;
type QuotesSetupRouteProp = RouteProp<YearbookStackParamList, 'QuotesSetup'>;

export default function QuotesSetup(): JSX.Element {
  const navigation = useNavigation<QuotesSetupNavProp>();
  const {
    params: { moduleInstanceId },
  } = useRoute<QuotesSetupRouteProp>();

  const { data: moduleInstanceData, loading: moduleInstanceLoading } =
    useModuleInstanceQuery({
      variables: {
        id: moduleInstanceId,
      },
    });

  const moduleInstance = moduleInstanceData?.moduleInstance;
  const quotesSetupConfig = moduleInstanceData?.moduleInstance?.quotesSetup;

  const [updateModuleInstance] = useUpdateModuleInstanceMutation();
  const [updateQuotesSetup] = useUpdateQuotesSetupMutation();

  const onUpdateModuleInstance = async ({
    isActive,
    dueDate,
  }: UpdateModuleInstanceInput) => {
    try {
      await updateModuleInstance({
        variables: {
          input: {
            id: moduleInstanceId,
            isActive,
            dueDate,
          },
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onUpdateQuotesSetup = async ({
    anonymous,
    publicQuotes,
    maxChars,
  }: UpdateQuotesSetupInput) => {
    try {
      if (moduleInstance && quotesSetupConfig) {
        await updateQuotesSetup({
          variables: {
            input: {
              id: quotesSetupConfig.id,
              isActive: moduleInstance.isActive,
              dueDate: moduleInstance.dueDate,
              anonymous,
              public: publicQuotes,
              maxChars,
            },
          },
        });
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  if (!moduleInstance || !quotesSetupConfig || moduleInstanceLoading) {
    return (
      <ModuleSetupLayoutLoading
        title={t('screens.quotesSetup')}
        onBack={() => navigation.goBack()}
      />
    );
  }
  return (
    <QuotesSetupLayout
      moduleInstance={moduleInstance}
      quotesSetupConfig={quotesSetupConfig}
      onBack={() => navigation.goBack()}
      onUpdateModuleInstance={onUpdateModuleInstance}
      onUpdateQuotesSetup={onUpdateQuotesSetup}
    />
  );
}
