import React from 'react';
import { UserGroup } from '~/data/models/group';
import { Team } from '~/data/models/team';
import { User } from '~/data/models/user';
import { IconStyledName } from '../IconStyled';
import {
  Container,
  AuthorList,
  AuthorTouchableView,
  AuthorRoundWrapper,
  AuthorUser,
  AuthorSquareRoundWrapper,
  AuthorTeam,
  AuthorName,
  AuthorCommon,
  StyledIcon,
} from './style';

interface AuthorType {
  id: string;
}

export interface AuthorCommonType extends AuthorType {
  authorType: 'common';
  info: {
    description: string;
    styledIcon?: IconStyledName;
  };
}

export interface AuthorUserType extends AuthorType {
  authorType: 'user';
  userGroup: UserGroup;
}

export interface AuthorTeamType extends AuthorType {
  authorType: 'team';
  team: Team;
}

export type AuthorPickerProps = {
  testID?: string;
  authors: (AuthorCommonType | AuthorUserType | AuthorTeamType)[];
  selectedAuthorId?: string;
  onSelectedAuthor: (authorId: string) => void;
  onLoadMore?: () => void;
};

export default function AuthorPicker({
  testID,
  authors,
  selectedAuthorId,
  onSelectedAuthor,
  onLoadMore,
}: AuthorPickerProps): JSX.Element {
  const renderItem = ({
    item,
  }: {
    item: AuthorCommonType | AuthorUserType | AuthorTeamType;
  }) => {
    const cardTestID = `${testID}:AuthorCard:${item.id}`;
    if (item.authorType === 'user') {
      const userGroup = item.userGroup;
      return (
        <AuthorTouchableView
          testID={cardTestID}
          onPress={() => onSelectedAuthor(userGroup.user.id)}
        >
          <AuthorRoundWrapper selected={selectedAuthorId === userGroup.user.id}>
            <AuthorUser userGroup={userGroup} />
          </AuthorRoundWrapper>
          <AuthorName>{userGroup.user.firstName}</AuthorName>
        </AuthorTouchableView>
      );
    } else if (item.authorType === 'team') {
      const team = item.team;
      return (
        <AuthorTouchableView
          testID={cardTestID}
          onPress={() => onSelectedAuthor(team.id)}
        >
          <AuthorSquareRoundWrapper selected={selectedAuthorId === team.id}>
            <AuthorTeam
              teamColor={team?.color?.hex}
              iconName={team?.icon?.icon}
            />
          </AuthorSquareRoundWrapper>
          <AuthorName>{team.name}</AuthorName>
        </AuthorTouchableView>
      );
    } else if (item.authorType === 'common') {
      const {
        info: { description, styledIcon },
      } = item;
      return (
        <AuthorTouchableView
          testID={cardTestID}
          onPress={() => onSelectedAuthor(item.id)}
        >
          <AuthorRoundWrapper selected={selectedAuthorId === item.id}>
            <AuthorCommon>
              {styledIcon ? <StyledIcon name={styledIcon} /> : null}
            </AuthorCommon>
          </AuthorRoundWrapper>
          <AuthorName>{description}</AuthorName>
        </AuthorTouchableView>
      );
    }
    return null;
  };
  return (
    <Container testID={testID}>
      <AuthorList<React.ElementType>
        horizontal
        showsHorizontalScrollIndicator={false}
        data={authors}
        renderItem={renderItem}
        keyExtractor={(item: User | Team) => item.id}
        onEndReachedThreshold={0.3}
        onEndReached={() => onLoadMore && onLoadMore()}
      />
    </Container>
  );
}
