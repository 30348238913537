import React, { useState } from 'react';
import { Platform } from 'react-native';
import { t } from '~/utils/i18n';
import {
  Container,
  Header,
  TypeTitle,
  TypeValue,
  SchoolPicker,
  WebContainer,
  WebPicker,
  Line,
} from './style';

export type SchoolType = {
  id: string;
  name: string;
};

type SchoolTypePickerProps = {
  testID?: string;
  toggleTestID?: string;
  label: string;
  schoolTypes: SchoolType[];
  selectedSchoolType?: SchoolType;
  initialShowPicker?: boolean;
  includeNoSelection?: boolean;
  onSelectedSchoolType: (schoolType?: SchoolType) => void;
};

const noSelection: SchoolType = {
  id: 'noSelection',
  name: t('noSelection'),
};

export default function SchoolTypePicker({
  testID,
  toggleTestID,
  label,
  schoolTypes,
  selectedSchoolType = noSelection,
  initialShowPicker = false,
  includeNoSelection = true,
  onSelectedSchoolType,
}: SchoolTypePickerProps): JSX.Element {
  const [showPicker, setShowPicker] = useState<boolean>(initialShowPicker);

  const allSchoolTypes = includeNoSelection
    ? [noSelection, ...schoolTypes]
    : schoolTypes;

  return Platform.OS !== 'web' ? (
    <Container>
      <Header testID={toggleTestID} onPress={() => setShowPicker(!showPicker)}>
        <TypeTitle numberOfLines={1}>{label}</TypeTitle>
        <TypeValue numberOfLines={1}>{selectedSchoolType.name}</TypeValue>
      </Header>
      {showPicker && (
        <SchoolPicker<React.ElementType>
          testID={testID}
          selectedValue={selectedSchoolType.id}
          onValueChange={(schoolTypeId: string) =>
            onSelectedSchoolType(
              schoolTypes.find((schoolType) => schoolType.id === schoolTypeId),
            )
          }
        >
          {allSchoolTypes.map((schoolType) => (
            <SchoolPicker.Item
              key={schoolType.id}
              label={schoolType.name}
              value={schoolType.id}
            />
          ))}
        </SchoolPicker>
      )}
    </Container>
  ) : (
    <>
      <WebContainer>
        <label htmlFor="web-picker">
          <TypeTitle numberOfLines={1}>{label}</TypeTitle>
        </label>
        <WebPicker<React.ElementType>
          id="web-picker"
          selectedValue={selectedSchoolType.id}
          onValueChange={(schoolTypeId: string) =>
            onSelectedSchoolType(
              schoolTypes.find((schoolType) => schoolType.id === schoolTypeId),
            )
          }
        >
          {allSchoolTypes.map((schoolType) => (
            <SchoolPicker.Item
              key={schoolType.id}
              label={schoolType.name}
              value={schoolType.id}
            />
          ))}
        </WebPicker>
      </WebContainer>
      <Line />
    </>
  );
}
