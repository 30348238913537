import React, {
  useState,
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
} from 'react';
import { FlatList, FlatListProps } from 'react-native';
import { Modalize } from 'react-native-modalize';
import { Portal } from 'react-native-portalize';
import { useTheme } from 'styled-components/native';
import Button from '~/components/Button';
import { COUNTRIES } from '~/data/constants';
import { Country, CountryInfo } from '~/data/models/marketProfile';
import { t } from '~/utils/i18n';
import CountryCell from './CountryCell';
import { Container, Title, Footer, VSeparator } from './style';

export type ModalCountryPickerHandler = Modalize;

export type ModalCountryPickerProps = {
  testID?: string;
  contentOnly?: boolean;
  initialSelectedCountry: Country;
  onUpdateCountry: (selectedCountry: Country) => void;
};

const ModalCountryPicker: ForwardRefRenderFunction<
  Modalize,
  ModalCountryPickerProps
> = ({ testID, contentOnly, initialSelectedCountry, onUpdateCountry }, ref) => {
  const theme = useTheme();

  const [selectedCountry, setSelectedCountry] = useState<Country | undefined>(
    () => initialSelectedCountry,
  );

  useEffect(() => {
    setSelectedCountry(initialSelectedCountry);
  }, [initialSelectedCountry]);

  const renderHeader = () => {
    return (
      <>
        <Title>{t('g.changeCountry')}</Title>
      </>
    );
  };

  const renderFooter = () => {
    return (
      <Footer>
        <Button
          text={t('g.switchCountry')}
          size={'2xl'}
          type={'secondary-contrast'}
          flex
          onPress={() => selectedCountry && onUpdateCountry(selectedCountry)}
        />
      </Footer>
    );
  };

  const renderItem = ({ item: countryInfo }: { item: CountryInfo }) => {
    return (
      <CountryCell
        countryInfo={countryInfo}
        selected={selectedCountry == countryInfo.country}
        onSelect={() => setSelectedCountry(countryInfo.country)}
      />
    );
  };

  const countryInfos = Object.values(COUNTRIES);

  const flatListProps: FlatListProps<CountryInfo> = {
    style: {
      backgroundColor: theme.color.base.c0,
      paddingVertical: 24,
      paddingHorizontal: 16,
      borderTopLeftRadius: 25,
      borderTopRightRadius: 25,
    },
    data: countryInfos,
    ListHeaderComponent: renderHeader,
    ListFooterComponent: renderFooter,
    ItemSeparatorComponent: () => <VSeparator />,
    renderItem: renderItem,
    keyExtractor: (item) => item.country,
    showsVerticalScrollIndicator: false,
  };

  const renderContent = () => {
    return <FlatList {...flatListProps} />;
  };

  if (contentOnly) {
    return renderContent();
  }

  return (
    <Container testID={testID}>
      <Portal>
        <Modalize
          ref={ref}
          flatListProps={flatListProps}
          adjustToContentHeight
          handlePosition={'inside'}
        />
      </Portal>
    </Container>
  );
};

export default forwardRef(ModalCountryPicker);
