/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation DestroyCollagesAlbum
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  DestroyCollagesAlbum,
  DestroyCollagesAlbumVariables,
} from './types/DestroyCollagesAlbum';
import { DESTROY_COLLAGES_ALBUM } from './';

export function useDestroyCollagesAlbumMutation(
  options?: MutationHookOptions<
    DestroyCollagesAlbum,
    DestroyCollagesAlbumVariables
  >,
): MutationTuple<DestroyCollagesAlbum, DestroyCollagesAlbumVariables> {
  return useMutation<DestroyCollagesAlbum, DestroyCollagesAlbumVariables>(
    DESTROY_COLLAGES_ALBUM,
    options,
  );
}

export function createDestroyCollagesAlbumMock({
  variables,
  data,
  error,
}: {
  variables?: DestroyCollagesAlbumVariables;
  data?: DestroyCollagesAlbum;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: DESTROY_COLLAGES_ALBUM,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
