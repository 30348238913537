import { LinearGradient, LinearGradientProps } from 'expo-linear-gradient';
import styled, { DefaultTheme } from 'styled-components/native';
import Icon from '~/components/Icon';

interface CellStateHelper {
  bgColors: (checked: boolean, theme: DefaultTheme) => string[];
  margin: (checked: boolean) => string;
  padding: (checked: boolean) => string;
}

export const byStateHelper: CellStateHelper = {
  bgColors: (checked: boolean, theme: DefaultTheme): string[] => {
    if (checked) {
      return [theme.color.base.c3, theme.color.base.c2];
    }
    return [theme.color.base.c3, theme.color.base.c3];
  },
  margin: (checked: boolean): string => {
    return checked ? '0px' : '2px;';
  },
  padding: (checked: boolean): string => {
    return checked ? '3px' : '1px;';
  },
};

export const PressableContainer = styled.Pressable``;

interface ILNContainer {
  checked: boolean;
}

export const LNContainer = styled(LinearGradient).attrs<
  ILNContainer,
  LinearGradientProps
>(({ checked, theme }) => ({
  colors: byStateHelper.bgColors(checked, theme),
  start: { x: 0, y: 0 },
  end: { x: 1, y: 0 },
}))<ILNContainer>`
  border-radius: 16px;
  margin: ${({ checked }) => byStateHelper.margin(checked)};
  padding: ${({ checked }) => byStateHelper.padding(checked)};
`;

export const Container = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  padding-vertical: 24px;
  padding-horizontal: 16px;
  border-radius: 14px;
  flex-direction: row;
  align-items: center;
`;

export const Text = styled.Text`
  flex: 1;
  color: ${({ theme }) => theme.color.brand_02};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
  line-height: 20px;
`;

export const ArrowIcon = styled(Icon).attrs(({ theme }) => ({
  name: 'chevron-right',
  size: 16,
  color: theme.color.brand_02,
}))`
  padding-horizontal: 2px;
`;
