/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation CreateEvent
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { CreateEvent, CreateEventVariables } from './types/CreateEvent';
import { CREATE_EVENT } from './';

export function useCreateEventMutation(
  options?: MutationHookOptions<CreateEvent, CreateEventVariables>,
): MutationTuple<CreateEvent, CreateEventVariables> {
  return useMutation<CreateEvent, CreateEventVariables>(CREATE_EVENT, options);
}

export function createCreateEventMock({
  variables,
  data,
  error,
}: {
  variables?: CreateEventVariables;
  data?: CreateEvent;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: CREATE_EVENT,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
