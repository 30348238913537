import React from 'react';
import Shimmer from '~/components/Shimmer';
import { mapUser } from '~/data/mappers';
import { User } from '~/data/models/user';
import { RankingsAnswer } from '~/data/models/yearbook';
import { t } from '~/utils/i18n';
import {
  ArrowIcon,
  Container,
  ImageContainer,
  Picture,
  Text,
  TextWrapper,
  UserQuestionIcon,
} from './style';

type RankingQuestionSelectedCellProps = {
  testID?: string;
  rankingAnswer?: RankingsAnswer | null;
  placeholder?: boolean;
  onPress: () => void;
};

export default function RankingQuestionSelectedCell({
  testID,
  rankingAnswer,
  placeholder = false,
  onPress,
}: RankingQuestionSelectedCellProps): JSX.Element {
  if (placeholder) {
    return (
      <Container>
        <ImageContainer>
          <Shimmer w={56} h={56} br={12} g={'g2'} />
        </ImageContainer>
        <TextWrapper>
          <Text>
            <Shimmer w={120} g={'g2'} />
          </Text>
        </TextWrapper>
        <Shimmer w={16} h={16} g={'g3'} />
      </Container>
    );
  }
  const formattedName = (user: User) => mapUser(user)?.name;

  if (!rankingAnswer) {
    return (
      <Container testID={testID} onPress={onPress}>
        <ImageContainer>
          <UserQuestionIcon />
        </ImageContainer>
        <TextWrapper>
          <Text>{t('g.vote')}</Text>
        </TextWrapper>
        <ArrowIcon />
      </Container>
    );
  }

  const { votee1UserGroup, votee2UserGroup, voteeListOption } = rankingAnswer;

  return (
    <Container testID={testID} onPress={onPress}>
      {votee1UserGroup ? (
        <ImageContainer>
          <Picture
            source={{
              uri:
                votee1UserGroup?.avatar ||
                votee1UserGroup?.defaultAvatar?.avatar ||
                '',
            }}
          />
          {votee2UserGroup && (
            <Picture
              source={{
                uri:
                  votee2UserGroup.avatar ||
                  votee2UserGroup.defaultAvatar?.avatar ||
                  '',
              }}
            />
          )}
        </ImageContainer>
      ) : null}
      <TextWrapper>
        <Text>
          {votee1UserGroup
            ? formattedName(votee1UserGroup.user)
            : voteeListOption?.text}
        </Text>
        {votee2UserGroup && <Text>{formattedName(votee2UserGroup.user)}</Text>}
      </TextWrapper>
      <ArrowIcon />
    </Container>
  );
}
