import React from 'react';
import Badge from '~/components/Badge';
import Button from '~/components/Button';
import TabBar from '~/components/TabBar';
import TabSectionButton from '~/components/TabSectionButton';
import { TeamType } from '~/data/operations/global';
import { t } from '~/utils/i18n';
import {
  Container,
  BottomBg,
  Content,
  TabBarContainer,
  TeamIcon,
  SectionButtonContainer,
  SectionButtonRow,
  HSpacer,
  TeamName,
  TeamNameAndBadgeWrapper,
  TeamBadgeContainer,
} from './style';

export type HeaderProps = {
  teamColor?: string;
  iconName?: string;
  selectedTabItemKey: string;
  pendingRequest: boolean;
  teamMember: boolean;
  isHidden: boolean;
  teamName?: string;
  teamBadgeName?: TeamType | null;
  onTabItemPress: (itemKey: string) => void;
  onCreatePostPress: () => void;
  onCreatePollPress: () => void;
  onRequestAccessPress: () => void;
};

export default function Header({
  teamColor,
  iconName,
  selectedTabItemKey,
  pendingRequest,
  teamMember,
  isHidden,
  teamName,
  teamBadgeName,
  onTabItemPress,
  onCreatePostPress,
  onCreatePollPress,
  onRequestAccessPress,
}: HeaderProps): JSX.Element {
  return (
    <Container>
      <BottomBg />
      <Content>
        <TeamIcon
          size={104}
          iconSize={48}
          teamColor={teamColor}
          iconName={iconName}
          defaultBg
        />
        <TeamNameAndBadgeWrapper>
          <TeamName>{teamName}</TeamName>
          <TeamBadgeContainer>
            <Badge
              text={t(`teamDetail.${teamBadgeName}`)}
              colorTheme="gray2Black"
              size="large"
            />
          </TeamBadgeContainer>
        </TeamNameAndBadgeWrapper>
      </Content>
      <TabBarContainer>
        <TabBar
          testID={'tabBar'}
          selectedTabItemKey={selectedTabItemKey}
          tabItems={[
            {
              key: 'activity',
              title: t('activity'),
            },
            {
              key: 'members',
              title: t('members'),
            },
          ]}
          tabColor={teamColor}
          onTabItemPress={onTabItemPress}
        />
      </TabBarContainer>
      {(() => {
        if (isHidden) {
          return null;
        }

        if (selectedTabItemKey === 'activity') {
          if (teamMember) {
            return (
              <SectionButtonRow>
                <Button
                  testID={'buttonCreatePost'}
                  iconName={'lead-icon'}
                  text={t('g.createPost')}
                  icon="left"
                  type="secondary-base"
                  minWidth={175}
                  onPress={onCreatePostPress}
                />
                <HSpacer />
                <Button
                  testID={'buttonCreatePoll'}
                  iconName={'horizontal-bar-chart-02'}
                  text={t('g.createPoll')}
                  icon="left"
                  type="secondary-contrast"
                  minWidth={175}
                  onPress={onCreatePollPress}
                />
              </SectionButtonRow>
            );
          }
          return null;
        }
        if (!teamMember) {
          return (
            <SectionButtonContainer>
              <TabSectionButton
                testID={'buttonRequestAccess'}
                title={t('teamDetail.requestAccessTitle')}
                detail={t('teamDetail.requestAccessDescp')}
                badgeText={pendingRequest ? t('requestPending') : undefined}
                styledIconName={'add_user'}
                styledIconColor={teamColor}
                onPress={() => !pendingRequest && onRequestAccessPress()}
              />
            </SectionButtonContainer>
          );
        }
        return null;
      })()}
    </Container>
  );
}
