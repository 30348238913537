/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query CustomPagesInstances
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  CustomPagesInstances,
  CustomPagesInstancesVariables,
} from './types/CustomPagesInstances';
import { CUSTOM_PAGES_INSTANCES } from './';

export function useCustomPagesInstancesQuery(
  options?: QueryHookOptions<
    CustomPagesInstances,
    CustomPagesInstancesVariables
  >,
): QueryResult<CustomPagesInstances, CustomPagesInstancesVariables> {
  return useQuery<CustomPagesInstances, CustomPagesInstancesVariables>(
    CUSTOM_PAGES_INSTANCES,
    options,
  );
}

export function useCustomPagesInstancesLazyQuery(
  options?: LazyQueryHookOptions<
    CustomPagesInstances,
    CustomPagesInstancesVariables
  >,
): QueryTuple<CustomPagesInstances, CustomPagesInstancesVariables> {
  return useLazyQuery<CustomPagesInstances, CustomPagesInstancesVariables>(
    CUSTOM_PAGES_INSTANCES,
    options,
  );
}

export function createCustomPagesInstancesMock({
  variables,
  data,
  error,
}: {
  variables?: CustomPagesInstancesVariables;
  data?: CustomPagesInstances;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: CUSTOM_PAGES_INSTANCES,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
