import { DefaultTheme } from 'styled-components/native';
import styled from 'styled-components/native';
import ImageComp from '~/components/Image';
import { BGColorType } from './types';

export type ColorShemaKey = keyof DefaultTheme['color']['base'];

interface IContainerr {
  size: number;
  bgColorType: BGColorType;
  noPic: boolean;
}

export const Container = styled.View<IContainerr>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: ${({ size }) => size / 2}px;
  background-color: ${({ theme, noPic }) =>
    noPic ? theme.color.base.c0 : theme.color.base.c3};
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

interface IImage {
  size: number;
}
export const Image = styled(ImageComp)<IImage>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;

interface IText {
  size: number;
}
export const Text = styled.Text<IText>`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: ${({ size }) => size * 0.4}px;
  color: ${({ theme }) => theme.color.base.c0};
`;

interface INotify {
  size: number;
}
export const NotifyBadge = styled.View<INotify>`
  position: absolute;
  right: 2px;
  bottom: 0px;
  width: ${({ size }) => size * 0.25}px;
  height: ${({ size }) => size * 0.25}px;
  border-width: ${({ size }) => (size > 20 ? '2px' : '1px')};
  border-color: ${({ theme }) => theme.color.base.c0};
  border-radius: ${({ size }) => (size * 0.25) / 2}px;
  background-color: ${({ theme }) => theme.color.error};
`;
