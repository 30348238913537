import React, {
  useState,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
} from 'react';
import DateTimePicker, {
  DateTimePickerEvent,
} from '@react-native-community/datetimepicker';
import { Platform } from 'react-native';
import { Modalize } from 'react-native-modalize';
import { Portal } from 'react-native-portalize';
import Button from '~/components/Button';
import { Container, Content, Title } from './style';

export type ModalTimePickerHandler = Modalize;

export type ModalTimePickerProps = {
  testID?: string;
  title: string;
  buttonText: string;
  time?: Date;
  contentOnly?: boolean;
  onConfirmTime: (time: Date) => void;
};

const ModalTimePicker: ForwardRefRenderFunction<
  ModalTimePickerHandler,
  ModalTimePickerProps
> = (
  {
    testID,
    title,
    buttonText,
    time = new Date('2000-01-01T10:00:00'),
    contentOnly,
    onConfirmTime,
  },
  ref,
) => {
  const [open, setOpen] = useState(false);
  const [selectedTime, setSelectedTime] = useState<Date>(time);

  useImperativeHandle(ref, () => ({
    open() {
      Platform.OS === 'android' && setOpen(true);
    },
    close() {
      Platform.OS === 'android' && setOpen(false);
    },
  }));

  const onSelectedTime = (event: DateTimePickerEvent, date?: Date) => {
    if (Platform.OS === 'android' && event.type === 'dismissed') {
      setOpen(false);
    } else if (Platform.OS === 'android' && event.type === 'set') {
      setOpen(false);
      date && onConfirmTime(date);
    }
    date && setSelectedTime(date);
  };

  const renderContent = () => {
    return (
      <Content>
        <Title>{title}</Title>
        <DateTimePicker
          mode={'time'}
          value={selectedTime}
          display={'spinner'}
          onChange={onSelectedTime}
        />
        <Button
          size={'xl'}
          text={buttonText}
          type={'primary-brand-02'}
          state={selectedTime ? 'default' : 'disabled'}
          flex
          onPress={() => {
            if (selectedTime) {
              onConfirmTime(selectedTime);
            }
          }}
        />
      </Content>
    );
  };

  if (contentOnly) {
    return renderContent();
  }

  if (Platform.OS === 'android') {
    return (
      <Container testID={testID}>
        {open ? (
          <DateTimePicker
            mode={'time'}
            value={selectedTime}
            display={'spinner'}
            onChange={onSelectedTime}
          />
        ) : null}
      </Container>
    );
  }

  return (
    <Container testID={testID}>
      <Portal>
        <Modalize ref={ref} adjustToContentHeight handlePosition={'inside'}>
          {renderContent()}
        </Modalize>
      </Portal>
    </Container>
  );
};

export default forwardRef(ModalTimePicker);
