import React, { useState } from 'react';
import AdsDatabase from '~/components/AdsDatabase';
import SwitchSelector from '~/components/SwitchSelector';
import { Campaign, Column } from '~/data/models/campaign';
import { t } from '~/utils/i18n';
import {
  AdsDatabaseWrapper,
  Container,
  HeaderContainer,
  SelectorWrapper,
  Title,
} from './style';

export enum AdsType {
  CAMPAIGN = 'Campaigns',
  CALENDER = 'Calendar',
}

type AdsLayoutProps = {
  campaigns: Campaign[];
  loading?: boolean;
  onOpenCampaign: (id: string) => void;
  handleOnCreateCampaign: () => void;
  totalCampaigns: number;
  setCurrentActiveTab: (tab: number) => void;
  filterQuery: string;
  sortedBy: string;
  rowsPerPage: number;
  setRowsPerPage: (value: number) => void;
  setFilterQuery: (value: string) => void;
  toggleSort: (value: string) => void;
  columns: Column[];
};

export default function Ads({
  onOpenCampaign,
  campaigns,
  loading,
  handleOnCreateCampaign,
  totalCampaigns,
  setCurrentActiveTab,
  filterQuery,
  rowsPerPage,
  sortedBy,
  setFilterQuery,
  setRowsPerPage,
  toggleSort,
  columns,
}: AdsLayoutProps): JSX.Element {
  const [eventKey, setEventKey] = useState<string>('CAMPAIGN');

  return (
    <Container>
      <HeaderContainer>
        <Title>{t('g.ads')}</Title>
        <SelectorWrapper>
          <SwitchSelector
            items={Object.keys(AdsType).map((key) => ({
              key,
              title: t(
                'g.' + AdsType[key as keyof typeof AdsType].toLowerCase(),
              ),
            }))}
            selectedItemKey={eventKey}
            onSelectedItem={setEventKey}
            testID="pickerAdsType"
          />
        </SelectorWrapper>
      </HeaderContainer>
      <AdsDatabaseWrapper>
        <AdsDatabase
          data={campaigns}
          onOpenCampaign={onOpenCampaign}
          handleOnCreateCampaign={handleOnCreateCampaign}
          setCurrentActiveTab={setCurrentActiveTab}
          filterQuery={filterQuery}
          rowsPerPage={rowsPerPage}
          sortedBy={sortedBy}
          setRowsPerPage={setRowsPerPage}
          setFilterQuery={setFilterQuery}
          toggleSort={toggleSort}
          totalCampaigns={totalCampaigns}
          loading={loading}
          columns={columns}
        />
      </AdsDatabaseWrapper>
    </Container>
  );
}
