import styled from 'styled-components/native';
import Icon from '~/components/Icon';

export const Container = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})`
  padding-vertical: 16px;
  flex-direction: row;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.color.base.c3};
  align-items: center;
`;

export const Content = styled.View`
  flex: 1;
  padding-horizontal: 16px;
`;

export const Name = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 16px;
  line-height: 20px;
`;

export const InputProgressView = styled.View`
  padding-vertical: 8px;
`;

export const Descp = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c5};
  font-size: 12px;
  line-height: 14px;
`;

export const ArrowContent = styled.View`
  background-color: ${({ theme }) => theme.color.base.c1};
  width: 32px;
  height: 32px;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
`;

export const ArrowIcon = styled(Icon).attrs(({ theme }) => ({
  name: 'chevron-right',
  size: 16,
  color: theme.color.brand_02,
}))``;
