import styled from 'styled-components/native';
import { ModalScreenContainer } from '~/screens/style';

export const Container = styled(ModalScreenContainer)``;

export const ScrollView = styled.View`
  padding-top: 8px;
  padding-horizontal: 16px;
  flex: 1;
`;

export const ContentContainer = styled.View`
  flex: 1;
`;

export const Loading = styled.ActivityIndicator.attrs(() => ({
  paddingVertical: 24,
}))``;

export const ErrorMessage = styled.Text`
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 16px;
`;

export const Footer = styled.View`
  padding-bottom: 24px;
`;
