import styled from 'styled-components/native';
import IconStyled from '~/components/IconStyled';
import { WebContainer } from '~/components/WebGrid';
import { ScrollContainer } from '~/screens/Auth/layout/AuthLayout/AuthWebLayout/style';

type ChangeIndicatorProps = {
  increase: boolean;
};

export const Container = styled(ScrollContainer)`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const ReportingWrapper = styled(WebContainer)`
  padding: 50px;
  background-color: ${({ theme }) => theme.color.base.c0};
  border-radius: 10px;
  box-shadow: rgb(234 234 234) 0px 0px 10px;
`;

export const Label = styled.Text`
  color: ${({ theme }) => theme.color.base.c9};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
  margin-bottom: 10px;
`;

export const InfoMessage = styled.Text`
  color: ${({ theme }) => theme.color.info};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
  padding: 20px;
  text-align: center;
`;

export const SubTitle = styled.Text`
  color: ${({ theme }) => theme.color.base.c6};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 14px;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const OptionsWrapper = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const OptionBlock = styled.View`
  width: 24%;
`;

export const StatisticsWrapper = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  border-top-width: 1px;
  border-top-color: ${({ theme }) => theme.color.base.c4};
  padding-top: 75px;
  margin-top: 75px;
`;

export const StatisticsBlock = styled.View`
  width: 33%;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgb(234 234 234);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
`;

export const MiddleStatisticsBlock = styled(StatisticsBlock)`
  border: 1px solid ${({ theme }) => theme.color.base.c3};
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-radius: 0px;
`;

export const LeftStatisticsBlock = styled(StatisticsBlock)`
  border-bottom-right-radius: 0px;
`;
export const RightStatisticsBlock = styled(StatisticsBlock)`
  border-bottom-left-radius: 0px;
`;
export const GraphStatistics = styled.View`
  width: 100%;
  border-radius: 5px 5px 0px 0px;
  box-shadow: 0 2px 4px rgb(234 234 234);
  padding: 30px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
`;

export const StatsLabel = styled.Text`
  font-size: 14px;
  color: ${({ theme }) => theme.color.base.c7};
  margin-bottom: 4px;
`;

export const MainStat = styled.Text`
  font-size: 24px;
  font-weight: bold;
  color: ${({ theme }) => theme.color.brand_02};
`;

export const SecondaryStat = styled.Text`
  font-size: 14px;
  color: ${({ theme }) => theme.color.base.c5};
`;

export const ChangeIndicator = styled.View<ChangeIndicatorProps>`
  background-color: ${({ increase }) => (increase ? '#dff0d8' : '#f2dede')};
  border-radius: 4px;
  padding: 2px 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  height: 20px;
`;

export const IndicatorText = styled.Text<ChangeIndicatorProps>`
  color: ${({ increase }) => (increase ? '#3c763d' : '#a94442')};
  font-weight: bold;
  font-size: 14px;
`;

export const GraphWrapper = styled.View`
  width: 100%;
  height: auto;
  margin-top: 50px;
  border-radius: 5px;
  box-shadow: rgb(234 234 234) 0px 0px 10px;
`;

export const BarChartWrapper = styled.View`
  width: 100%;
  padding: 30px;
  border-radius: 0px 0px 5px 5px;
`;

export const StatsRow = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const StatsGraphRow = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const GraphStatsLabel = styled.Text`
  text-transform: uppercase;
  font-size: 15px;
  color: ${({ theme }) => theme.color.base.c6};
`;

export const GraphMainStat = styled.Text`
  font-size: 40px;
  font-weight: normal;
  color: ${({ theme }) => theme.color.base.c9};
`;

export const GraphSecondaryStat = styled.Text`
  font-size: 14px;
  color: ${({ theme }) => theme.color.base.c7};
`;

export const InfoIcon = styled(IconStyled).attrs({
  name: 'info',
})``;
