import React, { useRef } from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Platform, useWindowDimensions } from 'react-native';
import { useTheme } from 'styled-components/native';
import { ModalSwitchGroupsHandler } from '~/components/ModalSwitchGroups';
import ModalSwitchGroups from '~/components/ModalSwitchGroups';
import { useAuth } from '~/context/auth';
import useGroupUsers from '~/data/hooks/useGroupUsers';
import { MainTabParamList } from '~/navigation/types';
import { RootStackParamList } from '~/navigation/types';
import { PROFILE_NAV } from '~/utils/helpers/const';
import FeedStack from './Feed';
import PlanningStack from './Planning';
import ProfileStack from './Profile';
import YearbookStack from './Yearbook';
import CustomDrawerContent from './layout/CustomDrawerContent';
import {
  DESKTOP_DRAWER_STYLE,
  MOBILE_DRAWER_STYLE,
} from './layout/CustomDrawerContent/style';
import CustomTabBar from './layout/CustomTabBar';

type MainNavProp = StackNavigationProp<RootStackParamList, 'MainTab'>;

const DrawerNavigator = createDrawerNavigator<MainTabParamList>();
const BottomTabNavigator = createBottomTabNavigator<MainTabParamList>();

const SCREENS: {
  name: keyof MainTabParamList;
  component: () => JSX.Element;
}[] = [
  { name: 'FeedStack', component: FeedStack },
  { name: 'PlanningStack', component: PlanningStack },
  { name: 'YearbookStack', component: YearbookStack },
  { name: 'ProfileStack', component: ProfileStack },
];

export default function MainTab(): JSX.Element {
  const INITIAL_ROUTE = 'FeedStack';
  const window = useWindowDimensions();
  const theme = useTheme();
  const isMobileView = window.width < theme.breakpoints.desktopMin;
  const switchGroupsModalRef = useRef<ModalSwitchGroupsHandler>(null);

  const navigation = useNavigation<MainNavProp>();

  const { onAuthGroup } = useAuth();

  if (Platform.OS === 'web') {
    return (
      <DrawerNavigator.Navigator
        initialRouteName={INITIAL_ROUTE}
        screenOptions={{
          drawerType: isMobileView ? 'front' : 'permanent',
          drawerStyle: isMobileView
            ? MOBILE_DRAWER_STYLE
            : DESKTOP_DRAWER_STYLE,
          headerShown: false,
        }}
        drawerContent={(props) => (
          <CustomDrawerContent isMobile={isMobileView} {...props} />
        )}
      >
        {SCREENS.map((screen) => (
          <DrawerNavigator.Screen key={screen.name} {...screen} />
        ))}
      </DrawerNavigator.Navigator>
    );
  }

  const onSelectedGroup = async (groupId: string, userGroupId: string) => {
    onAuthGroup(groupId, userGroupId);
    switchGroupsModalRef?.current?.close();
  };

  const onJoinGroup = () => {
    navigation.navigate('GroupStack', {
      screen: 'JoinSelectLocation',
    });
    switchGroupsModalRef?.current?.close();
  };

  const onLongPress = (label: string) => {
    PROFILE_NAV.includes(label) && switchGroupsModalRef?.current?.open();
  };

  const { groupUsers, loading, error } = useGroupUsers();
  return (
    <>
      <ModalSwitchGroups
        ref={switchGroupsModalRef}
        onSelectedGroup={onSelectedGroup}
        onJoinGroup={onJoinGroup}
        groupUsers={groupUsers}
        loading={loading}
        error={error}
      />
      <BottomTabNavigator.Navigator
        initialRouteName={INITIAL_ROUTE}
        tabBar={(props) => {
          return <CustomTabBar {...props} onLongPress={onLongPress} />;
        }}
        screenOptions={{
          headerShown: false,
        }}
      >
        {SCREENS.map((screen) => (
          <BottomTabNavigator.Screen key={screen.name} {...screen} />
        ))}
      </BottomTabNavigator.Navigator>
    </>
  );
}
