import styled from 'styled-components/native';
import Avatar from '~/components/Avatar';
import TeamIcon from '~/components/TeamIcon';
import IconStyled from '../IconStyled';

export const Container = styled.View`
  padding-vertical: 16px;
`;

export const AuthorList = styled.FlatList``;

export const AuthorTouchableView = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})`
  width: 98px;
  align-items: center;
  padding-horizontal: 4px;
`;

interface IAuthor {
  selected: boolean;
}
export const AuthorRoundWrapper = styled.View<IAuthor>`
  border-radius: 44px;
  border-color: ${({ selected, theme }) =>
    selected ? theme.color.brand_02 : theme.color.base.c0};
  border-width: 3px;
`;

export const AuthorCommon = styled.View`
  width: 84px;
  height: 84px;
  background-color: ${({ theme }) => theme.color.base.c2};
  border-radius: 42px;
  border-color: ${({ theme }) => theme.color.base.c0};
  border-width: 3px;
  align-items: center;
  justify-content: center;
`;

export const StyledIcon = styled(IconStyled).attrs(({ theme }) => ({
  color: theme.color.base.c6,
  size: 40,
}))``;

export const AuthorUser = styled(Avatar).attrs({
  size: 48,
})`
  border-radius: 42px;
  border-color: ${({ theme }) => theme.color.base.c0};
  border-width: 3px;
  align-items: center;
  justify-content: center;
`;

export const AuthorSquareRoundWrapper = styled.View<IAuthor>`
  border-radius: 18px;
  border-color: ${({ selected, theme }) =>
    selected ? theme.color.brand_02 : theme.color.base.c0};
  border-width: 3px;
`;
export const AuthorTeam = styled(TeamIcon).attrs({
  size: 48,
  iconSize: 28,
})`
  border-radius: 16px;
  border-color: ${({ theme }) => theme.color.base.c0};
  border-width: 3px;
`;

export const AuthorName = styled.Text`
  margin-top: 8px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c8};
  font-size: 14px;
  line-height: 18px;
`;
