import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { NO_PAG_LIST_OFFSET } from '~/data/constants';
import { InstituteType } from '~/data/models/institute';
import { Country } from '~/data/models/marketProfile';
import {
  InstitutesInstituteCountryChoices,
  RequestMissingInstituteInput,
} from '~/data/operations/global';
import { useInstituteTypesQuery } from '~/data/operations/institute/instituteTypes';
import { useRequestMissingInstituteMutation } from '~/data/operations/institute/requestMissingInstitute';
import { GroupStackParamList } from '~/navigation/types';
import RequestInstitutionLayout from './layout';

type RequestInstitutionNavProp = StackNavigationProp<
  GroupStackParamList,
  'RequestInstitution'
>;

export default function RequestInstitution(): JSX.Element {
  const navigation = useNavigation<RequestInstitutionNavProp>();

  const { country, onUpdateCountry } = useAuth();

  const { data: instituteTypesData } = useInstituteTypesQuery({
    variables: {
      first: NO_PAG_LIST_OFFSET,
      country: country && InstitutesInstituteCountryChoices[country],
    },
  });
  const instituteTypes: InstituteType[] =
    (instituteTypesData?.instituteTypes?.edges.map(
      (edge) => edge?.node,
    ) as InstituteType[]) || [];

  const [requestMissingInstitute, { loading }] =
    useRequestMissingInstituteMutation();

  async function onRequestCreation({
    name,
    website,
    zip,
    city,
    country,
    street,
    type,
  }: RequestMissingInstituteInput) {
    try {
      const { data } = await requestMissingInstitute({
        variables: {
          input: {
            name,
            zip,
            city,
            website,
            country,
            street,
            type,
          },
        },
      });
      if (data?.requestMissingInstitute?.message) {
        navigation.navigate('RequestInstitutionSent');
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  }

  return (
    <RequestInstitutionLayout
      country={country as Country}
      instituteTypes={instituteTypes}
      loading={loading}
      onBack={() => navigation.goBack()}
      onUpdateCountry={onUpdateCountry}
      onRequestCreation={onRequestCreation}
    />
  );
}

/**
 * TODO:
 * - Handle address and schoolTypeKey
 */
