const LOGIN_API = 'https://www.app.abihome.de/API/session';
const USER_COUNT_API = 'https://export.abihome.dev/v1/usercount';
const ADMIN_TYPE = '200';

type SessionResponse = {
  success: boolean;
  message: string;
  payload: {
    user: {
      type: string;
      name: string;
    };
  };
};

type LoginType = {
  success: boolean;
  isAdmin?: boolean;
  message: string;
  name?: string;
  userCount?: undefined | number;
  error?: string | undefined;
};

export const login = async (
  email: string,
  password: string,
): Promise<LoginType> => {
  const data = {
    mail: email,
    passwort: password,
  };

  try {
    const response = await fetch(LOGIN_API, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const json: SessionResponse = await response.json();

    const isAdmin = json?.payload?.user?.type === ADMIN_TYPE;
    let userCount;

    if (isAdmin) {
      userCount = await countUsers(email, password);
    }

    return {
      success: json.success,
      isAdmin: json?.payload?.user?.type === ADMIN_TYPE,
      message: json?.message,
      name: json?.payload?.user?.name,
      userCount: userCount,
    };
  } catch (e) {
    return { success: false, message: 'Something went wrong' };
  }
};

const countUsers = async (email: string, password: string): Promise<number> => {
  const response = await fetch(USER_COUNT_API, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      user: email,
      password,
    },
  });

  const json: { usercount: number } = await response.json();

  return json.usercount;
};
