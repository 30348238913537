import React from 'react';
import { SvgProps } from 'react-native-svg';
import KeyGSvg from '~/assets/svg/search/keyG.svg';
import KeyISvg from '~/assets/svg/search/keyI.svg';
import KeyOptionSvg from '~/assets/svg/search/keyOption.svg';
import KeyUSvg from '~/assets/svg/search/keyU.svg';
import { SearchIconName } from '~/utils/types/adminSearch';

type SvgIconMap = { [key in SearchIconName]: React.FC<SvgProps> };

const svgIconMap: SvgIconMap = {
  'icn/search/keyI': KeyISvg,
  'icn/search/keyU': KeyUSvg,
  'icn/search/keyG': KeyGSvg,
  'icn/search/keyOption': KeyOptionSvg,
};

type IconStyledProps = {
  name: SearchIconName;
  height?: number;
  width?: number;
};

export default function StyledShortcutImage({
  name,
  width,
  height,
}: IconStyledProps): JSX.Element {
  const ShortcutImage = svgIconMap[name];
  return <ShortcutImage width={width} height={height} />;
}
