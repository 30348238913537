import { useAuth } from '~/context/auth';
import { updateLocale } from '~/utils/i18n';
import { AppLocale } from '../models/app';

type AppLocaleHookProps = {
  locale: AppLocale;
  onChangeLocale: (locale: AppLocale) => Promise<void>;
};

type AppLocaleHookVars = {
  onReload?: () => void;
};

const useAppLocale = (props?: AppLocaleHookVars): AppLocaleHookProps => {
  const onReload = props?.onReload;

  const { locale, onChangeLocale: onAuthChangeLocale } = useAuth();

  async function onChangeLocale(locale: AppLocale) {
    await onAuthChangeLocale(locale);
    updateLocale(locale);
    onReload && onReload();
  }

  return {
    locale,
    onChangeLocale,
  };
};

export default useAppLocale;
