import styled from 'styled-components/native';
import SectionHeaderComp from '~/components/SectionHeader';

export const Container = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const SectionHeader = styled(SectionHeaderComp)`
  padding-horizontal: 16px;
`;

export const ColorList = styled.FlatList`
  padding-vertical: 16px;
`;

export const ColorListSeparator = styled.View`
  width: 16px;
`;

interface IColorContent {
  selected: boolean;
}
export const ColorContent = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})<IColorContent>`
  border-radius: 8px;
  border-width: 2px;
  border-color: ${({ selected, theme }) =>
    selected ? theme.color.base.c4 : theme.color.base.c0};
`;

interface IColorBox {
  hex: string;
  selected: boolean;
}
export const ColorBox = styled.View<IColorBox>`
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background-color: ${({ hex }) => hex};
  border-width: 2px;
  border-color: ${({ theme }) => theme.color.base.c0};
`;
