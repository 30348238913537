import styled from 'styled-components/native';
import ButtonIcon from '~/components/ButtonIcon';
import IconStyled from '~/components/IconStyled';
import ImageComp from '~/components/Image';
import { PhotoFormat } from '~/data/operations/global';

const IMAGE_HEIGHT = 128;
const CONTAINER_HEIGHT = IMAGE_HEIGHT + 24;

export const Container = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})`
  margin-right: 16px;
  height: ${CONTAINER_HEIGHT}px;
`;

export const ImageContainer = styled.View`
  flex: 1;
  justify-content: center;
`;

interface IImage {
  photoFormat: PhotoFormat;
}

export const ImageContent = styled.View``;

export const Image = styled(ImageComp).attrs<IImage>(({ photoFormat }) => ({
  autoWidthFromHeight:
    photoFormat === PhotoFormat.AS_UPLOADED ? IMAGE_HEIGHT : undefined,
  autoWidthMax: 256,
}))<IImage>`
  background-color: ${({ theme }) => theme.color.base.c3};
  border-radius: 14px;
  height: ${IMAGE_HEIGHT}px;
  ${({ photoFormat }) =>
    photoFormat === PhotoFormat.PORTRAIT
      ? 'aspect-ratio: 0.75;'
      : photoFormat === PhotoFormat.SQUARE
      ? 'aspect-ratio: 1;'
      : ''};
  overflow: hidden;
  align-items: center;
  justify-content: center;
`;

export const EmptyImageIcon = styled(IconStyled).attrs(({ theme }) => ({
  name: 'image_empty',
  color: theme.color.base.c6,
  size: 48,
}))``;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c0,
}))``;

export const RemoveButtonIcon = styled(ButtonIcon).attrs(({ theme }) => ({
  color: theme.color.base.c2,
  size: 12,
  name: 'x-close',
}))`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.color.base.c6};
  align-items: center;
  justify-content: center;
`;

export const CategoryText = styled.Text`
  margin-top: 8px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c7};
  line-height: 12px;
  font-size: 11px;
`;
