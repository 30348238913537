import styled from 'styled-components/native';

export const Container = styled.View`
  flex: 1;
  background-color: ${({ theme }) => theme.color.brand_01};
`;

export const TopContainer = styled.View`
  height: 376px;
  padding-top: ${({ theme }) => theme.safeAreaInsets.top}px;
`;

export const BottomContainer = styled.View`
  flex: 1;
  margin-top: 24px;
  margin-bottom: 64px;
  padding-horizontal: ${({ theme }) => theme.spacing.medium};
  justify-content: space-between;
`;

export const ImageContainer = styled.ImageBackground`
  height: 376px;
  width: 100%;
  position: absolute;
`;

export const TitleWrapper = styled.View`
  position: absolute;
  padding-horizontal: ${({ theme }) => theme.spacing.medium};
  bottom: 24px;
  align-items: self-start;
`;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c0};
  font-size: 32px;
  line-height: 44px;
`;

export const BadgeText = styled.Text`
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.color.base.c0};
  background-color: ${({ theme }) => theme.color.base.c9};
  padding: 2px 16px;
  border-radius: 14px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.color.base.c9};
  overflow: hidden;
`;

export const Desc = styled.Text`
  font-size: 18px;
  line-height: 24px;
`;
export const DescWrapper = styled.View`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: ${({ theme }) => theme.spacing.large};
`;
