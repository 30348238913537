import React from 'react';
import { Color } from '~/data/models/custom';
import { t } from '~/utils/i18n';
import {
  Container,
  SectionHeader,
  ColorList,
  ColorListSeparator,
  ColorContent,
  ColorBox,
} from './style';

type TeamColorPickerProps = {
  testID?: string;
  colors: Color[];
  selectedColor: Color | null;
  onSelectedColor: (color: Color) => void;
};

export default function TeamColorPicker({
  testID,
  colors,
  selectedColor,
  onSelectedColor,
}: TeamColorPickerProps): JSX.Element {
  const renderItem = ({ item: color }: { item: Color }) => {
    const selected = selectedColor != null && selectedColor.id === color.id;
    return (
      <ColorContent
        testID={color.id}
        selected={selected}
        onPress={() => onSelectedColor(color)}
      >
        <ColorBox hex={color.hex} selected={selected} />
      </ColorContent>
    );
  };

  return (
    <Container testID={testID}>
      <SectionHeader title={t('sections.teamColor')} />
      <ColorList<React.ElementType>
        horizontal
        showsHorizontalScrollIndicator={false}
        data={colors}
        renderItem={renderItem}
        ListHeaderComponent={<ColorListSeparator />}
        ItemSeparatorComponent={() => <ColorListSeparator />}
        keyExtractor={(item: Color) => item.id}
      />
    </Container>
  );
}
