import styled from 'styled-components/native';

export const EmptyComponentContainer = styled.View`
  align-items: center;
  text-align: center;
`;

export const EmptyListImage = styled.Image`
  width: 230px;
  height: 230px;
`;

export const EmptyContainerTitle = styled.Text`
  margin-top: 48px;
  color: ${({ theme }) => theme.color.base.c9};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 28px;
  line-height: 28px;
`;

export const EmptyComponentText = styled.Text`
  margin-top: 16px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c7};
  font-size: 18px;
  line-height: 130%;
  width: 240px;
`;
