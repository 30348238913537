/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation UpdateRankingsSetup
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  UpdateRankingsSetup,
  UpdateRankingsSetupVariables,
} from './types/UpdateRankingsSetup';
import { UPDATE_RANKINGS_SETUP } from './';

export function useUpdateRankingsSetupMutation(
  options?: MutationHookOptions<
    UpdateRankingsSetup,
    UpdateRankingsSetupVariables
  >,
): MutationTuple<UpdateRankingsSetup, UpdateRankingsSetupVariables> {
  return useMutation<UpdateRankingsSetup, UpdateRankingsSetupVariables>(
    UPDATE_RANKINGS_SETUP,
    options,
  );
}

export function createUpdateRankingsSetupMock({
  variables,
  data,
  error,
}: {
  variables?: UpdateRankingsSetupVariables;
  data?: UpdateRankingsSetup;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: UPDATE_RANKINGS_SETUP,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
