import styled from 'styled-components/native';
import InputField from '~/components/InputField';
import { ResponsiveProps } from '~/components/common/style.web';
import { TabScreenContainer } from '~/screens/style';

export const Container = styled(TabScreenContainer)`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const InputWrapper = styled.View<ResponsiveProps>`
  width: ${({ isDesktop }) => (isDesktop ? '230px' : '180px')};
  justify-content: center;
  flex: 1;
`;

export const InputFormWrapper = styled.View<ResponsiveProps>`
  width: ${({ isDesktop }) => (isDesktop ? '460px' : '100%')};
`;

export const InputActionsWrapper = styled(InputFormWrapper)`
  height: 48px;
`;

export const Name = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
`;

export const Email = styled.Text`
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 14px;
`;

export const DeclineButtonWrapper = styled.View`
  width: 50px;
`;

export const TableRowHeader = styled.Text`
  justify-content: center;
`;

export const RightSideWrapper = styled.View`
  width: 400px;
`;

export const SearchDuplicates = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SearchInput = styled(InputField)`
  width: 150px;
`;
