import styled from 'styled-components/native';

export const Container = styled.View``;

export const Content = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  padding-top: 48px;
  padding-horizontal: 16px;
  padding-bottom: 64px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
`;

export const Title = styled.Text`
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 18px;
  line-height: 22px;
`;

export const Descp = styled.Text`
  margin-top: 16px;
  margin-bottom: 32px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 16px;
  line-height: 22px;
`;
