/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query CollagesPhotos
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  CollagesPhotos,
  CollagesPhotosVariables,
} from './types/CollagesPhotos';
import { COLLAGES_PHOTOS } from './';

export function useCollagesPhotosQuery(
  options?: QueryHookOptions<CollagesPhotos, CollagesPhotosVariables>,
): QueryResult<CollagesPhotos, CollagesPhotosVariables> {
  return useQuery<CollagesPhotos, CollagesPhotosVariables>(
    COLLAGES_PHOTOS,
    options,
  );
}

export function useCollagesPhotosLazyQuery(
  options?: LazyQueryHookOptions<CollagesPhotos, CollagesPhotosVariables>,
): QueryTuple<CollagesPhotos, CollagesPhotosVariables> {
  return useLazyQuery<CollagesPhotos, CollagesPhotosVariables>(
    COLLAGES_PHOTOS,
    options,
  );
}

export function createCollagesPhotosMock({
  variables,
  data,
  error,
}: {
  variables?: CollagesPhotosVariables;
  data?: CollagesPhotos;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: COLLAGES_PHOTOS,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
