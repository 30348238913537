import styled from 'styled-components/native';

type MapContainerProps = {
  height?: number;
  weight?: number;
};
export const Container = styled.View<MapContainerProps>`
  background-color: ${({ theme }) => theme.color.base.c0};
  height: ${({ height }) => height}px;
  width: ${({ weight }) => weight}px;
  border-radius: 15px;
`;
