/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query Group
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { Group, GroupVariables } from './types/Group';
import { GROUP } from './';

export function useGroupQuery(
  options?: QueryHookOptions<Group, GroupVariables>,
): QueryResult<Group, GroupVariables> {
  return useQuery<Group, GroupVariables>(GROUP, options);
}

export function useGroupLazyQuery(
  options?: LazyQueryHookOptions<Group, GroupVariables>,
): QueryTuple<Group, GroupVariables> {
  return useLazyQuery<Group, GroupVariables>(GROUP, options);
}

export function createGroupMock({
  variables,
  data,
  error,
}: {
  variables?: GroupVariables;
  data?: Group;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: GROUP,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
