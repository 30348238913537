import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
} from 'react';
import { Animated } from 'react-native';
import { CollagesAlbum, CollagesPhoto } from '~/data/models/yearbook';
import { t } from '~/utils/i18n';
import {
  Container,
  AnimatedSwipeableContainer,
  DeleteButton,
  DeleteIcon,
  DeleteText,
  Content,
  LNContent,
  Footer,
  Name,
  Col,
  CollSpacer,
  Row,
  RowSpacer,
  PhotoImage,
  EmptyImageView,
  EmptyImageIcon,
  CELL_CONTAINER_HEIGHT,
  SWIPEABLE_BUTTON_WIDTH,
} from './style';

type CollagesAlbumCellProps = {
  testID?: string;
  collagesAlbum: CollagesAlbum;
  swipeable?: boolean;
  onPress: () => void;
  onDelete?: () => void;
};

export type CollagesAlbumCellHandle = {
  collapse: () => void;
};

const CollagesAlbumCell: ForwardRefRenderFunction<
  CollagesAlbumCellHandle,
  CollagesAlbumCellProps
> = ({ testID, collagesAlbum, swipeable, onPress, onDelete }, ref) => {
  const [animated] = useState(new Animated.Value(0));

  const deleteAnimation = {
    height: animated.interpolate({
      inputRange: [0, 1],
      outputRange: [CELL_CONTAINER_HEIGHT, 0],
      extrapolate: 'clamp',
    }),
  };

  function collapse() {
    Animated.timing(animated, {
      toValue: 1,
      duration: 250,
      useNativeDriver: false,
    }).start();
  }

  useImperativeHandle(ref, () => ({
    collapse: () => collapse(),
  }));

  const renderContent = () => {
    const collagesPhotos: CollagesPhoto[] =
      (collagesAlbum?.collagesPhotos?.edges.map(
        (edge) => edge?.node,
      ) as CollagesPhoto[]) || [];
    const photosLength = collagesPhotos?.length || 0;
    return (
      <Content onPress={onPress}>
        <Row>
          {photosLength === 0 ? (
            <EmptyImageView>
              <EmptyImageIcon />
            </EmptyImageView>
          ) : photosLength >= 1 ? (
            <Col>
              <PhotoImage
                source={{
                  uri: collagesPhotos?.[0]?.photo || undefined,
                }}
              />
            </Col>
          ) : null}
          {photosLength >= 3 ? (
            <>
              <RowSpacer />
              <Col sub>
                <PhotoImage
                  source={{
                    uri: collagesPhotos?.[1]?.photo || undefined,
                  }}
                />
                <CollSpacer />
                <PhotoImage
                  source={{
                    uri: collagesPhotos?.[2]?.photo || undefined,
                  }}
                />
              </Col>
            </>
          ) : null}
          {photosLength >= 5 ? (
            <>
              <RowSpacer />
              <Col sub>
                <PhotoImage
                  source={{
                    uri: collagesPhotos?.[3]?.photo || undefined,
                  }}
                />
                <CollSpacer />
                <PhotoImage
                  source={{
                    uri: collagesPhotos?.[4]?.photo || undefined,
                  }}
                />
              </Col>
            </>
          ) : null}
        </Row>
        <LNContent />
        <Footer>
          <Name>{collagesAlbum.name}</Name>
        </Footer>
      </Content>
    );
  };

  if (swipeable) {
    return (
      <Container testID={testID}>
        <AnimatedSwipeableContainer
          style={deleteAnimation}
          rightButtonWidth={SWIPEABLE_BUTTON_WIDTH}
          rightButtons={[
            <DeleteButton key={'deleteButton'} onPress={onDelete}>
              <DeleteIcon />
              <DeleteText>{t('g.delete')}</DeleteText>
            </DeleteButton>,
          ]}
        >
          {renderContent()}
        </AnimatedSwipeableContainer>
      </Container>
    );
  }
  return <Container testID={testID}>{renderContent()}</Container>;
};

export default forwardRef(CollagesAlbumCell);
