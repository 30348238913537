import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components/native';
import {
  Container,
  TableHeader,
  InstituteId,
  InstituteIconContainer,
  PaginationWrapper,
  EmptyCell,
  CellText,
  TableHeaderText,
  SortIcon,
} from '~/components/InstituteDatabase/style';
import SwitchSelector from '~/components/SwitchSelector';
import { EditInstituteGroupFields } from '~/data/models/admin';
import usePagination from '~/utils/hooks/usePagination';
import { t } from '~/utils/i18n';
import Paginator from '../Paginator';
import StepSizePicker from '../StepSizePicker';
import GroupsDatabaseHelpers from './helpers';
import {
  YearContainer,
  HeaderSection,
  GroupImage,
  GroupsTable,
  GroupName,
  LastCell,
  GroupTableRow,
  FirstCell,
  MiddleCell,
  Title,
  BoldedCellText,
} from './style';

type GroupsDatabaseProps = {
  groups: EditInstituteGroupFields[];
  onOpenGroups: (id: string) => void;
};

const DATABASE_ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 50];

export default function GroupsDatabase({
  onOpenGroups,
  groups,
}: GroupsDatabaseProps): JSX.Element {
  const theme = useTheme();
  const isDesktop = useMediaQuery({ minWidth: theme.breakpoints.desktopMin });

  const [rowsPerPage, setRowsPerPage] = useState<number>(
    DATABASE_ROWS_PER_PAGE_OPTIONS[0],
  );

  const graduationYears = [
    ...new Set(groups.map((grp) => grp.year.toString())),
  ].sort((a, b) => parseInt(a) - parseInt(b));

  const [yearKey, setYearKey] = useState<string>(graduationYears[0]);

  const filteredGroups = GroupsDatabaseHelpers.filterGroupsByYear(
    yearKey,
    groups,
  );

  const {
    totalNumberOfPages,
    currentPage,
    applyPaginationOnData,
    allowNextPage,
    allowPrevPage,
    incrementPage,
    decrementPage,
    resetPagination,
    updatePage,
  } = usePagination({
    itemsPerPage: rowsPerPage,
    totalItems: groups.length,
  });

  useEffect(() => {
    resetPagination();
  }, [yearKey, rowsPerPage]);

  const renderTableHeader = () => (
    <GroupTableRow noBorder isDesktop={isDesktop}>
      <EmptyCell />
      <FirstCell isDesktop={isDesktop}>
        <TableHeader>
          <TableHeaderText>
            {t('adminPanelInstitutes.databaseInternalId')}
          </TableHeaderText>
          <SortIcon />
        </TableHeader>
      </FirstCell>
      <MiddleCell>
        <TableHeader>
          <TableHeaderText>
            {t('adminPanelInstitutes.databaseType')}
          </TableHeaderText>
          <SortIcon />
        </TableHeader>
      </MiddleCell>
      <LastCell isDesktop={isDesktop}>
        <TableHeader>
          <TableHeaderText>
            {t('adminPanelInstitutes.databaseYear')}
          </TableHeaderText>
          <SortIcon />
        </TableHeader>
      </LastCell>
      <LastCell isDesktop={isDesktop}>
        <TableHeader>
          <TableHeaderText>
            {t('adminPanelInstitutes.databaseUsers')}
          </TableHeaderText>
          <SortIcon />
        </TableHeader>
      </LastCell>
    </GroupTableRow>
  );

  const renderTableFooter = () => (
    <PaginationWrapper>
      <StepSizePicker
        stepSize={rowsPerPage}
        onStepSizeChange={setRowsPerPage}
        options={DATABASE_ROWS_PER_PAGE_OPTIONS}
        title={t('adminPanelSearch.entries')}
      />
      <Paginator
        allowNext={allowNextPage}
        allowPrev={allowPrevPage}
        currentPage={currentPage}
        onPrevClick={decrementPage}
        onNextClick={incrementPage}
        numberOfPages={totalNumberOfPages}
        onPageClick={updatePage}
      />
    </PaginationWrapper>
  );

  const renderTableRow = ({
    item: group,
  }: {
    item: EditInstituteGroupFields;
  }) => {
    const groupUserCount = new Set(group.users).size;
    return (
      <GroupTableRow
        isDesktop={isDesktop}
        onPress={() => onOpenGroups(group.id)}
        testID={`GroupRow:${group.id}`}
      >
        <InstituteIconContainer>
          <GroupImage
            isSmall={!isDesktop}
            source={{
              uri: group?.avatar || group?.icon?.icon || undefined,
            }}
          />
        </InstituteIconContainer>
        <FirstCell isDesktop={isDesktop}>
          <GroupName>{group.name}</GroupName>
          <InstituteId>
            {t(`adminPanelSearch.flags.${group.institute.country}`)}
            &nbsp;
            {group.institute.internalId}
          </InstituteId>
        </FirstCell>
        {isDesktop && (
          <MiddleCell>
            <CellText>{group.type.name}</CellText>
          </MiddleCell>
        )}

        <LastCell isDesktop={isDesktop}>
          {!isDesktop && <BoldedCellText>{group.type.name}</BoldedCellText>}
          <CellText>{group.year}</CellText>
        </LastCell>
        {isDesktop && (
          <LastCell isDesktop={isDesktop}>
            <CellText>{groupUserCount}</CellText>
          </LastCell>
        )}
      </GroupTableRow>
    );
  };

  const paginatedGroups = applyPaginationOnData(filteredGroups);

  return (
    <Container>
      <HeaderSection isDesktop={isDesktop}>
        <Title isDesktop={isDesktop}>
          {t('adminPanelInstitutes.groupsDataBase')}
        </Title>
        <YearContainer isDesktop={isDesktop}>
          <SwitchSelector
            testID={'pickerYear'}
            items={graduationYears.map((year) => ({ key: year, title: year }))}
            selectedItemKey={yearKey}
            onSelectedItem={setYearKey}
          />
        </YearContainer>
      </HeaderSection>
      <GroupsTable<React.ElementType>
        data={paginatedGroups}
        renderItem={renderTableRow}
        ListHeaderComponent={isDesktop && renderTableHeader}
        ListFooterComponent={renderTableFooter}
        keyExtractor={(group: EditInstituteGroupFields) => group.id}
      />
    </Container>
  );
}
