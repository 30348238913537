import styled from 'styled-components/native';

export const Container = styled.View``;

export const Title = styled.Text`
  margin-vertical: 32px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 20px;
  line-height: 24px;
`;

export const Footer = styled.View`
  padding-vertical: 48px;
`;

export const VSeparator = styled.View`
  height: 16px;
`;
