/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query ProfilePagePhotoCategories
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  ProfilePagePhotoCategories,
  ProfilePagePhotoCategoriesVariables,
} from './types/ProfilePagePhotoCategories';
import { PROFILE_PAGE_PHOTO_CATEGORIES } from './';

export function useProfilePagePhotoCategoriesQuery(
  options?: QueryHookOptions<
    ProfilePagePhotoCategories,
    ProfilePagePhotoCategoriesVariables
  >,
): QueryResult<
  ProfilePagePhotoCategories,
  ProfilePagePhotoCategoriesVariables
> {
  return useQuery<
    ProfilePagePhotoCategories,
    ProfilePagePhotoCategoriesVariables
  >(PROFILE_PAGE_PHOTO_CATEGORIES, options);
}

export function useProfilePagePhotoCategoriesLazyQuery(
  options?: LazyQueryHookOptions<
    ProfilePagePhotoCategories,
    ProfilePagePhotoCategoriesVariables
  >,
): QueryTuple<ProfilePagePhotoCategories, ProfilePagePhotoCategoriesVariables> {
  return useLazyQuery<
    ProfilePagePhotoCategories,
    ProfilePagePhotoCategoriesVariables
  >(PROFILE_PAGE_PHOTO_CATEGORIES, options);
}

export function createProfilePagePhotoCategoriesMock({
  variables,
  data,
  error,
}: {
  variables?: ProfilePagePhotoCategoriesVariables;
  data?: ProfilePagePhotoCategories;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: PROFILE_PAGE_PHOTO_CATEGORIES,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
