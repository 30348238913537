import styled from 'styled-components/native';
import Image from '~/components/Image';
import Icon from '../Icon';

type IModuleCellProps = {
  isAdded?: boolean;
};

export const Container = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})<IModuleCellProps>`
  background-color: ${({ theme }) => theme.color.base.c0};
  height: 110px;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  padding: 16px;
  border-width: 1px;
  border-color: ${({ theme, isAdded }) =>
    isAdded ? theme.color.base.c3 : theme.color.base.c3};
  border-radius: 14px;
  box-shadow: -3px 3px 2px ${({ theme }) => theme.color.base.c1};
  justify-content: space-between;
`;

export const LeftSideContent = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ModuleName = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c7};
  font-size: 16px;
  line-height: 21px;
`;

export const DisclamerText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c5};
  font-size: 10px;
  line-height: 12px;
  font-style: italic;
`;

export const Text = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 14px;
`;

export const IconContainer = styled.TouchableOpacity<IModuleCellProps>`
  width: 32px;
  height: 32px;
  background-color: ${({ theme, isAdded }) =>
    isAdded ? theme.color.brand_02 : theme.color.base.c1};
  border-radius: 32px;
  align-items: center;
  justify-content: center;
`;

export const ModuleImage = styled(Image)<IModuleCellProps>`
  width: 114px;
  height: 78px;
  background-color: ${({ theme, isAdded }) =>
    isAdded ? theme.color.brand_02 : theme.color.base.c1};
  border-radius: 5px;
  margin-right: 16px;
`;

export const CellIcon = styled(Icon).attrs<IModuleCellProps>(
  ({ theme, isAdded }) => ({
    color: isAdded ? theme.color.base.c0 : theme.color.brand_02,
    size: 24,
  }),
)<IModuleCellProps>``;
