import React from 'react';
import { general } from '~/assets/imgs';
import Button from '~/components/Button';
import AuthWebLayout from '~/screens/Auth/layout/AuthLayout/AuthWebLayout';
import { LanguageProps } from '~/screens/Auth/layout/AuthLayout/types';
import { t } from '~/utils/i18n';
import {
  WebTitle,
  WebDescription,
  WebButtonsContainer,
  WebContentView,
  WebImage,
} from '../style';

type LaunchLayoutProps = {
  onSignUp: () => void;
  onLogIn: () => void;
} & LanguageProps;

export default function WebLaunch({
  onSignUp,
  onLogIn,
  selectedLocale,
  onSelectedLocale,
}: LaunchLayoutProps): JSX.Element {
  return (
    <AuthWebLayout
      isContentCentered
      languageProps={{
        selectedLocale,
        onSelectedLocale,
      }}
    >
      <WebContentView>
        <WebImage source={general.double_hearts} />
        <WebTitle>{`${t('launch.title')}`}</WebTitle>
        <WebDescription bold>
          {t('g.gradoo')}
          <WebDescription>{t('launch.description')}</WebDescription>
        </WebDescription>
        <WebButtonsContainer>
          <Button
            testID={'buttonSignUp'}
            text={t('launch.signUp')}
            size={'2xl'}
            type={'primary-brand-02'}
            bottomMargin={16}
            flex
            onPress={onSignUp}
          />
          <Button
            testID={'buttonLogIn'}
            text={t('launch.logIn')}
            type={'primary-brand-01'}
            size={'2xl'}
            flex
            onPress={onLogIn}
          />
        </WebButtonsContainer>
      </WebContentView>
    </AuthWebLayout>
  );
}
