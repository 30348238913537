import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import styled from 'styled-components/native';
import Image from '~/components/Image';
import { TabScreenContainer } from '~/screens/style';

export const Container = styled(TabScreenContainer)`
  padding: 0 5px 0 5px;
`;

export const ScrollView = styled(KeyboardAwareScrollView)`
  padding-horizontal: 16px;
`;

export const ImgDateWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  margin-top: 30px;
`;
export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 16px;
`;

export const SubTitle = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c5};
  font-size: 12px;
  margin-bottom: 8px;
`;

export const IconList = styled.View`
  margin-left: 10px;
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

export const RowWrapper = styled.View`
  flex-direction: row;
`;

export const HrLine = styled.View`
  height: 1px;
  background-color: ${({ theme }) => theme.color.base.c3};
  margin-top: 30px;
`;

export const AboutEvent = styled.View`
  margin-top: 30px;
`;

export const Desc = styled.Text`
  margin-top: 20px;
  margin-bottom: 50px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  size: 16px;
  line-height: 25px;
`;

export const EventImage = styled(Image)`
  width: 400px;
  height: 193px;
  background-color: ${({ theme }) => theme.color.base.c2};
  align-self: center;
  margin-top: 20px;
`;

export const DetailsWrapper = styled.View`
  flex-direction: row;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 20px;
`;

export const ImageBox = styled(Image)`
  background-color: ${({ theme }) => theme.color.base.c3};
  width: 40px;
  height: 40px;
  border-radius: 5px;
  margin-right: 15px;
`;

export const TitleAndDate = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 85%;
`;

export const TitleWrapper = styled.View``;

export const LocationText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c6};
  size: 12px;
  margin-left: 10px;
`;

export const AttendeesText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c6};
  size: 12px;
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c9,
}))``;

export const AvatarsGroup = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const CreateEventContainer = styled.View`
  position: absolute;
  background-color: ${({ theme }) => theme.color.base.c0};
  height: 15%;
  width: 100%;
  bottom: 0;

  padding: 20px 15px 0 15px;
`;

export const UserIsJoinedText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  size: 18px;
  color: ${({ theme }) => theme.color.base.c9};
  text-align: center;
  padding-top: 8px;
`;
