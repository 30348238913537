import React from 'react';
import { Platform } from 'react-native';
import { general } from '~/assets/imgs';
import Button from '~/components/Button';
import GroupCell from '~/components/GroupCell';
import GroupRequestCell from '~/components/GroupRequestCell';
import NavHeader from '~/components/NavHeader';
import UnderlineButton from '~/components/UnderlineButton';
import { GroupUser } from '~/data/models/group';
import AuthWebLayout from '~/screens/Auth/layout/AuthLayout/AuthWebLayout';
import { LanguageProps } from '~/screens/Auth/layout/AuthLayout/types';
import {
  DownloadOnMobileContainer,
  DownloadOnMobileTitle,
} from '~/screens/Common/InviteSuccess/layout/style';
import { StackScreenContainer } from '~/screens/style';
import { t } from '~/utils/i18n';
import {
  ScrollView,
  NoGroupImage,
  NoGroupDescp,
  RequestTitle,
  SectionTitle,
  Footer,
  InfoContent,
  Loading,
  ErrorMessage,
  Content,
  RefreshControl,
  ColorBox,
  NoGroupContent,
  NoGroupTitle,
  HeartsImage,
  UserName,
  NoGroupContainer,
  NoGroupsTitle,
  NoGroupsDescp,
} from './style';

export type LobbyLayoutProps = {
  userName: string;
  groupUsersAccepted: GroupUser[];
  groupUsersPending: GroupUser[];
  loading?: boolean;
  errorMessage?: string;
  onLogout: () => void;
  onSelectedGroup: (groupId: string, userGroupId: string) => void;
  onJoinGroup: () => void;
  onMessenger: () => void;
  onRemoveRequest: (groupId: string) => void;
  onRetry: () => void;
  onRefresh: () => void;
  openAppStore: (os: 'ios' | 'android') => void;
  onSelectedWebGroup: (groupId: string) => void;
} & LanguageProps;

export default function Lobby({
  userName,
  groupUsersAccepted,
  groupUsersPending,
  loading = false,
  errorMessage = undefined,
  selectedLocale,
  onLogout,
  onSelectedGroup,
  onJoinGroup,
  onRemoveRequest,
  onRetry,
  onSelectedLocale,
  onRefresh,
  onMessenger,
  openAppStore,
  onSelectedWebGroup,
}: LobbyLayoutProps): JSX.Element {
  const noGroup =
    groupUsersAccepted.length == 0 && groupUsersPending.length == 0;

  const isWeb = Platform.OS === 'web';
  const isReadyToRender = !loading && !errorMessage;
  const languageProps = {
    selectedLocale,
    onSelectedLocale,
  };

  if (isWeb) {
    return (
      <AuthWebLayout
        languageProps={languageProps}
        isContentCentered={false}
        HeaderRightComponent={
          <Button
            testID="buttonLogout"
            size="sm"
            text={t('g.logout')}
            type="secondary-base"
            onPress={onLogout}
          />
        }
      >
        {(() => {
          if (loading) {
            return (
              <InfoContent>
                <Loading />
              </InfoContent>
            );
          }
          return (
            <Content>
              <HeartsImage source={general.lobby_hearts} />
              <UserName>
                {t('lobby.lobbyTitle', { firstName: userName })}
              </UserName>
              {noGroup ? (
                <>
                  <NoGroupContainer>
                    <NoGroupsTitle>{t('lobby.noGroupsTitle')}</NoGroupsTitle>
                    <NoGroupsDescp>
                      {t('lobby.noGroupsDescription')}
                    </NoGroupsDescp>
                  </NoGroupContainer>
                  <DownloadOnMobileContainer>
                    <DownloadOnMobileTitle>
                      {t('inviteSuccess.mobileSectionTitle')}
                    </DownloadOnMobileTitle>
                    <Button
                      testID="buttonDownloadAndroid"
                      text={t('inviteSuccess.btnDownloadOnAndroid')}
                      onPress={() => openAppStore('android')}
                      type="secondary-base"
                      flex
                      size={'2xl'}
                    />
                    <Button
                      testID="buttonDownloadiOS"
                      text={t('inviteSuccess.btnDownloadOnIos')}
                      onPress={() => openAppStore('ios')}
                      flex
                      type="secondary-contrast"
                      size={'2xl'}
                    />
                  </DownloadOnMobileContainer>
                </>
              ) : (
                <>
                  {groupUsersAccepted.length > 0 ? (
                    <>
                      <SectionTitle>{t('lobby.sectionGroups')}</SectionTitle>
                      {groupUsersAccepted.map((groupUser) => (
                        <GroupCell
                          key={groupUser.id}
                          type={'primary'}
                          group={groupUser.group}
                          onPress={() =>
                            groupUser.group &&
                            onSelectedWebGroup(groupUser.group.id)
                          }
                        />
                      ))}
                    </>
                  ) : null}
                  {groupUsersPending.length > 0 ? (
                    <>
                      <SectionTitle>{t('lobby.sectionRequests')}</SectionTitle>
                      {groupUsersPending.map((groupUser, i) => (
                        <GroupRequestCell
                          testID={`groupRequestCell:${i}`}
                          key={groupUser.id}
                          groupUser={groupUser}
                          onRemove={() =>
                            groupUser.group &&
                            onRemoveRequest(groupUser.group.id)
                          }
                        />
                      ))}
                    </>
                  ) : null}
                </>
              )}
            </Content>
          );
        })()}
      </AuthWebLayout>
    );
  }

  return (
    <StackScreenContainer>
      <ColorBox />
      <NavHeader
        title={t('gradoo')}
        type={'main'}
        RightComponent={
          <Button
            testID="buttonLogout"
            size="sm"
            text={t('g.logout')}
            type="secondary-base"
            onPress={onLogout}
          />
        }
      />
      <RequestTitle>{`${t('hey')} ${userName} ✌🏼`}</RequestTitle>
      <ScrollView
        refreshControl={
          <RefreshControl refreshing={loading} onRefresh={onRefresh} />
        }
      >
        {(() => {
          if (loading) {
            return (
              <InfoContent>
                <Loading />
              </InfoContent>
            );
          } else if (errorMessage) {
            return (
              <InfoContent>
                <ErrorMessage>{errorMessage}</ErrorMessage>
                <UnderlineButton
                  testID={'buttonRetry'}
                  type={'light'}
                  text={t('retry')}
                  onPress={onRetry}
                />
              </InfoContent>
            );
          }
          return (
            <>
              {groupUsersAccepted && groupUsersAccepted.length > 0 ? (
                <>
                  <SectionTitle>{t('lobby.sectionGroups')}</SectionTitle>
                  {groupUsersAccepted.map((groupUser) => (
                    <GroupCell
                      key={groupUser.id}
                      type={'primary'}
                      group={groupUser.group}
                      onPress={() =>
                        groupUser.group &&
                        onSelectedGroup(groupUser.group.id, groupUser.id)
                      }
                    />
                  ))}
                </>
              ) : null}
              {groupUsersPending && groupUsersPending.length > 0 ? (
                <>
                  <SectionTitle>{t('lobby.sectionRequests')}</SectionTitle>
                  {groupUsersPending.map((groupUser, i) => (
                    <GroupRequestCell
                      testID={`groupRequestCell:${i}`}
                      key={groupUser.id}
                      groupUser={groupUser}
                      onRemove={() =>
                        groupUser.group && onRemoveRequest(groupUser.group.id)
                      }
                    />
                  ))}
                </>
              ) : null}
            </>
          );
        })()}
      </ScrollView>
      {isReadyToRender && noGroup && (
        <NoGroupContent>
          <NoGroupImage source={general.abschluss} />
          <NoGroupTitle>{t('lobby.nothingHereYet')}</NoGroupTitle>
          <NoGroupDescp>{t('lobby.noGroupDescp')}</NoGroupDescp>
        </NoGroupContent>
      )}
      {isReadyToRender && (
        <Footer>
          {noGroup && (
            <Button
              testID="buttonSupport"
              text={t('lobby.getInTouchWithSupport')}
              type="secondary-base"
              bottomMargin={16}
              flex
              size="2xl"
              onPress={onMessenger}
            />
          )}
          <Button
            testID={'buttonJoinGroup'}
            text={noGroup ? t('lobby.joinGroup') : t('lobby.joinAnotherGroup')}
            type="secondary-contrast"
            flex
            size="2xl"
            onPress={onJoinGroup}
          />
        </Footer>
      )}
    </StackScreenContainer>
  );
}
