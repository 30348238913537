/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation DestroyTeam
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { DestroyTeam, DestroyTeamVariables } from './types/DestroyTeam';
import { DESTROY_TEAM } from './';

export function useDestroyTeamMutation(
  options?: MutationHookOptions<DestroyTeam, DestroyTeamVariables>,
): MutationTuple<DestroyTeam, DestroyTeamVariables> {
  return useMutation<DestroyTeam, DestroyTeamVariables>(DESTROY_TEAM, options);
}

export function createDestroyTeamMock({
  variables,
  data,
  error,
}: {
  variables?: DestroyTeamVariables;
  data?: DestroyTeam;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: DESTROY_TEAM,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
