import { Platform } from 'react-native';
import styled from 'styled-components/native';

export const Container = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const TextInput = styled.TextInput.attrs(({ theme }) => ({
  placeholderTextColor: theme.color.base.c6,
}))`
  margin-horizontal: 8px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 14px;
  color: ${({ theme }) => theme.color.base.c6};
  margin-top: 8px;
  ${Platform.OS == 'web' &&
  `
    outline-width: 0px;
  `}
`;

export const ButtonView = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})`
  padding-vertical: 8px;
`;

export const ButtonText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.brand_02};
  font-size: 16px;
`;
