import React from 'react';
import { NotificationText, NotificationContainer, SeverityEnum } from './style';

type InlineNotificationProps = {
  severity?: SeverityEnum;
  text: string;
};

const InlineNotification: React.FC<InlineNotificationProps> = ({
  severity = SeverityEnum.Info,
  text,
}) => {
  return (
    <NotificationContainer severity={severity}>
      <NotificationText>{text}</NotificationText>
    </NotificationContainer>
  );
};

export default InlineNotification;
