import styled from 'styled-components/native';
import Avatar from '~/components/Avatar';
import TeamIcon from '~/components/TeamIcon';

export const Container = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  flex-direction: row;
  padding: 16px 16px 8px 16px;
`;

export const FlatList = styled.FlatList`
  background-color: ${({ theme }) => theme.color.base.c0};
  flex: 1;
  flex-grow: 1;
  flex-direction: row;
  overflow: hidden; // Prevent content from overflowing
  overflow-x: auto;
`;

export const Header = styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding-right: 12px;
  padding-bottom: 16px;
`;

export const InfoText = styled.Text`
  color: ${({ theme }) => theme.color.base.c6};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 14px;
`;

export const AuthorTouchableView = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})`
  width: 60px;
  align-items: center;
`;

interface IAuthor {
  selected: boolean;
}

export const AuthorUser = styled(Avatar).attrs({
  size: 32,
})<IAuthor>`
  border-radius: 16px;
  border-color: ${({ selected, theme }) =>
    selected ? theme.color.brand_02 : theme.color.base.c0};
  border-width: 3px;
  align-items: center;
  justify-content: center;
`;

export const AuthorTeam = styled(TeamIcon).attrs({
  size: 32,
  iconSize: 12,
})<IAuthor>`
  border-color: ${({ selected, theme }) =>
    selected ? theme.color.brand_02 : theme.color.base.c0};
  border-width: 3px;
  border-radius: 8px;
`;

export const AuthorName = styled.Text<IAuthor>`
  margin-top: 2px;
  text-align: center;
  font-family: ${({ selected, theme }) =>
    selected ? theme.fontFamily.core.w600 : theme.fontFamily.core.w400};
  color: ${({ selected, theme }) =>
    selected ? theme.color.brand_02 : theme.color.base.c5};
  font-size: 10px;
`;

export const PostContainer = styled.View``;
