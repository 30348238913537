import styled from 'styled-components/native';

export const Container = styled.View`
  z-index: 99999;
`;

export const Content = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  padding-top: 15px;
  padding-horizontal: 16px;
  padding-bottom: 64px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
`;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 18px;
  line-height: 22px;
`;

export const Line = styled.View`
  width: 64px;
  height: 8px;
  background-color: ${({ theme }) => theme.color.base.c2};
  border-radius: 5px;
`;

export const LineWrapper = styled.View`
  align-items: center;
  padding-bottom: 30px;
`;

export const FlexBox = styled.View`
  flex-direction: row;
  justify-content: space-between;
  vertical-align: middle;
  align-items: center;
`;

export const StyledFlatList = styled.FlatList`
  padding-top: 16px;
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c0,
}))``;
