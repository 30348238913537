/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query UserExists
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { UserExists, UserExistsVariables } from './types/UserExists';
import { USER_EXISTS } from './';

export function useUserExistsQuery(
  options?: QueryHookOptions<UserExists, UserExistsVariables>,
): QueryResult<UserExists, UserExistsVariables> {
  return useQuery<UserExists, UserExistsVariables>(USER_EXISTS, options);
}

export function useUserExistsLazyQuery(
  options?: LazyQueryHookOptions<UserExists, UserExistsVariables>,
): QueryTuple<UserExists, UserExistsVariables> {
  return useLazyQuery<UserExists, UserExistsVariables>(USER_EXISTS, options);
}

export function createUserExistsMock({
  variables,
  data,
  error,
}: {
  variables?: UserExistsVariables;
  data?: UserExists;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: USER_EXISTS,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
