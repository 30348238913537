import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components/native';
import Button from '~/components/Button';
import WebModal, {
  WebModalProps,
  WebModalHandler,
} from '~/components/WebModal';
import WebSelector, { WebSelectorProps } from '~/components/WebSelector';
import { t } from '~/utils/i18n';
import {
  Container,
  ModalTitle,
  ModalSelectorBox,
  ModalSelectorSubTitle,
} from './style';

type WebModalSelectors = {
  id: string;
  title: string;
  hidden?: boolean;
} & WebSelectorProps;

type WebModalSelectorProps = {
  onConfirm(): void;
  onClose?: () => void;
  title: string;
  buttonText?: string;
  confirmButtonTestId?: string;
  cancelButtonTestId?: string;
  webSelectors: WebModalSelectors[];
} & WebModalProps;

export type WebModalConfirmHandler = WebModalHandler;

const WebModalSelector: ForwardRefRenderFunction<
  WebModalConfirmHandler,
  WebModalSelectorProps
> = (
  {
    title,
    buttonText,
    onConfirm,
    onClose,
    defaultOpen = false,
    confirmButtonTestId,
    webSelectors,
  },
  ref,
): JSX.Element => {
  const theme = useTheme();
  const isDesktop = useMediaQuery({ minWidth: theme.breakpoints.desktopMin });

  return (
    <WebModal ref={ref} defaultOpen={defaultOpen} onCloseModal={onClose}>
      <Container isDesktop={isDesktop}>
        <ModalTitle>{title}</ModalTitle>
        <ModalSelectorBox>
          {webSelectors.map(({ hidden = false, ...selector }) => {
            if (hidden) {
              return null;
            }

            return (
              <React.Fragment key={`a1${selector.id}`}>
                <ModalSelectorSubTitle>{selector.title}</ModalSelectorSubTitle>
                <WebSelector
                  testID={selector.testID}
                  label={selector.label}
                  items={selector.items}
                  onItemSelect={selector.onItemSelect}
                  iconName={selector.iconName}
                  isEmptyText={selector.isEmptyText}
                  onSearch={selector.onSearch}
                />
              </React.Fragment>
            );
          })}
        </ModalSelectorBox>
        <Button
          testID={confirmButtonTestId}
          text={buttonText || t('alert.confirm')}
          onPress={onConfirm}
        />
      </Container>
    </WebModal>
  );
};

export default forwardRef(WebModalSelector);
