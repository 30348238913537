import React from 'react';
import { Event } from '~/data/models/calendar';
import { getAvatarAndName } from '~/utils/helpers';
import { t } from '~/utils/i18n';
import EventDate from '../EventDate';
import IconStyled from '../IconStyled';
import {
  Card,
  Title,
  SubTitle,
  TopWrapper,
  IconWrapper,
  BottomWrapper,
  ImageBox,
  TitleAndDate,
  TitleWrapper,
  LocationText,
  AttendeesText,
  CustomBadge,
} from './style';

export type UpcomingEvents = {
  event: Event;
  openEvent: (eventId: string) => void;
};

export default function UpcomingEventsCard({
  event,
  openEvent,
}: UpcomingEvents): JSX.Element {
  const EventText = t(`cardEvent.badge${event.eventType}`);

  const attendeesText = (attendeesCount: number | undefined) => {
    if (!attendeesCount) {
      return '-';
    }
    return t('g.attendees', { attendeesCount });
  };

  const { authorName, avatar } = getAvatarAndName(event);
  return (
    <Card
      key={event.id}
      testID={`EventCard:${event.id}`}
      onPress={() => openEvent(event.id)}
    >
      <TopWrapper>
        <ImageBox
          source={{
            uri: avatar,
          }}
        />
        <TitleAndDate>
          <TitleWrapper>
            <Title>{event.title}</Title>
            <SubTitle>
              {event.isSponsored && t('cardEvent.sponsored')}
              {t('cardEvent.createdBy', { by: authorName })}
            </SubTitle>
          </TitleWrapper>
          <EventDate date={new Date(event.startDateTime)} />
        </TitleAndDate>
      </TopWrapper>
      <BottomWrapper>
        <IconWrapper>
          <CustomBadge
            text={EventText}
            size={'large'}
            type={'basic'}
            eventType={event.eventType}
          />
        </IconWrapper>
        <IconWrapper>
          <IconStyled name="location_dark" />
          <LocationText numberOfLines={1}>{event.location}</LocationText>
        </IconWrapper>
        <IconWrapper>
          <AttendeesText>
            {attendeesText(event.attendees?.length)}
          </AttendeesText>
        </IconWrapper>
      </BottomWrapper>
    </Card>
  );
}
