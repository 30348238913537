import * as Localization from 'expo-localization';
import queryString from 'query-string';
import { Platform } from 'react-native';
import { getDeviceId, getSystemVersion } from 'react-native-device-info';
import { Group, GroupDetail, GroupSectionItem } from '~/data/models/group';
import { AuthUser, User } from '~/data/models/user';
import { getCurrentVersion } from '~/utils/helpers';
import { t } from '~/utils/i18n';
import { COUNTRIES } from '../constants';
import { GroupMember } from '../models/admin';
import { AdminSearchResultType } from '../models/admin';
import { AppLocale } from '../models/app';
import { Country } from '../models/marketProfile';
import { PollOption, PollVoteOption, Comment, Post } from '../models/post';
import { CollagesPhoto, Quote } from '../models/yearbook';
import { Language } from '../operations/global';

export function getGroupSectionsByYear(groups: Group[]): GroupSectionItem[] {
  const groupsByYear: { [key: number]: GroupSectionItem } = {};
  groups.forEach((group) => {
    if (!groupsByYear[group.year]) {
      groupsByYear[group.year] = {
        title: `${t('graduationYear')} ${group.year}`,
        data: [],
      };
    }
    groupsByYear[group.year].data.push(group);
  });

  const sortedYears = Object.keys(groupsByYear)
    .map(Number)
    .sort((a, b) => b - a);

  const sortedSections = sortedYears.map((year) => groupsByYear[year]);

  return sortedSections;
}

export function getPollVotedOptions(
  pollOptions: PollOption[],
  userId: string,
): PollVoteOption[] {
  const votedOptions: PollVoteOption[] = [];
  pollOptions.forEach((pollOption) =>
    pollOption.votes?.forEach((pollVote) => {
      if (pollVote?.userGroup?.user.id === userId) {
        votedOptions.push({
          voteId: pollVote.id,
          optionId: pollOption.id,
        });
      }
    }),
  );
  return votedOptions;
}

export function getPollParticipantsCount(pollOptions: PollOption[]): number {
  const userIds: string[] = [];
  pollOptions.forEach((pollOption) =>
    pollOption.votes?.forEach((pollVote) =>
      userIds.push(pollVote?.userGroup?.user.id as string),
    ),
  );
  return new Set(userIds).size;
}

//likes query are based on authUserGroupId
export function isPostLiked(post: Post): boolean {
  return (post.likes && post.likes?.edges.length > 0) || false;
}

export function isCommentLiked(comment: Comment): boolean {
  return (comment.likes && comment.likes?.edges.length > 0) || false;
}

export function isCollagePhotoLiked(collagePhoto: CollagesPhoto): boolean {
  return (
    (collagePhoto.collagesPhotoLikes?.edges &&
      collagePhoto.collagesPhotoLikes?.edges.length > 0) ||
    false
  );
}

export function isQuoteLiked(quote: Quote): boolean {
  return (
    (quote.quotesInstanceLikes?.edges &&
      quote.quotesInstanceLikes?.edges.length > 0) ||
    false
  );
}

interface NormalizedLocaleProps {
  locale?: string;
  includeSubLocale?: boolean;
}

export function getNormalizedLocale(props?: NormalizedLocaleProps): AppLocale {
  const locale = props?.locale || Localization.locale;
  const includeSubLocale = props?.includeSubLocale || true;
  if (includeSubLocale && locale.includes('de') && locale.includes('at')) {
    return 'de-at';
  }
  if (locale.includes('de')) {
    return 'de';
  } else if (locale.includes('tr')) {
    return 'tr';
  }
  return 'en';
}

export function getLanguageFromLocale(locale = Localization.locale): Language {
  const normalizedLocale = getNormalizedLocale({ locale });
  if (normalizedLocale === 'de' || normalizedLocale === 'de-at') {
    return Language.GERMAN;
  } else if (normalizedLocale === 'tr') {
    return Language.TURKISH;
  }
  return Language.ENGLISH;
}

export function getLocaleFromLanguage(language: Language): AppLocale {
  if (language === Language.ENGLISH) {
    return 'en';
  } else if (language === Language.GERMAN) {
    return 'de';
  } else if (language === Language.TURKISH) {
    return 'tr';
  }
  return 'auto';
}

export function getCountryFromLocale(locale = Localization.locale): Country {
  const normalizedLocale = getNormalizedLocale({ locale });
  if (normalizedLocale === 'tr') {
    return 'TR';
  } else if (normalizedLocale === 'de-at') {
    return 'AT';
  }
  return 'DE';
}

export function getYearbookTypeformQueryParams(
  user: AuthUser,
  group: GroupDetail,
  utm_medium: 'banner' | 'onboarding',
): string {
  const queryParam1 = {
    utm_source: `mobile-${Platform.OS}`,
    utm_medium,
    utm_campaign: 'static-inapp',
  };

  const queryParam2 = {
    firstname: user.firstName,
    surname: user.lastName,
    email: user.email,
    institute: group.institute.name,
    internalid: group.institute.internalId,
    country: group.institute.country,
    group: group.name,
    groupid: group.id,
    gradyear: `${group.year}`,
  };

  const queryParam1Str = queryString.stringify(queryParam1);
  const queryParam2Str = queryString.stringify(queryParam2);

  return `${queryParam1Str}#${queryParam2Str}`;
}

export function getFeedTypeformQueryParams(
  user: AuthUser,
  group: GroupDetail,
): string {
  const queryParam1 = {
    utm_source: `mobile-${Platform.OS}`,
    utm_medium: 'feed-banner',
  };

  const queryParam2 = {
    user_id: user.id,
    email: user.email,
    appversion: getCurrentVersion(),
    device: getDeviceId(),
    osversion: getSystemVersion(),
    groupid: group.id,
    instituteid: group.institute.id,
    gradtype: group.type.name,
    gradyear: `${group.year}`,
    country: group.institute.country,
  };

  const queryParam1Str = queryString.stringify(queryParam1);
  const queryParam2Str = queryString.stringify(queryParam2);

  return `${queryParam1Str}#${queryParam2Str}`;
}

export function getCountryFromPhoneCountryCode(
  phoneCode?: string | null,
): Country {
  const countryInfos = Object.values(COUNTRIES);
  const countryInfo = countryInfos.find((code) => code.code === phoneCode);
  return countryInfo?.country || 'DE';
}

export function getGroupMembersCount(members: (User | GroupMember)[]): number {
  const memberIds = members.map((member) => member.id);
  return new Set(memberIds).size;
}

export const createOrderedSearchResults = (
  arrayResults: AdminSearchResultType[],
) =>
  arrayResults.filter((result) => result !== null) as AdminSearchResultType[];
