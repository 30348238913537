import styled from 'styled-components/native';

export const Container = styled.View`
  margin-vertical: 16px;
  background-color: ${({ theme }) => theme.color.base.c1};
  padding-top: 16px;
  padding-bottom: 8px;
  padding-horizontal: 16px;
  border-radius: 14px;
`;

export const ItemContainer = styled.View`
  margin-bottom: 8px;
  flex-direction: row;
  align-items: center;
`;

export const ItemText = styled.Text`
  flex: 1;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c8};
  font-size: 16px;
`;

export const TouchableView = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})`
  height: 32px;
  min-width: 100px;
  padding: 4px 16px;
  background-color: ${({ theme }) => theme.color.base.c0};
  border-radius: 16px;
  box-shadow: 1px 1px 6px ${({ theme }) => theme.color.base.c2};
  margin-bottom: 8px;
`;
