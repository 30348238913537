import { useState } from 'react';

export type UseSortOptions = {
  initialSortBy: string;
};

export type UseSortProps = {
  sortedBy: string;
  toggleSort: (key: string) => void;
};

const useSort = ({ initialSortBy }: UseSortOptions): UseSortProps => {
  const [sortedBy, setSortBy] = useState(initialSortBy);

  const toggleSort = (columnKey: string) => {
    setSortBy((currentSortBy) => {
      const isCurrentSortField = currentSortBy.includes(columnKey);
      const isDescending = currentSortBy.startsWith('-');

      if (!isCurrentSortField || isDescending) {
        return columnKey;
      }

      return `-${columnKey}`;
    });
  };

  return { sortedBy, toggleSort };
};

export default useSort;
