import React from 'react';
import { SearchShortcutKey } from '~/utils/types/adminSearch';
import StyledShortcutImage from '../ShortcutImage';
import {
  SearchDropdownShortcut,
  SearchDropdownShortcutContainer,
  SearchDropdownShortcutlabel,
  SearchDropdownShortcutPlus,
} from './style';

type Shortcut = {
  keys: SearchShortcutKey[];
  label: string;
};

export default function Shortcut({ keys, label }: Shortcut): JSX.Element {
  return (
    <SearchDropdownShortcutContainer>
      <SearchDropdownShortcut>
        {keys.map((key, index) => (
          <>
            <StyledShortcutImage
              name={key.imageSource}
              width={key.width}
              height={key.height}
            />
            {index !== keys.length - 1 && (
              <SearchDropdownShortcutPlus>+</SearchDropdownShortcutPlus>
            )}
          </>
        ))}
      </SearchDropdownShortcut>
      <SearchDropdownShortcutlabel>{label}</SearchDropdownShortcutlabel>
    </SearchDropdownShortcutContainer>
  );
}
