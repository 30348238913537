import React from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';
import Button from '~/components/Button';
import CommonIconCell, {
  CommonIconCellProps,
} from '~/components/CommonIconCell';
import { NotificationGroupRequestCarousel } from '~/components/NotificationCarousel';
import SectionHeader from '~/components/SectionHeader';
import TrackingFlatlist from '~/components/TrackingFlatList';
import { AdsBiggerImage } from '~/components/common/style';
import { BestMatchedAsset } from '~/data/models/campaign';
import { Group, UserGroup } from '~/data/models/group';
import { VariantTypeEnum } from '~/data/operations/global';
import { getInviteUrl } from '~/utils/helpers';
import useAdsTracking from '~/utils/hooks/useAdsTracking';
import { t } from '~/utils/i18n';
import { HelpCell } from './HelpCell';
import {
  Container,
  LNContainer,
  ProfileAvatar,
  NameText,
  Header,
  Content,
  CardView,
  CardHeader,
  GroupImage,
  CardInfo,
  GroupName,
  GroupDetail,
  VSpacer,
  LatestVersionText,
  VersionText,
  TeamButtonWrapper,
  MiniAdsTitle,
} from './style';

export type ProfileLayoutProps = {
  userGroup?: UserGroup | null;
  group?: Group | null;
  version: string;
  pendingUserGroups: UserGroup[];
  needUpdate?: boolean;
  now?: Date;
  loadingMore: boolean;
  coreAdmin: boolean;
  authUserId: string;
  unReadMessageCount: number;
  onSettings: () => void;
  onShareLink: (url: string) => void;
  onContact: () => void;
  onHelpCenter: () => void;
  onEditProfile: () => void;
  onNotification: () => void;
  onAppSetup: () => void;
  onRateApp: () => void;
  onImprintAndLicences: () => void;
  onDataPolicy: () => void;
  onDeclineUser: (userId: string) => void;
  onAcceptUser: (userId: string) => void;
  openBannersInBrowser: (url: string) => void;
};

export default function Profile({
  userGroup,
  group,
  version,
  needUpdate,
  pendingUserGroups,
  loadingMore,
  now,
  coreAdmin,
  authUserId,
  unReadMessageCount,
  onSettings,
  onShareLink,
  onContact,
  onHelpCenter,
  onEditProfile,
  onNotification,
  onAppSetup,
  onRateApp,
  onImprintAndLicences,
  onDataPolicy,
  onDeclineUser,
  onAcceptUser,
  openBannersInBrowser,
}: ProfileLayoutProps): JSX.Element {
  const groupId = group?.id;
  const token = group?.token;
  const inviteUrl = groupId && token ? getInviteUrl(groupId, token) : '';

  const { handleViewTracking, handleAdsClick, bestMatchedAsset, randomIndex } =
    useAdsTracking({ authUserId, variantType: VariantTypeEnum.LARGE });

  const settingCellData: CommonIconCellProps[] = [
    {
      info: {
        text: t('g.notifications'),
        mainIcon: 'notification-text',
      },
      testID: 'buttoNotifications',
      onPress: onNotification,
    },
    {
      info: {
        text: t('profile.appSetup'),
        mainIcon: 'settings-01',
      },
      testID: 'buttonAppSetup',
      onPress: onAppSetup,
    },
    {
      info: {
        text: t('profile.helpCenter'),
        mainIcon: 'annotation-question',
      },
      onPress: onHelpCenter,
    },
    {
      testID: 'buttonRateApp',
      info: {
        text: t('profile.rateOurApp'),
        mainIcon: 'hearts',
      },
      onPress: onRateApp,
    },
  ];

  const legalCellData: CommonIconCellProps[] = [
    {
      info: {
        text: t('profile.imprintAndLicences'),
      },
      testID: 'buttonImprintAndLicences',
      onPress: onImprintAndLicences,
    },
    {
      info: {
        text: t('profile.dataPolicyCenter'),
      },
      testID: 'buttonDataPolicy',
      onPress: onDataPolicy,
    },
  ];

  const renderItem = ({
    item,
    index,
  }: {
    item?: BestMatchedAsset;
    index: number;
  }) => {
    const post = item as BestMatchedAsset;
    return (
      <TouchableOpacity
        testID={`${index}:AdsCell:${post?.id}`}
        onPress={() => {
          handleAdsClick(
            post?.id as string,
            post?.channelData?.[0]?.trackingUrl as string,
            openBannersInBrowser,
          );
        }}
      >
        <AdsBiggerImage
          source={{ uri: post?.channelData?.[0]?.imageLarge as string }}
        />
      </TouchableOpacity>
    );
  };

  return (
    <Container>
      <LNContainer />
      <Header>
        <ProfileAvatar userGroup={userGroup} size={48} />
        <NameText>{userGroup?.user.firstName}</NameText>
        <Button
          text={t('profile.manageProfile')}
          testID="buttonEditProfile"
          size={'sm'}
          type={'secondary-base'}
          onPress={onEditProfile}
        />
      </Header>
      <Content>
        <CardView>
          <CardHeader>
            <GroupImage
              source={{
                uri: group?.avatar || group?.icon?.icon || undefined,
              }}
            />
            <CardInfo>
              <GroupName>{group?.name}</GroupName>
              <GroupDetail>{group?.institute.name}</GroupDetail>
            </CardInfo>
          </CardHeader>
          <TeamButtonWrapper>
            <Button
              testID={'buttonManageGroup'}
              text={t('profile.manageGroup')}
              size={'sm'}
              type={'secondary-base'}
              onPress={onSettings}
              minWidth={150}
            />
            <Button
              testID={'buttonShareLink'}
              text={t('profile.inviteNewMembers')}
              size={'sm'}
              minWidth={160}
              type={'primary-brand-02'}
              onPress={() => onShareLink(inviteUrl)}
            />
          </TeamButtonWrapper>
        </CardView>

        <VSpacer height={24} />
        <HelpCell
          onPress={onContact}
          unReadMessageCount={unReadMessageCount}
          testID="intercomMessengerButton"
        />
        {coreAdmin && pendingUserGroups.length > 0 && (
          <>
            <VSpacer height={32} />
            <NotificationGroupRequestCarousel
              noPaginationMargin
              userGroups={pendingUserGroups}
              loading={loadingMore}
              now={now}
              onDeclineUser={onDeclineUser}
              onAcceptUser={onAcceptUser}
            />
          </>
        )}
        <VSpacer height={24} />
        <SectionHeader title={t('profile.settings')} />
        <VSpacer height={16} />
        {settingCellData.map((cellData) => (
          <CommonIconCell key={cellData.info.text} {...cellData} />
        ))}
        <VSpacer height={20} />
        {bestMatchedAsset?.[randomIndex] !== undefined && (
          <TrackingFlatlist
            testID={'flatList'}
            handleViewTracking={handleViewTracking}
            data={[bestMatchedAsset[randomIndex]]}
            renderItem={renderItem}
            scrollEnabled={false}
            ListHeaderComponent={
              <MiniAdsTitle>{t('asset.gratitude')}</MiniAdsTitle>
            }
            ListFooterComponent={() => <></>}
          />
        )}
        <VSpacer height={20} />
        <SectionHeader title={t('profile.legal')} />
        <VSpacer height={16} />
        {legalCellData.map((cellData) => (
          <CommonIconCell key={cellData.info.text} {...cellData} />
        ))}
        <VSpacer height={48} />
        {needUpdate === false ? (
          <LatestVersionText>
            {t('g.youAreUsingLatestVersion')}
          </LatestVersionText>
        ) : null}
        <VersionText>{t('g.appVersion', { version })}</VersionText>
      </Content>
    </Container>
  );
}
