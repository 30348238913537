import { InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';

export default new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        institutes: relayStylePagination([
          'city',
          'name',
          'type',
          'internalId',
          'first',
          'offset',
        ]),
        posts: relayStylePagination(['team', 'createdBy_Group']),
        teams: relayStylePagination(['group', 'name', 'userGroupId']),
        groups: relayStylePagination(['name', 'institute']),
        polls: relayStylePagination(['post_CreatedBy_Group']),
        quotesInstances: relayStylePagination(['moduleInstance', 'orderBy']),
        reportsInstances: relayStylePagination(['moduleInstance']),
        rankingsQuestions: relayStylePagination(['moduleInstance']),
        collagesAlbums: relayStylePagination(['moduleInstance']),
        userGroups: relayStylePagination(['group', 'user', 'status']),
        customListOptions: relayStylePagination(['customList']),
        profilePagePhotoCategories: relayStylePagination(['moduleInstance']),
        profilePageQuestions: relayStylePagination(['moduleInstance']),
        profilePageComments: relayStylePagination([
          'moduleInstance',
          'profileUserGroup',
          'comentatorUserGroup',
        ]),
        leaderboardUsers: relayStylePagination(['user']),
        collagesPhotos: relayStylePagination(['collagesAlbum']),
        dataExports: relayStylePagination(['group']),
        group(_, { args, toReference }) {
          return toReference({
            __typename: 'GroupNode',
            id: args?.id,
          });
        },
        userGroup(_, { args, toReference }) {
          return toReference({
            __typename: 'UserGroupNode',
            id: args?.id,
          });
        },
        userTeam(_, { args, toReference }) {
          return toReference({
            __typename: 'UserTeamNode',
            id: args?.id,
          });
        },
        team(_, { args, toReference }) {
          return toReference({
            __typename: 'TeamNode',
            id: args?.id,
          });
        },
        post(_, { args, toReference }) {
          return toReference({
            __typename: 'PostNode',
            id: args?.id,
          });
        },
        likePost(_, { args, toReference }) {
          return toReference({
            __typename: 'PostNode',
            id: args?.id,
          });
        },
        commentPost(_, { args, toReference }) {
          return toReference({
            __typename: 'PostNode',
            id: args?.id,
          });
        },
        collagesAlbum(_, { args, toReference }) {
          return toReference({
            __typename: 'CollagesAlbumNode',
            id: args?.id,
          });
        },
        reportsInstance(_, { args, toReference }) {
          return toReference({
            __typename: 'ReportsInstanceNode',
            id: args?.id,
          });
        },
        rankingsQuestion(_, { args, toReference }) {
          return toReference({
            __typename: 'RankingsQuestionNode',
            id: args?.id,
          });
        },
        blogCollection: {
          keyArgs: ['where'],
          merge(existing, incoming) {
            const currentItems = existing?.items || [];
            const newList = {
              __typename: 'BlogCollection',
              total: incoming.total || 0,
              ...existing,
              items: [...currentItems, ...incoming.items],
            };
            return newList;
          },
        },
      },
    },
    GroupNode: {
      fields: {
        users: {
          merge: false,
        },
        members: {
          merge: false,
        },
      },
    },
    TeamNode: {
      fields: {
        users: {
          merge: false,
        },
        sampleMembers: {
          merge: false,
        },
      },
    },
    PollOptionNode: {
      fields: {
        votes: {
          merge: false,
        },
      },
    },
    CommentNode: {
      fields: {
        likes: {
          merge: false,
        },
      },
    },
    CollagesAlbumNode: {
      fields: {
        collagesPhotos: {
          merge: false,
        },
      },
    },
    PostNode: {
      fields: {
        comments: {
          merge: false,
        },
      },
    },
  },
});
