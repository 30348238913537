import React from 'react';
import Badge from '~/components/Badge';
import { IconName } from '~/components/Icon';
import { mapUser } from '~/data/mappers';
import { UserGroup } from '~/data/models/group';
import { TeamUser } from '~/data/models/team';
import { t } from '~/utils/i18n';
import {
  Container,
  StyledAvatar,
  InfoContent,
  Name,
  InfoText,
  RemoveContent,
  RemoveText,
  CustomButtonIcon,
  CustomIconType,
} from './style';

type CustomIcon = 'chat' | 'add' | 'remove' | 'check';

type CustomInfo = {
  id: string;
  text: string;
};

type MemberCellProps = {
  testID?: string;
  authUserId?: string;
  userGroup?: UserGroup;
  teamUser?: TeamUser;
  customInfo?: CustomInfo;
  customIcon?: CustomIcon;
  customIconType?: CustomIconType;
  teamColor?: string;
  checked?: boolean;
  admin?: boolean;
  infoText?: string;
  removeText?: string;
  leaveText?: string;
  onRemovePress?: () => void;
  onCustomIconPress?: () => void;
  onCheckPress?: () => void;
  onSeeProfile?: () => void;
};

const customIconMap: { [key in CustomIcon]: IconName } = {
  chat: 'message-chat-circle',
  add: 'plus',
  remove: 'x-close',
  check: 'check',
};

export default function MemberCell({
  testID,
  authUserId,
  userGroup,
  teamUser,
  customInfo,
  customIcon,
  customIconType,
  teamColor,
  checked = false,
  admin,
  infoText,
  removeText = t('g.remove'),
  leaveText = t('g.leaveTeam'),
  onRemovePress,
  onCustomIconPress,
  onCheckPress,
  onSeeProfile,
}: MemberCellProps): JSX.Element {
  const customIconName = customIcon ? customIconMap[customIcon] : null;
  const user = userGroup?.user || teamUser?.user;
  const mappedUser = mapUser(user);
  return (
    <Container testID={testID}>
      {userGroup || teamUser ? (
        <StyledAvatar size={48} userGroup={userGroup} teamUser={teamUser} />
      ) : null}
      <InfoContent onPress={onSeeProfile}>
        <Name>{mappedUser?.name || customInfo?.text || ''}</Name>
        {infoText && <InfoText>{infoText}</InfoText>}
      </InfoContent>
      {admin && <Badge text={t('admin')} />}
      {(() => {
        if (onRemovePress) {
          return (
            <RemoveContent onPress={() => onRemovePress && onRemovePress()}>
              <RemoveText>
                {authUserId === user?.id ? leaveText : removeText}
              </RemoveText>
            </RemoveContent>
          );
        }
        if (customIconName) {
          return (
            <CustomButtonIcon
              name={customIconName}
              iconColor={teamColor}
              customIconType={customIconType}
              onPress={() => onCustomIconPress && onCustomIconPress()}
            />
          );
        }
        return null;
      })()}
      {checked && (
        <CustomButtonIcon
          name={'check-circle'}
          customIconType={'primary'}
          onPress={() => onCheckPress && onCheckPress()}
        />
      )}
    </Container>
  );
}
