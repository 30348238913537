import React from 'react';
import { t } from '~/utils/i18n';
import { LikeButtonTouchable, LikeButtonText, LikeIcon } from './style';

type LikeButtonProps = {
  id: string;
  liked: boolean;
  onLike: (id: string, liked: boolean) => void;
};
export default function LikeButton({
  id,
  liked,
  onLike,
}: LikeButtonProps): JSX.Element {
  return (
    <LikeButtonTouchable
      liked={liked}
      onPress={() => {
        if (onLike) {
          onLike(id, liked);
        }
      }}
    >
      <LikeIcon testID={'heartIcon'} name={liked ? 'heart-red' : 'heart'} />
      <LikeButtonText liked={liked}>
        {liked ? t('g.liked') : t('g.like')}
      </LikeButtonText>
    </LikeButtonTouchable>
  );
}
