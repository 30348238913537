import { DefaultTheme } from 'styled-components/native';
import { ToggleState } from './types';

interface ButtonStateHelper {
  innactiveTrackColor: (state: ToggleState, theme: DefaultTheme) => string;
  activeTrackColor: (state: ToggleState, theme: DefaultTheme) => string;
  innactiveThumbColor: (theme: DefaultTheme) => string;
  activeThumbColor: (theme: DefaultTheme) => string;
  focusBorderColor: (theme: DefaultTheme) => string;
}

export const byStateHelper: ButtonStateHelper = {
  innactiveTrackColor: (state: ToggleState, theme: DefaultTheme): string => {
    return state === 'hover' ? theme.color.base.c4 : theme.color.base.c3;
  },
  activeTrackColor: (state: ToggleState, theme: DefaultTheme): string => {
    return state === 'hover' ? theme.color.brand_02 : theme.color.brand_02;
  },
  innactiveThumbColor: (theme: DefaultTheme): string => {
    return theme.color.base.c0;
  },
  activeThumbColor: (theme: DefaultTheme): string => {
    return theme.color.base.c0;
  },
  focusBorderColor: (theme: DefaultTheme): string => {
    return theme.color.brand_02;
  },
};
