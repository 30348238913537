import { EditInstituteGroupFields } from '~/data/models/admin';

interface IGroupsDatabaseHelpers {
  filterGroupsByYear(
    year: string,
    groups: EditInstituteGroupFields[],
  ): EditInstituteGroupFields[];
}

export default <IGroupsDatabaseHelpers>{
  filterGroupsByYear(year: string, groups: EditInstituteGroupFields[]) {
    return groups.filter((group) =>
      String(group.year).toLocaleLowerCase().includes(year.toLocaleLowerCase()),
    );
  },
};
