import React from 'react';
import { Container, NumberText, Text } from './style';

type NumberItem = {
  number: number;
  text: string;
};

export type NumberCardProps = {
  numberItem: NumberItem;
  testID?: string;
};

export default function NumberCard({
  numberItem,
  testID,
}: NumberCardProps): JSX.Element {
  const { number, text } = numberItem;
  const formattedNumber = number
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return (
    <Container testID={testID}>
      <NumberText smallText={number > 1000}>{formattedNumber}</NumberText>
      <Text>{text}</Text>
    </Container>
  );
}
