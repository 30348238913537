import React from 'react';
import {
  CompositeNavigationProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import useAppLocale from '~/data/hooks/useAppLocale';
import { AuthStackParamList, RootStackParamList } from '~/navigation/types';
import { getServiceParamsObj } from '~/utils/helpers';
import LaunchLayout from './layout';

type LaunchNavProp = CompositeNavigationProp<
  StackNavigationProp<AuthStackParamList, 'Launch'>,
  StackNavigationProp<RootStackParamList>
>;
type LaunchRouteProp = RouteProp<AuthStackParamList, 'Launch'>;

export default function Launch(): JSX.Element {
  const navigation = useNavigation<LaunchNavProp>();
  const route = useRoute<LaunchRouteProp>();

  const serviceParams = getServiceParamsObj(route.params);

  const { locale, onChangeLocale } = useAppLocale({
    onReload: () => navigation.replace('Launch', {}),
  });

  return (
    <LaunchLayout
      onSignUp={() => navigation.navigate('SignUpName', { ...serviceParams })}
      onLogIn={() =>
        navigation.navigate('Login', {
          ...serviceParams,
        })
      }
      selectedLocale={locale}
      onSelectedLocale={onChangeLocale}
    />
  );
}
