/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation DestroyProfilePageComment
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  DestroyProfilePageComment,
  DestroyProfilePageCommentVariables,
} from './types/DestroyProfilePageComment';
import { DESTROY_PROFILE_PAGE_COMMENT } from './';

export function useDestroyProfilePageCommentMutation(
  options?: MutationHookOptions<
    DestroyProfilePageComment,
    DestroyProfilePageCommentVariables
  >,
): MutationTuple<
  DestroyProfilePageComment,
  DestroyProfilePageCommentVariables
> {
  return useMutation<
    DestroyProfilePageComment,
    DestroyProfilePageCommentVariables
  >(DESTROY_PROFILE_PAGE_COMMENT, options);
}

export function createDestroyProfilePageCommentMock({
  variables,
  data,
  error,
}: {
  variables?: DestroyProfilePageCommentVariables;
  data?: DestroyProfilePageComment;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: DESTROY_PROFILE_PAGE_COMMENT,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
