import React, { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useAuth } from '~/context/auth';
import { NO_PAG_LIST_OFFSET } from '~/data/constants';
import useTeams from '~/data/hooks/useTeams';
import useUserGroups from '~/data/hooks/useUserGroups';
import { Color, Icon } from '~/data/models/custom';
import { UserGroup } from '~/data/models/group';
import { useColorsQuery } from '~/data/operations/custom/colors';
import { useIconsQuery } from '~/data/operations/custom/icons';
import { PlanningStackParamList } from '~/navigation/types';
import CreateTeamLayout from './layout';

type CreateTeamNavProp = StackNavigationProp<
  PlanningStackParamList,
  'CreateTeam'
>;

export default function CreateTeam(): JSX.Element {
  const navigation = useNavigation<CreateTeamNavProp>();

  const { authUserId } = useAuth();

  const { onCreateTeam, createTeamLoading } = useTeams();

  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);

  const { data: colorsData, loading: colorsLoading } = useColorsQuery({
    variables: {
      first: NO_PAG_LIST_OFFSET,
    },
  });
  const { data: iconsData, loading: iconsLoading } = useIconsQuery({
    variables: {
      first: NO_PAG_LIST_OFFSET,
    },
  });

  const colors: Color[] =
    (colorsData?.colors?.edges.map((edge) => edge?.node) as Color[]) || [];

  const icons: Icon[] =
    (iconsData?.icons?.edges.map((edge) => edge?.node) as Icon[]) || [];

  const { userGroups } = useUserGroups();

  const selectedUserGroups: UserGroup[] = userGroups.filter((userGroup) =>
    selectedUserIds.includes(userGroup.user.id),
  );

  useEffect(() => {
    if (authUserId && !selectedUserIds.includes(authUserId)) {
      setSelectedUserIds([authUserId]);
    }
  }, [authUserId]);

  const onAddedUser = (userId: string) => {
    setSelectedUserIds((selectedUserIds) => [...selectedUserIds, userId]);
  };

  const onAddMember = () => {
    navigation.navigate('AddTeamMember', {
      localIncludedUserIds: selectedUserIds,
      onAddUser: (userId) => onAddedUser(userId),
    });
  };

  return (
    <CreateTeamLayout
      authUserId={authUserId}
      colors={colors}
      icons={icons}
      userGroups={selectedUserGroups}
      loading={colorsLoading || iconsLoading}
      createTeamLoading={createTeamLoading}
      onBack={() => navigation.goBack()}
      onCreateTeam={({ name, colorId, iconId }) =>
        onCreateTeam(
          {
            name,
            colorId,
            iconId,
            selectedUserIds,
          },
          () => navigation.goBack(),
        )
      }
      onAddMember={onAddMember}
      onRemoveMember={(userId) =>
        setSelectedUserIds(selectedUserIds.filter((uId) => uId != userId))
      }
    />
  );
}
