import React, { useEffect } from 'react';
import SearchInputField from '~/components/SearchInputField/index';
import { Spinner } from '~/components/common/style.web';
import { Campaign, Column } from '~/data/models/campaign';
import { formattedDate } from '~/utils/dates';
import usePagination from '~/utils/hooks/usePagination';
import { t } from '~/utils/i18n';
import { CampaignStatusBadgeColor } from '~/utils/types/adminAds';
import Badge from '../Badge';
import Button from '../Button';
import Paginator from '../Paginator';
import StepSizePicker from '../StepSizePicker';
import {
  Container,
  TableCell,
  TableHeader,
  TableRow,
  EmptyCell,
  TableHeaderText,
  SortIcon,
  AdsName,
  AdsId,
  CellText,
  AdsTable,
  HeaderSection,
  AdsIconContainer,
  Title,
  CampaingWrapper,
  PaginationWrapper,
  AdsIcon,
  ButtonWrapper,
  CellBadge,
  TableStatus,
  TableCellClickable,
} from './style';

type AdsDatabaseProps = {
  data: Campaign[];
  onOpenCampaign: (id: string, campaignName: string) => void;
  handleOnCreateCampaign: () => void;
  totalCampaigns: number;
  setCurrentActiveTab: (tab: number) => void;
  filterQuery: string;
  rowsPerPage: number;
  sortedBy: string;
  setRowsPerPage: (value: number) => void;
  setFilterQuery: (value: string) => void;
  toggleSort: (value: string) => void;
  loading?: boolean;
  columns: Column[];
};

const DATABASE_ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 50];

export default function AdsDatabase({
  data,
  onOpenCampaign,
  handleOnCreateCampaign,
  setCurrentActiveTab,
  filterQuery,
  rowsPerPage,
  sortedBy,
  setRowsPerPage,
  setFilterQuery,
  totalCampaigns,
  toggleSort,
  loading,
  columns,
}: AdsDatabaseProps): JSX.Element {
  const {
    totalNumberOfPages,
    currentPage,
    allowNextPage,
    allowPrevPage,
    incrementPage,
    decrementPage,
    resetPagination,
    updatePage,
  } = usePagination({
    itemsPerPage: rowsPerPage,
    initialPage: 1,
    totalItems: totalCampaigns,
  });

  useEffect(() => {
    setCurrentActiveTab(currentPage);
  }, [currentPage]);

  useEffect(() => {
    resetPagination();
  }, [filterQuery, rowsPerPage, sortedBy]);

  const renderTableHeader = () => (
    <TableRow noBorder>
      <EmptyCell />
      {columns.map(({ key, label }, index) => (
        <TableCellClickable key={key} onPress={() => toggleSort(key)}>
          {index === columns.length - 1 ? (
            <TableStatus>
              <TableHeaderText>{t(label)}</TableHeaderText>
              <SortIcon />
            </TableStatus>
          ) : (
            <TableHeader>
              <TableHeaderText>{t(label)}</TableHeaderText>
              <SortIcon />
            </TableHeader>
          )}
        </TableCellClickable>
      ))}
    </TableRow>
  );

  const renderTableRow = ({ item: campaign }: { item: Campaign }) => (
    <TableRow
      onPress={() => onOpenCampaign(campaign.id, campaign.name)}
      testID={`campaign_row-${campaign.id}`}
    >
      <AdsIconContainer>
        <AdsIcon />
      </AdsIconContainer>
      <TableCell>
        <AdsName>{campaign.name}</AdsName>
      </TableCell>
      <TableCell>
        <AdsId>{campaign.utm}</AdsId>
      </TableCell>
      <TableCell>
        <CellText>{campaign.customer}</CellText>
      </TableCell>
      <TableCell>
        <CellText>{formattedDate(campaign.startDate)}</CellText>
      </TableCell>
      <TableCell>
        <CellBadge>
          <Badge
            text={campaign.status as string}
            colorTheme={CampaignStatusBadgeColor[campaign.status as string]}
          />
        </CellBadge>
      </TableCell>
    </TableRow>
  );

  const renderTableFooter = () => (
    <PaginationWrapper>
      <StepSizePicker
        stepSize={rowsPerPage}
        onStepSizeChange={setRowsPerPage}
        options={DATABASE_ROWS_PER_PAGE_OPTIONS}
        title={t('adminPanelSearch.entries')}
      />
      <Paginator
        allowNext={allowNextPage}
        allowPrev={allowPrevPage}
        currentPage={currentPage}
        onPrevClick={decrementPage}
        onNextClick={incrementPage}
        numberOfPages={totalNumberOfPages}
        onPageClick={updatePage}
      />
    </PaginationWrapper>
  );

  return (
    <Container>
      <HeaderSection>
        <Title>{t('adminPanelAds.campaignTitle')}</Title>
        <CampaingWrapper>
          <SearchInputField
            iconName={'search-md'}
            label={t('adminPanelAds.search')}
            value={filterQuery}
            onChangeText={(query) => setFilterQuery(query)}
            width={200}
            height={34}
          />
          <ButtonWrapper>
            <Button
              testID={'buttonAddAds'}
              text={t('adminPanelAds.createANewCampaign')}
              size={'sm'}
              type={'secondary-base'}
              icon={'left'}
              iconName={'plus'}
              flex={true}
              onPress={handleOnCreateCampaign}
            />
          </ButtonWrapper>
        </CampaingWrapper>
      </HeaderSection>
      {loading ? (
        <Spinner testID="spinner" />
      ) : (
        <AdsTable<React.ElementType>
          data={data}
          renderItem={renderTableRow}
          ListHeaderComponent={renderTableHeader}
          ListFooterComponent={renderTableFooter}
        />
      )}
    </Container>
  );
}
