import { gql } from '@apollo/client';
import { USER_GROUP_FIELDS } from '../group';
import { TEAM_FIELDS } from '../team';

export const COMMENT_FIELDS = gql`
  ${USER_GROUP_FIELDS}
  ${TEAM_FIELDS}
  fragment CommentFields on CommentNode {
    id
    text
    owner
    userGroup {
      ...UserGroupFields
    }
    team {
      ...TeamFields
    }
    likes(userGroup: $authUserGroupId) {
      edges {
        node {
          userGroup {
            id
          }
        }
      }
    }
    likesCount
    created
  }
`;

export const BASE_POST_FIELDS = gql`
  ${USER_GROUP_FIELDS}
  ${TEAM_FIELDS}
  ${COMMENT_FIELDS}
  fragment BasePostFields on PostNode {
    id
    owner
    text
    createdBy {
      ...UserGroupFields
    }
    images
    likesCount
    team {
      ...TeamFields
    }
    comments {
      ...CommentFields
    }
    created
  }
`;

export const POLL_VOTE_FIELDS = gql`
  fragment PollVoteFields on PollVoteNode {
    id
    userGroup {
      user {
        id
      }
    }
  }
`;

export const POLL_OPTION_FIELDS = gql`
  ${POLL_VOTE_FIELDS}
  fragment PollOptionFields on PollOptionNode {
    id
    text
    image
    votes {
      ...PollVoteFields
    }
    percentage
    votesNumber
  }
`;

export const BASE_POLL_FIELDS = gql`
  ${POLL_OPTION_FIELDS}
  fragment BasePollFields on PollNode {
    id
    question
    deadline
    answersAllowed
    randomizeOptions
    allowRevote
    allowComments
    anonymousVotes
    privateResults
    options {
      ...PollOptionFields
    }
    created
  }
`;

export const POST_FIELDS = gql`
  ${BASE_POST_FIELDS}
  ${BASE_POLL_FIELDS}
  fragment PostFields on PostNode {
    ...BasePostFields
    poll {
      ...BasePollFields
    }
    likes(userGroup: $authUserGroupId) {
      edges {
        node {
          userGroup {
            id
          }
        }
      }
    }
  }
`;

export const POLL_FIELDS = gql`
  ${BASE_POLL_FIELDS}
  ${BASE_POST_FIELDS}
  fragment PollFields on PollNode {
    ...BasePollFields
    post {
      ...BasePostFields
      likes(userGroup: $authUserGroupId) {
        edges {
          node {
            userGroup {
              id
            }
          }
        }
      }
    }
  }
`;

export const POSTS = gql`
  ${POST_FIELDS}
  query Posts(
    $after: String
    $first: Int!
    $owner: Owner
    $createdBy: ID
    $team: ID
    $text: String
    $createdBy_Group: ID
    $authUserGroupId: ID!
  ) {
    posts(
      after: $after
      first: $first
      owner: $owner
      createdBy: $createdBy
      team: $team
      text: $text
      createdBy_Group: $createdBy_Group
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...PostFields
        }
      }
    }
  }
`;

export const POST = gql`
  ${POST_FIELDS}
  query Post($id: ID!, $authUserGroupId: ID!) {
    post(id: $id) {
      ...PostFields
    }
  }
`;

export const CREATE_POST = gql`
  ${POST_FIELDS}
  mutation CreatePost($input: CreatePostInput!, $authUserGroupId: ID!) {
    createPost(input: $input) {
      post {
        ...PostFields
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const DELETE_POST = gql`
  ${POST_FIELDS}
  mutation DeletePost($input: DeletePostInput!, $authUserGroupId: ID!) {
    deletePost(input: $input) {
      post {
        ...PostFields
      }
    }
  }
`;

export const REPORT_POST_CONTENT = gql`
  mutation ReportPostContent($input: ReportPostContentInput!) {
    reportPostContent(input: $input) {
      success
      message
    }
  }
`;

export const BLOCK_USER = gql`
  mutation BlockUser($input: BlockUserMutationInput!) {
    blockUser(input: $input) {
      success
      message
    }
  }
`;

export const REPORT_MUTATION = gql`
  mutation ReportMutation($input: ReportMutationInput!) {
    reportMutation(input: $input) {
      success
      message
    }
  }
`;

export const ADD_IMAGES_TO_POST = gql`
  ${POST_FIELDS}
  mutation AddImagesToPost(
    $input: AddImagesToPostInput!
    $authUserGroupId: ID!
  ) {
    addImagesToPost(input: $input) {
      post {
        ...PostFields
      }
    }
  }
`;

export const POLLS = gql`
  ${POLL_FIELDS}
  query Polls(
    $after: String
    $first: Int!
    $post_Team: ID
    $post_CreatedBy_Group: ID
    $authUserGroupId: ID!
  ) {
    polls(
      after: $after
      first: $first
      post_Team: $post_Team
      post_CreatedBy_Group: $post_CreatedBy_Group
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...PollFields
        }
      }
    }
  }
`;

// export const POLL = gql`
//   ${POLL_FIELDS}
//   query Poll($id: ID!) {
//     poll(id: $id) {
//       ...PollFields
//     }
//   }
// `;

export const CREATE_POLL = gql`
  ${POLL_FIELDS}
  mutation CreatePoll($input: CreatePollInput!, $authUserGroupId: ID!) {
    createPoll(input: $input) {
      poll {
        ...PollFields
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const DELETE_POLL = gql`
  ${POLL_FIELDS}
  mutation DeletePoll($input: DeletePollInput!, $authUserGroupId: ID!) {
    deletePoll(input: $input) {
      poll {
        ...PollFields
      }
    }
  }
`;

export const CREATE_POLL_OPTION = gql`
  ${POLL_OPTION_FIELDS}
  mutation CreatePollOption($input: CreatePollOptionInput!) {
    createPollOption(input: $input) {
      pollOption {
        ...PollOptionFields
      }
    }
  }
`;

export const CREATE_POLL_VOTE = gql`
  ${POLL_VOTE_FIELDS}
  mutation CreatePollVote($input: CreatePollVoteInput!) {
    createPollVote(input: $input) {
      pollVote {
        ...PollVoteFields
      }
    }
  }
`;

export const UPDATE_POLL = gql`
  ${BASE_POLL_FIELDS}
  mutation UpdatePoll($input: UpdatePollInput!) {
    updatePoll(input: $input) {
      poll {
        ...BasePollFields
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const DELETE_POLL_VOTE = gql`
  ${POLL_VOTE_FIELDS}
  mutation DeletePollVote($input: DeletePollVoteInput!) {
    deletePollVote(input: $input) {
      pollVote {
        ...PollVoteFields
      }
    }
  }
`;

export const LIKE_POST = gql`
  ${POST_FIELDS}
  mutation LikePost($input: LikePostInput!, $authUserGroupId: ID!) {
    likePost(input: $input) {
      post {
        ...PostFields
      }
    }
  }
`;

export const REMOVE_LIKE_FROM_POST = gql`
  ${POST_FIELDS}
  mutation RemoveLikeFromPost(
    $input: RemoveLikeFromPostInput!
    $authUserGroupId: ID!
  ) {
    removeLikeFromPost(input: $input) {
      post {
        ...PostFields
      }
    }
  }
`;

export const COMMENT_POST = gql`
  ${POST_FIELDS}
  mutation CommentPost($input: CommentPostInput!, $authUserGroupId: ID!) {
    commentPost(input: $input) {
      post {
        ...PostFields
      }
    }
  }
`;

export const LIKE_COMMENT = gql`
  ${POST_FIELDS}
  mutation LikeComment($input: LikeCommentInput!, $authUserGroupId: ID!) {
    likeComment(input: $input) {
      post {
        ...PostFields
      }
    }
  }
`;

export const REMOVE_LIKE_FROM_COMMENT = gql`
  ${POST_FIELDS}
  mutation RemoveLikeFromComment(
    $input: RemoveLikeFromCommentInput!
    $authUserGroupId: ID!
  ) {
    removeLikeFromComment(input: $input) {
      post {
        ...PostFields
      }
    }
  }
`;

export const DELETE_COMMENT = gql`
  ${POST_FIELDS}
  mutation DeleteComment($input: DeleteCommentInput!, $authUserGroupId: ID!) {
    deleteComment(input: $input) {
      post {
        ...PostFields
      }
    }
  }
`;
