import { useEffect, useRef } from 'react';

type useEffectOnceProps = {
  effect: React.EffectCallback;
  deps?: React.DependencyList;
};

// useEffect that can be toggled on and off
const useEffectToggle = ({ effect, deps = [] }: useEffectOnceProps) => {
  const runEffect = useRef(true);

  useEffect(() => {
    if (!runEffect.current) {
      return;
    }

    effect();
  }, deps);

  return {
    stopEffect: () => (runEffect.current = false),
    startEffect: () => (runEffect.current = true),
    toggleEffect: () =>
      runEffect.current
        ? (runEffect.current = false)
        : (runEffect.current = true),
  };
};

export default useEffectToggle;
