import { View, Animated, Platform } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import styled from 'styled-components/native';
import IconComp from '~/components/Icon';

const SafeAreaContainerView = Platform.OS === 'web' ? View : SafeAreaView;

interface ISafeAreaPostion {
  position: number;
}

export const SafeAreaContainer = styled(
  SafeAreaContainerView,
)<ISafeAreaPostion>`
  position: absolute;
  top: ${({ theme, position }) =>
    (position ? position : 52) + theme.safeAreaInsets.top}px;
  left: 16px;
  right: 16px;
`;

const Container = styled.View`
  elevation: 6;
  background-color: ${({ theme }) => theme.color.base.c7};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  border-radius: 14px;
`;

export const AnimatedContainer = Animated.createAnimatedComponent(Container);

export const Icon = styled(IconComp).attrs(({ theme }) => ({
  size: 24,
  color: theme.color.base.c0,
}))`
  margin-right: 16px;
`;

export const Text = styled.Text`
  flex: 1;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c0};
  font-size: 16px;
`;

export const ActionButton = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})``;

export const ActionText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c3};
  font-size: 16px;
`;
