import React from 'react';
import { Platform } from 'react-native';
import { SvgProps } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

export type IconSetKey =
  | 'users'
  | 'alerts'
  | 'arrows'
  | 'communication'
  | 'security'
  | 'maps'
  | 'time'
  | 'images'
  | 'education'
  | 'editor';

export const iconSet = {
  users: ['user-03', 'user-circle', 'users-01', 'users-plus'],
  general: [
    'check',
    'check-circle',
    'search-md',
    'eye',
    'eye-off',
    'plus',
    'x-close',
    'dots-horizontal',
    'share-07',
    'edit-05',
    'check-done-02',
    'home-02',
    'trash-01',
    'upload-01',
    'pin-02',
    'hearts',
    'settings-01',
    'minus',
  ],
  alerts: ['alert-circle', 'thumbs-up', 'alert-triangle', 'notification-text'],
  arrows: [
    'chevron-selector-vertical',
    'chevron-right',
    'chevron-left',
    'chevron-up',
    'chevron-down',
    'refresh-ccw-01',
    'refresh-ccw-02',
    'arrow-left',
  ],
  communication: [
    'mail-01',
    'message-chat-circle',
    'message-dots-circle',
    'annotation-question',
  ],
  security: ['lock-02', 'lock-unlocked-02'],
  maps: ['marker-pin-01', 'marker-pin-06', 'flag-01', 'map-02'],
  time: ['clock', 'calendar-date'],
  images: ['camera-01', 'flash', 'image-03', 'lead-icon'],
  education: ['book-open-01', 'briefcase-02', 'graduation-hat-02'],
  charts: ['horizontal-bar-chart-02', 'presentation-chart-02'],
  files: ['file-search-02'],
  editor: ['magic-wand-01'],
  social: ['instagram', 'linkedin', 'discord', 'youtube', 'tiktok'],
  static: ['heart', 'heart-red'],
} as const;

export type IconName =
  | (typeof iconSet)['users'][number]
  | (typeof iconSet)['general'][number]
  | (typeof iconSet)['alerts'][number]
  | (typeof iconSet)['arrows'][number]
  | (typeof iconSet)['communication'][number]
  | (typeof iconSet)['security'][number]
  | (typeof iconSet)['maps'][number]
  | (typeof iconSet)['time'][number]
  | (typeof iconSet)['images'][number]
  | (typeof iconSet)['education'][number]
  | (typeof iconSet)['charts'][number]
  | (typeof iconSet)['files'][number]
  | (typeof iconSet)['editor'][number]
  | (typeof iconSet)['social'][number]
  | (typeof iconSet)['social'][number]
  | (typeof iconSet)['static'][number];

type SvgIconMap = { [key in IconName]: React.FC<SvgProps> };

const svgIconMap: SvgIconMap = {
  //users
  'user-03': require('~/assets/svg/untitled-icons/users/user-03.svg').default,
  'user-circle': require('~/assets/svg/untitled-icons/users/user-circle.svg')
    .default,
  'users-01': require('~/assets/svg/untitled-icons/users/users-01.svg').default,
  'users-plus': require('~/assets/svg/untitled-icons/users/users-plus.svg')
    .default,
  //general
  check: require('~/assets/svg/untitled-icons/general/check.svg').default,
  'check-circle':
    require('~/assets/svg/untitled-icons/general/check-circle.svg').default,
  'search-md': require('~/assets/svg/untitled-icons/general/search-md.svg')
    .default,
  eye: require('~/assets/svg/untitled-icons/general/eye.svg').default,
  'eye-off': require('~/assets/svg/untitled-icons/general/eye-off.svg').default,
  plus: require('~/assets/svg/untitled-icons/general/plus.svg').default,
  minus: require('~/assets/svg/untitled-icons/general/minus.svg').default,
  'x-close': require('~/assets/svg/untitled-icons/general/x-close.svg').default,
  'dots-horizontal':
    require('~/assets/svg/untitled-icons/general/dots-horizontal.svg').default,
  'share-07': require('~/assets/svg/untitled-icons/general/share-07.svg')
    .default,
  'edit-05': require('~/assets/svg/untitled-icons/general/edit-05.svg').default,
  'check-done-02':
    require('~/assets/svg/untitled-icons/general/check-done-02.svg').default,
  'home-02': require('~/assets/svg/untitled-icons/general/home-02.svg').default,
  'trash-01': require('~/assets/svg/untitled-icons/general/trash-01.svg')
    .default,
  'upload-01': require('~/assets/svg/untitled-icons/general/upload-01.svg')
    .default,
  'pin-02': require('~/assets/svg/untitled-icons/general/pin-02.svg').default,
  hearts: require('~/assets/svg/untitled-icons/general/hearts.svg').default,
  'settings-01': require('~/assets/svg/untitled-icons/general/settings-01.svg')
    .default,
  //alerts
  'alert-circle': require('~/assets/svg/untitled-icons/alerts/alert-circle.svg')
    .default,
  'thumbs-up': require('~/assets/svg/untitled-icons/alerts/thumbs-up.svg')
    .default,
  'alert-triangle':
    require('~/assets/svg/untitled-icons/alerts/alert-triangle.svg').default,
  'notification-text':
    require('~/assets/svg/untitled-icons/alerts/notification-text.svg').default,
  //arrows
  'chevron-selector-vertical':
    require('~/assets/svg/untitled-icons/arrows/chevron-selector-vertical.svg')
      .default,
  'chevron-right':
    require('~/assets/svg/untitled-icons/arrows/chevron-right.svg').default,
  'chevron-left': require('~/assets/svg/untitled-icons/arrows/chevron-left.svg')
    .default,
  'chevron-up': require('~/assets/svg/untitled-icons/arrows/chevron-up.svg')
    .default,
  'chevron-down': require('~/assets/svg/untitled-icons/arrows/chevron-down.svg')
    .default,
  'refresh-ccw-01':
    require('~/assets/svg/untitled-icons/arrows/refresh-ccw-01.svg').default,
  'refresh-ccw-02':
    require('~/assets/svg/untitled-icons/arrows/refresh-ccw-02.svg').default,
  'arrow-left': require('~/assets/svg/untitled-icons/arrows/arrow-left.svg')
    .default,
  //communication
  'mail-01': require('~/assets/svg/untitled-icons/communication/mail-01.svg')
    .default,
  'message-chat-circle':
    require('~/assets/svg/untitled-icons/communication/message-chat-circle.svg')
      .default,
  'message-dots-circle':
    require('~/assets/svg/untitled-icons/communication/message-dots-circle.svg')
      .default,
  //security
  'lock-02': require('~/assets/svg/untitled-icons/security/lock-02.svg')
    .default,
  'lock-unlocked-02':
    require('~/assets/svg/untitled-icons/security/lock-unlocked-02.svg')
      .default,
  'annotation-question':
    require('~/assets/svg/untitled-icons/communication/annotation-question.svg')
      .default,
  //maps
  'marker-pin-01': require('~/assets/svg/untitled-icons/maps/marker-pin-01.svg')
    .default,
  'marker-pin-06': require('~/assets/svg/untitled-icons/maps/marker-pin-06.svg')
    .default,
  'flag-01': require('~/assets/svg/untitled-icons/maps/flag-01.svg').default,
  'map-02': require('~/assets/svg/untitled-icons/maps/map-02.svg').default,
  //time
  clock: require('~/assets/svg/untitled-icons/time/clock.svg').default,
  'calendar-date': require('~/assets/svg/untitled-icons/time/calendar-date.svg')
    .default,
  //images
  'camera-01': require('~/assets/svg/untitled-icons/images/camera-01.svg')
    .default,
  'image-03': require('~/assets/svg/untitled-icons/images/image-03.svg')
    .default,

  flash: require('~/assets/svg/untitled-icons/images/flash.svg').default,
  'lead-icon': require('~/assets/svg/untitled-icons/images/lead-icon.svg')
    .default,
  //education
  'book-open-01':
    require('~/assets/svg/untitled-icons/education/book-open-01.svg').default,
  'briefcase-02':
    require('~/assets/svg/untitled-icons/education/briefcase-02.svg').default,
  'graduation-hat-02':
    require('~/assets/svg/untitled-icons/education/graduation-hat-02.svg')
      .default,
  //charts
  'horizontal-bar-chart-02':
    require('~/assets/svg/untitled-icons/charts/horizontal-bar-chart-02.svg')
      .default,
  'presentation-chart-02':
    require('~/assets/svg/untitled-icons/charts/presentation-chart-02.svg')
      .default,
  //editor
  'magic-wand-01':
    require('~/assets/svg/untitled-icons/editor/magic-wand-01.svg').default,
  //social
  instagram: require('~/assets/svg/social/instagram.svg').default,
  linkedin: require('~/assets/svg/social/linkedin.svg').default,
  discord: require('~/assets/svg/social/discord.svg').default,
  youtube: require('~/assets/svg/social/youtube.svg').default,
  tiktok: require('~/assets/svg/social/tiktok.svg').default,
  // files
  'file-search-02':
    require('~/assets/svg/untitled-icons/files/file-search-02.svg').default,

  // static
  heart: require('~/assets/svg/static/heart.svg').default,
  'heart-red': require('~/assets/svg/static/heart-red.svg').default,
};

//static icons
const staticIcons: IconName[] = [...iconSet['social'], ...iconSet['static']];

//some icons from untitled set are defined as stroke prop, but affected by fill prop (both set the same color)
const strokeIcons: IconName[] = [
  'user-03',
  'user-circle',
  'users-01',
  'alert-circle',
  'check',
  'check-circle',
  'camera-01',
  'check-done-02',
  'flash',
  'flag-01',
  'thumbs-up',
  'alert-triangle',
  'notification-text',
  'image-03',
  'settings-01',
];
export interface IconProps {
  testID?: string;
  name: IconName;
  size?: number;
  color?: string;
  strokeFill?: string;
}

export default function Icon({
  testID,
  name,
  color,
  size = 24,
  ...style
}: IconProps): JSX.Element {
  const SvgIcon = svgIconMap[name];
  const theme = useTheme();
  const styleProps = Platform.OS === 'web' ? {} : style;
  const isStroke = strokeIcons.includes(name);
  const isStatic = staticIcons.includes(name);

  const _strokeFill = '#00000000';
  const _color = color || theme.color.base.c9;
  const fill = !isStroke ? _color : _strokeFill;
  const stroke = isStroke ? _color : undefined;

  if (isStatic) {
    return (
      <SvgIcon {...styleProps} testID={testID} width={size} height={size} />
    );
  }

  return (
    <SvgIcon
      {...styleProps}
      testID={testID}
      width={size}
      height={size}
      color={_color}
      fill={fill}
      stroke={stroke}
    />
  );
}
