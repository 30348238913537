import styled from 'styled-components/native';
import ButtonIcon from '~/components/ButtonIcon';
import ImageComp from '~/components/Image';
import { GroupRequestCellType } from './types';

interface IType {
  type?: GroupRequestCellType;
}

export const Container = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})<IType>`
  background-color: ${({ theme }) => theme.color.base.c0};
  padding-vertical: 24px;
  padding-horizontal: 16px;
  flex-direction: row;
  margin-bottom: 8px;
  border-radius: 5px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.color.base.c3};
  box-shadow: 2px 3px 2px rgba(248, 249, 250, 0.8);
`;

export const Image = styled(ImageComp)`
  width: 56px;
  height: 56px;
  border-radius: 5px;
  border-width: 2px;
  border-color: ${({ theme }) => theme.color.base.c2};
`;

export const Content = styled.View`
  flex: 1;
  padding-left: 16px;
`;

export const Name = styled.Text<IType>`
  color: ${({ theme }) => theme.color.base.c10};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
  line-height: 20px;
`;

export const Detail = styled.Text<IType>`
  color: ${({ theme }) => theme.color.base.c8};
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 16px;
`;

export const RemoveView = styled.View`
  position: absolute;
  top: 0px;
  right: 0px;
`;

export const RemoveButton = styled(ButtonIcon).attrs<IType>(
  ({ theme, type }) => ({
    name: 'x-close',
    size: 24,
    color: type === 'light' ? theme.color.base.c0 : theme.color.base.c9,
  }),
)``;
