import React, { useEffect } from 'react';
import {
  CompositeNavigationProp,
  useNavigation,
  useRoute,
  RouteProp,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Linking, Platform } from 'react-native';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { useIntercom } from '~/context/intercom';
import { LINKS, NO_PAG_LIST_OFFSET } from '~/data/constants';
import useAppLocale from '~/data/hooks/useAppLocale';
import { Group, GroupUser } from '~/data/models/group';
import { GroupsUserGroupStatusChoices } from '~/data/operations/global';
import { GROUP_USERS } from '~/data/operations/group';
import {
  useGroupUsersLazyQuery,
  useGroupUsersQuery,
} from '~/data/operations/group/groupUsers';
import { useRemoveUserFromGroupMutation } from '~/data/operations/group/removeUserFromGroup';
import { useUserGroupQuery } from '~/data/operations/group/userGroup';
import { getRefreshToken, getToken } from '~/data/storage';
import { LobbyStackParamList, RootStackParamList } from '~/navigation/types';
import {
  redirectToCC,
  redirectToLayoutCreatorWithToken,
} from '~/utils/helpers';
import { t } from '~/utils/i18n';
import LobbyLayout from './layout';

type LobbyNavProp = CompositeNavigationProp<
  StackNavigationProp<LobbyStackParamList, 'Lobby'>,
  StackNavigationProp<RootStackParamList>
>;

type LobbyRouteProp = RouteProp<LobbyStackParamList, 'Lobby'>;

export default function Lobby(): JSX.Element {
  const navigation = useNavigation<LobbyNavProp>();
  const { onMessenger } = useIntercom();

  const route = useRoute<LobbyRouteProp>();
  const hasCcParam = route.params?.cc;
  const hasLayoutCreatorParam = route.params?.layoutcreator;

  const { locale, onChangeLocale } = useAppLocale({
    onReload: () => navigation.replace('Lobby', {}),
  });

  const { authUserId, authUser, loading, onAuthGroup, onLogout } = useAuth();
  const [loadGroupUsers] = useGroupUsersLazyQuery();

  const firstName = authUser?.firstName || '';

  const {
    loading: loadingGroupUsers,
    error: groupUsersError,
    data: groupUsersData,
    refetch: refetchGroupUsers,
  } = useGroupUsersQuery({
    skip: !authUserId,
    variables: {
      user: authUserId,
      first: NO_PAG_LIST_OFFSET,
    },
  });

  const [removeUserFromGroup] = useRemoveUserFromGroupMutation();

  const groupUsers: GroupUser[] =
    (groupUsersData?.userGroups?.edges.map(
      (edge) => edge?.node,
    ) as GroupUser[]) || [];

  const groupUsersAccepted: GroupUser[] = groupUsers.filter(
    (userGroup) => userGroup.status === 'ACCEPTED',
  );
  const groupUsersNotAccepted: GroupUser[] = groupUsers.filter(
    (userGroup) =>
      userGroup.status === 'PENDING' || userGroup.status === 'REJECTED',
  );

  const userGroupPending = groupUsersNotAccepted.find(
    (userGroup) => userGroup.status === 'PENDING',
  );

  useUserGroupQuery({
    skip: !userGroupPending,
    variables: {
      id: userGroupPending?.id as string,
    },
    pollInterval: 5000,
  });

  const onSelectedGroup = async (
    authGroupId: string,
    authUserGroupId: string,
  ) => {
    onAuthGroup(authGroupId, authUserGroupId);
  };

  async function onRemoveRequest(groupId: string) {
    try {
      await removeUserFromGroup({
        variables: {
          input: { groupId, userId: authUserId },
        },
        refetchQueries: [
          {
            query: GROUP_USERS,
            variables: {
              user: authUserId,
              first: NO_PAG_LIST_OFFSET,
            },
          },
        ],
      });
      Snackbar.show(t('lobby.requestCancelled'));
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  }

  useEffect(() => {
    (async () => {
      try {
        if (Platform.OS === 'web' && authUser) {
          if (hasLayoutCreatorParam) {
            const token = await getToken();
            const refreshToken = await getRefreshToken();

            token &&
              refreshToken &&
              redirectToLayoutCreatorWithToken({ token, refreshToken });
            return;
          }

          if (hasCcParam) {
            const { data: groupUsersData } = await loadGroupUsers({
              variables: {
                user: authUserId,
                first: NO_PAG_LIST_OFFSET,
                status: GroupsUserGroupStatusChoices.ACCEPTED,
              },
            });
            const groups =
              (groupUsersData?.userGroups?.edges.map(
                (edge) => edge?.node?.group,
              ) as Group[]) || null;

            if (authUser && groups) {
              redirectToCC(authUser, groups);
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          Snackbar.show(e.message);
        }
      }
    })();
  }, [authUser]);

  const openAppStore = (os: 'ios' | 'android') => {
    const url = os === 'ios' ? LINKS.IOS_APPSTORE : LINKS.ANDROID_PLAYSTORE;

    Linking.canOpenURL(url)
      .then((supported) => {
        if (supported) {
          Linking.openURL(url);
        }
      })
      .catch((err) => Snackbar.show(err));
  };

  const onSelectedWebGroup = async (groupId: string) => {
    navigation.navigate('InviteSuccess', { groupId });
  };
  return (
    <LobbyLayout
      selectedLocale={locale}
      loading={loading || loadingGroupUsers}
      errorMessage={(groupUsersError && groupUsersError.message) || undefined}
      userName={firstName}
      groupUsersAccepted={groupUsersAccepted}
      groupUsersPending={groupUsersNotAccepted}
      onLogout={() => onLogout()}
      onJoinGroup={() =>
        navigation.navigate('GroupStack', {
          screen: 'JoinSelectLocation',
        })
      }
      onMessenger={onMessenger}
      onSelectedGroup={(groupId, userGroupId) =>
        onSelectedGroup(groupId, userGroupId)
      }
      onRemoveRequest={(groupId) => onRemoveRequest(groupId)}
      onRetry={() => {
        refetchGroupUsers();
      }}
      onSelectedLocale={onChangeLocale}
      onRefresh={() => {
        refetchGroupUsers();
      }}
      openAppStore={openAppStore}
      onSelectedWebGroup={onSelectedWebGroup}
    />
  );
}

/**
 * TODO:
 * - Setup no group lottie
 * - Handle multiple groups
 * - Handle request overview
 */
