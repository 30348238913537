import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
} from 'react';
import { Animated } from 'react-native';
import Shimmer from '~/components/Shimmer';
import { mapUser } from '~/data/mappers';
import { Quote } from '~/data/models/yearbook';
import { t } from '~/utils/i18n';
import {
  Container,
  AnimatedSwipeableContainer,
  DeleteButton,
  DeleteIcon,
  DeleteText,
  Content,
  AuthorContainer,
  AuthorAvatar,
  AuthorName,
  AuthorSeparator,
  FooterContainer,
  LikeButtonIcon,
  LikeContainer,
  LikeText,
  Text,
  SWIPEABLE_BUTTON_WIDTH,
} from './style';

type QuoteCellProps = {
  testID?: string;
  quote?: Quote;
  swipeable?: boolean;
  anonymous?: boolean;
  ownQuote?: boolean;
  isLiked: boolean;
  onLike: (quoteId: string, liked: boolean) => void;
  onDelete?: (quoteId: string) => void;
};

export type QuoteCellHandle = {
  collapse: () => void;
};

const QuoteCell: ForwardRefRenderFunction<QuoteCellHandle, QuoteCellProps> = (
  { testID, quote, swipeable, anonymous, ownQuote, isLiked, onLike, onDelete },
  ref,
) => {
  const [animated] = useState(new Animated.Value(0));
  const [cellHeight, setCellHeight] = useState<number | null>(null);

  function collapse() {
    Animated.timing(animated, {
      toValue: 1,
      duration: 250,
      useNativeDriver: false,
    }).start();
  }

  useImperativeHandle(ref, () => ({
    collapse: () => collapse(),
  }));

  const renderContent = () => {
    if (!quote) {
      return (
        <Content>
          <Shimmer w={240} h={24} g={'g3'} />
          <Shimmer w={200} h={24} mt={4} g={'g3'} />
          <AuthorSeparator />
          <AuthorContainer>
            <Shimmer w={15} h={15} br={8} mt={2} g={'g3'} />
            <Shimmer h={20} ml={4} g={'g3'} />
          </AuthorContainer>
          <LikeContainer>
            <Shimmer w={24} h={24} br={24} />
          </LikeContainer>
        </Content>
      );
    }

    const textSize = (text: string): number => {
      const newLines = Math.max(text.split('\\n').length - 1, 0);

      return text.length + newLines * 20;
    };
    const mappedUser = mapUser(quote.userGroup.user);
    return (
      <Content>
        <Text size={textSize(quote.quote) > 80 ? 16 : 22}>{quote.quote}</Text>
        <AuthorSeparator />
        <FooterContainer>
          <AuthorContainer>
            {(() => {
              if (anonymous && ownQuote) {
                return (
                  <>
                    <AuthorAvatar
                      size={15}
                      userGroup={quote.userGroup}
                      hidden
                    />
                    <AuthorName hidden>{`${mappedUser?.name}\n(${t(
                      'g.anonymousPost',
                    )})`}</AuthorName>
                  </>
                );
              } else if (anonymous && !ownQuote) {
                return <AuthorName>{t('g.anonymous')}</AuthorName>;
              }
              return (
                <>
                  <AuthorAvatar size={15} userGroup={quote.userGroup} />
                  <AuthorName>{mappedUser?.name}</AuthorName>
                </>
              );
            })()}
          </AuthorContainer>
          <LikeContainer>
            <LikeButtonIcon
              name={'thumbs-up'}
              checked={isLiked}
              onPress={() => onLike(quote.id, isLiked)}
            />
            <LikeText>
              {t('yearbookQuotes.likeWithCount', {
                count: quote.quotesInstanceLikesCount || 0,
              })}
            </LikeText>
          </LikeContainer>
        </FooterContainer>
      </Content>
    );
  };

  if (swipeable && !!cellHeight) {
    const deleteAnimation = {
      height: animated.interpolate({
        inputRange: [0, 1],
        outputRange: [cellHeight, 0],
        extrapolate: 'clamp',
      }),
    };
    return (
      <Container testID={testID}>
        <AnimatedSwipeableContainer
          style={deleteAnimation}
          rightButtonWidth={SWIPEABLE_BUTTON_WIDTH}
          rightButtons={[
            <DeleteButton
              key={'deleteButton'}
              onPress={() => quote && onDelete && onDelete(quote.id)}
            >
              <DeleteIcon />
              <DeleteText>{t('g.delete')}</DeleteText>
            </DeleteButton>,
          ]}
        >
          {renderContent()}
        </AnimatedSwipeableContainer>
      </Container>
    );
  }
  return (
    <Container
      testID={testID}
      onLayout={(event) => setCellHeight(event.nativeEvent.layout.height)}
    >
      {renderContent()}
    </Container>
  );
};

export default forwardRef(QuoteCell);
