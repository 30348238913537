/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation JoinEvent
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { JoinEvent, JoinEventVariables } from './types/JoinEvent';
import { JOIN_EVENT } from './';

export function useJoinEventMutation(
  options?: MutationHookOptions<JoinEvent, JoinEventVariables>,
): MutationTuple<JoinEvent, JoinEventVariables> {
  return useMutation<JoinEvent, JoinEventVariables>(JOIN_EVENT, options);
}

export function createJoinEventMock({
  variables,
  data,
  error,
}: {
  variables?: JoinEventVariables;
  data?: JoinEvent;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: JOIN_EVENT,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
