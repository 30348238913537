import React, { useState } from 'react';
import InputField from '~/components/InputField';
import NavHeader from '~/components/NavHeader';
import { ProfilePageUserSummary } from '~/data/models/yearbook';
import { t } from '~/utils/i18n';
import UserSummaryCell from './UserSummaryCell';
import { Container, UserSummaryList, CellContainer, VSpacer } from './style';

type ProfilePageResultLayoutProps = {
  usersSummary: ProfilePageUserSummary[];
  totalQuestions: number;
  totalImages: number;
  onBack: () => void;
  onSelectedUserGroup: (userGroupId: string) => void;
};

export default function ProfilePageResults({
  usersSummary,
  totalQuestions,
  totalImages,
  onBack,
  onSelectedUserGroup,
}: ProfilePageResultLayoutProps): JSX.Element {
  const [searchingText, setSearchingText] = useState('');

  const renderItem = ({
    item: profilePageUserSummary,
  }: {
    item: ProfilePageUserSummary;
  }) => {
    return (
      <CellContainer>
        <UserSummaryCell
          testID={`UserSummaryCell:${profilePageUserSummary.userGroup.id}`}
          profilePageUserSummary={profilePageUserSummary}
          totalQuestions={totalQuestions}
          totalImages={totalImages}
          onPress={() =>
            onSelectedUserGroup(profilePageUserSummary.userGroup.id)
          }
        />
      </CellContainer>
    );
  };

  const filteredUsersSummary = usersSummary.filter(
    (userSummary) =>
      userSummary.userGroup.user.firstName
        .toLocaleLowerCase()
        .includes(searchingText.toLocaleLowerCase()) ||
      userSummary.userGroup.user.lastName
        .toLocaleLowerCase()
        .includes(searchingText.toLocaleLowerCase()),
  );

  return (
    <Container>
      <NavHeader
        backButtonTestID={'buttonBack'}
        title={t('screens.profilePage')}
        onBackPress={onBack}
      />
      <UserSummaryList<React.ElementType>
        data={filteredUsersSummary}
        renderItem={renderItem}
        ListHeaderComponent={
          <>
            <InputField
              testID={'inputSearch'}
              iconName={'search-md'}
              label={t('g.search')}
              value={searchingText}
              onChangeText={(value) => {
                setSearchingText(value);
              }}
            />
            <VSpacer />
          </>
        }
        ListFooterComponent={<VSpacer />}
        keyExtractor={(userSummary: ProfilePageUserSummary) =>
          userSummary.userGroup.id
        }
      />
    </Container>
  );
}
