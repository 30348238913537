import React, { useState, useRef, useEffect } from 'react';
import { Alert } from 'react-native';
import Button from '~/components/Button';
import InputField from '~/components/InputField';
import MemberCell from '~/components/MemberCell';
import ModalConfirm, { ModalConfirmHandler } from '~/components/ModalConfirm';
import NavHeader from '~/components/NavHeader';
import TeamColorPicker from '~/components/TeamColorPicker';
import TeamIconPicker from '~/components/TeamIconPicker';
import TeamTypeBanner from '~/components/TeamTypeBanner';
import { mapUser } from '~/data/mappers';
import { Color, Icon } from '~/data/models/custom';
import { UserGroup } from '~/data/models/group';
import { Team, TeamUser } from '~/data/models/team';
import { TeamType } from '~/data/operations/global';
import { t } from '~/utils/i18n';
import {
  Container,
  LoadingContent,
  Loading,
  HeaderContainer,
  TeamTypeView,
  MemberList,
  MemberCellView,
  Header,
  Name,
  InputContent,
  SectionHeader,
  RefreshControl,
  Footer,
  ButtonView,
} from './style';

type EditTeamLoadingLayoutProps = {
  onBack: () => void;
};

export function EditTeamLoading({
  onBack,
}: EditTeamLoadingLayoutProps): JSX.Element {
  return (
    <Container>
      <NavHeader
        backButtonTestID={'buttonBack'}
        title={t('screens.editTeam')}
        onBackPress={onBack}
      />
      <LoadingContent>
        <Loading />
      </LoadingContent>
    </Container>
  );
}

export type EditTeamInputProps = {
  name: string;
  colorId: string;
  iconId: string;
};

export type EditTeamLayoutProps = {
  authUserId?: string;
  team: Team;
  colors: Color[];
  icons: Icon[];
  teamUsers: TeamUser[];
  loading: boolean;
  removeUserLoading: boolean;
  removeTeamLoading: boolean;
  onBack: () => void;
  onRemoveUser: (userId: string, leavingTeam: boolean) => void;
  onEditTeam: (teamInput: EditTeamInputProps) => void;
  onAddMember: () => void;
  onRefresh: () => void;
  onRemoveTeam: () => void;
  onHelp: () => void;
};

export default function EditTeam({
  authUserId,
  team,
  colors,
  icons,
  teamUsers,
  loading,
  removeUserLoading,
  removeTeamLoading,
  onBack,
  onRemoveUser,
  onEditTeam,
  onAddMember,
  onRefresh,
  onRemoveTeam,
  onHelp,
}: EditTeamLayoutProps): JSX.Element {
  const modalizeRef = useRef<ModalConfirmHandler>(null);

  const getInitialName = () => team.name;
  const getInitialColor = () => team.color;
  const getInitialIcon = () => team.icon;

  const [name, setName] = useState<string>(getInitialName);
  const [selectedColor, setSelectedColor] = useState<Color | null>(
    getInitialColor,
  );
  const [selectedIcon, setSelectedIcon] = useState<Icon | null>(getInitialIcon);
  const [removingTeamUser, setRemovingTeamUser] = useState<TeamUser | null>(
    null,
  );

  const renderMemberItem = ({ item: teamUser }: { item: TeamUser }) => {
    return (
      <MemberCellView>
        <MemberCell
          testID={`MemberCell:${teamUser.id}`}
          authUserId={authUserId}
          teamUser={teamUser}
          teamColor={selectedColor?.hex}
          leaveText={t('g.leaveTeam')}
          onRemovePress={() => {
            setRemovingTeamUser(teamUser);
            modalizeRef.current?.open();
          }}
        />
      </MemberCellView>
    );
  };

  useEffect(() => {
    const updatedName = name && name !== getInitialName();
    const updatedColor =
      selectedColor && selectedColor.id !== getInitialColor()?.id;
    const updatedIcon =
      selectedIcon && selectedIcon.id !== getInitialIcon()?.id;

    if (
      selectedColor &&
      selectedIcon &&
      (updatedName || updatedColor || updatedIcon)
    ) {
      onEditTeam({
        name,
        colorId: selectedColor.id,
        iconId: selectedIcon.id,
      });
    }
  }, [name, selectedColor, selectedIcon]);

  const removingUserGroupName = mapUser(removingTeamUser?.user)?.name;
  const leavingTeam = removingTeamUser?.user?.id === authUserId;

  const isDefaultTeam = team.type !== TeamType.OTHER;

  const removeTeamText = isDefaultTeam
    ? t('editTeam.deactivateTeam')
    : t('editTeam.deleteTeam');

  const removeTeamAlertText = isDefaultTeam
    ? t('editTeam.deactivateTeamAlert')
    : t('editTeam.deleteTeamAlert');

  const removeText = isDefaultTeam ? t('g.deactivate') : t('g.delete');

  return (
    <Container>
      <NavHeader
        backButtonTestID={'buttonBack'}
        title={t('screens.editTeam')}
        onBackPress={onBack}
        RightComponent={
          <Button
            text={t('getHelp')}
            testID="getHelp"
            size={'sm'}
            type={'secondary-base'}
            onPress={onHelp}
          />
        }
      />
      <MemberList<React.ElementType>
        refreshControl={
          <RefreshControl refreshing={loading} onRefresh={onRefresh} />
        }
        data={teamUsers}
        renderItem={renderMemberItem}
        ListHeaderComponent={
          <HeaderContainer>
            <TeamIconPicker
              testID={'iconPicker'}
              icons={icons}
              initialIconIndex={icons.findIndex(
                (icon) => icon.id === team?.icon?.id,
              )}
              teamColor={selectedColor?.hex}
              onSelectedIcon={(icon) => setSelectedIcon(icon)}
            />
            <Header>
              <Name>{name || t('editTeam.insertTeamName')}</Name>
            </Header>
            <InputContent>
              <InputField
                testID={'inputName'}
                iconName={'users-01'}
                value={name}
                label={t('editTeam.inputName')}
                onChangeText={(value) => setName(value)}
              />
            </InputContent>
            <TeamColorPicker
              testID={'colorPicker'}
              colors={colors}
              selectedColor={selectedColor}
              onSelectedColor={(color) => setSelectedColor(color)}
            />
            {team.type ? (
              <TeamTypeView>
                <TeamTypeBanner teamType={team.type} />
              </TeamTypeView>
            ) : null}
            <SectionHeader
              title={t('sections.teamMembers')}
              RightComponent={
                <Button
                  testID={'buttonAddMember'}
                  text={t('addMember')}
                  onPress={onAddMember}
                  size="sm"
                ></Button>
              }
            />
          </HeaderContainer>
        }
        ListFooterComponent={
          team.type !== TeamType.CORE && team.type !== TeamType.YEARBOOK ? (
            <Footer>
              <SectionHeader title={t('sections.dangerZone')} />
              <ButtonView>
                <Button
                  testID={'buttonRemoveTeam'}
                  type={isDefaultTeam ? 'primary-brand-03' : 'destructive'}
                  minWidth={360}
                  text={removeTeamText}
                  size="xl"
                  loading={removeTeamLoading}
                  onPress={() => {
                    Alert.alert(removeTeamAlertText, '', [
                      {
                        text: t('alert.cancel'),
                        style: 'cancel',
                      },
                      {
                        text: removeText,
                        onPress: () => {
                          onRemoveTeam();
                        },
                      },
                    ]);
                  }}
                />
              </ButtonView>
            </Footer>
          ) : null
        }
        keyExtractor={(item: UserGroup) => item.id}
      />
      <ModalConfirm
        testID={'modalConfirm'}
        buttonTestID={'modalConfirmButton'}
        ref={modalizeRef}
        title={
          leavingTeam
            ? t('editTeam.leaveModalTitle', {
                teamName: team?.name,
              })
            : t('editTeam.removeModalTitle', {
                name: removingUserGroupName,
                teamName: team?.name,
              })
        }
        descp={t('editTeam.modalDescp')}
        buttonText={
          leavingTeam
            ? t('editTeam.leaveTeam')
            : t('editTeam.removeUser', {
                name: removingUserGroupName,
              })
        }
        loading={removeUserLoading}
        onConfirm={() => {
          modalizeRef.current?.close();
          removingTeamUser &&
            onRemoveUser(removingTeamUser.user.id, leavingTeam);
        }}
      />
    </Container>
  );
}
