import styled from 'styled-components/native';
import { ModalScreenContainer } from '~/screens/style';

export const Container = styled(ModalScreenContainer)`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const Header = styled.View`
  padding-vertical: 24px;
`;

export const ModuleList = styled.FlatList`
  padding: 16px;
`;
