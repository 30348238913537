import React from 'react';
import { flags } from '~/assets/imgs';
import { COUNTRIES } from '~/data/constants';
import { Country } from '~/data/models/marketProfile';
import {
  Container,
  FlagImage,
  FlagView,
  Name,
  SelectedButtonIcon,
} from './style';

type ResidenceCountryCell = {
  country: Country;
  onSelect: () => void;
};

export default function ResidenceCountryCell({
  country,
  onSelect,
}: ResidenceCountryCell): JSX.Element {
  const name = country ? COUNTRIES[country].name : '';

  return (
    <Container>
      <FlagView>
        <FlagImage
          testID={`flagResidenceImage${country}`}
          source={flags[country]}
        />
      </FlagView>
      <Name>{name}</Name>
      <SelectedButtonIcon onPress={() => onSelect()} />
    </Container>
  );
}
