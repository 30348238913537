/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query AdminGetGroup
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { AdminGetGroup, AdminGetGroupVariables } from './types/AdminGetGroup';
import { ADMIN_GET_GROUP } from './';

export function useAdminGetGroupQuery(
  options?: QueryHookOptions<AdminGetGroup, AdminGetGroupVariables>,
): QueryResult<AdminGetGroup, AdminGetGroupVariables> {
  return useQuery<AdminGetGroup, AdminGetGroupVariables>(
    ADMIN_GET_GROUP,
    options,
  );
}

export function useAdminGetGroupLazyQuery(
  options?: LazyQueryHookOptions<AdminGetGroup, AdminGetGroupVariables>,
): QueryTuple<AdminGetGroup, AdminGetGroupVariables> {
  return useLazyQuery<AdminGetGroup, AdminGetGroupVariables>(
    ADMIN_GET_GROUP,
    options,
  );
}

export function createAdminGetGroupMock({
  variables,
  data,
  error,
}: {
  variables?: AdminGetGroupVariables;
  data?: AdminGetGroup;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: ADMIN_GET_GROUP,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
