/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation DestroyModuleInstance
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  DestroyModuleInstance,
  DestroyModuleInstanceVariables,
} from './types/DestroyModuleInstance';
import { DESTROY_MODULE_INSTANCE } from './';

export function useDestroyModuleInstanceMutation(
  options?: MutationHookOptions<
    DestroyModuleInstance,
    DestroyModuleInstanceVariables
  >,
): MutationTuple<DestroyModuleInstance, DestroyModuleInstanceVariables> {
  return useMutation<DestroyModuleInstance, DestroyModuleInstanceVariables>(
    DESTROY_MODULE_INSTANCE,
    options,
  );
}

export function createDestroyModuleInstanceMock({
  variables,
  data,
  error,
}: {
  variables?: DestroyModuleInstanceVariables;
  data?: DestroyModuleInstance;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: DESTROY_MODULE_INSTANCE,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
