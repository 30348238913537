import styledWeb from 'styled-components';
import styled from 'styled-components/native';
import Icon from '~/components/Icon';
import { CommonPressable } from '~/components/common/style';
import IconStyled from '../IconStyled';
import { bySizeHelper, byTypeHelper } from './helpers';
import { ButtonState, ButtonSize, ButtonType, ButtonIcon } from './types';

interface IPressableContainer {
  type: ButtonType;
  state: ButtonState;
  fullWidthInWrap?: boolean;
  bottomMargin?: number;
  iconType?: ButtonIcon;
  paddingTop?: number;
}

export const PressableContainer = CommonPressable<IPressableContainer>`
  border-radius: 50px;
  border-width: ${({ iconType }) => (iconType === 'only' ? 0 : 1)}px;
  border-color: ${({ type, state, theme }) =>
    byTypeHelper.innerBorderColor(type, state, theme)};
  box-shadow: 0px 1px 2px rgba(33, 37, 41, 0.10);
  margin-bottom: ${({ bottomMargin = 3 }) => bottomMargin}px;
  width: ${({ fullWidthInWrap }) => (fullWidthInWrap ? '100%' : 'auto')};
`;

export const OpacityView = styled.View<{ opacity: number }>`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #000;
  border-radius: 50px;
  opacity: ${({ opacity }) => opacity};
  pointer-events: none;
`;

interface IText {
  type: ButtonType;
  state: ButtonState;
  size: ButtonSize;
}
export const Text = styled.Text<IText>`
  text-align: center;
  font-size: ${({ size }) => bySizeHelper.textSize(size)}px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ type, state, theme }) =>
    byTypeHelper.textColor(type, state, theme)};
  line-height: ${({ size }) => bySizeHelper.lineHeight(size)}px;
`;

/** Icon */
interface IIcon {
  type: ButtonType;
  state: ButtonState;
  buttonSize: ButtonSize;
  iconType?: ButtonIcon;
}
export const CustomIcon = styled(Icon).attrs<IIcon>(
  ({ type, state, buttonSize, theme }) => ({
    size: bySizeHelper.iconSize(buttonSize),
    color: byTypeHelper.textColor(type, state, theme),
  }),
)<IIcon>`
  ${({ iconType }) => iconType == 'right' && `margin-left: 8px;`}
  ${({ iconType }) => iconType == 'left' && `margin-right: 8px;`}
`;

export const CustomStyledIcon = styled(IconStyled).attrs<IIcon>(
  ({ type, state, buttonSize, theme }) => ({
    size: bySizeHelper.iconSize(buttonSize),
    color: byTypeHelper.textColor(type, state, theme),
  }),
)<IIcon>`
  ${({ iconType }) => iconType == 'right' && `padding-left: 8px;`}
  ${({ iconType }) => iconType == 'left' && `padding-right: 8px;`}
`;

interface ILoading {
  type: ButtonType;
  state: ButtonState;
}
export const Loading = styled.ActivityIndicator.attrs<ILoading>(
  ({ type, state, theme }) => ({
    color: byTypeHelper.textColor(type, state, theme),
  }),
)<ILoading>``;

export const WebInnerWrapper = styledWeb('div')`
  box-shadow: 0px 1px 2px 0px rgba(33, 37, 41, 0.1), 0px 1px 2px 1.5px rgba(255, 255, 255, 0.3) inset, 0px -2px 2px 0.5px rgba(0, 0, 0, 0.1) inset;
  display: flex;

  flex-direction: row;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;
