import React from 'react';
import NavHeader from '~/components/NavHeader';
import SectionHeader from '~/components/SectionHeader';
import { GroupStatistics } from '~/data/models/group';
import { daysTo } from '~/utils/dates';
import { t } from '~/utils/i18n';
import NumberCard, { NumberCardProps } from './NumberCard';
import PercentCard, { PercentCardProps } from './PercentCard';
import TimeProgress from './TimeProgress';
import {
  Container,
  TopBg,
  NumberInfoList,
  PercentInfoList,
  HSpacer,
  Header,
  TimeProgressView,
} from './style';

type PercentItem = PercentCardProps['percentItem'];

type NumberItem = NumberCardProps['numberItem'];

type BookStatisticsLayoutProps = {
  groupStatistics?: GroupStatistics | null;
  now?: Date;
  onBack: () => void;
};

export default function BookStatistics({
  groupStatistics,
  now = new Date(),
  onBack,
}: BookStatisticsLayoutProps): JSX.Element {
  const percentItems = [
    {
      pct: groupStatistics?.yearbookStatistics?.profilePagesFilledIn,
      text: t('bookStatistics.profilePagesFilledIn'),
    },
    {
      pct: groupStatistics?.yearbookStatistics?.usersWithQuote,
      text: t('bookStatistics.postedAtLeastOneQuote'),
    },
    // {
    //   pct: 0, //TODO
    //   text: t('bookStatistics.participatedAtRanking'),
    // },
  ];

  const filteredPercentItems = percentItems.filter(
    (percentItem) => percentItem.pct !== undefined && percentItem.pct !== null,
  );

  const getTotalModuleNumber = () => {
    const totalModuleNumber =
      groupStatistics?.yearbookStatistics?.contentModules || 0;
    return totalModuleNumber;
  };

  const numberItems = [
    {
      number: getTotalModuleNumber(),
      text: t('bookStatistics.contentModulesAdded'),
    },
    {
      number: groupStatistics?.yearbookStatistics?.estimatedPages || 0,
      text: t('bookStatistics.estimatedPages'),
    },
    {
      number: groupStatistics?.yearbookStatistics?.imagesUploaded || 0,
      text: t('bookStatistics.imagesUploaded'),
    },
    {
      number: groupStatistics?.yearbookStatistics?.wordCount || 0,
      text: t('bookStatistics.totalWordCount'),
    },
  ];

  const lastDeadline = groupStatistics?.yearbookStatistics?.lastDeadline;
  const daysToLastDeadline = daysTo(lastDeadline, now) || 0;

  const renderNumberItem = ({ item: numberItem }: { item: NumberItem }) => {
    return (
      <NumberCard
        testID={`numberCard:${numberItem.text}`}
        numberItem={numberItem}
      />
    );
  };

  const renderPercentItem = ({ item: percentItem }: { item: PercentItem }) => {
    return <PercentCard percentItem={percentItem} />;
  };

  return (
    <Container>
      <TopBg />
      <NavHeader
        backButtonTestID={'buttonBack'}
        title={t('screens.bookStatistics')}
        onBackPress={onBack}
      />
      <NumberInfoList<React.ElementType>
        data={numberItems}
        renderItem={renderNumberItem}
        numColumns={2}
        keyExtractor={(_: NumberItem, i: number) => `ni${i}`}
        columnWrapperStyle={{
          flex: 1,
          justifyContent: 'space-between',
          paddingHorizontal: 16,
          paddingTop: 16,
        }}
        ListHeaderComponent={() => (
          <>
            <PercentInfoList<React.ElementType>
              data={filteredPercentItems}
              renderItem={renderPercentItem}
              horizontal
              showsHorizontalScrollIndicator={false}
              keyExtractor={(_: PercentItem, i: number) => `pi${i}`}
              ListHeaderComponent={() => <HSpacer />}
              ListFooterComponent={() => <HSpacer />}
              ItemSeparatorComponent={() => <HSpacer />}
            />
            <Header>
              <SectionHeader title={t('bookStatistics.timeSection')} />
              <TimeProgressView>
                <TimeProgress daysLeft={daysToLastDeadline} />
              </TimeProgressView>
              <SectionHeader title={t('bookStatistics.yearbookSection')} />
            </Header>
          </>
        )}
      />
    </Container>
  );
}
