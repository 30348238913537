/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation CreatePollOption
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  CreatePollOption,
  CreatePollOptionVariables,
} from './types/CreatePollOption';
import { CREATE_POLL_OPTION } from './';

export function useCreatePollOptionMutation(
  options?: MutationHookOptions<CreatePollOption, CreatePollOptionVariables>,
): MutationTuple<CreatePollOption, CreatePollOptionVariables> {
  return useMutation<CreatePollOption, CreatePollOptionVariables>(
    CREATE_POLL_OPTION,
    options,
  );
}

export function createCreatePollOptionMock({
  variables,
  data,
  error,
}: {
  variables?: CreatePollOptionVariables;
  data?: CreatePollOption;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: CREATE_POLL_OPTION,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
