import React from 'react';
import {
  CompositeNavigationProp,
  useNavigation,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useAuth } from '~/context/auth';
import { useIntercom } from '~/context/intercom';
import useTeamSamples from '~/data/hooks/useTeamSamples';
import {
  MainTabParamList,
  PlanningStackParamList,
  RootStackParamList,
} from '~/navigation/types';
import PlanningLayout from './layout';

// NOTE: Todo feature will not be implemented for a while and we remove the cell in V2 design. In case we implement it, related code are just hidden with comment lines. If we are sure about not implementing it, we can remove the related code.

// NOTE: Intercom will be implemented in the future, onHelp button will connect to it.

type PlanningNavProp = CompositeNavigationProp<
  StackNavigationProp<PlanningStackParamList, 'Planning'>,
  CompositeNavigationProp<
    StackNavigationProp<RootStackParamList>,
    StackNavigationProp<MainTabParamList>
  >
>;

export default function Planning(): JSX.Element {
  const navigation = useNavigation<PlanningNavProp>();
  const { onHelpCenter } = useIntercom();
  const {
    teamSamples,
    loading: teamSamplesLoading,
    errorMessage: teamSamplesErrorMessage,
    onLoadMore: onLoadMoreTeams,
  } = useTeamSamples();

  const { coreAdmin, authUserId } = useAuth();

  const filteredTeams = coreAdmin
    ? teamSamples.sort((t1, t2) => +t1.isHidden - +t2.isHidden)
    : teamSamples.filter((t) => !t.isHidden);

  const openBannersInBrowser = (url: string) => {
    navigation.navigate('Browser', {
      title: 'Banner',
      url,
    });
  };
  return (
    <PlanningLayout
      teams={filteredTeams}
      loading={teamSamplesLoading}
      errorMessage={teamSamplesErrorMessage}
      openBannersInBrowser={openBannersInBrowser}
      onSeeAllTeams={() => navigation.navigate('Teams')}
      onSelectedTeam={(teamId) => navigation.navigate('TeamDetail', { teamId })}
      onPoll={() => navigation.navigate('Polls')}
      // onTodo={() => navigation.navigate('ToDoLists')}
      onCalendar={() => navigation.navigate('Calendar')}
      onLoadMoreTeams={onLoadMoreTeams}
      onHelp={onHelpCenter}
      authUserId={authUserId}
    />
  );
}
