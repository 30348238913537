import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { AdsStackParamList } from '~/navigation/types';
import AddCampaign from '~/screens/Admin/AddCampaign';
import Ads from '~/screens/Admin/Ads';
import EditAd from '~/screens/Admin/EditAd';
import EditAsset from '~/screens/Admin/EditAsset';
const AdsStackNavigator = createStackNavigator<AdsStackParamList>();

export default function AdsStack(): JSX.Element {
  return (
    <AdsStackNavigator.Navigator
      initialRouteName={'Ads'}
      screenOptions={{
        headerShown: false,
      }}
    >
      <AdsStackNavigator.Screen name={'Ads'} component={Ads} />
      <AdsStackNavigator.Screen name={'EditAd'} component={EditAd} />
      <AdsStackNavigator.Screen name={'EditAsset'} component={EditAsset} />
      <AdsStackNavigator.Screen name={'AddCampaign'} component={AddCampaign} />
    </AdsStackNavigator.Navigator>
  );
}
