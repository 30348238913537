/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation ResetPassword
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { ResetPassword, ResetPasswordVariables } from './types/ResetPassword';
import { RESET_PASSWORD } from './';

export function useResetPasswordMutation(
  options?: MutationHookOptions<ResetPassword, ResetPasswordVariables>,
): MutationTuple<ResetPassword, ResetPasswordVariables> {
  return useMutation<ResetPassword, ResetPasswordVariables>(
    RESET_PASSWORD,
    options,
  );
}

export function createResetPasswordMock({
  variables,
  data,
  error,
}: {
  variables?: ResetPasswordVariables;
  data?: ResetPassword;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: RESET_PASSWORD,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
