/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query Polls
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { Polls, PollsVariables } from './types/Polls';
import { POLLS } from './';

export function usePollsQuery(
  options?: QueryHookOptions<Polls, PollsVariables>,
): QueryResult<Polls, PollsVariables> {
  return useQuery<Polls, PollsVariables>(POLLS, options);
}

export function usePollsLazyQuery(
  options?: LazyQueryHookOptions<Polls, PollsVariables>,
): QueryTuple<Polls, PollsVariables> {
  return useLazyQuery<Polls, PollsVariables>(POLLS, options);
}

export function createPollsMock({
  variables,
  data,
  error,
}: {
  variables?: PollsVariables;
  data?: Polls;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: POLLS,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
