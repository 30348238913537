import React from 'react';
import Button from '~/components/Button';
import Shimmer from '~/components/Shimmer';
import { mapUser } from '~/data/mappers';
import { UserGroup } from '~/data/models/group';
import { Team, TeamUser } from '~/data/models/team';
import { formattedTimeAgo } from '~/utils/dates';
import { t } from '~/utils/i18n';
import Avatar from '../Avatar';
import {
  Container,
  DateText,
  Title,
  Descp,
  Row,
  ArrowIcon,
  TeamIcon,
  Footer,
} from './style';

type NotificationCellProps = {
  testID?: string;
  requestDate?: string;
  now?: Date;
  onDecline: () => void;
  onAccept: () => void;
};

interface NotificationTeamRequestCellProps extends NotificationCellProps {
  teamUser: TeamUser;
  team: Team;
}

export function NotificationTeamRequestCell({
  testID,
  teamUser,
  requestDate = teamUser.created,
  team,
  now = new Date(),
  onDecline,
  onAccept,
}: NotificationTeamRequestCellProps): JSX.Element {
  const user = mapUser(teamUser.user);
  return (
    <Container testID={testID}>
      <DateText>{formattedTimeAgo(requestDate, now)}</DateText>
      <Row>
        <Avatar size={32} teamUser={teamUser} />
        <ArrowIcon />
        <TeamIcon
          teamColor={team.color?.hex}
          iconName={team.icon?.icon}
          size={32}
          borderRadius={5}
        />
        <Title>{user?.name}</Title>
      </Row>
      <Descp>
        {t('notification.teamRequestDescp', { teamName: team.name })}
      </Descp>
      <Footer>
        <Button
          text={t('g.decline')}
          type="destructive"
          size="sm"
          onPress={() => onDecline()}
        />
        <Button
          text={t('g.accept')}
          size="sm"
          type="secondary-contrast"
          onPress={() => onAccept()}
        />
      </Footer>
    </Container>
  );
}

interface NotificationGroupRequestCellProps extends NotificationCellProps {
  userGroup?: UserGroup;
}

export function NotificationGroupRequestCell({
  testID,
  userGroup,
  requestDate = userGroup?.created,
  now = new Date(),
  onDecline,
  onAccept,
}: NotificationGroupRequestCellProps): JSX.Element {
  if (!userGroup) {
    return (
      <Container>
        <DateText>
          <Shimmer w={110} />
        </DateText>
        <Row>
          <Shimmer w={32} h={32} br={16} g={'g2'} />
          <Title>
            <Shimmer h={22} g={'g3'} />
          </Title>
        </Row>
        <Descp>
          <Shimmer w={200} h={20} />
        </Descp>
        <Footer>
          <Shimmer w={94} h={24} g={'g2'} />
          <Shimmer w={94} h={42} ml={16} g={'g3'} />
        </Footer>
      </Container>
    );
  }

  const user = mapUser(userGroup.user);
  return (
    <Container testID={testID}>
      <DateText>{formattedTimeAgo(requestDate, now)}</DateText>
      <Row>
        <Avatar size={32} userGroup={userGroup} />
        <Title>{user?.name}</Title>
      </Row>
      <Descp>{t('notification.groupRequestDescp')}</Descp>
      <Footer>
        <Button
          text={t('g.decline')}
          type="destructive"
          size="sm"
          onPress={() => onDecline()}
        />
        <Button
          text={t('g.accept')}
          size="sm"
          type="secondary-contrast"
          onPress={() => onAccept()}
        />
      </Footer>
    </Container>
  );
}
