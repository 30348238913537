import styled from 'styled-components/native';
import { ResponsiveProps } from '~/components/common/style.web';

export const Container = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  overflow-y: auto;
`;

export const Text = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 14px;
`;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 20px;
  color: ${({ theme }) => theme.color.base.c9};
  padding-bottom: 15px;
`;

export const MapContainer = styled.View<ResponsiveProps>`
  background-color: aliceblue;
  height: ${({ isDesktop }) => (isDesktop ? '450px' : '260px')};
  width: ${({ isDesktop }) => (isDesktop ? '320px' : '340px')};
  border-radius: 15px;
  margin-right: 24px;
`;

export const TargetingContainer = styled.View<ResponsiveProps>`
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
  padding-bottom: 10px;
`;

export const FilteringContainer = styled.View<ResponsiveProps>`
  max-height: 450px;
  padding-top: ${({ isDesktop }) => (isDesktop ? '0' : '15px')};
`;

export const ButtonAddFilterContainer = styled.View`
  align-items: end;
  z-index: 2;
  margin: 15px 0 100px;
`;

export const StatisticsContainer = styled.View`
  margin-bottom: 10px;
`;

export const PickerContainer = styled.View`
  position: absolute;
  z-index: 1;
  margin-top: 48px;
  background-color: ${({ theme }) => theme.color.base.c0};
  border-width: 1px;
  border-color: ${({ theme }) => theme.color.base.c3};
  border-radius: 7px;
  align-items: flex-start;
  width: 190px;
`;

interface IButtonProps {
  showBorder?: boolean;
}

export const ButtonContent = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})<IButtonProps>`
  padding: 8px 16px;
  border-bottom-width: ${({ showBorder }) => (showBorder ? 1 : 0)}px;
  width: 190px;
  border-color: ${({ theme }) => theme.color.brand_02};
`;

export const OrAndOptionsContainer = styled.View`
  width: 85px;
  height: 38px;
  margin: 15px 0;
`;

export const OptionsContainer = styled.View``;
