import styled, { css } from 'styled-components/native';
import { general } from '~/assets/imgs';
import { ResponsiveProps } from '~/components/common/style.web';

export const PageGrid = styled.View<ResponsiveProps>`
  display: grid;
  grid-template-columns: ${({ isDesktop }) =>
    isDesktop ? 'minmax(515px, auto) minmax(auto, 592px)' : 'auto'};
  min-height: 100%;
`;

export const ImporterLogos = styled.Image<ResponsiveProps>`
  width: 170px;
  height: 64px;
  margin-top: 49px;
  ${({ isDesktop }) =>
    !isDesktop &&
    css`
      margin-left: auto;
      margin-right: auto;
    `}
`;

export const Content = styled.View<ResponsiveProps>`
  background-color: ${({ theme, isDesktop }) =>
    isDesktop ? theme.color.base.c0 : 'transparent'};
  ${({ isDesktop }) =>
    !isDesktop &&
    css`
      padding: 0 16px;
    `}
`;

export const Container = styled.View<ResponsiveProps>`
  display: grid;
  grid-template-rows: ${({ isDesktop }) => (isDesktop ? '210px' : '140px')} 1fr auto;
  width: ${({ isDesktop }) => (isDesktop ? '350px' : '100%')};
  min-height: 100%;
  margin-left: ${({ isDesktop }) => (isDesktop ? '130px' : '0')};
`;

export const Mocks = styled.View`
  overflow: hidden;
`;

export const AbihomeMock = styled.ImageBackground.attrs({
  source: general.abihomeMock,
})`
  position: absolute;
  top: 0;
  right: 0;
  width: 569px;
  height: 530px;
`;

export const GradooMock = styled.ImageBackground.attrs({
  source: general.gradooMock,
})`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 660px;
  height: 505px;
`;

export const VSpacer = styled.View`
  height: 64px;
`;

export const MobileWhiteContainer = styled.View<ResponsiveProps>`
  ${({ isDesktop, theme }) =>
    !isDesktop &&
    css`
      padding: 32px 16px 64px;
      border-radius: 14px;
      background-color: ${theme.color.base.c0};
    `}
`;
