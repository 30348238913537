import React, { useCallback, useRef } from 'react';
import { FlatList, StyleProp, ViewStyle, ViewToken } from 'react-native';
import { BestMatchedAsset } from '~/data/models/campaign';
import { Post } from '~/data/models/post';
import { TeamUser } from '~/data/models/team';
import { ProfilePageQuestion } from '~/data/models/yearbook';
import { t } from '~/utils/i18n';
import { Content, EmptyText, FlatListWrapper, Loading } from './style';

type TrackingFlatListProps<
  T extends BestMatchedAsset | Post | ProfilePageQuestion | TeamUser,
> = {
  data: T[];
  testID: string;
  renderItem: ({ item, index }: { item?: T; index: number }) => JSX.Element;
  refreshControl?: React.ReactElement;
  ListHeaderComponent?: React.ReactElement;
  loading?: boolean;
  onEndReachedThreshold?: number;
  scrollEnabled?: boolean;
  horizontal?: boolean;
  showsHorizontalScrollIndicator?: boolean;
  setActiveNewsIndex?: React.Dispatch<React.SetStateAction<number>>;
  ListFooterComponent?: () => JSX.Element | null;
  ListEmptyComponent?: () => JSX.Element | null;
  onEndReached?: () => void;
  handleViewTracking: (postId: string) => void;
  contentContainerStyle?: StyleProp<ViewStyle>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TrackingFlatlist = React.forwardRef<FlatList, TrackingFlatListProps<any>>(
  function TrackingFlatlist({ data, renderItem, ...props }, ref) {
    // Declare trackingSent as a Set to keep track of items already tracked.
    const trackingSent = useRef<Set<number>>(new Set<number>());

    const onViewableItemsChanged = useCallback(
      ({ viewableItems }: { viewableItems: ViewToken[] }) => {
        const currentViewToken = viewableItems?.[0]; // first item is current one because of itemVisiblePercentThreshold = 1

        if (
          currentViewToken?.index !== null &&
          currentViewToken?.index !== undefined
        ) {
          props?.setActiveNewsIndex?.(currentViewToken.index);
        }

        viewableItems?.forEach((item) => {
          if (!item.item) {
            //handle shimmer items ([undefined, undefined])
            return;
          }
          if (
            !trackingSent.current.has(item.index as number) &&
            item.item.__typename === 'MatchedAssetNode'
          ) {
            trackingSent.current.add(item.index as number);
            props.handleViewTracking(item.item.id as string);

            setTimeout(() => {
              trackingSent.current.delete(item.index as number);
            }, 60000);
          }
        });
      },
      [],
    );

    return (
      <FlatListWrapper>
        <FlatList
          {...props}
          testID={props.testID}
          ref={ref}
          data={data}
          renderItem={renderItem}
          onViewableItemsChanged={onViewableItemsChanged}
          showsHorizontalScrollIndicator={props.showsHorizontalScrollIndicator}
          horizontal={props.horizontal}
          viewabilityConfig={{
            itemVisiblePercentThreshold: 1,
            minimumViewTime: 1,
            waitForInteraction: false,
          }}
          refreshControl={props.refreshControl}
          ListHeaderComponent={props.ListHeaderComponent}
          ListFooterComponent={
            props.ListFooterComponent
              ? props.ListFooterComponent
              : () => {
                  if (props.loading && data.length > 0) {
                    return (
                      <Content>
                        <Loading />
                      </Content>
                    );
                  }
                  return <Content />;
                }
          }
          ListEmptyComponent={
            props.ListEmptyComponent
              ? props.ListEmptyComponent
              : () => {
                  if (!props.loading) {
                    return (
                      <Content>
                        <EmptyText>{t('feed.empty')}</EmptyText>
                      </Content>
                    );
                  }
                  return null;
                }
          }
          onEndReachedThreshold={props.onEndReachedThreshold}
          onEndReached={props.onEndReached}
        />
      </FlatListWrapper>
    );
  },
);

// Add the display name to your component
TrackingFlatlist.displayName = 'TrackingFlatlist';

export default TrackingFlatlist;
