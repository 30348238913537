import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { Platform } from 'react-native';
import {
  YearbookStackParamList,
  MobileRootYearbookStackParamList,
} from '~/navigation/types';
import AddModule from '~/screens/Yearbook/AddModule';
import AddQuote from '~/screens/Yearbook/AddQuote';
import BookStatistics from '~/screens/Yearbook/BookStatistics';
import Collages from '~/screens/Yearbook/Collages';
import CollagesAlbumDetail from '~/screens/Yearbook/CollagesAlbumDetail';
import CollagesPhotoDetail from '~/screens/Yearbook/CollagesPhotoDetail';
import CollagesSetup from '~/screens/Yearbook/CollagesSetup';
import CollectedData from '~/screens/Yearbook/CollectedData';
import CreateCollage from '~/screens/Yearbook/CreateCollage';
import Custom from '~/screens/Yearbook/Custom';
import CustomPageResult from '~/screens/Yearbook/CustomPageResult';
import CustomPageResults from '~/screens/Yearbook/CustomPageResults';
import CustomSetup from '~/screens/Yearbook/CustomSetup';
import EditCustomPage from '~/screens/Yearbook/EditCustomPage';
import ExportData from '~/screens/Yearbook/ExportData';
import ExportHistory from '~/screens/Yearbook/ExportHistory';
import Exporting from '~/screens/Yearbook/Exporting';
import Yearbook from '~/screens/Yearbook/Home';
import Manage from '~/screens/Yearbook/Manage';
import ManageRequest from '~/screens/Yearbook/ManageRequest';
import ModuleDetail from '~/screens/Yearbook/ModuleDetail';
import ProfileCreateComment from '~/screens/Yearbook/ProfileCreateComment';
import ProfilePage from '~/screens/Yearbook/ProfilePage';
import ProfilePageAddPhotoCategory from '~/screens/Yearbook/ProfilePageAddPhotoCategory';
import ProfilePageAddQuestion from '~/screens/Yearbook/ProfilePageAddQuestion';
import ProfilePageAnswer from '~/screens/Yearbook/ProfilePageAnswer';
import ProfilePageComments from '~/screens/Yearbook/ProfilePageComments';
import ProfilePageResults from '~/screens/Yearbook/ProfilePageResults';
import ProfilePageSetup from '~/screens/Yearbook/ProfilePageSetup';
import Quotes from '~/screens/Yearbook/Quotes';
import QuotesSetup from '~/screens/Yearbook/QuotesSetup';
import RankingVote from '~/screens/Yearbook/RankingVote';
import Rankings from '~/screens/Yearbook/Rankings';
import RankingsAddQuestion from '~/screens/Yearbook/RankingsAddQuestion';
import RankingsCustomList from '~/screens/Yearbook/RankingsCustomList';
import RankingsCustomListOption from '~/screens/Yearbook/RankingsCustomListOption';
import RankingsSetup from '~/screens/Yearbook/RankingsSetup';
import ReportDetail from '~/screens/Yearbook/ReportDetail';
import ReportSettings from '~/screens/Yearbook/ReportSettings';
import Reports from '~/screens/Yearbook/Reports';
import ReportsSetup from '~/screens/Yearbook/ReportsSetup';

const YearbookStackNavigator = createStackNavigator<YearbookStackParamList>();

export const MOBILE_ROOT_YEARBOOK_SCREENS: {
  name: keyof MobileRootYearbookStackParamList;
  component: () => JSX.Element;
  isModalPresentationIOS?: boolean;
}[] = [
  {
    name: 'ProfileCreateComment',
    component: ProfileCreateComment,
    isModalPresentationIOS: true,
  },
  {
    name: 'ProfilePageAddQuestion',
    component: ProfilePageAddQuestion,
    isModalPresentationIOS: true,
  },
  {
    name: 'ProfilePageAddPhotoCategory',
    component: ProfilePageAddPhotoCategory,
    isModalPresentationIOS: true,
  },
  {
    name: 'CreateCollage',
    component: CreateCollage,
    isModalPresentationIOS: true,
  },
  {
    name: 'CollagesPhotoDetail',
    component: CollagesPhotoDetail,
    isModalPresentationIOS: false,
  },
  {
    name: 'ReportDetail',
    component: ReportDetail,
    isModalPresentationIOS: false,
  },
  {
    name: 'ReportSettings',
    component: ReportSettings,
    isModalPresentationIOS: false,
  },
  {
    name: 'AddQuote',
    component: AddQuote,
    isModalPresentationIOS: true,
  },
  {
    name: 'RankingsAddQuestion',
    component: RankingsAddQuestion,
    isModalPresentationIOS: true,
  },
  {
    name: 'RankingsCustomList',
    component: RankingsCustomList,
    isModalPresentationIOS: true,
  },
  {
    name: 'RankingsCustomListOption',
    component: RankingsCustomListOption,
    isModalPresentationIOS: true,
  },
  {
    name: 'RankingVote',
    component: RankingVote,
    isModalPresentationIOS: true,
  },
  {
    name: 'AddModule',
    component: AddModule,
    isModalPresentationIOS: true,
  },
  {
    name: 'ModuleDetail',
    component: ModuleDetail,
    isModalPresentationIOS: true,
  },
  {
    name: 'ProfilePageAnswer',
    component: ProfilePageAnswer,
    isModalPresentationIOS: true,
  },
  {
    name: 'ExportData',
    component: ExportData,
    isModalPresentationIOS: true,
  },
  {
    name: 'Exporting',
    component: Exporting,
    isModalPresentationIOS: true,
  },
  {
    name: 'ExportHistory',
    component: ExportHistory,
    isModalPresentationIOS: true,
  },
];

export default function YearbookStack(): JSX.Element {
  return (
    <YearbookStackNavigator.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <YearbookStackNavigator.Screen name={'Yearbook'} component={Yearbook} />
      <YearbookStackNavigator.Screen
        name={'ProfilePage'}
        component={ProfilePage}
      />
      <YearbookStackNavigator.Screen
        name={'ProfilePageComments'}
        component={ProfilePageComments}
      />
      <YearbookStackNavigator.Screen
        name={'ProfilePageSetup'}
        component={ProfilePageSetup}
      />
      <YearbookStackNavigator.Screen
        name={'ProfilePageResults'}
        component={ProfilePageResults}
      />
      <YearbookStackNavigator.Screen name={'Quotes'} component={Quotes} />
      <YearbookStackNavigator.Screen
        name={'QuotesSetup'}
        component={QuotesSetup}
      />
      <YearbookStackNavigator.Screen name={'Rankings'} component={Rankings} />
      <YearbookStackNavigator.Screen
        name={'RankingsSetup'}
        component={RankingsSetup}
      />
      <YearbookStackNavigator.Screen name={'Collages'} component={Collages} />
      <YearbookStackNavigator.Screen
        name={'CollagesAlbumDetail'}
        component={CollagesAlbumDetail}
      />
      <YearbookStackNavigator.Screen
        name={'CollagesSetup'}
        component={CollagesSetup}
      />
      <YearbookStackNavigator.Screen name={'Reports'} component={Reports} />
      <YearbookStackNavigator.Screen
        name={'ReportsSetup'}
        component={ReportsSetup}
      />
      <YearbookStackNavigator.Screen name={'Manage'} component={Manage} />
      <YearbookStackNavigator.Screen
        name={'ManageRequest'}
        component={ManageRequest}
      />

      <YearbookStackNavigator.Screen
        name={'CollectedData'}
        component={CollectedData}
      />
      <YearbookStackNavigator.Screen
        name={'BookStatistics'}
        component={BookStatistics}
      />
      <YearbookStackNavigator.Screen name={'Custom'} component={Custom} />
      <YearbookStackNavigator.Screen
        name={'EditCustomPage'}
        component={EditCustomPage}
      />
      <YearbookStackNavigator.Screen
        name={'CustomSetup'}
        component={CustomSetup}
      />
      <YearbookStackNavigator.Screen
        name={'CustomPageResults'}
        component={CustomPageResults}
      />

      <YearbookStackNavigator.Screen
        name={'CustomPageResult'}
        component={CustomPageResult}
      />
      {Platform.OS === 'web' &&
        MOBILE_ROOT_YEARBOOK_SCREENS.map((screen) => (
          <YearbookStackNavigator.Screen key={screen.name} {...screen} />
        ))}
    </YearbookStackNavigator.Navigator>
  );
}
