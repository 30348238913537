import React from 'react';
import CheckBox from '../CheckBox';
import { ArrowIcon, Container, Text } from './style';

export type CommonSelectableCellProps = {
  testID?: string;
  text: string;
  selected: boolean;
  onPress: () => void;
  onChange: (selected: boolean) => void;
};

export default function CommonSelectableCell({
  testID,
  text,
  selected,
  onPress,
  onChange,
}: CommonSelectableCellProps): JSX.Element {
  return (
    <Container testID={testID} selected={selected} onPress={onPress}>
      <CheckBox
        size={'large'}
        colorStyle={'tertiary'}
        checked={selected}
        onChange={onChange}
      />
      <Text selected={selected}>{text}</Text>
      <ArrowIcon name={'chevron-right'} selected={selected} />
    </Container>
  );
}
