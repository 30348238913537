import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import styled from 'styled-components/native';
import { ModalScreenContainer } from '~/screens/style';

export const Container = styled(ModalScreenContainer)``;

export const ScrollView = styled(KeyboardAwareScrollView)``;

export const InputAreaView = styled.View`
  padding: 32px 16px;
`;

export const SaveButtonContainer = styled.View`
  padding-horizontal: 16px;
  padding-bottom: 24px;
`;
