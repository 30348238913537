import { Dimensions } from 'react-native';
import styled from 'styled-components/native';
import ButtonIcon from '~/components/ButtonIcon';
import Image from '~/components/Image';
import { StackScreenContainer } from '~/screens/style';
const { width } = Dimensions.get('window');

export const CONTAINER_WIDTH = width;
export const CONTAINER_PADDING = 36;
export const PHOTO_CARD_WIDTH = CONTAINER_WIDTH - 2 * CONTAINER_PADDING;

export const Container = styled(StackScreenContainer)`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c9};
`;

export const Content = styled.View`
  flex: 1;
  padding-top: 24px;
`;

export const PhotoCardContainer = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})`
  flex: 1;
  overflow: hidden;
  justify-content: center;
`;

export const PhotoCardImage = styled(Image).attrs({
  autoHeightFromWidth: PHOTO_CARD_WIDTH,
})`
  background-color: ${({ theme }) => theme.color.base.c7};
  width: ${PHOTO_CARD_WIDTH}px;
  border-radius: 8px;
`;

export const PhotoCardInfo = styled.View`
  padding: 24px;
  flex-direction: row;
  justify-content: center;
`;

export const PhotoCardInfoTextBold = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c4};
  font-size: 14px;
`;

export const PhotoCardInfoText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c4};
  font-size: 14px;
  margin-horizontal: 2px;
`;

export const Footer = styled.View`
  padding-vertical: 24px;
  flex-direction: row;
  justify-content: center;
`;

interface ILikeButtonIcon {
  liked: boolean;
}

export const LikeButtonIcon = styled(ButtonIcon).attrs<ILikeButtonIcon>(
  ({ liked, theme }) => ({
    color: liked ? theme.color.brand_02 : theme.color.base.c7,
    size: 32,
  }),
)<ILikeButtonIcon>`
  width: 80px;
  height: 80px;
  border-radius: 40px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.base.c2};
`;

export const DeleteButtonIcon = styled(ButtonIcon).attrs(({ theme }) => ({
  color: theme.color.error,
  size: 32,
}))`
  width: 80px;
  height: 80px;
  border-radius: 40px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.base.c2};
`;

export const DownloadButtonIcon = styled(ButtonIcon).attrs(({ theme }) => ({
  color: theme.color.base.c9,
  size: 32,
}))`
  width: 80px;
  height: 80px;
  border-radius: 40px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const ButtonWrapper = styled.View`
  align-items: center;
  margin-horizontal: 16px;
`;

export const ButtonText = styled.Text`
  margin-top: 8px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c0};
  font-size: 14px;
`;
