import styled from 'styled-components/native';
import SectionHeader from '~/components/SectionHeader';

export const Container = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const DayWrapper = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})`
  width: 100%;
  align-items: center;
  align-self: stretch;
`;

export const DayFooter = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 10px;
  align-items: center;
  justify-content: center;
`;

interface ISelected {
  selected: boolean;
}

export const Dot = styled.View<ISelected>`
  margin: 1px;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: ${({ selected, theme }) =>
    selected ? theme.color.base.c0 : theme.color.brand_02};
`;

interface IDayCell {
  marked?: boolean;
}
export const DayCell = styled.View<IDayCell>`
  width: 34px;
  height: 34px;
  border-radius: 17px;
  ${({ marked, theme }) =>
    marked && `background-color: ${theme.color.brand_02};`}
  align-items: center;
  justify-content: center;
`;

interface IDayText {
  disabled: boolean;
}
export const DayText = styled.Text<IDayText>`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ disabled, theme }) =>
    disabled ? theme.color.base.c3 : theme.color.base.c5};
  font-size: 14px;
`;

interface ISelectableDayCell {
  marked: boolean;
}
export const SelectableDayCell = styled(DayCell)<ISelectableDayCell>`
  ${({ marked, theme }) =>
    marked && `background-color: ${theme.color.brand_02};`}
`;

interface ISelectableDayText extends IDayText {
  marked: boolean;
}
export const SelectableDayText = styled(DayText)<ISelectableDayText>`
  color: ${({ disabled, marked, theme }) =>
    disabled
      ? theme.color.base.c3
      : marked
      ? theme.color.base.c0
      : theme.color.base.c5};
  font-size: 14px;
  font-weight: 500;
`;

export const Footer = styled.View`
  align-items: center;
  padding-horizontal: 16px;
`;

export const CalendarHeaderTitle = styled(SectionHeader)`
  flex: 1;
`;

export const CalendarHeader = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
`;

export const CalendarHeaderMonth = styled.View`
  flex-direction: row;
`;

export const LeftArrow = styled.TouchableOpacity``;

export const RightArrow = styled.TouchableOpacity``;

export const MonthYear = styled.Text`
  font-size: 16px;
  color: ${({ theme }) => theme.color.base.c9};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  padding-left: 10px;
  padding-right: 10px;
`;
