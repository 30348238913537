import React from 'react';
import InputProgress from '~/components/InputProgress';
import { Container, Title, Descp, InputProgressContent } from './style';
export type InputHeaderProps = {
  title: string;
  descp: string;
  total?: number;
  progress?: number;
};

export default function InputHeader({
  title,
  descp,
  total,
  progress,
}: InputHeaderProps): JSX.Element {
  return (
    <Container>
      <Title>{title}</Title>
      <Descp>{descp}</Descp>
      {total && progress ? (
        <InputProgressContent>
          <InputProgress total={total} progress={progress} />
        </InputProgressContent>
      ) : null}
    </Container>
  );
}
