import styled from 'styled-components/native';

export const Container = styled.View`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c0}
  align-items: center;
  justify-content: center;
`;

export const Image = styled.Image`
  margin-top: 14px;
  height: 64px;
`;

export const Footer = styled.View`
  position: absolute;
  left: 16px;
  right: 16px;
  bottom: ${({ theme }) => theme.safeAreaInsets.bottom + 16}px;
  align-items: center;
`;
