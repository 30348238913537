import { DefaultTheme } from 'styled-components/native';
import styled from 'styled-components/native';
import Icon from '~/components/Icon';
import IconStyled from '~/components/IconStyled';
import Image from '~/components/Image';

export const Container = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})`
  background-color: ${({ theme }) => theme.color.base.c0};
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  padding: 16px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.color.base.c3};
  border-radius: 14px;
  box-shadow: -3px 3px 2px ${({ theme }) => theme.color.base.c1};
`;

export type ColorShemaKey = keyof DefaultTheme['color']['base'];

export const ImageContainer = styled.View`
  width: 56px;
  height: 56px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.color.base.c3};
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 16px;
`;

export const Picture = styled(Image)`
  width: 56px;
  height: 56px;
`;

export const TextWrapper = styled.View`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
`;

export const Text = styled.Text`
  color: ${({ theme }) => theme.color.brand_02};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
`;

export const ArrowIcon = styled(Icon).attrs(({ theme }) => ({
  name: 'chevron-right',
  size: 16,
  color: theme.color.brand_02,
}))`
  padding-left: 8px;
`;

export const UserQuestionIcon = styled(IconStyled).attrs({
  name: 'user_question_base',
  size: 32,
})``;
