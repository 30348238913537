import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Platform } from 'react-native';
import { openInbox } from '~/components/EmailLink';
import useAppLocale from '~/data/hooks/useAppLocale';
import { AuthStackParamList } from '~/navigation/types';
import ForgotPasswordInstructionLayout from './layout';

type ForgotPasswordInstructionNavProp = StackNavigationProp<
  AuthStackParamList,
  'ForgotPasswordInstruction'
>;

const isWeb = Platform.OS === 'web';
const GMAIL_URL = 'https://mail.google.com/mail/u/1/#inbox';

export default function ForgotPasswordInstruction(): JSX.Element {
  const navigation = useNavigation<ForgotPasswordInstructionNavProp>();

  const { locale, onChangeLocale } = useAppLocale({
    onReload: () => navigation.replace('ForgotPasswordInstruction'),
  });

  /**
   *  On web, open the gmail link in a new tab
   *  On mobile, open the prefered inbox app
   */
  const handleOpenEmail = (): {
    text: string;
    onPress: () => void;
  } => {
    if (isWeb) {
      return {
        text: 'forgotPasswordInstruction.openGmail',
        onPress: () => window.open(GMAIL_URL, '_blank'),
      };
    }

    return {
      text: 'forgotPasswordInstruction.openEmail',
      onPress: () => openInbox(),
    };
  };

  return (
    <ForgotPasswordInstructionLayout
      selectedLocale={locale}
      onBack={() => navigation.goBack()}
      onOpenEmailConfig={handleOpenEmail()}
      onSkip={() => navigation.pop(2)}
      onSelectedLocale={onChangeLocale}
    />
  );
}
