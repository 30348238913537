import React, { useState } from 'react';
import { useTheme } from 'styled-components/native';
import Toolbar from '~/components/Toolbar';
import { UserGroup } from '~/data/models/group';
import { Team } from '~/data/models/team';
import { t } from '~/utils/i18n';
import { Container, TextInput } from './style';

type CommentInputFieldProps = {
  testID?: string;
  value: string;
  placeholder?: string;
  loading: boolean;
  lockedTeamId?: string;
  authUserGroup?: UserGroup | null;
  teams: Team[];
  hideTeams?: boolean;
  btnText?: string;
  onChangeText: (text: string) => void;
  onPostComment: (authorTeamId?: string) => void;
};

export default function CommentInputField({
  testID = 'InputField',
  value,
  loading,
  lockedTeamId,
  authUserGroup,
  teams,
  hideTeams = false,
  placeholder,
  btnText,
  onChangeText,
  onPostComment,
}: CommentInputFieldProps): JSX.Element {
  const theme = useTheme();
  const [authorTeamId, setAuthorTeamId] = useState<string | undefined>(
    lockedTeamId,
  );
  const createPostEnabled = value !== '';

  const onPost = () => {
    if (createPostEnabled) {
      onPostComment(authorTeamId);
    }
  };

  return (
    <Container testID={`${testID}:CommentInputField`}>
      <TextInput
        placeholderTextColor={theme.color.base.c7}
        placeholder={placeholder}
        value={value}
        multiline
        blurOnSubmit
        autoFocus
        clearButtonMode={'always'}
        autoCapitalize={'none'}
        onChangeText={onChangeText}
      />
      <Toolbar
        testID={`${testID}:toolbarInput`}
        postButtonText={btnText || t('post.comment')}
        authorTeamId={authorTeamId}
        userGroup={!lockedTeamId ? authUserGroup : undefined}
        teams={!hideTeams ? teams : []}
        createEnabled={createPostEnabled}
        loading={loading}
        onAuthorTeamChange={setAuthorTeamId}
        onCreate={onPost}
      />
    </Container>
  );
}
