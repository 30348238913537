import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components/native';
import AdminHeader from '~/components/AdminHeader';
import Avatar from '~/components/Avatar';
import Button from '~/components/Button';
import CountryTypePicker from '~/components/CountryPicker';
import InputField, { InputFieldHandle } from '~/components/InputField';
import {
  TableCell,
  TableRow,
  InstituteName,
  InstituteId,
  InstituteIconContainer,
  InstituteIcon,
  InstituteTable,
} from '~/components/InstituteDatabase/style';
import SchoolTypePicker from '~/components/SchoolTypePicker';
import { WebContainer } from '~/components/WebGrid';
import WebModalConfirm, {
  WebModalConfirmHandler,
} from '~/components/WebModalConfirm';
import {
  Container,
  FormContainer,
  SectionLabel,
  FlexRowCentered,
  Spacer,
  GrownFlex,
  FlexRow,
  Spinner,
} from '~/components/common/style.web';
import { COUNTRIES } from '~/data/constants';
import { Institute, InstituteType } from '~/data/models/institute';
import { InstituteRequest } from '~/data/models/instituteRequest';
import { CountryInfo } from '~/data/models/marketProfile';
import { AdminAcceptInstituteRequestInput } from '~/data/operations/global';
import { t } from '~/utils/i18n';
import {
  InputWrapper,
  InputFormWrapper,
  Name,
  Email,
  TableRowHeader,
  RightSideWrapper,
  SearchDuplicates,
  SearchInput,
  InputActionsWrapper,
} from './style';

type InstituteRequestLayoutProps = {
  onBack: () => void;
  instituteRequest?: InstituteRequest;
  duplicateInstitutes: Institute[];
  instituteTypes: InstituteType[];
  openDrawer: () => void;
  requestLoading?: boolean;
  duplicatesLoading?: boolean;
  onDeclineInstituteRequestPress: (
    id: string,
    existingInstitute: string,
  ) => void;
  onAcceptInstituteRequest: (input: AdminAcceptInstituteRequestInput) => void;
  onDeleteInstituteRequest: (id: string) => void;
  setInstitutesSearch: (search: string) => void;
  institutesSearch: string;
};

export default function InstituteRequestLayout({
  onBack,
  instituteRequest,
  duplicateInstitutes,
  openDrawer,
  instituteTypes,
  requestLoading = false,
  duplicatesLoading = false,
  onDeclineInstituteRequestPress,
  onAcceptInstituteRequest,
  onDeleteInstituteRequest,
  setInstitutesSearch,
  institutesSearch,
}: InstituteRequestLayoutProps): JSX.Element {
  const theme = useTheme();
  const isDesktop = useMediaQuery({ minWidth: theme.breakpoints.desktopMin });
  const [searchValue, setSearchValue] = useState(institutesSearch);

  // ____FORM REFS____
  const instituteNameRef = useRef<InputFieldHandle>(null);
  const instituteStreetRef = useRef<InputFieldHandle>(null);
  const instituteZipRef = useRef<InputFieldHandle>(null);
  const instituteCityRef = useRef<InputFieldHandle>(null);
  const instituteWebsiteRef = useRef<InputFieldHandle>(null);
  const confirmModalRef = useRef<WebModalConfirmHandler>(null);
  const deleteRequestModalRef = useRef<WebModalConfirmHandler>(null);

  // ____FORM STATE____
  const [instituteName, setInstituteName] = useState<string>('');
  const [instituteStreet, setInstituteStreet] = useState<string>('');
  const [instituteZip, setInstituteZip] = useState<string>('');
  const [instituteCity, setInstituteCity] = useState<string>('');
  const [instituteWebsite, setInstituteWebsite] = useState<string>('');

  const [instituteCountry, setInstituteCountry] = useState<
    CountryInfo | undefined
  >(undefined);

  const [instituteType, setInstituteType] = useState<InstituteType | undefined>(
    undefined,
  );

  useEffect(() => {
    if (!instituteRequest) {
      return;
    }

    setInstituteName(instituteRequest.name || '');
    setInstituteStreet(instituteRequest.street || '');
    setInstituteZip(instituteRequest.zip || '');
    setInstituteCity(instituteRequest.city || '');
    setInstituteWebsite(instituteRequest.website || '');
    setInstituteCountry(() => {
      const countryCodes = Object.keys(COUNTRIES);
      if (countryCodes.includes(instituteRequest.country)) {
        return COUNTRIES[instituteRequest.country as 'DE' | 'AT' | 'TR'];
      }

      return COUNTRIES.DE;
    });

    setInstituteType(
      instituteTypes.find((type) => type.id === instituteRequest.type.id),
    );
  }, [instituteRequest]);

  const onSubmitChanges = () => {
    if (!instituteRequest) {
      return;
    }

    const data: AdminAcceptInstituteRequestInput = {
      name: instituteName,
      country: instituteCountry?.country || instituteRequest.country,
      city: instituteCity,
      street: instituteStreet,
      website: instituteWebsite,
      zip: instituteZip,
      type: instituteType?.id || instituteRequest.type.id,
    };

    onAcceptInstituteRequest(data);
  };

  const renderTableRow = ({ item: institute }: { item: Institute }) => (
    <TableRow testID={`DuplicateRow:${institute.id}`}>
      <InstituteIconContainer>
        <InstituteIcon />
      </InstituteIconContainer>
      <TableCell>
        <InstituteName>{institute.name}</InstituteName>
        <InstituteId>
          {t(`adminPanelSearch.flags.${institute.country}`, {
            defaults: [
              {
                message: t(`adminPanelSearch.flags.DE`),
              },
            ],
          })}
          &nbsp;
          {institute.internalId}
        </InstituteId>
      </TableCell>
      <TableCell>
        <TableRowHeader>{institute.zip}</TableRowHeader>
      </TableCell>
      <TableCell>
        <Button
          text={t('instituteRequest.decline')}
          state={'default'}
          size={'sm'}
          type={'destructive'}
          onPress={() => confirmModalRef.current?.open()}
        />
      </TableCell>
      <WebModalConfirm
        onConfirm={() => {
          onDeclineInstituteRequestPress(
            instituteRequest?.id as string,
            institute.id,
          );
          confirmModalRef.current?.close();
        }}
        onCancel={() => confirmModalRef.current?.close()}
        title={t('instituteRequest.instituteRequestDeclineModal')}
        description={t('instituteRequest.description')}
        ref={confirmModalRef}
        icon="settings"
      />
    </TableRow>
  );

  if (requestLoading) {
    return (
      <Container centerContent>
        <Spinner testID="loadingSpinner" />
      </Container>
    );
  }

  return (
    <Container>
      <AdminHeader
        onOpenDrawer={openDrawer}
        onBack={onBack}
        title={t('screens.instituteRequest')}
        enableSaveChanges
        onSubmitClick={onSubmitChanges}
        breadcrumbs={['Institutes', 'InstituteRequest']}
        buttonTitle={t('adminPanelSearch.approveRequest')}
      />
      <WebContainer isDesktop={isDesktop}>
        <FormContainer isDesktop={isDesktop}>
          {/* REQUESTED BY WHO */}
          <GrownFlex>
            <SectionLabel>
              {t('adminPanelInstitutes.requestedBySection')}
            </SectionLabel>
            <Spacer v={24} />
            <FlexRowCentered>
              <Avatar uri={instituteRequest?.user.avatar} size={46} />
              <Spacer h={32} />
              <View>
                <FlexRowCentered>
                  <Name>{`${instituteRequest?.user.firstName} ${instituteRequest?.user.lastName}`}</Name>
                </FlexRowCentered>
                <Email>{instituteRequest?.user.email}</Email>
              </View>
            </FlexRowCentered>
          </GrownFlex>
          <Spacer v={40} />

          <FlexRow isDesktop={isDesktop}>
            {/* LEFT SIDE */}
            <GrownFlex>
              <SectionLabel>
                {t('adminPanelInstitutes.instituteDetails')}
              </SectionLabel>
              <Spacer v={24} />
              <InputFormWrapper>
                <InputField
                  ref={instituteNameRef}
                  testID={'inputName'}
                  iconName={'users-01'}
                  label={t('instituteRequest.instituteName')}
                  placeholder={t('createGroup.namePlaceholder')}
                  value={instituteName}
                  onChangeText={(value) => setInstituteName(value)}
                  blurOnSubmit={false}
                  returnKeyType={'next'}
                />
                <InputField
                  ref={instituteStreetRef}
                  testID={'inputStreet'}
                  iconName={'users-01'}
                  label={t('instituteRequest.street')}
                  placeholder={t('createGroup.namePlaceholder')}
                  value={instituteStreet}
                  onChangeText={(value) => setInstituteStreet(value)}
                  blurOnSubmit={false}
                  returnKeyType={'next'}
                />
                <FlexRow isDesktop>
                  <InputWrapper>
                    <InputField
                      ref={instituteZipRef}
                      testID={'inputZip'}
                      iconName={'users-01'}
                      label={t('instituteRequest.zip')}
                      placeholder={t('createGroup.namePlaceholder')}
                      value={instituteZip}
                      onChangeText={(value) => setInstituteZip(value)}
                      blurOnSubmit={false}
                      returnKeyType={'next'}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <InputField
                      ref={instituteCityRef}
                      testID={'inputCity'}
                      iconName={'users-01'}
                      label={t('instituteRequest.city')}
                      placeholder={t('createGroup.namePlaceholder')}
                      value={instituteCity}
                      onChangeText={(value) => setInstituteCity(value)}
                      blurOnSubmit={false}
                      returnKeyType={'next'}
                    />
                  </InputWrapper>
                </FlexRow>

                <FlexRow isDesktop>
                  <InputWrapper>
                    <CountryTypePicker
                      testID={'pickerSchool'}
                      toggleTestID={'buttonPickerToggle'}
                      label={t('adminPanelInstitutes.country')}
                      selectedCountryType={instituteCountry}
                      initialShowPicker={false}
                      onSelectedCountryType={(countryType) =>
                        setInstituteCountry(countryType as CountryInfo)
                      }
                    />
                  </InputWrapper>
                </FlexRow>

                <Spacer v={20} />

                <FlexRow isDesktop>
                  <InputWrapper>
                    <SchoolTypePicker
                      label={t('adminPanelInstitutes.instituteType')}
                      schoolTypes={instituteTypes}
                      selectedSchoolType={instituteType}
                      initialShowPicker={false}
                      onSelectedSchoolType={(instituteType) =>
                        setInstituteType(instituteType as InstituteType)
                      }
                    />
                  </InputWrapper>
                </FlexRow>

                <Spacer v={25} />

                <FlexRow isDesktop>
                  <InputWrapper>
                    <InputField
                      ref={instituteWebsiteRef}
                      testID={'inputWebsite'}
                      iconName={'users-01'}
                      label={t('instituteRequest.website')}
                      placeholder={t('createGroup.namePlaceholder')}
                      value={instituteWebsite}
                      onChangeText={(value) => setInstituteWebsite(value)}
                      blurOnSubmit={false}
                      returnKeyType={'next'}
                    />
                  </InputWrapper>
                </FlexRow>
              </InputFormWrapper>
              <Spacer v={61} />
              <SectionLabel>
                {t('adminPanelInstitutes.criticalAction')}
              </SectionLabel>
              <Spacer v={24} />
              <InputActionsWrapper>
                <Button
                  testID="deleteRequestBtn"
                  icon="left"
                  iconName="trash-01"
                  text={t('adminPanelInstitutes.declineRequest')}
                  size={'sm'}
                  type={'destructive'}
                  onPress={() => deleteRequestModalRef.current?.open()}
                />
              </InputActionsWrapper>
            </GrownFlex>

            <Spacer h={48} v={isDesktop ? 0 : 12} />

            {/* RIGHT SIDE */}
            <GrownFlex>
              <RightSideWrapper>
                <SearchDuplicates>
                  <SectionLabel>
                    {t('adminPanelInstitutes.possibleDuplicates')}
                  </SectionLabel>
                  <SearchInput
                    testID={'instituteSearchInput'}
                    iconName={'search-md'}
                    value={searchValue}
                    onChangeText={(query) => {
                      setSearchValue(query);
                      setInstitutesSearch(query);
                    }}
                    placeholder={t('g.search')}
                    size={'small'}
                  />
                </SearchDuplicates>
                <Spacer v={20} />
                {duplicatesLoading ? (
                  <Spinner testID="duplicatesSpinner" />
                ) : (
                  <InstituteTable<React.ElementType>
                    data={duplicateInstitutes}
                    renderItem={renderTableRow}
                    keyExtractor={(institute: Institute) =>
                      `institute-${institute.id}`
                    }
                    ListEmptyComponent={
                      <Email>{t('adminPanelInstitutes.noDuplicates')}</Email>
                    }
                  />
                )}
              </RightSideWrapper>
            </GrownFlex>
          </FlexRow>
          {!isDesktop && (
            <Button
              testID="approveRequestBtn"
              text={t('adminPanelSearch.approveRequest')}
              onPress={onSubmitChanges}
              state={'activeWeb'}
              size={'lg'}
            />
          )}
        </FormContainer>
      </WebContainer>

      <WebModalConfirm
        title={t('adminPanelInstitutes.deleteRequestModalTitle')}
        description={t('adminPanelInstitutes.deleteRequestModalDescription')}
        icon="settings"
        onCancel={() => deleteRequestModalRef.current?.close()}
        ref={deleteRequestModalRef}
        onConfirm={() => {
          onDeleteInstituteRequest(instituteRequest?.id ?? '');
          deleteRequestModalRef.current?.close();
        }}
        buttonText={t('adminPanelInstitutes.deleteRequestModalBtn')}
        confirmButtonTestId="confirmBtn"
      />
    </Container>
  );
}
