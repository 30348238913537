import React from 'react';
import {
  CompositeNavigationProp,
  useNavigation,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useAuth } from '~/context/auth';
import { RootStackParamList, YearbookStackParamList } from '~/navigation/types';
import ExportingLayout from './layout';

type ExportingNavProp = CompositeNavigationProp<
  StackNavigationProp<YearbookStackParamList, 'Exporting'>,
  StackNavigationProp<RootStackParamList>
>;

export default function Exporting(): JSX.Element {
  const navigation = useNavigation<ExportingNavProp>();

  const { authUserId } = useAuth();

  const openBannersInBrowser = (url: string) => {
    navigation.navigate('Browser', {
      title: 'Banner',
      url,
    });
  };

  return (
    <ExportingLayout
      onBack={() => navigation.pop(2)}
      openBannersInBrowser={openBannersInBrowser}
      onViewHistory={() => navigation.navigate('ExportHistory')}
      authUserId={authUserId}
    />
  );
}
