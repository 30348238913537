/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * An enumeration.
 */
export enum AdvertisementsAssetChannelTypeChoices {
  BANNER_ADS = 'BANNER_ADS',
  CAREER_POST = 'CAREER_POST',
  SPONSORED_EVENTS = 'SPONSORED_EVENTS',
  SPONSORED_POLL = 'SPONSORED_POLL',
  SPONSORED_POST = 'SPONSORED_POST',
  SPONSORED_QUIZ = 'SPONSORED_QUIZ',
}

/**
 * An enumeration.
 */
export enum AllowedVoteType {
  EVERYBODY = 'EVERYBODY',
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  OTHER = 'OTHER',
}

/**
 * An enumeration.
 */
export enum AnswerType {
  COUPLE = 'COUPLE',
  LIST = 'LIST',
  PERSON = 'PERSON',
}

/**
 * An enumeration.
 */
export enum ApprovalSetting {
  ANY_MEMBER = 'ANY_MEMBER',
  AUTO_APPROVAL = 'AUTO_APPROVAL',
  ONLY_ADMIN = 'ONLY_ADMIN',
}

/**
 * An enumeration.
 */
export enum AssetVisibilityType {
  LIVE = 'LIVE',
  OFFLINE = 'OFFLINE',
  SCHEDULE = 'SCHEDULE',
}

/**
 * An enumeration.
 */
export enum AuthorType {
  GROUP = 'GROUP',
  TEAM = 'TEAM',
  USER = 'USER',
}

/**
 * An enumeration.
 */
export enum CampaignStatus {
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',
  INACTIVE = 'INACTIVE',
}

/**
 * An enumeration.
 */
export enum CampaignType {
  EXTERNAL = 'EXTERNAL',
  INTERNAL = 'INTERNAL',
}

/**
 * An enumeration.
 */
export enum ChannelType {
  BANNER_ADS = 'BANNER_ADS',
  CAREER_POST = 'CAREER_POST',
  SPONSORED_EVENTS = 'SPONSORED_EVENTS',
  SPONSORED_POLL = 'SPONSORED_POLL',
  SPONSORED_POST = 'SPONSORED_POST',
  SPONSORED_QUIZ = 'SPONSORED_QUIZ',
}

/**
 * An enumeration.
 */
export enum EventType {
  EVENT = 'EVENT',
  MEETING = 'MEETING',
  PARTY = 'PARTY',
}

export enum EventTypeEnum {
  CLICK = 'CLICK',
  VIEW = 'VIEW',
}

/**
 * An enumeration.
 */
export enum EventsEventAuthorTypeChoices {
  GROUP = 'GROUP',
  TEAM = 'TEAM',
  USER = 'USER',
}

/**
 * An enumeration.
 */
export enum GroupsUserGroupRoleChoices {
  STUDENT = 'STUDENT',
  TEACHER = 'TEACHER',
}

/**
 * An enumeration.
 */
export enum GroupsUserGroupStatusChoices {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export enum InstituteActivityTypeEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

/**
 * An enumeration.
 */
export enum InstituteTargetingActivityEnum {
  ACTIVE = 'ACTIVE',
  EMPTY = 'EMPTY',
  INACTIVE = 'INACTIVE',
}

/**
 * An enumeration.
 */
export enum InstitutesCityLocationCountryChoices {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW',
}

/**
 * An enumeration.
 */
export enum InstitutesInstituteCountryChoices {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW',
}

/**
 * An enumeration.
 */
export enum InstitutesInstituteRequestCountryChoices {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW',
}

/**
 * An enumeration.
 */
export enum InstitutesInstituteTypeCountryChoices {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW',
}

/**
 * An enumeration.
 */
export enum Language {
  ENGLISH = 'ENGLISH',
  GERMAN = 'GERMAN',
  TURKISH = 'TURKISH',
}

/**
 * An enumeration.
 */
export enum ModuleType {
  COLLAGES = 'COLLAGES',
  CUSTOM_PAGES = 'CUSTOM_PAGES',
  FOREWORD = 'FOREWORD',
  PREWORD = 'PREWORD',
  PROFILE_PAGE = 'PROFILE_PAGE',
  QUOTES = 'QUOTES',
  RANKINGS = 'RANKINGS',
  REPORTS = 'REPORTS',
}

/**
 * An enumeration.
 */
export enum Owner {
  TEAM = 'TEAM',
  USER = 'USER',
}

/**
 * An enumeration.
 */
export enum PageFormat {
  CUSTOM = 'CUSTOM',
  LANDSCAPE = 'LANDSCAPE',
  PORTRAIT = 'PORTRAIT',
}

/**
 * An enumeration.
 */
export enum PageQuality {
  CRITICAL = 'CRITICAL',
  GOOD = 'GOOD',
  POOR = 'POOR',
}

/**
 * An enumeration.
 */
export enum PageType {
  IMAGE = 'IMAGE',
  PDF = 'PDF',
}

/**
 * An enumeration.
 */
export enum PhotoFormat {
  AS_UPLOADED = 'AS_UPLOADED',
  PORTRAIT = 'PORTRAIT',
  SQUARE = 'SQUARE',
}

/**
 * An enumeration.
 */
export enum Platform {
  CONTENTFUL = 'CONTENTFUL',
}

/**
 * An enumeration.
 */
export enum Status {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

/**
 * An enumeration.
 */
export enum TargetingFilterGroupMembersOperation {
  BELOW = 'BELOW',
  EXACTLY = 'EXACTLY',
  LARGER_THAN = 'LARGER_THAN',
}

/**
 * An enumeration.
 */
export enum TargetingFilterUserGenderOperation {
  DIVERSE = 'DIVERSE',
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  NOTSET = 'NOTSET',
}

/**
 * An enumeration.
 */
export enum TargetingFilterUserRegistrationOperation {
  BEFORE = 'BEFORE',
  EXACTLY = 'EXACTLY',
  LATER_THAN = 'LATER_THAN',
}

/**
 * An enumeration.
 */
export enum TeamType {
  CLOTHING = 'CLOTHING',
  CORE = 'CORE',
  FINANCE = 'FINANCE',
  OTHER = 'OTHER',
  PROM = 'PROM',
  YEARBOOK = 'YEARBOOK',
}

/**
 * An enumeration.
 */
export enum UserType {
  STUDENT = 'STUDENT',
  TEACHER = 'TEACHER',
}

/**
 * An enumeration.
 */
export enum UsersUserGenderChoices {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  OTHER = 'OTHER',
}

export enum VariantTypeEnum {
  LARGE = 'LARGE',
  SMALL = 'SMALL',
}

export interface AcceptUserInGroupInput {
  groupId: string;
  userId: string;
  accept: boolean;
  clientMutationId?: string | null;
}

export interface AcceptUserInTeamInput {
  teamId: string;
  userId: string;
  accept: boolean;
  clientMutationId?: string | null;
}

export interface ActivateTeamInput {
  id: string;
  clientMutationId?: string | null;
}

export interface AddImagesToPostInput {
  id: string;
  images?: any | null;
  clientMutationId?: string | null;
}

export interface AddPhotoToCollagesAlbumInput {
  id: string;
  photo?: any | null;
  clientMutationId?: string | null;
}

export interface AddUserToGroupInput {
  groupId: string;
  userId: string;
  role: string;
  avatar?: any | null;
  defaultAvatarId?: string | null;
  token?: string | null;
  clientMutationId?: string | null;
}

export interface AddUserToTeamInput {
  teamId: string;
  userId: string;
  clientMutationId?: string | null;
}

export interface AdminAcceptInstituteRequestInput {
  name: string;
  country: string;
  street?: string | null;
  zip?: string | null;
  city?: string | null;
  type?: string | null;
  website?: string | null;
  id?: string | null;
  clientMutationId?: string | null;
}

export interface AdminAddUserToTeamInput {
  teamId: string;
  userId: string;
  clientMutationId?: string | null;
}

export interface AdminClearUserFlagsInput {
  id: string;
  clientMutationId?: string | null;
}

export interface AdminDeleteInstituteRequestInput {
  id: string;
  clientMutationId?: string | null;
}

export interface AdminDeleteUserInput {
  id: string;
  clientMutationId?: string | null;
}

export interface AdminDowngradeUserInput {
  id: string;
  clientMutationId?: string | null;
}

export interface AdminForgetPasswordLinkInput {
  id: string;
  clientMutationId?: string | null;
}

export interface AdminRejectInstituteRequestInput {
  id: string;
  existingInstitute: string;
  clientMutationId?: string | null;
}

export interface AdminUpdateInstituteInput {
  id?: string | null;
  name: string;
  street?: string | null;
  zip?: string | null;
  isPrivate?: boolean | null;
  country: string;
  city?: string | null;
  location?: string | null;
  marketProfileName: string;
  instituteTypeName: string;
  website?: string | null;
  type?: string | null;
  marketProfile?: string | null;
  clientMutationId?: string | null;
}

export interface AdminUpdateUserInput {
  id: string;
  firstName: string;
  lastName: string;
  gender?: string | null;
  email: string;
  phoneCountryCode?: string | null;
  phone?: string | null;
  communicationLanguage?: string | null;
  isActive?: boolean | null;
  optIn?: boolean | null;
  clientMutationId?: string | null;
}

export interface AdminUpgradeUserInput {
  id: string;
  toSuperuser?: boolean | null;
  clientMutationId?: string | null;
}

export interface BlockUserMutationInput {
  id?: string | null;
  clientMutationId?: string | null;
}

export interface CommentPostInput {
  id: string;
  text: string;
  owner?: Owner | null;
  team?: string | null;
  clientMutationId?: string | null;
}

export interface CreateAssetEventInput {
  assetId: string;
  userId: string;
  variant?: VariantTypeEnum | null;
  eventType: EventTypeEnum;
  clientMutationId?: string | null;
}

export interface CreateBannerAssetInput {
  campaign: string;
  assetLargeImage: any;
  assetSmallImage: any;
  trackingUrl: string;
  targetingFilters?: (TargetingFilterInput | null)[] | null;
  clientMutationId?: string | null;
}

export interface CreateCampaignInput {
  name: string;
  utm: string;
  startDate: any;
  endDate: any;
  type: string;
  customer?: string | null;
  orderId?: string | null;
  totalViews?: number | null;
  totalClicks?: number | null;
  isDeleted?: boolean | null;
  clientMutationId?: string | null;
}

export interface CreateCollagesAlbumInput {
  name: string;
  moduleInstance: string;
  clientMutationId?: string | null;
}

export interface CreateCustomListInput {
  moduleId: string;
  title: string;
  options?: (string | null)[] | null;
  clientMutationId?: string | null;
}

export interface CreateCustomListOptionInput {
  customListId: string;
  text: string;
  clientMutationId?: string | null;
}

export interface CreateCustomPagesInstanceInput {
  moduleInstance: string;
  pageFormat: string;
  title: string;
  pageType?: string | null;
  pageQuality?: string | null;
  page?: any | null;
  clientMutationId?: string | null;
}

export interface CreateDataExportInput {
  groupId: string;
  exportCollages?: boolean | null;
  exportProfilePages?: boolean | null;
  exportQuotes?: boolean | null;
  exportRankings?: boolean | null;
  exportReports?: boolean | null;
  onlyAgent?: boolean | null;
  clientMutationId?: string | null;
}

export interface CreateEventInput {
  title: string;
  bannerImage?: string | null;
  startDateTime: any;
  endDateTime: any;
  isAllDay?: boolean | null;
  eventType: string;
  location: string;
  description: string;
  authorUser?: string | null;
  authorGroup?: string | null;
  authorTeam?: string | null;
  authorType: string;
  isJoinable?: boolean | null;
  isPublic?: boolean | null;
  isSponsored?: boolean | null;
  companyName?: string | null;
  companyLogo?: string | null;
  ctaLink?: string | null;
  clientMutationId?: string | null;
}

export interface CreateGroupInput {
  name: string;
  type?: string | null;
  year: number;
  institute: string;
  marketProfile?: string | null;
  role: string;
  clientMutationId?: string | null;
}

export interface CreateInstituteInput {
  name: string;
  street?: string | null;
  zip?: string | null;
  isPrivate?: boolean | null;
  country: string;
  city?: string | null;
  location?: string | null;
  marketProfileName: string;
  instituteTypeName: string;
  website?: string | null;
  clientMutationId?: string | null;
}

export interface CreateModuleInstanceInput {
  isActive?: boolean | null;
  dueDate?: any | null;
  module: string;
  group: string;
  clientMutationId?: string | null;
}

export interface CreatePollInput {
  owner: string;
  team?: string | null;
  group: string;
  question: string;
  deadline: any;
  answersAllowed: number;
  randomizeOptions?: boolean | null;
  allowRevote?: boolean | null;
  allowComments?: boolean | null;
  anonymousVotes?: boolean | null;
  privateResults?: boolean | null;
  clientMutationId?: string | null;
}

export interface CreatePollOptionInput {
  poll: string;
  image?: any | null;
  text: string;
  clientMutationId?: string | null;
}

export interface CreatePollVoteInput {
  pollOption: string;
  clientMutationId?: string | null;
}

export interface CreatePostInput {
  owner: string;
  team?: string | null;
  group: string;
  text: string;
  clientMutationId?: string | null;
}

export interface CreateProfilePageAnswerInput {
  text?: string | null;
  profilePageQuestion: string;
  profilePageQuestionOption?: string | null;
  clientMutationId?: string | null;
}

export interface CreateProfilePageCommentInput {
  text: string;
  profileUserGroup: string;
  moduleInstance: string;
  clientMutationId?: string | null;
}

export interface CreateProfilePagePhotoCategoryInput {
  name?: string | null;
  moduleInstance: string;
  clientMutationId?: string | null;
}

export interface CreateProfilePagePhotoInput {
  profilePagePhotoCategory: string;
  image?: any | null;
  clientMutationId?: string | null;
}

export interface CreateProfilePageQuestionInput {
  text: string;
  moduleInstance: string;
  clientMutationId?: string | null;
}

export interface CreateQuotesInstanceInput {
  quote: string;
  moduleInstance: string;
  clientMutationId?: string | null;
}

export interface CreateRankingsAnswerInput {
  rankingsQuestion: string;
  votee1UserGroup?: string | null;
  votee2UserGroup?: string | null;
  voteeListOption?: string | null;
  clientMutationId?: string | null;
}

export interface CreateRankingsQuestionInput {
  question: string;
  answerType: string;
  allowedVotes?: string | null;
  onlyOppositeGender?: boolean | null;
  moduleInstance: string;
  customList?: string | null;
  clientMutationId?: string | null;
}

export interface CreateReportsInstanceInput {
  title: string;
  text: string;
  editTeam?: string | null;
  viewTeam?: string | null;
  maxChars: number;
  moduleInstance: string;
  clientMutationId?: string | null;
}

export interface CreateTeamInput {
  name: string;
  approvalSetting?: string | null;
  color: string;
  icon: string;
  group: string;
  clientMutationId?: string | null;
}

export interface CreateUserInput {
  firstName: string;
  lastName: string;
  gender?: string | null;
  email: string;
  phoneCountryCode?: string | null;
  phone?: string | null;
  password: string;
  passwordConfirmation: string;
  dataPolicyAcceptance: boolean;
  dataPolicyVersion: string;
  termsAcceptance: boolean;
  termsVersion: string;
  subscribeNewsletter?: boolean | null;
  communicationLanguage?: string | null;
  countryOfResidence?: string | null;
  referredBy?: string | null;
  inviteCode?: string | null;
  clientMutationId?: string | null;
}

export interface DashboardFilterInput {
  country?: (string | null)[] | null;
  graduationYear?: (number | null)[] | null;
  instituteType?: (string | null)[] | null;
  graduationType?: (string | null)[] | null;
  fromDate?: any | null;
  toDate?: any | null;
}

export interface DeleteAssetInput {
  assetId: string;
  clientMutationId?: string | null;
}

export interface DeleteCampaignInput {
  id: string;
  clientMutationId?: string | null;
}

export interface DeleteCommentInput {
  id: string;
  clientMutationId?: string | null;
}

export interface DeleteCustomListInput {
  id: string;
  clientMutationId?: string | null;
}

export interface DeleteCustomListOptionInput {
  id: string;
  clientMutationId?: string | null;
}

export interface DeleteEventInput {
  id: string;
  clientMutationId?: string | null;
}

export interface DeletePollInput {
  id: string;
  clientMutationId?: string | null;
}

export interface DeletePollVoteInput {
  pollOptionId: string;
  clientMutationId?: string | null;
}

export interface DeletePostInput {
  id: string;
  clientMutationId?: string | null;
}

export interface DeleteUserInput {
  id: string;
  clientMutationId?: string | null;
}

export interface DestroyCollagesAlbumInput {
  id: string;
  clientMutationId?: string | null;
}

export interface DestroyCustomPagesInstanceInput {
  id: string;
  clientMutationId?: string | null;
}

export interface DestroyModuleInstanceInput {
  id: string;
  clientMutationId?: string | null;
}

export interface DestroyProfilePageAnswerInput {
  id: string;
  clientMutationId?: string | null;
}

export interface DestroyProfilePageCommentInput {
  id: string;
  clientMutationId?: string | null;
}

export interface DestroyProfilePagePhotoCategoryInput {
  id: string;
  clientMutationId?: string | null;
}

export interface DestroyProfilePagePhotoInput {
  id: string;
  clientMutationId?: string | null;
}

export interface DestroyProfilePageQuestionInput {
  id: string;
  clientMutationId?: string | null;
}

export interface DestroyQuotesInstanceInput {
  id: string;
  clientMutationId?: string | null;
}

export interface DestroyRankingsQuestionInput {
  id: string;
  clientMutationId?: string | null;
}

export interface DestroyReportsInstanceInput {
  id: string;
  clientMutationId?: string | null;
}

export interface DestroyTeamInput {
  id: string;
  clientMutationId?: string | null;
}

export interface DuplicateAssetInput {
  assetId: string;
  clientMutationId?: string | null;
}

export interface EditBannerAdAssetInput {
  id: string;
  visibility?: AssetVisibilityType | null;
  startDate?: any | null;
  endDate?: any | null;
  assetLargeImage?: any | null;
  assetSmallImage?: any | null;
  targetingFilters?: (TargetingFilterInput | null)[] | null;
  trackingUrl?: string | null;
  clientMutationId?: string | null;
}

export interface EditCampaignInput {
  id: string;
  name: string;
  utm: string;
  startDate: any;
  endDate: any;
  type: string;
  customer?: string | null;
  orderId?: string | null;
  isDeleted?: boolean | null;
  clientMutationId?: string | null;
}

export interface EditEventInput {
  id: string;
  title: string;
  bannerImage?: string | null;
  startDateTime: any;
  endDateTime: any;
  isAllDay?: boolean | null;
  eventType: string;
  location?: string | null;
  description?: string | null;
  authorUser?: string | null;
  authorGroup?: string | null;
  authorTeam?: string | null;
  authorType: string;
  isJoinable?: boolean | null;
  isPublic?: boolean | null;
  isSponsored?: boolean | null;
  companyName?: string | null;
  companyLogo?: string | null;
  ctaLink?: string | null;
  clientMutationId?: string | null;
}

export interface ForgetPasswordInput {
  email: string;
  clientMutationId?: string | null;
}

export interface GenerateShopifyRedirectUrlInput {
  returnUrl?: string | null;
  clientMutationId?: string | null;
}

export interface ImportToErpInput {
  userId: string;
  groupId: string;
  instituteId: string;
  clientMutationId?: string | null;
}

export interface JoinEventInput {
  eventId: string;
  clientMutationId?: string | null;
}

export interface LeaveEventInput {
  eventId: string;
  clientMutationId?: string | null;
}

export interface LikeCollagesPhotoInput {
  id: string;
  clientMutationId?: string | null;
}

export interface LikeCommentInput {
  id: string;
  clientMutationId?: string | null;
}

export interface LikePostInput {
  id: string;
  clientMutationId?: string | null;
}

export interface LikeQuotesInstanceInput {
  id: string;
  clientMutationId?: string | null;
}

export interface MoveGroupToInstituteInput {
  groupId: string;
  instituteId: string;
  clientMutationId?: string | null;
}

export interface MoveProfilePageQuestionToPositionInput {
  id: string;
  newPosition: number;
  clientMutationId?: string | null;
}

export interface ObtainJSONWebTokenInput {
  email: string;
  password: string;
  clientMutationId?: string | null;
}

export interface RemoveExternalEventInput {
  eventId: string;
  clientMutationId?: string | null;
}

export interface RemoveLikeFromCollagesPhotoInput {
  id: string;
  clientMutationId?: string | null;
}

export interface RemoveLikeFromCommentInput {
  id: string;
  clientMutationId?: string | null;
}

export interface RemoveLikeFromPostInput {
  id: string;
  clientMutationId?: string | null;
}

export interface RemoveLikeFromQuotesInstanceInput {
  id: string;
  clientMutationId?: string | null;
}

export interface RemovePhotoFromCollagesAlbumInput {
  id: string;
  clientMutationId?: string | null;
}

export interface RemoveUserFromGroupInput {
  groupId: string;
  userId: string;
  clientMutationId?: string | null;
}

export interface RemoveUserFromTeamInput {
  teamId: string;
  userId: string;
  clientMutationId?: string | null;
}

export interface ReportMutationInput {
  id?: string | null;
  clientMutationId?: string | null;
}

export interface ReportPostContentInput {
  id?: string | null;
  clientMutationId?: string | null;
}

export interface RequestMissingInstituteInput {
  name: string;
  country: string;
  street?: string | null;
  zip?: string | null;
  city?: string | null;
  type?: string | null;
  website?: string | null;
  id?: string | null;
  clientMutationId?: string | null;
}

export interface ResetPasswordInput {
  password: string;
  passwordConfirmation: string;
  token: string;
  clientMutationId?: string | null;
}

export interface SaveExternalEventInput {
  eventId: string;
  clientMutationId?: string | null;
}

export interface SetEventBannerInput {
  id: string;
  bannerImage?: any | null;
  clientMutationId?: string | null;
}

export interface SetGroupAvatarInput {
  id: string;
  avatar?: any | null;
  iconId?: string | null;
  clientMutationId?: string | null;
}

export interface SetUserGroupAvatarInput {
  userGroupId: string;
  avatar?: any | null;
  defaultAvatarId?: string | null;
  clientMutationId?: string | null;
}

export interface TargetingFilterGroupInput {
  graduationType?: string | null;
  instituteType?: string | null;
  graduationYear?: (number | null)[] | null;
  members?: TargetingFilterGroupMembersInput | null;
  groupIds?: (string | null)[] | null;
  teams?: (TeamType | null)[] | null;
}

export interface TargetingFilterGroupMembersInput {
  value?: number | null;
  operator?: TargetingFilterGroupMembersOperation | null;
}

export interface TargetingFilterInput {
  operator: string;
  type: string;
  location?: TargetingFilterLocationInput | null;
  user?: TargetingFilterUserInput | null;
  group?: TargetingFilterGroupInput | null;
}

export interface TargetingFilterLocationInput {
  country?: string | null;
  zip?: (number | null)[] | null;
  region?: (string | null)[] | null;
  city?: string | null;
  radius?: number | null;
}

export interface TargetingFilterUserInput {
  gender?: TargetingFilterUserGenderOperation | null;
  registration?: TargetingFilterUserRegistrationInput | null;
  userIds?: (string | null)[] | null;
}

export interface TargetingFilterUserRegistrationInput {
  date?: string | null;
  operator?: TargetingFilterUserRegistrationOperation | null;
}

export interface ToggleExternalEventSaveInput {
  eventId: string;
  clientMutationId?: string | null;
}

export interface ToggleIsActiveGroupInput {
  groupId: string;
  isActive: boolean;
  clientMutationId?: string | null;
}

export interface UpdateCollagesAlbumInput {
  id: string;
  name: string;
  clientMutationId?: string | null;
}

export interface UpdateCollagesSetupInput {
  id: string;
  isActive: boolean;
  dueDate?: any | null;
  createAlbumsTeam?: string | null;
  addPhotosTeam?: string | null;
  viewPhotosTeam?: string | null;
  clientMutationId?: string | null;
}

export interface UpdateCustomListInput {
  id: string;
  title: string;
  clientMutationId?: string | null;
}

export interface UpdateCustomListOptionInput {
  id: string;
  text: string;
  clientMutationId?: string | null;
}

export interface UpdateCustomPagesInstanceInput {
  id: string;
  pageFormat?: string | null;
  title?: string | null;
  pageType?: string | null;
  pageQuality?: string | null;
  page?: any | null;
  clientMutationId?: string | null;
}

export interface UpdateCustomPagesSetupInput {
  id: string;
  isActive: boolean;
  dueDate?: any | null;
  createPagesTeam?: string | null;
  viewPagesTeam?: string | null;
  guide?: string | null;
  clientMutationId?: string | null;
}

export interface UpdateGroupInput {
  id: string;
  name: string;
  type?: string | null;
  year: number;
  whoCanCreateTeam?: string | null;
  clientMutationId?: string | null;
}

export interface UpdateMeInput {
  firstName: string;
  lastName: string;
  gender?: string | null;
  email: string;
  phoneCountryCode?: string | null;
  phone?: string | null;
  communicationLanguage?: string | null;
  countryOfResidence?: string | null;
  clientMutationId?: string | null;
}

export interface UpdateModuleInstanceInput {
  id: string;
  isActive?: boolean | null;
  dueDate?: any | null;
  clientMutationId?: string | null;
}

export interface UpdatePollInput {
  id: string;
  owner: string;
  question: string;
  deadline: any;
  answersAllowed: number;
  randomizeOptions?: boolean | null;
  allowRevote?: boolean | null;
  allowComments?: boolean | null;
  anonymousVotes?: boolean | null;
  privateResults?: boolean | null;
  clientMutationId?: string | null;
}

export interface UpdatePrewordInstanceInput {
  id: string;
  isActive: boolean;
  dueDate?: any | null;
  text: string;
  clientMutationId?: string | null;
}

export interface UpdateProfilePageAnswerInput {
  id: string;
  text?: string | null;
  profilePageQuestionOption?: string | null;
  clientMutationId?: string | null;
}

export interface UpdateProfilePageCommentInput {
  id: string;
  text: string;
  clientMutationId?: string | null;
}

export interface UpdateProfilePagePhotoCategoryInput {
  id: string;
  name?: string | null;
  clientMutationId?: string | null;
}

export interface UpdateProfilePagePhotoInput {
  id: string;
  image?: any | null;
  clientMutationId?: string | null;
}

export interface UpdateProfilePageQuestionInput {
  id: string;
  text: string;
  clientMutationId?: string | null;
}

export interface UpdateProfilePageSetupInput {
  id: string;
  isActive: boolean;
  dueDate?: any | null;
  photoFormat?: string | null;
  maxChars: number;
  anonymousComments?: boolean | null;
  visibleComments?: boolean | null;
  clientMutationId?: string | null;
}

export interface UpdateQuotesSetupInput {
  id: string;
  isActive: boolean;
  dueDate?: any | null;
  anonymous?: boolean | null;
  public?: boolean | null;
  maxChars: number;
  clientMutationId?: string | null;
}

export interface UpdateRankingsAnswerInput {
  id: string;
  votee1UserGroup?: string | null;
  votee2UserGroup?: string | null;
  voteeListOption?: string | null;
  clientMutationId?: string | null;
}

export interface UpdateRankingsQuestionInput {
  id: string;
  question: string;
  answerType: string;
  allowedVotes?: string | null;
  onlyOppositeGender?: boolean | null;
  customList?: string | null;
  clientMutationId?: string | null;
}

export interface UpdateRankingsSetupInput {
  id: string;
  isActive: boolean;
  dueDate?: any | null;
  resultsTeam?: string | null;
  clientMutationId?: string | null;
}

export interface UpdateReportsInstanceInput {
  id: string;
  title: string;
  text: string;
  editTeam?: string | null;
  viewTeam?: string | null;
  maxChars: number;
  clientMutationId?: string | null;
}

export interface UpdateReportsSetupInput {
  id: string;
  isActive: boolean;
  dueDate?: any | null;
  allowImages?: boolean | null;
  clientMutationId?: string | null;
}

export interface UpdateTeamInput {
  id: string;
  name: string;
  color: string;
  icon: string;
  clientMutationId?: string | null;
}

export interface UpdateUserGroupInput {
  groupId: string;
  userId: string;
  role: string;
  avatar?: any | null;
  defaultAvatarId?: string | null;
  clientMutationId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
