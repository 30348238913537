import styled from 'styled-components/native';

export const Header = styled.View`
  position: relative;
  z-index: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  padding: 0 ${({ theme }) => theme.spacing.medium};
  background: ${({ theme }) => theme.color.base.c0};
  box-shadow: 0 4px 4px rgba(148, 163, 184, 0.09);
`;

export const Logo = styled.Image`
  width: 40px;
  height: 40px;
`;

export const Burger = styled.Image`
  width: 24px;
  height: 24px;
`;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 22px;
`;
