import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { ImporterStackParamList, ImportStatuses } from '~/navigation/types';
import ImportStatusLayout from './layout';

type ImportStatusNavProp = StackNavigationProp<
  ImporterStackParamList,
  'ImportStatus'
>;

export default function ImportStatus(): JSX.Element {
  const navigation = useNavigation<ImportStatusNavProp>();
  return (
    <ImportStatusLayout
      onBackToBeginning={() =>
        navigation.reset({
          routes: [{ name: 'AbihomeLogin' }],
        })
      }
      status={ImportStatuses.processing}
    />
  );
}
