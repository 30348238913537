import React from 'react';
import { TeamType } from '~/data/operations/global';
import { t } from '~/utils/i18n';
import Badge from '../Badge';
import { Container, Title, Row, TypeText, Descp } from './style';

type TeamTypeBannerProps = {
  teamType: TeamType;
};

export default function TeamTypeBanner({
  teamType,
}: TeamTypeBannerProps): JSX.Element {
  const isDefaultTeam = teamType !== TeamType.OTHER;

  return (
    <Container>
      <Title>
        {isDefaultTeam
          ? t('teamTypeBanner.systemTeamTitle')
          : t('teamTypeBanner.userTeamTitle')}
      </Title>
      {isDefaultTeam ? (
        <Row>
          <TypeText>{t('g.type').toUpperCase()}</TypeText>
          <Badge text={t(`teamType.${teamType}`)} colorTheme="gray2Black" />
        </Row>
      ) : null}
      <Descp>{t(`teamTypeBanner.${teamType}`)}</Descp>
    </Container>
  );
}
