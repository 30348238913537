/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query DefaultAvatars
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  DefaultAvatars,
  DefaultAvatarsVariables,
} from './types/DefaultAvatars';
import { DEFAULT_AVATARS } from './';

export function useDefaultAvatarsQuery(
  options?: QueryHookOptions<DefaultAvatars, DefaultAvatarsVariables>,
): QueryResult<DefaultAvatars, DefaultAvatarsVariables> {
  return useQuery<DefaultAvatars, DefaultAvatarsVariables>(
    DEFAULT_AVATARS,
    options,
  );
}

export function useDefaultAvatarsLazyQuery(
  options?: LazyQueryHookOptions<DefaultAvatars, DefaultAvatarsVariables>,
): QueryTuple<DefaultAvatars, DefaultAvatarsVariables> {
  return useLazyQuery<DefaultAvatars, DefaultAvatarsVariables>(
    DEFAULT_AVATARS,
    options,
  );
}

export function createDefaultAvatarsMock({
  variables,
  data,
  error,
}: {
  variables?: DefaultAvatarsVariables;
  data?: DefaultAvatars;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: DEFAULT_AVATARS,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
