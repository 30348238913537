import React from 'react';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import useUserGroups from '~/data/hooks/useUserGroups';
import { TeamDetail, TeamUser } from '~/data/models/team';
import { Status } from '~/data/operations/global';
import { useAcceptUserInTeamMutation } from '~/data/operations/team/acceptUserInTeam';
import { useTeamDetailQuery } from '~/data/operations/team/teamDetail';
import { PlanningStackParamList } from '~/navigation/types';
import AddTeamMemberLayout from './layout';

type AddTeamMemberNavProp = StackNavigationProp<
  PlanningStackParamList,
  'AddTeamMember'
>;
type AddTeamMemberRouteProp = RouteProp<
  PlanningStackParamList,
  'AddTeamMember'
>;
export default function AddTeamMember(): JSX.Element {
  const navigation = useNavigation<AddTeamMemberNavProp>();
  const {
    params: { teamId, localIncludedUserIds, onAddUser },
  } = useRoute<AddTeamMemberRouteProp>();

  const {
    userGroups,
    loading: userGroupsLoading,
    onRefresh: onRefetchUserGroups,
    onLoadMore: onLoadMoreUsers,
  } = useUserGroups();

  const {
    data: teamData,
    loading: loadingTeam,
    refetch: refetchTeam,
  } = useTeamDetailQuery({
    skip: !teamId,
    variables: {
      id: teamId as string,
    },
    pollInterval: 10000,
  });

  const team = teamData?.team as TeamDetail;
  const teamUsers: TeamUser[] =
    (team?.users?.map((user) => user) as TeamUser[]) || [];

  const includedUserIds = teamUsers.map((teamUser) => teamUser.user.id);

  const pendingTeamUsers: TeamUser[] = teamUsers.filter(
    (teamUser) => teamUser.status === Status.PENDING,
  );

  const [acceptUserInTeam] = useAcceptUserInTeamMutation();

  const onAcceptUserInTeam = async (userId: string, accept: boolean) => {
    try {
      if (teamId) {
        await acceptUserInTeam({
          variables: {
            input: {
              teamId,
              userId,
              accept,
            },
          },
        });
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  return (
    <AddTeamMemberLayout
      userGroups={userGroups}
      teamUsers={pendingTeamUsers}
      includedUserIds={localIncludedUserIds || includedUserIds}
      team={team}
      loading={loadingTeam}
      loadingMore={userGroupsLoading}
      onBack={() => navigation.goBack()}
      onSelectedUser={(userId) => {
        onAddUser(userId);
        navigation.goBack();
      }}
      onDeclineUser={(userId) => onAcceptUserInTeam(userId, false)}
      onAcceptUser={(userId) => onAcceptUserInTeam(userId, true)}
      onRefresh={() => {
        refetchTeam();
        onRefetchUserGroups();
      }}
      onLoadMoreUsers={onLoadMoreUsers}
    />
  );
}
