/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation DeleteCustomListOption
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  DeleteCustomListOption,
  DeleteCustomListOptionVariables,
} from './types/DeleteCustomListOption';
import { DELETE_CUSTOM_LIST_OPTION } from './';

export function useDeleteCustomListOptionMutation(
  options?: MutationHookOptions<
    DeleteCustomListOption,
    DeleteCustomListOptionVariables
  >,
): MutationTuple<DeleteCustomListOption, DeleteCustomListOptionVariables> {
  return useMutation<DeleteCustomListOption, DeleteCustomListOptionVariables>(
    DELETE_CUSTOM_LIST_OPTION,
    options,
  );
}

export function createDeleteCustomListOptionMock({
  variables,
  data,
  error,
}: {
  variables?: DeleteCustomListOptionVariables;
  data?: DeleteCustomListOption;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: DELETE_CUSTOM_LIST_OPTION,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
