import styled from 'styled-components/native';
import Icon from '~/components/Icon';

export const Container = styled.View``;

export const TypeValue = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.brand_02};
  font-size: 16px;
  line-height: 22px;
`;

export const ArrowIconBox = styled.View`
  padding-right: 10px;
`;

export const ArrowIcon = styled(Icon).attrs(({ theme }) => ({
  size: 16,
  color: theme.color.base.c3,
}))``;

export const Label = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.brand_02};
  margin-top: 0;
  padding-left: 10px;
  font-size: 12px;
`;

export const SelectedRow = styled.Text`
  color: ${({ theme }) => theme.color.base.c9};
  text-align: left;
  padding-left: 10px;
  font-size: 16px;
`;

export const OptionRow = styled.Text`
  color: ${({ theme }) => theme.color.base.c5};
  text-align: center;
  font-size: 18px;
`;
export const SelectorContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const SelectorTextContainer = styled.View`
  flex-direction: column;
`;
