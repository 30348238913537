/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation UpdateCustomList
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  UpdateCustomList,
  UpdateCustomListVariables,
} from './types/UpdateCustomList';
import { UPDATE_CUSTOM_LIST } from './';

export function useUpdateCustomListMutation(
  options?: MutationHookOptions<UpdateCustomList, UpdateCustomListVariables>,
): MutationTuple<UpdateCustomList, UpdateCustomListVariables> {
  return useMutation<UpdateCustomList, UpdateCustomListVariables>(
    UPDATE_CUSTOM_LIST,
    options,
  );
}

export function createUpdateCustomListMock({
  variables,
  data,
  error,
}: {
  variables?: UpdateCustomListVariables;
  data?: UpdateCustomList;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: UPDATE_CUSTOM_LIST,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
