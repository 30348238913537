import React, { useRef } from 'react';
import { Alert } from 'react-native';
import Button from '~/components/Button';
import SwitchSelector, { SwitchItem } from '~/components/SwitchSelector';
import { Quote } from '~/data/models/yearbook';
import { isQuoteLiked } from '~/data/utils';
import { t } from '~/utils/i18n';
import ModuleLayout from '../../layout/ModuleLayout';
import QuoteCell, { QuoteCellHandle } from './QuoteCell';
import { Header, RefreshControl, List, Content, Loading } from './style';

type QuotesLayoutProps = {
  authUserGroupId: string;
  quotes: Quote[];
  tabOptions: SwitchItem[];
  currentTabKey: string;
  yearbookAdmin?: boolean;
  isActive?: boolean;
  viewOnly?: boolean;
  anonymous?: boolean;
  loading: boolean;
  hasNextPage?: boolean;
  isDeadlinePassed?: boolean | null;
  onTabPressed: (item: string) => void;
  onSetup: () => void;
  onHelp: () => void;
  onRequestYearbookTeam: () => void;
  onBack: () => void;
  onAddQuote: () => void;
  onLikeQuote: (quoteId: string, liked: boolean) => void;
  onDeleteQuote: (quoteId: string) => void;
  onRefresh: () => void;
  onLoadMoreQuotes: () => void;
  refetchLoading?: boolean;
};

export default function Quotes({
  authUserGroupId,
  quotes,
  tabOptions,
  currentTabKey,
  yearbookAdmin,
  isActive,
  viewOnly,
  anonymous,
  loading,
  hasNextPage,
  isDeadlinePassed,
  onTabPressed,
  onSetup,
  onHelp,
  onRequestYearbookTeam,
  onBack,
  onAddQuote,
  onLikeQuote,
  onDeleteQuote,
  onRefresh,
  onLoadMoreQuotes,
  refetchLoading,
}: QuotesLayoutProps): JSX.Element {
  const quoteCellsRef = useRef<(QuoteCellHandle | null)[]>([]);

  const renderItem = ({
    item: quote,
    index,
  }: {
    item?: Quote;
    index: number;
  }) => {
    const liked = quote ? isQuoteLiked(quote) : false;
    const ownQuote = quote?.userGroup.id === authUserGroupId;
    return (
      <QuoteCell
        testID={`QuoteCell:${quote?.id}`}
        ref={(el) => (quoteCellsRef.current[index] = el)}
        quote={quote}
        swipeable={yearbookAdmin || ownQuote}
        anonymous={anonymous}
        ownQuote={viewOnly ? undefined : ownQuote}
        isLiked={liked}
        onLike={(quoteId, liked) => {
          if (!viewOnly) {
            onLikeQuote(quoteId, liked);
          }
        }}
        onDelete={(quoteId) => {
          Alert.alert(t('alert.deleteQuoteTitle'), '', [
            {
              text: t('alert.cancel'),
              style: 'cancel',
            },
            {
              text: t('alert.deleteQuoteConfirm'),
              onPress: () => {
                quoteCellsRef?.current?.[index]?.collapse();
                onDeleteQuote(quoteId);
              },
            },
          ]);
        }}
      />
    );
  };

  const data = loading && quotes.length === 0 ? [undefined] : quotes;
  return (
    <ModuleLayout
      title={t('screens.quotes')}
      yearbookAdmin={yearbookAdmin}
      viewOnly={viewOnly}
      onBack={onBack}
      onSetup={onSetup}
      isActive={isActive}
      isDeadlinePassed={isDeadlinePassed}
      onHelp={onHelp}
      onRequestYearbookTeam={onRequestYearbookTeam}
    >
      <>
        <Header>
          <SwitchSelector
            testID="switchSelector"
            items={tabOptions}
            selectedItemKey={currentTabKey}
            onSelectedItem={(item) => onTabPressed(item)}
          />
          {refetchLoading && (
            <Content>
              <Loading />
            </Content>
          )}
        </Header>
        <List<React.ElementType>
          testID={'quotesList'}
          refreshControl={
            <RefreshControl refreshing={loading} onRefresh={onRefresh} />
          }
          data={data}
          renderItem={renderItem}
          keyExtractor={(item: Quote, index: number) => item?.id + index}
          ListHeaderComponent={
            !viewOnly ? (
              <Header>
                <Button
                  testID={'buttonAddQuote'}
                  text={t('yearbookQuotes.newQuote')}
                  icon={'left'}
                  iconName={'plus'}
                  size={'md'}
                  type={'secondary-base'}
                  flex
                  onPress={onAddQuote}
                />
              </Header>
            ) : null
          }
          ListFooterComponent={
            loading && (
              <Content>
                <Loading />
              </Content>
            )
          }
          onEndReachedThreshold={0.3}
          onEndReached={() => hasNextPage && onLoadMoreQuotes()}
        />
      </>
    </ModuleLayout>
  );
}
