import { LineStyle, TextStyle } from 'react-native-markdown-editor';
import styled from 'styled-components/native';
import ButtonIcon from '~/components/ButtonIcon';
import { getLineFontFamily, getLineTextSize } from '../MarkdownText/utils';

export const Container = styled.View`
  background-color: ${({ theme }) => theme.color.base.c1};
  padding-vertical: 8px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
`;

export const Header = styled.View`
  flex-direction: row;
  padding: 4px 16px;
  align-items: center;
`;

export const Title = styled.Text`
  flex: 1;
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 18px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
`;

export const CloseButtonIcon = styled(ButtonIcon).attrs(({ theme }) => ({
  color: theme.color.base.c6,
  size: 16,
  name: 'x-close',
}))``;

export const LineStyleContainer = styled.View`
  padding: 8px 16px;
  flex-direction: row;
`;

export const ButtonListSeparator = styled.View`
  width: 8px;
`;

interface ILineStyleButton {
  selected: boolean;
  lineStyle: LineStyle;
}
export const LineStyleButton = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})<ILineStyleButton>`
  background-color: ${({ selected, theme }) =>
    selected ? theme.color.brand_02 : theme.color.base.c1};
  border-radius: 14px;
  padding: 4px 16px;
  justify-content: center;
`;

export const LineStyleButtonText = styled.Text<ILineStyleButton>`
  color: ${({ selected, theme }) =>
    selected ? theme.color.base.c0 : theme.color.base.c10};
  font-size: ${({ lineStyle }) => getLineTextSize(lineStyle)}px;
  font-family: ${({ lineStyle, theme }) =>
    getLineFontFamily({ lineStyle, theme })};
`;

export const TextStyleContainer = styled.View`
  flex-direction: row;
  padding: 8px 16px;
`;

interface ITextStyleButton {
  selected: boolean;
  textStyle: TextStyle;
  marginRight?: boolean;
  leftBorderRadius?: boolean;
  rightBorderRadius?: boolean;
}
export const TextStyleButton = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})<ITextStyleButton>`
  flex: 1;
  height: 70px;
  background-color: ${({ selected, theme }) =>
    selected ? theme.color.brand_02 : theme.color.base.c0};
  border-width: 2px;
  border-color: ${({ selected, theme }) =>
    selected ? theme.color.brand_02 : theme.color.base.c2};
  ${({ leftBorderRadius }) =>
    leftBorderRadius &&
    'border-top-left-radius: 14px;  border-bottom-left-radius: 14px;'};
  ${({ rightBorderRadius }) =>
    rightBorderRadius &&
    'border-top-right-radius: 14px;  border-bottom-right-radius: 14px;'};
  padding: 8px 16px;
  align-items: center;
  justify-content: center;
  ${({ marginRight }) => marginRight && 'margin-right: 8px'};
`;

interface ITextStyleButtonText {
  selected: boolean;
  textStyle: TextStyle;
}
export const TextStyleButtonText = styled.Text<ITextStyleButtonText>`
  color: ${({ selected, theme }) =>
    selected ? theme.color.base.c0 : theme.color.base.c10};
  text-decoration-color: ${({ selected, theme }) =>
    selected ? theme.color.base.c0 : theme.color.base.c10};
  font-size: 32px;
  font-family: ${({ textStyle, theme }) =>
    textStyle === '**'
      ? theme.fontFamily.core.w600
      : textStyle === '__'
      ? theme.fontFamily.core.w400Italic
      : theme.fontFamily.core.w400};
  ${({ textStyle }) =>
    textStyle === '--' && 'text-decoration-line: underline;'};
  ${({ textStyle }) =>
    textStyle === '~~' && 'text-decoration-line:  line-through;'};
`;
