import styledWeb from 'styled-components';
import styled from 'styled-components/native';
import ButtonIcon from '~/components/ButtonIcon';
import Icon from '~/components/Icon';
import Image from '~/components/Image';

export const ImageContainer = styled.View`
  flex-direction: row;
  padding-vertical: ${({ theme }) => theme.spacing.medium};
`;

export const SelectedImage = styled(Image)`
  width: 100px;
  height: 100px;
  border-radius: 22px;
  border-width: 4px;
  border-color: ${({ theme }) => theme.color.base.c2};
`;

export const ImageContent = styled.View`
  flex: 1;
  padding-left: ${({ theme }) => theme.spacing.medium};
`;

export const ImageListContent = styled.View`
  flex-direction: row;
`;

export const ImageList = styled.FlatList``;

export const ImageItemButton = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})``;

export const ImageItem = styled(Image)`
  width: 50px;
  height: 50px;
  border-radius: 6px;
  border-width: 2px;
  margin-right: 6px;
  border-color: ${({ theme }) => theme.color.base.c2};
`;

export const IconContent = styled.View`
  justify-content: center;
  padding-horizontal: 4px;
`;

export const LeftButton = styled(ButtonIcon).attrs(({ theme }) => ({
  name: 'chevron-left',
  size: 20,
  color: theme.color.base.c3,
}))``;

export const RightButton = styled(ButtonIcon).attrs(({ theme }) => ({
  name: 'chevron-right',
  size: 20,
  color: theme.color.base.c3,
}))``;

export const UploadButton = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})`
  flex-direction: row;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.tiny};
  padding-vertical: ${({ theme }) => theme.spacing.tiny};
  padding-horizontal: ${({ theme }) => theme.spacing.medium};
  border-width: 3px;
  border-color: ${({ theme }) => theme.color.brand_02};
  border-radius: 14px;
`;

export const FileInput = styledWeb('input')`
  display: none;
`;

export const FileInputLabel = styledWeb('label')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.tiny};
  padding: ${({ theme }) => theme.spacing.tiny} ${({ theme }) =>
  theme.spacing.medium};
  background: ${({ theme }) => theme.color.base.c0};
  border: 2px solid ${({ theme }) => theme.color.brand_02};
  border-radius: 10px;
  cursor: pointer;
`;

export const PhotoIcon = styled(Icon).attrs(({ theme }) => ({
  name: 'camera-01',
  size: 18,
  color: theme.color.brand_02,
}))``;

export const UploadText = styled.Text`
  flex: 1;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.brand_02};
  font-size: 14px;
  line-height: 18px;
`;
