import { LngLatLike } from 'mapbox-gl';
import { map as mapAssets } from '~/assets/imgs';

export type IconMapper = {
  /**
   * a unique id for the mapbox layer
   */
  id: string;
  /**
   * one of '~/assets/imgs', e.g. map.schoolGrey
   */
  icon: string;
  /**
   * see https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#match
   */
  matcher: unknown[];
};

export const DEFAULT_MATCHER: IconMapper[] = [
  {
    id: 'instituteWithoutGroups',
    icon: mapAssets.schoolGrey.toString(),
    matcher: ['!', false],
  },
];

export type MapBoxWrapperProps = {
  center?: LngLatLike;
  zoom?: number;
  clusterData?: GeoJSON.FeatureCollection<GeoJSON.Geometry> | string;
  iconMapper?: IconMapper[];
  isDesktop?: boolean;
};

export interface MapBoxWrapperHandle {
  recenter: (center: LngLatLike, zoom: number) => void;
}
