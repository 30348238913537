import React from 'react';
import { general } from '~/assets/imgs';
import Button from '~/components/Button';
import IconBox from '~/components/IconBox';
import NavHeader from '~/components/NavHeader';
import SectionHeader from '~/components/SectionHeader';
import { VSpacer } from '~/components/common/style';
import { t } from '~/utils/i18n';
import { Container, ScrollView, Image, Desc, DescColourful } from './style';

type ManageRequestLayoutProps = {
  onBack: () => void;
  onRequestYearbookTeam: () => void;
  onHelp: () => void;
};

export default function ManageRequest({
  onBack,
  onRequestYearbookTeam,
  onHelp,
}: ManageRequestLayoutProps): JSX.Element {
  return (
    <Container>
      <NavHeader
        backButtonTestID={'buttonBack'}
        title={t('screens.manageYearbook')}
        onBackPress={onBack}
        RightComponent={
          <Button
            text={t('getHelp')}
            testID="getHelp"
            size={'sm'}
            type={'secondary-base'}
            onPress={onHelp}
          />
        }
      />
      <ScrollView>
        <VSpacer height={32} />
        <Image source={general.lock} />
        <VSpacer height={16} />
        <Desc>
          {t('manageRequest.desc')}{' '}
          <DescColourful>{t('manageRequest.descRestBlue')}</DescColourful>
        </Desc>
        <VSpacer height={64} />
        <SectionHeader title={t('manageRequest.requestTitle')} />
        <VSpacer height={16} />
        <IconBox
          testID={'joinYearbookTeamButton'}
          text={t('manageRequest.yearbookTeam')}
          buttonText={t('manageRequest.requestAccess')}
          icon="notification-text"
          onPress={onRequestYearbookTeam}
        />
      </ScrollView>
    </Container>
  );
}
