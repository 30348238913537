import React from 'react';
import Avatar from '~/components/Avatar';
import { TeamSample } from '~/data/models/team';
import { t } from '~/utils/i18n';
import Shimmer from '../Shimmer';
import TeamIcon from '../TeamIcon';
import {
  Container,
  Header,
  Name,
  Footer,
  AvatarListContent,
  AvatarContent,
  MembersCount,
} from './style';

type TeamCardProps = {
  testID?: string;
  team?: TeamSample;
  onPress: () => void;
};

export default function TeamCard({
  testID,
  team,
  onPress,
}: TeamCardProps): JSX.Element {
  if (!team) {
    return (
      <Container light disabled>
        <Header>
          <Shimmer w={32} h={32} br={16} g={'g0'} />
        </Header>
        <Shimmer w={200} h={20} br={16} g={'g0'} />
        <Shimmer w={90} h={20} br={16} g={'g0'} mt={4} />
        <Footer>
          <AvatarListContent>
            {Array(5)
              .fill(0)
              .map((_, i) => (
                <Shimmer key={i} w={24} h={24} br={12} ml={-8} g={'g2'} />
              ))}
          </AvatarListContent>
          <Shimmer w={72} h={10} br={5} g={'g2'} />
        </Footer>
      </Container>
    );
  }
  const teamUsers = team.sampleMembers || [];
  const userCount = team.usersCount;
  const teamColor = team.isHidden ? undefined : team.color?.hex;
  return (
    <Container testID={testID} teamColor={teamColor} onPress={onPress}>
      <Header>
        {team.icon && (
          <TeamIcon
            iconName={team.icon.icon}
            teamColor={teamColor}
            iconSize={32}
            defaultBg
          />
        )}
      </Header>
      <Name>{team.name}</Name>
      <Footer>
        <AvatarListContent>
          {!team.isHidden
            ? teamUsers.slice(0, 5).map((teamUser, index) => (
                <AvatarContent key={index}>
                  <Avatar size={24} teamUser={teamUser} />
                </AvatarContent>
              ))
            : null}
        </AvatarListContent>
        <MembersCount>
          {team.isHidden ? t('g.deactivated') : `${userCount} ${t('members')}`}
        </MembersCount>
      </Footer>
    </Container>
  );
}
