/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query ModuleInstances
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  ModuleInstances,
  ModuleInstancesVariables,
} from './types/ModuleInstances';
import { MODULE_INSTANCES } from './';

export function useModuleInstancesQuery(
  options?: QueryHookOptions<ModuleInstances, ModuleInstancesVariables>,
): QueryResult<ModuleInstances, ModuleInstancesVariables> {
  return useQuery<ModuleInstances, ModuleInstancesVariables>(
    MODULE_INSTANCES,
    options,
  );
}

export function useModuleInstancesLazyQuery(
  options?: LazyQueryHookOptions<ModuleInstances, ModuleInstancesVariables>,
): QueryTuple<ModuleInstances, ModuleInstancesVariables> {
  return useLazyQuery<ModuleInstances, ModuleInstancesVariables>(
    MODULE_INSTANCES,
    options,
  );
}

export function createModuleInstancesMock({
  variables,
  data,
  error,
}: {
  variables?: ModuleInstancesVariables;
  data?: ModuleInstances;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: MODULE_INSTANCES,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
