import styled from 'styled-components/native';
import { TabScreenContainer } from '~/screens/style';

export const Container = styled(TabScreenContainer)`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const ImportItem = styled.View`
  flex-direction: row;
  align-items: center;
  height: 64px;
  margin-bottom: 16px;
  padding: 0 16px;
  background: ${({ theme }) => theme.color.brand_02};
  border-radius: 14px;
`;

export const Label = styled.Text`
  flex-grow: 1;
  color: ${({ theme }) => theme.color.base.c0};
  font-weight: 600;
  font-size: 16px;
  margin-left: 16px;
`;

export const SubHeader = styled.Text<{ mb: number }>`
  margin-top: 48px;
  margin-bottom: ${({ mb }) => mb}px;
  color: ${({ theme }) => theme.color.base.c6};
  font-weight: 600;
  font-size: 16px;
`;
