import styled from 'styled-components/native';
import Icon from '../Icon';
import IconStyled from '../IconStyled';

export interface ICommon {
  selected: boolean;
}
export const Container = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})<ICommon>`
  flex: 1;
  margin: 8px;
  border-radius: 5px;
  border-width: 1px;
  border-color: ${({ selected, theme }) =>
    selected ? theme.color.brand_02 : theme.color.base.c2};
  align-items: center;
  background-color: ${({ theme }) => theme.color.base.c0};
  box-shadow: 1px 2px 6px ${({ theme }) => theme.color.base.c2};
`;

export interface IIconContainer extends ICommon {
  size: number;
}
export const IconContainer = styled.View<IIconContainer>`
  border-radius: 5px;
  background-color: ${({ selected, theme }) =>
    selected ? theme.color.brand_02 : theme.color.base.c2};
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  align-items: center;
  margin-top: 13px;
  margin-horizontal: 12px;
  justify-content: center;
`;

export interface IIcon extends ICommon {
  size: number;
  selected: boolean;
}
export const OptionIconStyled = styled(IconStyled).attrs<IIcon>(
  ({ size, theme, selected }) => ({
    size,
    color: selected ? theme.color.base.c0 : theme.color.base.c6,
  }),
)<IIcon>``;

export const OptionIcon = styled(Icon).attrs<IIcon>(
  ({ size, theme, selected }) => ({
    size,
    color: selected ? theme.color.base.c0 : theme.color.base.c6,
  }),
)<IIcon>``;

export const Text = styled.Text<ICommon>`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ selected, theme }) =>
    selected ? theme.color.brand_02 : theme.color.base.c7};
  font-size: 10px;
  margin-top: 8px;
  margin-bottom: 13px;
  text-align: center;
`;
