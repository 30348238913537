import styled from 'styled-components/native';

const CELL_PADDING = 16;

const cellWidth = (screenWidth: number) =>
  (screenWidth - 3 * CELL_PADDING) * 0.5;

export const Container = styled.View`
  width: ${({ theme }) => cellWidth(theme.dimensions.screenWidth)}px;
  background-color: ${({ theme }) => theme.color.base.c0};
  padding: 16px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.color.base.c3};
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
`;

interface INumberText {
  smallText?: boolean;
}
export const NumberText = styled.Text<INumberText>`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.brand_02};
  font-size: ${({ smallText }) => (smallText ? '36px' : '48px')};
`;

export const Text = styled.Text`
  margin-top: 8px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 14px;
  text-align: center;
`;
