import { InstitutesInstituteTypeCountryChoices } from '~/data/operations/global';

export const enum SEARCH_TAG_TYPES {
  SELECT = 'select',
  INPUT = 'input',
}

export const enum SEARCH_TAG_OPTION_TYPES {
  INSTITUTE_TYPES = 'instituteTypes',
  GRADUATION_TYPES = 'graduationTypes',
}

interface SearchTagBase<T extends SEARCH_TAG_TYPES> {
  type: T;
  tag: string;
  description: string;
}

type InputSearchTag = SearchTagBase<SEARCH_TAG_TYPES.INPUT>;

export interface SelectSearchTag
  extends SearchTagBase<SEARCH_TAG_TYPES.SELECT> {
  getOptions: SEARCH_TAG_OPTION_TYPES;
}

export type SearchTag = InputSearchTag | SelectSearchTag;

export type SearchTags = {
  key: string;
  description: string;
  tags: SearchTag[];
};

export type SearchTagValue = {
  tag: string;
  value: string;
  error: boolean;
};

export type SearchTagOption = {
  description?: string;
  country?: InstitutesInstituteTypeCountryChoices | null;
};

export type SearchTagOptions = {
  tag: string;
  description: string;
  options?: SearchTagOption[];
};

export type SearchShortcutKey = {
  imageSource: SearchIconName;
  width: number;
  height: number;
};

export type SearchShortcutAction = {
  param: string;
  label: string;
};

export type SearchShortcut = {
  keys: SearchShortcutKey[];
  inputs: string[];
  action: SearchShortcutAction;
  label: string;
};

export type SearchIconName =
  | 'icn/search/keyI'
  | 'icn/search/keyU'
  | 'icn/search/keyG'
  | 'icn/search/keyOption';
