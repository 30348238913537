/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query GraduationTypesCountry
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  GraduationTypesCountry,
  GraduationTypesCountryVariables,
} from './types/GraduationTypesCountry';
import { GRADUATION_TYPES_COUNTRY } from './';

export function useGraduationTypesCountryQuery(
  options?: QueryHookOptions<
    GraduationTypesCountry,
    GraduationTypesCountryVariables
  >,
): QueryResult<GraduationTypesCountry, GraduationTypesCountryVariables> {
  return useQuery<GraduationTypesCountry, GraduationTypesCountryVariables>(
    GRADUATION_TYPES_COUNTRY,
    options,
  );
}

export function useGraduationTypesCountryLazyQuery(
  options?: LazyQueryHookOptions<
    GraduationTypesCountry,
    GraduationTypesCountryVariables
  >,
): QueryTuple<GraduationTypesCountry, GraduationTypesCountryVariables> {
  return useLazyQuery<GraduationTypesCountry, GraduationTypesCountryVariables>(
    GRADUATION_TYPES_COUNTRY,
    options,
  );
}

export function createGraduationTypesCountryMock({
  variables,
  data,
  error,
}: {
  variables?: GraduationTypesCountryVariables;
  data?: GraduationTypesCountry;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: GRADUATION_TYPES_COUNTRY,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
