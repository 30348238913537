import React from 'react';
import { general } from '~/assets/imgs';
import Button from '~/components/Button';
import AuthLayout from '~/screens/Auth/layout/AuthLayout';
import { LanguageProps } from '~/screens/Auth/layout/AuthLayout/types';
import { t } from '~/utils/i18n';
import { Content, Image } from './style';

type ForgotPasswordInstructionLayoutProps = {
  openEmailEnabled?: boolean;
  onBack: () => void;
  onOpenEmailConfig: {
    text: string;
    onPress: () => void;
  };
  onSkip: () => void;
} & LanguageProps;

export default function ForgotPasswordInstruction({
  selectedLocale,
  onBack,
  onOpenEmailConfig,
  onSkip,
  onSelectedLocale,
}: ForgotPasswordInstructionLayoutProps): JSX.Element {
  const headerProps = {
    title: t('forgotPasswordInstruction.title'),
    descp: t('forgotPasswordInstruction.descp'),
    progress: 2,
    total: 2,
  };

  const languageProps = {
    selectedLocale,
    onSelectedLocale,
  };

  return (
    <AuthLayout
      headerProps={headerProps}
      onBack={onBack}
      languageProps={languageProps}
    >
      <>
        <Content>
          <Image source={general.stickers_row} resizeMode={'contain'} />
        </Content>
        <Button
          testID={'buttonOpenEmail'}
          text={t(onOpenEmailConfig.text)}
          size="2xl"
          bottomMargin={8}
          onPress={onOpenEmailConfig.onPress}
          flex
        />
        <Button
          testID={'buttonSkip'}
          type={'secondary-contrast'}
          text={t('forgotPasswordInstruction.skipEmail')}
          onPress={onSkip}
          size="2xl"
          flex
        />
      </>
    </AuthLayout>
  );
}
