import React, { useEffect, useState } from 'react';
import AuthorPicker, {
  AuthorCommonType,
  AuthorTeamType,
} from '~/components/AuthorPicker';
import InputField from '~/components/InputField';
import SectionHeader from '~/components/SectionHeader';
import { Spacer } from '~/components/common/style.web';
import { Team } from '~/data/models/team';
import { CustomPagesSetupConfig, ModuleInstance } from '~/data/models/yearbook';
import { t } from '~/utils/i18n';
import ModuleSetupLayout, {
  ModuleSetupLayoutLoading,
  UpdateModuleInstanceInput,
} from '../../layout/ModuleSetupLayout';
import { ContentGuideDescritpion } from './style';

export type UpdateCustomPagesSetupInput = {
  addCustomPagesTeamId?: string | null;
  viewCustomPagesTeamId?: string | null;
  quoteContent: string;
};
type CustomSetupLayoutProps = {
  onBack: () => void;
  moduleInstance: ModuleInstance;
  teams: Team[];
  customSetupConfig: CustomPagesSetupConfig;
  loading: boolean;
  onUpdateModuleInstance: (
    updateModuleInstanceInput: UpdateModuleInstanceInput,
  ) => void;
  onUpdateCustomPagesSetup: (
    updateCustomPagesSetupInput: UpdateCustomPagesSetupInput,
  ) => void;
  onLoadMoreTeams: () => void;
};

export default function CustomSetup({
  onBack,
  moduleInstance,
  teams,
  customSetupConfig,
  loading,
  onUpdateModuleInstance,
  onLoadMoreTeams,
  onUpdateCustomPagesSetup,
}: CustomSetupLayoutProps): JSX.Element {
  const [addCustomPagesTeamId, setAddCustomPagesTeamId] = useState(
    customSetupConfig?.createPagesTeam?.id,
  );

  const [viewCustomPagesTeamId, setViewCustomPagesTeamId] = useState(
    customSetupConfig?.viewPagesTeam?.id,
  );

  const [contentGuide, setContentGuide] = useState(
    customSetupConfig?.guide as string,
  );

  useEffect(() => {
    const hasAddCustomPagesTeamIdChanged =
      addCustomPagesTeamId !== customSetupConfig?.createPagesTeam?.id;
    const hasViewCustomPagesTeamIdChanged =
      viewCustomPagesTeamId !== customSetupConfig?.viewPagesTeam?.id;
    const hasContentGuideChanged = contentGuide !== customSetupConfig?.guide;

    const shouldUpdateCustomPagesSetup =
      hasAddCustomPagesTeamIdChanged ||
      hasViewCustomPagesTeamIdChanged ||
      hasContentGuideChanged;

    const updatedAddCustomPagesTeamId =
      addCustomPagesTeamId === authorEverybody.id ? null : addCustomPagesTeamId;
    const updatedViewCustomPagesTeamId =
      viewCustomPagesTeamId === authorEverybody.id
        ? null
        : viewCustomPagesTeamId;

    if (shouldUpdateCustomPagesSetup) {
      onUpdateCustomPagesSetup({
        addCustomPagesTeamId: updatedAddCustomPagesTeamId,
        viewCustomPagesTeamId: updatedViewCustomPagesTeamId,
        quoteContent: contentGuide,
      });
    }
  }, [addCustomPagesTeamId, viewCustomPagesTeamId, contentGuide]);

  const authorEverybody: AuthorCommonType = {
    id: 'all',
    authorType: 'common',
    info: {
      description: t('g.everybody'),
      styledIcon: 'everyone',
    },
  };

  const teamAuthors: AuthorTeamType[] = teams.map((team) => {
    return {
      id: team.id,
      authorType: 'team',
      team,
    };
  });

  const authors = [authorEverybody, ...teamAuthors];

  if (loading) {
    return (
      <ModuleSetupLayoutLoading
        title={t('screens.customSetup')}
        onBack={onBack}
      />
    );
  }
  return (
    <ModuleSetupLayout
      title={t('screens.customSetup')}
      activateText={t('rankings.setupActivate')}
      moduleInstance={moduleInstance}
      onBack={onBack}
      onUpdateModuleInstance={onUpdateModuleInstance}
    >
      <>
        <SectionHeader
          sub
          title={t('customPages.setupVisibilityCustomPages')}
        />
        <AuthorPicker
          testID={'createViewTeamAuthorPicker'}
          authors={authors}
          selectedAuthorId={viewCustomPagesTeamId || authorEverybody.id}
          onSelectedAuthor={(authorId) => setViewCustomPagesTeamId(authorId)}
          onLoadMore={onLoadMoreTeams}
        />
        <SectionHeader sub title={t('customPages.setupAddCustomPages')} />
        <AuthorPicker
          testID={'createAdditionTeamAuthorPicker'}
          authors={authors}
          selectedAuthorId={addCustomPagesTeamId || authorEverybody.id}
          onSelectedAuthor={(authorId) => setAddCustomPagesTeamId(authorId)}
          onLoadMore={onLoadMoreTeams}
        />
        <SectionHeader sub title={t('customPages.contentGuide')} />
        <ContentGuideDescritpion>
          {t('customPages.contentGuideDescription')}
        </ContentGuideDescritpion>
        <InputField
          testID={'ContentGuideInput'}
          value={contentGuide}
          onChangeText={(query) => setContentGuide(query)}
          label={t('customPages.contentGuideInput')}
        />
        <Spacer v={45} />
      </>
    </ModuleSetupLayout>
  );
}
