import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { SettingsStackParamList } from '~/navigation/types';
import Settings from '~/screens/Admin/Settings';

const SettingsStackNavigator = createStackNavigator<SettingsStackParamList>();

export default function SettingsStack(): JSX.Element {
  return (
    <SettingsStackNavigator.Navigator
      initialRouteName={'Settings'}
      screenOptions={{
        headerShown: false,
      }}
    >
      <SettingsStackNavigator.Screen name={'Settings'} component={Settings} />
    </SettingsStackNavigator.Navigator>
  );
}
