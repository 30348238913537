import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import * as Updates from 'expo-updates';
import useAppLocale from '~/data/hooks/useAppLocale';
import { ProfileStackParamList } from '~/navigation/types';
import AppSetupLayout from './layout';

type AppSetupNavProp = StackNavigationProp<ProfileStackParamList, 'AppSetup'>;

export default function AppSetup(): JSX.Element {
  const navigation = useNavigation<AppSetupNavProp>();

  const { locale, onChangeLocale } = useAppLocale({
    onReload: () => Updates.reloadAsync(),
  });

  return (
    <AppSetupLayout
      selectedLocale={locale}
      onBack={() => navigation.goBack()}
      onUpdateLocale={onChangeLocale}
    />
  );
}
