import styled from 'styled-components/native';
import {
  MODAL_MAX_WIDTH,
  MODAL_SIDE_PADDING,
} from '~/components/WebModal/style';
import { ResponsiveProps } from '~/components/common/style.web';

export const Container = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const Text = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 14px;
`;

export const Title = styled.Text<ResponsiveProps>`
  color: ${({ theme }) => theme.color.base.c9};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: ${({ isDesktop }) => (isDesktop ? '22' : '16')}px;
`;

export const HeaderSection = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

interface TableRowProps {
  noBorder?: boolean;
}

export const AgentsTable = styled.FlatList`
  min-height: 55vh;
`;

export const TableRow = styled.View<TableRowProps>`
  flex-direction: row;
  border-bottom-width: ${({ noBorder }) => (noBorder ? '0px' : '0.5px')};
  border-bottom-color: ${({ theme }) => theme.color.base.c5};
  padding: 10px 0;
  gap: 12px;
`;

export const TableCell = styled.View`
  flex: 1;
  justify-content: center;
  gap: 5px;
`;

export const CellText = styled.Text`
  color: ${({ theme }) => theme.color.base.c5};
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 14px;
  user-select: text;
`;

export const CellBadge = styled.View<ResponsiveProps>`
  color: ${({ theme }) => theme.color.base.c5};
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 14px;
  user-select: text;
  align-self: flex-end;
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
  gap: 10px;
`;

export const TableCellRemove = styled.TouchableOpacity`
  padding-right: 10px;
  padding-left: 10px;
`;

export const RemoveText = styled.Text<ResponsiveProps>`
  color: ${({ theme }) => theme.color.error};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: ${({ isDesktop }) => (isDesktop ? '16' : '14')}px;
  user-select: none;
  text-align: right;
`;

export const TableCellRemoveText = styled.Text`
  color: ${({ theme }) => theme.color.error};
  text-transform: uppercase;
`;

export const TableCellUpgrade = styled.TouchableOpacity`
  padding-right: 10px;
  padding-left: 10px;
`;
export const UpgradeText = styled.Text`
  color: ${({ theme }) => theme.color.brand_02};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
  user-select: none;
`;

export const UserName = styled.Text`
  color: ${({ theme }) => theme.color.base.c9};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
`;

export const PaginationWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
`;

export const CampaingWrapper = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

export const ButtonWrapper = styled.View`
  max-width: fit-content;
`;

export const MobileBadgeWrapper = styled.View`
  max-width: 50px;
  align-items: center;
  justify-content: center;
`;

const CONTAINER_WIDTH = MODAL_MAX_WIDTH - 2 * MODAL_SIDE_PADDING;

export const ModalContent = styled.View<ResponsiveProps>`
  background-color: ${({ theme }) => theme.color.base.c0};
  width: ${({ isDesktop }) => (isDesktop ? `${CONTAINER_WIDTH}px` : '100%')};
`;

export const ModalSubTitle = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 18px;
  text-align: left;
  padding: 15px 0;
`;
