import styled from 'styled-components/native';

export const Header = styled.View`
  padding-horizontal: 16px;
  padding-bottom: 8px;
`;

export const RefreshControl = styled.RefreshControl.attrs(({ theme }) => ({
  color: theme.color.base.c4,
}))``;

export const List = styled.FlatList``;

export const Content = styled.View`
  padding-vertical: 24px;
  align-items: center;
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c7,
}))``;
