/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation RequestMissingInstitute
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  RequestMissingInstitute,
  RequestMissingInstituteVariables,
} from './types/RequestMissingInstitute';
import { REQUEST_MISSING_INSTITUTE } from './';

export function useRequestMissingInstituteMutation(
  options?: MutationHookOptions<
    RequestMissingInstitute,
    RequestMissingInstituteVariables
  >,
): MutationTuple<RequestMissingInstitute, RequestMissingInstituteVariables> {
  return useMutation<RequestMissingInstitute, RequestMissingInstituteVariables>(
    REQUEST_MISSING_INSTITUTE,
    options,
  );
}

export function createRequestMissingInstituteMock({
  variables,
  data,
  error,
}: {
  variables?: RequestMissingInstituteVariables;
  data?: RequestMissingInstitute;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: REQUEST_MISSING_INSTITUTE,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
