import React from 'react';
import { mapUser } from '~/data/mappers';
import { DataExport } from '~/data/models/yearbook';
import { formattedDate } from '~/utils/dates';
import { t } from '~/utils/i18n';
import Avatar from '../Avatar';
import { Container, Content, Name, InfoText, CustomIcon } from './style';

export type ExportCellProps = {
  testID?: string;
  dataExport: DataExport;
  onPress: () => void;
};

export default function ExportCell({
  testID,
  dataExport,
  onPress,
}: ExportCellProps): JSX.Element {
  const mappedUser = mapUser(dataExport.userGroup?.user);

  const finished = !!dataExport.exportFile;

  return (
    <Container testID={testID} disabled={!finished} onPress={onPress}>
      <Avatar size={48} userGroup={dataExport.userGroup} />
      <Content>
        <Name>{`${t('g.export')} ${
          dataExport.isodatetime
            ? formattedDate(dataExport.isodatetime, 'dd.MM.yyyy')
            : ''
        }`}</Name>
        <InfoText>{t('g.byName', { name: mappedUser?.name })}</InfoText>
      </Content>
      <CustomIcon name={'database'} finished={finished} />
      <CustomIcon name={'attachment'} finished={finished} />
      <CustomIcon name={'send_email'} finished={finished} />
    </Container>
  );
}
