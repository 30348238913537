/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation CreateReportsInstance
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  CreateReportsInstance,
  CreateReportsInstanceVariables,
} from './types/CreateReportsInstance';
import { CREATE_REPORTS_INSTANCE } from './';

export function useCreateReportsInstanceMutation(
  options?: MutationHookOptions<
    CreateReportsInstance,
    CreateReportsInstanceVariables
  >,
): MutationTuple<CreateReportsInstance, CreateReportsInstanceVariables> {
  return useMutation<CreateReportsInstance, CreateReportsInstanceVariables>(
    CREATE_REPORTS_INSTANCE,
    options,
  );
}

export function createCreateReportsInstanceMock({
  variables,
  data,
  error,
}: {
  variables?: CreateReportsInstanceVariables;
  data?: CreateReportsInstance;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: CREATE_REPORTS_INSTANCE,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
