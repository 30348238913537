import React, { useState } from 'react';
import { Calendar } from 'react-native-calendars';
import { useTheme } from 'styled-components/native';
import Icon from '~/components/Icon';
import { MONTHS } from '~/data/constants';
import { CalendarDateObject, CalendarEventByDay } from '~/data/models/calendar';
import { t } from '~/utils/i18n';
import {
  Container,
  DayWrapper,
  SelectableDayCell,
  SelectableDayText,
  DayFooter,
  Dot,
  CalendarHeaderTitle,
  CalendarHeader,
  CalendarHeaderMonth,
  LeftArrow,
  RightArrow,
  MonthYear,
} from './style';

export type EventCalendarProps = {
  testID?: string;
  calendarEventsByDay: CalendarEventByDay;
  now?: Date;
  onAddEvent: () => void;
  onDateSelected?: (date: string) => void;
};

interface CustomHeaderProps {
  date: XDate;
  handleMonthChange: (newDate: CalendarDateObject) => void;
}

export default function EventCalendar({
  testID,
  calendarEventsByDay,
  now,
  onDateSelected,
}: EventCalendarProps): JSX.Element {
  const theme = useTheme();
  const [selectedDate, setSelectedDate] = useState<CalendarDateObject | null>(
    null,
  );
  const [currentMonth, setCurrentMonth] = useState(now?.toISOString());

  const handleMonthChange = (newDate: CalendarDateObject) => {
    const timeStamp = newDate?.timestamp;
    const date = new Date(timeStamp as number);
    setCurrentMonth(date?.toISOString());
  };

  const getDateValues = (date: Date) => ({
    day: date.getDate(),
    month: date.getMonth(),
    year: date.getFullYear(),
    dateString: date.toDateString(),
  });

  const CustomHeader: React.FC<CustomHeaderProps> = ({
    date,
    handleMonthChange,
  }) => {
    const handlePrevMonth = () => {
      const newDate = new Date(date.toString('yyyy-MM-dd'));
      newDate.setMonth(newDate.getMonth() - 1);

      handleMonthChange({
        ...getDateValues(newDate),
        timestamp: newDate.getTime(),
      });
    };

    const handleNextMonth = () => {
      const newDate = new Date(date.toString('yyyy-MM-dd'));
      newDate.setMonth(newDate.getMonth() + 1);

      handleMonthChange({
        ...getDateValues(newDate),
        timestamp: newDate.getTime(),
      });
    };

    const year = date?.getFullYear();
    const monthNumber = date?.getMonth();
    const month = MONTHS[monthNumber as number];

    return (
      <CalendarHeader>
        <CalendarHeaderTitle title={t('g.explore')} />
        <CalendarHeaderMonth>
          <LeftArrow onPress={handlePrevMonth}>
            <Icon name="chevron-left" color={theme.color.brand_02} />
          </LeftArrow>
          <MonthYear>{`${month} ${year}`}</MonthYear>
          <RightArrow onPress={handleNextMonth}>
            <Icon name="chevron-right" color={theme.color.brand_02} />
          </RightArrow>
        </CalendarHeaderMonth>
      </CalendarHeader>
    );
  };

  return (
    <Container testID={testID}>
      <Calendar
        key={currentMonth}
        markingType={'period'}
        current={currentMonth}
        monthFormat={'MMM yyyy'}
        theme={{
          calendarBackground: theme.color.base.c0,
          textSectionTitleColor: theme.color.brand_02,
          textDayHeaderFontFamily: theme.fontFamily.core.w600,
          textDayHeaderFontSize: 10,
          dayTextColor: theme.color.base.c5,
          monthTextColor: theme.color.base.c5,
          textMonthFontFamily: theme.fontFamily.core.w600,
          textMonthFontSize: 16,
          arrowColor: theme.color.brand_02,
        }}
        renderHeader={(date) => (
          <CustomHeader
            date={date as XDate}
            handleMonthChange={handleMonthChange}
          />
        )}
        hideArrows={true}
        style={{ width: '100%' }}
        enableSwipeMonths
        onDayPress={(day) => {
          setSelectedDate(day);
          onDateSelected && onDateSelected(day.dateString);
        }}
        dayComponent={({ date, state, onPress, onLongPress }) => {
          function formatDate(dateString: string): string {
            const date = new Date(dateString);
            const isoString = date.toISOString();
            const withoutMilliseconds =
              isoString.slice(0, -5) + isoString.slice(-1);
            return withoutMilliseconds.replace('Z', '+00:00');
          }
          const disabled = state === 'disabled';
          //selection mode
          const selected = date?.dateString === selectedDate?.dateString;

          const calendarEvents = date
            ? calendarEventsByDay[formatDate(date?.dateString)]
            : [];
          return (
            <DayWrapper
              onPress={() => onPress && onPress(date)}
              onLongPress={() => onLongPress && onLongPress(date)}
            >
              <SelectableDayCell marked={selected}>
                <SelectableDayText disabled={disabled} marked={selected}>
                  {date?.day || ''}
                </SelectableDayText>
              </SelectableDayCell>
              <DayFooter>
                {calendarEvents &&
                  calendarEvents.map((calendarEvent) => (
                    <Dot key={calendarEvent.id} selected={selected} />
                  ))}
              </DayFooter>
            </DayWrapper>
          );
        }}
      />
    </Container>
  );
}
