import React from 'react';
import Carousel from 'react-native-snap-carousel';
import { useTheme } from 'styled-components/native';
import { Icon } from '~/data/models/custom';
import TeamIcon from '../TeamIcon';
import { Container, CONTAINER_WIDTH, ICON_CARD_WIDTH } from './style';

type TeamIconPickerProps = {
  testID?: string;
  icons: Icon[];
  initialIconIndex?: number;
  teamColor?: string;
  onSelectedIcon: (icon: Icon) => void;
};

export default function TeamIconPicker({
  testID,
  icons,
  initialIconIndex = 0,
  teamColor = useTheme().color.base.c5,
  onSelectedIcon,
}: TeamIconPickerProps): JSX.Element {
  const renderCardItem = ({ item: icon }: { item: Icon }) => {
    return (
      <TeamIcon
        iconName={icon.icon}
        size={80}
        iconSize={48}
        teamColor={teamColor}
      />
    );
  };

  return (
    <Container testID={testID}>
      <Carousel
        data={icons}
        renderItem={renderCardItem}
        activeSlideAlignment="start"
        containerCustomStyle={{ paddingHorizontal: 20 }}
        sliderWidth={CONTAINER_WIDTH}
        itemWidth={ICON_CARD_WIDTH}
        inactiveSlideScale={0.75}
        inactiveSlideOpacity={1.0}
        firstItem={initialIconIndex}
        onSnapToItem={(index) => onSelectedIcon(icons[index])}
        removeClippedSubviews={false}
      />
    </Container>
  );
}
