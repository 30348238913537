/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation DestroyRankingsQuestion
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  DestroyRankingsQuestion,
  DestroyRankingsQuestionVariables,
} from './types/DestroyRankingsQuestion';
import { DESTROY_RANKINGS_QUESTION } from './';

export function useDestroyRankingsQuestionMutation(
  options?: MutationHookOptions<
    DestroyRankingsQuestion,
    DestroyRankingsQuestionVariables
  >,
): MutationTuple<DestroyRankingsQuestion, DestroyRankingsQuestionVariables> {
  return useMutation<DestroyRankingsQuestion, DestroyRankingsQuestionVariables>(
    DESTROY_RANKINGS_QUESTION,
    options,
  );
}

export function createDestroyRankingsQuestionMock({
  variables,
  data,
  error,
}: {
  variables?: DestroyRankingsQuestionVariables;
  data?: DestroyRankingsQuestion;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: DESTROY_RANKINGS_QUESTION,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
