/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation UpdateCustomPagesSetup
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  UpdateCustomPagesSetup,
  UpdateCustomPagesSetupVariables,
} from './types/UpdateCustomPagesSetup';
import { UPDATE_CUSTOM_PAGES_SETUP } from './';

export function useUpdateCustomPagesSetupMutation(
  options?: MutationHookOptions<
    UpdateCustomPagesSetup,
    UpdateCustomPagesSetupVariables
  >,
): MutationTuple<UpdateCustomPagesSetup, UpdateCustomPagesSetupVariables> {
  return useMutation<UpdateCustomPagesSetup, UpdateCustomPagesSetupVariables>(
    UPDATE_CUSTOM_PAGES_SETUP,
    options,
  );
}

export function createUpdateCustomPagesSetupMock({
  variables,
  data,
  error,
}: {
  variables?: UpdateCustomPagesSetupVariables;
  data?: UpdateCustomPagesSetup;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: UPDATE_CUSTOM_PAGES_SETUP,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
