import React, { useState } from 'react';
import { NetworkStatus } from '@apollo/client';
import {
  useNavigation,
  useRoute,
  RouteProp,
  CompositeNavigationProp,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { SwitchItem } from '~/components/SwitchSelector';
import { useAuth } from '~/context/auth';
import { useIntercom } from '~/context/intercom';
import { DEFAULT_LIST_OFFSET } from '~/data/constants';
import useTeamSamples from '~/data/hooks/useTeamSamples';
import { Quote } from '~/data/models/yearbook';
import { useDestroyQuotesInstanceMutation } from '~/data/operations/yearbook/destroyQuotesInstance';
import {
  readQuotesInstancesQuery,
  writeQuotesInstancesQuery,
} from '~/data/operations/yearbook/helpers';
import { useLikeQuotesInstanceMutation } from '~/data/operations/yearbook/likeQuotesInstance';
import { useModuleInstanceQuery } from '~/data/operations/yearbook/moduleInstance';
import { useQuotesInstancesQuery } from '~/data/operations/yearbook/quotesInstances';
import { useRemoveLikeFromQuotesInstanceMutation } from '~/data/operations/yearbook/removeLikeFromQuotesInstance';
import { RootStackParamList, YearbookStackParamList } from '~/navigation/types';
import { isDateExpired } from '~/utils/dates';
import { t } from '~/utils/i18n';
import QuotesLayout from './layout';

// Note: The necessities for Updated Quotes UI
// Actions : Addtion, Deletion, Like, Unlike
// *** Newest Tab ***
// - Addtion: update cache
// - Deletion: update cache
// - Like: -
// - Unlike: -
// *** Most Liked Tab ***
// - Addtion: db request
// - Deletion: update cache
// - Like: db request cache
// - Unlike: db request cache

type QuotesNavProp = CompositeNavigationProp<
  StackNavigationProp<YearbookStackParamList, 'Quotes'>,
  StackNavigationProp<RootStackParamList>
>;
type QuotesRouteProp = RouteProp<YearbookStackParamList, 'Quotes'>;

export default function Quotes(): JSX.Element {
  const tabs: SwitchItem[] = [
    { key: 'newest', title: t('yearbookQuotes.newest') },
    { key: 'popular', title: t('yearbookQuotes.popular') },
  ];

  const navigation = useNavigation<QuotesNavProp>();
  const { onHelpCenter } = useIntercom();
  const {
    params: { moduleInstanceId, viewOnly },
  } = useRoute<QuotesRouteProp>();

  const [currentTab, setCurrentTab] = useState(tabs[0].key);
  const toggleTab = (itemKey: string) => setCurrentTab(itemKey);

  const { authGroupId, authUserGroupId, yearbookAdmin } = useAuth();
  const { teamSamples } = useTeamSamples();
  const yearbookTeam = teamSamples.filter((t) => t.type === 'YEARBOOK');

  const { data: moduleInstanceData } = useModuleInstanceQuery({
    variables: {
      id: moduleInstanceId,
    },
  });

  const quotesSetup = moduleInstanceData?.moduleInstance?.quotesSetup;
  const anonymous = quotesSetup?.anonymous;

  const isActive = moduleInstanceData?.moduleInstance?.isActive;
  const dueDate = moduleInstanceData?.moduleInstance?.dueDate;
  const isDeadlinePassed = dueDate && isDateExpired(dueDate);

  const quotesVar = {
    after: null,
    first: DEFAULT_LIST_OFFSET,
    moduleInstance: moduleInstanceId,
    userGroup: quotesSetup?.public ? undefined : authUserGroupId,
    authUserGroupId,
    orderBy: currentTab === 'newest' ? '-created' : '-likes', // "-" means descending order (newest/popular first)
  };

  const {
    data: quotesData,
    loading: quotesLoading,
    networkStatus,
    refetch: refetchQuotes,
    fetchMore: fetchMoreQuotes,
  } = useQuotesInstancesQuery({
    skip: !quotesSetup,
    variables: quotesVar,
    notifyOnNetworkStatusChange: true,
  });

  const [likeQuotesInstance] = useLikeQuotesInstanceMutation();
  const [removeLikeFromQuotesInstance] =
    useRemoveLikeFromQuotesInstanceMutation();
  const [destroyQuotesInstance] = useDestroyQuotesInstanceMutation();

  const quotes: Quote[] =
    (quotesData?.quotesInstances?.edges.map((edge) => edge?.node) as Quote[]) ||
    [];
  const hasNextPage = quotesData?.quotesInstances?.pageInfo.hasNextPage;
  const endCursor = quotesData?.quotesInstances?.pageInfo.endCursor;

  async function onLikeQuote(quoteId: string, liked: boolean) {
    try {
      if (liked) {
        await removeLikeFromQuotesInstance({
          variables: {
            input: {
              id: quoteId,
            },
            authUserGroupId,
          },
        });
      } else {
        await likeQuotesInstance({
          variables: {
            input: {
              id: quoteId,
            },
            authUserGroupId,
          },
        });
      }

      await refetchQuotes();
    } catch (e) {
      console.log(e); //eslint-disable-line
    }
  }

  const onDeleteQuote = async (quoteId: string) => {
    try {
      await destroyQuotesInstance({
        variables: {
          input: {
            id: quoteId,
          },
        },
        update(cache) {
          const currentQuotesInstancesQuery = readQuotesInstancesQuery({
            cache,
            variables: quotesVar,
          });
          if (currentQuotesInstancesQuery?.quotesInstances?.edges) {
            const filteredEdges =
              currentQuotesInstancesQuery.quotesInstances.edges.filter(
                (edge) => edge?.node?.id !== quoteId,
              );
            writeQuotesInstancesQuery({
              cache,
              variables: quotesVar,
              data: {
                ...currentQuotesInstancesQuery,
                quotesInstances: {
                  ...currentQuotesInstancesQuery.quotesInstances,
                  edges: filteredEdges,
                },
              },
            });
          }
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  return (
    <QuotesLayout
      authUserGroupId={authUserGroupId}
      quotes={quotes}
      isActive={isActive}
      currentTabKey={currentTab}
      tabOptions={tabs}
      yearbookAdmin={yearbookAdmin}
      isDeadlinePassed={isDeadlinePassed}
      anonymous={anonymous}
      loading={quotesLoading}
      refetchLoading={networkStatus === NetworkStatus.refetch}
      hasNextPage={hasNextPage}
      viewOnly={viewOnly}
      onTabPressed={toggleTab}
      onAddQuote={() =>
        navigation.navigate('AddQuote', {
          moduleInstanceId,
        })
      }
      onLikeQuote={onLikeQuote}
      onDeleteQuote={onDeleteQuote}
      onSetup={() => navigation.navigate('QuotesSetup', { moduleInstanceId })}
      onHelp={onHelpCenter}
      onRequestYearbookTeam={() => {
        navigation.popToTop();
        navigation.navigate('MainTab', {
          screen: 'PlanningStack',
          gid: authGroupId,
          params: {
            screen: 'TeamDetail',
            params: {
              teamId: yearbookTeam[0]?.id,
            },
          },
        });
      }}
      onBack={() => navigation.goBack()}
      onRefresh={() => refetchQuotes()}
      onLoadMoreQuotes={() => {
        fetchMoreQuotes({
          variables: {
            ...quotesVar,
            after: endCursor,
          },
        });
      }}
    />
  );
}
