import { UseDownloadImageProps } from './types';

const useDownloadImage = (): UseDownloadImageProps => {
  return {
    downloadImage: () => {
      new Error('Not supported');
    },
    downloadPDF: () => {
      new Error('Not supported');
    },
  };
};

export default useDownloadImage;
