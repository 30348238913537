import React from 'react';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import useCollagesPhotos from '~/data/hooks/useCollagesPhotos';
import { useCollagesAlbumQuery } from '~/data/operations/yearbook/collagesAlbum';
import { useLikeCollagesPhotoMutation } from '~/data/operations/yearbook/likeCollagesPhoto';
import { useRemoveLikeFromCollagesPhotoMutation } from '~/data/operations/yearbook/removeLikeFromCollagesPhoto';
import { YearbookStackParamList } from '~/navigation/types';
import useDownloadImage from '~/utils/hooks/useDownloadImage';
import { t } from '~/utils/i18n';
import CollagesPhotoDetailLayout from './layout';

type CollagesPhotoDetailNavProp = StackNavigationProp<
  YearbookStackParamList,
  'CollagesPhotoDetail'
>;

type CollagesPhotoDetailRouteProp = RouteProp<
  YearbookStackParamList,
  'CollagesPhotoDetail'
>;

export default function CollagesPhotoDetail(): JSX.Element {
  const navigation = useNavigation<CollagesPhotoDetailNavProp>();
  const {
    params: { moduleInstanceId, collageAlbumId, collagePhotoId, viewOnly },
  } = useRoute<CollagesPhotoDetailRouteProp>();

  const { authUserGroupId, yearbookAdmin } = useAuth();

  const { collagesPhotos, deletePhotoLoading, onDeletePhoto } =
    useCollagesPhotos({
      moduleInstanceId,
      collageAlbumId,
    });

  const { data: collagesAlbumData } = useCollagesAlbumQuery({
    skip: !authUserGroupId,
    variables: {
      id: collageAlbumId,
    },
  });

  const { downloadImage } = useDownloadImage();

  const collagesAlbum = collagesAlbumData?.collagesAlbum;

  const initialPhotoIndex = collagesPhotos
    .map((cp) => cp.id)
    .indexOf(collagePhotoId);

  const [likeCollagesPhoto] = useLikeCollagesPhotoMutation();
  const [removeLikeFromCollagesPhoto] =
    useRemoveLikeFromCollagesPhotoMutation();

  const onLikePhoto = async (collagePhotoId: string, liked: boolean) => {
    try {
      if (liked) {
        await removeLikeFromCollagesPhoto({
          variables: {
            input: {
              id: collagePhotoId,
            },
            authUserGroupId,
          },
        });
      } else {
        await likeCollagesPhoto({
          variables: {
            input: {
              id: collagePhotoId,
            },
            authUserGroupId,
          },
        });
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onDownloadImage = async (imageUri: string) => {
    try {
      await downloadImage(imageUri);
      Snackbar.show(t('g.successfulDownload'), {
        duration: Snackbar.durations.SHORT,
        position: Snackbar.positions.BOTTOM_CHANGE,
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  return (
    <CollagesPhotoDetailLayout
      collageAlbumName={collagesAlbum?.name}
      collagesPhotos={collagesPhotos}
      initialPhotoIndex={initialPhotoIndex}
      deletePhotoLoading={deletePhotoLoading}
      viewOnly={viewOnly}
      onBack={() => navigation.goBack()}
      onLikePhoto={onLikePhoto}
      onDeletePhoto={(photoId) => {
        onDeletePhoto(photoId, () => {
          navigation.goBack();
        });
      }}
      onDownloadImage={onDownloadImage}
      authUserGroupId={authUserGroupId}
      yearbookAdmin={yearbookAdmin}
    />
  );
}
