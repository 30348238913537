import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import Dropdown from '~/components/Dropdown';
import { OptionsInfo } from '~/components/Dropdown';
import SwitchSelector from '~/components/SwitchSelector';
import { MONTHS } from '~/data/constants';
import { Statistics } from '~/data/models/campaign';
import { t } from '~/utils/i18n';
import { FilterOptionsKeys, SelectionSetKey, SelectionsState } from '..';
import {
  HeaderContainer,
  SelectorWrapper,
  Title,
} from '../../Ads/layout/style';
import { Spinner } from '../../EditInstitute/layout/style';
import {
  BarChartWrapper,
  ChangeIndicator,
  Container,
  GraphMainStat,
  GraphSecondaryStat,
  GraphStatistics,
  GraphStatsLabel,
  GraphWrapper,
  IndicatorText,
  InfoIcon,
  Label,
  MainStat,
  OptionBlock,
  OptionsWrapper,
  ReportingWrapper,
  SecondaryStat,
  StatisticsWrapper,
  StatsGraphRow,
  StatsLabel,
  StatsRow,
  SubTitle,
  MiddleStatisticsBlock,
  LeftStatisticsBlock,
  RightStatisticsBlock,
} from './style';

interface ReportingLayoutProps {
  selections: SelectionsState;
  percentageChanges: {
    institutes: number;
    users: number;
    usersCount: number;
    groups: number;
    activeGroups: number;
  };
  countries: OptionsInfo[];
  graduationYears: OptionsInfo[];
  graduationTypes: OptionsInfo[];
  instituteTypes: OptionsInfo[];
  loading: boolean;
  statisticsData1: Statistics | null | undefined;
  totalSchools: number | null | undefined;
  chartData: { chartDataCondition1: number[]; chartDataCondition2: number[] };
  formatNumberWithDotSeparator: (num?: number | null) => string;
  onChangeSelection: (
    setKey: SelectionSetKey,
    key: FilterOptionsKeys,
    value: string,
  ) => void;
}

export enum ReportingType {
  PLATFORM = 'PLATFORM',
  SEGMENTS = 'SEGMENTS',
  FINANCIAL = 'FINANCIAL',
}

export default function Search({
  selections,
  countries,
  graduationYears,
  graduationTypes,
  instituteTypes,
  statisticsData1,
  loading,
  totalSchools,
  percentageChanges,
  chartData,
  formatNumberWithDotSeparator,
  onChangeSelection,
}: ReportingLayoutProps): JSX.Element {
  const [eventKey, setEventKey] = useState<string>(ReportingType.PLATFORM);
  return (
    <Container>
      <HeaderContainer>
        <Title>{t('g.reporting')}</Title>
        <SelectorWrapper>
          <SwitchSelector
            items={Object.keys(ReportingType).map((key) => ({
              key,
              title: t(`g.${key.toLowerCase()}`),
            }))}
            selectedItemKey={eventKey}
            onSelectedItem={setEventKey}
            testID="pickerReportingType"
          />
        </SelectorWrapper>
      </HeaderContainer>
      <ReportingWrapper>
        <OptionsWrapper>
          <OptionBlock>
            <Label>{t('country')}</Label>
            <Dropdown
              testID={'countryDropdown1'}
              selectedLocale={
                !selections.selections1.country?.length
                  ? 'global'
                  : selections.selections1.country[0]
              }
              selectableOptions={countries}
              onSelectedLocale={(selectedCountry) => {
                onChangeSelection(
                  SelectionSetKey.Selections1,
                  FilterOptionsKeys.country,
                  selectedCountry,
                );
              }}
            />
          </OptionBlock>
          <OptionBlock>
            <Label>{t('graduationYear')}</Label>
            <Dropdown
              testID={'graduationYearDropdown1'}
              selectedLocale={
                !selections.selections1.graduationYear?.length
                  ? 'all'
                  : selections.selections1.graduationYear[0].toString()
              }
              selectableOptions={graduationYears}
              onSelectedLocale={(selectedYear) => {
                onChangeSelection(
                  SelectionSetKey.Selections1,
                  FilterOptionsKeys.graduationYear,
                  selectedYear,
                );
              }}
            ></Dropdown>
          </OptionBlock>
          <OptionBlock>
            <Label>{t('graduationType')}</Label>
            <Dropdown
              testID={'graduationTypeDropdown1'}
              selectedLocale={
                !selections.selections1.graduationType?.length
                  ? 'all'
                  : selections.selections1.graduationType[0]
              }
              selectableOptions={graduationTypes}
              onSelectedLocale={(selectedType) => {
                onChangeSelection(
                  SelectionSetKey.Selections1,
                  FilterOptionsKeys.graduationType,
                  selectedType,
                );
              }}
            />
          </OptionBlock>
          <OptionBlock>
            <Label>{t('instituteType')}</Label>
            <Dropdown
              testID={'instituteTypesDropdown1'}
              selectedLocale={
                !selections.selections1.instituteType?.length
                  ? 'all'
                  : selections.selections1.instituteType[0]
              }
              selectableOptions={instituteTypes}
              onSelectedLocale={(selectedType) => {
                onChangeSelection(
                  SelectionSetKey.Selections1,
                  FilterOptionsKeys.instituteType,
                  selectedType,
                );
              }}
            />
          </OptionBlock>
        </OptionsWrapper>
        <SubTitle>{t('reporting.compareTo')}</SubTitle>
        <OptionsWrapper>
          <OptionBlock>
            <Label>{t('country')}</Label>
            <Dropdown
              testID={'countryDropdown2'}
              selectedLocale={
                !selections.selections2.country?.length
                  ? 'global'
                  : selections.selections2.country[0]
              }
              selectableOptions={countries}
              onSelectedLocale={(selectedCountry) => {
                onChangeSelection(
                  SelectionSetKey.Selections2,
                  FilterOptionsKeys.country,
                  selectedCountry,
                );
              }}
            />
          </OptionBlock>
          <OptionBlock>
            <Label>{t('graduationYear')}</Label>
            <Dropdown
              testID={'graduationYearDropdown2'}
              selectedLocale={
                !selections.selections2.graduationYear?.length
                  ? 'all'
                  : selections.selections2.graduationYear[0].toString()
              }
              selectableOptions={graduationYears}
              onSelectedLocale={(selectedYear) => {
                onChangeSelection(
                  SelectionSetKey.Selections2,
                  FilterOptionsKeys.graduationYear,
                  selectedYear,
                );
              }}
            ></Dropdown>
          </OptionBlock>
          <OptionBlock>
            <Label>{t('graduationType')}</Label>
            <Dropdown
              testID={'graduationTypeDropdown2'}
              selectedLocale={
                !selections.selections2.graduationType?.length
                  ? 'all'
                  : selections.selections2.graduationType[0]
              }
              selectableOptions={graduationTypes}
              onSelectedLocale={(selectedType) => {
                onChangeSelection(
                  SelectionSetKey.Selections2,
                  FilterOptionsKeys.graduationType,
                  selectedType,
                );
              }}
            />
          </OptionBlock>
          <OptionBlock>
            <Label>{t('instituteType')}</Label>
            <Dropdown
              testID={'instituteTypesDropdown2'}
              selectedLocale={
                !selections.selections2.instituteType?.length
                  ? 'all'
                  : selections.selections2.instituteType[0]
              }
              selectableOptions={instituteTypes}
              onSelectedLocale={(selectedType) => {
                onChangeSelection(
                  SelectionSetKey.Selections2,
                  FilterOptionsKeys.instituteType,
                  selectedType,
                );
              }}
            />
          </OptionBlock>
        </OptionsWrapper>
        <StatisticsWrapper>
          <LeftStatisticsBlock>
            <StatsLabel>{t('reporting.instituteStats')}</StatsLabel>
            {loading ? (
              <Spinner testID="institutesSpinner1" />
            ) : (
              <>
                <MainStat>
                  {formatNumberWithDotSeparator(
                    statisticsData1?.institutesCount,
                  )}
                </MainStat>
                <StatsRow>
                  <SecondaryStat>
                    {`${t('reporting.of')} ${formatNumberWithDotSeparator(
                      totalSchools,
                    )}`}
                  </SecondaryStat>
                  <ChangeIndicator increase={percentageChanges.institutes >= 0}>
                    <IndicatorText increase={percentageChanges.institutes >= 0}>
                      {percentageChanges.institutes >= 0 ? `↑` : `↓`}
                      {Math.abs(percentageChanges.institutes).toFixed(2)}%
                    </IndicatorText>
                  </ChangeIndicator>
                </StatsRow>
              </>
            )}
          </LeftStatisticsBlock>

          <MiddleStatisticsBlock>
            <StatsLabel>{t('reporting.registeredUsers')}</StatsLabel>
            {loading ? (
              <Spinner testID="registeredUsersSpinner1" />
            ) : (
              <>
                <MainStat>
                  {formatNumberWithDotSeparator(statisticsData1?.usersCount)}
                </MainStat>
                <StatsRow>
                  <ChangeIndicator increase={percentageChanges.users >= 0}>
                    <IndicatorText increase={percentageChanges.users >= 0}>
                      {percentageChanges.users >= 0 ? `↑` : `↓`}
                      {Math.abs(percentageChanges.users).toFixed(2)}%
                    </IndicatorText>
                  </ChangeIndicator>
                </StatsRow>
              </>
            )}
          </MiddleStatisticsBlock>

          <RightStatisticsBlock>
            <StatsLabel>{t('reporting.groupsStats')}</StatsLabel>
            {loading ? (
              <Spinner testID="activeGroupsSpinner1" />
            ) : (
              <>
                <MainStat>
                  {statisticsData1?.activeGroupsCount
                    ? percentageChanges.activeGroups.toFixed(2) + '%'
                    : 0}
                </MainStat>
                <StatsRow>
                  <ChangeIndicator increase={percentageChanges.groups >= 0}>
                    <IndicatorText increase={percentageChanges.groups >= 0}>
                      {percentageChanges.groups >= 0 ? `↑` : `↓`}
                      {Math.abs(percentageChanges.groups).toFixed(2)}%
                    </IndicatorText>
                  </ChangeIndicator>
                </StatsRow>
              </>
            )}
          </RightStatisticsBlock>
        </StatisticsWrapper>
        <GraphWrapper>
          {loading ? (
            <Spinner testID="chartSpinner" />
          ) : (
            <GraphStatistics>
              <StatsGraphRow>
                <GraphStatsLabel>{t('reporting.graphLabel')}</GraphStatsLabel>
                <InfoIcon />
              </StatsGraphRow>
              <GraphMainStat>
                {loading ? (
                  <Spinner testID="chartUserDataSpinner" />
                ) : (
                  statisticsData1?.usersCount &&
                  formatNumberWithDotSeparator(
                    statisticsData1?.usersCount + percentageChanges.usersCount,
                  )
                )}
              </GraphMainStat>
              <GraphSecondaryStat>
                {t('reporting.secondaryText')}
              </GraphSecondaryStat>
            </GraphStatistics>
          )}

          <BarChartWrapper>
            <Bar
              data={{
                labels: MONTHS,
                datasets: [
                  {
                    label: t('reporting.dataSet1'),
                    backgroundColor: 'rgba(54, 162, 235, 0.2)',
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(54, 162, 235, 0.4)',
                    hoverBorderColor: 'rgba(54, 162, 235, 1)',
                    data: chartData.chartDataCondition1,
                  },
                  {
                    label: t('reporting.dataSet2'),
                    backgroundColor: 'rgba(153, 102, 255, 0.2)',
                    borderColor: 'rgba(153, 102, 255, 1)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(153, 102, 255, 0.4)',
                    hoverBorderColor: 'rgba(153, 102, 255, 1)',
                    data: chartData.chartDataCondition2,
                  },
                ],
              }}
              width={100}
              height={50}
              options={{
                maintainAspectRatio: true,
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  ],
                },
                legend: {
                  position: 'bottom',
                },
              }}
            />
          </BarChartWrapper>
        </GraphWrapper>
      </ReportingWrapper>
    </Container>
  );
}
