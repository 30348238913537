import React, { useRef, useState } from 'react';
import InputField, { InputFieldHandle } from '~/components/InputField';
import { SIGN_UP_TOTAL_STEPS } from '~/screens/Auth/constants';
import AuthLayout from '~/screens/Auth/layout/AuthLayout';
import {
  LanguageProps,
  SubmitButtonProps,
} from '~/screens/Auth/layout/AuthLayout/types';
import { t } from '~/utils/i18n';
import { isValidName } from '~/utils/validators';

type SignUpNameLayoutProps = {
  initialFirstName: string;
  initialLastName: string;
  inputProgress: number;
  onBack: () => void;
  onNext: (firstName: string, lastName: string) => void;
} & LanguageProps;

export default function SignUpName({
  inputProgress,
  selectedLocale,
  onBack,
  onNext,
  onSelectedLocale,
  initialFirstName,
  initialLastName,
}: SignUpNameLayoutProps): JSX.Element {
  const firstNameRef = useRef<InputFieldHandle>(null);
  const lastNameRef = useRef<InputFieldHandle>(null);

  const [firstName, setFirstName] = useState<string>(initialFirstName || '');
  const [lastName, setLastName] = useState<string>(initialLastName || '');

  const isValidInput = (): boolean => {
    return isValidName(firstName) && isValidName(lastName);
  };

  const handleNext = () => {
    isValidInput() && onNext && onNext(firstName, lastName);
  };

  const headerProps = {
    title: t('signUpName.title'),
    descp: t('signUpName.descp'),
    progress: inputProgress,
    total: SIGN_UP_TOTAL_STEPS,
  };

  const submitButtonProps: SubmitButtonProps = {
    isValidInput,
    text: t('next'),
    onPress: handleNext,
    flex: true,
  };

  const languageProps = {
    selectedLocale,
    onSelectedLocale,
  };

  return (
    <AuthLayout
      onBack={onBack}
      headerProps={headerProps}
      submitButtonProps={submitButtonProps}
      languageProps={languageProps}
    >
      <>
        <InputField
          ref={firstNameRef}
          testID={'inputFirstName'}
          iconName={'user-03'}
          label={t('signUpName.firstNameLabel')}
          placeholder={t('signUpName.firstNamePlaceholder')}
          value={firstName}
          onChangeText={(value) => setFirstName(value)}
          blurOnSubmit={false}
          returnKeyType={'next'}
          onSubmitEditing={() => lastNameRef.current?.focus()}
        />
        <InputField
          ref={lastNameRef}
          testID={'inputLastName'}
          iconName={'user-03'}
          label={t('signUpName.lastNameLabel')}
          placeholder={t('signUpName.lastNamePlaceholder')}
          value={lastName}
          blurOnSubmit={false}
          returnKeyType={'next'}
          onChangeText={(value) => setLastName(value)}
          onSubmitEditing={() => handleNext()}
        />
      </>
    </AuthLayout>
  );
}
