import React from 'react';
import { useTheme } from 'styled-components/native';
import IconStyled, { TeamIconName as TeamIconStyledName } from '../IconStyled';
import { Container } from './style';

export type TeamIconName = TeamIconStyledName;

export type TeamIconProps = {
  teamColor?: string;
  iconName?: string;
  iconColor?: string;
  size?: number;
  iconSize?: number;
  borderRadius?: number;
  defaultBg?: boolean;
};

export default function TeamIcon({
  teamColor,
  iconName,
  iconColor = useTheme().color.base.c0,
  size = 48,
  iconSize = 24,
  borderRadius = 20,
  defaultBg,
  ...style
}: TeamIconProps): JSX.Element {
  return (
    <Container
      {...style}
      teamColor={teamColor}
      size={size}
      defaultBg={defaultBg}
      borderRadius={borderRadius}
    >
      {(() => {
        if (iconName) {
          return (
            <IconStyled
              size={iconSize}
              color={iconColor}
              name={iconName as TeamIconName}
            />
          );
        }
        return null;
      })()}
    </Container>
  );
}
