import { useState } from 'react';
import Papa from 'papaparse';

// Define the UploadStateEnum
export enum UploadStateEnum {
  None,
  Uploading,
  Uploaded,
  Error,
}

type ParseResult = {
  data: string[] | string[][];
  errors: { type: string; code: string; message: string }[];
};

type UseUploadCSVReturnType = {
  uploadingState: UploadStateEnum;
  handleUploadCSV: (file: File) => Promise<void>;
  removeUploadedFile: () => void;
  setUploadedCSVFileName: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  uploadedCSVFileName: string | undefined;
  uploadedCSVFileLength: number | undefined;
  uploadedInput: (string | number)[];
};

const parseCSV = (file: File): Promise<ParseResult> => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      complete: (result: ParseResult) => {
        if (result.errors.length) {
          reject(result.errors);
        } else {
          resolve(result);
        }
      },
      header: false,
      delimiter: ',',
      skipEmptyLines: true,
    });
  });
};

type ProcessedData = (string | number)[];

const processParsedData = (data: ParseResult['data']): ProcessedData => {
  // Flatten the array and convert each item to its appropriate type
  return data.flat().map((value) => {
    // Check if the value can be converted to a number
    const numValue = Number(value);
    return isNaN(numValue) ? value : numValue;
  });
};
const useUploadCSV = (): UseUploadCSVReturnType => {
  const [uploadingState, setUploadingState] = useState<UploadStateEnum>(
    UploadStateEnum.None,
  );
  const [uploadedCSVFileName, setUploadedCSVFileName] = useState<string>();
  const [uploadedCSVFileLength, setUploadedCSVFileLength] = useState<number>();
  const [uploadedInput, setUploadedInput] = useState<(string | number)[]>([]);

  const handleUploadCSV = async (file: File) => {
    setUploadedCSVFileName(file.name);
    setUploadingState(UploadStateEnum.Uploading);

    try {
      // Parse the CSV file
      const parsedData = await parseCSV(file);

      // Process the parsed data
      const processedData = processParsedData(parsedData.data);
      setUploadingState(UploadStateEnum.Uploaded);
      setUploadedInput(processedData);
      setUploadedCSVFileLength(processedData.length);
    } catch (error) {
      setUploadingState(UploadStateEnum.Error);
    }
  };

  const removeUploadedFile = () => {
    setUploadingState(UploadStateEnum.None);
    // You can reset other states here if needed
  };

  return {
    uploadingState,
    handleUploadCSV,
    removeUploadedFile,
    setUploadedCSVFileName,
    uploadedCSVFileName,
    uploadedCSVFileLength,
    uploadedInput,
  };
};

export default useUploadCSV;
