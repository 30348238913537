/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation RemovePhotoFromCollagesAlbum
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  RemovePhotoFromCollagesAlbum,
  RemovePhotoFromCollagesAlbumVariables,
} from './types/RemovePhotoFromCollagesAlbum';
import { REMOVE_PHOTO_FROM_COLLAGES_ALBUM } from './';

export function useRemovePhotoFromCollagesAlbumMutation(
  options?: MutationHookOptions<
    RemovePhotoFromCollagesAlbum,
    RemovePhotoFromCollagesAlbumVariables
  >,
): MutationTuple<
  RemovePhotoFromCollagesAlbum,
  RemovePhotoFromCollagesAlbumVariables
> {
  return useMutation<
    RemovePhotoFromCollagesAlbum,
    RemovePhotoFromCollagesAlbumVariables
  >(REMOVE_PHOTO_FROM_COLLAGES_ALBUM, options);
}

export function createRemovePhotoFromCollagesAlbumMock({
  variables,
  data,
  error,
}: {
  variables?: RemovePhotoFromCollagesAlbumVariables;
  data?: RemovePhotoFromCollagesAlbum;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: REMOVE_PHOTO_FROM_COLLAGES_ALBUM,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
