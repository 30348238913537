/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query Signups
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { Signups, SignupsVariables } from './types/Signups';
import { SIGNUPS } from './';

export function useSignupsQuery(
  options?: QueryHookOptions<Signups, SignupsVariables>,
): QueryResult<Signups, SignupsVariables> {
  return useQuery<Signups, SignupsVariables>(SIGNUPS, options);
}

export function useSignupsLazyQuery(
  options?: LazyQueryHookOptions<Signups, SignupsVariables>,
): QueryTuple<Signups, SignupsVariables> {
  return useLazyQuery<Signups, SignupsVariables>(SIGNUPS, options);
}

export function createSignupsMock({
  variables,
  data,
  error,
}: {
  variables?: SignupsVariables;
  data?: Signups;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: SIGNUPS,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
