import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { t } from '~/utils/i18n';
import { DEFAULT_LIST_OFFSET, NO_PAG_LIST_OFFSET } from '../constants';
import { UserGroup } from '../models/group';
import { GroupsUserGroupStatusChoices, Status } from '../operations/global';
import { useAcceptUserInGroupMutation } from '../operations/group/acceptUserInGroup';
import {
  readGroupUsersQuery,
  readUserGroupsQuery,
  writeGroupUsersQuery,
  writeUserGroupsQuery,
} from '../operations/group/helpers';
import { useRemoveUserFromGroupMutation } from '../operations/group/removeUserFromGroup';
import { useUserGroupsQuery } from '../operations/group/userGroups';

type UserGroupsHookProps = {
  userGroups: UserGroup[];
  loading: boolean;
  hasNextPage?: boolean;
  onRefresh: () => void;
  onLoadMore: () => void;
  onAnswerRequest: (userId: string, accept: boolean) => void;
  onRemoveUser: (userId: string) => Promise<void>;
};

const useUserGroups = (): UserGroupsHookProps => {
  const { authGroupId, authUserId, onLogoutGroup } = useAuth();

  const userGroupsVar = {
    after: null,
    first: DEFAULT_LIST_OFFSET,
    group: authGroupId,
    status: GroupsUserGroupStatusChoices.ACCEPTED,
  };

  const {
    data: userGroupsData,
    loading: userGroupsLoading,
    refetch: refetchUserGroups,
    fetchMore: fetchMoreUserGroups,
  } = useUserGroupsQuery({
    skip: !authGroupId,
    variables: userGroupsVar,
    notifyOnNetworkStatusChange: true,
  });

  const userGroups: UserGroup[] =
    (userGroupsData?.userGroups?.edges.map(
      (edge) => edge?.node,
    ) as UserGroup[]) || [];
  const hasNextPage = userGroupsData?.userGroups?.pageInfo.hasNextPage;
  const endCursor = userGroupsData?.userGroups?.pageInfo.endCursor;

  const [acceptUserInGroup] = useAcceptUserInGroupMutation({
    update(cache, { data }) {
      const requestedUserGroup = data?.acceptUserInGroup?.userGroup;

      const pendingUserGroupsVar = {
        first: NO_PAG_LIST_OFFSET,
        group: authGroupId,
        status: GroupsUserGroupStatusChoices.PENDING,
      };

      const pendingUserGroupsQuery = readUserGroupsQuery({
        cache,
        variables: pendingUserGroupsVar,
      });

      if (pendingUserGroupsQuery?.userGroups?.edges && requestedUserGroup) {
        writeUserGroupsQuery({
          cache,
          variables: pendingUserGroupsVar,
          data: {
            ...pendingUserGroupsQuery,
            userGroups: {
              ...pendingUserGroupsQuery.userGroups,
              edges: pendingUserGroupsQuery.userGroups.edges.filter(
                (edge) => edge?.node?.user.id != requestedUserGroup.user.id,
              ),
            },
          },
        });
      }

      const userGroupsQuery = readUserGroupsQuery({
        cache,
        variables: userGroupsVar,
      });

      if (
        userGroupsQuery?.userGroups?.edges &&
        requestedUserGroup?.status === Status.ACCEPTED
      ) {
        writeUserGroupsQuery({
          cache,
          variables: userGroupsVar,
          data: {
            ...userGroupsQuery,
            userGroups: {
              ...userGroupsQuery.userGroups,
              edges: [
                ...userGroupsQuery.userGroups.edges,
                {
                  __typename: 'UserGroupNodeEdge',
                  node: requestedUserGroup,
                },
              ],
            },
          },
        });
      }
    },
  });

  const [removeUserFromGroup] = useRemoveUserFromGroupMutation();

  const onRefresh = () => {
    refetchUserGroups(userGroupsVar);
  };

  const onLoadMore = () => {
    hasNextPage &&
      !userGroupsLoading &&
      fetchMoreUserGroups({
        variables: {
          ...userGroupsVar,
          after: endCursor,
        },
      });
  };

  const onAnswerRequest = async (userId: string, accept: boolean) => {
    try {
      await acceptUserInGroup({
        variables: {
          input: {
            groupId: authGroupId,
            userId,
            accept,
          },
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onRemoveUser = async (userId: string) => {
    try {
      await removeUserFromGroup({
        variables: {
          input: {
            groupId: authGroupId,
            userId,
          },
        },
        update(cache) {
          const userGroupsQuery = readUserGroupsQuery({
            cache,
            variables: userGroupsVar,
          });

          if (userGroupsQuery?.userGroups?.edges && userId) {
            writeUserGroupsQuery({
              cache,
              variables: userGroupsVar,
              data: {
                ...userGroupsQuery,
                userGroups: {
                  ...userGroupsQuery.userGroups,
                  edges: userGroupsQuery.userGroups.edges.filter(
                    (edge) => edge?.node?.user.id != userId,
                  ),
                },
              },
            });
          }
          // leaving team
          if (userId === authUserId) {
            const groupUsersQuery = readGroupUsersQuery({
              cache,
              variables: {
                user: authUserId,
                first: NO_PAG_LIST_OFFSET,
              },
            });
            if (groupUsersQuery?.userGroups?.edges) {
              writeGroupUsersQuery({
                cache,
                variables: {
                  user: authUserId,
                  first: NO_PAG_LIST_OFFSET,
                },
                data: {
                  ...groupUsersQuery,
                  userGroups: {
                    ...groupUsersQuery.userGroups,
                    edges: groupUsersQuery.userGroups.edges.filter(
                      (edge) => edge?.node?.group?.id != authGroupId,
                    ),
                  },
                },
              });
            }
          }
        },
      });

      if (userId === authUserId) {
        Snackbar.show(t('groupSetup.leaveGroupSuccess'));
        onLogoutGroup();
      } else {
        Snackbar.show(t('groupSetup.removeUserSuccess'));
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  return {
    userGroups,
    loading: userGroupsLoading,
    hasNextPage,
    onRefresh,
    onLoadMore,
    onAnswerRequest,
    onRemoveUser,
  };
};

export default useUserGroups;
