import React from 'react';
import SelectionControl, {
  SelectionControlProps,
} from '~/components/SelectionControl';

export default function CheckBox({
  testID,
  state = 'default',
  size = 'large',
  checked = false,
  icon = undefined,
  colorStyle,
  onChange,
}: SelectionControlProps): JSX.Element {
  return (
    <SelectionControl
      testID={testID}
      type={'checkBox'}
      state={state}
      size={size}
      checked={checked}
      icon={icon}
      colorStyle={colorStyle}
      onChange={onChange}
    />
  );
}
