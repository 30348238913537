import styled from 'styled-components/native';
import IconComp from '~/components/Icon';
import { bySizeHelper, byStatusHelper } from './helpers';
import {
  SelectionControlColorStyle,
  SelectionControlSize,
  SelectionControlState,
  SelectionControlType,
} from './types';

interface IContainer {
  type: SelectionControlType;
  state: SelectionControlState;
  size: SelectionControlSize;
}
export const Container = styled.TouchableOpacity.attrs({
  activeOpacity: 1.0,
})<IContainer>`
  margin: ${({ state }) => (state === 'focus' ? '2px' : '5px')};
  box-shadow: 0px 1px 3px ${({ theme }) => theme.color.base.c3};
  elevation: 3;
`;

interface IFocusContent {
  type: SelectionControlType;
  state: SelectionControlState;
  size: SelectionControlSize;
  colorStyle: SelectionControlColorStyle;
  checked: boolean;
}
export const FocusContent = styled.View<IFocusContent>`
  border-radius: ${({ type, size }) =>
    bySizeHelper.focusContentRadius(type, size)};
  border-color: ${({ colorStyle, theme }) =>
    byStatusHelper.focusBorderColor(colorStyle, theme)};
  border-width: ${({ state }) => (state === 'focus' ? '3px' : '0px')};
`;

interface IContent {
  type: SelectionControlType;
  state: SelectionControlState;
  size: SelectionControlSize;
  colorStyle: SelectionControlColorStyle;
  checked: boolean;
}
export const Content = styled.View<IContent>`
  width: ${({ size }) => bySizeHelper.contentSize(size)};
  height: ${({ size }) => bySizeHelper.contentSize(size)};
  border-radius: ${({ type, size }) => bySizeHelper.contentRadius(type, size)};
  border-width: ${({ state, size, checked }) =>
    bySizeHelper.borderWidth(size, state, checked)};
  border-color: ${({ state, checked, theme }) =>
    byStatusHelper.borderColor(state, checked, theme)};
  background-color: ${({ state, colorStyle, checked, theme }) =>
    byStatusHelper.bgColor(state, colorStyle, checked, theme)};
  align-items: center;
  justify-content: center;
`;

export const Icon = styled(IconComp).attrs(({ theme }) => ({
  size: 10,
  color: theme.color.base.c0,
}))``;

interface IIconWrapper {
  state: SelectionControlState;
}
export const IconWrapper = styled.View<IIconWrapper>`
  width: 21px;
  height: 21px;
  border-radius: 21px;
  background-color: ${({ state, theme }) =>
    byStatusHelper.bgColor(state, 'primary', true, theme)};
  align-items: center;
  justify-content: center;
`;
