/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query Event
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { Event, EventVariables } from './types/Event';
import { EVENT } from './';

export function useEventQuery(
  options?: QueryHookOptions<Event, EventVariables>,
): QueryResult<Event, EventVariables> {
  return useQuery<Event, EventVariables>(EVENT, options);
}

export function useEventLazyQuery(
  options?: LazyQueryHookOptions<Event, EventVariables>,
): QueryTuple<Event, EventVariables> {
  return useLazyQuery<Event, EventVariables>(EVENT, options);
}

export function createEventMock({
  variables,
  data,
  error,
}: {
  variables?: EventVariables;
  data?: Event;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: EVENT,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
