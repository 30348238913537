import styled from 'styled-components/native';

export const Container = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})`
  background-color: ${({ theme }) => theme.color.base.c1};
  border-radius: 5px;
  padding: 5px;
  flex-direction: row;
  align-items: center;
  margin-right: 8px;
`;

export const EmojiContainer = styled.View`
  background-color: ${({ theme }) => theme.color.base.c2};
  height: 42px;
  width: 42px;
  margin-right: 8px;
  border-radius: 21px;
  align-items: center;
  justify-content: center;
`;

export const EmojiText = styled.Text`
  font-size: 20px;
`;

export const Content = styled.View``;

export const QuestionText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 14px;
`;

export const QuestionType = styled.Text`
  margin-top; 8px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 10px;
`;
