import React, { useRef, useState, useEffect } from 'react';
import { Animated, Easing } from 'react-native';
import { IconName } from '~/components/Icon';
import {
  SafeAreaContainer,
  AnimatedContainer,
  Icon,
  Text,
  ActionButton,
  ActionText,
} from './style';

export const positions = {
  BOTTOM_STACK: 52,
  BOTTOM_TAB: 80,
  BOTTOM_CHANGE: 120,
};

export const durations = {
  LONG: 3500,
  SHORT: 2000,
};

interface SnackbarContentProps {
  message: string;
  opacity: Animated.Value;
  iconName?: IconName;
  actionText?: string;
  onActionPress?: () => void;
}

export function SnackbarContent({
  message,
  opacity,
  iconName,
  actionText,
  onActionPress,
}: SnackbarContentProps): JSX.Element {
  return (
    <AnimatedContainer
      style={{
        opacity,
      }}
    >
      {iconName && <Icon name={iconName} />}
      <Text>{message}</Text>
      {actionText && (
        <ActionButton onPress={() => onActionPress && onActionPress()}>
          <ActionText>{actionText}</ActionText>
        </ActionButton>
      )}
    </AnimatedContainer>
  );
}

export interface SnackbarOptionsProps {
  position: number;
  duration: number;
  iconName?: IconName;
  actionText?: string;
  onActionPress?: () => void;
}

interface SnackbarContainerProps extends SnackbarOptionsProps {
  message: string;
}

export default function SnackbarContainer({
  message,
  iconName,
  actionText,
  duration,
  position,
  onActionPress,
}: SnackbarContainerProps): JSX.Element | null {
  const { current: opacity } = useRef<Animated.Value>(new Animated.Value(0.0));
  const [visible, setVisible] = useState<boolean>(true);
  const [animating, setAnimating] = useState<boolean>(false);

  const hideTimeout = useRef<NodeJS.Timeout | undefined>(undefined);

  const show = () => {
    if (!animating) {
      if (hideTimeout.current) clearTimeout(hideTimeout.current);
      setAnimating(true);
      Animated.timing(opacity, {
        toValue: 1,
        duration: 200,
        easing: Easing.out(Easing.ease),
        useNativeDriver: true,
      }).start(({ finished }) => {
        if (finished) {
          setAnimating(false);
          if (duration > 0) {
            hideTimeout.current = setTimeout(
              () => hide(),
              duration,
            ) as unknown as NodeJS.Timeout;
          }
        }
      });
    }
  };

  const hide = () => {
    if (hideTimeout.current) clearTimeout(hideTimeout.current);
    if (!animating) {
      Animated.timing(opacity, {
        toValue: 0,
        duration: 200,
        easing: Easing.in(Easing.ease),
        useNativeDriver: true,
      }).start(({ finished }) => {
        if (finished) {
          setAnimating(false);
          setVisible(false);
        }
      });
    }
  };

  useEffect(() => {
    show();
  }, []);

  if (!visible && !animating) return null;

  return (
    <SafeAreaContainer position={position}>
      <SnackbarContent
        message={message}
        opacity={opacity}
        iconName={iconName}
        actionText={actionText}
        onActionPress={onActionPress}
      />
    </SafeAreaContainer>
  );
}
