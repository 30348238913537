import React, { useState } from 'react';
import { general } from '~/assets/imgs';
import AddModuleCell from '~/components/AddModuleCell';
import Button from '~/components/Button';
import NavHeader from '~/components/NavHeader';
import SectionHeader from '~/components/SectionHeader';
import UnderlineButton from '~/components/UnderlineButton';
import { Module } from '~/data/models/yearbook';
import { ModuleType } from '~/data/operations/global';
import { t } from '~/utils/i18n';
import {
  Container,
  ScrollView,
  Image,
  Descp,
  HistoryView,
  ModulesView,
  FormatsRow,
  FormatImage,
  Footer,
} from './style';

type ExportDataLayoutProps = {
  modules: Module[];
  exportLoading: boolean;
  onBack: () => void;
  onViewHistory: () => void;
  onSendLink: (moduleTypes: ModuleType[]) => void;
};

export default function ExportData({
  modules,
  exportLoading,
  onBack,
  onViewHistory,
  onSendLink,
}: ExportDataLayoutProps): JSX.Element {
  const [selectedModuleTypes, setSelectedModuleTypes] = useState<ModuleType[]>(
    [],
  );
  return (
    <Container>
      <NavHeader
        backButtonTestID={'buttonBack'}
        title={t('screens.exportData')}
        onBackPress={onBack}
      />
      <ScrollView>
        <Image source={general.exportData} resizeMode={'cover'} />
        <SectionHeader title={t('exportData.title')} />
        <Descp>{t('exportData.descp')}</Descp>
        <HistoryView>
          <UnderlineButton
            testID={'buttonViewHistory'}
            text={t('exportData.viewHistory')}
            onPress={onViewHistory}
          />
        </HistoryView>
        <SectionHeader title={t('exportData.modulesSection')} sub />
        <ModulesView>
          {modules.map((module) => (
            <AddModuleCell
              key={module.id}
              testID={`AddModuleCell:${module.id}`}
              module={module}
              isAdded={
                !!module.type && selectedModuleTypes.includes(module.type)
              }
              onAddPress={() => {
                if (module.type) {
                  selectedModuleTypes.includes(module.type)
                    ? setSelectedModuleTypes([
                        ...selectedModuleTypes.filter(
                          (mId) => mId !== module.type,
                        ),
                      ])
                    : setSelectedModuleTypes([
                        ...selectedModuleTypes,
                        module.type,
                      ]);
                }
              }}
            />
          ))}
        </ModulesView>
        <SectionHeader title={t('exportData.formatSection')} sub />
        <FormatsRow>
          <FormatImage source={general.excel} resizeMode={'contain'} />
          <FormatImage source={general.xml} resizeMode={'contain'} />
          <FormatImage source={general.zip} resizeMode={'contain'} />
        </FormatsRow>
        <Footer>
          <Button
            testID={'buttonSendLink'}
            text={t('exportData.buttonSendLink')}
            state={selectedModuleTypes.length === 0 ? 'disabled' : 'default'}
            loading={exportLoading}
            flex
            onPress={() => onSendLink(selectedModuleTypes)}
          />
        </Footer>
      </ScrollView>
    </Container>
  );
}
