/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation CreateProfilePageQuestion
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  CreateProfilePageQuestion,
  CreateProfilePageQuestionVariables,
} from './types/CreateProfilePageQuestion';
import { CREATE_PROFILE_PAGE_QUESTION } from './';

export function useCreateProfilePageQuestionMutation(
  options?: MutationHookOptions<
    CreateProfilePageQuestion,
    CreateProfilePageQuestionVariables
  >,
): MutationTuple<
  CreateProfilePageQuestion,
  CreateProfilePageQuestionVariables
> {
  return useMutation<
    CreateProfilePageQuestion,
    CreateProfilePageQuestionVariables
  >(CREATE_PROFILE_PAGE_QUESTION, options);
}

export function createCreateProfilePageQuestionMock({
  variables,
  data,
  error,
}: {
  variables?: CreateProfilePageQuestionVariables;
  data?: CreateProfilePageQuestion;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: CREATE_PROFILE_PAGE_QUESTION,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
