/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation DeleteInstituteRequest
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  DeleteInstituteRequest,
  DeleteInstituteRequestVariables,
} from './types/DeleteInstituteRequest';
import { DELETE_INSTITUTE_REQUEST } from './';

export function useDeleteInstituteRequestMutation(
  options?: MutationHookOptions<
    DeleteInstituteRequest,
    DeleteInstituteRequestVariables
  >,
): MutationTuple<DeleteInstituteRequest, DeleteInstituteRequestVariables> {
  return useMutation<DeleteInstituteRequest, DeleteInstituteRequestVariables>(
    DELETE_INSTITUTE_REQUEST,
    options,
  );
}

export function createDeleteInstituteRequestMock({
  variables,
  data,
  error,
}: {
  variables?: DeleteInstituteRequestVariables;
  data?: DeleteInstituteRequest;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: DELETE_INSTITUTE_REQUEST,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
