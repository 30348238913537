/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation AdminUpdateUser
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  AdminUpdateUser,
  AdminUpdateUserVariables,
} from './types/AdminUpdateUser';
import { ADMIN_UPDATE_USER } from './';

export function useAdminUpdateUserMutation(
  options?: MutationHookOptions<AdminUpdateUser, AdminUpdateUserVariables>,
): MutationTuple<AdminUpdateUser, AdminUpdateUserVariables> {
  return useMutation<AdminUpdateUser, AdminUpdateUserVariables>(
    ADMIN_UPDATE_USER,
    options,
  );
}

export function createAdminUpdateUserMock({
  variables,
  data,
  error,
}: {
  variables?: AdminUpdateUserVariables;
  data?: AdminUpdateUser;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: ADMIN_UPDATE_USER,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
