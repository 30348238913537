import React from 'react';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { DEFAULT_LIST_OFFSET } from '~/data/constants';
import { useCreateCustomListOptionMutation } from '~/data/operations/yearbook/createCustomListOption';
import {
  readCustomListOptionsQuery,
  writeCustomListOptionsQuery,
} from '~/data/operations/yearbook/helpers';
import { YearbookStackParamList } from '~/navigation/types';
import RankingsCustomListOptionLayout from './layout';

type RankingsCustomListOptionNavProp = StackNavigationProp<
  YearbookStackParamList,
  'RankingsCustomListOption'
>;

type RankingsCustomListOptionRouteProp = RouteProp<
  YearbookStackParamList,
  'RankingsCustomListOption'
>;

export default function RankingsCustomListOption(): JSX.Element {
  const navigation = useNavigation<RankingsCustomListOptionNavProp>();
  const {
    params: { customListId },
  } = useRoute<RankingsCustomListOptionRouteProp>();

  const listOptionsVar = {
    after: null,
    first: DEFAULT_LIST_OFFSET,
    customList: customListId,
  };

  const [createCustomListOption, { loading: createCustomListOptionLoading }] =
    useCreateCustomListOptionMutation({
      update(cache, { data }) {
        const newCustomListOption =
          data?.createCustomListOption?.customListOption;

        const customListOptionsQuery = readCustomListOptionsQuery({
          cache,
          variables: listOptionsVar,
        });

        if (
          customListOptionsQuery?.customListOptions?.edges &&
          newCustomListOption
        ) {
          writeCustomListOptionsQuery({
            cache,
            variables: listOptionsVar,
            data: {
              ...customListOptionsQuery,
              customListOptions: {
                ...customListOptionsQuery.customListOptions,
                edges: [
                  ...customListOptionsQuery.customListOptions.edges,
                  {
                    __typename: 'CustomListOptionNodeEdge',
                    node: newCustomListOption,
                  },
                ],
              },
            },
          });
        }
      },
    });

  const onCreateOption = async (text: string) => {
    try {
      await createCustomListOption({
        variables: {
          input: {
            customListId: customListId,
            text,
          },
        },
      });
      navigation.goBack();
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  return (
    <RankingsCustomListOptionLayout
      loading={createCustomListOptionLoading}
      onBack={() => navigation.goBack()}
      onCreateOption={onCreateOption}
    />
  );
}
