import { gql } from '@apollo/client';

// ADMIN SEARCH

export const ADMIN_SEARCH_USER_FIELDS = gql`
  fragment AdminSearchUser on SearchUserType {
    id
    firstName
    lastName
    phone
    email
    avatar
    phoneCountryCode
    groups {
      ...AdminSearchGroup
    }
  }
`;

export const ADMIN_SEARCH_GROUP_FIELDS = gql`
  fragment AdminSearchGroup on SearchGroupType {
    id
    name
    graduationYear
    type
    avatar
    institute {
      ...AdminSearchInstitute
    }
  }
`;

export const ADMIN_SEARCH_INSTITUTE_FIELDS = gql`
  fragment AdminSearchInstitute on SearchInstituteType {
    id
    name
    internalId
    country
    city
    zip
    type
  }
`;

export const ADMIN_SEARCH = gql`
  ${ADMIN_SEARCH_USER_FIELDS}
  ${ADMIN_SEARCH_GROUP_FIELDS}
  ${ADMIN_SEARCH_INSTITUTE_FIELDS}

  query AdminSearch($searchString: String!) {
    adminSearchResults(custom: $searchString) {
      users {
        ...AdminSearchUser
      }
      groups {
        ...AdminSearchGroup
      }
      institutes {
        ...AdminSearchInstitute
      }
    }
  }
`;

// EDIT GROUP

export const ADMIN_GROUP_MEMBER_FIELDS = gql`
  fragment AdminGroupMemberFields on GroupMemberNode {
    id
    firstName
    lastName
    avatar
    email
    phone
    phoneCountryCode
    role
    status
    user {
      id
    }
    teams {
      id
      name
      avatar
      icon {
        icon
      }
      color {
        hex
      }
      group {
        id
      }
    }
  }
`;

export const ADMIN_GROUP_INSTITUTE_FIELDS = gql`
  fragment AdminGroupInstituteFields on InstituteNode {
    name
    id
    internalId
    type {
      name
      graduationTypes {
        id
        name
      }
    }
    country
    street
    zip
    city
  }
`;

export const ADMIN_GROUP_FIELDS = gql`
  ${ADMIN_GROUP_MEMBER_FIELDS}
  ${ADMIN_GROUP_INSTITUTE_FIELDS}
  fragment AdminGroupFields on GroupNode {
    id
    name
    avatar
    created
    token
    isActive
    isImported
    type {
      id
      name
    }
    year
    allMembers {
      ...AdminGroupMemberFields
    }
    institute {
      ...AdminGroupInstituteFields
    }
  }
`;

export const ADMIN_GET_GROUP = gql`
  ${ADMIN_GROUP_FIELDS}

  query AdminGetGroup($id: ID!) {
    group(id: $id) {
      ...AdminGroupFields
    }
  }
`;

export const ADMIN_TOGGLE_GROUP_ACTIVE = gql`
  ${ADMIN_GROUP_FIELDS}
  mutation AdminToggleGroupActive($input: ToggleIsActiveGroupInput!) {
    toggleIsActiveGroup(input: $input) {
      group {
        ...AdminGroupFields
      }
    }
  }
`;

export const ADMIN_MOVE_GROUP_TO_INSTITUTE = gql`
  ${ADMIN_GROUP_FIELDS}
  mutation AdminMoveGroupToInstitute($input: MoveGroupToInstituteInput!) {
    moveGroupToInstitute(input: $input) {
      group {
        ...AdminGroupFields
      }
    }
  }
`;

// EDIT USER

export const ADMIN_USER_TEAMS_FIELDS = gql`
  fragment AdminUserTeamsFields on TeamNode {
    id
    name
    avatar
    icon {
      icon
    }
    color {
      hex
    }
    group {
      id
    }
  }
`;

export const ADMIN_USER_GROUPS_FIELDS = gql`
  fragment AdminUserGroupsFields on GroupNode {
    id
    name
    avatar
    year
    icon {
      icon
    }
    institute {
      id
      name
      internalId
      country
    }
    type {
      name
    }
    users {
      id
      status
      user {
        id
      }
    }
    teams {
      id
      name
    }
  }
`;

export const ADMIN_USER_FIELDS = gql`
  ${ADMIN_USER_TEAMS_FIELDS}
  ${ADMIN_USER_GROUPS_FIELDS}
  fragment AdminUserFields on UserNode {
    id
    firstName
    lastName
    email
    phoneCountryCode
    phone
    gender
    isActive
    optIn
    created
    isImported
    isAgent
    isSuperuser
    avatar
    groups {
      ...AdminUserGroupsFields
    }
    teams {
      ...AdminUserTeamsFields
    }
  }
`;

export const ADMIN_GET_USER = gql`
  ${ADMIN_USER_FIELDS}

  query AdminGetUser($id: ID!) {
    user(id: $id) {
      ...AdminUserFields
    }
  }
`;

export const ADMIN_GET_GRADOO_STAFF = gql`
  ${ADMIN_USER_FIELDS}
  query AdminGetGradooStaff(
    $offset: Int
    $after: String
    $first: Int
    $searchNameEmail: String
  ) {
    users(
      offset: $offset
      after: $after
      first: $first
      isStaff: true
      searchNameEmail: $searchNameEmail
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...AdminUserFields
        }
      }
      totalCount
    }
  }
`;

export const ADMIN_GET_USERS = gql`
  ${ADMIN_USER_FIELDS}
  query AdminGetUsers(
    $offset: Int
    $after: String
    $first: Int
    $firstName: String
    $lastName: String
    $email: String
    $isAgent: Boolean
    $isSuperuser: Boolean
    $isStaff: Boolean
    $searchNameEmail: String
  ) {
    users(
      offset: $offset
      after: $after
      first: $first
      firstName: $firstName
      lastName: $lastName
      email: $email
      isAgent: $isAgent
      isSuperuser: $isSuperuser
      isStaff: $isStaff
      searchNameEmail: $searchNameEmail
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...AdminUserFields
        }
      }
      totalCount
    }
  }
`;

export const ADMIN_UPDATE_USER = gql`
  ${ADMIN_USER_FIELDS}
  mutation AdminUpdateUser($input: AdminUpdateUserInput!) {
    adminUpdateUser(input: $input) {
      user {
        ...AdminUserFields
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const ADMIN_UPGRADE_USER = gql`
  ${ADMIN_USER_FIELDS}
  mutation AdminUpgradeUser($input: AdminUpgradeUserInput!) {
    adminUpgradeUser(input: $input) {
      user {
        ...AdminUserFields
      }
    }
  }
`;

export const ADMIN_DOWNGRADE_USER = gql`
  ${ADMIN_USER_FIELDS}
  mutation AdminDowngradeUser($input: AdminDowngradeUserInput!) {
    adminDowngradeUser(input: $input) {
      user {
        ...AdminUserFields
      }
    }
  }
`;

export const ADMIN_CLEAR_USER_FLAGS = gql`
  ${ADMIN_USER_FIELDS}
  mutation AdminClearUserFlags($input: AdminClearUserFlagsInput!) {
    adminClearUserFlags(input: $input) {
      user {
        ...AdminUserFields
      }
    }
  }
`;

export const ADMIN_FORGET_PASSWORD_LINK = gql`
  mutation AdminForgetPasswordLink($input: AdminForgetPasswordLinkInput!) {
    adminForgetPasswordLink(input: $input) {
      forgetPasswordLink
    }
  }
`;

export const ADMIN_ADD_USER_TO_TEAM = gql`
  ${ADMIN_USER_FIELDS}
  mutation AdminAddUserToTeam($input: AdminAddUserToTeamInput!) {
    adminAddUserToTeam(input: $input) {
      user {
        ...AdminUserFields
      }
    }
  }
`;

export const ADMIN_DELETE_USER = gql`
  ${ADMIN_USER_FIELDS}
  mutation AdminDeleteUser($input: AdminDeleteUserInput!) {
    adminDeleteUser(input: $input) {
      user {
        ...AdminUserFields
      }
    }
  }
`;

// EDIT INSTITUTE

export const ADMIN_INSTITUTE_GROUP_FIELDS = gql`
  fragment AdminInstituteGroupFields on GroupNode {
    id
    name
    type {
      name
    }
    year
    avatar
    icon {
      icon
    }
    users {
      id
    }
    institute {
      country
      internalId
    }
  }
`;

export const ADMIN_INSTITUTE_FIELDS = gql`
  ${ADMIN_INSTITUTE_GROUP_FIELDS}
  fragment AdminInstituteFields on InstituteNode {
    id
    name
    street
    internalId
    city
    zip
    country
    website
    type {
      id
      name
    }
    marketProfile {
      id
      name
    }
    groups {
      ...AdminInstituteGroupFields
    }
    instituteActivity {
      year
      status
    }
  }
`;

export const ADMIN_GET_INSTITUTE = gql`
  ${ADMIN_INSTITUTE_FIELDS}
  query AdminGetInstitute($id: ID!) {
    institute(id: $id) {
      ...AdminInstituteFields
    }
  }
`;

export const ADMIN_UPDATE_INSTITUTE = gql`
  ${ADMIN_INSTITUTE_FIELDS}
  mutation AdminUpdateInstitute($input: AdminUpdateInstituteInput!) {
    adminUpdateInstitute(input: $input) {
      institute {
        ...AdminInstituteFields
      }
      errors {
        field
        messages
      }
    }
  }
`;

// ________### Targeting config for Ads feature ###________

// Institutes without groups in current graduation year
// Institutes with inactive groups (<15 members) in current graduation year
// Institutes with active groups (>=15 members) in current graduation year

export const INSTITUTE_TARGETING_FIELDS = gql`
  fragment InstituteTargetingFields on InstituteTargetingNode {
    id
    location
    activity
  }
`;

export const INSTITUTES_TARGETING = gql`
  ${INSTITUTE_TARGETING_FIELDS}
  query InstitutesTargeting($targetingFilters: [TargetingFilterInput]) {
    institutesTargeting(targetingFilters: $targetingFilters) {
      usersCount
      activeGroupsCount
      inactiveGroupsCount
      institutes {
        ...InstituteTargetingFields
      }
    }
  }
`;
