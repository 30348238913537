/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query QuotesInstances
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  QuotesInstances,
  QuotesInstancesVariables,
} from './types/QuotesInstances';
import { QUOTES_INSTANCES } from './';

export function useQuotesInstancesQuery(
  options?: QueryHookOptions<QuotesInstances, QuotesInstancesVariables>,
): QueryResult<QuotesInstances, QuotesInstancesVariables> {
  return useQuery<QuotesInstances, QuotesInstancesVariables>(
    QUOTES_INSTANCES,
    options,
  );
}

export function useQuotesInstancesLazyQuery(
  options?: LazyQueryHookOptions<QuotesInstances, QuotesInstancesVariables>,
): QueryTuple<QuotesInstances, QuotesInstancesVariables> {
  return useLazyQuery<QuotesInstances, QuotesInstancesVariables>(
    QUOTES_INSTANCES,
    options,
  );
}

export function createQuotesInstancesMock({
  variables,
  data,
  error,
}: {
  variables?: QuotesInstancesVariables;
  data?: QuotesInstances;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: QUOTES_INSTANCES,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
