import { ApolloCache } from '@apollo/client';
import { GROUP_FIELDS, GROUP_USERS, USER_GROUPS, USER_GROUP_FIELDS } from './';
import { GroupFields } from './types/GroupFields';
import { GroupUsers, GroupUsersVariables } from './types/GroupUsers';
import { UserGroupFields } from './types/UserGroupFields';
import { UserGroups, UserGroupsVariables } from './types/UserGroups';

export function readUserGroupsQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: UserGroupsVariables;
}): UserGroups | null {
  return cache.readQuery<UserGroups>({
    query: USER_GROUPS,
    variables,
  });
}

export function writeUserGroupsQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: UserGroupsVariables;
  data: UserGroups;
}): void {
  cache.writeQuery<UserGroups>({
    query: USER_GROUPS,
    variables,
    data,
  });
}

export function readGroupUsersQuery({
  cache,
  variables,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: GroupUsersVariables;
}): GroupUsers | null {
  return cache.readQuery<GroupUsers>({
    query: GROUP_USERS,
    variables,
  });
}

export function writeGroupUsersQuery({
  cache,
  variables,
  data,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  variables?: GroupUsersVariables;
  data: GroupUsers;
}): void {
  cache.writeQuery<GroupUsers>({
    query: GROUP_USERS,
    variables,
    data,
  });
}

export function readUserGroupFragment({
  cache,
  id,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
}): UserGroupFields | null {
  return cache.readFragment<UserGroupFields>({
    id: `UserGroupNode:${id}`,
    fragment: USER_GROUP_FIELDS,
    fragmentName: 'UserGroupFields',
  });
}

export function readGroupFragment({
  cache,
  id,
}: {
  cache: ApolloCache<any>; //eslint-disable-line
  id: string;
}): GroupFields | null {
  return cache.readFragment<GroupFields>({
    id: `GroupNode:${id}`,
    fragment: GROUP_FIELDS,
    fragmentName: 'GroupFields',
  });
}
