import React from 'react';
import { ContainerButton, Text } from './style';
import { UnderlineButtonType } from './types';

type UnderlineButtonProps = {
  testID?: string;
  type?: UnderlineButtonType;
  text: string;
  underline?: boolean; //a bit redundant, we could change to OutlineButton or smtg
  onPress: () => void;
};

export default function UnderlineButton({
  testID,
  type = 'default',
  text,
  underline = true,
  onPress,
}: UnderlineButtonProps): JSX.Element {
  return (
    <ContainerButton testID={testID} onPress={onPress}>
      <Text type={type} underline={underline}>
        {text}
      </Text>
    </ContainerButton>
  );
}
