import React from 'react';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import useTeams from '~/data/hooks/useTeams';
import { useModuleInstanceQuery } from '~/data/operations/yearbook/moduleInstance';
import { useUpdateCollagesSetupMutation } from '~/data/operations/yearbook/updateCollagesSetup';
import { useUpdateModuleInstanceMutation } from '~/data/operations/yearbook/updateModuleInstance';
import { YearbookStackParamList } from '~/navigation/types';
import { t } from '~/utils/i18n';
import {
  ModuleSetupLayoutLoading,
  UpdateModuleInstanceInput,
} from '../layout/ModuleSetupLayout';
import CollagesSetupLayout, { UpdateCollagesSetupInput } from './layout';

type CollagesSetupNavProp = StackNavigationProp<
  YearbookStackParamList,
  'CollagesSetup'
>;
type CollagesSetupRouteProp = RouteProp<
  YearbookStackParamList,
  'CollagesSetup'
>;

export default function CollagesSetup(): JSX.Element {
  const navigation = useNavigation<CollagesSetupNavProp>();
  const {
    params: { moduleInstanceId },
  } = useRoute<CollagesSetupRouteProp>();

  const { data: moduleInstanceData, loading: moduleInstanceLoading } =
    useModuleInstanceQuery({
      variables: {
        id: moduleInstanceId,
      },
    });

  const {
    teams,
    loading: teamsLoading,
    onLoadMore: onLoadMoreTeams,
  } = useTeams();

  const moduleInstance = moduleInstanceData?.moduleInstance;

  const collagesSetupConfig = moduleInstanceData?.moduleInstance?.collagesSetup;

  const [updateModuleInstance] = useUpdateModuleInstanceMutation();
  const [updateCollagesSetup] = useUpdateCollagesSetupMutation();

  const onUpdateModuleInstance = async ({
    isActive,
    dueDate,
  }: UpdateModuleInstanceInput) => {
    try {
      await updateModuleInstance({
        variables: {
          input: {
            id: moduleInstanceId,
            isActive,
            dueDate,
          },
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onUpdateCollagesSetup = async ({
    createAlbumsTeamId,
    addPhotosTeamId,
    viewPhotosTeamId,
  }: UpdateCollagesSetupInput) => {
    try {
      if (moduleInstance && collagesSetupConfig) {
        await updateCollagesSetup({
          variables: {
            input: {
              id: collagesSetupConfig.id,
              isActive: moduleInstance.isActive,
              dueDate: moduleInstance.dueDate,
              createAlbumsTeam: createAlbumsTeamId,
              addPhotosTeam: addPhotosTeamId,
              viewPhotosTeam: viewPhotosTeamId,
            },
          },
        });
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  if (
    !moduleInstance ||
    !collagesSetupConfig ||
    moduleInstanceLoading ||
    teamsLoading
  ) {
    return (
      <ModuleSetupLayoutLoading
        title={t('screens.collagesSetup')}
        onBack={() => navigation.goBack()}
      />
    );
  }

  return (
    <CollagesSetupLayout
      moduleInstance={moduleInstance}
      collagesSetupConfig={collagesSetupConfig}
      teams={teams}
      onBack={() => navigation.goBack()}
      onUpdateModuleInstance={onUpdateModuleInstance}
      onUpdateCollagesSetup={onUpdateCollagesSetup}
      onLoadMoreTeams={onLoadMoreTeams}
    />
  );
}
