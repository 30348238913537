import { Dimensions } from 'react-native';
import styled from 'styled-components/native';
import ButtonIcon from '~/components/ButtonIcon';
import Image from '~/components/Image';
import { TabScreenContainer } from '~/screens/style';
const { width } = Dimensions.get('window');

export const NUM_COLUMNS = 4;
const BASE_CONTAINER_PADDING = 16;
const INNER_CARD_PADDING = 8;
const CARD_SIZE =
  (width -
    BASE_CONTAINER_PADDING * 2 -
    INNER_CARD_PADDING * (NUM_COLUMNS - 1)) /
  NUM_COLUMNS;

export const Container = styled(TabScreenContainer)`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const HeaderRightContainer = styled.View`
  flex-direction: row;
`;

export const EditButtonIcon = styled(ButtonIcon).attrs(({ theme }) => ({
  name: 'edit-05',
  color: theme.color.base.c9,
  size: 20,
}))`
  margin-right: 16px;
`;

export const AddButtonIcon = styled(ButtonIcon).attrs(({ theme }) => ({
  name: 'plus',
  color: theme.color.base.c9,
  size: 20,
}))``;

export const PhotoList = styled.FlatList`
  padding-top: 24px;
  padding-horizontal: ${BASE_CONTAINER_PADDING}px;
`;

export const PhotoCard = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})`
  width: ${CARD_SIZE}px;
  height: ${CARD_SIZE}px;
  margin-bottom: ${INNER_CARD_PADDING}px;
  margin-right: ${INNER_CARD_PADDING}px;
  border-radius: 8px;
  overflow: hidden;
`;

export const PhotoImage = styled(Image)`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c1};
`;

export const LoadingView = styled.View`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  align-items: center;
  justify-content: center;
`;

export const Loading = styled.ActivityIndicator``;

export const Footer = styled.View`
  padding-vertical: 24px;
`;

export const RefreshControl = styled.RefreshControl.attrs(({ theme }) => ({
  color: theme.color.base.c4,
}))``;
