/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation DestroyProfilePagePhotoCategory
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  DestroyProfilePagePhotoCategory,
  DestroyProfilePagePhotoCategoryVariables,
} from './types/DestroyProfilePagePhotoCategory';
import { DESTROY_PROFILE_PAGE_PHOTO_CATEGORY } from './';

export function useDestroyProfilePagePhotoCategoryMutation(
  options?: MutationHookOptions<
    DestroyProfilePagePhotoCategory,
    DestroyProfilePagePhotoCategoryVariables
  >,
): MutationTuple<
  DestroyProfilePagePhotoCategory,
  DestroyProfilePagePhotoCategoryVariables
> {
  return useMutation<
    DestroyProfilePagePhotoCategory,
    DestroyProfilePagePhotoCategoryVariables
  >(DESTROY_PROFILE_PAGE_PHOTO_CATEGORY, options);
}

export function createDestroyProfilePagePhotoCategoryMock({
  variables,
  data,
  error,
}: {
  variables?: DestroyProfilePagePhotoCategoryVariables;
  data?: DestroyProfilePagePhotoCategory;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: DESTROY_PROFILE_PAGE_PHOTO_CATEGORY,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
