import styled from 'styled-components/native';
import { ResponsiveProps } from '~/components/common/style.web';

const CELL_WIDTH_MOBILE = 75;
const CELL_WIDTH_DESKTOP = 94;
const CELL_GAP = 4;

export const Container = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  overflow-y: auto;
`;

export const Text = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 14px;
`;

export const RowOverview = styled.View`
  flex-direction: row;
  margin-bottom: ${CELL_GAP}px;
`;

export const RowCell = styled.View<ResponsiveProps>`
  width: ${({ isDesktop }) =>
    isDesktop ? CELL_WIDTH_DESKTOP : CELL_WIDTH_MOBILE}px;
  height: 24px;
  background-color: ${({ status, theme }) =>
    status === 'ACTIVE'
      ? theme.color.brand_02
      : status === 'DECLINE'
      ? theme.color.base.c0
      : status === 'INACTIVE'
      ? theme.color.warning
      : status === 'SUCCESS'
      ? '#E2F6ED'
      : theme.color.base.c2};
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin-right: ${CELL_GAP}px;
`;
export const RowCellText = styled.Text<ResponsiveProps>`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ status, theme }) =>
    status === 'ACTIVE'
      ? theme.color.base.c0
      : status === 'DECLINE'
      ? theme.color.error
      : status === 'INACTIVE'
      ? theme.color.brand_02
      : status === 'SUCCESS' && '#215240'};
  font-size: ${({ isDesktop }) => (isDesktop ? '10px' : '8px')};
  text-transform: capitalize;
`;

export const IconContainer = styled.View`
  align-items: center;
`;

export const RowYearCell = styled.View<ResponsiveProps>`
  width: ${({ isDesktop }) =>
    isDesktop ? CELL_WIDTH_DESKTOP : CELL_WIDTH_MOBILE}px;
  height: 24px;
  background-color: ${({ theme }) => theme.color.base.c0};
  border-radius: none;
  align-items: center;
  justify-content: center;
  margin-top: ${CELL_GAP}px;
  margin-right: ${CELL_GAP}px;
`;

export const RowYearCellText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 16px;
`;
