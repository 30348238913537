import styled from 'styled-components/native';
import Icon from '~/components/Icon';

export const Container = styled.View``;

export const Content = styled.TouchableOpacity`
  background-color: ${({ theme }) => theme.color.base.c0};
  border-color: ${({ theme }) => theme.color.base.c3};
  border-width: 1px;
  border-radius: 14px;
  padding: 24px;
`;

export const Text = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 16px;
  line-height: 20px;
`;

export const Footer = styled.View`
  flex-direction: row;
  padding-vertical: 16px;
`;

export const FooterContent = styled.View`
  flex: 1;
  padding-horizontal: 8px;
`;

export const Name = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 12px;
  line-height: 20px;
`;

export const Date = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c5};
  font-size: 12px;
  line-height: 14px;
`;

export const ButtonContainer = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})`
  padding-horizontal: 8px;
  align-items: center;
`;

export const ReportIcon = styled(Icon).attrs(({ theme }) => ({
  name: 'alert-triangle',
  size: 16,
  color: theme.color.brand_02,
}))``;

export const ReportText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.brand_02};
  font-size: 12px;
  line-height: 14px;
`;

export const DeleteIcon = styled(Icon).attrs(({ theme }) => ({
  name: 'trash-01',
  size: 16,
  color: theme.color.error,
}))``;

export const DeleteText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.error};
  font-size: 12px;
  line-height: 14px;
`;
