import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { ImporterStackParamList } from '~/navigation/types';
import AbihomeLogin from '~/screens/Importer/AbihomeLogin';
import ImportStatus from '~/screens/Importer/ImportStatus';
import Overview from '~/screens/Importer/Overview';
import SetupGroup from '~/screens/Importer/SetupGroup';

const ImporterStackNavigator = createStackNavigator<ImporterStackParamList>();

export default function ImporterStack(): JSX.Element {
  return (
    <ImporterStackNavigator.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <ImporterStackNavigator.Screen
        name="AbihomeLogin"
        component={AbihomeLogin}
      />
      <ImporterStackNavigator.Screen name="SetupGroup" component={SetupGroup} />
      <ImporterStackNavigator.Screen name="Overview" component={Overview} />
      <ImporterStackNavigator.Screen
        name="ImportStatus"
        component={ImportStatus}
      />
    </ImporterStackNavigator.Navigator>
  );
}
