import styled from 'styled-components/native';
import ButtonIcon from '../ButtonIcon';
import { SectionLabel } from '../common/style.web';

export const Container = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Text = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 14px;
`;

export const IconButton = styled(ButtonIcon).attrs(({ theme, styledName }) => ({
  styledName,
  size: 24,
  color: theme.color.base.c0,
}))`
  width: 45px;
  height: 45px;
  border-radius: 50px;
  background-color: ${({ theme }) => theme.color.brand_02};
  align-items: center;
  justify-content: center;
`;

export const YearContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const YearText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 16px;
  padding: 0 10px 0 10px;
`;

type ISectionLabelProps = {
  sectionColor?: string;
};
export const SectionYearLabel = styled(SectionLabel)<ISectionLabelProps>`
  color: ${({ theme, sectionColor }) =>
    sectionColor ? sectionColor : theme.color.base.c9};
`;
