import React, { FC } from 'react';
import { Dimensions } from 'react-native';
import { NeomorphBox } from 'react-native-neomorph-shadows';
import { useTheme } from 'styled-components/native';
import { general } from '~/assets/imgs';
import Avatar from '~/components/Avatar';
import Icon from '~/components/Icon';
import { t } from '~/utils/i18n';
import {
  Badge,
  Content,
  Desc,
  IconContainer,
  PressableContainer,
  TextContainer,
  Title,
  BadgeText,
} from './style';

const { width } = Dimensions.get('window');
const DEFAULT_PADDING = 16;

type HelpCellProps = {
  onPress?: () => void;
  unReadMessageCount?: number;
  testID?: string;
};

export const HelpCell: FC<HelpCellProps> = ({
  onPress,
  unReadMessageCount,
  testID,
}) => {
  const theme = useTheme();

  return (
    <PressableContainer onPress={onPress} testID={testID}>
      <NeomorphBox
        inner
        style={{
          flexDirection: 'row',
          borderRadius: 50,
          shadowRadius: 1,
          backgroundColor: theme.color.brand_01,
          width: width - DEFAULT_PADDING * 2,
          height: 80,
          shadowOpacity: 0.7,
          shadowOffset: { width: 0, height: -1 },
          overflow: 'hidden',
        }}
      >
        <Content>
          {/* Left: Avatar */}
          <Avatar size={56} localImage={general.help_avatar} />

          {/* Middle: Text */}
          <TextContainer>
            <Title>{t('profile.helpTitle')} </Title>
            <Desc>{t('profile.helpDesc')}</Desc>
          </TextContainer>

          {/* Right: Icon */}
          <IconContainer>
            <Icon
              color={theme.color.brand_02}
              size={20}
              name="message-dots-circle"
            />
            {Boolean(unReadMessageCount) && (
              <Badge>
                <BadgeText>{unReadMessageCount}</BadgeText>
              </Badge>
            )}
          </IconContainer>
        </Content>
      </NeomorphBox>
    </PressableContainer>
  );
};
