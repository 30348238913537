/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation ImportToErp
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { ImportToErp, ImportToErpVariables } from './types/ImportToErp';
import { IMPORT_TO_ERP } from './';

export function useImportToErpMutation(
  options?: MutationHookOptions<ImportToErp, ImportToErpVariables>,
): MutationTuple<ImportToErp, ImportToErpVariables> {
  return useMutation<ImportToErp, ImportToErpVariables>(IMPORT_TO_ERP, options);
}

export function createImportToErpMock({
  variables,
  data,
  error,
}: {
  variables?: ImportToErpVariables;
  data?: ImportToErp;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: IMPORT_TO_ERP,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
