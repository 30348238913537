import * as Sentry from 'sentry-expo';

//known issues that does not result in a crash and does not
//  ends in a major problem. marked as a level 'error' on sentry
const knownIssues: string[] = [];

export function trackError(e: unknown) {
  if (e instanceof Error && !knownIssues.includes(e.message)) {
    Sentry.Browser.captureException(e);
  }
}

export const customSentryTagUser = (userId?: string | null) => {
  if (!userId) {
    return;
  }
  Sentry.Browser.setUser({ userId });
  Sentry.Browser.setTag('user_id_base64', userId);
};
