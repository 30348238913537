import { Dimensions } from 'react-native';
import styled from 'styled-components/native';
import ButtonIcon from '~/components/ButtonIcon';
import Image from '~/components/Image';

const IMAGE_WIDTH = Dimensions.get('window').width - 32;

export const Container = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
`;

interface ITitle {
  largeTitle: boolean;
}
export const Title = styled.Text<ITitle>`
  font-family: ${({ largeTitle, theme }) =>
    largeTitle ? theme.fontFamily.core.w600 : theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c10};
  font-size: ${({ largeTitle }) => (largeTitle ? '22px' : '18px;')};
`;

export const Descp = styled.Text`
  margin-top: 4px;
  margin-bottom: 8px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c7};
  font-size: 16px;
`;

interface IPoolOption {
  hasImg: boolean;
  voted: boolean;
  votedOption: boolean;
}
export const OptionButton = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})<IPoolOption>`
  background-color: ${({ votedOption, theme }) =>
    votedOption ? theme.color.brand_02 : theme.color.base.c0};
  margin-top: 8px;
  padding-horizontal: 16px;
  padding-vertical: ${({ hasImg }) => (hasImg ? '16px' : '8px')};
  flex-direction: row;
  align-items: center;
  border-color: ${({ voted, votedOption, theme }) =>
    votedOption
      ? theme.color.brand_02
      : voted
      ? theme.color.brand_02
      : theme.color.brand_02};
  border-width: 2px;
  border-radius: 100px;
  ${({ voted, votedOption }) => voted && !votedOption && 'opacity: 0.55;'}
`;

export const OptionContentImage = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})``;

export const OptionImage = styled(Image)`
  width: 56px;
  height: 56px;
  border-radius: 14px;
  margin-right: 12px;
  background-color: ${({ theme }) => theme.color.base.c1};
`;

interface IPoolOptionTitle {
  voted: boolean;
  votedOption: boolean;
  hasImg: boolean;
}
export const OptionTitle = styled.Text<IPoolOptionTitle>`
  flex: 1;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ votedOption, voted, theme }) =>
    votedOption
      ? theme.color.base.c2
      : voted
      ? theme.color.brand_02
      : theme.color.brand_02};
  font-size: ${({ hasImg }) => (hasImg ? '16px' : '14px')};
`;

interface IOptionResult {
  hasImg: boolean;
}
export const OptionResult = styled.View`
  margin-top: 8px;
`;

export const OptionResultContent = styled.View<IOptionResult>`
  padding-vertical: ${({ hasImg }) => (hasImg ? '16px' : '8px')};
  padding-horizontal: 16px;
  flex-direction: row;
  align-items: center;
`;

interface IOptionResultTitle extends IOptionResult {
  voted: boolean;
}
export const OptionResultTitle = styled.Text<IOptionResultTitle>`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ voted, theme }) =>
    voted ? theme.color.brand_02 : theme.color.base.c7};
  font-size: ${({ hasImg }) => (hasImg ? '16px' : '14px')};
  line-height: ${({ hasImg }) => (hasImg ? '20px' : '18px')};
`;

interface IOptionResultBG {
  voted: boolean;
  pct: number;
}
export const OptionResultBG = styled.View<IOptionResultBG>`
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  width: ${({ pct }) => pct}%;
  background-color: ${({ voted, theme }) =>
    voted ? theme.color.base.c3 : theme.color.base.c2};

  border-radius: 100px;
`;

export const OptionResultPct = styled.Text`
  margin-left: 16px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 14px;
`;

export const RevoteButton = styled(ButtonIcon).attrs(({ theme }) => ({
  name: 'refresh-ccw-01',
  size: 16,
  color: theme.color.brand_02,
}))`
  margin-left: 16px;
`;

export const FullImageModalContainer = styled.View`
  padding-vertical: 16px;
`;

export const FullImageCloseButtonIcon = styled(ButtonIcon).attrs(
  ({ theme }) => ({
    name: 'x-close',
    size: 24,
    color: theme.color.brand_02,
  }),
)`
  background-color: ${({ theme }) => theme.color.brand_02};
  align-self: flex-end;
  margin-bottom: 16px;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  align-items: center;
  justify-content: center;
`;

export const FullImage = styled(Image).attrs({
  autoHeightFromWidth: IMAGE_WIDTH,
})`
  width: ${IMAGE_WIDTH};
  border-radius: 14px;
  background-color: ${({ theme }) => theme.color.base.c1};
`;
