import { ColorSchema, FontCore, FontSize } from 'gradoo-theme/lib/types';
import styled from 'styled-components/native';

type ITextSize = keyof FontSize;

type ITextWeight = keyof FontCore;

type ITextColor = keyof ColorSchema;

export const fontSize = {
  //font-size: 28px 9
  head: '26px', //font-size: 26px 14
  //font-size: 24px 3
  //font-size: 22px 14
  subhead: '20px', //font-size: 20px 13
  title: '18px', //font-size: 18px 60
  //font-size: 17px 2
  body: '16px', //font-size: 16px 128
  body2: '14px', //font-size: 14px 113
  footnote: '12px', //font-size: 12px 72
  //font-size: 11px 6
  //font-size: 10px 17
};

export interface IText {
  size?: ITextSize;
  weight?: ITextWeight;
  color?: ITextColor;
}

export default styled.Text<IText>`
  font-size: ${({ size = 'body', theme }) => theme.fontSize[size]};
  font-family: ${({ weight = 'w400', theme }) => theme.fontFamily.core[weight]};
  color: ${({ color = 'c10', theme }) => theme.color.base[color]};
`;
