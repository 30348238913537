import styled from 'styled-components/native';
import Avatar from '~/components/Avatar';
import TeamIcon from '~/components/TeamIcon';
import Image from '../Image';

export const Container = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  flex-direction: row;
  padding: 0px 16px 8px 0;
`;

export const FlatList = styled.FlatList`
  overflow-x: scroll;
  background-color: ${({ theme }) => theme.color.base.c0};
  flex: 1;
  flex-direction: row;
`;

export const AuthorTouchableView = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})`
  width: 110px;
  align-items: center;
`;

interface IAuthor {
  selected: boolean;
}
export const GroupAvatar = styled(Image)<IAuthor>`
  width: 80px;
  height: 80px;
  background-color: ${({ theme }) => theme.color.base.c3};
  border-color: ${({ selected, theme }) =>
    selected ? theme.color.brand_02 : theme.color.base.c0};
  border-width: 3px;
  border-radius: 15px;
`;
export const AuthorUser = styled(Avatar).attrs({
  size: 80,
})<IAuthor>`
  border-radius: 60px;
  border-color: ${({ selected, theme }) =>
    selected ? theme.color.brand_02 : theme.color.base.c0};
  border-width: 3px;
  align-items: center;
  justify-content: center;
`;

export const AuthorTeam = styled(TeamIcon).attrs({
  size: 80,
  iconSize: 32,
})<IAuthor>`
  border-color: ${({ selected, theme }) =>
    selected ? theme.color.brand_02 : theme.color.base.c0};
  border-width: 3px;
  border-radius: 15px;
`;

export const AuthorName = styled.Text<IAuthor>`
  margin-top: 2px;
  text-align: center;
  font-family: ${({ selected, theme }) =>
    selected ? theme.fontFamily.core.w600 : theme.fontFamily.core.w400};
  color: ${({ selected, theme }) =>
    selected ? theme.color.brand_02 : theme.color.base.c5};
  font-size: 14px;
`;
