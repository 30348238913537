import React from 'react';
import { TouchableOpacity } from 'react-native';
import { t } from '~/utils/i18n';
import { BreadcrumbsContainer, BreadcrumbLink, BreadcrumbArrow } from './style';

type BreadcrumbsProps = {
  routes: string[];
  onNavigatePress: (route: string) => void;
};

export default function Breadcrumbs({
  onNavigatePress,
  routes = [],
}: BreadcrumbsProps): JSX.Element {
  return (
    <BreadcrumbsContainer>
      {routes.length > 1 &&
        routes.map((routeName, routeIdx) => (
          <React.Fragment key={`bc:${routeName}:idx:${routeIdx}`}>
            <TouchableOpacity
              onPress={() => onNavigatePress(routeName)}
              key={`bc-route-${routeName}`}
              disabled={routeIdx === routes.length - 1}
            >
              <BreadcrumbLink enabled={routeIdx !== routes.length - 1}>
                {t(`breadcrumbs.${routeName}`)}
              </BreadcrumbLink>
            </TouchableOpacity>
            {routeIdx !== routes.length - 1 && (
              <BreadcrumbArrow>{'<'}</BreadcrumbArrow>
            )}
          </React.Fragment>
        ))}
    </BreadcrumbsContainer>
  );
}
