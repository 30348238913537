/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation AdminDeleteUser
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  AdminDeleteUser,
  AdminDeleteUserVariables,
} from './types/AdminDeleteUser';
import { ADMIN_DELETE_USER } from './';

export function useAdminDeleteUserMutation(
  options?: MutationHookOptions<AdminDeleteUser, AdminDeleteUserVariables>,
): MutationTuple<AdminDeleteUser, AdminDeleteUserVariables> {
  return useMutation<AdminDeleteUser, AdminDeleteUserVariables>(
    ADMIN_DELETE_USER,
    options,
  );
}

export function createAdminDeleteUserMock({
  variables,
  data,
  error,
}: {
  variables?: AdminDeleteUserVariables;
  data?: AdminDeleteUser;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: ADMIN_DELETE_USER,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
