/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query Campaigns
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { Campaigns, CampaignsVariables } from './types/Campaigns';
import { CAMPAIGNS } from './';

export function useCampaignsQuery(
  options?: QueryHookOptions<Campaigns, CampaignsVariables>,
): QueryResult<Campaigns, CampaignsVariables> {
  return useQuery<Campaigns, CampaignsVariables>(CAMPAIGNS, options);
}

export function useCampaignsLazyQuery(
  options?: LazyQueryHookOptions<Campaigns, CampaignsVariables>,
): QueryTuple<Campaigns, CampaignsVariables> {
  return useLazyQuery<Campaigns, CampaignsVariables>(CAMPAIGNS, options);
}

export function createCampaignsMock({
  variables,
  data,
  error,
}: {
  variables?: CampaignsVariables;
  data?: Campaigns;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: CAMPAIGNS,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
