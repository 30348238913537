import React from 'react';
import { Container, Dot } from './style';

type ProgressProps = {
  current: number;
  max: number;
  onSelectedPage: (index: number) => void;
};

export default function Progress({
  current,
  max,
  onSelectedPage,
}: ProgressProps): JSX.Element {
  return (
    <Container>
      {Array.from({ length: max }, (_, i) => {
        const active = i <= current;
        return (
          <Dot
            key={i}
            active={active}
            onPress={() => active && onSelectedPage(i)}
          />
        );
      })}
    </Container>
  );
}
