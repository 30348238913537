import styled from 'styled-components/native';
import ButtonIcon from '~/components/ButtonIcon';
import { StackScreenContainer } from '~/screens/style';

export const Container = styled(StackScreenContainer)`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const SettingsButtonIconIcon = styled(ButtonIcon).attrs(({ theme }) => ({
  styledName: 'settings',
  color: theme.color.base.c7,
  size: 24,
}))``;
