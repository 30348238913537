/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation CreateGroup
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { CreateGroup, CreateGroupVariables } from './types/CreateGroup';
import { CREATE_GROUP } from './';

export function useCreateGroupMutation(
  options?: MutationHookOptions<CreateGroup, CreateGroupVariables>,
): MutationTuple<CreateGroup, CreateGroupVariables> {
  return useMutation<CreateGroup, CreateGroupVariables>(CREATE_GROUP, options);
}

export function createCreateGroupMock({
  variables,
  data,
  error,
}: {
  variables?: CreateGroupVariables;
  data?: CreateGroup;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: CREATE_GROUP,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
