import React from 'react';
import CardOptionCell from '~/components/CardOptionCell';
import { t } from '~/utils/i18n';
import { CHANNEL_TYPES } from '..';
import {
  Container,
  ChannelWapper,
  Title,
  ChooseChannelTitle,
  ChooseChannelContainer,
} from './style';

type ChannelSelectionProps = {
  setSelectChannel: (channel: CHANNEL_TYPES) => void;
  selectChannel: CHANNEL_TYPES;
};

export default function ChannelSelection({
  setSelectChannel,
  selectChannel,
}: ChannelSelectionProps): JSX.Element {
  const channelTitle = `createAssets.chooseChannel.channels.${selectChannel
    .toString()
    .toLowerCase()}`;

  return (
    <Container testID="createAssetModalChannels">
      <Title>{t('createAssets.chooseChannel.title')}</Title>
      <ChooseChannelContainer>
        <ChooseChannelTitle>
          {t('createAssets.chooseChannel.subTitle')}
        </ChooseChannelTitle>
        <ChannelWapper>
          <CardOptionCell
            testID={`channelOption_${selectChannel.toString()}`}
            text={t(channelTitle)}
            isSelected={true}
            onPress={() => setSelectChannel(selectChannel)}
          />
        </ChannelWapper>
      </ChooseChannelContainer>
    </Container>
  );
}
