import React from 'react';
import { IconName } from '~/components/Icon';
import TeamIcon from '~/components/TeamIcon';
import { Team } from '~/data/models/team';
import Shimmer from '../Shimmer';
import {
  Container,
  Name,
  CustomButtonIcon,
  CustomIconType,
  SkeletonContainer,
  SkeletonIcon,
  SkeletonText,
  SkeletonArrow,
} from './style';

type CustomIcon = 'arrow' | 'add' | 'check';

type TeamCellProps = {
  testID?: string;
  team?: Team;
  customIcon?: CustomIcon;
  customIconType?: CustomIconType;
  onCustomIconPress?: () => void;
};

const customIconMap: { [key in CustomIcon]: IconName } = {
  arrow: 'chevron-right',
  add: 'plus',
  check: 'check-circle',
};

export default function TeamCell({
  testID,
  team,
  customIcon,
  customIconType = 'default',
  onCustomIconPress,
}: TeamCellProps): JSX.Element {
  if (!team) {
    return (
      <SkeletonContainer>
        <SkeletonIcon>
          <Shimmer h={20} w={50} />
        </SkeletonIcon>
        <SkeletonText>
          <Shimmer h={20} w={200} />
        </SkeletonText>
        <SkeletonArrow>
          <Shimmer h={20} w={50} />
        </SkeletonArrow>
      </SkeletonContainer>
    );
  }

  const customIconName = customIcon ? customIconMap[customIcon] : null;

  const teamColor = team?.isHidden ? undefined : team?.color?.hex;
  return (
    <Container
      testID={testID}
      onPress={() => onCustomIconPress && onCustomIconPress()}
    >
      <TeamIcon
        teamColor={teamColor}
        iconName={team?.icon?.icon}
        defaultBg
        borderRadius={6}
      />
      <Name>{team?.name}</Name>
      {!!customIconName && (
        <CustomButtonIcon
          name={customIconName}
          customIconType={customIconType}
          onPress={() => onCustomIconPress && onCustomIconPress()}
        />
      )}
    </Container>
  );
}
