import React from 'react';
import useAppLocale from '~/data/hooks/useAppLocale';
import BrokenLinkLayout from './layout';

export default function BrokenLink(): JSX.Element {
  const { locale, onChangeLocale } = useAppLocale();

  const openContactSupport = () => {
    window.Intercom('showMessages');
  };

  return (
    <BrokenLinkLayout
      selectedLocale={locale}
      onSelectedLocale={onChangeLocale}
      openContactSupport={openContactSupport}
    />
  );
}
