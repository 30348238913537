/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper query Posts
// ====================================================

import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { Posts, PostsVariables } from './types/Posts';
import { POSTS } from './';

export function usePostsQuery(
  options?: QueryHookOptions<Posts, PostsVariables>,
): QueryResult<Posts, PostsVariables> {
  return useQuery<Posts, PostsVariables>(POSTS, options);
}

export function usePostsLazyQuery(
  options?: LazyQueryHookOptions<Posts, PostsVariables>,
): QueryTuple<Posts, PostsVariables> {
  return useLazyQuery<Posts, PostsVariables>(POSTS, options);
}

export function createPostsMock({
  variables,
  data,
  error,
}: {
  variables?: PostsVariables;
  data?: Posts;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: POSTS,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
