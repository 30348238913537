import React from 'react';
import Button from '~/components/Button';
import IconBox from '~/components/IconBox';
import NavHeader from '~/components/NavHeader';
import SectionHeader from '~/components/SectionHeader';
import { VSpacer } from '~/components/common/style';
import { t } from '~/utils/i18n';
import {
  Container,
  ScrollView,
  Content,
  Loading,
  LockImage,
  CheckImage,
  Title,
  Descp,
} from './style';

export type ModuleLayoutProps = {
  title: string;
  yearbookAdmin?: boolean;
  isActive?: boolean;
  viewOnly?: boolean;
  onBack: () => void;
  onHelp: () => void;
  onSetup: () => void;
  onRequestYearbookTeam: () => void;
  children: JSX.Element;
  isDeadlinePassed?: boolean | null;
};

export default function ModuleLayout({
  title,
  yearbookAdmin,
  isActive,
  viewOnly,
  onBack,
  onHelp,
  onSetup,
  onRequestYearbookTeam,
  children,
  isDeadlinePassed,
}: ModuleLayoutProps): JSX.Element {
  return (
    <Container>
      <NavHeader
        backButtonTestID={'buttonBack'}
        title={title}
        RightComponent={(() => {
          if (viewOnly) return null;

          if (yearbookAdmin) {
            return (
              <Button
                testID={'buttonSetup'}
                text={t('g.setup')}
                type={'primary-brand-02'}
                minWidth={80}
                size={'sm'}
                onPress={onSetup}
              />
            );
          }

          return (
            <Button
              testID={'buttonHelp'}
              text={t('getHelp')}
              type={'secondary-base'}
              minWidth={80}
              size={'sm'}
              onPress={onHelp}
            />
          );
        })()}
        onBackPress={onBack}
      />
      {(() => {
        const loading = yearbookAdmin === undefined || isActive === undefined;
        const contentNotAccessible =
          ((!isActive && !viewOnly) || (isDeadlinePassed && !viewOnly)) &&
          !viewOnly;

        if (loading) {
          return (
            <Content>
              <Loading testID={'module-layout-loading'} />
            </Content>
          );
        } else if (contentNotAccessible) {
          const ImageComp = isDeadlinePassed ? CheckImage : LockImage;
          const centerTitle = !isDeadlinePassed
            ? yearbookAdmin
              ? 'notActiveAdminTitle'
              : 'notActiveTitle'
            : 'passedDeadlineTitle';
          const descpText = isDeadlinePassed ? 'passedDeadlineDescp' : null;
          const buttonText = isDeadlinePassed
            ? 'buttonPassedDeadline'
            : 'buttonSetupModule';
          return (
            <ScrollView>
              <Content>
                <ImageComp />
                <VSpacer height={16} />
                <Title testID={centerTitle}>
                  {t(`moduleLayout.${centerTitle}`)}
                </Title>
                {descpText ? (
                  <>
                    <VSpacer height={16} />
                    <Descp>{t(`moduleLayout.${descpText}`)}</Descp>
                  </>
                ) : null}
                <VSpacer height={32} />
                {yearbookAdmin ? (
                  <Button
                    testID={buttonText}
                    text={t(`moduleLayout.${buttonText}`)}
                    size={'xl'}
                    flex
                    onPress={onSetup}
                  />
                ) : (
                  <>
                    <VSpacer height={32} />
                    <SectionHeader title={t('manageRequest.requestTitle')} />
                    <VSpacer height={16} />
                    <IconBox
                      testID={'joinYearbookTeamButton'}
                      text={t('manageRequest.yearbookTeam')}
                      buttonText={t('manageRequest.requestAccess')}
                      icon="notification-text"
                      onPress={onRequestYearbookTeam}
                    />
                  </>
                )}
              </Content>
            </ScrollView>
          );
        }
        return children;
      })()}
    </Container>
  );
}
