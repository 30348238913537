import styled from 'styled-components/native';
import { general } from '~/assets/imgs';
import Image from '~/components/Image';

export const ReportList = styled.FlatList`
  padding: 16px;
`;

export const Header = styled.View`
  padding-top: 12px;
  padding-bottom: 24px;
`;

export const RefreshControl = styled.RefreshControl.attrs(({ theme }) => ({
  color: theme.color.base.c4,
}))``;

export const Content = styled.View`
  padding-top: 96px;
  align-items: center;
`;

export const Footer = styled.View`
  padding-vertical: 24px;
  align-items: center;
`;

export const InfoText = styled.Text`
  padding-horizontal: 16px;
  color: ${({ theme }) => theme.color.base.c7};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 18px;
  line-height: 24px;
  text-align: center;
`;

export const EmptyImage = styled(Image).attrs({
  source: general.reports_fallback,
  resizeMode: 'contain',
})`
  width: 100%;
  height: 127px;
  margin-bottom: 32px;
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c7,
}))`
  margin-bottom: 16px;
`;
