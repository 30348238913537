import React from 'react';
import {
  PressableContainer,
  LNContainer,
  Container,
  Text,
  ArrowIcon,
} from './style';

type CityCellProps = {
  testID?: string;
  city: string;
  checked?: boolean;
  onPress: () => void;
};

export default function CityCell({
  testID,
  city,
  checked,
  onPress,
}: CityCellProps): JSX.Element {
  return (
    <PressableContainer testID={testID} onPress={onPress}>
      {({ pressed: active }) => (
        <LNContainer checked={checked || active}>
          <Container>
            <Text>{city}</Text>
            <ArrowIcon />
          </Container>
        </LNContainer>
      )}
    </PressableContainer>
  );
}
